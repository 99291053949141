import {planePointer} from "../primitives/planePointer";
import store from "../../store";
import {planePolygon} from "../primitives/planePolygon";
import {perspectiveLine} from "../primitives/perspectiveLine";
import {sortObjectsByZindex} from "../libs/sortObjectsByZindex";
import {bus} from "../../vue_bus";
import {planeLine} from "../primitives/planeLine";
import {Vector3} from "three";
import {getPerspectivePoints} from "../libs/getPerspectivePoints";

export const SetScaleTool = function (fabricJS) {
    this.fabricJS = fabricJS;
    this.pointZoneSize = 5;
    this.point1 = null;
    this.point2 = null;
    this.activeLine = null;
    this.movingID = null;
    this.line = null;


    // this.drawAll = () => {
    //     let pointer = new planePointer(this.pointArray[pointID], pointID);
    //     let line = new perspectiveLine(this.pointArray[link], this.pointArray[pointID]);
    //
    // }

    this.addPoint = () => {
        return (opt) => {
            let relativePoint = this.fabricJS.getPointer(opt.e);
            // const pointList = store.getters["active/POINTS"]
            if (!this.point1) {
                this.point1 = new planePointer(relativePoint, 1)
                this.fabricJS.add(this.point1.toFabric());
                this.activeLine = new planeLine(relativePoint, relativePoint)
                this.fabricJS.add(this.activeLine);
            } else {
                // this.fabricJS.remove(this.activeLine);
                this.point2 = new planePointer(relativePoint, 2)
                this.fabricJS.add(this.point2.toFabric());
                this.line = this.activeLine;
                this.activeLine = null;
                this.addMovingEvent();
                this.fabricJS.off('mouse:down');
                this.point1.dragging(true);
                this.point2.dragging(true);
                bus.$emit("ScaleLineIsDrawn");
            }
        }
    }

    this.addMovingEvent = () => {
        this.fabricJS.on('object:moving', (event) => {
            this.movingID = event.target.pointID;
            this.movingPosition = {
                x: event.target.left,
                y: event.target.top
            }
            if (this.movingID === 1)
                this.line.set({
                    x1: event.target.left,
                    y1: event.target.top
                })
            else
                this.line.set({
                    x2: event.target.left,
                    y2: event.target.top
                })

            this.fabricJS.renderAll();
        });
    }

    this.fabricJS.on('mouse:down', this.addPoint());

    this.fabricJS.on('mouse:move', (options) => {
        let pointer = this.fabricJS.getPointer(options.e);
        // console.log(this.activeLine)
        if (this.activeLine) {
            this.activeLine.set({
                x2: pointer.x,
                y2: pointer.y
            })
        }
        this.fabricJS.renderAll();
    });

    this.destroy = () => {
        bus.$off("AcceptScale");
    }


    bus.$on("AcceptScale", async (callback) => {

        if(!this.point1 || !this.point2){
            alert("Не задан эталонный интервал");
            return;
        }

        if(!store.state.UI.distanceBetweenPointsForScale){
            alert("Не указана длина эталонного интервала");
            return;
        }

        let scale = {};
        scale.points = {
            1: this.point1.position,
            2: this.point2.position
        };
        scale.distance = store.state.UI.distanceBetweenPointsForScale;
        await store.dispatch('active/SET_SCALE_PHOTO', scale);
        callback();
    })

    this.onUpdateScale = (scale)=>{
        if (!this.point1)
            return

        this.point1.toFabric().set({
            scaleX: 2/scale,
            scaleY: 2/scale,
        })

        if (this.point2)
            this.point2.toFabric().set({
                scaleX: 2/scale,
                scaleY: 2/scale,
            })

        if (this.activeLine)
            this.activeLine.set({
                strokeWidth: 3 / scale,
            })

        if (this.line)
            this.line.set({
                strokeWidth: 3 / scale,
            })

    }
    // this.drawAll();
    // this.addMovingEvent();


}
