var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lite-projects-sidebar-prompt-container" },
    [
      _c("div", { staticClass: "prompt-label" }, [
        _vm._v("\n        Создайте дом Вашей мечты всего за 3 действия!\n    ")
      ]),
      _vm._v(" "),
      _c("SVGPromptOne", { staticClass: "prompt-svg" }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("SVGPromptTwo", { staticClass: "prompt-svg" }),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("SVGPromptThree", { staticClass: "prompt-svg" }),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "blue-button",
          staticStyle: { "margin-top": "40px" },
          on: { click: _vm.closePrompt }
        },
        [_vm._v("\n        Понятно!\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prompt-grid" }, [
      _c("div", { staticClass: "prompt-number" }, [
        _vm._v("\n            1\n        ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "prompt-sub-label" }, [
        _vm._v("\n            Выберите подходящий проект здания\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prompt-grid" }, [
      _c("div", { staticClass: "prompt-number" }, [
        _vm._v("\n            2\n        ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "prompt-sub-label" }, [
        _vm._v(
          "\n            Подберите матералы, которые подойдут именно Вам\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prompt-grid" }, [
      _c("div", { staticClass: "prompt-number" }, [
        _vm._v("\n            3\n        ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "prompt-sub-label" }, [
        _vm._v("\n            Получите результат и  оформите заказ\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }