var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-background" }, [
    _c("div", { staticClass: "modal-head-grid" }, [
      _c("div", { staticClass: "modal-label" }, [
        _vm._v("\n            Параметры категории\n        ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-close-btn-mp",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("SVGClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c(
        "div",
        {
          staticClass: "modal-form-grid",
          staticStyle: { "margin-top": "23px" }
        },
        [
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                Название\n            ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nameCategory,
                expression: "nameCategory"
              }
            ],
            staticClass: "modal-form-grid-input",
            attrs: { type: "text", placeholder: "Название категории" },
            domProps: { value: _vm.nameCategory },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.nameCategory = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal-form-grid-label",
              staticStyle: { height: "36px" }
            },
            [_vm._v("\n                Область применения\n            ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-form-checkbox-grid" },
            [
              _vm._l(_vm.productTypes, function(productType) {
                return [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedProductTypes,
                          expression: "selectedProductTypes"
                        }
                      ],
                      staticClass: "form-input-checkbox",
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: productType.id,
                        checked: Array.isArray(_vm.selectedProductTypes)
                          ? _vm._i(_vm.selectedProductTypes, productType.id) >
                            -1
                          : _vm.selectedProductTypes
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.selectedProductTypes,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = productType.id,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.selectedProductTypes = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectedProductTypes = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectedProductTypes = $$c
                          }
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-input-checkbox-name" }, [
                    _vm._v(_vm._s(productType.name))
                  ])
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                Видимость в меню\n            ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-checkbox-grid" }, [
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.active,
                    expression: "active"
                  }
                ],
                staticClass: "form-input-checkbox",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.active)
                    ? _vm._i(_vm.active, null) > -1
                    : _vm.active
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.active,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.active = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.active = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.active = $$c
                    }
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm.active
              ? _c("div", { staticClass: "form-input-checkbox-name" }, [
                  _vm._v("Отображается")
                ])
              : _c("div", { staticClass: "form-input-checkbox-name" }, [
                  _vm._v("Скрыта")
                ])
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-lower-buttons-grid" }, [
        _c(
          "div",
          {
            staticClass: "blue-button modal-accept-button",
            on: {
              click: function($event) {
                return _vm.save()
              }
            }
          },
          [_vm._v("Сохранить")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blue-outline-button modal-accept-button",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v("Отмена")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }