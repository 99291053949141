var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "244",
        height: "216",
        viewBox: "0 0 244 216",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M7.5 103.853C44.0665 83.8735 135.6 58.3463 236.5 104.284V202.481C142.057 146.902 51.7635 174.499 7.5 201.794V199.499V116.999V103.853Z",
          stroke: "currentColor",
          "stroke-width": "15"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M126.596 2.40381C124.058 -0.134594 119.942 -0.134594 117.404 2.40381L76.0381 43.7696C73.4996 46.308 73.4996 50.4235 76.0381 52.962C78.5765 55.5004 82.692 55.5004 85.2304 52.9619L122 16.1924L158.77 52.9619C161.308 55.5004 165.424 55.5004 167.962 52.9619C170.5 50.4235 170.5 46.308 167.962 43.7696L126.596 2.40381ZM128.5 125L128.5 7.00001L115.5 7.00001L115.5 125L128.5 125Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }