<template>
    <div class="workspace-configurator">
        <div class="arrow-photo-left" style="z-index: 3; left: 402px; top: 45%;" v-on:click="previousPhoto">
            <SVGArrowSwitchPhotoLeft/>
        </div>
        <div class="arrow-photo-right" style="z-index: 3; right: 40px; top: 45%;" v-on:click="nextPhoto">
            <SVGArrowSwitchPhotoRight/>
        </div>

        <div v-show="Object.keys(photoList).length > 0" class="photo-navigation-tip-configurator" style="left: calc(50% + 92px)">
            <SVGRotateHouse/>
            <template v-for="(photo, id) in photoList">
                <div class="house-rotation-mark" :class="{green: id === activePhoto}"
                     v-on:click="selectPhoto(id)"></div>
            </template>
        </div>

        <div class="blue-workspace-button zoom-button" v-on:click="zoomSwitch" :class="{'active-blue-workspace-button': isZoomed}">
            <SVGZoomUp class="zoom-button-icon" v-show="!isZoomed"/>
            <SVGZoomDown class="zoom-button-icon" v-show="isZoomed"/>
        </div>

        <div v-if="visibleMarks" v-for="dataMark in marks" class="workspace-product-type-button"
             v-on:click="selectMark(dataMark)"
             :style="{'top': getHtmlCoords(dataMark).top - 35 + 'px',
             'left': getHtmlCoords(dataMark).left - 35 + 'px'}">
            <div class="workspace-product-type-button-outer">
                <div class="workspace-product-type-button-inner">
                    <component v-bind:is="matchingSvgAndTypePlane[dataMark.typePlanes].component"/>
                    <div class="workspace-product-type-button-text">
                        {{ matchingSvgAndTypePlane[dataMark.typePlanes].name }}
                    </div>
                </div>
            </div>
        </div>
        <!--        <div class="workspace-product-type-button"-->
        <!--             :style="{'top': 'calc(' + navbarHeight + 'px + ' + workspaceFacadeTop + '%)',-->
        <!--             'left': 'calc(' + sidebarWidth + 'px + ' + workspaceFacadeLeft + '%)'}">-->
        <!--            <div class="workspace-product-type-button-outer">-->
        <!--                <div class="workspace-product-type-button-inner">-->
        <!--                    <SVGFacade/>-->
        <!--                    <div class="workspace-product-type-button-text">Фасад</div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        <div class="workspace-product-type-button"-->
        <!--             :style="{'top': 'calc(' + navbarHeight + 'px + ' + workspaceGutterTop + '%)',-->
        <!--             'left': 'calc(' + sidebarWidth + 'px + ' + workspaceGutterLeft + '%)'}">-->
        <!--            <div class="workspace-product-type-button-outer">-->
        <!--                <div class="workspace-product-type-button-inner">-->
        <!--                    <SVGGutter/>-->
        <!--                    <div class="workspace-product-type-button-text">Водосток</div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        <div class="workspace-product-type-button"-->
        <!--             :style="{'top': 'calc(' + navbarHeight + 'px + ' + workspaceFenceTop + '%)',-->
        <!--             'left': 'calc(' + sidebarWidth + 'px + ' + workspaceFenceLeft + '%)'}">-->
        <!--            <div class="workspace-product-type-button-outer">-->
        <!--                <div class="workspace-product-type-button-inner">-->
        <!--                    <SVGFence/>-->
        <!--                    <div class="workspace-product-type-button-text">Забор</div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        TODO get [1,2,3,4,5] from photo num-->
        <div class="fabric-js-container" ref="fabricJSContainer">
            <div v-for="index in [1,2,3,4,5]" v-show="activePhoto == index" :class="{'hidden': !showCanvas}">
                <canvas :id="'fabric-js-canvas'+index" class="workspace-module-container"
                        :ref="'fabricJS' + index"></canvas>
            </div>
            <img v-if="!showCanvas" class="configurator-workspace-image" :src="srcShowingPhoto">
        </div>
    </div>
</template>

<script>
import SVGRoof from "../../../../SVGMP/SVGRoof";
import SVGFacade from "../../../../SVGMP/SVGFacade";
import SVGGutter from "../../../../SVGMP/SVGGutter";
import SVGFence from "../../../../SVGMP/SVGFence";
import {urlForFileStorage} from "../../../../common";
import {bus} from "../../../../../vue_bus";
import {fabricEditor} from "../../../../../fabric/fabricMain";
import SVGArrowSwitchPhotoLeft from "../../../../SVGMP/SVGArrowSwitchPhotoLeft";
import SVGArrowSwitchPhotoRight from "../../../../SVGMP/SVGArrowSwitchPhotoRight";
import {getNormalizeScale, sleep} from "../../../../../fabric/libs/common";
import {getRenderBase64, saveRender} from "../../../../../fabric/libs/renderSaver";
import store from "../../../../../store";
import SVGRotateHouse from "../../../../SVGMP/SVGRotateHouse";
import SVGRoofPlus from "../../../../SVGMP/SVGRoofPlus";
import SVGFacadePlus from "../../../../SVGMP/SVGFacadePlus";
import SVGGutterPlus from "../../../../SVGMP/SVGGutterPlus";
import SVGFencePlus from "../../../../SVGMP/SVGFencePlus";
import SVGZoomUp from "../../../../SVGMP/SVGZoomUp";
import SVGZoomDown from "../../../../SVGMP/SVGZoomDown";

export default {
    name: "WorkspaceConfiguratorDefault",
    components: {
        SVGZoomDown,
        SVGZoomUp,
        SVGRotateHouse,
        SVGArrowSwitchPhotoRight,
        SVGArrowSwitchPhotoLeft,
        // SVGFence,
        // SVGGutter,
        // SVGFacade,
        // SVGRoof
    },
    mixins: [urlForFileStorage],
    data() {
        return {
            sourceImg: {},
            scale: {},
            editor: {},
            viewPortScale: {},
            visibleMarks: false,
            sidebarWidth: 362,
            navbarHeight: 60,
            showCanvas: false,
            isZoomed: false,
            upScale: 1,
            renderPromises: {},
            matchingSvgAndTypePlane: {
                'roof': {
                    name: 'Кровля',
                    component: SVGRoofPlus,
                },
                'wall': {
                    name: 'Фасад',
                    component: SVGFacadePlus,
                },
                'gutter': {
                    name: 'Водосток',
                    component: SVGGutterPlus,
                    label: 'gutter'
                },
                'fence': {
                    name: 'Забор',
                    component: SVGFencePlus,
                }
            }
        }
    },
    computed: {
        activePhoto() {
            return this.$route.params.photoID;
        },
        activePhotoAbsoluteURL() {
            return this.urlForFileStorage(this.$store.getters[`active/GET_ACTIVE_PHOTO`].src);
        },
        activeDesign() {
            return this.$route.params.designID;
        },
        marks() {
            const activePhoto = this.$store.getters['active/GET_ACTIVE_PHOTO'];
            if (activePhoto)
                return activePhoto.markers;
            else
                return [];
        },
        photoList() {
            return this.$store.getters['active/GET_ALL_PHOTO'];
        },
        srcShowingPhoto() {
            const projectUUID = this.$store.state.route.params.projectUUID;
            const photoID = this.$store.state.route.params.photoID;
            const designID = this.$store.state.route.params.designID;
            const renderedPhoto = this.$store.getters[`projectList/${projectUUID}/designList/${designID}/RENDERED_PHOTO_BY_ID`](photoID);
            // const renderedPhoto = this.$store.getters[`projectList/${projectUUID}/designList/${designID}/RENDERED_PREVIEW_PHOTO_BY_ID`](photoID);
            // if (renderedPhoto)

            return this.urlForFileStorage(renderedPhoto);
            // return this.activePhotoAbsoluteURL
        },
    },
    watch: {
        activePhoto() {
            // bus.$off("saveRenderedImage");
            // this.editor.destroy();
            this.initFabric();
            this.setNewZoom();
        },
        activeDesign() {
            // this.showCanvas = false;
            bus.$off("saveRenderedImage");
            bus.$off("getRenderBase64");
            bus.$off("completeUpdatingTextures");
            for (let photoID of ["1","2","3","4","5"]) {
              if (this.editor[photoID])
                this.editor[photoID].destroy();
            }
            this.initFabric();
        },
    },
    methods: {
        async selectPhoto(id) {
            if (this.renderPromises[id]) {
                this.$store.dispatch("IN_PROGRESS", this.renderPromises[id]);
                await this.renderPromises[id]
            }
            this.$router.replace({
                    name: 'selectProduct',
                    params: {photoID: id}
            }).catch((e) => {});
        },
        zoomSwitch() {
            this.isZoomed = !this.isZoomed;
            this.setNewZoom();
        },
        setNewZoom() {
            if (this.isZoomed) {
                this.visibleMarks = false;
                this.editor[this.activePhoto].draggingLite.enable = true;
                if (this.upScale === 1.5) {
                    this.editor[this.activePhoto].setViewportLite(
                        this.sourceImg[this.activePhoto],
                        this.viewPortScale[this.activePhoto] * 2);
                    return;
                }
                this.upScale = 1.5;
            } else {
                this.visibleMarks = true;
                this.editor[this.activePhoto].draggingLite.enable = false;
                this.editor[this.activePhoto].setViewportLite(
                    this.sourceImg[this.activePhoto],
                    this.viewPortScale[this.activePhoto]);
                return
            }

            this.showCanvas = false;

            bus.$off("saveRenderedImage");
            bus.$off("getRenderBase64");
            bus.$off("completeUpdatingTextures");
            for (let photoID of ["1","2","3","4","5"]) {
                if (this.editor[photoID])
                    this.editor[photoID].destroy();
            }
            sleep(1000).then(async ()=>{
                this.editor = [];
                await this.initFabric();
                this.visibleMarks = false;
                this.editor[this.activePhoto].setViewportLite(
                    this.sourceImg[this.activePhoto],
                    this.viewPortScale[this.activePhoto] * 2);
                this.editor[this.activePhoto].draggingLite.enable = true;
            })

            this.$store.dispatch("IN_PROGRESS", sleep(2000));
            // bus.$emit('setZoom', this.isZoomed);
        },
        async nextPhoto() {
            const photosIds = Object.keys(this.photoList);
            const indexPhoto = photosIds.findIndex(photoId => photoId == this.activePhoto);

            let selectedPhotoID;
            if (indexPhoto < photosIds.length - 1) {
                selectedPhotoID = photosIds[indexPhoto + 1];
            } else {
                selectedPhotoID = photosIds[0];
            }
            if (this.renderPromises[selectedPhotoID]) {
                this.$store.dispatch("IN_PROGRESS", this.renderPromises[selectedPhotoID]);
                await this.renderPromises[selectedPhotoID]
            }
            // for (let photoID of ["1","2","3","4","5"]) {
            //     if (photoID == indexPhoto)
            //         continue;
                // if (this.editor[photoID]) {
                //     this.editor[photoID].destroy();
                //     this.editor[photoID] = null;
                // }

            // }
            this.$router.replace({
                name: 'selectProduct',
                params: {photoID: selectedPhotoID}
            }).catch((e) => {});
        },
        async previousPhoto() {
            const photosIds = Object.keys(this.photoList);
            const indexPhoto = photosIds.findIndex(photoId => photoId == this.activePhoto);
            let selectedPhotoID;
            if (indexPhoto > 0) {
                selectedPhotoID = photosIds[indexPhoto - 1];
            } else {
                selectedPhotoID = photosIds[photosIds.length - 1];
            }
            if (this.renderPromises[selectedPhotoID]) {
                this.$store.dispatch("IN_PROGRESS", this.renderPromises[selectedPhotoID]);
                await this.renderPromises[selectedPhotoID]
            }
            // for (let photoID of ["1","2","3","4","5"]) {
            //     if (photoID == indexPhoto)
            //         continue;
                // if (this.editor[photoID]) {
                //     this.editor[photoID].destroy();
                //     this.editor[photoID] = null;
                // }
            // }
            this.$router.replace({
                name: 'selectProduct',
                params: {photoID: selectedPhotoID}
            }).catch((e) => {});
        },
        getHtmlCoords(coords) {
            if (this.editor[this.activePhoto]) {
                return this.editor[this.activePhoto].convertToHtmlCoords(coords);
            } else {
                return {top: 0, left: 0}
            }
        },
        selectMark(dataMark) {
            this.$router.replace({
                name: 'selectProduct',
                params: {groupID: dataMark.idPlaneGroup, facadeElement: dataMark.typePlanes}
            }).catch((e) => {
            });
        },
        makeFabricObject: async function (photoID) {
            const projectUUID = this.$store.state.route.params.projectUUID;
             return new Promise(async (resolve, reject) => {
                this.editor[photoID] = new fabricEditor(this.$refs["fabricJS" + photoID][0], {
                    zoom: false,
                    dragging: false,
                    // zoom: true,
                    draggingLite: true
                });
                this.editor[photoID].fabricJS.backgroundColor = "#D8D9DA";

                this.editor[photoID].setQuality("high");
                this.editor[photoID].updateCanvaSize({
                        width: this.$refs["fabricJSContainer"].offsetWidth,
                        height: this.$refs["fabricJSContainer"].offsetHeight
                    }
                );
                //TODO size b
                let size = await store.dispatch("active/GET_ACTIVE_IMG_SIZE_BY_ID", photoID);
                // let size = await this.$store.dispatch("active/GET_ACTIVE_IMG_SIZE");
                this.scale[photoID] = getNormalizeScale(size, this.editor[photoID].fabricJS, this.upScale);
                this.sourceImg[photoID] = await this.editor[photoID].addSourceImage(
                    this.urlForFileStorage(this.$store.getters[`projectList/${projectUUID}/photoList/GET_PHOTO_BY_ID`](photoID).src),
                    this.scale[photoID]);

                this.editor[photoID].addMaterialTool({
                    scale: this.scale[photoID],
                    photoID,
                }).then(
                    (materialTool) => {
                        resolve(materialTool);
                    }
                ).catch((err) => {
                    reject(err)
                });
                let viewPort = this.editor[photoID].setViewport(this.sourceImg[photoID], this.scale[photoID]);
                this.viewPortScale[photoID] = viewPort[0];
            })
        },
        initFabric: async function () {
            const projectUUID = this.$store.state.route.params.projectUUID;
            const designID = this.$store.state.route.params.designID;
            this.visibleMarks = false;
            // this.showCanvas = false;
            if (this.editor[this.activePhoto]) {
                if (this.editor[this.activePhoto].materialTool)
                    this.editor[this.activePhoto].materialTool.renderAll();
                this.visibleMarks = true;
                // this.showCanvas = true;
                return
            }
            this.renderPromises[this.activePhoto] = this.makeFabricObject(this.activePhoto);
            let renderPromise = this.renderPromises[this.activePhoto];
            // this.$store.dispatch("IN_PROGRESS", this.renderPromises[this.activePhoto])
            renderPromise.then(async () => {
                this.visibleMarks = true;
                // this.editor[this.activePhoto].addDebugCoords(); // Получить координаты
                this.showCanvas = true;

            })

            for (let photoID of Object.keys(this.photoList)) {
                if (photoID != this.activePhoto && !this.editor[photoID]) {
                    // await sleep(100);
                    console.log("Фоновый рендеринг фото ", photoID)
                    this.renderPromises[photoID] = this.makeFabricObject(photoID);
                    // this.$store.dispatch("IN_PROGRESS", this.renderPromises[photoID])
                    // this.renderPromises[photoID];

                }
            }
            // this.editor[this.activePhoto].addDebugCoords(); // Получить координаты
            this.$store.dispatch("IN_PROGRESS", renderPromise)
            bus.$on("getRenderBase64", async (cb) => {
                for (let photoID of Object.keys(this.photoList)) {
                    if (!this.editor[photoID])
                        continue

                    if (!this.editor[photoID].materialTool)
                        continue

                    if (!this.editor[photoID].materialTool.renderAll)
                        continue

                    await this.editor[photoID].materialTool.renderAll();
                    this.editor[photoID].fabricJS.renderAll();
                    const render = getRenderBase64(2, this.editor[photoID].fabricJS, {
                        width: this.$refs["fabricJSContainer"].offsetWidth,
                        height: this.$refs["fabricJSContainer"].offsetHeight
                    });
                    store.commit(`projectList/${projectUUID}/designList/${designID}/RENDERED_PHOTOS`, {
                        photoId: photoID,
                        photoSrc: render
                    });

                }
                cb();
            })

            bus.$on("saveRenderedImage", (cb) => {
                saveRender(1.3, this.editor[this.activePhoto].fabricJS, {
                    width: this.$refs["fabricJSContainer"].offsetWidth,
                    height: this.$refs["fabricJSContainer"].offsetHeight
                }).then((request) => {
                    cb(request.data.fileList[0]);
                });
            });

            bus.$on("completeUpdatingTextures", async () => {
                for (let photoID of Object.keys(this.photoList)) {
                    if (!this.editor[photoID])
                        continue
                    if (!this.editor[photoID].materialTool)
                        continue
                    // await this.editor[photoID].materialTool.renderAll();
                }
            });

            return renderPromise;
        }
    },
    mounted() {
        this.initFabric();
    },
    beforeDestroy() {
        let params = Object.assign({}, this.$route.params);
        delete params.view;
        this.$router.replace({params}).catch(() => {
        });
    },
    destroyed() {
        bus.$off("saveRenderedImage");
        bus.$off("getRenderBase64");
        bus.$off("completeUpdatingTextures");
        for (let photoID of ["1","2","3","4","5"]) {
            if (this.editor[photoID])
                this.editor[photoID].destroy();
        }
    },
}
</script>

<style scoped>
.fabric-js-container {
    width: 100%;
    height: 100%;
}
</style>
