var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-background" }, [
    _c("div", { staticClass: "modal-head-grid" }, [
      _c("div", { staticClass: "modal-label" }, [
        _vm._v("\n                Добавить продукт\n            ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-close-btn-mp",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("SVGClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container pv-23" }, [
      _c(
        "div",
        {
          staticClass: "modal-form-grid",
          staticStyle: { "grid-template-columns": "170px auto" }
        },
        [
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v(
              "\n                    Выберите тип продукта\n                "
            )
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.productTypeId,
                  expression: "productTypeId"
                }
              ],
              staticClass: "modal-form-grid-input width100",
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.productTypeId = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function($event) {
                    return _vm.selectProductType(_vm.productTypeId)
                  }
                ]
              }
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v("- Не выбран -")
              ]),
              _vm._v(" "),
              _vm._l(_vm.productTypes, function(prodType) {
                return _c("option", { domProps: { value: prodType.id } }, [
                  _vm._v(_vm._s(prodType.name))
                ])
              })
            ],
            2
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.productTypeId !== null,
            expression: "productTypeId !== null"
          }
        ]
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "modal-data-container pv-23" }, [
          _c("div", { staticClass: "two-cols-modal-grid" }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "modal-form-grid" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal-form-grid-label",
                      staticStyle: { height: "36px" }
                    },
                    [
                      _vm._v(
                        "\n                                Область применения\n                            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-form-checkbox-grid" },
                    [
                      _vm._l(_vm.areas, function(area) {
                        return [
                          _c("div", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedAreas,
                                  expression: "selectedAreas"
                                }
                              ],
                              staticClass: "form-input-checkbox",
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: area.name,
                                checked: Array.isArray(_vm.selectedAreas)
                                  ? _vm._i(_vm.selectedAreas, area.name) > -1
                                  : _vm.selectedAreas
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.selectedAreas,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = area.name,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectedAreas = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectedAreas = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectedAreas = $$c
                                  }
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-input-checkbox-name" },
                            [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.getRussianNameApplicationAreas(
                                      area.name
                                    )
                                  ) +
                                  "\n                                    "
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-form-grid-label" }, [
                    _vm._v(
                      "\n                                Название\n                            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.productName,
                        expression: "productName"
                      }
                    ],
                    staticClass: "modal-form-grid-input",
                    domProps: { value: _vm.productName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.productName = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-form-grid-label" }, [
                    _vm._v(
                      "\n                                Url\n                            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.linkInOnlineStore,
                        expression: "linkInOnlineStore"
                      }
                    ],
                    staticClass: "modal-form-grid-input",
                    domProps: { value: _vm.linkInOnlineStore },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.linkInOnlineStore = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.productCategories, function(category) {
                    return [
                      _c("div", { staticClass: "modal-form-grid-label" }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(category.name) +
                            "\n                                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          staticClass: "modal-form-grid-input",
                          domProps: {
                            value: _vm.getSubcategoryParam(category.id)
                          },
                          on: {
                            input: function($event) {
                              return _vm.setSubcategory(
                                category.id,
                                $event.target.value
                              )
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("-- Не выбран --")
                          ]),
                          _vm._v(" "),
                          _vm._l(category.subcategories, function(subcategory) {
                            return _c(
                              "option",
                              { domProps: { value: subcategory.id } },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(subcategory.name) +
                                    "\n                                    "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "modal-form-grid" }, [
                _c("div", { staticClass: "modal-form-grid-label" }, [
                  _vm._v(
                    "\n                                Парам. текстуры\n                            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedTextureParameters,
                        expression: "selectedTextureParameters"
                      }
                    ],
                    staticClass: "modal-form-grid-input input-251",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedTextureParameters = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("-- Не выбран --")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.textureParameters, function(value, key) {
                      return _c("option", { domProps: { value: key } }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(value.name) +
                            "\n                                "
                        )
                      ])
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-form-grid-label" }, [
                  _vm._v(
                    "\n                                Текстура\n                            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedDefuseMapId,
                        expression: "selectedDefuseMapId"
                      }
                    ],
                    staticClass: "modal-form-grid-input input-251",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedDefuseMapId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("-- Не выбран --")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.defuseMaps, function(defuseMap) {
                      return _c(
                        "option",
                        { domProps: { value: defuseMap.id } },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(defuseMap.name) +
                              "\n                                "
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-form-grid-label" }, [
                  _vm._v(
                    "\n                                Карта нормали\n                            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedNormalMapId,
                        expression: "selectedNormalMapId"
                      }
                    ],
                    staticClass: "modal-form-grid-input input-251",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedNormalMapId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("-- Не выбран --")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.normalMaps, function(normalMap) {
                      return _c(
                        "option",
                        { domProps: { value: normalMap.id } },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(normalMap.name) +
                              "\n                                "
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-form-grid-label" }, [
                  _vm._v(
                    "\n                                Карта высот\n                            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedHeightMapId,
                        expression: "selectedHeightMapId"
                      }
                    ],
                    staticClass: "modal-form-grid-input input-251",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedHeightMapId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("- Не выбран -")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.heightMaps, function(heightMap) {
                      return _c(
                        "option",
                        { domProps: { value: heightMap.id } },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(heightMap.name) +
                              "\n                                "
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-form-grid-label" }, [
                  _vm._v(
                    "\n                                3D модель\n                            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedModel3dId,
                        expression: "selectedModel3dId"
                      }
                    ],
                    staticClass: "modal-form-grid-input input-251",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedModel3dId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v("-- Не выбран --")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.model3ds, function(model) {
                      return _c("option", { domProps: { value: model.id } }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(model.name) +
                            "\n                                "
                        )
                      ])
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-form-img-container" }, [
                _c("img", {
                  staticClass: "modal-form-img",
                  attrs: { src: _vm.getPhoto() }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "blue-button modal-form-img-button",
                    on: {
                      click: function($event) {
                        return _vm.$refs.previewFile.click()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                                    Изменить\n                                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  ref: "previewFile",
                  attrs: { type: "file", accept: ".jpg,.png", hidden: "" },
                  on: {
                    change: function($event) {
                      return _vm.handleFileUpload($event)
                    }
                  }
                })
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-lower-buttons-grid" }, [
        _c(
          "div",
          {
            staticClass: "blue-button modal-accept-button",
            class: { "disabled-button": _vm.productTypeId === null },
            on: { click: _vm.save }
          },
          [_vm._v("Сохранить\n                ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blue-outline-button modal-accept-button",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v("Отмена")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "mp-sidebar-borderline" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }