<template>
    <div id="index">
        <router-view></router-view>
    </div>
</template>

<script>
    import {bus} from "../vue_bus";
    import ModalAlert from "./Modal/ModalAlert";
    import TempCopyRight from "./TempCopyRight";
    import ModalSuccess from "./Modal/ModalSuccess";

    export default {
        name: "TheApp",
        components: {
            TempCopyRight
        },
        data() {
            return {
                pxRatio: null,
                reloadingTimer: null
            }
        },
        methods: {
            resizeAction() {
                if (this.isZooming()) {
                    if (this.reloadingTimer) {
                        clearTimeout(this.reloadingTimer);
                        this.reloadingTimer = null;
                    }
                    this.reloadingTimer = setTimeout(() => {
                        window.location.reload();
                    }, 700);
                }
            },
            isZooming() {
                let newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
                if (newPx_ratio !== this.pxRatio){
                    this.pxRatio = newPx_ratio;
                    return true;
                 }else {
                    return false;
                }
            }
        },
        created() {
            window.addEventListener("resize", this.resizeAction);
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeAction);
        },
        mounted() {
            bus.$on("showModalAlert", (text) => {
                this.$modal.show(ModalAlert, {text}, {
                    name: 'modalAlert',
                    height: 'auto',
                    pivotY: 0.2,
                    width: 460,
                    draggable: true,
                });
            });
            bus.$on("showModalSuccess", (text) => {
                this.$modal.show(ModalSuccess, {text}, {
                    name: 'modalAlert',
                    height: 'auto',
                    pivotY: 0.2,
                    width: 460,
                    draggable: true,
                });
            });
            this.pxRatio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth
        }
    }
</script>

<style scoped>
    #index {
        display: block;
        width: 100vw;
        height: 100vh;
        font-family: Verdana;
    }
</style>

<style>
    /*METALL PROFIL*/

    :root {
        --one-line-height-12-px: 15px;
    }


    .page-container {
        display: block;
        width: 100vw;
        height: 100vh;
    }

    .page-navbar {
        width: 100%;
        height: 60px;
        background-color: #1C1F21;
    }

    .page-admin-body {
        width: 100%;
        height: calc(100% - 60px);
        display: grid;
        grid-template-columns: 240px auto;
    }

    .page-admin-sidebar {
        background-color: #4C5459;
    }

    .page-admin-workspace {

    }

    .profile-button {
        color: white;
        cursor: pointer;
        user-select: none;
        width: max-content;
    }

    .profile-button:hover {
        color: #baff00;
    }

    .profile-button:active {
        color: white;
    }

    .admin-sidebar-menu {
        margin: 30px 0;
        display: grid;
        width: 100%;
        grid-auto-rows: 40px;
    }

    .admin-sidebar-menu-element {
        display: grid;
        grid-template-columns: 20px 150px;
        color: white;
        padding-left: 35px;
        cursor: pointer;
        user-select: none;
    }

    .admin-sidebar-menu-element:hover {
        /*background-color: #baff00;*/
        /*color: #1C1F21;*/
        background-color: #343a3d;
        color: #baff00;
    }

    .admin-sidebar-menu-element:active {
        background-color: #baff00;
        color: #1C1F21;
    }

    .active-menu {
        background-color: #baff00 !important;
        color: #1C1F21 !important;
    }

    .admin-sidebar-menu-element-svg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-sidebar-menu-element-text {
        font-size: 12px;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .thenavbar {
        display: grid;
        grid-template-columns: max-content auto;
        width: 100%;
        height: 60px;
        background-color: #1C1F21;
        z-index: 10;
        position: relative;
    }

    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 135px;
        height: 100%;
        cursor: pointer;
        user-select: none;
    }

    .navbar-text-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        /*width: 600px;*/
        height: 100%;
        font-size: 14px;
        margin: 0 40px;
    }

    .mh-10 {
        margin: 0 10px;
    }

    .white-text {
        color: white;
    }

    .link-underline {
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
    }

    .link-underline:hover {
        color: #baff00;
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .application {
        display: block;
        width: 100vw;
        height: 100vh;
    }

    .application-body {
        width: 100%;
        height: 100%;
        background-color: #D8D9DA;
    }

    .lite-app-body {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: max-content auto max-content;
    }

    .lite-projects-sidebar {
        width: 440px;
        height: 100%;
        background-color: white;
        display: grid;
        grid-template-columns: 120px 320px;

    }

    .sidebar-building-types {
        background-color: #4C5459;
    }

    .lite-projects-sidebar-list {

    }

    .lite-projects-workspace {
        background-color: #D8D9DA;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lite-projects-sidebar-prompt {
        width: 275px;
        height: 100%;
        background-color: white;
    }

    .lite-configurator-sidebar {
        width: 362px;
        height: calc(100% - 60px);
        background-color: white;
        display: grid;
        grid-template-columns: max-content auto;
    }

    .lite-configurator-workspace {
        width: 100%;
        height: calc(100vh - 120px);
    }

    .lite-configurator-result-tools {
        width: 100%;
        height: 60px;
        background-color: white;
        border-top: 1px solid #D8D9DA;
    }

    .projects-sidebar {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: max-content auto max-content max-content;
        height: calc(100vh - 60px);
    }

    .sidebar-data-container {
        margin: 18px 23px;
        display: block;
    }

    .sidebar-tags-container {
        height: 55px;
        overflow-y: auto;
    }

    .sidebar-tags-container-placeholder {
        display: flex;
        height: 100%;
        align-items: center;
        font-size: 12px;
        color: #1C1F21;
        padding-left: 7px;
    }

    .pill-tag {
        background-color: #4C5459;
        height: 22px;
        font-size: 12px;
        border-radius: 11px;
        color: white;
        padding: 0 7px;
        margin: 0 6px 6px 0;
        width: max-content;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        border: 1px solid transparent;
    }

    .pill-tag:active {
        background-color: white;
        color: #4C5459;
        border-color: #4C5459;
    }

    .pill-tag-static {
        background-color: #4C5459;
        height: 22px;
        font-size: 12px;
        border-radius: 11px;
        color: white;
        padding: 0 7px;
        margin: 0 6px 6px 0;
        width: max-content;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        border: 1px solid transparent;
    }

    .pill-outline-tag {
        background-color: white;
        color: #4C5459;
        border: 1px solid #4C5459;
        height: 22px;
        font-size: 12px;
        border-radius: 11px;
        padding: 0 7px;
        margin: 0 6px 6px 0;
        width: max-content;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }

    .pill-outline-tag:hover {
        background-color: white;
        color: #1C1F21;
        border-color: #1C1F21;
    }

    .pill-outline-tag:active {
        background-color: #4C5459;
        color: white;
        border-color: #4C5459;
    }

    .tag-menu-switch {
        color: #0075A9;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 7px;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
    }

    .expanded-tags-menu {
        border: 1px solid #E8ECEF;
        border-radius: 4px;
        position: absolute;
        z-index: 100;
        top: 172px;
        width: 318px;
        background-color: white;
    }

    .scroll-tags-menu {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 400px;
    }

    .tag-category-grid {
        display: grid;
        grid-template-columns: 90px auto;
    }

    .tag-category-label {
        font-size: 12px;
        color: #1C1F21;
        margin-top: 11px;
    }

    .tag-category-container {
        margin-top: 7px;
    }

    .sidebar-filter-svg-container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #7A7C7D;
        cursor: pointer;
        user-select: none;
    }

    .sidebar-filter-svg-container:hover {
        color: #1C1F21;
    }

    .mp-sidebar-label {
        font-size: 18px;
        color: #1C1F21;
        font-weight: bold;
    }

    .sidebar-project-preview {
        border: 2px solid transparent;
        border-radius: 2px;
        background-color: white;
        height: 193px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        /*margin: 18px 0;*/
    }

    .sidebar-project-preview:hover {
        border: 2px solid #0075A9;
    }

    .active-sidebar-project-preview {
        border: 2px solid #0075A9;
    }

    .sidebar-project-preview-image {
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: 1px;
        object-fit: cover;
    }

    .projects-list-container {
        overflow-y: auto;
        display: block;
        height: auto;
    }

    .projects-list {
        /*height: 540px;*/
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 18px;
    }

    .mp-sidebar-text {
        font-size: 12px;
    }

    .blue-button {
        color: white;
        background-color: #0075A9;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        border-radius: 3px;
        margin: 13px 0;
        user-select: none;
        cursor: pointer;
        border: 1px solid transparent;
    }

    .blue-button:hover {
        background-color: #005275;
    }

    .blue-button:active {
        background-color: #B4FF00;
        color: #1C1F21;
    }

    .disabled-button {
        background-color: #a8a8a8 !important;
        cursor: default !important;
        color: white !important;
    }

    .blue-outline-button {
        color: #0075A9;
        background-color: white;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        border-radius: 3px;
        margin: 13px 0;
        user-select: none;
        cursor: pointer;
        border: 1px solid #0075A9;
    }

    .blue-outline-button:hover {
        color: #005275;
        border-color: #005275;
    }

    .blue-outline-button:active {
        color: #1C1F21;
        border-color: #1C1F21;
    }

    .mp-sidebar-borderline {
        border-bottom: 2px solid #E8ECEF;
    }

    .filter-frame {
        border: 1px solid #383838;
        margin: 15px 0;
    }

    .filter-label-grid {
        height: 42px;
        display: grid;
        grid-template-columns: auto 42px;
        border-bottom: 1px solid #383838;
        color: white;
    }

    .filter-label {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-left: 10px;
    }

    .filter-frame-collapse-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }

    .filter-frame-collapse-arrow:hover {
        color: #baff00;
    }

    .filter-data-container {
        /*max-height: 165px;*/

        /*overflow-y: auto;*/

        overflow-y: hidden;
        overflow-x: hidden;
    }

    .filter-data {
        padding: 17px 10px;
        color: white;
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 14px;
    }

    .expand-to-full-container {
        margin: 0 0 12px 12px;
        user-select: none;
    }

    .filter-expand-to-full {
        font-size: 12px;
        color: #baff00;
        padding: 5px 0 0 0;
        display: grid;
        justify-content: start;
        align-items: center;
        grid-template-columns: max-content max-content;
        grid-column-gap: 5px;
        cursor: pointer;
    }

    .filter-expand-to-full:hover {
        text-decoration: underline;
    }

    .arrow-to-full {
        width: 12px
    }

    .filter-data-checkbox-line {
        /*display: inline-flex;*/
        /*justify-content: start;*/
        /*align-items: start;*/

        display: grid;
        width: 100%;
        grid-template-columns: max-content auto max-content;
        grid-column-gap: 3px;
        align-items: start;
    }

    .checkbox-label {
        font-size: 14px;
    }

    .checkbox-count {
        font-size: 14px;
        color: #b3b3b3;
    }

    .filter-data-colors-line {
        display: grid;
        grid-template-columns: max-content max-content max-content max-content;
        grid-column-gap: 9px;
        grid-row-gap: 9px;
        width: 100%;
    }

    .filter-color {
        display: grid;
        /*grid-template-columns: auto;*/
        /*grid-template-rows: auto auto;*/
        height: 36px;
        width: 36px;
        border: 0.15em solid transparent;
        border-radius: 19px;
        margin: auto;
        cursor: pointer;
    }

    .filter-color-icon {
        height: 36px;
        width: 36px;
        border-radius: 18px;

    }

    .filter-color:active {
        border: 0.15em solid #baff00;
    }

    .color-selected {
        border: 0.15em solid #baff00 !important;
    }

    .filter-color-ral {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 9px;
        white-space: nowrap;
    }

    .filters-container {
        width: 237px;
        background-color: #4C5459;
    }

    .products-container {
        width: auto;
        /*padding: 20px 6px;*/
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 60px);
        border-right: 1px solid #D8D9DA;
    }

    .collapsed {
        width: 53px;
    }

    .filters-head-expanded {
        width: 100%;
    }

    .filters-head-collapsed {

    }

    .filters-expanded-label-grid {
        display: grid;
        grid-template-columns: auto 53px;
        height: 42px;
        border:  1px solid #383838;
    }

    .filters-expanded-label {
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 14px;
        font-weight: normal;
        border: 1px solid #383838;
        user-select: none;
        cursor: pointer;
    }

    .filters-expanded-label:hover {
        background-color: #343a3d;
        color: #baff00;
    }

    .filters-expanded-label:active {
        background-color: #baff00;
        color: #1C1F21;
    }

    .filters-collapsed-label {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 14px;
        font-weight: normal;
        border: 1px solid #383838;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        width: 51px;
        height: auto;
        margin-top: -1px;
        padding: 10px 0;
        user-select: none;
    }

    .filters-collapse-button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        user-select: none;
        height: 42px;
    }

    .filters-collapse-button:hover {
        background-color: #343a3d;
        color: #baff00;
    }

    .filters-collapse-button:active {
        background-color: #baff00;
        color: #1C1F21;
    }

    .expanded-plane-type-menu {
        display: grid;
        /*grid-template-columns: max-content max-content max-content max-content;*/
        padding: 15px 14px;

        grid-template-columns: max-content max-content max-content;
        justify-content: center;
    }

    .collapsed-plane-type-menu {
        display: grid;
        grid-template-rows: max-content max-content max-content max-content;
        margin-top: -1px;
    }

    .plane-type-menu-button {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 34px 15px;
        color: white;
        border: 1px solid #383838;
        cursor: pointer;
        user-select: none;
        width: 51px;
        height: 49px;
    }

    .plane-type-menu-button:hover {
        background-color: #343a3d;
        color: #baff00;
    }

    .plane-type-menu-button:active {
        background-color: #baff00;
        color: #1C1F21;
    }

    .activeFiltersTab {
        background-color: #baff00 !important;
        color: #1C1F21 !important;
    }

    .plane-type-menu-button-text {
        font-size: 9px;
        text-align: center;
    }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .m-left-1 {
        margin-left: -1px;
    }

    .m-top-1 {
        margin-top: -1px;
    }

    .no-mt {
        margin-top: 0;
    }

    .default-border {
        border: 1px solid #383838;
    }

    .sidebar-filters-outer-container {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 180px);
    }

    .sidebar-filters-container {
        padding: 14px;
    }

    .products-sidebar-single {
        display: grid;
        grid-template-columns: auto;
        justify-items: center;
        padding: 20px 6px;
    }

    .products-sidebar-double {
        display: grid;
        grid-template-columns: auto auto;
        justify-items: center;
        padding: 20px 6px;
    }

    .sidebar-product-container {
        width: 105px;
        padding: 2px;
        border: 2px solid transparent;
        cursor: pointer;
        user-select: none;
    }

    .sidebar-product-container:hover {
        border: 2px solid #0075A9;
        border-radius: 2px;
    }

    .sidebar-product-img {
        width: 101px;
        height: 101px;
        object-fit: cover;
    }

    .sidebar-product-text {
        color: #1C1F21;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: calc(var(--one-line-height-12-px) * 2);
    }

    .modal-background {
        background-color: white;
        border-radius: 4px;
    }

    .modal-head-grid {
        display: grid;
        grid-template-columns: auto 58px;
        width: 100%;
        height: 50px;
        background-color: #4C5459;
        color: white;
        user-select: none;
        margin-bottom: 5px;
    }

    .modal-label {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 20px;
    }

    .modal-close-btn-mp {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .result-modal-options-buttons {
        position: absolute;
        display: grid;
        grid-template-columns: 60px;
        grid-template-rows: 60px 60px 60px;
        right: 0;
        top: 50px;
    }

    .std-gray-border {
        border: 1px solid #D8D9DA;
    }

    .result-modal-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 444px;
        width: calc(100% - 130px);
        margin: auto;
    }

    .result-modal-image {
        max-width: 100%;
        max-height: 444px;
        user-select: none;
    }

    .result-modal-photo-navigation-tip {
        position: absolute;
        top: 455px;
        width: 185px;
        height: 33px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: #4C5459;
        opacity: 0.75;
        border-radius: 2px;
        color: white;
        user-select: none;
    }

    .result-modal-products-carousel {
        display: inline-flex;
        justify-content: flex-start;
        width: 880px;
        overflow-y: hidden;
        overflow-x: auto;
        margin: 0 20px;
    }

    .result-modal-product-card {
        margin: 9px;
        display: block;
        width: 160px;
    }

    .result-modal-product-card-img {
        width: 160px;
        height: 160px;
        object-fit: cover;
        margin-bottom: 5px;
    }

    .result-modal-product-card-body {
        height: 100px;
    }

    .result-modal-product-card-label {
        font-size: 14px;
        color: #535353;
        margin-bottom: 5px;
    }

    .result-modal-product-card-characteristic {
        font-size: 10px;
        color: #73818A;
    }

    .result-modal-product-card-borderline {
        border-bottom: 1px solid #4C5459;
        margin: 5px 0;
    }

    .result-modal-product-card-shop-btn {
        font-size: 10px;
        height: 30px;
    }

    .modal-data-container {
        margin: 8px 20px;
    }

    .modal-borderline-container {
        margin: 0 20px;
    }

    .modal-tip-grid {
        display: grid;
        grid-template-columns: 85px auto;
        color: #535353;
        width: 100%;
        margin: 30px 0;
    }

    .modal-tip-icon {
        color: #73818A;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-tip-text {
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .modal-form-grid {
        display: grid;
        grid-template-columns: max-content auto;
        grid-row-gap: 23px;
        grid-column-gap: 10px;
        /*grid-auto-rows: 36px;*/
    }

    .modal-form-grid-three {
        display: grid;
        grid-template-columns: max-content auto max-content;
        grid-row-gap: 23px;
        grid-column-gap: 10px;
        /*grid-auto-rows: 36px;*/
    }

    .modal-form-grid-label {
        color: #535353;
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .modal-form-grid-input {
        font-size: 14px;
        outline: none;
        border: 1px solid #E8ECEF;
        background-color: #FBFBFB;
        padding: 0 12px;
        display: flex;
        align-items: center;
        height: 36px;
        /*max-width: 220px;*/
    }

    .input-251 {
        max-width: 251px;
    }

    .width100 {
        width: 100% !important;
        max-width: unset !important;
    }

    .form-sublabel {
        margin: 40px 0;
        font-weight: bold;
    }

    .form-sublabel-top {
        margin-bottom: 40px;
        font-weight: bold;
    }

    .modal-form-checkbox-grid {
        display: grid;
        grid-template-columns: max-content auto;
        grid-column-gap: 20px;
        align-items: center;
    }

    .form-input-checkbox {
        user-select: none;
    }

    .form-input-checkbox-name {
        color: #535353;
        font-size: 12px;
        display: flex;
        align-items: center;
        height: 38px;
    }

    .modal-accept-button {
        width: max-content;
        float: right;
        font-size: 14px;
        padding: 0 23px;
    }

    .modal-share-buttons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }

    .modal-share-buttons-grid {
        display: grid;
        grid-template-columns: 60px 60px 60px 60px 60px;
        grid-template-rows: 60px;
        grid-column-gap: 20px;
    }

    .projects-workspace-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }

    .configurator-workspace-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        user-select: none;
    }

    .arrow-photo-left {
        position: absolute;
        cursor: pointer;
        opacity: 0.6;
    }

    .arrow-photo-left:hover {
        opacity: 0.8;
    }

    .arrow-photo-left:active {
        opacity: 1;
    }

    .arrow-photo-right {
        position: absolute;
        cursor: pointer;
        opacity: 0.6;
    }

    .arrow-photo-right:hover {
        opacity: 0.8;
    }

    .arrow-photo-right:active {
        opacity: 1;
    }

    .configurator-button {
        position: absolute;
        bottom: 94px;
        color: white;
        background-color: #0075A9;
        font-size: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 320px;
        border-radius: 3px;
        border: 1px solid white;
        margin: 13px 0;
        cursor: pointer;
        user-select: none;
    }

    .configurator-button:hover {
        background-color: #005275;
    }

    .configurator-button:active {
        background-color: #B4FF00;
        color: #1C1F21;
    }

    .photo-navigation-tip {
        position: absolute;
        bottom: 14px;
        width: 185px;
        height: 33px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: #4C5459;
        opacity: 0.75;
        border-radius: 2px;
        color: white;
        user-select: none;
    }

    .photo-navigation-tip-configurator {
        position: absolute;
        bottom: 74px;
        width: 185px;
        height: 33px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: #4C5459;
        opacity: 0.75;
        border-radius: 2px;
        color: white;
        user-select: none;
        z-index: 10;
    }

    .house-rotation-mark {
        width: 35px;
        height: 6px;
        margin: 6px;
        border-radius: 3px;
        background-color: white;
        cursor: pointer;
    }

    .house-rotation-mark:hover {
        background-color: #e6e6e6;
    }

    .green {
        background-color: #B4FF00 !important;
        cursor: default;
    }

    @media screen and (max-width: 1410px) {
        .lover-bar-button-text {
            display: none !important;
        }
        .lover-bar-button-svg {
            margin-right: 0 !important;
        }
    }

    .lover-bar-buttons {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        height: 100%;
        width: 100%;
    }

    .lover-bar-button {
        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #D8D9DA;
        color: #1C1F21;
        background-color: white;
        user-select: none;
        cursor: pointer;
    }

    .lover-bar-button:hover {
        background-color: #efefef;
    }

    .lover-bar-button:active {
        background-color: white;
    }

    .lover-bar-button-svg {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }

    .lover-bar-button-text {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .workspace-configurator {
        background-color: #D8D9DA;
        width: 100%;
        height: 100%;
    }

    .workspace-product-type-button {
        width: 46px;
        height: 46px;
        /*opacity: 0.3;*/
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        background-color: rgba(196,196,196,.3);
        cursor: pointer;
        user-select: none;
        position: absolute;
        z-index: 90;
        padding:12px;
    }

    .workspace-product-type-button:hover {
        width: auto;
    }

    .workspace-product-type-button:hover .workspace-product-type-button-outer{
        width: auto;
    }

    .workspace-product-type-button:hover .workspace-product-type-button-inner{
        width: auto;
        background-color: #baff00;
        color: #1C1F21;
        padding: 0 10px 0 5px;
    }

    .workspace-product-type-button:hover .workspace-product-type-button-text{
        display: flex;
    }

    .workspace-product-type-button-outer {
        width: 38px;
        height: 38px;
        padding: 4px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 23px;
    }

    .workspace-product-type-button-inner {
        width: 38px;
        height: 38px;
        background-color: #0075A9;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 19px;
    }

    .workspace-product-type-button-text {
        display: none;
        padding-left: 10px;
        font-size: 14px;
    }

    .hidden {
        display: none;
    }

    .admin-workspace-container {
        padding: 30px 90px 90px 40px;
        width: calc(100% - 131px);
        height: calc(100% - 196px);
        /*height: calc(100% - 60px);*/
    }

    .search-input {
        padding-left: 40px !important;
    }

    .search-label {
        position: relative;
    }

    .search-label:before {
        content: "";
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 0;
        width: 20px;
        background: url("data:image/svg+xml,%3Csvg%20width%3D%2720%27%20height%3D%2720%27%20viewBox%3D%270%200%2016%2016%27%20fill%3D%27none%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20d%3D%27M5.99967%201.3335C3.43024%201.3335%201.33301%203.43073%201.33301%206.00016C1.33301%208.5696%203.43024%2010.6668%205.99967%2010.6668C7.16501%2010.6668%208.22968%2010.2322%209.04915%209.521L9.33301%209.80485V10.6668L13.057%2014.3908C13.425%2014.7588%2014.0223%2014.7588%2014.3903%2014.3908C14.7583%2014.0228%2014.7583%2013.4255%2014.3903%2013.0575L10.6663%209.3335H9.80436L9.52051%209.04964C10.2317%208.23017%2010.6663%207.16549%2010.6663%206.00016C10.6663%203.43073%208.56911%201.3335%205.99967%201.3335ZM5.99967%202.66683C7.84852%202.66683%209.33301%204.15132%209.33301%206.00016C9.33301%207.84901%207.84852%209.3335%205.99967%209.3335C4.15083%209.3335%202.66634%207.84901%202.66634%206.00016C2.66634%204.15132%204.15083%202.66683%205.99967%202.66683Z%27%20fill%3D%27%23939393%27%2F%3E%3C%2Fsvg%3E") center / contain no-repeat;
    }

    .search-icon-anchor {
        position: relative;
        width: 0;
        height: 0;
        left: 11px;
        top: 11px;
    }

    .search-icon {
        color: #73818A;
        width: 17px;
        height: 17px;
    }

    .admin-categories-filter-grid {
        display: grid;
        grid-template-columns: max-content 287px;
        grid-column-gap: 35px;
    }

    .admin-search-new-container {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 20px;
        width: max-content;
    }

    .admin-new-button {
        width: max-content;
        float: right;
        font-size: 14px;
        padding: 0 23px;
        margin: 0;
    }

    .admin-tabs-container {
        display: inline-flex;
        width: 100%;
        margin-top: 20px;
        flex-wrap: wrap;
    }

    .admin-tab {
        background-color: #4C5459;
        color: white;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 9px;
        margin-right: 2px;
        margin-bottom: 2px;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
    }

    .admin-tab:hover {
        /*color: #1C1F21;*/
        /*background-color: #baff00;*/
        background-color: #343a3d;
        color: #baff00;
    }

    .admin-tab:active {
        color: #1C1F21;
        background-color: #baff00;
    }

    .active-admin-tab {
        color: #1C1F21 !important;
        background-color: #baff00 !important;
    }

    .admin-tab-blue {
        background-color: #0075A9;
        color: white;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 9px;
        margin-right: 2px;
        margin-bottom: 2px;
        cursor: pointer;
        user-select: none;
    }

    .admin-tab-blue:hover {
        background-color: #005275;
        color: white;
    }

    .admin-tab-blue:active {
        color: #1C1F21;
        background-color: #baff00;
    }

    .admin-tab-text {
        font-size: 12px;
    }

    .admin-tab-controls {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-column-gap: 5px;
        margin-left: 10px;
        grid-template-columns: max-content max-content;
    }

    .admin-tab-icon {
        height: 13px;
    }

    .admin-table-container {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 274px);
        margin-top: 2px;


    }

    .admin-table {
        width: 100%;
        font-size: 12px;
        color: #535353;
        margin-top: 2px;
    }

    .admin-table-head-tr {
        text-align: left;
        height: 58px;
    }

    .admin-table-filter-icon {
        position: relative;
        height: 14px;
        top: 2px;
        margin-right: 5px;
        cursor: pointer;
        user-select: none;
        opacity: 0.5;
    }

    .admin-table-filter-icon:hover {
        opacity: 0.75;
    }

    .admin-table-filter-icon:active {
        opacity: 1;
    }

    table, th, td {
        border-top: 1px solid #E8ECEF;
        border-bottom: 1px solid #E8ECEF;
        border-collapse: collapse;
    }

    .admin-table-options-head {
        width: 60px;

    }

    .admin-table-line {
        height: 92px;
    }

    .admin-table-options {
        display: grid;
        grid-template-columns: auto auto;
    }

    .admin-table-option {
        opacity: 0.5;
        cursor: pointer;
        user-select: none;
    }

    .admin-table-option:hover {
        opacity: 0.75;
    }

    .admin-table-option:active {
        opacity: 1;
    }

    .admin-table-image {
        height: 90px;
        width: 160px;
        object-fit: cover;

        /*max-width: 160px;*/
    }

    .admin-card {
        border-radius: 4px;
        margin-bottom: 20px;
    }

    .admin-card-head {
        background-color: #4C5459;
        color: white;
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 12px;
        padding-left: 25px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    .admin-card-body {
        color: #535353;
        font-size: 12px;
        border: 1px solid #E8ECEF;
        border-top: none;
        min-height: 90px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .admin-scrollable-workspace {
        overflow-y: auto;
        overflow-x: hidden;
        display: block;
        height: calc(100vh - 290px);
        padding: 30px 90px 90px 40px;
        width: calc(100% - 131px);
    }

    .admin-logs-container {
        height: 110px;
        width: calc(100% - 1px);
        border-left: 1px solid white;
    }

    .admin-logs-head {
        background-color: #4C5459;
        color: white;
        display: grid;
        grid-template-columns: auto 120px;
        align-items: center;
        height: 26px;
        font-size: 12px;
        padding-left: 30px;
    }

    .admin-logs-body {
        height: 84px;
    }

    .admin-scrollable-logs {
        height: 84px;
        overflow-y: auto;
        overflow-x: hidden;
        display: block;
    }

    .admin-logs-table {
        width: 100%;
        font-size: 12px;
        color: #535353;
    }

    .admin-logs-table-head {
        text-align: left;
        height: 23px;
    }

    .line {
        background-color: #E8ECEF;
    }

    .admin-card-graphs {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: 90px;
        margin: 0 45px;
        width: auto;
        min-height: 90px;
    }

    .admin-graph-container {
        display: grid;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .admin-graph {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-graph-info-anchor {
        position: relative;
        /*width: 0;*/
        /*height: 0;*/
        top: -38px;
        z-index: 2;
    }

    .admin-graph-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 6px;
    }

    .admin-graph-label {
        color: #535353;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 6px;
    }

    .admin-graph-value {
        color: #535353;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-tabs-container-extended {
        display: grid;
        grid-template-columns: auto 140px;
        /*width: 100%;*/
    }

    .blue-link {
        color: #0075A9;
        text-decoration: underline;
        font-size: 12px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }

    .blue-link:hover {
        color: #005275;
    }

    .blue-link:active {
        color: #0075A9;
    }

    .modal-lower-buttons-grid {
        display: grid;
        grid-template-columns: auto max-content;
    }

    .pv-23 {
        padding: 23px 0;
    }

    .pt-23 {
        padding-top: 23px;
    }

    .two-cols-modal-grid {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 40px;
    }

    .modal-form-images-container {
        justify-content: right;
    }

    .modal-form-img-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .modal-form-img-button {
        position: absolute;
        padding: 0 20px;
        margin: 12px;
    }

    .modal-form-img {
        max-height: 240px;
        width: 100%;
        margin-top: 23px;
        object-fit: contain;
    }

    .image-placeholder {
        /*cursor: pointer;*/
    }

    .modal-tabs-container {
        display: inline-flex;
        margin-bottom: 2px;
    }

    .modal-form-imgs-carousel {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .project-modal-tags {
        display: inline-block;
        height: auto;
        width: auto;
    }

    .pill-tag-svg {
        margin: 2px 0 0 5px;
        height: 8px;
        cursor: pointer;
    }

    .green-active:active {
        color: #baff00;
    }

    .modal-tags-grid {
        display: grid;
        grid-template-columns: 60% 40%;
        height: 400px;
    }

    .admin-scrollable-tags {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        display: block;
    }

    .admin-tags-table-head {
        height: 40px;
    }

    .loading-line {
        height: 6px;
        width: 100%;
    }

    .loading-line-progress {
        display: flex;
        background-color: #0075A9;
        height: 100%;
    }

    .loading-page-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 64px);
    }

    .project-tags-menu-head {
        display: flex;
        font-size: 12px;
        align-items: center;
    }

    .tag-input-grid {
        display: grid;
        grid-template-columns: auto max-content;
        grid-column-gap: 10px;
        /*margin: 10px 0;*/
        align-items: center;
        padding-right: 10px;
        width: calc(100% - 30px);
    }

    .tag-input-grid-single {
        display: grid;
        grid-template-columns: auto;
        grid-column-gap: 10px;
        align-items: center;
        margin: 13px 0;
    }

    .tag-input {
        font-size: 14px;
        outline: none;
        border: 1px solid #E8ECEF;
        background-color: #FBFBFB;
        padding: 0 12px;
        display: flex;
        align-items: center;
        height: 38px;
        /*max-width: 145px;*/
    }

    .edit-tag-button {
        width: 20px;
        height: 24px;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;

    }

    .edit-tag-button:hover {
        color: #F2F2F2;
    }

    .edit-tag-button:active {
        color: #e6e6e6;
    }

    .new-category-btn {
        color: white;
        background-color: #0075A9;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        margin: 10px;
        /*padding: 6px;*/
        width: 24px;
        height: 24px;
        user-select: none;
        cursor: pointer;
        border: 1px solid transparent;
    }

    .new-category-btn:hover {
        background-color: #005275;
    }

    .new-category-btn:active {
        background-color: #B4FF00;
        color: #1C1F21;
    }

    .active-new-category-btn {
        background-color: #B4FF00 !important;
        color: #1C1F21 !important;
    }

    .th-double {
        display: grid;
        grid-template-columns: auto max-content;
        grid-column-gap: 10px;
        align-items: center;
    }

    .right-border {
        border-right: 1px solid #EFEFEF;
    }

    .table-data-spacing {
        margin: 15px 0;
    }

    .modal-table-edit-btn {
        padding-left: 30px;
        width: 30px;
        cursor: pointer;
        user-select: none;
    }

    .modal-table-edit-btn:hover {
        opacity: 0.75;
    }

    .modal-table-edit-btn:active {
        opacity: 1;
    }

    .tag-input-grid-sized {
        display: grid;
        grid-template-rows: max-content max-content max-content;
        grid-row-gap: 10px;
        align-items: center;
        padding-right: 10px;
        width: calc(100% - 25px);
        padding-left: 15px;
    }

    .tags-modal-button {
        margin: unset !important;
        width: 100%;
        padding: unset !important;
    }

    .tags-sorting-right-grid {
        display: grid;
        grid-template-rows: auto max-content;
        grid-row-gap: 10px;
    }

    .tag-input-sized {
        font-size: 14px;
        outline: none;
        border: 1px solid #E8ECEF;
        background-color: #FBFBFB;
        padding: 0 12px;
        display: flex;
        align-items: center;
        height: 38px;
        /*width: 85%;*/
    }

    .add-tag-category {
        /*text-decoration: underline;*/
        color: #1C1F21;
        cursor: pointer;
        user-select: none;
        width: max-content;
    }

    .add-tag-category:hover {
        color: black;
    }

    .add-tag-category:active {
        color: #1C1F21;
    }

    .modal-tags-text {
        font-size: 12px;
        color: #535353;
        display: flex;
        margin-right: unset !important;
        align-items: center;
    }

    .blue-workspace-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: #0075A9;
        color:white;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
        border: 1px solid white;
    }

    .blue-workspace-button:hover {
        background-color: #005275;
    }

    .blue-workspace-button:active {
        background-color: #B4FF00;
        color: #1C1F21;
    }

    .active-blue-workspace-button {
        background-color: #B4FF00 !important;
        color: #1C1F21 !important;
    }

    /*.active-blue-workspace-button:hover {*/
    /*    background-color: #AfDD00 !important;*/
    /*    color: #1C1F21 !important;*/
    /*}*/


    .zoom-button {
        position: absolute;
        z-index: 10;
        top: 80px;
        right: 20px;
    }

    .zoom-button-icon {
        width: 30px;
        height: 30px;
    }














    /* END METALL PROFIL*/








    .red-rectangle-button-of-love {
        border: 2px solid #D83336;
        padding: 10px 25px 10px 25px;
        background-color: #D83336;
        color: white;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }

    .red-rectangle-button-of-love:hover {
        background-color: #b82325;
        border-color: #b82325;
    }

    .red-rectangle-button-of-love:active {
        background-color: #ad0e27;
        border-color: #ad0e27;
    }

    .red-pill-button-of-hate {
        border-radius: 50px;
        border: 2px solid #D83336;
        background-color: #D83336;
        color: white;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }

    .red-pill-button-of-hate:hover {
        background-color: #b82325;
        border-color: #b82325;
    }

    .red-pill-button-of-hate:active {
        background-color: #ad0e27;
        border-color: #ad0e27;
    }

    .disabled-pill-button-of-hate {
        border-radius: 50px;
        border: 2px solid #ACACAC;
        background-color: #ACACAC;
        color: white;
        font-weight: bold;
        text-align: center;
        cursor: not-allowed;
        user-select: none;
    }

    .grey-outline-pill-button {
        border-radius: 50px;
        border-radius: 50px;
        border: 2px solid #939393;
        padding: 10px 25px 10px 25px;
        color: #939393;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }

    .grey-outline-pill-button:hover {
        color: #838383;
        border-color: #838383;
    }

    .grey-outline-pill-button:active {
        color: #737373;
        border-color: #737373;
    }

    .bold-text {
        font-weight: bold;
    }

    .sidebar-main-label-grid {
        display: grid;
        grid-template-columns: auto 40px;
        grid-column-gap: 10px;
    }

    .sidebar-main-label {
        font-weight: bold;
        color: #303030;
    }

    .collapsable-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .sidebar-step-two-img {
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    .lb-grid {
        display: grid;
        grid-template-columns: auto max-content;
    }

    #projects-page {
        display: block;
        width: 100%;
        height: 100%;
    }

    .projects-page-outer {
        display: block;
        width: 100%;
        height: 100%;
    }

    .is-blurred {
        background-size: cover;
        -webkit-filter: blur(0.3em);
        -moz-filter: blur(0.3em);
        -ms-filter: blur(0.3em);
        -o-filter: blur(0.3em);
        filter: blur(0.3em);
    }

    @media screen and (max-height: 800px) {
        #configurator-group {
            display: inline-flex;
            height: calc(100% - 62px);
            box-sizing: border-box;
        }
        .step-text{
            font-size: 12px;
        }
        .sidenav{
            width: 240px;
        }
        .sidebar-main-label{
            font-size: 16px;
        }
        #workspace {
            width: calc(100vw - 320px);
            height: calc(100% - 55px);
        }

        #workspace-lite {
            width: calc(100vw - 320px);
            height: 100%;
        }

        #workspace-lite-wide {
            width: 100vw;
            height: 100%;
        }

        #workspace-ap {
            width: calc(100vw - 320px);
            height: calc(100vh - 80px);
        }

        .workspace-admin-scroll-part {
            height: calc(100vh - 201px);
        }
        .grey-outline-pill-button {
            height: 20px;
            font-size: 16px;
            padding: 8px 23px 10px 23px;
        }
        .red-pill-button-of-hate {
            height: 20px;
            font-size: 16px;
            padding: 8px 23px 10px 23px;
        }
        .disabled-pill-button-of-hate {
            height: 20px;
            font-size: 16px;
            padding: 8px 23px 10px 23px;
        }
        .extra-left-margin {
            margin-left: 10px;
        }
        .lb-btn-container {
            padding: 5px 10px;
        }
        .photo-lower-bar-button-group {
            padding-left: 5px;
            font-size: 14px;
        }
        .photo-lower-bar-button {
            padding: 12px 12px;
        }
        .photo-lower-bar-button-custom {
            padding: 12px 12px;
        }
        .sidebar-step-two-img-container {
            margin: 10px 0;
        }
        .sidebar-text-area {
            margin: 10px 0;
            font-size: 12px;
        }
        .sidebar-step-two-img {
            max-height: 200px;
        }
        .red-rectangle-button-of-love {
            height: 25px;
            font-size: 14px;
        }
        .lb-svg {
            height: 20px;
            width: auto;
        }
        .lb-svg-rotate {
            height: 24px;
            width: auto;
        }

        .lite-navbar-button {
            margin-top: 10px;
        }
    }

    @media screen and (min-height: 800px) {
        #configurator-group {
            display: inline-flex;
            height: calc(100% - 82px);
            box-sizing: border-box;
        }
        .step-text{
            font-size: 15px;
        }
        .sidenav{
            width: 320px;
        }
        .sidebar-main-label{
            font-size: 23px;
        }
        #workspace {
            width: calc(100vw - 420px);
            height: calc(100% - 70px);
        }

        #workspace-lite {
            width: calc(100vw - 320px);
            height: 100%;
        }

        #workspace-lite-wide {
            width: 100vw;
            height: 100%;
        }

        #workspace-ap {
            width: calc(100vw - 320px);
            height: calc(100vh - 80px);
        }

        .workspace-admin-scroll-part {
            height: calc(100vh - 201px);
        }

        .grey-outline-pill-button {
            height: 25px;
            font-size: 18px;
            padding: 10px 25px 10px 25px;
        }
        .red-pill-button-of-hate {
            height: 25px;
            font-size: 18px;
            padding: 10px 25px 10px 25px;
        }
        .disabled-pill-button-of-hate {
            height: 25px;
            font-size: 18px;
            padding: 10px 25px 10px 25px;
        }
        .extra-left-margin {
            margin-left: 20px;
        }
        .lb-btn-container {
            padding: 11px 30px;
        }
        .photo-lower-bar-button-group {
            padding-left: 40px;
        }
        .photo-lower-bar-button {
            padding: 22px 20px;
        }
        .photo-lower-bar-button-custom {
            padding: 22px 20px;
        }
        .sidebar-step-two-img-container {
            margin: 40px 0;
        }
        .sidebar-text-area {
            margin: 20px 0;
            font-size: 12px;
        }
        .sidebar-step-two-img {
            max-height: 450px;
        }
        .red-rectangle-button-of-love {
            height: 25px;
            font-size: 18px;
        }
        .lb-svg {
            height: 24px;
            width: auto;
        }
        .lb-svg-rotate {
            height: 28px;
            width: auto;
        }
    }


    .lb-btn-container {
        display: inline-flex;
        justify-content: flex-end;
    }

    .lb-btn-single-center-container {
        padding: 11px 30px;
        display: inline-flex;
        justify-content: center;
    }

    .no-left-padding {
        padding-left: 0 !important;
    }

    .no-right-padding {
        padding-right: 0 !important;
    }

    .extra-left-padding {
        padding-left: 10px;
    }


    .sidebar-menu-container {
        margin: 20px;
        height: auto;
        /*overflow-x: hidden;*/
        /*overflow-y: auto;*/
    }

    .sidebar-sub-label {
        margin: 20px 0;
        font-size: 18px;
        font-weight: bold;
    }

    .sidebar-text {
        margin: 20px 0;
        font-size: 14px;
    }

    .sidenav {
        height: 100%;
        border-right: 2px solid #F2F2F2;
        z-index: 9;
        background-color: white;
        overflow-x: hidden;
        box-sizing: border-box;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .sidenav-lite {
      overflow-y: hidden;
    }

    .lite-sidebar-upper {
      width: 100%;
      height: calc(100% - 70px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .lite-sidebar-lower {
      width: 100%;
      height: 70px;
      overflow: hidden;
    }

    .workspace-module-container {
        display: block;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }



    #lower-bar {
        border-top: 2px solid #F2F2F2;
    }

    .photo-lower-bar-button-group {
        white-space: nowrap;
        display: inline-flex;
        /*justify-content: center;*/
        align-items: center;
    }

    .photo-lower-bar-button {
        display: inline-flex;
        color: #939393;
        cursor: pointer;
        user-select: none;
    }

    .photo-lower-bar-button:hover {
        color: #808080;
    }

    .photo-lower-bar-button:active {
        color: #707070;
    }

    .projects-lite-lower-bar-button {
        display: inline-flex;
        padding: 22px 20px;
        color: #939393;
        cursor: pointer;
        user-select: none;
    }

    .projects-lite-lower-bar-button:hover {
        color: #808080;
    }

    .projects-lite-lower-bar-button:active {
        color: #707070;
    }

    .photo-lower-bar-button-text {
        position: relative;
        /*bottom: 4px;*/
        /*padding-bottom: 5px;*/
        /*font-size: 20px;*/
        font-weight: bold;
    }

    .photo-lower-bar-not-button-text {
        position: relative;
        bottom: 4px;
        /*font-size: 20px;*/
        font-weight: bold;
    }

    .photo-lower-bar-not-button {
        display: inline-flex;
        padding: 22px 20px;
        color: #939393;
        user-select: none;
    }

    .planes-dropdown-label {
        margin: 15px 15px 15px 0;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        user-select: none;
        display: inline-flex;
    }

    .planes-dropdown-label-static {
        margin: 15px;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        user-select: none;
        display: inline-flex;
    }

    .planes-dropdown-label:hover {
        color: #404040;
    }

    .planes-dropdown-label:active {
        color: #303030;
    }

    .planes-dropdown-list {
        margin: 20px;
    }

    .planes-dropdown-list-item {
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0;
        cursor: pointer;
        user-select: none;
        display: grid;
        grid-template-columns: max-content auto;
        grid-column-gap: 10px;
    }

    .planes-dropdown-list-item:hover {
        color: #d83336;
    }

    .planes-dropdown-list-item:active {
        color: #b82325;
    }

    .planes-dropdown-group-inner-item {
        font-size: 18px;
        padding: 10px 10px;
        cursor: pointer;
        user-select: none;
        /*background-color: #F2F2F2;*/
        display: grid;
        grid-template-columns: max-content auto;
        grid-column-gap: 10px;
        border-left: 2px solid transparent;
    }

    .planes-dropdown-group-inner-item:hover {
        color: #d83336;
        border-left: 2px solid #d83336;
        z-index: 100;
    }

    .planes-dropdown-group-inner-item:active {
        color: #b82325;
        border-left: 2px solid #b82325;
    }

    .active-photo {
        border: 2px solid #d83336 !important;
    }

    .project-preview {
        display: inline-block;
        background-color: white;
        margin: 10px;
        width: 300px;
        height: 195px;
        border-radius: 4px;
        border: 2px solid #f2f2f2;
        user-select: none;
    }

    .project-preview:hover {
        border: 2px solid #bdbdbd;
    }

    .project-preview:active {
        border: 2px solid #d83336;
    }

    .project-preview-sidebar {
        display: block;
        background-color: white;
        width: 276px;
        height: 165px;
        border-radius: 4px;
        border: 2px solid #f2f2f2;
        margin-bottom: 20px;
    }

    .active-project {
        border: 2px solid #d83336 !important;
    }

    .project-preview-image {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        object-fit: cover;
        object-position: 0 0;
    }

    .project-preview-text-container {
        position: relative;
        bottom: 44px;
        height: 30px;
        padding-left: 15px;
        padding-top: 10px;
        background: rgba(0, 0, 0, 0.1);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        border-radius: 0 0 2px 2px;
        color: white;
        font-size: 14px;
    }

    .sidebar-borderline {
        margin: 15px 0;
        border-bottom: 2px solid #F2F2F2;
    }

    .modal-borderline-thin {
        margin: 2px 0;
        border-bottom: 1px solid #F2F2F2;
    }

    .modal-borderline {
        margin: 15px 30px;
        border-bottom: 2px solid #F2F2F2;
    }

    .modal-grid-two-cols {
        display: grid;
        grid-template-columns: 50% 50%;
        /*grid-column-gap: 10px;*/
        /*grid-row-gap: 10px;*/
    }

    .stripe {
        background-color: #F2F2F2;
    }

    .important-text {
        color: #d83336;
    }

    .sidebar-project-img {
        width: 100%;
        height: auto;
        border-radius: 2px;
        border: 2px solid white;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
    }

    .sidebar-project-img:hover {
        border: 2px solid #ACACAC;
    }

    .sidebar-project-img:active {
        border: 2px solid #d83336;
    }

    /*    Modal*/

    .modal-img-container {
        width: 100%;
        height: auto;
    }

    .modal-img {
        width: 100%;
        max-height: 250px;
    }

    .modal-close-btn-point {
        position: absolute;
        /*bottom: 265px;*/
        top: 9px;
        /*left: 530px;*/
        right: 59px;
        width: 0;
        height: 0;
    }

    .modal-close-btn {
        cursor: pointer;
    }
    .modal-close-btn:hover .modal-close-circle {
        fill: #b82325;
    }

    .modal-close-btn:active .modal-close-circle {
        fill: #ad0e27;
    }

    .modal-title {
        margin: 30px;
        font-weight: bold;
        font-size: 20px;
        color: #303030;
    }

    .modal-close-btn-container{
        display: flex;
        width: max-content;
        height: max-content;
        justify-content: center;
        align-items: center;
    }

    .modal-title-big {
        padding: 30px;
        font-weight: bold;
        font-size: 22px;
        color: #303030;
    }

    .modal-text {
        margin: 30px;
        font-size: 16px;
    }

    .modal-list {
        margin: 20px 10px;
        padding: 0;
        /*font-size: 18px;*/
    }

    .modal-text-input {
        width: calc(100% - 82px);
        padding: 10px;
        margin: 10px 30px;
        border: 1px solid #BDBDBD;
    }

    .modal-photo-carousel {
        display: flex;
        justify-content: safe center;
        margin: 0 30px;
        /*width: 100%;*/
        height: 220px;
        /*overflow-x: auto;*/
        /*overflow-y: hidden;*/
    }

    .modal-photo-carousel-element {
        display: inline;
        width: auto;
        height: 200px;
        margin-right: 10px;
    }

    .modal-button {
        background-color: #383838;
        padding: 10px 20px;
        margin: 30px;
        color: white;
        font-weight: bold;
        width: max-content;
        cursor: pointer;
        user-select: none;
        text-align: center;
        font-size: 14px;
    }

    .modal-button-auto-width {
        width: auto;
    }

    .modal-button:hover {
        background-color: #404040;
    }

    .modal-button:active {
        background-color: #383838;
    }

    .cd-modal-grid {
        display: grid;
        grid-template-columns: auto auto;
        margin: 20px 10px;
    }

    .cd-modal-grid-element {
        margin: 0 20px;
    }

    .cd-modal-text {
        font-size: 18px;
    }

    ul {
        list-style: none;
        padding-left: 20px;
    }

    .modal-list::before {
        content: "•";
        color: #d83336;
    }

    .cd-modal-list {
        margin: 10px;
        padding: 0;
        /*font-size: 16px;*/
    }

    .cd-modal-list::before {
        content: "•";
        color: #d83336;
    }

    .dnd-container {
        text-align: center;
        cursor: pointer;
    }

    .dnd-container-dragover {
        background-color: darkgray;
    }

    .cd-modal-text-input {
        width: available;
        width: -moz-available;
        width: -webkit-fill-available;
        padding: 10px;
        border: 1px solid #BDBDBD;
    }

    .cd-select {
        color: #767676;
    }

    .cd-modal-text-input:hover {
        border-color: #828282;
    }

    .cd-modal-text-input:focus {
        border-color: #828282;
    }

    .red-text {
        color: #D83336;
    }

    .add-padding {
        padding: 0 20px;
    }

    .ap-modal-element {
        margin: 10px 30px
    }

    .no-margin-top {
        margin-top: 0;
    }

    .ap-product-modal-img {
        display: block;
        margin: 5px 30px;
        width: 200px;
        height: 134px;
        object-fit: cover;
    }

    .ap-product-modal-button {
        width: 180px;
        margin: 5px 30px;
        text-align: center;
    }

    .ap-product-modal-button-thin {
        width: 180px;
        text-align: center;
    }

    .ap-red-text-field {
        background-color: #f2f2f2;
        font-weight: bold;
        padding: 5px 10px;
        text-align: center;
        font-size: 16px;
        width: 179px;
        border-left: 2px solid #e4373a;
    }

    .ap-modal-select {
        display: block;
        /*font-size: 18px;*/
        width: 198px;
        height: 38px;
        padding: 10px;
    }

    .extra-top-margin {
        margin-top: 20px;
    }

    .ap-loader-grid {
        display: grid;
        grid-template-columns: 40px max-content;
        width: max-content;
        margin: 30px auto;
    }

    .ap-loader-text {
        margin: -7px 0;
        font-size: 18px;
        font-weight: bold;
        color: #626262;
    }

    .ap-table-uuid-holder {
        padding: 10px;
        background-color: #626262;
        color: white;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        display: grid;
        grid-template-columns: auto auto;
        width: 61px;
        -webkit-transition: background-color 0.2s ease-out;
        -moz-transition: background-color 0.2s ease-out;
        -o-transition: background-color 0.2s ease-out;
        transition: background-color 0.2s ease-out
    }

    .ap-table-uuid-holder:hover {
        background-color: #d83336;
    }

    .profile-btn-grid {
        display: grid;
        grid-template-columns: auto auto;
        margin: 30px;
    }

    .confirmation-btn-grid {
        display: grid;
        grid-template-columns: auto auto;
        margin: 30px;
        grid-column-gap: 10px;
    }

    .profile-btn {
        margin: 0 5px 5px 0;
        width: auto;
    }

    .button-logout {
        margin: 0 5px 5px 0;
        background-color: #e4373a;
        width: 140px;
    }

    .button-logout:hover {
        background-color: #8a0a1f;
    }

    .no-right-margin {
        margin-right: 0 !important;
    }

    .hide-underline {
        text-decoration: none;
    }

    .form-alert-danger{
        font-size: 13px;
        color: #d83336;
        /*margin: 5px 30px;*/
        margin: 7px 0
    }

    .modal-img-block {
        display: inline-block;
        margin: 5px;
        width: 131px;
        height: 74px;
        border-radius: 4px;
        border: 2px solid #f2f2f2;
    }

    .modal-img-block:hover {
        border: 2px solid #bdbdbd;
    }

    .modal-img-block:active {
        border: 2px solid #d83336;
    }

    .modal-img-block-image {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        object-fit: cover;
        object-position: 0 0;
    }

    .ap-modal-title-big {
        padding: 21px 30px;
        font-weight: bold;
        font-size: 22px;
        color: #303030;
    }

    .ap-modal-input-name {
        position: relative;
        font-size: 14px;
        background-color: white;
        color: #939393;
        padding: 2px;
        top: 11px;
        left: 7px;
        width: max-content;
        z-index: 2;
    }

    .ap-button-edit-category {
        display: inline-flex;
        color: #303030;
        cursor: pointer;
        user-select: none;
    }

    .ap-button-edit-category-svg {
        color: #ACACAC;
    }

    .ap-button-edit-category-text {
        padding: 0 10px;
    }

    .ap-button-edit-category:hover .ap-button-edit-category-svg {
        color: #727272;
    }

    .ap-button-edit-category:active .ap-button-edit-category-svg {
        color: #A3A3A3;
    }

    .ap-modal-title-medium {
        /*font-size: 18px;*/
        font-weight: bold;
        margin: 15px 30px 5px 30px;
    }

    .ap-product-modal-grid {
        display: grid;
        grid-template-columns: 60% 40%;
        grid-column-gap: 0;
    }

    .ap-change-photo-anchor {
        position: relative;
        top: 10px;
        left: 195px;
        width: 0;
        height: 0;
    }

    .ap-change-photo-btn{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ffffff;
        /*opacity: 0.2;*/
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ACACAC;
    }

    .ap-change-photo-btn:hover {
        cursor: pointer;
        color: #727272;
    }
    .ap-change-photo-svg {
        /*opacity: 1;*/
        /*width: 70%;*/
    }

    .ap-modal-text {
        margin: 10px 0;
        color: #303030;
    }

    .vs__dropdown-menu {
        /*z-index: 4000 !important;*/
        position: relative;
        display: block;
        /*overflow: visible;*/
    }

    .edit-project-name-container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ACACAC;
        cursor: pointer;
    }

    .edit-project-name-container:hover {
        color: #A3A3A3;
    }

    .edit-project-name-container:active {
        color: #ACACAC;
    }

    .photo-sidebar-rename-project {
        padding: 7px 10px 7px 10px;
        border: 1px solid #BDBDBD;
        width: -moz-available;
        width: available;
        width: -webkit-fill-available;

    }

    .photo-sidebar-rename-project:hover {
        border-color: #828282;
    }

    .photo-sidebar-rename-project:focus {
        border-color: #828282;
    }

    .lb-checkbox {
        position: relative;
        margin: auto;
    }

    .temp-btn {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9999;
    }

    .svg-configurator-edit {
        width: 24px;
        height: 24px;
    }

</style>
