import axios from "axios"
import {config} from "./config";
import store from "./store";


const checkPermissions = function (data) {
    if (typeof data === 'string') {
        try {
            data = JSON.parse(data);
            if (data.error === "Token expired" || data.error === "Not valid token" || data.error === "Token is empty") {
                store.dispatch('profile/USER_UNAUTHORIZED');
            }
            if (data.error === "Access not permitted for this action") {
                console.log('Нет прав!!!');
            }
            if ('token' in data) {
                localStorage.token = data.token;
                delete data.token;
                store.commit('profile/USER_AUTHORIZED', true);
            }
        } catch (e) { /* Ignore */
        }
    }
    return data;
}

const addAuthorizationData = function (data, headers) {
    headers.Authorization = "Bearer " + localStorage.token;
    // store.commit('ADD_NEW_REQUEST_PENDING');
    if (headers['Content-Type'] === "application/json") {
        return JSON.stringify(data);
    }
    return data;
}

export const http_request = axios.create({
    headers: {
        "Content-Type": "application/json"
    },
    baseURL: config.urlForRequest,
    transformRequest: [addAuthorizationData],
    transformResponse: [checkPermissions],
});

// http_request.interceptors.response.use(function (response) {
//     store.commit('DELETE_REQUEST_PENDING');
//     return response;
// }, function (error) {
//     // Обрабатываем ошибку
//     store.commit('DELETE_REQUEST_PENDING');
//     return Promise.reject(error);
// });

export const uploadFileRequest = (data, callbackLoadProgress) => {
    return http_request.post('/save_file', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            transformRequest: [addAuthorizationData],
            transformResponse: [checkPermissions],
            onUploadProgress: (progressEvent) => {
                let uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                if (callbackLoadProgress)
                    callbackLoadProgress(uploadPercentage);
            },
            // timeout: 30000
        }
    )
        .then((e) => {
            console.log(e);
            return e;
        })
};

export const uploadFirstFile = (files, callbackLoadProgress) => {
    let formData = new FormData();
    formData.append(files.target.files[0].name, files.target.files[0]);

    return uploadFileRequest(formData, callbackLoadProgress);
}

export const uploadFiles = (files, callbackLoadProgress) => {
    let formData = new FormData();
    for (let fileIndex in files) {
        formData.append(files[fileIndex].name, files[fileIndex]);
    }

    return uploadFileRequest(formData, callbackLoadProgress);
}

export const updatePhotoAnother =  async (inputData) => {
    console.log(">>>", inputData)
    const formData = new FormData();
    formData.append('imageFile', inputData, 'imageFile.jpg');
    console.log(formData);
    return uploadFileRequest(formData);
}

export const updatePhoto =  async (inputData) => {
    console.log(">>>", inputData)
    const formData = new FormData();
    formData.append('imageFile', inputData.file, 'imageFile.jpg');
    console.log(formData);

    const progress = (progress) => {
        store.commit('PROGRESS_PERCENT', progress);
    };

    await uploadFileRequest(formData, progress)
        .then((request) => {
            console.log(request.data);
            store.dispatch(`projectList/${inputData.projectUUID}/photoList/REPLACE_PHOTO`, {
                idPhoto: inputData.photoID,
                srcPhoto: request.data.fileList[0]
            });
            // store.commit(`projectList/${inputData.projectUUID}/photoList/${inputData.photoID}/SRC`, request.data.fileList[0]);
        })
        .catch((error) => {
            console.log(error);
        })
}
