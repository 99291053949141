var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("WorkspaceLoader", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ]
      }),
      _vm._v(" "),
      _c("TheModalLite"),
      _vm._v(" "),
      _c("TheLiteNavbar"),
      _vm._v(" "),
      _c("div", { staticClass: "application-body" }, [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }