var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "31",
        height: "20",
        viewBox: "0 0 31 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M16.815 6.1101V0.867188H24.2035L26.4704 7.63224H14.9678V0.867188H7.91517L5.39636 7.63224H12.6169V9.23894H4.80864L1.36627 18.8791H14.9678V9.23894H26.8902L30.2486 18.8791H16.815V11.6067",
          stroke: "CurrentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }