var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-background" }, [
    _c("div", { staticClass: "modal-head-grid" }, [
      _c("div", { staticClass: "modal-label" }, [
        _vm._v("\n            Добавить\n        ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-close-btn-mp",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("SVGClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container pv-23" }, [
      _c("div", { staticClass: "two-cols-modal-grid" }, [
        _c("div", { staticClass: "modal-form-grid" }, [
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Название\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Ширина\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.width,
                expression: "width"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.width },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.width = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Высота\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.height,
                expression: "height"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.height },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.height = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Цвет (#ffffff)\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.hex,
                expression: "hex"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.hex },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.hex = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-form-img-container" }, [
          _c("img", {
            staticClass: "modal-form-img",
            class: { "image-placeholder": _vm.image === null },
            attrs: { src: _vm.getPhoto() }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "blue-button modal-form-img-button",
              on: {
                click: function($event) {
                  return _vm.$refs.previewFile.click()
                }
              }
            },
            [_vm._v("\n                    Изменить\n                ")]
          ),
          _vm._v(" "),
          _c("input", {
            ref: "previewFile",
            attrs: { type: "file", accept: ".jpg,.png", hidden: "" },
            on: {
              change: function($event) {
                return _vm.handleFileUpload($event)
              }
            }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-lower-buttons-grid" }, [
        _c(
          "div",
          {
            staticClass: "blue-button modal-accept-button",
            on: { click: _vm.save }
          },
          [_vm._v("Сохранить\n            ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blue-outline-button modal-accept-button",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v("Отмена")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }