<template>
    <div class="admin-workspace-container">
        <div class="admin-search-new-container">
            <div>
                <div class="search-icon-anchor">
                    <SVGSearch class="search-icon"/>
                </div>
                <input class="modal-form-grid-input search-input" type="text" placeholder="Найти проект" v-model="mask">
            </div>
            <div class="blue-button admin-new-button">Добавить новый</div>
        </div>

        <div class="admin-tabs-container-extended">
            <div class="admin-tabs-container">
                <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'common'}"
                     v-on:click="switchAdminTab('common')">
                    <SVGPeople style="margin-right: 12px; width: 16px;"/>
                    <div class="admin-tab-text">Общие</div>
                </div>
                <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'private'}"
                     v-on:click="switchAdminTab('private')">
                    <SVGLock style="margin-right: 12px; height: 16px;"/>
                    <div class="admin-tab-text">Частные</div>
                </div>
                <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'self'}"
                     v-on:click="switchAdminTab('self')">
                    <SVGWorker style="margin-right: 12px; height: 16px;"/>
                    <div class="admin-tab-text">Мои проекты</div>
                </div>
            </div>
            <div class="blue-link" v-on:click="editTags">
                настроить теги
                <SVGTags style="margin-left: 5px"/>
            </div>
        </div>


        <vue-custom-scrollbar class="admin-table-container">
            <table class="admin-table">
                <thead>
                <tr class="admin-table-head-tr">
                    <th>№</th>
                    <th>Изображение</th>
                    <th>Название</th>
                    <th>Создан</th>
                    <th>Отредактирован</th>
                    <th>Доступ в Lite</th>
                    <th class="admin-table-options-head">Опции</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(value, index) in projects" class="admin-table-line">
                    <td>
                        <span class="">{{index + 1}}</span>
                    </td>
                    <td>
                        <PreviewImgFromProject :dataProject="value.data_json" class="admin-table-image"/>
                        <!--                        <img class="admin-table-image" :src="urlForStatic('/img/projectPreviewsDemo/house3.jpg')"/>-->
                    </td>
                    <td>
                        <span>{{value.name}}</span>
                    </td>
                    <td>
                        <!--                        <div>{{value.creator}}</div>-->
                        <div>{{dateIntoRu(value.create_date)}}</div>
                    </td>
                    <td>
                        <!--                        <div>{{value.editor}}</div>-->
                        <div>{{dateIntoRu(value.update_date)}}</div>
                    </td>
                    <td>
                        <span>{{value.open_read}}</span>
                    </td>
                    <td>
                        <div class="admin-table-options">
                            <div class="admin-table-option" v-on:click="editRow(value)">
                                <SVGEdit/>
                            </div>
                            <div class="admin-table-option" v-on:click="deleteRow(value)">
                                <SVGClose/>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

        </vue-custom-scrollbar>
    </div>
</template>

<script>
    import SVGSearch from "../../../SVG/SVGSearch";
    import SVGEdit from "../../../SVGMP/SVGEdit";
    import SVGClose from "../../../SVGMP/SVGClose";
    import {urlForStaticVue} from "../../../common";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import SVGPeople from "../../../SVGMP/SVGPeople";
    import SVGLock from "../../../SVGMP/SVGLock";
    import SVGWorker from "../../../SVGMP/SVGWorker";
    import SVGTags from "../../../SVGMP/SVGTags";
    import ModalProject from "../../../ModalMP/ModalAdmin/ModalProject";
    import ModalTags from "../../../ModalMP/ModalAdmin/ModalTags";
    import ModalAttention from "../../../ModalMP/ModalAdmin/ModalAttention";
    import {http_request} from "../../../../httpConfig";
    import PreviewImgFromProject
        from "../../../LiteVersionMP/Components/Projects/SidebarProjects/PreviewImgFromProject";

    export default {
        name: "AdminWorkspaceProjects",
        mixins: [urlForStaticVue],
        components: {
            PreviewImgFromProject,
            SVGTags,
            SVGWorker,
            SVGLock,
            SVGPeople,
            SVGClose,
            SVGEdit,
            SVGSearch,
            vueCustomScrollbar
        },
        data() {
            return {
                projects: [],
                activeAdminTab: 'common',
                mask: '',
                timerId: null,
                requestInProgress: false,
                allProjectsLoaded: false
            }
        },
        methods: {
            scrollReachEnd() {
                this.getProjects();
            },
            urlForPhoto(url) {
                return this.$store.getters[`projectConfiguration/GENERATE_URL`](url);
            },
            dateIntoRu(inputDate) {
                let date = new Date(inputDate).toLocaleString('ru', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
                return date;
            },
            updateProject(project) {
                const indexProject = this.projects.map(proj => proj.uuid).indexOf(project.uuid);
                if (indexProject > -1) {
                    this.projects.splice(indexProject, 1, project);
                }
            },
            deleteProject(projectUuid) {
                return () => {
                    http_request.post('/delete_project', {uuid: projectUuid})
                        .then((request) => {
                            console.log(request.data);
                            const indexProject = this.projects.map(proj => proj.uuid).indexOf(projectUuid);
                            if (indexProject > -1) {
                                this.projects.splice(indexProject, 1);
                            }
                        })
                }
            },
            editRow(projectData) {
                this.$modal.show(ModalProject, {
                    project: projectData,
                    callback: this.updateProject
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 775,
                });
            },
            deleteRow(project) {
                this.$modal.show(ModalAttention,
                    {
                        alertMsg: `Вы уверены, что хотите удалить проект "${project.name}"?`,
                        callback: this.deleteProject(project.uuid)
                    }, {
                        height: 'auto',
                        pivotY: 0.2,
                        width: 515,
                    });
            },
            editTags() {
                this.$modal.show(ModalTags, {}, {
                    height: 560,
                    pivotY: 0.2,
                    width: 815,
                });
            },
            switchAdminTab(tabName) {
                this.activeAdminTab = tabName
            },
            callbackForProjects(data) {
                const {newProjects, mask, projectsLength} = data;
                if (mask === this.mask
                    && projectsLength === this.projects.length) {
                    if (newProjects.length === 0) {
                        this.allProjectsLoaded = true;
                        return;
                    }
                    this.projects.push(...newProjects);
                } else {
                    console.warn('Пришли уже неактуальные данные.', newProjects)
                }
            },
            async getProjects() {
                if (this.requestInProgress || this.allProjectsLoaded) {
                    return;
                }

                const projectsLength = this.projects.length;
                const mask = this.mask;
                const activeAdminTab = this.activeAdminTab;

                let dataForSend = {
                    part: {
                        from: projectsLength,
                        count: 15,
                    },
                    find: {
                        mask: mask
                    }
                };
                console.log(dataForSend);
                this.requestInProgress = true;
                if (this.activeAdminTab === 'common') {
                    await http_request.post('/get_project_lite', dataForSend)
                        .then((request) => {
                            console.log(request.data);
                            this.callbackForProjects({
                                newProjects: request.data.projects,
                                mask,
                                activeAdminTab,
                                projectsLength
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            console.log(error.response);
                        });
                } else if (this.activeAdminTab === 'private') {
                    await http_request.post('/get_project_other', dataForSend)
                        .then((request) => {
                            console.log(request.data);
                            this.callbackForProjects({
                                newProjects: request.data.projects,
                                mask,
                                activeAdminTab,
                                projectsLength
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            console.log(error.response);
                        });
                } else if (this.activeAdminTab === 'self') {
                    await http_request.post('/get_project', dataForSend)
                        .then((request) => {
                            console.log(request.data);
                            this.callbackForProjects({
                                newProjects: request.data.projects,
                                mask,
                                activeAdminTab,
                                projectsLength
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            console.log(error.response);
                        });
                }
                this.requestInProgress = false;
            }
        },
        watch: {
            activeAdminTab() {
                this.projects.length = 0;
                this.requestInProgress = false;
                this.allProjectsLoaded = false;
                this.getProjects();
            },
            mask() {
                this.projects.length = 0;
                this.requestInProgress = false;
                this.allProjectsLoaded = false;
                if (this.timerId) {
                    clearTimeout(this.timerId);
                    this.timerId = null;
                }
                this.timerId = setTimeout(() => {
                    this.getProjects();
                    this.timerId = null;
                }, 500);
            }
        },
        mounted() {
            this.getProjects();
        }
    }
</script>

<style scoped>

</style>

