export const activePlane = {
    namespaced: false,
    state: {},
    getters: {
        PERSPECTIVE_POINTS: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const photoID = rootState.route.params.photoID;
            const idActivePlane = rootState.route.params.planeID;
            if (projectUUID && photoID && idActivePlane) {
                return rootGetters[`projectList/${projectUUID}/photoList/${photoID}/planeList/${idActivePlane}/PERSPECTIVE_POINTS`];
            } else {
                return null;
            }
        },
        POINTS: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const photoID = rootState.route.params.photoID;
            const idActivePlane = rootState.route.params.planeID;
            if (projectUUID && photoID && idActivePlane) {
                return rootGetters[`projectList/${projectUUID}/photoList/${photoID}/planeList/${idActivePlane}/POINTS`];
            } else {
                return null;
            }
        },
        PLANE_BY_ID: (state, getters, rootState, rootGetters) => (id) => {
            const projectUUID = rootState.route.params.projectUUID;
            const photoID = rootState.route.params.photoID;
            if (projectUUID && photoID) {
                return rootGetters[`projectList/${projectUUID}/photoList/${photoID}/planeList/PLANE_BY_ID`](id);
            } else {
                return null;
            }
        },
        ACTIVE_PLANE: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const photoID = rootState.route.params.photoID;
            const planeID = rootState.route.params.planeID;
            if (projectUUID && photoID) {
                return rootGetters[`projectList/${projectUUID}/photoList/${photoID}/planeList/PLANE_BY_ID`](planeID);
            } else {
                return null;
            }
        },
        TAKE_INTO_CALCULATIONS: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const photoID = rootState.route.params.photoID;
            const planeID = rootState.route.params.planeID;
            return rootGetters[`projectList/${projectUUID}/photoList/${photoID}/planeList/${planeID}/TAKE_INTO_CALCULATIONS`];
        },
        NUMBER_PLANES_BY_TYPE: (state, getters, rootState, rootGetters) => {
            let planeCountedByType = {};
            const projectUUID = rootState.route.params.projectUUID;
            const photoList = rootGetters[`projectList/${projectUUID}/photoList/PHOTOS`];
            for(let idPhoto in photoList){
                const photo = photoList[idPhoto];
                for(let idPlane in photo.planeList){
                    const plane = photo.planeList[idPlane];
                    if(plane.takeIntoCalculations && plane.type){
                        if(planeCountedByType[plane.type])
                            planeCountedByType[plane.type]++;
                        else
                            planeCountedByType[plane.type] = 1;
                    }
                }
            }
            return planeCountedByType;
        }
    },
    mutations: {},
    actions: {
        TAKE_INTO_CALCULATIONS: function (context, stateFlag) {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            const idActivePlane = context.rootState.route.params.planeID;
            context.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/TAKE_INTO_CALCULATIONS`, stateFlag, {root: true});
        },
        SAVE_PERSPECTIVE_POINTS: function (context, perspectivePoints) {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            const idActivePlane = context.rootState.route.params.planeID;
            context.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/PERSPECTIVE_POINTS`, perspectivePoints, {root: true});
        },
        SET_ID_DESIGN_GROUP_ACTIVE_PLANE: (context, idDesignGroup) => {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            const idActivePlane = context.rootState.route.params.planeID;
            context.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/ID_DESIGN_GROUP`, idDesignGroup, {root: true});
        },
        IS_COMPLETE: (context, flag) => {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            const idActivePlane = context.rootState.route.params.planeID;
            if (flag !== undefined) {
                return context.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/IS_COMPLETE`, flag, {root: true});
            } else {
                return context.rootGetters[`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/IS_COMPLETE`];
            }
        },
        CREATE_POINT: (context, data) => {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            const idActivePlane = context.rootState.route.params.planeID;
            return context.dispatch(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/CREATE_POINT`, data, {root: true});
        },
        UPDATE_PLANE_POINTS: (context, data) => {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            const idActivePlane = context.rootState.route.params.planeID;
            context.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/UPDATE_PLANE_POINTS`, data, {root: true});
        },
        CHANGE_POINT_POSITION: (context, data) => {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            const idActivePlane = context.rootState.route.params.planeID;
            context.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/CHANGE_POINT_POSITION`, data, {root: true});
        },
        REMOVE_POINT_BY_ID: (context, data) => {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            const idActivePlane = context.rootState.route.params.planeID;
            context.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/REMOVE_POINT_BY_ID`, data, {root: true});
        },

        ADD_PLANE: async function (context, planeData) {
            const uuidActiveProject = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            const idPlane = await context.dispatch(`projectList/${uuidActiveProject}/photoList/${idActivePhoto}/planeList/ADD_PLANE`, {
                data: planeData,
                path: ['projectList', uuidActiveProject, 'photoList', idActivePhoto]
            }, {root: true})
            return idPlane;
        },

        DELETE_PLANE: (context) => {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            const idActivePlane = context.rootState.route.params.planeID;
            const pathModule = ['projectList', activeProjectUUID, 'photoList', idActivePhoto, 'planeList', idActivePlane];
            context.dispatch('projectList/DELETE_MODULE', pathModule, {root: true});
        },
    }
};
