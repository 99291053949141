<template>
    <svg width="205" height="101" viewBox="0 0 205 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M73.8237 77.5635V92.9192H133.867V77.5635H143.517L131.723 40.6823H114.567V29.835H103.845V40.6823H73.8237L64.1738 77.5635H73.8237Z" fill="#B4FF00" stroke="black" stroke-width="2"/>
        <path d="M63.2038 2.00405L63.1227 2L56.3691 21.9578L56.5304 21.9513C57.463 21.9137 59.3804 22.2247 60.4865 23.7407C61.2027 24.7222 61.8426 25.3375 62.4901 25.7063C63.1395 26.0762 63.7879 26.1931 64.5112 26.1931C65.2297 26.1931 65.8198 26.1351 66.5355 25.8262C67.2467 25.5193 68.0765 24.967 69.2817 23.991C70.48 23.0205 71.3451 22.5121 72.0416 22.2454C72.7354 21.9797 73.2678 21.9514 73.8089 21.9514C74.3502 21.9514 74.9105 21.9797 75.6322 22.2458C76.356 22.5127 77.2481 23.0212 78.4456 23.991C79.6507 24.967 80.4805 25.5193 81.1918 25.8262C81.9074 26.1351 82.4975 26.1931 83.216 26.1931C83.9394 26.1931 84.5877 26.0762 85.2372 25.7063C85.8846 25.3375 86.5246 24.7222 87.2407 23.7407C88.3469 22.2247 90.2643 21.9137 91.1969 21.9513L91.3581 21.9578L84.6046 2L84.5234 2.00405C84.1302 2.02368 83.5004 2.12901 82.8784 2.38137C82.2577 2.63322 81.631 3.03718 81.2633 3.66345C80.8323 4.39749 80.4349 4.78137 80.0742 4.98384C79.7148 5.18565 79.3806 5.21386 79.0594 5.21386C78.7477 5.21386 78.4379 5.16046 78.0318 4.92705C77.6213 4.69111 77.1103 4.27002 76.4021 3.52783C75.686 2.77728 75.1556 2.39435 74.7424 2.20024C74.3897 2.03451 74.1266 2.00868 73.9183 2.00466V2.00392L73.8636 2.00402L73.8089 2.00392V2.00466C73.6006 2.00868 73.3376 2.03451 72.9848 2.20024C72.5717 2.39435 72.0413 2.77728 71.3251 3.52783C70.617 4.27002 70.1059 4.69111 69.6954 4.92705C69.2893 5.16046 68.9796 5.21386 68.6679 5.21386C68.3467 5.21386 68.0125 5.18565 67.653 4.98384C67.2924 4.78137 66.8949 4.39749 66.4639 3.66345C66.0962 3.03718 65.4695 2.63322 64.8488 2.38137C64.2269 2.12901 63.5971 2.02368 63.2038 2.00405Z" stroke="black" stroke-width="2"/>
        <path d="M26.3504 55.4421V47.2642H37.9963L41.5694 57.8164H23.4389V47.2642H12.3225L8.35228 57.8164H19.7335V60.3225H7.42591L2 75.3594H23.4389V60.3225H42.2311L47.5247 75.3594H26.3504V64.0158" stroke="black" stroke-width="2"/>
        <path d="M169.271 51.435V74.5992H174.232V51.435L171.751 49.6062L169.271 51.435Z" stroke="black" stroke-width="2"/>
        <path d="M174.232 56.3106H179.193" stroke="black" stroke-width="2"/>
        <path d="M174.232 70.941H179.193" stroke="black" stroke-width="2"/>
        <path d="M179.193 51.4343V74.5986H184.155V51.4343L181.674 49.6056L179.193 51.4343Z" stroke="black" stroke-width="2"/>
        <path d="M184.154 56.3106H189.116" stroke="black" stroke-width="2"/>
        <path d="M184.154 70.941H189.116" stroke="black" stroke-width="2"/>
        <path d="M189.115 51.4343V74.5986H194.077V51.4343L191.596 49.6056L189.115 51.4343Z" stroke="black" stroke-width="2"/>
        <path d="M194.078 56.3106H199.039" stroke="black" stroke-width="2"/>
        <path d="M194.078 70.941H199.039" stroke="black" stroke-width="2"/>
        <path d="M199.039 51.4343V74.5986H204V51.4343L201.519 49.6056L199.039 51.4343Z" stroke="black" stroke-width="2"/>
        <path d="M136.798 2L129.729 21.912H133.214L135.106 26.1931H145.063L146.755 21.912H149.842L151.435 26.1931H161.292L163.184 21.912H166.669L159.898 2H156.613L155.517 5.18592H151.136L149.842 2H146.954L145.66 5.18592H141.08L140.184 2H136.798Z" stroke="black" stroke-width="2"/>
        <path d="M39.3296 78.6763L46.8086 85.5701H82.3179" stroke="black" stroke-width="2"/>
        <circle cx="81.928" cy="85.5708" r="1.88602" fill="black"/>
        <circle cx="88.1717" cy="47.7196" r="1.88602" fill="black"/>
        <circle cx="119.518" cy="69.1814" r="1.88602" fill="black"/>
        <path d="M69.7666 26.3229V32.3061L88.1065 47.6544" stroke="black" stroke-width="2"/>
        <path d="M145.858 28.2089V38.8747L119.519 69.1161" stroke="black" stroke-width="2"/>
        <path d="M179.481 78.4816L167.515 99.0327H125.827" stroke="black" stroke-width="2"/>
        <path d="M127.815 98.8568C127.815 99.8985 126.971 100.743 125.929 100.743C124.887 100.743 124.043 99.8985 124.043 98.8568C124.043 97.8152 124.887 96.9708 125.929 96.9708C126.971 96.9708 127.815 97.8152 127.815 98.8568Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: "SVGPromptTwo"
}
</script>

<style scoped>

</style>