var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 9 6",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M7.09375 8.74228e-08L8.09375 1L4.04688 5.04687L8.74227e-08 1L1 6.20155e-07L4.04688 3.04687L7.09375 8.74228e-08Z",
          fill: "#9d9d9d"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }