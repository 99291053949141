<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Параметры пользователя
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-form-grid" style="margin-top: 23px">
                <div class="modal-form-grid-label">
                    ФИО
                </div>
                <input class="modal-form-grid-input" type="text" placeholder="Полное имя" v-model="fullname">
                <!--                <div class="modal-form-grid-label">-->
                <!--                    Город-->
                <!--                </div>-->
                <!--                <input class="modal-form-grid-input" type="text" placeholder="Город">-->
                <div class="modal-form-grid-label">
                    Подразделение
                </div>
                <input class="modal-form-grid-input" type="text" placeholder="Место работы">
                <div class="modal-form-grid-label">
                    E-mail
                </div>
                <input class="modal-form-grid-input" type="text" placeholder="example@mail.com" v-model="email">
                <template v-if="!changePassword">
                    <div></div>
                    <div class="blue-button" v-on:click="changePassword = true">Сменить пароль</div>
                </template>
                <template v-else>
                    <div class="modal-form-grid-label">
                        Пароль
                    </div>
                    <input class="modal-form-grid-input" type="text" placeholder="Пароль" v-model="password">
                    <div class="modal-form-grid-label">
                        Подтверждение пароля
                    </div>
                    <input class="modal-form-grid-input" type="text" placeholder="Пароль" v-model="repeatedPassword">
                </template>
                <div class="modal-form-grid-label">
                    Телефон
                </div>
                <input class="modal-form-grid-input" type="text" placeholder="+7 (***)-***-**-**" v-model="phone">
                <div class="modal-form-grid-label">
                    Роль пользователя
                </div>
                <select class="modal-form-grid-input" v-model="role">
                    <option value="manager">manager</option>
                    <option value="superadmin">superadmin</option>
                </select>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-lower-buttons-grid">
                <div class="blue-button modal-accept-button" v-on:click="saveUser">Сохранить</div>
                <div class="blue-outline-button modal-accept-button" v-on:click="$emit('close')">Отмена</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import {http_request} from "../../../httpConfig";

    export default {
        name: "ModalUser",
        components: {
            SVGClose
        },
        props: {
            user: {
                type: Object
            },
            callback: {
                type: Function
            }
        },
        data() {
            return {
                email: '',
                fullname: '',
                phone: '',
                role: '',
                password: '',
                repeatedPassword: '',
                changePassword: true
            }
        },
        methods: {
            saveUser() {
                let newUser = {
                    email: this.email,
                    fullname: this.fullname,
                    phone: this.phone,
                    role: this.role,
                };

                if (this.changePassword) {
                    if(this.password.trim().length > 0
                        && this.password === this.repeatedPassword) {
                        newUser['password'] = this.password;
                    } else {
                        alert('Новый пароль не задан или не совпадает с проверочным!');
                        return;
                    }
                }

                if (this.user) {
                    newUser['id'] = this.user.id;
                }
                if (this.password.length > 0) {
                    newUser['password'] = this.password;
                }
                console.log('/save_user', newUser);
                http_request.post('/save_user', newUser)
                    .then((request) => {
                        console.log(request.data);
                        if (this.callback) {
                            this.callback(request.data.user);
                        }
                        this.$emit('close');
                    })
                    .catch((error) => {
                        console.error(error);
                        alert('Ошибка при сохранении!');
                    });
            }
        },
        watch: {
            password(newValue) {
                newValue = newValue.trim();
            }
        },
        mounted() {
            if (this.user) {
                this.changePassword = false;
                this.email = this.user.email;
                this.fullname = this.user.fullname;
                this.phone = this.user.phone;
                this.role = this.user.role;
            }
        }
    }
</script>

<style scoped>

</style>
