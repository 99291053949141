import store from "../store";
import {bus} from "../vue_bus";

export default ((to, from, next) => {
    if(to.matched.some(record => record.meta.checkScale)){
        if(!store.state.projectList[to.params.projectUUID].photoList[to.params.photoID].scale){
            // alert('На фото не задан масштаб! Перейдите на шаг "Перспектива" и укажите масштаб.');
            bus.$emit('showModalAlert', 'На фото не задан масштаб! Перейдите на шаг "Перспектива" и укажите масштаб.');
            return ;
        }
        next();
    } else {
        next();
    }
});
