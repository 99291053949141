<template>
    <div>
        <div class="modal-data-container pv-23">
            <div class="modal-form-grid" style="grid-template-columns: 170px auto">
                <div class="modal-form-grid-label">
                    Выберите категорию
                </div>
                <select v-model="selectedCategoryId"
                        class="modal-form-grid-input width100">
                    <option :value="null">Без категории</option>
                    <option v-for="category in categoriesWithTags" :value="category.id">{{category.tag_category_name}}
                    </option>
                </select>
            </div>
        </div>

        <div class="modal-data-container">
            <div class="modal-borderline-thin "></div>
        </div>
        <div class="modal-data-container modal-tags-grid" style="height: 301px">
            <vue-custom-scrollbar class="admin-scrollable-tags right-border">
                <td style="padding-left: 30px">
                    <div class="pill-tag-static" style="margin-top: 6px"
                         v-for="tag in tagsFromSelectedCategory">
                        {{tag.tag_name}}
                        <div class="edit-tag-button" v-on:click="initEditTag(tag.id)">
                            <SVGEdit style="width: 12px"/>
                        </div>
                    </div>
                    <div class="pill-outline-tag" style="margin-top: 6px" v-on:click="initAddTag()">
                        Добавить
                        <SVGPlus class="pill-tag-svg"/>
                    </div>
                </td>
            </vue-custom-scrollbar>
            <vue-custom-scrollbar class="admin-scrollable-tags">

                <div class="tag-input-grid-sized" v-show="selectedTagStatus">
                    <input class="tag-input-sized" type="text" placeholder="введите тег" v-model="newNameTag">
                    <div class="blue-button modal-accept-button tags-modal-button" v-on:click="saveTag">Сохранить</div>
                    <div class="blue-outline-button modal-accept-button tags-modal-button" v-on:click="cancelTag">Отмена</div>
                </div>

                <div v-show="!selectedTagStatus">
                    <div class="modal-data-container modal-tags-text">
                        Редактируйте тег
                        <SVGEdit style="width: 12px; margin: 0 5px"/>
                    </div>
                    <div class="modal-data-container modal-tags-text">
                        Или&#160;<span class="bold-text">добавьте</span>&#160;новый
                    </div>
                </div>
            </vue-custom-scrollbar>
        </div>
    </div>
</template>

<script>
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import SVGEdit from "../../SVGMP/SVGEdit";
    import SVGPlus from "../../SVGMP/SVGPlus";
    import {http_request} from "../../../httpConfig";

    export default {
        name: "ModalTagsTags",
        components: {
            SVGEdit,
            SVGPlus,
            vueCustomScrollbar,
        },
        data() {
            return {
                categoriesWithTags: [],
                tagsWithoutCategory: [],
                selectedCategoryId: null,
                selectedTagStatus: false,
                newNameTag: '',
                selectedTagId: null
            }
        },
        computed: {
            tagsFromSelectedCategory() {
                if (this.selectedCategoryId) {
                    const selectedCategory = this.categoriesWithTags.find(category => category.id == this.selectedCategoryId);
                    return selectedCategory.tags;
                } else {
                    return this.tagsWithoutCategory;
                }
            }
        },
        methods: {
            initEditTag(tagId) {
                this.selectedTagId = tagId;
                const selectedTagForEdit = this.tagsFromSelectedCategory.find(tag => tag.id == tagId);
                this.newNameTag = selectedTagForEdit.tag_name;
                console.log('Исправить tag', tagId);
                this.selectedTagStatus = true;
            },
            initAddTag() {
                this.selectedTagId = null;
                this.newNameTag = '';
                this.selectedTagStatus = true;
            },
            saveTag() {
                let dataForSend = {
                    tag_name: this.newNameTag,
                };
                if (this.selectedCategoryId) {
                    dataForSend['category_id'] = this.selectedCategoryId;
                }
                if (this.selectedTagId) {
                    dataForSend['id'] = this.selectedTagId;
                    http_request.post('/update_tags', {tags: [dataForSend]})
                        .then((request) => {
                            console.log(request.data);
                            this.getCategoriesWithTags();
                        })
                        .catch((err) => {
                            alert('Ошибка. Тег не сохранен.')
                            console.log(err);
                        })
                } else {
                    http_request.post('/save_tags', {tags: [dataForSend]})
                        .then((request) => {
                            console.log(request.data);
                            this.getCategoriesWithTags();
                        })
                        .catch((err) => {
                            alert('Ошибка. Тег не сохранен.')
                            console.log(err);
                        })
                }
                this.selectedTagStatus = false;
                console.log('Создать тег', dataForSend);
            },
            cancelTag() {
                this.selectedTagId = null;
                this.newNameTag = '';
                this.selectedTagStatus = false;
            },
            getCategoriesWithTags() {
                this.categoriesWithTags.length = 0;
                this.tagsWithoutCategory.length = 0;
                http_request.post('/get_categories_with_tags', {})
                    .then((request) => {
                        console.log(request.data);
                        this.categoriesWithTags.push(...request.data.tag_categories);
                        this.tagsWithoutCategory.push(...request.data.uncategorized.filter(tag => !tag.permanent));
                    })
                    .catch((err) => {
                        alert('Ошибка при загрузке списка тегов.');
                        console.log(err);
                    })
            }
        },
        mounted() {
            this.getCategoriesWithTags();
        }
    }
</script>

<style scoped>
    table, th, tr, td {
        border: none;
    }

    .admin-logs-table {
        border: none;
    }
</style>
