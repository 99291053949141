var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { margin: "0 0 1px 1px" },
      attrs: {
        width: "29",
        height: "23",
        viewBox: "0 0 31 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d: "M1 6.31408V22.9592H4.51725V6.31408L2.75863 5L1 6.31408Z",
          stroke: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M4.51682 9.81836H8.03407", stroke: "currentColor" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M4.51682 20.3308H8.03407", stroke: "currentColor" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M8.03485 6.31408V22.9592H11.5521V6.31408L9.79348 5L8.03485 6.31408Z",
          stroke: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M11.5519 9.81836H15.0691", stroke: "currentColor" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M11.5519 20.3308H15.0691", stroke: "currentColor" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M15.0689 6.31408V22.9592H18.5862V6.31408L16.8275 5L15.0689 6.31408Z",
          stroke: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M18.5859 9.81836H21.5", stroke: "currentColor" }
      }),
      _vm._v(" "),
      _c("path", { attrs: { d: "M18.5859 20.3308H22.1032", stroke: "white" } }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M22 10.5L22.104 22.9592H25.6212V11",
          stroke: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "24.7942",
          cy: "5.79422",
          r: "5.29422",
          stroke: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M27.7658 5.94291C27.7658 6.1572 27.5921 6.33092 27.3778 6.33092H25.1225V8.57165C25.1225 8.79398 24.9423 8.97421 24.7199 8.97421V8.97421C24.4976 8.97421 24.3174 8.79398 24.3174 8.57165V6.33092H22.0621C21.8478 6.33092 21.6741 6.1572 21.6741 5.94291V5.94291C21.6741 5.72863 21.8478 5.55491 22.0621 5.55491H24.3174V3.31418C24.3174 3.09185 24.4976 2.91162 24.7199 2.91162V2.91162C24.9423 2.91162 25.1225 3.09185 25.1225 3.31418V5.55491H27.3778C27.5921 5.55491 27.7658 5.72863 27.7658 5.94291V5.94291Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }