import {cities} from "./cities";
import {regions} from "./regions";

export const selectRegionCity = {
    namespaced: true,
    state: () => ({
        selectedRegionID: null,
        selectedCityID: null,
    }),
    modules:{
        cities,
        regions
    },
    getters:{
        ORGANIZATIONS_T: (state, getters, rootState, rootGetters) => {
            const organizations = getters['organizations/SELF'];
            const selectCityID = state.selectedCityID;
            if (selectCityID) {
                return organizations.filter(organization => organization.city_id == selectCityID);
            } else {
                return organizations;
            }
        },
    },
    mutations: {
        SELECTED_CITY_ID: (state, value) => {
            state.selectedCityID = value;
        },
        SELECTED_REGION_ID: (state, value) => {
            state.selectedRegionID = value;
        },
        SELECTED_ORGANIZATION_ID: (state, value) => {
            state.selectedOrganizationID = value;
        },
        CLEAR_ALL: (state, value) => {
            state.selectedRegionID = null;
            state.selectedCityID = null;
        }
    },
    actions: {
        CLEAR_ALL: function (context, inputData) {
            context.commit('cities/CLEAR_CITIES');
            context.commit('regions/CLEAR_REGIONS');
            context.commit('CLEAR_ALL');
        },
        GET_CITIES_BY_SELECT_CATEGORIES: function (context, inputData) {
            let dataForRequest = {};
            if (context.state.selectedRegionID) {
                dataForRequest.region_id = context.state.selectedRegionID
            }

            context.dispatch('cities/GET_CITIES', dataForRequest);
        },
        SELECTED_CITY: async function (context, cityID) {
            let selectedCity = context.getters['cities/CITY_BY_ID'](cityID);
            if (!selectedCity && cityID)
                await context.dispatch('cities/REQUEST_GET_CITIES', {city_id: cityID})

            selectedCity = context.getters['cities/CITY_BY_ID'](cityID);
            if (selectedCity) {
                context.commit('SELECTED_REGION_ID', selectedCity.region_id);
                context.commit('SELECTED_CITY_ID', selectedCity.city_id);
            } else {
                context.commit('SELECTED_CITY_ID', cityID);
            }
        }
    }
}
