var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "30",
        height: "22",
        viewBox: "0 0 30 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M28.8669 21.001V14.9723L17.8667 6.59909V3.98665H24.5962V1.30722H6.60764V3.98665H13.2078V8.60866L24.5962 16.6469V21.001M6.60764 6.59909L1.49578 14.9723C1.36637 16.9819 2.20756 21.001 6.60764 21.001C11.0077 21.001 11.9352 16.9819 11.8489 14.9723L6.60764 6.59909Z",
          stroke: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }