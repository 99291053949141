import {Group, Mesh, MeshBasicMaterial, PlaneGeometry, Raycaster, Scene, Vector2, Vector3} from "three";
// import * as THREE from "three";


export const PointProjection = function (camera, width, height) {
    this.width = width;
    this.height = height;
    this.camera = camera;
    this.raycaster = new Raycaster();
    this.planeSize = 300000;
    const geometry = new PlaneGeometry(this.planeSize, this.planeSize, 4, 4);
    let material = new MeshBasicMaterial( { color: 0x000000 } );

    geometry.rotateX(-Math.PI / 2);

    let floorGroup = new Group();
    let floor = new Mesh(geometry, material);
    floorGroup.add(floor);
    // floorGroup.rotateX(-Math.PI / 2);
    this.floor = floor;
    // floorGroup.add(this.camera);

    this.getRelativePosition = (point) => {
        return new Vector2(((point.x/* - rect.left*/) / this.width) * 2 - 1, -((point.y /*- rect.top*/) / this.height) * 2 + 1);
    };

    this.getProjection = (point, plane) => {
        let relative_point = this.getRelativePosition({x: point.x, y: point.y});
        this.raycaster.setFromCamera(relative_point, this.camera);
        let intersects = this.raycaster.intersectObject(plane, true);
        return intersects
    }

    this.getFloorProjection = (point) => {
        return this.getProjection(point, this.floor);
    }

}


