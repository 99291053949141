import {http_request} from "../../httpConfig";

function compareDataRequest(data1, data2) {
    if (!(data1 && data2)) {
        return false;
    }

    const compareArr = (a1, a2) => {
        return a1.length === a2.length && a1.every((v, i) => v === a2[i])
    };

    for (let key in data1) {
        if (Array.isArray(data1[key])) {
            if (!(Array.isArray(data2[key]) && compareArr(data1[key], data2[key]))) {
                return false;
            }
        } else {
            if (data1[key] !== data2[key]) {
                return false;
            }
        }
    }

    return true;
}

export const productsWithFilters = {
    namespaced: true,
    state: {
        productTypesList: [],
        categoriesList: [],
        productTypesSelected: [],
        subcategoriesSelected: [],
        nofProductsInSubcategory: [],
        requestedDataInProgress: null,
        allProductsLoaded: false,
        products: [],
        mask: '',
    },
    mutations: {
        CLEAR_PRODUCTS: function (state) {
            state.products.splice(0);
        },
        CATEGORIES_LIST: function (state, categories) {
            const uniqCategories = categories.reduce((accum, currentValue) => {
                if (!accum.find(elem => elem.id == currentValue.id)) {
                    accum.push(currentValue);
                }
                return accum;
            }, []);

            state.categoriesList.splice(0);
            state.categoriesList.push(...uniqCategories);
        },
        PRODUCT_TYPES_LIST: function (state, productTypes) {
            state.productTypesList.splice(0);
            state.productTypesList.push(...productTypes);
        },
        SUBCATEGORIES_SELECTED: function (state, subcategoriesIdSelected) {
            state.subcategoriesSelected.splice(0);
            state.allProductsLoaded = false;
            state.subcategoriesSelected.push(...subcategoriesIdSelected);
        },
        PRODUCT_TYPES_SELECTED: function (state, productTypesIdSelected) {
            state.productTypesSelected.splice(0);
            state.allProductsLoaded = false;
            state.productTypesSelected.push(...productTypesIdSelected);
        },
        CALLBACK: function (state, {dataForCompare, products, nofProductsInSubcategory}) {
            if (compareDataRequest(state.requestedDataInProgress, dataForCompare)) {
                state.requestedDataInProgress = null;
                state.nofProductsInSubcategory.splice(0);
                state.nofProductsInSubcategory.push(...nofProductsInSubcategory);
                if (products.length === 0) {
                    state.allProductsLoaded = true;
                    return;
                }
                state.products.push(...products);
            } else {
                console.warn('Пришли уже неактуальные данные.', request.data.products)
            }
        },
        CATCH_CALLBACK: function (state, {dataForCompare}) {
            if (compareDataRequest(state.requestedDataInProgress, dataForCompare)) {
                state.requestedDataInProgress = null;
            }
        }
    },
    getters: {},
    actions: {
        GET_FILTERS: async function (context, nameArea) {
            http_request.post('/get_filters', {
                name: nameArea
            })
                .then((request) => {
                    console.log('/get_filters', request.data);
                    context.commit('PRODUCT_TYPES_LIST', request.data.product_types);
                    context.commit('CATEGORIES_LIST', request.data.categories);
                    context.state.products.length = 0;
                    context.state.allProductsLoaded = false;
                    context.dispatch('GET_PRODUCTS');
                })
        },
        GET_PRODUCTS: async function (context, inputData) {
            let application_areas, product_types, productsLength, mask, dataForCompare, subcategories, categories;
            if (!context.rootState.route.params.facadeElement) {
                console.error('Не указан facadeElement');
                return;
            }

            if (context.state.allProductsLoaded) {
                console.log('Нет больше продуктов!');
                return;
            }

            application_areas = [context.rootState.route.params.facadeElement];

            if (context.state.productTypesSelected.length > 0) {
                product_types = context.state.productTypesSelected;
            } else if (context.state.productTypesList.length > 0) {
                product_types = context.state.productTypesList.map(productType => productType.id);
            }

            if (context.state.subcategoriesSelected.length > 0) {
                let uniqueCategories = new Set();
                subcategories = [];
                for (let categoryAndSubcategory of context.state.subcategoriesSelected) {
                    const catAndSubcat = categoryAndSubcategory.split('/');
                    uniqueCategories.add(catAndSubcat[0]);
                    subcategories.push(catAndSubcat[1]);
                }
                categories = [...uniqueCategories];
            }

            productsLength = context.state.products.length;
            mask = context.state.mask;
            dataForCompare = {
                application_areas,
                product_types,
                categories,
                subcategories,
                productsLength,
                // mask,
            };
            // В методе compareDataRequest производится сравнение того, что зпросили, с тем, что собираемся запросить
            // Если данные для запроса одинаковые, то запрос не производится.
            if (compareDataRequest(context.state.requestedDataInProgress, dataForCompare)) {
                return;
            }

            let dataForSend = {
                part: {
                    from: productsLength,
                    count: 15,
                },
                // find: {
                //     mask: mask,
                //     field: 'name'
                // },
                application_areas,
                product_types,
                categories,
                subcategories,
            };
            console.log('/get_products', dataForSend);
            context.state.requestedDataInProgress = dataForCompare;
            await http_request.post('/get_products', dataForSend)
                .then((request) => {
                    console.log('Получил', request.data, dataForCompare);
                    context.commit('CALLBACK', {
                        dataForCompare,
                        products: request.data.products,
                        nofProductsInSubcategory: request.data.nof_products_in_subcategory
                    })
                })
                .catch((error) => {
                    context.commit('CATCH_CALLBACK', {dataForCompare});
                    console.log(error);
                    console.log(error.response);
                })
        }
    }
}
