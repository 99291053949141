import {planePointer} from "../primitives/planePointer";
import store from "../../store";
import {planeLine} from "../primitives/planeLine";
import draw from "tui-image-editor/src/js/ui/template/submenu/draw";
import {planePolygon} from "../primitives/planePolygon";
import {bus} from "../../vue_bus";
import {sortObjectsByZindex} from "../libs/sortObjectsByZindex";
import {nearPoint} from "../libs/nearPoint";
import {markerPointer} from "../primitives/markerPointer";
import {getFermatPolygon} from "../../math/getAreaCorrected";
import {getEdgesFiltered} from "../../math/fermatCollection";
import {describeEdges, getIncludedPlanes, getNeighbours, getPlaneSide} from "../../math/2dPlanReconstruction";

export const baseLineTool = function (fabricJS) {
    this.fabricJS = fabricJS;
    this.pointZoneSize = 10;
    this.movingID = null;
    this.movingPosition = null;
    this.oldPointID = null;
    this.activeLine = null;
    this.activeShape = null;
    this.pointArray = [];
    this.lineDict = {};
    this.selectedPointID = null;
    this.nearPointFinder = new nearPoint(this.fabricJS, this.pointZoneSize);
    this.middleLinePointer = markerPointer();
    this.isComplete = false;
    this.unsaveChanges = false;

    this.lineOverEvent = (event) => {
        if (!event.target)
            return;

        if (!event.target.isLine)
            return

        this.middleLinePointer.set({
            left: (event.target.x1 + event.target.x2)/2,
            top: (event.target.y1 + event.target.y2)/2,
        })

        this.fabricJS.add(this.middleLinePointer);
    }

    this.lineOutEvent = (event) => {
        if (!event.target)
            return;

        if (!event.target.isLine)
            return;

        this.fabricJS.remove(this.middleLinePointer);
    }

    this.removeAll = () => {
        this.pointArray.forEach((point)=>{
            this.fabricJS.remove(point.toFabric());
        })
        for (let lineID in this.lineDict) {
            this.fabricJS.remove(this.lineDict[lineID]);
        }
        // this.fabricJS.remove(this.polygon);
        // this.pointArray.push(newPoint);

        this.pointArray = [];
    }

    this.lineClickEvent = (event) => {
        if (!event.target)
            return;

        if (!event.target.isLine)
            return;

        let newPoint = {
            x: this.middleLinePointer.left,
            y: this.middleLinePointer.top,
            link: event.target.linkPoint,
        }

        let points = [];
        let linkShift = 0;
        for (let pointID in this.pointArray) {
            if (event.target.linkPoint == (pointID - 1)) {
                points.push(newPoint);
                linkShift = 1;
            }
            points.push(this.pointArray[pointID].position)
            points[points.length-1].link = linkShift + ((pointID-1)>=0?pointID-1:Object.keys(this.pointArray).length);
        }
        if (event.target.linkPoint == Object.keys(this.pointArray).length - 1) {
            points.push(newPoint);
        }
        this.removeAll();
        this.fabricJS.remove(this.middleLinePointer);
        this.drawAll(points);
        this.unsaveChanges = true;
    }

    this.drawAll = (pointListIn) => {
        let pointList
        if (pointListIn)
            pointList = pointListIn;
        else {
            const projectUUID = store.state.route.params.projectUUID;
            const photoID = store.state.route.params.photoID;
            console.log(projectUUID);
            console.log(photoID);
            pointList = store.getters[`projectList/${projectUUID}/photoList/${photoID}/BASE_LINE`];
            console.log(pointList)
        }

        let points = []
        for (let pointID in pointList) {
            let pointer = new planePointer(pointList[pointID], pointID);
            pointer.toFabric().set({zIndex: 7});
            // pointer.link = parseInt(pointList[pointID].link);
            pointer.link  = (pointID==0)?pointList.length-1:pointID-1;
            pointer.dragging(true);
            this.pointArray.push(pointer);
            points.push(pointer.position);
            this.fabricJS.add(pointer.toFabric());
        }
        // this.polygon = new planePolygon(points);
        // this.polygon.set({
        //     zIndex: 3,
        //     evented: false
        // });
        // this.fabricJS.add(this.polygon);
        for (let pointID in this.pointArray) {
            if (pointID == 0)
                continue

            let line = new planeLine(this.pointArray[this.pointArray[pointID].link].position, this.pointArray[pointID].position);
            line.set({
                zIndex: 5,
                isLine: true,
                linkPoint: this.pointArray[pointID].link,
            });
            this.fabricJS.add(line);
            this.lineDict[this.pointArray[pointID].link] = line;
        }
        sortObjectsByZindex(this.fabricJS);

    }

    this.addMovingEvent = () => {
        this.fabricJS.on('object:moving', (event) => {
            let nearPoint = this.nearPointFinder.findNearPoint({x: event.target.left, y: event.target.top});
            if (nearPoint) {
                event.target.set({
                    left: nearPoint.x,
                    top: nearPoint.y
                })
            }
            this.movingID = event.target.pointID;
            this.movingPosition = {
                x: event.target.left,
                y: event.target.top
            }
            // let points = this.polygon.get("points");
            // points[this.movingID] = {
            //     x: event.target.left,
            //     y: event.target.top
            // }
            // this.polygon.set({
            //     points: points
            // });

            if (this.lineDict[this.movingID]) {
                this.lineDict[this.movingID].set({
                    x1: event.target.left,
                    y1: event.target.top
                })
            }

            if (this.lineDict[this.pointArray[this.movingID].link]) {
                this.lineDict[this.pointArray[this.movingID].link].set({
                    x2: event.target.left,
                    y2: event.target.top
                })
            }

            this.fabricJS.renderAll();
            this.unsaveChanges = true;
        });
    }

    this.deletePoint = () => {
        if (this.selectedPointID === null)
            return

        let points = [];
        let index = 0;
        for (let pointID in this.pointArray) {
            if (pointID == this.selectedPointID) {
                continue;
            }
            points.push(this.pointArray[pointID].position)
            if (index !== 0) {
                // points.push(this.pointArray[pointID].position)
                points[points.length - 1].link = index - 1;
            }  else {
                points[points.length - 1].link = this.pointArray.length - 2;
            }
            index+=1;
        }
        this.removeAll();
        this.drawAll(points);
        this.unsaveChanges = true;
    }

    this.fabricJS.on('mouse:move', (options) => {
        // if (!this.isComplete)
        this.nearPointFinder.setEnable(!options.e.shiftKey);

        let pointer = this.fabricJS.getPointer(options.e);
        if (this.activeLine) {
            this.activeLine.set({
                x2: pointer.x,
                y2: pointer.y
            })
        }

        this.nearPointFinder.setPoint(pointer);
        this.fabricJS.renderAll();
    });

    this.fabricJS.on('selection:created', (event) => {
        this.selectedPointID = event.target.pointID
        bus.$emit("showDeleteButton", true);
    });

    this.fabricJS.on('selection:updated', (event) => {
        this.selectedPointID = event.target.pointID
    });

    this.fabricJS.on('selection:cleared', () => {
        this.selectedPointID = null;
        bus.$emit("showDeleteButton", false);
    });

    this.destroy = () => {
        bus.$off("AcceptPlaneDrawing");
        bus.$off("deletePoint", this.deletePoint);
        this.fabricJS.off('mouse:over',this.lineOverEvent);
        this.fabricJS.off('mouse:out',this.lineOutEvent);
        this.fabricJS.off('mouse:down',this.lineClickEvent);
    }

    bus.$on("deletePoint", this.deletePoint);

    bus.$on("AcceptPlaneDrawing", async (callback) => {
        const projectUUID = store.state.route.params.projectUUID;
        const photoID = store.state.route.params.photoID;
        const planeId = parseInt(store.state.route.params.planeID);
        this.unsaveChanges = false;
        let newPointArray = [];
        this.pointArray.forEach((point) => {
            newPointArray.push(point.position);
        })
        store.commit(`projectList/${projectUUID}/photoList/${photoID}/BASE_LINE`, newPointArray);

        // callback()
    })

    this.onUpdateScale = (scale)=>{
        this.pointArray.forEach((pointer)=>{
            pointer.toFabric().set({
                scaleX: 2/scale,
                scaleY: 2/scale,
            })
        })
        for (let lineID in this.lineDict) {
            this.lineDict[lineID].set({
                strokeWidth: 2 / scale,
            })
        }
        this.nearPointFinder.setScale(scale);
        this.pointZoneSize = 18 / scale;
        // console.log(this.pointZoneSize);
        // console.log(scale);
    }

    // this.drawAll();

    this.isComplete = true;
    this.fabricJS.on('mouse:over',this.lineOverEvent);
    this.fabricJS.on('mouse:out',this.lineOutEvent);
    this.fabricJS.on('mouse:down',this.lineClickEvent);
    this.nearPointFinder.setVisible(false);
    // this.nearPointFinder.enable = false;
    this.drawAll();
    this.addMovingEvent();
}
