<template>
    <svg class="modal-close-btn" width="50" height="50" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle class="modal-close-circle" r="12.2443" transform="matrix(0.707106 -0.707108 0.707106 0.707108 18.7302 18.7302)" fill="#E4373A" stroke="CurrentFill" stroke-width="2"/>
        <path d="M14.6588 15.4728L17.9162 18.7302L14.6588 21.9877L15.4732 22.802L18.7306 19.5446L21.988 22.802L22.8024 21.9877L19.5449 18.7302L22.8024 15.4728L21.988 14.6584L18.7306 17.9159L15.4732 14.6584L14.6588 15.4728Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGModalClose"
    }
</script>

<style scoped>

</style>
