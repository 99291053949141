import {Design} from "./Design";
import {getModuleNamespace} from "../index";
import {planeGroupSettings} from "../modules/UI/planeGroupSettings";

export function DesignList() {
    this.namespaced = true;
    this.state = {
        // Здесь нельзя ничего писать! Здесь будут только динамически созданные модули проектов! Иначе сломается логика.
    };

    this.getters = {
        MAX_ID_DESIGN: state => {
            let maxID = 0;
            for (let idDesign in state) {
                if (maxID < parseInt(idDesign)) maxID = parseInt(idDesign);
            }
            return maxID;
        },
        ALL_DESIGNS: state => {
            return state;
        },
        DESIGN_BY_ID: state => id => {
            if(state[id])
                return state[id];
            else
                return null
        },
        PRODUCTS_USED: (state, getters) => {
            let productsUsed = [];
            for(let designId in state){
                const productsArray = getters[`${designId}/PRODUCTS_USED`];
                for(let prodUUID of productsArray){
                    if(!productsUsed.includes(prodUUID)){
                        productsUsed.push(prodUUID);
                    }
                }
            }
            return productsUsed;
        }
    };

    this.mutations = {};

    this.actions = {
        CREATE_NEW_DESIGN_MODULE: async function (context, inputData) {
            const idNewModule = context.getters.MAX_ID_DESIGN + 1;
            const modulePath = getModuleNamespace(this, context.state);
            inputData.id = idNewModule
            await context.dispatch('CREATE_DESIGN_MODULE', inputData);

            let pathPlaneGroupList = modulePath.slice(0, modulePath.length - 1);
            pathPlaneGroupList.push('planeGroupList')
            const planeGroupList = context.rootGetters[pathPlaneGroupList.join('/')+'/SELF'];
            for(let idPlaneGroup in planeGroupList) {
                await context.dispatch(`${idNewModule}/designGroupList/CREATE_DESIGN_GROUP_MODULE`, {id: idPlaneGroup});
            }
            return idNewModule;
        },
        CREATE_DESIGN_MODULE: async function (context, inputData) {
            let id = inputData.id;
            const modulePath = getModuleNamespace(this, context.state);
            const pathDesign = modulePath.concat([id]);
            const designData = inputData.data;
            this.registerModule(pathDesign, new Design());
            await context.dispatch(`${id}/INIT`, {
                path: pathDesign,
                data: designData,
            });
            return id;
        },
        CLEAR_DESIGN_PHOTOS: function (context) {
            for(let designId in context.state) {
                context.commit(`${designId}/CLEAR_RENDERED_PHOTOS`);
            }
        }
    }
}
