import {getBoundingBox} from "../Tools/pointsTools";

export const cutPlane = async function(fabricJS, polygon, sourceImagePromise, scale) {
    let _scale;
    if (scale)
        _scale = scale;
    else
        _scale = 1;
    // let boundingBox = getBoundingBox(points);
    let sourceImage = await sourceImagePromise;

    let rect = polygon.getBoundingRect();
    // const canvas = document.createElement('canvas');
    // const ctx = canvas.getContext("2d");
    // canvas.width = rect.width * scale;
    // canvas.height = rect.height * scale;

    fabricJS.renderAll();

    sourceImage.bringToFront();
// this.fabricJS.renderAll();
    let texture;


    texture = fabricJS.toDataURL({
            left: rect.left,
            top: rect.top,
            width: Math.ceil(rect.width),
            height: Math.ceil(rect.height),
            multiplier:  1/(fabricJS.viewportTransform[0]*devicePixelRatio)
        })

    // fabricJS.viewportTransform = backUpTransform;

    sourceImage.sendToBack();
    return texture
}

