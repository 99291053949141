var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-background" },
    [
      _c("div", { staticClass: "modal-head-grid" }, [
        _c("div", { staticClass: "modal-label" }, [
          _vm._v("\n            Результат\n        ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "modal-close-btn-mp",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_c("SVGClose")],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "result-modal-options-buttons" }, [
        _c(
          "div",
          {
            staticClass: "lover-bar-button std-gray-border",
            on: { click: _vm.createPDF }
          },
          [_c("SVGSavePDF")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "lover-bar-button std-gray-border",
            on: { click: _vm.openModalContactManager }
          },
          [_c("SVGContactManager")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "lover-bar-button std-gray-border",
            on: { click: _vm.openModalShare }
          },
          [_c("SVGShare")],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "result-modal-image-container" }, [
        _c(
          "div",
          {
            staticClass: "arrow-photo-left",
            staticStyle: { left: "75px" },
            on: {
              click: function($event) {
                return _vm.previousPhoto()
              }
            }
          },
          [_c("SVGArrowSwitchPhotoLeft")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "arrow-photo-right",
            staticStyle: { right: "75px" },
            on: {
              click: function($event) {
                return _vm.nextPhoto()
              }
            }
          },
          [_c("SVGArrowSwitchPhotoRight")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "result-modal-photo-navigation-tip" },
          [
            _c("SVGRotateHouse"),
            _vm._v(" "),
            _vm._l(_vm.photoList, function(photo, photoId) {
              return _c("div", {
                staticClass: "house-rotation-mark",
                class: { green: photoId == _vm.activePhotoId },
                on: {
                  click: function($event) {
                    _vm.activePhotoId = photoId
                  }
                }
              })
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("img", {
          staticClass: "result-modal-image",
          attrs: { src: _vm.urlForPhoto(_vm.srcProjectPhoto) }
        })
      ]),
      _vm._v(" "),
      _c(
        "vue-custom-scrollbar",
        { staticClass: "result-modal-products-carousel" },
        _vm._l(_vm.products, function(product) {
          return _c("div", { staticClass: "result-modal-product-card" }, [
            _c("img", {
              staticClass: "result-modal-product-card-img",
              attrs: { src: _vm.urlForPhoto(product.preview) }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "result-modal-product-card-body" }, [
              _c("div", { staticClass: "result-modal-product-card-label" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(product.name) +
                    "\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "result-modal-product-card-borderline" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "blue-button result-modal-product-card-shop-btn",
                on: {
                  click: function($event) {
                    return _vm.openLinkInOnlineStore(product.data_json)
                  }
                }
              },
              [_vm._v("\n                Страница в магазине\n            ")]
            )
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }