import { render, staticRenderFns } from "./WorkspaceLoader.vue?vue&type=template&id=745c7295&"
import script from "./WorkspaceLoader.vue?vue&type=script&lang=js&"
export * from "./WorkspaceLoader.vue?vue&type=script&lang=js&"
import style0 from "./WorkspaceLoader.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/metall-profil/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('745c7295')) {
      api.createRecord('745c7295', component.options)
    } else {
      api.reload('745c7295', component.options)
    }
    module.hot.accept("./WorkspaceLoader.vue?vue&type=template&id=745c7295&", function () {
      api.rerender('745c7295', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/UI/ComponentsMP/Loaders/WorkspaceLoader.vue"
export default component.exports