import {getFermatPolygon} from "./getAreaCorrected";
import {getEdgesFiltered} from "./fermatCollection";
import {getLinesIntersection} from "../fabric/libs/getPerspectivePoints";
import {Point, Segment} from "@mathigon/fermat";

export const Planner2d = function () {
    this.bottomPoint = {x: 0, y: 0};
    this.bottomPointPlane = undefined;
    this.pointCloud = undefined;
    this.baseLine = [];

    this.findBaseLine = (planes, pointCloud) => {
        if (!pointCloud) {
            return undefined;
        }
        this.pointCloud = pointCloud;
        this.findBottomPoint(planes);
        console.log("UNDEFINED OF UNDEFINED", planes, this.bottomPointPlane);
        let fermatBot = getFermatPolygon(planes[this.bottomPointPlane].points);
        // console.log("FERMAT BOT", fermatBot);
        let fermatBotDescribed = describeEdges(fermatBot);
        fermatBotDescribed["realBaseLine"] = fermatBotDescribed.fermat.edges[fermatBotDescribed.bot];
        // console.log("fermatBotDescribed PUSHING", fermatBotDescribed);
        this.baseLine.push(fermatBotDescribed["realBaseLine"]);
        // console.log("POINT CLOUD in findBase", pointCloud);

        this.findNeighboursBaseLine(fermatBotDescribed, this.bottomPointPlane, planes, "left");
        this.findNeighboursBaseLine(fermatBotDescribed, this.bottomPointPlane, planes, "right");


        // this.adjustBaseLine(leftBaseLine, "left");
        // this.adjustBaseLine(rightBaseLine, "right");
        // console.log("FOUNDED LEFT NEIGHBOURS FOR BOT", leftBaseLine);
        // console.log("FOUNDED RIGHT NEIGHBOURS FOR BOT", rightBaseLine);
        return this.baseLine;
    }

    this.findNeighboursBaseLine = (fermatDescribed, plane, planes, side) => {
        // TODO not implemented in store yet
        console.log("recursion", plane);
        let neighbours = getNeighbours(fermatDescribed, plane, planes);
        // console.log("neighbours from getNeighboursBaseLine", side, plane, neighbours);
        if (neighbours[side].length) {
            let fermatWithLowestBotLineDescribed = this.getNeighbourWithLowestBotLine(neighbours[side], planes);
            let edges = fermatWithLowestBotLineDescribed.fermat.edges;
            // fermatWithLowestBotLineDescribed["realBaseLine"] = edges[fermatWithLowestBotLineDescribed.bot];
            if (checkSharedPoint(fermatDescribed.realBaseLine, edges[fermatWithLowestBotLineDescribed.bot])) {
                // console.log("EDGES HAVE POINT");
                fermatWithLowestBotLineDescribed["realBaseLine"] = edges[fermatWithLowestBotLineDescribed.bot];
            }
            else {
                // console.log("EDGES DONT HAVE POINT");
                fermatWithLowestBotLineDescribed["realBaseLine"] =
                    this.getAdjustedBaseLine(
                                            edges[fermatWithLowestBotLineDescribed.bot],
                                            fermatDescribed.realBaseLine,
                                            edges[fermatWithLowestBotLineDescribed[side]],
                                            side);
            }
            this.baseLine.push(fermatWithLowestBotLineDescribed.realBaseLine);
            // console.log("LAM!!! PUSH", plane, neighbours, fermatWithLowestBotLineDescribed);
            this.findNeighboursBaseLine(fermatWithLowestBotLineDescribed, fermatWithLowestBotLineDescribed.planeId, planes, side);
            // neighbours[side].forEach( (v) => {this.getNeighboursBaseLine(v, side, planes, baseLine)});
        }
        // return this.baseLine;
        // while (notEmptyNeighbours(neighbours)) {
        //     this.getNeighboursBaseLIne(   );
        // }

    }

    this.findBottomPoint = (planes) => {
        for (let plane in planes) {
            if (planes[plane].type === "wall" || planes[plane].type === "window" || planes[plane].type === "door") {
                const points = planes[plane].points
                for (let point in points) {
                    if (points[point].y > this.bottomPoint.y) {
                        this.bottomPoint = points[point];
                        this.bottomPointPlane = plane;
                    }
                }
            }
        }
        return this.bottomPoint
        // console.log("bottom point", this.bottomPoint, this.bottomPointPlane);
    }

    this.getNeighbourWithLowestBotLine = (neighbours, planes) => {
        // console.log("FROM getNeighbourWithLowestBotLine", neighbours)
        let fermatDescribed = undefined;
        let minY = 0;
        for (let neighbour in neighbours) {
            // console.log("planes[neighbours[neighbour]] LAM", planes, neighbours, neighbour);
            let fermatPretender = getFermatPolygon(planes[neighbours[neighbour]].points);
            let fermatDescribedPretender = describeEdges(fermatPretender);
            let edges = fermatDescribedPretender.fermat.edges;
            if (edges[fermatDescribedPretender.bot].midpoint.y > minY) {
                minY = edges[fermatDescribedPretender.bot].midpoint.y;
                fermatDescribed = fermatDescribedPretender;
                fermatDescribed["planeId"] = neighbours[neighbour];
            }
        }
        return fermatDescribed;
    }

    this.getAdjustedBaseLine = (edge, pivotEdge, outEdge, side) => {
        let vp = undefined;
        let point1 = undefined;
        if (side === "left") {
            vp = getLeftPoint(this.pointCloud.pointCloud[0], this.pointCloud.pointCloud[1]);
            point1 = getLeftPoint(pivotEdge.p1, pivotEdge.p2);
        }
        else if (side === "right") {
            vp = getRightPoint(this.pointCloud.pointCloud[0], this.pointCloud.pointCloud[1]);
            point1 = getRightPoint(pivotEdge.p1, pivotEdge.p2);
        }
        if (!vp || !point1) return undefined;
        let point2 = getLinesIntersection(new Segment(point1, vp), outEdge);

        return new Segment(point1, point2);
    }

    return this;
}

export const getPlaneSide = function (horizontals, width) {
    let point = getLinesIntersection(horizontals[0], horizontals[1]);
    if (point.x < width / 2) {
        return "left";
    } else if (point.x > width / 2) {
        return "right";
    }
}

export const getIncludedPlanes = function (fermatOrig, fermatOrigId, planes) {
    let includedPlanesIds = [];
    for (let plane in planes) {
        if (!fermatOrigId || fermatOrigId == plane) {
            continue;
        }
        const fermat = getFermatPolygon(planes[plane].points);
        let contained = true;
        for (let point in fermat.points) {
            if (!fermatOrig.contains(fermat.points[point])) {
                contained = false;
                break;
            }
        }
        if (contained) {
            includedPlanesIds.push(plane);
        }
    }
    return includedPlanesIds;
}

export const getNeighbours = function (describedFermat, fermatOrigId, planes) {
    // console.log("FROM NEIGHBOURS", describedFermat, fermatOrigId, planes);
    let neighbours = {
        "left": [],
        "right": [],
        "bot": [],
        "top": []
    };
    const fermatOrig = describedFermat.fermat;
    const fermatOrigEdges = fermatOrig.edges;
    for (let plane in planes) {
        if (!fermatOrigId || fermatOrigId == plane) {
            continue;
        }
        const fermat = getFermatPolygon(planes[plane].points);
        const fermatEdges = fermat.edges;
        for (let edgeOrigin in fermatOrigEdges)
            for (let edge in fermatEdges) {
                if (is2edgesShared(fermatOrigEdges[edgeOrigin], fermatEdges[edge])) {
                    let side = getKeyByValue(describedFermat, edgeOrigin);
                    if (side) {
                        neighbours[side].push(plane);
                    }
                // console.log("fermatOrig.points[point]", fermatOrig.points[point]);
                // console.log("fermat.edges[edge].p1", fermat.edges[edge].p1);
                // if (fermat.edges[edge].contains(fermatOrig.points[point])
                //     || nearlyEqualsPoints(fermatOrig.points[point], fermat.edges[edge].p1)
                //     || nearlyEqualsPoints(fermatOrig.points[point], fermat.edges[edge].p2)) {
                //     console.log("contained point", fermatOrig.points[point]);
                }
        }
    }
    return neighbours;
}

const getKeyByValue = function (object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

const PRECISION = 0.000001;

const is2edgesShared = function (edge1, edge2) {
    const liesOnLine = checkPointLiesOnLine(edge1, edge2.p1) && checkPointLiesOnLine(edge1, edge2.p2);
    if (!liesOnLine) {
        return false;
    }
    const hasInternalPoint = checkInternalPoint(edge1, edge2.p1) + checkInternalPoint(edge1, edge2.p2)
                            + checkInternalPoint(edge2, edge1.p1) + checkInternalPoint(edge2, edge1.p2);
    if (hasInternalPoint < 2) {
        return false;
    }
    if (hasInternalPoint === 2) {
        return !checkSamePoints(edge1, edge2);
    }
    return true;
    // console.log("hasInternalPoint", hasInternalPoint);
    // // const checkSamePoints = !((nearlyEqualsPoints(edge1.p1, edge2.p1) || nearlyEqualsPoints(edge1.p1, edge2.p2)) ^
    // //                           (nearlyEqualsPoints(edge1.p2, edge2.p1) || nearlyEqualsPoints(edge1.p2, edge2.p2)))
    // return liesOnLine && (hasInternalPoint > 1); // && checkSamePoints;
}

const checkSamePoints = function (edge1, edge2) {
    return nearlyEqualsPoints(edge1.p1, edge2.p1) || nearlyEqualsPoints(edge1.p1, edge2.p2) ||
                              nearlyEqualsPoints(edge1.p2, edge2.p1) || nearlyEqualsPoints(edge1.p2, edge2.p2)
}

const checkPointLiesOnLine = function (edge, point) {
    const a = edge.p1.y - edge.p2.y;
    if (nEqual(a, 0, PRECISION)) {
        return nEqual(edge.p1.y, point.y, PRECISION);
    }
    const b = edge.p2.x - edge.p1.x;
    if (nEqual(b, 0, PRECISION)) {
        return nEqual(edge.p1.x, point.x, PRECISION);
    }
    const c = edge.p1.x*edge.p2.y - edge.p2.x*edge.p1.y;

    const toCheck = a*point.x + b*point.y + c;
    return nEqual(toCheck, 0, PRECISION);
}

const checkInternalPoint = function (edge, point) {
    const ac = Point.distance(edge.p1, point);
    const bc = Point.distance(edge.p2, point);
    const ab = Point.distance(edge.p1, edge.p2);

    // const samePoint = nEqual(ac, 0, PRECISION) || nEqual(bc, 0, PRECISION);

    // return (ac + PRECISION < ab)
    return nEqual(ac+bc, ab, PRECISION) ;
    // return nEqual(ac+bc, ab, PRECISION) && !(nearlyEqualsPoints(point, edge.p1) || nearlyEqualsPoints(point, edge.p2));
}

const nEqual = function (a, b, epsilon) {
    return Math.abs(a-b) < epsilon;
}

const nearlyEqualsPoints = function (p1, p2, t = PRECISION) {
    if (!p1 || !p2)
        return false;
    // console.log("differences", Math.abs(p1.x - p2.x), );

    return (Math.abs(p1.x - p2.x) < t && Math.abs(p1.y - p2.y) < t);
}

const checkSharedPoint = function (edge1, edge2) {
    return nearlyEqualsPoints(edge1.p1, edge2.p1) || nearlyEqualsPoints(edge1.p1, edge2.p2)
            || nearlyEqualsPoints(edge1.p2, edge2.p1) || nearlyEqualsPoints(edge1.p2, edge2.p2);
}

export let describeEdges = function (fermat) {
    let maxLeft = Infinity, left = undefined;
    let maxRight = 0, right = undefined;
    let maxBot = 0, bot = undefined;
    let maxTop = Infinity, top = undefined;
    for (let edge in fermat.edges) {
        if (fermat.edges[edge].midpoint.x <= maxLeft) {
            left = edge;
            maxLeft = fermat.edges[edge].midpoint.x;
        }
        if (fermat.edges[edge].midpoint.x >= maxRight) {
            right = edge;
            maxRight = fermat.edges[edge].midpoint.x;
        }
        if (fermat.edges[edge].midpoint.y >= maxBot) {
            bot = edge;
            maxBot = fermat.edges[edge].midpoint.y;
        }
        if (fermat.edges[edge].midpoint.y <= maxTop) {
            top = edge;
            maxTop = fermat.edges[edge].midpoint.y;
        }
    }
    return {
        "fermat": fermat,
        "left": left,
        "right": right,
        "bot": bot,
        "top": top,
    }
}

const getLeftPoint = function (p1, p2) {
    return p1.x < p2.x ? p1 : p2;
}

const getRightPoint = function (p1, p2) {
    return p1.x > p2.x ? p1 : p2;
}
