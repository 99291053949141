import store from "../../store";
import {planePolygonForShowPlanes} from "../primitives/planePolygon";
import {bus} from "../../vue_bus";
import {planesDrawer} from "../libs/drawPlanes";
import {sortObjectsByZindex} from "../libs/sortObjectsByZindex";
import {getAdditionalPolygonPoints} from "../primitives/additionalPlane";
import pSBC from "shade-blend-color";
import {getRenderBase64} from "../libs/renderSaver";


export const materialTool = async function(fabricJS, options) {
    this.fabricJS = fabricJS;
    this.photoID = store.state.route.params.photoID;
    if (options.photoID)
        this.photoID = options.photoID;
    this.withTexture = options.withTexture;
    this.sourceImage = options.sourceImage;
    this.scale = options.scale;
    this.planeList = store.getters["active/GET_ALL_PHOTO"][this.photoID].planeList;
    // this.planeList = store.getters["active/ALL_PLANES_FROM_PHOTO"];
    this.polygonList = [];
    this.watchersDestroyCallbacks = [];
    this.selected = null;

    this.fabricJS.on('mouse:down', (e) => {
        if(e.target) {
            if (!e.target.planeID) {
                bus.$emit("unSelectedPlaneFromPlaneVisualisator", e.target.planeID);
            }

            if (e.target.isPlane) {
                bus.$emit("selectedPlaneFromPlaneVisualisator", e.target.planeID);
            }
        }
    });

    this.fabricJS.on('mouse:dblclick', (e) => {
        if (!e.target)
            return

        if (e.target.isPlane) {
            bus.$emit("dblclickPlaneFromPlaneVisualisator", e.target.planeID);
        }
    });
    this.design = store.getters["active/DESIGN"];

    this.planesDrawer = await new planesDrawer(
        this.fabricJS,
        this.planeList,
        this.design,
        this.photoID,
        this.sourceImage,
        this.scale,
        true);

    let promises = [];
    this.polygonList = [];

    let index = 0;


    // this.renderingPromises = [];
    this.activeJobsForRender = {};
    this.activeRenderInProgress = false;

    this.renderActive = async () => {
        if (this.activeRenderInProgress)
            return
        console.log(Object.keys(this.activeJobsForRender))
        if (!Object.keys(this.activeJobsForRender).length)
            return

        this.activeRenderInProgress = true;
        let promises = [];
        for (let planeID in this.activeJobsForRender) {
            let promise = this.planesDrawer.setPolygonSurface(this.activeJobsForRender[planeID],planeID)
            promises.push(promise);
            await promise;
            delete this.activeJobsForRender[planeID];
            // store.dispatch("IN_PROGRESS", promise);
        }
        this.activeRenderInProgress = false;

        if (Object.keys(this.activeJobsForRender).length) {
            await this.renderActive();
            return
        }

        this.jobsForRender = {};

        return Promise.all(promises);
    }


    this.jobsForRender = {};

    this.renderAll = async () => {
        let promises = [];
        for (let planeID in this.jobsForRender) {
            let promise = this.planesDrawer.setPolygonSurface(this.jobsForRender[planeID],planeID)
            promises.push(promise);
            await promise;
            // store.dispatch("IN_PROGRESS", promise);
        }
        this.jobsForRender = {};
        return Promise.all(promises);
    }

    for (let planeID in this.planeList) {
        planeID = parseInt(planeID);
        if (!this.planeList[planeID].isComplete)
            continue;

        let pointList = this.planeList[planeID].points;

        if (this.planeList[planeID].type === "additional")
            pointList = getAdditionalPolygonPoints(this.planeList[planeID], 1, 10);

        let polygon = planePolygonForShowPlanes(
            pointList,
            this.planeList[planeID].type,
            this.scale,
            planeID
        );
        polygon.set({
            opacity: 0.0,
        })

        this.polygonList.push(polygon)
        this.fabricJS.add(polygon);

        if (!this.design)
            continue;

        if ((["door", "window", "cutout"].includes(this.planeList[planeID].type))) {
            promises.push(this.planesDrawer.setPolygonSurface(polygon, planeID));
            continue;
        }

        if (!this.design.designGroupList)
            continue;

        if (!this.design.designGroupList[this.planeList[planeID].idDesignGroup])
            continue;

        let renderPromise = this.planesDrawer.setPolygonSurface(polygon, planeID, this.photoID === store.state.route.params.photoID)
        promises.push(renderPromise);
        // if (this.photoID === store.state.route.params.photoID) {
        //     store.dispatch("IN_PROGRESS", promises[promises.length - 1]);
        // }



        this.watchersDestroyCallbacks.push(store.watch(
            ()=>[
                this.design.designGroupList[this.planeList[planeID].idDesignGroup][this.planeList[planeID].type].uuidProduct,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup][this.planeList[planeID].type].textureRotationAngle,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].shadows,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].lightCorrection,
            ],
            async (newUUIDs)=>{
                if (this.photoID !== store.state.route.params.photoID) {
                    this.jobsForRender[planeID] = polygon;
                } else {
                    this.activeJobsForRender[planeID] = polygon;
                    this.renderActive();
                    // if (!Object.keys(this.activeJobsForRender)) {
                    //     this.planesDrawer.setPolygonSurface(polygon, planeID)
                    // } else {
                    //
                    // }

                    // let promise = this.planesDrawer.setPolygonSurface(polygon, planeID)

                    // await store.dispatch("IN_PROGRESS_WITHOUT_PERCENT", promise);
                }
            }));

        this.watchersDestroyCallbacks.push(store.watch(
            ()=>[
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].additional.uuidProduct,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].shadows,
            ],

            (newUUIDs)=>{
                let promise = this.planesDrawer.setPolygonSurface(polygon, planeID)
                store.dispatch("IN_PROGRESS", promise);
            }));

        index+=1;
    }


    await Promise.all(promises);

    this.fabricJS.renderAll();
    sortObjectsByZindex(this.fabricJS);

    this.destroy = () => {
        this.watchersDestroyCallbacks.forEach((cb)=>{cb()});
        this.planesDrawer.destroy();
    }

    return this
}
