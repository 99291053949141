import {planePointer} from "../primitives/planePointer";
import store from "../../store";
import {planePolygon} from "../primitives/planePolygon";
import {perspectiveLine} from "../primitives/perspectiveLine";
import {sortObjectsByZindex} from "../libs/sortObjectsByZindex";
import {bus} from "../../vue_bus";
import {getPerspectivePoints} from "../libs/getPerspectivePoints";
import {perspectiveLineGlobal} from "../primitives/perspectiveLineGlobal";
import {solver} from "view-solver";
import {getVPCloud} from "../../math/autoVPs";
import {Planner2d} from "../../math/2dPlanReconstruction"
import {planeLine} from "../primitives/planeLine";
import {Vector2} from "three";


export const SetGlobalPerspectiveTool = async function (fabricJS) {
    this.fabricJS = fabricJS;
    this.pointZoneSize = 5;
    this.lineArray = [];
    const imageSize = await store.dispatch("active/GET_ACTIVE_IMG_SIZE");
    this.activeProjectUUID = store.state.route.params.projectUUID;
    this.photoID = store.state.route.params.photoID;

    this.width = imageSize.width;
    this.height = imageSize.height;

    this.find_intersection = (line1, line2) => {
        let xdiff = [line1.point1.x - line1.point2.x, line2.point1.x - line2.point2.x];
        let ydiff = [line1.point1.y - line1.point2.y, line2.point1.y - line2.point2.y];

        let det = (a, b) => {
            return a[0] * b[1] - a[1] * b[0];
        }

        let div = det(xdiff, ydiff);
        if (div === 0) {
            return null;
        }
        let d = [det([line1.point1.x, line1.point1.y], [line1.point2.x, line1.point2.y]),
            det([line2.point1.x, line2.point1.y], [line2.point2.x, line2.point2.y])];
        let x = det(d, xdiff)/div;
        let y = det(d, ydiff)/div;
        return  new Vector2(x, y);

    }

    // let pointCloud  = getVPCloud(lineCloud);
    let planeList = store.getters["active/ALL_PLANES_FROM_PHOTO"];
    let wallList = store.getters["active/ALL_WALL_FROM_PHOTO"];
    let photoData = await store.dispatch("active/GET_ACTIVE_IMG_SIZE");
    let pointCloud = getVPCloud(planeList, photoData.width, photoData.height);
    let planner = new Planner2d();
    let baseLines = planner.findBaseLine(wallList, pointCloud);
    let baseLinePoints;
    if (baseLines) {
        baseLines.forEach((segment)=>{
            let line = new planeLine(segment.p1, segment.p2);
            this.fabricJS.add(line);
        })

        if (store.getters[`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/BASE_LINE`].length === 0)
        {
            baseLinePoints = [];
            baseLinePoints.push(baseLines[0].p1);
            baseLines.forEach((segment)=>{
                baseLinePoints.push(segment.p2);
            })

            console.log("HOPEFULLY FOUND BASELINE", baseLines);
            store.commit(`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/BASE_LINE`, baseLinePoints);
        } else {
            baseLinePoints = store.getters[`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/BASE_LINE`];
        }
    }

    if (pointCloud) {
        pointCloud["pointCloud"].forEach((point) => {
            let fabricPoint = new fabric.Circle({
                radius: 2,
                originX: 'center',
                originY: 'center',
                top: point.y,
                left: point.x,
                strokeWidth: 1,
                evented: false,
                fill: 'rgba(255,0,0,1)',
            });
            this.fabricJS.add(fabricPoint)
        })
    }

    let p11x = 380, p11y = 380,
        p12x = 620, p12y = 455;
    let p21x = 380, p21y = 780,
        p22x = 620, p22y = 735;
    let p31x = 59, p31y = 687,
        p32x = 278, p32y = 771;
    let p41x = 86, p41y = 500,
        p42x = 309, p42y = 367;
    if (pointCloud) {
        p11x = pointCloud["rightHorizontals"][0].p1.x; p11y = pointCloud["rightHorizontals"][0].p1.y;
        p12x = pointCloud["rightHorizontals"][0].p2.x; p12y = pointCloud["rightHorizontals"][0].p2.y;
        p21x = pointCloud["rightHorizontals"][1].p1.x; p21y = pointCloud["rightHorizontals"][1].p1.y;
        p22x = pointCloud["rightHorizontals"][1].p2.x; p22y = pointCloud["rightHorizontals"][1].p2.y;
        p31x = pointCloud["leftHorizontals"][0].p1.x; p31y = pointCloud["leftHorizontals"][0].p1.y;
        p32x = pointCloud["leftHorizontals"][0].p2.x; p32y = pointCloud["leftHorizontals"][0].p2.y;
        p41x = pointCloud["leftHorizontals"][1].p1.x; p41y = pointCloud["leftHorizontals"][1].p1.y;
        p42x = pointCloud["leftHorizontals"][1].p2.x; p42y = pointCloud["leftHorizontals"][1].p2.y;
    }

    if (store.getters[`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/PERSPECTIVE_LINES`].length !== 0) {
        this.linesPreset = store.getters[`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/PERSPECTIVE_LINES`];
    } else
        this.linesPreset = [
            {
                point1: {x: p11x, y: p11y},
                point2: {x: p12x, y: p12y},
                color: "#fc1212",
            },
            {
                point1: {x: p21x, y: p21y},
                point2: {x: p22x, y: p22y},
                color: "#fc1212",
            },
            {
                point1: {x: p31x, y: p31y},
                point2: {x: p32x, y: p32y},
                color: "#7fd70a",
            },
            {
                point1: {x: p41x, y: p41y},
                point2: {x: p42x, y: p42y},
                color: "#7fd70a",
            }]

    let lineSegments1 = [[{
        "x": p11x/this.width,
        "y": p11y/this.height
    }, {
        "x": p12x/this.width,
        "y": p12y/this.height
    }], [{
        "x": p21x/this.width,
        "y": p21y/this.height
    }, {
        "x": p22x/this.width,
        "y": p22y/this.height
    }]];

    let lineSegments3 = [[{
        "x": p31x/this.width,
        "y": p31y/this.height
    }, {
        "x": p32x/this.width,
        "y": p32y/this.height
    }], [{
        "x": p41x/this.width,
        "y": p41y/this.height
    }, {
        "x": p42x/this.width,
        "y": p42y/this.height
    }]];

    let bottomPoint = planner.findBottomPoint(planeList);

    let testInputJSON =
        {
            "imageSize": {"width": this.width, "height": this.height},
            "globalSettings": {"calibrationMode": "TwoVanishingPoints"},
            "calibrationSettingsBase": {
                "cameraData": {"presetId": null, "presetData": null, "customSensorWidth": 36, "customSensorHeight": 24},
                "firstVanishingPointAxis": "xPositive",
                "secondVanishingPointAxis": "zNegative",
                "thirdVanishingPointAxis": "yPositive",
            },
            "calibrationSettings2VP": {"principalPointMode": "Default", "quadModeEnabled": false},
            "controlPointsStateBase": {
                "principalPoint": {"x": 0.5, "y": 0.5},
                "origin": {"x": bottomPoint.x/this.width, "y": bottomPoint.y/this.height},
                "firstVanishingPoint": {
                    "lineSegments": [[{
                        "x": 0.7306817806308229,
                        "y": 0.44419721706202614
                    }, {"x": 0.32888991201533535, "y": 0.6450040925470991}], [{
                        "x": 0.36015814865022416,
                        "y": 0.8709043994451701
                    }, {"x": 0.4920788999884722, "y": 0.7766807873869722}]]
                }
            },
            "controlPointsState2VP": {
                "secondVanishingPoint": {
                    "lineSegments": [[{
                        "x": 0.11463875653629052,
                        "y": 0.3993033709798703
                    }, {"x": 0.32891829622770213, "y": 0.6473397270025979}], [{
                        "x": 0.293313425402867,
                        "y": 0.15181240324678352
                    }, {"x": 0.5954693007144473, "y": 0.2231216130946473}]]
                },
                "thirdVanishingPoint": {
                    "lineSegments": [[{
                        "x": bottomPoint.x/this.width,
                        "y": bottomPoint.y/this.height
                    }, {"x": 0.30893837018837017, "y": 0.3142575174095972}], [{
                        "x": 0.6774662162162162,
                        "y": 0.6094667009961638
                    }, {"x": 0.7070341932841933, "y": 0.35057030079777063}]]
                }
            }
        }

    // console.log(testInputJSON);
    testInputJSON.controlPointsStateBase.firstVanishingPoint.lineSegments = lineSegments1;
    testInputJSON.controlPointsState2VP.secondVanishingPoint.lineSegments = lineSegments3;

    // let firstVanishingPoint = this.find_intersection(this.lines.fabric[0],this.lines.fabric[1]);
    // let secondVanishingPoint = this.find_intersection(this.lines.fabric[4],this.lines.fabric[5]);
    // if (firstVanishingPoint.x < secondVanishingPoint.x) {
    //     testInputJSON.controlPointsStateBase.firstVanishingPoint.lineSegments = lineSegments3;
    //     testInputJSON.controlPointsState2VP.secondVanishingPoint.lineSegments = lineSegments1;
    // } else {
    //     testInputJSON.controlPointsStateBase.firstVanishingPoint.lineSegments = lineSegments1;
    //     testInputJSON.controlPointsState2VP.secondVanishingPoint.lineSegments = lineSegments3;
    // }



    this.getPointOnLine = (p1, p2, d) => {
        let x, y;
        let angle = Math.atan2(p2.y - p1.y, p2.x - p1.x)
        x = p1.x + (d * Math.cos(angle))
        y = p1.y + (d * Math.sin(angle))
        return {x, y}
    }

    this.drawAll = () => {
        this.linesPreset.forEach((preset) => {
            this.lineArray.push(new perspectiveLineGlobal(preset.point1, preset.point2, fabricJS, preset.color));
        })

        sortObjectsByZindex(this.fabricJS);
    }

    this.addMovingEvent = () => {
        this.fabricJS.on('object:moving', (event) => {
            this.fabricJS.renderAll();
        });
    }

    this.destroy = () => {
        bus.$off("AcceptGlobalPerspective");
    }

    bus.$on("AcceptPlaneDrawing", async (callback) => {
        this.unsaveChanges = false;
        let linesPresetToSave = [
            {
                point1: {x: this.lineArray[0].x1, y: this.lineArray[0].y1},
                point2: {x: this.lineArray[0].x2, y: this.lineArray[0].y2},
                color: "#fc1212",
            },
            {
                point1: {x: this.lineArray[1].x1, y: this.lineArray[1].y1},
                point2: {x: this.lineArray[1].x2, y: this.lineArray[1].y2},
                color: "#fc1212",
            },
            {
                point1: {x: this.lineArray[2].x1, y: this.lineArray[2].y1},
                point2: {x: this.lineArray[2].x2, y: this.lineArray[2].y2},
                color: "#7fd70a",
            },
            {
                point1: {x: this.lineArray[3].x1, y: this.lineArray[3].y1},
                point2: {x: this.lineArray[3].x2, y: this.lineArray[3].y2},
                color: "#7fd70a",
            }]
        console.log(this.activeProjectUUID, this.photoID);
        this.lineArray
        let lineSegments1 = [[{
            "x": this.lineArray[0].x1/this.width,
            "y": this.lineArray[0].y1/this.height
        }, {
            "x": this.lineArray[0].x2/this.width,
            "y": this.lineArray[0].y2/this.height
        }], [{
            "x": this.lineArray[1].x1/this.width,
            "y": this.lineArray[1].y1/this.height
        }, {
            "x": this.lineArray[1].x2/this.width,
            "y": this.lineArray[1].y2/this.height
        }]];

        let lineSegments3 = [[{
            "x": this.lineArray[2].x1/this.width,
            "y": this.lineArray[2].y1/this.height
        }, {
            "x": this.lineArray[2].x2/this.width,
            "y": this.lineArray[2].y2/this.height
        }], [{
            "x": this.lineArray[3].x1/this.width,
            "y": this.lineArray[3].y1/this.height
        }, {
            "x": this.lineArray[3].x2/this.width,
            "y": this.lineArray[3].y2/this.height
        }]];
        testInputJSON.controlPointsStateBase.firstVanishingPoint.lineSegments = lineSegments1;
        testInputJSON.controlPointsState2VP.secondVanishingPoint.lineSegments = lineSegments3;
        console.log(JSON.parse(JSON.stringify(testInputJSON)));
        store.commit(`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/PERSPECTIVE_LINES`,linesPresetToSave);
        store.commit(`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/CAMERA_SETTINGS`,solver.solve2vp(testInputJSON));
        // callback()
    })

    this.onUpdateScale = (scale) => {

    }

    this.drawAll();
    this.addMovingEvent();
}
