var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "18",
        viewBox: "0 0 16 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M11.6182 13.5136L7.55564 17.0129C7.20004 17.3192 6.64051 17.0694 6.6411 16.5929L6.64341 14.7471C2.93984 14.7001 0.00397739 13.9541 0.00837256 10.4442C0.0101465 9.0276 0.924524 7.62531 1.93423 6.89283C2.24932 6.66424 2.6976 6.95219 2.58107 7.32322C1.53138 10.6654 3.07165 11.555 6.64734 11.611L6.64988 9.58391C6.65048 9.10663 7.21107 8.8594 7.56547 9.16625L11.6192 12.676C11.8742 12.8968 11.874 13.2932 11.6182 13.5136Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M3.69878 3.63923L7.75691 0.134904C8.11213 -0.171869 8.67197 0.0771696 8.67197 0.553712V2.3995C12.3756 2.4419 15.3124 3.18417 15.3124 6.69405C15.3124 8.1107 14.3998 9.51413 13.391 10.2479C13.0762 10.4769 12.6275 10.1895 12.7436 9.8183C13.7891 6.47476 12.2477 5.58711 8.67197 5.53564L8.67197 7.5627C8.67197 8.03998 8.11169 8.28792 7.75691 7.98151L3.69878 4.47684C3.44352 4.25637 3.44317 3.86 3.69878 3.63923Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }