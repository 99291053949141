import store from '../store/index'

export default (async (to, from, next) => {
    if(to.matched.some(record => record.meta.checkSaveProjects)) {
        if (store.getters["saveProject/LENGTH_PROJECTS_NEED_SAVE"] > 0) {
            const promiseSaveProjects = store.dispatch('saveProject/SAVE_PROJECTS');
            if(store.state.inProgress) {
                await store.state.inProgress;
            }
            store.dispatch('IN_PROGRESS', promiseSaveProjects, {root: true});
            await promiseSaveProjects;
        }

        if (store.getters["saveProject/LENGTH_PROJECTS_NEED_SAVE"] === 0) {
            next();
        }
    }
    next();
});