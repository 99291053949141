export const photoEditorData = {
    namespaced: true,
    state: {
        undoStackLength : 0,
        redoStackLength : 0
    },
    mutations: {
        UNDO_STACK_LENGTH: (state, value) => {
            state.undoStackLength = parseInt(value);
        },
        REDO_STACK_LENGTH: (state, value) => {
            state.redoStackLength = parseInt(value);
        },
    }
};
