var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "11",
        height: "18",
        viewBox: "0 0 11 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M3.72573e-07 8.52348L7.95525 17.047L10.1484 15L4.10366 8.52348L10.1484 2.04695L7.95525 -3.47735e-07L3.72573e-07 8.52348Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }