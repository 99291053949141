export const saveProject = {
    namespaced: true,
    state: {
        trackChangesToSave: true,
        projectsNeedSave: [], //UUID проектов
        timeLastSave: null,
        timerID_SaveProject: null,
        initializationModulesProcess: false
    },
    mutations: {
        INITIALIZATION_MODULES_PROCESS: (state, flag) => {
            state.initializationModulesProcess = flag;
        },
        TRACK_CHANGES_TO_SAVE: (state, flag) => {
            state.trackChangesToSave = flag;
        },
        ADD_PROJECT_NEED_SAVE: (state, projectUUID) => {
            if (!state.projectsNeedSave.includes(projectUUID)) {
                state.projectsNeedSave.push(projectUUID);
            }
        },
        REMOVE_PROJECT_NEED_SAVE: (state, projectUUID) => {
            const pos = state.projectsNeedSave.findIndex(uuid => uuid === projectUUID);
            state.projectsNeedSave.splice(pos, 1);
        },
    },
    getters: {
        LENGTH_PROJECTS_NEED_SAVE: state => {
            return state.projectsNeedSave.length;
        },
    },
    actions: {
        SAVE_PROJECTS: async (context) => {
            const copyProjectsNeedSave = context.state.projectsNeedSave.slice();
            for (let projectUUID of copyProjectsNeedSave) {
                context.commit('REMOVE_PROJECT_NEED_SAVE', projectUUID);
                await context.dispatch('projectList/SAVE_PROJECT', projectUUID, {root: true})
                    .then((flagSuccess) => {
                        if (!flagSuccess) {
                            context.commit('ADD_PROJECT_NEED_SAVE', projectUUID);
                        }
                    })
            }
        },
        ENABLE_TIMER_SAVE_PROJECT: (context) => {
            let firstStart = true;
            const handlerTimer = async () => {
                await context.dispatch('SAVE_PROJECTS');
                timerEnable();
            };
            const timerEnable = () => {
                if (context.state.timerID_SaveProject || firstStart) {
                    firstStart = false;
                    context.state.timerID_SaveProject = setTimeout(handlerTimer, 5000);
                }
            };
            timerEnable();
        },
        DISABLED_TIMER_SAVE_PROJECT: ({state, commit}) => {
            clearTimeout(state.timerID_SaveProject);
            state.timerID_SaveProject = null;
        },
        CHECK_MUTATION: function (context, mutName) {
            if (mutName.type.includes('projectList')
                && mutName.type.split('/').length > 2) {
                if(!context.state.initializationModulesProcess) {
                    if (!mutName.type.includes('RENDERED_PHOTOS')
                        && !mutName.type.includes('SIZE')
                        && !mutName.type.includes('TAKE_INTO_CALCULATIONS')
                        && !mutName.type.includes('AREA')
                        && !mutName.type.includes('PERSPECTIVE_MATRIX')
                        && !mutName.type.includes('CAMERA_SETTINGS')
                        && !mutName.type.includes('NAME')
                    ) {
                        const projectUUID = mutName.type.split('/')[1];
                        context.commit('resultImg/CLEAR', null, {root: true});
                        if (context.rootState.applicationVersion !== 'lite') {
                            context.dispatch(`projectList/${projectUUID}/designList/CLEAR_DESIGN_PHOTOS`, null, {root: true});
                        }
                    }
                    if (context.state.trackChangesToSave) {
                        const projectUUID = mutName.type.split('/')[1];
                        context.commit('ADD_PROJECT_NEED_SAVE', projectUUID);
                    }
                }
            }
        }
    }
};
