var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "50",
        height: "50",
        viewBox: "0 0 72 72",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("circle", { attrs: { cx: "36", cy: "36", r: "36", fill: "#73818A" } }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M39.7656 10L38.8643 47.4067H32.9707L32 10H39.7656ZM39.4189 61.6206H32.3467V54.3057H39.4189V61.6206Z",
          fill: "white"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }