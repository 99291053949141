import {getPerspectiveMatrix} from "../../math/perspectiveTransform";

export function Plane(type) {
    this.namespaced = true;
    this.state = {
        constructorName: 'Plane',
        isComplete: false,
        type: null,
        takeIntoCalculations: true,
        idDesignGroup: null,
        points: {},
        perspectivePoints: [],
        perspectiveMatrix: null,
        cameraSettings: null,
        subType: null,
        includesWindows: [],
        includesDoors: [],
        includesPlanes: [],
        adjacentTop: [],
        adjacentBottom: [],
        adjacentLeft: [],
        adjacentRight: [],
        verticalLines: [],
        horizontalLines: [],
        plane3d: {},
        threejs: {},
        side: null,
        neighbours: null
    }

    this.getters = {
        POINTS: state => {
            return state.points;
        },
        IS_COMPLETE: function(state) {
            return state.isComplete;
        },
        SELF: function(state) {
            return state;
        },
        PERSPECTIVE_POINTS: function(state) {
            return state.perspectivePoints;
        },
        TAKE_INTO_CALCULATIONS: function(state) {
            return state.takeIntoCalculations;
        },
        AREA: function(state) {
            return state.area;
        },
        PERSPECTIVE_MATRIX: function(state) {
            return state.perspectiveMatrix;
        },
        CAMERA_SETTINGS: function(state) {
            return state.cameraSettings;
        },
        ID_DESIGN_GROUP: state => {
            return state.idDesignGroup;
        },
        PLANE_3D: state => {
            return state.plane3d;
        },
    }

    this.mutations = {
        NEIGHBOURS: function (state, value) {
            state.neighbours = value;
        },
        SIDE: function (state, value) {
            state.side = value;
        },
        VERTICAL_LINES: function (state, arr) {
            if (arr)
                return;
            state.verticalLines.length = 0;
            for (let i = 0; i < arr.length; i++) {
                state.verticalLines.push(arr[i]);
            }
        },
        HORIZONTAL_LINES: function (state, arr) {
            if (!arr)
                return;
            state.horizontalLines.length = 0;
            for (let i = 0; i < arr.length; i++) {
                state.horizontalLines.push(arr[i]);
            }
        },
        INCLUDES_WINDOWS: function (state, arr) {
            if (!arr)
                return;
            state.includesWindows.length = 0;
            for (let i = 0; i < arr.length; i++) {
                state.includesWindows.push(arr[i]);
            }
        },
        INCLUDES_DOORS: function (state, arr) {
            if (!arr)
                return;
            state.includesDoors.length = 0;
            for (let i = 0; i < arr.length; i++) {
                state.includesDoors.push(arr[i]);
            }
        },
        INCLUDES_PLANES: function (state, arr) {
            if (!arr)
                return;
            state.includesPlanes.length = 0;
            for (let i = 0; i < arr.length; i++) {
                state.includesPlanes.push(arr[i]);
            }
        },
        ADJACENT_TOP: function (state, arr) {
            if (!arr)
                return;
            state.adjacentTop.length = 0;
            for (let i = 0; i < arr.length; i++) {
                state.adjacentTop.push(arr[i]);
            }
        },
        ADJACENT_BOTTOM: function (state, arr) {
            if (!arr)
                return;
            state.adjacentBottom.length = 0;
            for (let i = 0; i < arr.length; i++) {
                state.adjacentBottom.push(arr[i]);
            }
        },
        ADJACENT_LEFT: function (state, arr) {
            if (!arr)
                return;
            state.adjacentLeft.length = 0;
            for (let i = 0; i < arr.length; i++) {
                state.adjacentLeft.push(arr[i]);
            }
        },
        ADJACENT_RIGHT: function (state, arr) {
            if (!arr)
                return;
            state.adjacentRight.length = 0;
            for (let i = 0; i < arr.length; i++) {
                state.adjacentRight.push(arr[i]);
            }
        },
        TAKE_INTO_CALCULATIONS: function(state, stateFlag){
            state.takeIntoCalculations = stateFlag;
        },
        PERSPECTIVE_POINTS: function(state, data) {
            state.perspectivePoints = data;
        },
        IS_COMPLETE: function(state, flag) {
            state.isComplete = flag;
        },
        INIT: function (state, data) {
            for (let key in data) {
                if(data.hasOwnProperty(key)) state[key] = data[key];
            }
        },
        ADD_POINT: (state, data) => {
            state.points[data.id] = data.position;
            state.points[data.id].link = data.link;
        },
        UPDATE_PLANE_POINTS: (state, pointDict) => {
            state.points = {};
            for (let pointID in pointDict) {
                 state.points[pointID] = {
                     x: pointDict[pointID].x,
                     y: pointDict[pointID].y,
                     link: pointDict[pointID].link,
                 }
            }
        },
        CHANGE_POINT_POSITION: (state, dataPoint) => {
            state.points[dataPoint.id].x = dataPoint.position.x;
            state.points[dataPoint.id].y = dataPoint.position.y;
        },
        REMOVE_POINT_BY_ID: (state, idPoint) => {
            delete state.points[idPoint];
        },
        ID_DESIGN_GROUP: (state, idGroup) => {
            if (idGroup)
                state.idDesignGroup = parseInt(idGroup);
            else
                state.idDesignGroup = null;
        },
        AREA: (state, idGroup) => {
            state.idDesignGroup = parseInt(idGroup);
        },
        PERSPECTIVE_MATRIX: (state, perspectiveMatrix) => {
            state.perspectiveMatrix = perspectiveMatrix;
        },
        CAMERA_SETTINGS: (state, cameraSettings) => {
            state.cameraSettings = cameraSettings;
        },
        PLANE_3D: (state, options) => {
            state.plane3d = options;
        },
    }

    this.actions = {
        CREATE_POINT: function (context, data) {
            let maxID = 0;
            for (let idPoint in context.state.points) {
                if (maxID < parseInt(idPoint)) maxID = parseInt(idPoint);
            }
            let idNewPoint = maxID + 1;
            const position = {
                x: data.x,
                y: data.y
            };
            context.commit('ADD_POINT', {
                id: idNewPoint,
                position,
                link: data.link
            });
            return idNewPoint;
        },
        GET_PERSPECTIVE_MATRIX: async function (context, inputData) {
            if (context.getters['PERSPECTIVE_MATRIX'])
                return context.getters['PERSPECTIVE_MATRIX']
            else {
                let perspectiveMatrix = await getPerspectiveMatrix({
                    planeID: inputData.planeID,
                    photoID: inputData.photoID,
                    scale: 1 / window.devicePixelRatio
                })
                context.commit('PERSPECTIVE_MATRIX',perspectiveMatrix)
                return perspectiveMatrix
            }
        },
        INIT: function (context, inputData) {
            const pathPlane = inputData.path;
            const planeData = inputData.data;
            // !!!!!!!!!!!!!!!!!!!!!! ВАЖНО !!!!!!!!!!!!
            // INIT должен вызываться перед созданием каких-либо модулей.
            // Иначе, данные модули будут созданны не верно
            context.commit('INIT', planeData);
        }
    }
}
