import {http_request} from "../../httpConfig";

export const customers = {
    namespaced: true,
    state: () => ({
        numberLoadedItems: 50,
        customers: [],
        waitingResponseToCustomerRequest: false,
        allUploaded: false,
        mask: ''
    }),
    mutations: {
        CUSTOMERS: (state, customers) => {
            if(customers.length !== 0)
                state.customers = state.customers.concat(customers);
            else
                state.allUploaded = true;
        },
        SAVE_CUSTOMER: (state, dataCustomer) => {
                let indexCustomer = state.customers.findIndex(customer => customer.uuid === dataCustomer.uuid);
                if (indexCustomer === -1) {
                    state.customers.unshift(dataCustomer);
                } else {
                    state.customers.splice(indexCustomer, 1, dataCustomer)
                }
        },
        DELETE_CUSTOMER: (state, uuidCustomer) => {
            let indexCustomer = state.customers.findIndex(customer => customer.uuid === uuidCustomer);
            if (indexCustomer !== -1) {
                console.log(state.customers.splice(indexCustomer, 1));
            }
        },
        CLEAR_CUSTOMERS: (state) => {
            state.customers.splice(0);
            state.allUploaded = false;
        },
        MASK: (state, value) => {
            state.mask = value;
        }
    },
    getters: {
        SELF: state => {
            return state.customers;
        }
    },
    actions: {
        GET_CUSTOMERS: async function (context, inputData) {
            if(context.state.waitingResponseToCustomerRequest || context.state.allUploaded)
                return

            context.state.waitingResponseToCustomerRequest = true;
            const mask = context.state.mask.trim();
            let dataForSend = {
                part: {
                    // from: context.getters.LENGTH,
                    from: context.state.customers.length,
                    count: context.state.numberLoadedItems
                }
            };
            if(mask.length !== 0){
                dataForSend.find = {
                    mask,
                    field: 'name'
                };
            }
            console.log(dataForSend);
            await http_request.post('/get_customers', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('CUSTOMERS', request.data.customers);
                })
                .catch((error) => {
                    console.error(error);
                    context.state.allUploaded = true;
                });

            context.state.waitingResponseToCustomerRequest = false;
        },
        SAVE_CUSTOMER: async function (context, inputData) {
            console.log(inputData);
            await http_request.post('/save_customer', inputData)
                .then((request) => {
                    console.log(request.data);
                    context.commit('SAVE_CUSTOMER', request.data.customer);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        DELETE_CUSTOMER: async function (context, uuidCustomer) {
            await http_request.post('/delete_customer', {uuid: uuidCustomer})
                .then((request) => {
                    console.log(request.data);
                    context.commit('DELETE_CUSTOMER', uuidCustomer);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
};
