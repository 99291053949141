<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Результат
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="result-modal-options-buttons">
            <div class="lover-bar-button std-gray-border" v-on:click="createPDF">
                <SVGSavePDF/>
            </div>
            <div class="lover-bar-button std-gray-border" v-on:click="openModalContactManager">
                <SVGContactManager/>
            </div>
            <div class="lover-bar-button std-gray-border" v-on:click="openModalShare">
                <SVGShare/>
            </div>
        </div>
        <div class="result-modal-image-container">
            <div class="arrow-photo-left" style="left: 75px" v-on:click="previousPhoto()">
                <SVGArrowSwitchPhotoLeft/>
            </div>
            <div class="arrow-photo-right" style="right: 75px" v-on:click="nextPhoto()">
                <SVGArrowSwitchPhotoRight/>
            </div>
            <div class="result-modal-photo-navigation-tip">
                <SVGRotateHouse/>
                <div v-for="(photo, photoId) in photoList" class="house-rotation-mark"
                     :class="{green: photoId == activePhotoId}" v-on:click="activePhotoId = photoId"></div>
            </div>
            <img class="result-modal-image" :src="urlForPhoto(srcProjectPhoto)"/>
        </div>
        <vue-custom-scrollbar class="result-modal-products-carousel">
            <div v-for="product in products" class="result-modal-product-card">
                <img class="result-modal-product-card-img" :src="urlForPhoto(product.preview)">
                <div class="result-modal-product-card-body">
                    <div class="result-modal-product-card-label">
                        {{product.name}}
                    </div>
                    <!--                    <div class="result-modal-product-card-characteristic">-->
                    <!--                        Цвет: Кедр-->
                    <!--                    </div>-->
                    <!--                    <div class="result-modal-product-card-characteristic">-->
                    <!--                        Покрытие: ECOSTEEL-->
                    <!--                    </div>-->
                    <!--                    <div class="result-modal-product-card-characteristic">-->
                    <!--                        Толщина, мм: 0.5-->
                    <!--                    </div>-->
                </div>
                <div class="result-modal-product-card-borderline"></div>
                <div class="blue-button result-modal-product-card-shop-btn"
                     v-on:click="openLinkInOnlineStore(product.data_json)">
                    Страница в магазине
                </div>
            </div>
            <!--            <div class="result-modal-product-card">-->
            <!--                <img class="result-modal-product-card-img" :src="urlForStatic('/img/tmp/Monterrey_RAL_1014.png')">-->
            <!--                <div class="result-modal-product-card-body">-->
            <!--                    <div class="result-modal-product-card-label">-->
            <!--                        Металлочерепица МП Ламонтерра (ПЭ-01-3005-0.4)-->
            <!--                    </div>-->
            <!--                    <div class="result-modal-product-card-characteristic">-->
            <!--                        Цвет: Кедр-->
            <!--                    </div>-->
            <!--                    <div class="result-modal-product-card-characteristic">-->
            <!--                        Покрытие: ECOSTEEL-->
            <!--                    </div>-->
            <!--                    <div class="result-modal-product-card-characteristic">-->
            <!--                        Толщина, мм: 0.5-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="result-modal-product-card-borderline"></div>-->
            <!--                <div class="blue-button result-modal-product-card-shop-btn">-->
            <!--                    Страница в магазине-->
            <!--                </div>-->
            <!--            </div>-->
        </vue-custom-scrollbar>
    </div>
</template>

<script>
    import SVGArrowSwitchPhotoLeft from "../../SVGMP/SVGArrowSwitchPhotoLeft";
    import SVGArrowSwitchPhotoRight from "../../SVGMP/SVGArrowSwitchPhotoRight";
    import SVGColorCards from "../../SVGMP/SVGColorCards";
    import SVGRotateHouse from "../../SVGMP/SVGRotateHouse";
    import {urlForStaticVue} from "../../common";
    import SVGClose from "../../SVGMP/SVGClose";
    import SVGContactManager from "../../SVGMP/SVGContactManager";
    import SVGSavePDF from "../../SVGMP/SVGSavePDF";
    import SVGShare from "../../SVGMP/SVGShare";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import ModalContactManager from "./ModalContactManager";
    import {bus} from "../../../vue_bus";
    import {loadResultPDF} from "../../../reportGenerator/createPdf";
    import ModalShare from "./ModalShare";
    import {http_request} from "../../../httpConfig";

    export default {
        name: "ModalResult",
        mixins: [urlForStaticVue],
        components: {
            SVGShare,
            SVGSavePDF,
            SVGContactManager,
            SVGClose,
            SVGRotateHouse,
            SVGColorCards,
            SVGArrowSwitchPhotoRight,
            SVGArrowSwitchPhotoLeft,
            vueCustomScrollbar
        },
        data() {
            return {
                products: [],
                activePhotoId: null,
            }
        },
        computed: {
            photoList() {
                return this.$store.getters['active/GET_ALL_PHOTO'];
            },
            srcProjectPhoto() {
                const projectUUID = this.$store.state.route.params.projectUUID;
                const designID = this.$store.state.route.params.designID;
                const renderedPhoto = this.$store.getters[`projectList/${projectUUID}/designList/${designID}/RENDERED_PHOTO_BY_ID`](this.activePhotoId);
                if (renderedPhoto)
                    return renderedPhoto;

                const photo = this.photoList[this.activePhotoId];
                if (photo) {
                    return photo.src;
                }
            }
        },
        methods: {
            nextPhoto() {
                const photosIds = Object.keys(this.photoList);
                const indexPhoto = photosIds.findIndex(photoId => photoId == this.activePhotoId);
                if (indexPhoto < photosIds.length - 1) {
                    this.activePhotoId = photosIds[indexPhoto + 1];
                }
            },
            previousPhoto() {
                const photosIds = Object.keys(this.photoList);
                const indexPhoto = photosIds.findIndex(photoId => photoId == this.activePhotoId);
                if (indexPhoto > 0) {
                    this.activePhotoId = photosIds[indexPhoto - 1];
                }
            },
            urlForPhoto(url) {
                return this.$store.getters[`projectConfiguration/GENERATE_URL`](url);
            },
            openLinkInOnlineStore(dataJson) {
                if (!(dataJson && dataJson.linkInOnlineStore)) {
                    alert('Нет ссылки на продукт в магазине.')
                    return;
                }
                window.open(dataJson.linkInOnlineStore);
            },
            openModalContactManager() {
                this.$modal.show(ModalContactManager, {}, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 515,
                });
            },
            async createPDF() {
                bus.$emit("saveRenderedImage", async (src) => {
                    const promisePDF = loadResultPDF(await this.$store.dispatch('costLite/GET_COST'), [src]);
                });
            },
            openModalShare() {
                bus.$emit("saveRenderedImage", (src) => {
                    this.$modal.show(ModalShare, {
                        src: src,
                    }, {
                        height: 'auto',
                        pivotY: 0.2,
                        width: 515,
                    });
                });
            },
            getProducts(arrUUIDs) {
                if (!(arrUUIDs && arrUUIDs.length > 0)) {
                    alert('У данного проекта не выбраны продукты.')
                    return;
                }
                http_request.post('/get_products', {
                    uuid: arrUUIDs
                })
                    .then((request) => {
                        this.products.push(...request.data.products)
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
            }
        },
        mounted() {
            const projectUUID = this.$route.params.projectUUID;
            const productsUsed = this.$store.getters[`projectList/${projectUUID}/designList/PRODUCTS_USED`];
            this.getProducts(productsUsed);
            this.activePhotoId = this.$route.params.photoID;
        }
    }
</script>

<style scoped>

</style>
