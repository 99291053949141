import {http_request} from "../../httpConfig";

export const productCategories = {
    namespaced: true,
    state: {
        dataLoadFlag: false,
        selectedCategories: [],
        categories: {},
    },
    getters: {
        CATEGORIES: state => {
            return state.categories;
        },
        SELECTED_CATEGORY: state => {
            if (state.selectedCategories.length === 0) {
                return state.categories;
            }

            let availableCategories = null;
            for (let paramCategory of state.selectedCategories) {
                let idCategory = paramCategory.idCategory;
                if (availableCategories) {
                    availableCategories = availableCategories.subcategories[idCategory];
                } else {
                    availableCategories = state.categories[idCategory];
                }
            }
            return availableCategories;
        },
    },
    mutations: {
        DATA_LOAD_FLAG: function (state, value){
            state.dataLoadFlag = value;
        },
        SELECTED_CATEGORIES: (state, newSelectCategory) => {
            state.selectedCategories = newSelectCategory;
        },
        ADD_SELECTED_CATEGORIES: (state, selectCategory) => {
            state.selectedCategories.push(selectCategory);
        },
        BACK_TO_CATEGORY: (state, idCategory) => {
            if(!idCategory) {
                state.selectedCategories.splice(0, state.selectedCategories.length);
                return;
            }

            const index = state.selectedCategories.findIndex(elem => parseInt(elem.idCategory) === idCategory);

            if(index < 0){
                state.selectedCategories.splice(0, state.selectedCategories.length);
            } else {
                state.selectedCategories.splice(index, state.selectedCategories.length);
            }
        },
        CATEGORIES: (state, categories) => {
            state.categories = categories;
        },
    },
    actions: {
        REPRODUCE_TEMPLATE_SELECTED_CATEGORIES: ({state, commit}, templateCategories) => {
            let currentIterableCategory = state.categories;
            let categoryHierarchy = [];
            for(let idCategory of templateCategories){
                if(idCategory in currentIterableCategory){
                    categoryHierarchy.push({
                        idCategory: idCategory,
                        nameCategory: currentIterableCategory[idCategory].name
                    });
                    currentIterableCategory = currentIterableCategory[idCategory].subcategories;
                }
            }
            if(categoryHierarchy.length > 0) {
                commit('SELECTED_CATEGORIES', categoryHierarchy);
            }else {
                console.error('Ошибка открытии категории с выбранным продуктом.');
            }
        },
        GET_CATEGORIES: async function (context, data) {
            context.commit('DATA_LOAD_FLAG', true);
            await http_request.post('/get_product_categories', {})
                .then((request) => {
                    // console.log(request.data);
                    context.commit('CATEGORIES', request.data.product_categories);
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });
            context.commit('DATA_LOAD_FLAG', false);
        }
    }
};

// Для поиска всех Родительских категорий заданной категории. Как в параметре categories продукта. Вдруг пригодится :)
// const getParentCategories = (jsonCategories, idCategoryForSearch) => new Promise((resolve, reject) => {
//     const recursiveSearchParents = (jsonCategories, id, parentsInput, parentID) => {
//         let parents;
//         if (parentsInput && parentID) {
//             parents = parentsInput.slice();
//             parents.push(parentID);
//         } else {
//             parents = [];
//         }
//         for (let idCategory in jsonCategories) {
//             if (parseInt(idCategory) === id) {
//                 parents.push(idCategory);
//                 resolve(parents);
//             } else {
//                 if (Object.keys(jsonCategories[idCategory].subcategories).length !== 0) {
//                     recursiveSearchParents(jsonCategories[idCategory].subcategories, id, parents, idCategory);
//                 }
//             }
//         }
//     }
//     recursiveSearchParents(jsonCategories, idCategoryForSearch);
//     reject('Родитель не найден!');
// });