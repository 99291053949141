<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Изменить значение переменной "{{variableName}}"
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-form-grid-three" style="margin-top: 23px">
                <div class="modal-form-grid-label">
                    Значение
                </div>
                <input class="modal-form-grid-input" type="text" placeholder="Задайте значение"
                           v-model="variableData">
                <div class="admin-table-option flex-center" v-on:click="reset()">
                    <SVGRefresh/>
                </div>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-lower-buttons-grid">
                <div class="blue-button modal-accept-button" v-on:click="save">Сохранить</div>
                <div class="blue-outline-button modal-accept-button" v-on:click="$emit('close')">Отмена</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import SVGRefresh from "../../SVGMP/SVGRefresh";
    import {http_request} from "../../../httpConfig";

    export default {
        name: "ModalVariable",
        components: {
            SVGClose,
            SVGRefresh
        },
        props: {
            variable: {
                type: Object
            },
            callback: {
                type: Function
            }
        },
        data() {
            return {
                variableName: this.variable.name,
                variableData: this.variable.value
            }
        },
        methods: {
            save() {
                let newDataConfig = {
                    name: this.variableName,
                    value: this.variableData
                };

                http_request.post('/update_config', newDataConfig)
                    .then((request) => {
                        console.log(request.data);
                        if (this.callback) {
                            this.callback(request.data.config);
                        }
                        this.$emit('close');
                    })
                    .catch((error) => {
                        console.error(error);
                        alert('Ошибка при сохранении!');
                    });
            },
            reset() {
                this.variableData = this.variable.default;
            }
        }
    }
</script>

<style scoped>

</style>
