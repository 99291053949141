<template>
    <svg width="207" height="125" viewBox="0 0 207 125" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M203.14 118.979C203.14 121.752 200.892 124 198.12 124C195.347 124 193.099 121.752 193.099 118.979C193.099 118.214 193.271 117.488 193.577 116.838C194.381 115.136 196.113 113.959 198.12 113.959C200.892 113.959 203.14 116.207 203.14 118.979Z" fill="#B4FF00"/>
        <path d="M203.14 118.979C203.14 121.752 200.892 124 198.12 124C195.347 124 193.099 121.752 193.099 118.979C193.099 118.214 193.271 117.488 193.577 116.838C194.381 115.136 196.113 113.959 198.12 113.959C200.892 113.959 203.14 116.207 203.14 118.979Z" stroke="black" stroke-width="2"/>
        <path d="M203.14 118.979C203.14 121.752 200.892 124 198.12 124C195.347 124 193.099 121.752 193.099 118.979C193.099 118.214 193.271 117.488 193.577 116.838C194.381 115.136 196.113 113.959 198.12 113.959C200.892 113.959 203.14 116.207 203.14 118.979Z" stroke="black" stroke-width="2"/>
        <path d="M203.14 118.979C203.14 121.752 200.892 124 198.12 124C195.347 124 193.099 121.752 193.099 118.979C193.099 118.214 193.271 117.488 193.577 116.838C194.381 115.136 196.113 113.959 198.12 113.959C200.892 113.959 203.14 116.207 203.14 118.979Z" stroke="black" stroke-width="2"/>
        <path d="M203.14 118.979C203.14 121.752 200.892 124 198.12 124C195.347 124 193.099 121.752 193.099 118.979C193.099 118.214 193.271 117.488 193.577 116.838C194.381 115.136 196.113 113.959 198.12 113.959C200.892 113.959 203.14 116.207 203.14 118.979Z" stroke="black" stroke-width="2"/>
        <path d="M180.827 111.169L193.498 117.066" stroke="black" stroke-width="2"/>
        <path d="M13.8245 95.3018V114.118H87.3989V95.3018H99.2234L84.7713 50.1093H63.75V36.8174H50.6117V50.1093H13.8245L2 95.3018H13.8245Z" stroke="black" stroke-width="2"/>
        <rect x="105.005" y="1" width="27.0076" height="36.6769" rx="1" fill="white" stroke="black" stroke-width="2"/>
        <path d="M107.958 3.95325H129.059V9.16917H107.958V3.95325Z" fill="#B4FF00" stroke="black"/>
        <rect x="116.607" y="12.7201" width="3.80153" height="3.86013" fill="black" stroke="black" stroke-width="2"/>
        <rect x="115.607" y="28.1292" width="5.80153" height="5.86013" fill="black"/>
        <rect x="115.607" y="19.9233" width="5.80153" height="5.86013" fill="black"/>
        <rect x="107.986" y="12.2209" width="4.80153" height="4.86013" fill="#B4FF00" stroke="black"/>
        <rect x="124.73" y="12.7207" width="3.80153" height="3.86013" fill="black" stroke="black" stroke-width="2"/>
        <rect x="108.486" y="29.1292" width="3.80153" height="3.86013" fill="black" stroke="black" stroke-width="2"/>
        <rect x="124.73" y="29.1292" width="3.80153" height="3.86013" fill="black" stroke="black" stroke-width="2"/>
        <rect x="108.486" y="20.924" width="3.80153" height="3.86013" fill="black" stroke="black" stroke-width="2"/>
        <path d="M124.73 20.924H128.532V24.7841H124.73V20.924Z" fill="black" stroke="black" stroke-width="2"/>
        <path d="M74.041 39.1285C74.041 24.1643 86.1719 12.0334 101.136 12.0334" stroke="black" stroke-width="2"/>
        <path d="M164.251 47.496C164.251 32.5318 152.121 20.4009 137.156 20.4009" stroke="black" stroke-width="2"/>
        <path d="M152.21 82.8896V83.8896H153.21H158.558V104.858C158.558 106.515 157.215 107.858 155.558 107.858H137.013C135.356 107.858 134.013 106.515 134.013 104.858V79.3876C134.013 77.7308 135.356 76.3876 137.013 76.3876H152.21V82.8896ZM154.21 81.8896V78.1176L157.402 81.8896H154.21Z" fill="#B4FF00" stroke="black" stroke-width="2"/>
        <path d="M138.418 97.7312V100.526H136.784V92.5941H139.878C140.474 92.5941 140.997 92.7031 141.447 92.921C141.901 93.1389 142.249 93.4494 142.493 93.8525C142.736 94.252 142.858 94.7078 142.858 95.2199C142.858 95.9971 142.591 96.6108 142.057 97.0612C141.527 97.5079 140.791 97.7312 139.851 97.7312H138.418ZM138.418 96.4075H139.878C140.31 96.4075 140.639 96.3058 140.864 96.1024C141.093 95.899 141.207 95.6085 141.207 95.2308C141.207 94.8422 141.093 94.528 140.864 94.2883C140.635 94.0486 140.319 93.9252 139.916 93.9179H138.418V96.4075Z" fill="black"/>
        <path d="M143.985 100.526V92.5941H146.426C147.123 92.5941 147.746 92.7521 148.295 93.0681C148.847 93.3804 149.277 93.8271 149.586 94.4082C149.894 94.9856 150.049 95.643 150.049 96.3802V96.7452C150.049 97.4825 149.896 98.138 149.591 98.7118C149.29 99.2856 148.863 99.7305 148.311 100.047C147.759 100.362 147.136 100.522 146.442 100.526H143.985ZM145.62 93.9179V99.213H146.41C147.049 99.213 147.537 99.0042 147.875 98.5865C148.213 98.1689 148.385 97.5715 148.393 96.7943V96.3748C148.393 95.5685 148.226 94.9584 147.891 94.5444C147.557 94.1267 147.069 93.9179 146.426 93.9179H145.62Z" fill="black"/>
        <path d="M156.014 97.2845H152.876V100.526H151.242V92.5941H156.406V93.9179H152.876V95.9662H156.014V97.2845Z" fill="black"/>
        <rect x="169.396" y="54.3934" width="35.8371" height="26.7562" rx="3" fill="#B4FF00" stroke="black" stroke-width="2"/>
        <path d="M169.352 55.864L187.314 67.0149L205.373 55.864" stroke="black" stroke-width="2"/>
        <circle cx="198.28" cy="99.5341" r="5.02055" fill="#B4FF00"/>
        <circle cx="198.28" cy="99.5341" r="5.02055" stroke="black" stroke-width="2"/>
        <circle cx="198.28" cy="99.5341" r="5.02055" stroke="black" stroke-width="2"/>
        <circle cx="198.28" cy="99.5341" r="5.02055" stroke="black" stroke-width="2"/>
        <circle cx="198.28" cy="99.5341" r="5.02055" stroke="black" stroke-width="2"/>
        <path d="M180.827 107.424L193.578 101.367" stroke="black" stroke-width="2"/>
        <circle cx="176.284" cy="109.257" r="5.02055" fill="#B4FF00"/>
        <circle cx="176.284" cy="109.257" r="5.02055" stroke="black" stroke-width="2"/>
        <circle cx="176.284" cy="109.257" r="5.02055" stroke="black" stroke-width="2"/>
        <circle cx="176.284" cy="109.257" r="5.02055" stroke="black" stroke-width="2"/>
        <circle cx="176.284" cy="109.257" r="5.02055" stroke="black" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: "SVGPromptThree"
}
</script>

<style scoped>

</style>