import {Photo} from "./Photo";
import {uploadFiles} from "../../httpConfig";
import {getModuleNamespace} from "../index";

export function PhotoList() {
    this.namespaced = true;
    this.state = {
        // Здесь нельзя ничего писать! Здесь будут только динамически созданные модули проектов! Иначе сломается логика.
    }

    this.getters = {
        MAX_ID_PHOTO: state => {
            let maxID = 0;
            for (let idPhoto in state) {
                if (maxID < parseInt(idPhoto)) maxID = parseInt(idPhoto);
            }
            return maxID;
        },
        SRC_FIRST_PHOTO: (state, getters, rootState, rootGetters) => {
            for (let idPhoto in state) {
                return state[idPhoto].src;
            }
            return null;
        },
        FIRST_PHOTO_ID: state => {
            let photosID = Object.keys(state);
            if(photosID.length > 0){
                return photosID[0];
            }else {
                return null;
            }
        },
        CHECK_PHOTO_BY_ID: (state, getters, rootState, rootGetters) => idPhoto => {
            return !!state[idPhoto];
        },
        GET_PHOTO_BY_ID: state => id => {
            return state[id];
        },
        PHOTOS: state => state,
    }

    this.mutations = {}

    this.actions = {
        CREATE_PHOTO_MODULE: function (context, inputData) {
            let id;
            if(inputData.id) {
                id = inputData.id;
            } else {
                id = parseInt(context.getters.MAX_ID_PHOTO) + 1;
            }
            const modulePath = getModuleNamespace(this, context.state);
            const pathPhoto = modulePath.concat([id]);
            // const pathPhoto = inputData.path.concat([id]);
            const photoData = inputData.data;
            this.registerModule(pathPhoto, new Photo());
            context.dispatch(`${id}/INIT`, {
                path: pathPhoto,
                data: photoData,
            });
        },

        REPLACE_PHOTO:async function (context, inputData) {
            const idPhoto = inputData.idPhoto;
            const srcPhoto = inputData.srcPhoto;
            const modulePath = getModuleNamespace(this, context.state);
            const pathToProjectList = modulePath.slice(0, modulePath.length - 2);
            await context.dispatch(`${pathToProjectList.join('/')}/DELETE_MODULE`, modulePath.concat([idPhoto]), {root: true});
            context.dispatch(`CREATE_PHOTO_MODULE`, {
                id: idPhoto,
                data: {src: srcPhoto},
            });
        },

        ADD_PHOTOS: function (context, inputData) {
            const savePhotos = (photosSRC) => {
                for (let srcPhoto of photosSRC) {
                    context.dispatch('CREATE_PHOTO_MODULE', {
                        path: inputData.pathProject.concat(['photoList']),
                        data: {src: srcPhoto},
                    })
                }
            };

            const progress = (progress) => {
                console.log(progress);
            };

            uploadFiles(inputData.photosFiles, progress)
                .then((request) => {
                    console.log(request.data);
                    savePhotos(request.data.fileList);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }
}
