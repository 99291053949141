<template>
  <svg width="68" height="54" viewBox="0 0 68 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 35.698V52.7446H44.1171H67V28.5283V12.7454H44.1171V28.5283H38.322L37.3 1H29.82L28.4461 28.5283H23.1283L21.9 1H14.64L13.2525 28.5283H1.00003L1 35.698Z" stroke="currentColor" stroke-width="2"/>
  </svg>

</template>

<script>
export default {
name: "SVGIndustrialBuilding"
}
</script>

<style scoped>

</style>