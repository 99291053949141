import {http_request, uploadFiles} from "../../../httpConfig";
import {v4 as uuidv4} from "uuid";
import {Project} from "../../ProjectModuleFactory/Project";

export const requestCustomDesign = {
    namespaced: true,
    state: {
        customerUUID: null,
        projectUUID: null,
    },
    mutations: {},
    getters: {
        UPLOADED_PHOTOS: function (state, getters, rootState, rootGetters) {
            if(state.projectUUID){
                return getters[`${state.projectUUID}/photoList/PHOTOS`];
            } else {
                return {};
            }
        },
        THERE_IS_PHOTO: function (state, getters, rootState, rootGetters) {
            return !!(state.projectUUID && Object.keys(getters[`${state.projectUUID}/photoList/PHOTOS`]).length !== 0);
        }
    },
    actions: {
        SAVE_CUSTOMER: async function (context, inputData) {
            let uuid;
            if(context.state.customerUUID){
                uuid = context.state.customerUUID;
            } else {
                uuid = uuidv4();
                context.state.customerUUID = uuid;
            }

            inputData.uuid = uuid;

            return await http_request.post('/save_customer', inputData)
                .then((request) => {
                    console.log(request.data);
                    return request.data.customer;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        SAVE_NEW_PROJECT: async function (context, customerUUID) {
            let dataForSend = {
                uuid: context.state.projectUUID,
                data_json: context.state[context.state.projectUUID],
                customer: customerUUID,
            };

            return await http_request.post('/save_project_lite', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    return request.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        CREATE_NEW_PROJECT: async function (context) {
            const newProjectUUID = uuidv4();
            const templateProject = [
                {
                    data_json: {
                        constructorName: "Project",
                        name: "Новый проект"
                    },
                    uuid: newProjectUUID
                }
            ];
            await context.dispatch('projectList/createDynamicModules', {
                rootPath: ['UI', 'requestCustomDesign'],
                projects: templateProject
            },{root: true});

            context.state.projectUUID = newProjectUUID;

            return newProjectUUID;
        },
        SAVE_PHOTOS: async function (context, selectPhotos) {
            const pathProject = `UI/requestCustomDesign/${context.state.projectUUID}`;
            const pathArr = ['UI', 'requestCustomDesign', context.state.projectUUID];
            this.dispatch(pathProject + '/photoList/ADD_PHOTOS', {
                pathProject: pathArr,
                photosFiles: selectPhotos
            });
        },
        SEND_ORDER_TO_MANAGER: async function (context, customerInfo) {
            const customer = await context.dispatch('SAVE_CUSTOMER', customerInfo);

            if (!customer && customer.uuid) {
                console.log('Заказчик не создался');
                return;
            }

            await context.dispatch('SAVE_NEW_PROJECT', customer.uuid);
        }
    }
};
