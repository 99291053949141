<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="4.78943" width="4.42105" height="4.42105" fill="currentColor"/>
        <rect x="4.78955" width="4.42105" height="4.42105" fill="currentColor"/>
        <rect x="9.57886" y="9.57898" width="4.42105" height="4.42105" fill="currentColor"/>
        <rect x="4.78955" y="9.57898" width="4.42105" height="4.42105" fill="currentColor"/>
        <rect y="9.57898" width="4.42105" height="4.42105" fill="currentColor"/>
        <rect x="4.78955" y="4.78943" width="4.42105" height="4.42105" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGAdministrating"
    }
</script>

<style scoped>

</style>
