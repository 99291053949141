<template>
    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 2.44121V20.6965H4.84322V2.44121L2.92161 1L1 2.44121Z" stroke="currentColor"/>
        <path d="M4.84314 6.28468H8.68636" stroke="currentColor"/>
        <path d="M4.84314 17.8142H8.68636" stroke="currentColor"/>
        <path d="M8.68634 2.44121V20.6965H12.5296V2.44121L10.6079 1L8.68634 2.44121Z" stroke="currentColor"/>
        <path d="M12.5295 6.28468H16.3727" stroke="currentColor"/>
        <path d="M12.5295 17.8142H16.3727" stroke="currentColor"/>
        <path d="M16.3726 2.44121V20.6965H20.2158V2.44121L18.2942 1L16.3726 2.44121Z" stroke="currentColor"/>
        <path d="M20.2158 6.28468H24.059" stroke="currentColor"/>
        <path d="M20.2158 17.8142H24.059" stroke="currentColor"/>
        <path d="M24.059 2.44121V20.6965H27.9022V2.44121L25.9806 1L24.059 2.44121Z" stroke="currentColor"/>
    </svg>

</template>

<script>
    export default {
        name: "SVGFence"
    }
</script>

<style scoped>

</style>
