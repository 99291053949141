import {PhotoList} from "./PhotoList";
import {DesignList} from "./DesignList";
import {PlaneGroupList} from "./PlaneGroupList";

export function Project() {
    this.namespaced = true;
    this.state = {
        constructorName: 'Project',
        name: '',
        src: ''
    }

    this.getters = {
        NAME: state => {
            return state.name;
        }
    }

    this.mutations = {
        INIT: (state, data) => {
            state.name = data.name;
            state.src = data.src;
        },
        NAME: (state, newName) => {
            state.name = newName;
        },
        MODULE_WAS_REMOVED: function (state, modulePath) {

        }
    }

    this.actions = {
        NAME_EVERYONE: {
            root: true,
            handler: (context, newName) => {
                context.commit('NAME', newName);
            }
        },
        INIT: function (context, inputData) {
            let store = this;
            const projectPath = inputData.projectPath;
            const projectData = inputData.projectData;
            const pathCreatePhotoList = projectPath.concat(['photoList']);
            store.registerModule(pathCreatePhotoList, new PhotoList());
            const pathCreatePlaneGroupList = projectPath.concat(['planeGroupList']);
            store.registerModule(pathCreatePlaneGroupList, new PlaneGroupList());
            const pathCreateDesignList = projectPath.concat(['designList']);
            store.registerModule(pathCreateDesignList, new DesignList());
            context.commit(`INIT`, projectData);
        },
        CREATE_GROUP: async function (context, dataPlaneGroup) {
            const idPlaneGroup = await context.dispatch(`planeGroupList/CREATE_PLANE_GROUP_MODULE`, {
                data: dataPlaneGroup,
            });
            const designList = context.getters["designList/ALL_DESIGNS"];
            for(let designId in designList) {
                await context.dispatch(`designList/${designId}/designGroupList/CREATE_DESIGN_GROUP_MODULE`, {id: idPlaneGroup});
            }
            return idPlaneGroup;
        },
        DELETE_GROUP: async function (context, id) {
            const moduleID = parseInt(id);
            await context.dispatch(`planeGroupList/DELETE_PLANE_GROUP_MODULE`, moduleID);
            const designList = context.getters["designList/ALL_DESIGNS"];
            const photoList = context.getters["photoList/PHOTOS"];
            for (let designId in designList) {
                await context.dispatch(`designList/${designId}/designGroupList/DELETE_DESIGN_GROUP_MODULE`, moduleID);
            }
            for (let photoID in photoList) {
                const planeList = context.getters[`photoList/${photoID}/planeList/PLANE_LIST`];
                for (let planeID in planeList) {
                    const idDesignGroup = context.getters[`photoList/${photoID}/planeList/${planeID}/ID_DESIGN_GROUP`];
                    if(moduleID == idDesignGroup) {
                        context.commit(`photoList/${photoID}/planeList/${planeID}/ID_DESIGN_GROUP`, null);
                    }
                }
            }
        }
    }
}
