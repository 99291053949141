<template>
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="4" height="4" rx="1" fill="currentColor"/>
        <rect x="11" width="4" height="4" rx="1" fill="currentColor"/>
        <rect x="7" y="8" width="4" height="4" rx="1" fill="currentColor"/>
        <path d="M4 2H11" stroke="currentColor"/>
        <path d="M3.5 3.5L7.5 8.5" stroke="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGCategories"
    }
</script>

<style scoped>

</style>
