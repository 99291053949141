<template>
    <div>
        <div class="filter-frame no-mt">
            <div class="filter-label-grid">
                <div class="filter-label">
                    Тип
                </div>
                <div class="filter-frame-collapse-arrow" v-on:click="displayType = !displayType">
                    <SVGCollapseTop v-if="displayType"/>
                    <SVGExpandBot v-else/>
                </div>
            </div>
            <div class="filter-data-container" v-show="displayType">
                <div class="filter-data">
                    <template v-for="(prodType, index) in productTypes">
                        <div class="filter-data-checkbox-line"
                             v-if="prodType.nof_products > 0 && (index < indexBreaker || productTypesExpanded)">
                            <input type="checkbox" :value="prodType.id" v-model="productTypesSelected">
                            <div class="checkbox-label">{{prodType.name}}</div>
                            <!--                        <div class="checkbox-count">(23)</div>-->
                        </div>
                    </template>
                </div>
                <div class="expand-to-full-container" v-if="productTypes.length > indexBreaker">
                    <div class="filter-expand-to-full"
                         v-show="productTypes.length > indexBreaker && !productTypesExpanded"
                         v-on:click="productTypesExpanded = true">
                        Показать ещё
                        <SVGExpandBot class="arrow-to-full"/>
                    </div>
                    <div class="filter-expand-to-full"
                         v-show="productTypes.length > 2 && productTypesExpanded"
                         v-on:click="productTypesExpanded = false">
                        Свернуть
                        <SVGCollapseTop class="arrow-to-full"/>
                    </div>
                </div>
            </div>
        </div>
        <template v-for="category in categories">
            <div v-if="category.name.trim().toLowerCase().indexOf('цвет') === -1 && getFilledSubcategories(category.subcategories).length > 0" class="filter-frame no-mt">
                <div class="filter-label-grid">
                    <div class="filter-label">
                        {{category.name}}
                    </div>
                    <div class="filter-frame-collapse-arrow" v-on:click="collapseFilter(category.id)">
                        <SVGCollapseTop v-if="!collapseCategoryIds.includes(category.id)"/>
                        <SVGExpandBot v-else/>
                    </div>
                </div>
                <div class="filter-data-container" v-show="!collapseCategoryIds.includes(category.id)">
                    <div class="filter-data">
                        <template v-for="(subcategory, index) in getFilledSubcategories(category.subcategories)">
                            <div class="filter-data-checkbox-line"
                                 v-if="(index < indexBreaker || dynamicExpandRule[category.id])">
                                <input type="checkbox" :value="category.id + '/' + subcategory.id"
                                       v-model="subcategorySelected">
                                <div class="checkbox-label">{{subcategory.name}}</div>
                                <div class="checkbox-count">({{getNofProductsInSubcategory(subcategory.id)}})</div>
                            </div>
                        </template>
                    </div>
                    <div class="expand-to-full-container" v-if="getFilledSubcategories(category.subcategories).length > indexBreaker">
                        <div class="filter-expand-to-full"
                             v-show="getFilledSubcategories(category.subcategories).length > indexBreaker && !dynamicExpandRule[category.id]"
                             v-on:click="setExpandRule(category.id, true)">
                            Показать ещё
                            <SVGExpandBot class="arrow-to-full"/>
                        </div>
                        <div class="filter-expand-to-full"
                             v-show="productTypes.length > 2 && dynamicExpandRule[category.id]"
                             v-on:click="setExpandRule(category.id, false)">
                            Свернуть
                            <SVGCollapseTop class="arrow-to-full"/>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else-if="getFilledSubcategories(category.subcategories).length > 0" class="filter-frame">
                <div class="filter-label-grid">
                    <div class="filter-label">
                        {{category.name}}
                    </div>
                    <div class="filter-frame-collapse-arrow" v-on:click="collapseFilter(category.id)">
                        <SVGCollapseTop v-if="!collapseCategoryIds.includes(category.id)"/>
                        <SVGExpandBot v-else/>
                    </div>
                </div>
                <vue-custom-scrollbar class="filter-data-container" v-show="!collapseCategoryIds.includes(category.id)">
                    <div class="filter-data">
                        <div class="filter-data-colors-line">
                            <div v-for="subcategory in getFilledSubcategories(category.subcategories)" class="filter-color"
                                 :class="{'color-selected': checkColorActive(category.id + '/' + subcategory.id)}"
                                 :title="subcategory.name">
                                <img class="filter-color-icon"
                                     :src="urlForPhoto(subcategory.preview_src)"
                                     @click="selectColorCategory(category.id + '/' + subcategory.id)">
                                <!--                        Картинка!!! {{subcategory.preview_src}}-->
                                <!--                        <div class="filter-color-ral"></div>-->
                            </div>
                        </div>
                    </div>
                </vue-custom-scrollbar>
            </div>
        </template>
    </div>
</template>

<script>
    import SVGCollapseTop from "../../../../../SVGMP/SVGCollapseTop";
    import SVGExpandBot from "../../../../../SVGMP/SVGExpandBot";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import {bus} from "../../../../../../vue_bus";
    import SVGDropdownSwitchArrowDown from "../../../../../SVG/SVGDropdownSwitchArrowDown";
    import Vue from 'vue';

    export default {
        name: "SidebarLeftFilters",
        components: {
            SVGDropdownSwitchArrowDown,
            SVGExpandBot,
            SVGCollapseTop,
            vueCustomScrollbar
        },
        data() {
            return {
                displayType: true,
                productTypesExpanded: false,
                indexBreaker: 4,
                dynamicExpandRule: {},
                collapseCategoryIds: []
            }
        },
        computed: {
            facadeElementSelected() {
                return this.$store.state.route.params.facadeElement;
            },
            productTypes() {
                return this.$store.state.productsWithFilters.productTypesList;
            },
            categories() {
              const sortedCategories = [];
              for (let categoryID in this.$store.state.productsWithFilters.categoriesList) {
                if (this.$store.state.productsWithFilters.categoriesList[categoryID].name === "Цвет" )
                  sortedCategories.push(this.$store.state.productsWithFilters.categoriesList[categoryID]);
              }
              for (let categoryID in this.$store.state.productsWithFilters.categoriesList) {
                if (this.$store.state.productsWithFilters.categoriesList[categoryID].name !== "Цвет" )
                  sortedCategories.push(this.$store.state.productsWithFilters.categoriesList[categoryID]);
              }
                return sortedCategories;
            },
            productTypesSelected: {
                get() {
                    return this.$store.state.productsWithFilters.productTypesSelected;
                },
                set(value) {
                    this.$store.commit('productsWithFilters/PRODUCT_TYPES_SELECTED', value);
                    this.$store.commit('productsWithFilters/CLEAR_PRODUCTS');
                    this.$store.dispatch('productsWithFilters/GET_PRODUCTS');
                }
            },
            subcategorySelected: {
                get() {
                    return this.$store.state.productsWithFilters.subcategoriesSelected;
                },
                set(value) {
                    this.$store.commit('productsWithFilters/SUBCATEGORIES_SELECTED', value);
                    this.$store.commit('productsWithFilters/CLEAR_PRODUCTS');
                    this.$store.dispatch('productsWithFilters/GET_PRODUCTS');
                }
            }
        },
        methods: {
            getFilledSubcategories(list) {
                let newList = []
                let subCategoriesOfSelected = []
                for (let item in this.subcategorySelected) {
                    subCategoriesOfSelected.push(parseInt(this.subcategorySelected[item].split('/')[1]));
                }
                for (let subcategory in list) {
                    if (this.getNofProductsInSubcategory(list[subcategory]['id']) != 0 || subCategoriesOfSelected.includes(list[subcategory]['id'])) {
                        newList.push(list[subcategory])
                    }
                }
                return newList
            },
            urlForPhoto(url) {
                return this.$store.getters[`projectConfiguration/GENERATE_URL`](url);
            },
            checkColorActive(value) {
                return this.subcategorySelected.includes(value)
            },
            selectColorCategory(value) {
                let selectedValues = [...this.subcategorySelected];
                const indexColor = selectedValues.indexOf(value);
                if (indexColor === -1) {
                    selectedValues.push(value);
                } else {
                    selectedValues.splice(indexColor, 1);
                }
                this.subcategorySelected = selectedValues;
            },
            getNofProductsInSubcategory(subcategoryId) {
                const dataSubcategory = this.$store.state.productsWithFilters.nofProductsInSubcategory.find(elem => elem[0] == subcategoryId);
                if (dataSubcategory) {
                    return dataSubcategory[1];
                }
                return 0;
            },
            collapseFilter(categoryId) {
                const index = this.collapseCategoryIds.indexOf(categoryId);
                if (index > -1) {
                    this.collapseCategoryIds.splice(index, 1);
                } else {
                    this.collapseCategoryIds.push(categoryId);
                }
            },
            dynamicExpandRuleSetter() {
                for (let category in this.categories) {
                    Vue.set(this.dynamicExpandRule, category, false)
                }
            },
            setExpandRule(categoryID, value) {
                Vue.set(this.dynamicExpandRule, categoryID, value)
            }
        },
        watch: {
            facadeElementSelected(value) {
                this.$store.commit('productsWithFilters/PRODUCT_TYPES_SELECTED', []);
                this.$store.commit('productsWithFilters/SUBCATEGORIES_SELECTED', []);
                this.$store.commit('productsWithFilters/PRODUCT_TYPES_LIST', []);
                this.$store.commit('productsWithFilters/CATEGORIES_LIST', []);
                this.$store.commit('productsWithFilters/CLEAR_PRODUCTS');
                this.$store.dispatch('productsWithFilters/GET_FILTERS', this.facadeElementSelected);
                bus.$emit('scrollSideBarFiltersTop');
            },
            categories() {
                this.dynamicExpandRuleSetter()
            }
        },
        mounted() {
          console.log(this.categories);
            this.$store.dispatch('productsWithFilters/GET_FILTERS', this.facadeElementSelected);
        }
    }
</script>

<style scoped>

</style>
