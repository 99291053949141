<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Добавить продукт
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container pv-23">
            <div class="modal-form-grid" style="grid-template-columns: 170px auto">
                <div class="modal-form-grid-label">
                    Выберите тип продукта
                </div>
                <select v-model="productTypeId" v-on:change="selectProductType(productTypeId)"
                        class="modal-form-grid-input width100">
                    <option :value="null">- Не выбран -</option>
                    <option v-for="prodType in productTypes" :value="prodType.id">{{prodType.name}}</option>
                </select>
            </div>
        </div>
        <div v-show="productTypeId !== null">
            <div class="modal-data-container">
                <div class="mp-sidebar-borderline"></div>
            </div>
            <div class="modal-data-container pv-23">

                <div class="two-cols-modal-grid">

                    <!--                    <component v-bind:is="activeProductForm"/>-->
                    <!--                                        <ModalProductSoffit/>-->

                    <div>
                        <div class="modal-form-grid">
                            <div class="modal-form-grid-label" style="height: 36px">
                                Область применения
                            </div>
                            <div class="modal-form-checkbox-grid">
                                <template v-for="area in areas">
                                    <div>
                                        <input type="checkbox" :value="area.name" v-model="selectedAreas"
                                               class="form-input-checkbox">
                                    </div>
                                    <div class="form-input-checkbox-name">
                                        {{getRussianNameApplicationAreas(area.name)}}
                                    </div>
                                </template>
                            </div>
                            <div class="modal-form-grid-label">
                                Название
                            </div>
                            <input v-model="productName" class="modal-form-grid-input">
                            <div class="modal-form-grid-label">
                                Url
                            </div>
                            <input v-model="linkInOnlineStore" class="modal-form-grid-input">
                            <template v-for="category in productCategories">
                                <div class="modal-form-grid-label">
                                    {{category.name}}
                                </div>
                                <select :value="getSubcategoryParam(category.id)"
                                        v-on:input="setSubcategory(category.id, $event.target.value)"
                                        class="modal-form-grid-input">
                                    <option :value="null">-- Не выбран --</option>
                                    <option v-for="subcategory in category.subcategories" :value="subcategory.id">
                                        {{subcategory.name}}
                                    </option>
                                </select>
                            </template>
                        </div>
                    </div>

                    <div>
                        <div class="modal-form-grid">
                            <div class="modal-form-grid-label">
                                Парам. текстуры
                            </div>
                            <select v-model="selectedTextureParameters"
                                    class="modal-form-grid-input input-251">
                                <option :value="null">-- Не выбран --</option>
                                <option v-for="(value, key) in textureParameters" :value="key">
                                    {{value.name}}
                                </option>
                            </select>
                            <div class="modal-form-grid-label">
                                Текстура
                            </div>
                            <select v-model="selectedDefuseMapId"
                                    class="modal-form-grid-input input-251">
                                <option :value="null">-- Не выбран --</option>
                                <option v-for="defuseMap in defuseMaps" :value="defuseMap.id">
                                    {{defuseMap.name}}
                                </option>
                            </select>
                            <div class="modal-form-grid-label">
                                Карта нормали
                            </div>
                            <select v-model="selectedNormalMapId"
                                    class="modal-form-grid-input input-251">
                                <option :value="null">-- Не выбран --</option>
                                <option v-for="normalMap in normalMaps" :value="normalMap.id">
                                    {{normalMap.name}}
                                </option>
                            </select>
                            <div class="modal-form-grid-label">
                                Карта высот
                            </div>
                            <select v-model="selectedHeightMapId"
                                    class="modal-form-grid-input input-251">
                                <option :value="null">- Не выбран -</option>
                                <option v-for="heightMap in heightMaps" :value="heightMap.id">
                                    {{heightMap.name}}
                                </option>
                            </select>
                            <div class="modal-form-grid-label">
                                3D модель
                            </div>
                            <select v-model="selectedModel3dId"
                                    class="modal-form-grid-input input-251">
                                <option :value="null">-- Не выбран --</option>
                                <option v-for="model in model3ds" :value="model.id">
                                    {{model.name}}
                                </option>
                            </select>
                        </div>
<!--                        <div class="modal-form-images-container">-->
                            <!--                        <div class="modal-tabs-container">-->
                            <!--                            <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'previewSrc'}"-->
                            <!--                                 v-on:click="switchAdminTab('previewSrc')">-->
                            <!--                                <div class="admin-tab-text">Изображение</div>-->
                            <!--                            </div>-->
                            <!--                            <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'textureSrc'}"-->
                            <!--                                 v-on:click="switchAdminTab('textureSrc')">-->
                            <!--                                <div class="admin-tab-text">Текстура</div>-->
                            <!--                            </div>-->
                            <!--                        </div>-->
                            <div class="modal-form-img-container">
                                <img class="modal-form-img" :src="getPhoto()"/>
                                <div class="blue-button modal-form-img-button" @click="$refs.previewFile.click()">
                                    Изменить
                                </div>
                                <input type="file" ref="previewFile"
                                       v-on:change="handleFileUpload($event)"
                                       accept=".jpg,.png" hidden>
                            </div>
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-lower-buttons-grid">
                <div class="blue-button modal-accept-button"
                     :class="{'disabled-button': productTypeId === null}"
                     v-on:click="save">Сохранить
                </div>
                <div class="blue-outline-button modal-accept-button" v-on:click="$emit('close')">Отмена</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import {urlForStaticVue} from "../../common";
    import {http_request, uploadFiles} from "../../../httpConfig";
    import {config} from "../../../config";

    export default {
        name: "ModalProduct",
        mixins: [urlForStaticVue],
        components: {
            SVGClose,
        },
        props: {
            product: {
                type: Object
            },
            callback: {
                type: Function
            }
        },
        data() {
            return {
                selectedAreas: [],
                areas: [],
                image: null,
                productTypes: [],
                productCategories: [],
                selectedSubcategoriesByCategoryId: {},
                productTypeId: null,
                previewSrc: null,
                productName: null,
                selectedDefuseMapId: null,
                selectedNormalMapId: null,
                selectedHeightMapId: null,
                selectedModel3dId: null,
                defuseMaps: [],
                normalMaps: [],
                heightMaps: [],
                model3ds: [],
                linkInOnlineStore: "",
                selectedTextureParameters: null,
                textureParameters: config.textureParameters

                // activeProductType: null,
                // activeAdminTab: 'previewSrc',
            }
        },
        methods: {
            getPhoto(namePhotoVariable) {
                if (this.previewSrc) {
                    return this.urlForPhoto(this.previewSrc);
                } else {
                    return this.urlForStatic('/img/no-img-placeholder.png');
                }
            },
            urlForPhoto(url) {
                return this.$store.getters[`projectConfiguration/GENERATE_URL`](url);
            },
            getRussianNameApplicationAreas(nameOnServer) {
                //TODO
                const area = config.applicationAreas.find(area => area.nameAreaOnServer === nameOnServer);
                if (area)
                    return area.nameInRussian;
            },
            uploadFile(fileInput, callback) {
                for (let file in fileInput.target.files) {
                    if (fileInput.target.files[file].size / 1048576 > 15) {
                        // fileTooLarge = true
                        return
                    }
                }
                // fileTooLarge = false
                console.log(fileInput.target.files);
                uploadFiles(fileInput.target.files)
                    .then((request) => {
                        console.log(request.data);
                        callback(request.data.fileList);
                    })
            },
            handleFileUpload(fileInput) {
                const savePhotos = (photosSRC) => {
                    for (let srcPhoto of photosSRC) {
                        this.previewSrc = srcPhoto;
                    }
                };
                this.uploadFile(fileInput, savePhotos);
            },
            getSubcategoryParam(categoryId) {
                return this.selectedSubcategoriesByCategoryId[categoryId];
            },
            setSubcategory(categoryId, subcategoriesId) {
                if(!subcategoriesId) {
                    delete this.selectedSubcategoriesByCategoryId[categoryId];
                    return;
                }
                this.selectedSubcategoriesByCategoryId[categoryId] = subcategoriesId
            },
            findCategoryAndSetSubcategory(subcategories) {
                for (let category of this.productCategories) {
                    for (let subcategory of category.subcategories) {
                        if (subcategories.find(subcat => subcat.id == subcategory.id)) {
                            this.selectedSubcategoriesByCategoryId[category.id] = subcategory.id;
                        }
                    }
                }
            },
            // switchAdminTab(tabName) {
            //     this.activeAdminTab = tabName
            // },
            selectProductType(productTypeId,) {
                this.productTypeId = productTypeId;
                http_request.post('/get_info_by_product_type', {product_type_id: productTypeId})
                    .then((request) => {
                        console.log(request.data);
                        this.areas.length = 0;
                        this.areas.push(...request.data.application_areas)
                        this.productCategories.length = 0;
                        this.productCategories.push(...request.data.categories);
                        if (this.product) {
                            this.findCategoryAndSetSubcategory(this.product.subcategories);
                        }
                        console.log(this.productCategories);
                    })
            },
            save() {
                let subcategories = Object.values(this.selectedSubcategoriesByCategoryId);

                const dataProduct = {
                    application_areas: this.selectedAreas,
                    name: this.productName,
                    product_type_id: this.productTypeId,
                    subcategories: subcategories,
                    preview: this.previewSrc,
                    defuse_map_id: this.selectedDefuseMapId,
                    normal_map_id: this.selectedNormalMapId,
                    height_map_id: this.selectedHeightMapId,
                    model3d_id: this.selectedModel3dId,
                    data_json: {
                        linkInOnlineStore: this.linkInOnlineStore,
                        textureParameters: this.selectedTextureParameters
                    }
                };

                if (this.product) {
                    dataProduct['uuid'] = this.product.uuid;
                }

                console.log(dataProduct);

                http_request.post('/save_product', dataProduct)
                    .then((request) => {
                        console.log(request.data);
                        if (this.callback) {
                            this.callback(request.data.product);
                        }
                        this.$emit('close');
                    })
                .catch(() => {
                    alert('Ошибка при сохранении!');
                })
            }
        },
        mounted() {
            http_request.post('/get_product_types', {})
                .then((request) => {
                    console.log(request.data);
                    this.productTypes.push(...request.data.product_types);
                    if (this.product) {
                        this.selectedAreas = this.product.application_areas.map(appArea => appArea.name);
                        this.productName = this.product.name;
                        this.previewSrc = this.product.preview;
                        this.selectedDefuseMapId = this.product.defuse_map_id;
                        this.selectedNormalMapId = this.product.normal_map_id;
                        this.selectedHeightMapId = this.product.height_map_id;
                        this.selectedModel3dId = this.product.model3d_id;
                        this.selectProductType(this.product.product_type_id);
                        if (this.product.data_json) {
                            this.linkInOnlineStore = this.product.data_json.linkInOnlineStore;
                            this.selectedTextureParameters = this.product.data_json.textureParameters;
                        }
                    }
                    console.log(this.productTypes);
                })
            http_request.post('/get_defuse_maps', {})
                .then((request) => {
                    console.log(request.data);
                    this.defuseMaps.push(...request.data.defuse_maps);
                })
            http_request.post('/get_normal_maps', {})
                .then((request) => {
                    console.log(request.data);
                    this.normalMaps.push(...request.data.normal_maps);
                })
            http_request.post('/get_height_maps', {})
                .then((request) => {
                    console.log(request.data);
                    this.heightMaps.push(...request.data.height_maps);
                })
            http_request.post('/get_model3ds', {})
                .then((request) => {
                    console.log(request.data);
                    this.model3ds.push(...request.data.model3ds);
                })
        }
    }
</script>

<style scoped>

</style>
