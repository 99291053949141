import {planePointer} from "../primitives/planePointer";
import store from "../../store";
import {planeLine} from "../primitives/planeLine";
import draw from "tui-image-editor/src/js/ui/template/submenu/draw";
import {planePolygon} from "../primitives/planePolygon";
import {bus} from "../../vue_bus";
import {sortObjectsByZindex} from "../libs/sortObjectsByZindex";
import {nearPoint} from "../libs/nearPoint";
import {markerPointer} from "../primitives/markerPointer";
import {getFermatPolygon} from "../../math/getAreaCorrected";
import {getEdgesFiltered} from "../../math/fermatCollection";
import {describeEdges, getIncludedPlanes, getNeighbours, getPlaneSide} from "../../math/2dPlanReconstruction";

export const planeDrawingTool = function (fabricJS) {
    this.fabricJS = fabricJS;
    this.pointZoneSize = 10;
    this.movingID = null;
    this.movingPosition = null;
    this.oldPointID = null;
    this.activeLine = null;
    this.activeShape = null;
    this.polygon = null;
    this.pointArray = [];
    this.lineDict = {};
    this.selectedPointID = null;
    this.nearPointFinder = new nearPoint(this.fabricJS, this.pointZoneSize);
    this.middleLinePointer = markerPointer();
    this.isComplete = false;
    this.unsaveChanges = false;

    this.pointArrayToDict = (pointArray) => {
        let pointDict = {};
        for (let pointID in pointArray) {
            pointDict[parseInt(pointID)+1] = {
                x:pointArray[pointID].position.x,
                y:pointArray[pointID].position.y,
                link: pointArray[pointID].link+1,
            }
        }
        return pointDict;
    };

    this.pointDictToArray = (pointDict) => {
        let pointArray = [];
        for (let pointID in pointDict) {
            pointArray.push({
                x:pointDict[pointID].x,
                y:pointDict[pointID].y,
                link: pointDict[pointID].link-1,
            })
        }
        return pointArray
    };


    this.complete = (pointID) => {
        // const pointList = store.getters["active/POINTS"]

        this.pointArray[pointID].link = this.pointArray.length - 1;
        this.pointArray[0].setColor('default');
        this.activeLine = null;
        let points = this.activeShape.get("points");
        this.updatePolygon(points.slice(0, -1));
        this.polygon = this.activeShape;
        this.polygon.set({zIndex: 3});
        this.activeShape = null;
        this.fabricJS.off('mouse:down');
        this.pointArray.forEach((point) => {
            point.dragging(true);
        })
        sortObjectsByZindex(this.fabricJS);
        this.addMovingEvent();
        this.nearPointFinder.setVisible(false);
        // this.nearPointFinder.enable = false;
        this.fabricJS.on('mouse:over',this.lineOverEvent);
        this.fabricJS.on('mouse:out',this.lineOutEvent);
        this.fabricJS.on('mouse:down',this.lineClickEvent);
        this.unsaveChanges = true;
    }

    this.updatePolygon = (points) => {
        let polygon = new planePolygon(points);
        this.fabricJS.remove(this.activeShape);
        this.fabricJS.add(polygon);
        this.activeShape = polygon;
        this.fabricJS.renderAll();
    }

    this.updateActiveShape = (point) => {
        let points;
        if (this.activeShape) {
            points = this.activeShape.get("points");
            points[points.length-1].x = point.x;
            points[points.length-1].y = point.y;
            points.push({
                x: point.x,
                y: point.y
            });
        } else {
            points = [{
                x: point.x,
                y: point.y
            }]
        }
        this.updatePolygon(points);
    }

    this.addPoint = () => {
        return (opt) => {
            let relativePoint = this.fabricJS.getPointer(opt.e);
            let correctedPoint = {};
            correctedPoint.x = relativePoint.x;
            correctedPoint.y = relativePoint.y;
            let nearPoint = this.nearPointFinder.findNearPoint(relativePoint);
            if (nearPoint) {
                correctedPoint.x = nearPoint.x;
                correctedPoint.y = nearPoint.y;
            }
            for (let pointID in this.pointArray) {
                if (Math.sqrt(Math.pow(this.pointArray[pointID].position.x - correctedPoint.x, 2) +
                                 Math.pow(this.pointArray[pointID].position.y - correctedPoint.y, 2))
                    < this.pointZoneSize) {
                    if (this.pointArray[pointID].link === undefined && this.pointArray.length >= 3) {
                        this.activeLine.set({
                            x2: correctedPoint.x,
                            y2: correctedPoint.y
                        });
                        // this.updateActiveShape(correctedPoint);
                        this.complete(pointID);
                    }
                    return
                }
            }
            let pointID = this.pointArray.length;
            let pointer = new planePointer(correctedPoint, pointID)
            pointer.toFabric().set({zIndex: 7});
            if (pointID > 0) {
                pointer.link = this.oldPointID;
            } else {
                pointer.setColor('green');
            }
            this.pointArray.push(pointer);
            this.fabricJS.add(pointer.toFabric());
            if (this.activeLine)
                this.activeLine.set({
                    x2: correctedPoint.x,
                    y2: correctedPoint.y
                })
            this.activeLine = new planeLine(correctedPoint, correctedPoint)
            this.activeLine.set({
                zIndex: 5,
                isLine: true,
                linkPoint: pointID,
            });
            this.fabricJS.add(this.activeLine);
            this.lineDict[pointID] = this.activeLine;
            this.updateActiveShape(correctedPoint);
            this.oldPointID = pointID;

        }
    }

    this.lineOverEvent = (event) => {
        if (!event.target)
            return;

        if (!event.target.isLine)
            return

        this.middleLinePointer.set({
            left: (event.target.x1 + event.target.x2)/2,
            top: (event.target.y1 + event.target.y2)/2,
        })

        this.fabricJS.add(this.middleLinePointer);
    }

    this.lineOutEvent = (event) => {
        if (!event.target)
            return;

        if (!event.target.isLine)
            return;

        this.fabricJS.remove(this.middleLinePointer);
    }

    this.removeAll = () => {
        this.pointArray.forEach((point)=>{
            this.fabricJS.remove(point.toFabric());
        })
        for (let lineID in this.lineDict) {
            this.fabricJS.remove(this.lineDict[lineID]);
        }
        this.fabricJS.remove(this.polygon);
        // this.pointArray.push(newPoint);

        this.pointArray = [];
    }

    this.lineClickEvent = (event) => {
        if (!event.target)
            return;

        if (!event.target.isLine)
            return;

        let newPoint = {
            x: this.middleLinePointer.left,
            y: this.middleLinePointer.top,
            link: event.target.linkPoint,
        }

        let points = [];
        let linkShift = 0;
        for (let pointID in this.pointArray) {
            if (event.target.linkPoint == (pointID - 1)) {
                points.push(newPoint);
                linkShift = 1;
            }
            points.push(this.pointArray[pointID].position)
            points[points.length-1].link = linkShift + ((pointID-1)>=0?pointID-1:Object.keys(this.pointArray).length);
        }
        if (event.target.linkPoint == Object.keys(this.pointArray).length - 1) {
            points.push(newPoint);
        }
        this.removeAll();
        this.fabricJS.remove(this.middleLinePointer);
        this.drawAll(points);
        this.unsaveChanges = true;
    }

    this.drawAll = (pointListIn) => {
        let pointList
        if (pointListIn)
            pointList = pointListIn;
        else
            pointList = this.pointDictToArray(store.getters["active/POINTS"]);

        let points = []
        for (let pointID in pointList) {
            let pointer = new planePointer(pointList[pointID], pointID);
            pointer.toFabric().set({zIndex: 7});
            pointer.link = parseInt(pointList[pointID].link);
            pointer.dragging(true);
            this.pointArray.push(pointer);
            points.push(pointer.position);
            this.fabricJS.add(pointer.toFabric());
        }
        this.polygon = new planePolygon(points);
        this.polygon.set({
            zIndex: 3,
            evented: false
        });
        this.fabricJS.add(this.polygon);
        for (let pointID in this.pointArray) {
            let line = new planeLine(this.pointArray[this.pointArray[pointID].link].position, this.pointArray[pointID].position);
            line.set({
                zIndex: 5,
                isLine: true,
                linkPoint: this.pointArray[pointID].link,
            });
            this.fabricJS.add(line);
            this.lineDict[this.pointArray[pointID].link] = line;
        }
        sortObjectsByZindex(this.fabricJS);

    }

    this.addMovingEvent = () => {
        this.fabricJS.on('object:moving', (event) => {
            let nearPoint = this.nearPointFinder.findNearPoint({x: event.target.left, y: event.target.top});
            if (nearPoint) {
                event.target.set({
                    left: nearPoint.x,
                    top: nearPoint.y
                })
            }
            this.movingID = event.target.pointID;
            this.movingPosition = {
                x: event.target.left,
                y: event.target.top
            }
            let points = this.polygon.get("points");
            points[this.movingID] = {
                x: event.target.left,
                y: event.target.top
            }
            this.polygon.set({
                points: points
            });
            this.lineDict[this.movingID].set({
                x1: event.target.left,
                y1: event.target.top
            })
            this.lineDict[this.pointArray[this.movingID].link].set({
                x2: event.target.left,
                y2: event.target.top
            })

            this.fabricJS.renderAll();
            this.unsaveChanges = true;
        });
    }

    this.deletePoint = () => {
        if (this.selectedPointID === null)
            return

        let points = [];
        let index = 0;
        for (let pointID in this.pointArray) {
            if (pointID == this.selectedPointID) {
                continue;
            }
            points.push(this.pointArray[pointID].position)
            if (index !== 0) {
                // points.push(this.pointArray[pointID].position)
                points[points.length - 1].link = index - 1;
            }  else {
                points[points.length - 1].link = this.pointArray.length - 2;
            }
            index+=1;
        }
        this.removeAll();
        this.drawAll(points);
        this.unsaveChanges = true;
    }

    this.fabricJS.on('mouse:move', (options) => {
        // if (!this.isComplete)
        this.nearPointFinder.setEnable(!options.e.shiftKey);

        let pointer = this.fabricJS.getPointer(options.e);
        if (this.activeLine) {
            this.activeLine.set({
                x2: pointer.x,
                y2: pointer.y
            })
        }
        if (this.activeShape) {
            let points = this.activeShape.get("points");
            points[this.pointArray.length] = {
                x: pointer.x,
                y: pointer.y
            }
            this.activeShape.set({
                points: points
            });
        }
        this.nearPointFinder.setPoint(pointer);
        this.fabricJS.renderAll();
    });

    this.fabricJS.on('selection:created', (event) => {
        this.selectedPointID = event.target.pointID
        bus.$emit("showDeleteButton", true);
    });

    this.fabricJS.on('selection:updated', (event) => {
        this.selectedPointID = event.target.pointID
    });

    this.fabricJS.on('selection:cleared', () => {
        this.selectedPointID = null;
        bus.$emit("showDeleteButton", false);
    });

    this.destroy = () => {
        bus.$off("AcceptPlaneDrawing");
        bus.$off("deletePoint", this.deletePoint);
        this.fabricJS.off('mouse:over',this.lineOverEvent);
        this.fabricJS.off('mouse:out',this.lineOutEvent);
        this.fabricJS.off('mouse:down',this.lineClickEvent);
    }

    bus.$on("deletePoint", this.deletePoint);

    bus.$on("AcceptPlaneDrawing", async (callback) => {
        const projectUUID = store.state.route.params.projectUUID;
        const photoID = store.state.route.params.photoID;
        const planeId = parseInt(store.state.route.params.planeID);
        this.unsaveChanges = false;

        if (await store.dispatch("active/IS_COMPLETE")) {
            await store.dispatch('active/UPDATE_PLANE_POINTS', this.pointArrayToDict(this.pointArray));
        } else {
            this.pointArray.forEach((point) => {
                store.dispatch(`active/CREATE_POINT`, Object.assign({link: point.link + 1}, point.position));
                // point.dragging(true);
            })
        }

        if (this.pointArray.length > 2) {
            await store.dispatch("active/IS_COMPLETE", true);
            const planes = store.getters["active/ALL_PLANES_FROM_PHOTO"];
            if (store.getters["active/PLANE_BY_ID"](planeId).type === "wall") {
                console.log(planes);
                let fermat = getFermatPolygon(planes[planeId].points);
                let filteredEdges = getEdgesFiltered(fermat);
                store.commit(`projectList/${projectUUID}/photoList/${photoID}/planeList/${planeId}/VERTICAL_LINES`, filteredEdges.verticals);
                store.commit(`projectList/${projectUUID}/photoList/${photoID}/planeList/${planeId}/HORIZONTAL_LINES`, filteredEdges.horizontals);
                let photoData = await store.dispatch("active/GET_ACTIVE_IMG_SIZE");
                let side = getPlaneSide(filteredEdges.horizontals, photoData.width);
                console.log("side", side);
                store.commit(`projectList/${projectUUID}/photoList/${photoID}/planeList/${planeId}/SIDE`, side);
                let describedFermat = describeEdges(fermat);
                let neighbours = getNeighbours(describedFermat, planeId, planes);
                store.commit(`projectList/${projectUUID}/photoList/${photoID}/planeList/${planeId}/NEIGHBOURS`, neighbours);
                console.log("NEIGHBOURS", neighbours);
            }
        }
        callback()
    })

    this.onUpdateScale = (scale)=>{
        this.pointArray.forEach((pointer)=>{
            pointer.toFabric().set({
                scaleX: 2/scale,
                scaleY: 2/scale,
            })
        })
        for (let lineID in this.lineDict) {
            this.lineDict[lineID].set({
                strokeWidth: 2 / scale,
            })
        }
        this.nearPointFinder.setScale(scale);
        this.pointZoneSize = 18 / scale;
        // console.log(this.pointZoneSize);
        // console.log(scale);
    }

    store.dispatch('active/IS_COMPLETE').then((isComplete) => {
            if (!isComplete) {
                this.fabricJS.on('mouse:down', this.addPoint());
            } else {
                this.isComplete = true;
                this.fabricJS.on('mouse:over',this.lineOverEvent);
                this.fabricJS.on('mouse:out',this.lineOutEvent);
                this.fabricJS.on('mouse:down',this.lineClickEvent);
                this.nearPointFinder.setVisible(false);
                // this.nearPointFinder.enable = false;
                this.drawAll();
                this.addMovingEvent();
            }

        }
    )
}
