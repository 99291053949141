var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "33",
        height: "29",
        viewBox: "0 0 33 29",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M6.248 3.02095C6.14632 2.56342 5.74051 2.23788 5.27181 2.23788H1.61894C1.27711 2.23788 1 1.96077 1 1.61894C1 1.27711 1.27711 1 1.61894 1H6.26481C6.73351 1 7.13932 1.32553 7.24099 1.78307L10.6066 16.9284C10.7083 17.3859 11.1141 17.7114 11.5828 17.7114H25.8667C26.2857 17.7114 26.6602 17.4503 26.8051 17.0571L29.9447 8.53513C30.1853 7.88227 29.7022 7.18942 29.0064 7.18942H15.2357C14.8938 7.18942 14.6167 6.91231 14.6167 6.57048C14.6167 6.22865 14.8938 5.95154 15.2357 5.95154H30.7817C31.4774 5.95154 31.9606 6.64439 31.72 7.29724L27.6682 18.295C27.5234 18.6882 27.1488 18.9493 26.7299 18.9493H10.5898C10.1211 18.9493 9.7153 18.6238 9.61362 18.1663L6.248 3.02095Z",
          fill: "currentColor",
          stroke: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          r: "2.47577",
          transform: "matrix(-1 0 0 1 12.7599 26.3762)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          r: "2.47577",
          transform: "matrix(-1 0 0 1 23.9008 26.3762)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }