import {http_request} from "../../httpConfig";

export const products = {
    namespaced: true,
    state: {
        dataLoadFlag: false,
        productsUsedInProject: {},
        productsExpectedFromServer: {}
    },
    mutations: {
        DATA_LOAD_FLAG: function (state, value){
          state.dataLoadFlag = value;
        },
        ARRAY_PRODUCTS_USED_IN_PROJECT: function (state, data) {
            const objProducts = {};
            for (let product of data) {
                state.productsUsedInProject[product.uuid] = product;
            }
        },
        PRODUCT_USED_IN_PROJECT: function (state, product) {
            state.productsUsedInProject[product.uuid] = product;
        },
        FILL_PRODUCT_USED_IN_PROJECT: function (state, arrUUID) {
            for (let productUUID of arrUUID) {
                if (!(productUUID in state.productsUsedInProject)) {
                    state.productsUsedInProject[productUUID] = null;
                }
            }
        }
    },
    getters: {
        CHECK_PRODUCTS: state => productsUUID => {
            for (let uuid of productsUUID) {
                if (!(uuid in state.productsUsedInProject)) {
                    return false;
                }
            }
            return true;
        },
        PRODUCT_BY_UUID_ONLY_STORE: state => uuid => {
            if (uuid in state.productsUsedInProject) {
                return state.productsUsedInProject[uuid];
            }

            return null;
        }
    },
    actions: {
        GET_PRODUCT_BY_UUID_FROM_SERVER: async function (context, uuid) {
            if (!uuid)
                return;

            let arrUUID;
            if (typeof uuid === "string") {
                if(Object.keys(context.state.productsExpectedFromServer).find(productUuid => productUuid === uuid)){
                    return context.state.productsExpectedFromServer[uuid];
                }
                arrUUID = [uuid];
            } else {
                arrUUID = uuid;
            }

            const productsExpectedPromise = http_request.post('/get_products', {
                uuid: arrUUID
            })
                .then((request) => {
                    console.log(request.data);
                    context.commit('ARRAY_PRODUCTS_USED_IN_PROJECT', request.data.products);
                    context.commit('FILL_PRODUCT_USED_IN_PROJECT', arrUUID);
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });

            for(let productUuid of arrUUID) {
                context.state.productsExpectedFromServer[productUuid] = productsExpectedPromise;
            }

            return productsExpectedPromise;
        },
        PRODUCT_BY_UUID: async function (context, uuid) {
            if (uuid in context.state.productsUsedInProject)
                return context.state.productsUsedInProject[uuid];

            await context.dispatch('GET_PRODUCT_BY_UUID_FROM_SERVER', uuid);

            if (uuid in context.state.productsUsedInProject) {
                return context.state.productsUsedInProject[uuid];
            } else {
                return null;
            }
        }
    }
};
