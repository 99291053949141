<template>
    <div class="page-container">
        <TheModalLite/>
        <TheLiteNavbar/>
        <FullBodyLoader :loadingProgress="progressPercent"/>
    </div>
</template>

<script>
    import TheLiteNavbar from "./Components/TheLiteNavbar";
    import TheModalLite from "../ModalMP/ModalLite/TheModalLite";
    import FullBodyLoader from "../ComponentsMP/Loaders/FullBodyLoader";

    export default {
        name: "TheLoadingPage",
        components: {
            FullBodyLoader,
            TheModalLite,
            TheLiteNavbar,
        },
        data() {
            return {
                progressPercent: 5
            }
        },
        async mounted() {
            if (this.$store.state.applicationVersion === 'pro' && sessionStorage.initialStateFromLocalStorage) {
                const initialState = JSON.parse(sessionStorage.initialStateFromLocalStorage);
                const projectList = initialState.projectList;
                if (projectList) {
                    await this.$store.dispatch('projectList/createDynamicModules', projectList);
                }
            }
            const projectUUID = this.$route.query.projectUUID;
            const redirectPath = this.$route.query.redirect;
            if (projectUUID && redirectPath) {
                let timerId = setInterval(() => {
                    this.progressPercent++;
                    console.log(this.progressPercent);
                }, 1000)
                let project = await this.$store.dispatch('projectList/LOAD_PROJECT_BY_UUID', projectUUID)
                this.progressPercent = 99;
                clearInterval(timerId);
                if (!project) {
                    alert('Данный проект не найдет!');
                    this.redirectProjectsPage();
                    return;
                }
                const productsUsed = this.$store.getters[`projectList/${projectUUID}/designList/PRODUCTS_USED`];
                await this.$store.dispatch('UI/products/GET_PRODUCT_BY_UUID_FROM_SERVER', productsUsed);
                this.$router.replace(redirectPath).catch(() => {
                });
            } else {
                this.$router.replace(redirectPath).catch(() => {
                });
            }
        },
        methods: {
            redirectProjectsPage: function () {
                if (this.$store.state.applicationVersion === 'pro') {
                    this.$router.replace({name: "projects"}).catch(() => {
                    });
                } else {
                    this.$router.replace({name: "liteProjects"}).catch(() => {
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
