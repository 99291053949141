var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "32",
        viewBox: "0 0 20 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M0.8 23.5041C0.8 21.5858 2.3551 20.0307 4.27342 20.0307C4.67492 20.0307 5.07415 20.091 5.45772 20.2097L6.40468 20.5026C8.74681 21.2271 11.2532 21.2271 13.5953 20.5026L14.5423 20.2097C14.9259 20.091 15.3251 20.0307 15.7266 20.0307C17.6449 20.0307 19.2 21.5858 19.2 23.5041V30.8582H0.8V23.5041Z",
          stroke: "currentColor",
          "stroke-width": "1.6"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M20 11.7729V7.11408C20 6.37075 19.5306 5.76817 18.9516 5.76817L18.6224 5.76817C18.6224 1.7971 14.0649 0 9.97776 0C5.8906 0 1.54964 1.83295 1.54964 5.77504L1.04845 5.76817C0.469405 5.76817 0 6.37075 0 7.11408V11.7729C0 12.5163 0.469405 13.1188 1.04845 13.1188H3.12501C2.5 11.599 2.78562 7.24187 3.33333 5.7995L2.56841 5.78901C2.56841 2.66741 5.8906 1.04347 9.97776 1.04347C14.0649 1.04347 17.373 2.66822 17.373 5.78982L16.6667 5.7995C17.0833 7.04225 17.5 10.7507 16.875 13.1188H17.1202V14.8812C17.1202 14.9873 17.0531 15.0734 16.9704 15.0734H11.4331V16.0868H17.7667C17.877 16.0868 18.0233 16.004 18.0233 15.8625V13.1188H18.9516C19.5306 13.1188 20 12.5163 20 11.7729Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M10.0015 15.0351C10.0015 14.8142 10.1806 14.6351 10.4015 14.6351H11.7911C12.012 14.6351 12.1911 14.8142 12.1911 15.0351V16.1698C12.1911 16.3907 12.012 16.5698 11.7911 16.5698H10.4015C10.1806 16.5698 10.0015 16.3907 10.0015 16.1698V15.0351Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M9.81018 17.7296C12.6295 17.7296 15.334 14.8672 15.334 10.7702C15.334 7.46043 14.892 5.84368 14.1705 5.00209C13.5144 4.23683 12.3465 3.81077 9.81018 3.81077C7.4898 3.81077 6.41874 4.2267 5.80242 4.97919C5.10679 5.82852 4.66732 7.46524 4.66732 10.7702C4.66732 14.9749 7.07651 17.7296 9.81018 17.7296ZM9.81018 19.0552C13.5973 19.0552 16.6673 15.3459 16.6673 10.7702C16.6673 4.14217 15.0007 2.48517 9.81018 2.48517C5.00065 2.48517 3.33398 4.14217 3.33398 10.7702C3.33398 15.3459 6.02308 19.0552 9.81018 19.0552Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M8.33398 5.7992C7.33398 5.7992 5.41732 7.18004 4.58398 7.87046V5.38495L7.08398 3.31367H12.5007L15.4173 5.38495V8.28467C14.584 6.21342 13.29 5.19971 12.9173 5.38495C12.084 5.79917 11.5284 5.7992 10.834 5.7992H8.33398Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }