var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "203",
        height: "103",
        viewBox: "0 0 203 103",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("mask", { attrs: { id: "path-1-inside-1", fill: "white" } }, [
        _c("path", {
          attrs: {
            d:
              "M0 101.5C2.35338e-06 74.5805 10.6937 48.7636 29.7287 29.7287C48.7636 10.6937 74.5805 2.88804e-06 101.5 0C128.419 -2.88804e-06 154.236 10.6937 173.271 29.7286C192.306 48.7636 203 74.5805 203 101.5L176.547 101.5C176.547 81.5962 168.641 62.5076 154.566 48.4335C140.492 34.3594 121.404 26.4526 101.5 26.4526C81.5962 26.4526 62.5076 34.3594 48.4335 48.4335C34.3594 62.5076 26.4526 81.5962 26.4526 101.5L0 101.5Z"
          }
        })
      ]),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M0 101.5C2.35338e-06 74.5805 10.6937 48.7636 29.7287 29.7287C48.7636 10.6937 74.5805 2.88804e-06 101.5 0C128.419 -2.88804e-06 154.236 10.6937 173.271 29.7286C192.306 48.7636 203 74.5805 203 101.5L176.547 101.5C176.547 81.5962 168.641 62.5076 154.566 48.4335C140.492 34.3594 121.404 26.4526 101.5 26.4526C81.5962 26.4526 62.5076 34.3594 48.4335 48.4335C34.3594 62.5076 26.4526 81.5962 26.4526 101.5L0 101.5Z",
          fill: "#106FDE",
          stroke: "#4C5459",
          "stroke-width": "2",
          mask: "url(#path-1-inside-1)"
        }
      }),
      _vm._v(" "),
      _c("mask", { attrs: { id: "path-2-inside-2", fill: "white" } }, [
        _c("path", {
          attrs: {
            d:
              "M0 101.5C1.36107e-06 85.9311 3.58149 70.571 10.4674 56.6077C17.3533 42.6444 27.3591 30.4523 39.7107 20.9746C52.0623 11.4969 66.4286 4.98763 81.6983 1.95029C96.968 -1.08704 112.732 -0.570985 127.77 3.45853L120.924 29.0098C109.805 26.0305 98.1491 25.6489 86.859 27.8947C75.5688 30.1404 64.9466 34.9533 55.8141 41.9609C46.6815 48.9686 39.2834 57.9832 34.1921 68.3074C29.1007 78.6316 26.4526 89.9887 26.4526 101.5H0Z"
          }
        })
      ]),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M0 101.5C1.36107e-06 85.9311 3.58149 70.571 10.4674 56.6077C17.3533 42.6444 27.3591 30.4523 39.7107 20.9746C52.0623 11.4969 66.4286 4.98763 81.6983 1.95029C96.968 -1.08704 112.732 -0.570985 127.77 3.45853L120.924 29.0098C109.805 26.0305 98.1491 25.6489 86.859 27.8947C75.5688 30.1404 64.9466 34.9533 55.8141 41.9609C46.6815 48.9686 39.2834 57.9832 34.1921 68.3074C29.1007 78.6316 26.4526 89.9887 26.4526 101.5H0Z",
          fill: "#B4FF00",
          stroke: "#4C5459",
          "stroke-width": "2",
          mask: "url(#path-2-inside-2)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }