var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-workspace-container" }, [
    _c(
      "div",
      [
        _c("div", { staticClass: "admin-search-new-container" }, [
          _c(
            "div",
            {
              staticClass: "blue-button admin-new-button",
              on: { click: _vm.addNewSubcategory }
            },
            [
              _vm._v(
                "\n                    Добавить подкатегорию\n                "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "admin-tabs-container" },
          [
            _vm._l(_vm.categories, function(category, index) {
              return _c(
                "div",
                {
                  staticClass: "admin-tab",
                  class: {
                    "active-admin-tab": _vm.idSelectedCategory == category.id
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectCategory(category.id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "admin-tab-text" }, [
                    _vm._v(_vm._s(category.name))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "admin-tab-controls" },
                    [
                      [
                        category.active
                          ? _c("SVGEye", {
                              staticClass: "admin-tab-icon",
                              attrs: { "tooltip-content": "показать/скрыть" }
                            })
                          : _c("SVGEyeSlash", {
                              staticClass: "admin-tab-icon",
                              attrs: { "tooltip-content": "показать/скрыть" }
                            })
                      ],
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.editCategory(category)
                            }
                          }
                        },
                        [
                          _c("SVGEdit", {
                            staticClass: "admin-tab-icon",
                            attrs: { "tooltip-content": "редактировать" }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "admin-tab-blue",
                on: { click: _vm.addNewCategory }
              },
              [_vm._v("\n                    +\n                ")]
            )
          ],
          2
        ),
        _vm._v(" "),
        _c("vue-custom-scrollbar", { staticClass: "admin-table-container" }, [
          _c("table", { staticClass: "admin-table" }, [
            _c("thead", [
              _c("tr", { staticClass: "admin-table-head-tr" }, [
                _c("th", [_vm._v("№")]),
                _vm._v(" "),
                _vm.selectedCategoryIsColor
                  ? _c("th", [_vm._v("Изображение")])
                  : _vm._e(),
                _vm._v(" "),
                _c("th", [_vm._v("Название")]),
                _vm._v(" "),
                _c("th", { staticClass: "admin-table-options-head" }, [
                  _vm._v("Опции")
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.subcategories, function(value, index) {
                return _c("tr", { staticClass: "admin-table-line" }, [
                  _c("td", [_c("span", {}, [_vm._v(_vm._s(index + 1))])]),
                  _vm._v(" "),
                  _vm.selectedCategoryIsColor
                    ? _c("td", [
                        _c("img", {
                          staticClass: "admin-table-image",
                          attrs: { src: _vm.urlForPhoto(value.preview_src) }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(value.name))])]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "admin-table-options" }, [
                      _c(
                        "div",
                        {
                          staticClass: "admin-table-option",
                          on: {
                            click: function($event) {
                              return _vm.editRow(value)
                            }
                          }
                        },
                        [_c("SVGEdit")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "admin-table-option",
                          on: {
                            click: function($event) {
                              return _vm.deleteRow(value)
                            }
                          }
                        },
                        [_c("SVGClose")],
                        1
                      )
                    ])
                  ])
                ])
              }),
              0
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }