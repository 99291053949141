import {http_request} from "../../../httpConfig";

export const cities = {
    namespaced: true,
    state: () => ({
        numberLoadedItems: 5,
        cities: [],
        waitingResponseToCityRequest: false,
        allUploaded: false,
        mask: ''
    }),
    mutations: {
        CITIES: (state, cities) => {
            if (cities.length !== 0)
                state.cities = state.cities.concat(cities);
            else
                state.allUploaded = true;
        },
        CLEAR_CITIES: (state) => {
            state.cities.splice(0);
            state.allUploaded = false;
        },
        MASK: (state, value) => {
            state.mask = value;
        }
    },
    getters: {
        SELF: state => {
            return state.cities;
        },
        CITY_BY_ID: state => id => {
            return state.cities.find(city => city.city_id == id);
        }
    },
    actions: {
        GET_CITIES: async function (context, inputData) {
            if (context.state.waitingResponseToCityRequest || context.state.allUploaded)
                return;

            context.state.waitingResponseToCityRequest = true;
            const mask = context.state.mask.trim();
            let dataForSend = {};

            if (inputData) {
                dataForSend = Object.assign(dataForSend, inputData);
            }

            dataForSend.part = {
                // from: context.getters.LENGTH,
                from: context.state.cities.length,
                count: context.state.numberLoadedItems
            };

            if (mask.length !== 0) {
                dataForSend.find = {
                    mask: mask + '%',
                    field: 'title_ru'
                };
            }

            await context.dispatch('REQUEST_GET_CITIES', dataForSend);
            context.state.waitingResponseToCityRequest = false;
        },
        REQUEST_GET_CITIES: async function (context, inputData) {
            console.log(inputData);
            await http_request.post('/get_cities', inputData)
                .then((request) => {
                    console.log(request.data);
                    context.commit('CITIES', request.data.cities);
                })
                .catch((error) => {
                    context.state.allUploaded = true;
                    console.error(error);
                });
        }
    }
}
