var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "45",
        height: "45",
        viewBox: "0 0 45 45",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M15.7501 0C7.07819 0 0 7.07819 0 15.7501C0 24.4219 7.07819 31.5001 15.7501 31.5001C19.6831 31.5001 23.2763 30.0331 26.0421 27.6329L27.0001 28.5909V31.5001L39.5685 44.0685C40.8105 45.3105 42.8265 45.3105 44.0685 44.0685C45.3105 42.8265 45.3105 40.8105 44.0685 39.5685L31.5001 27.0001H28.5909L27.6329 26.0421C30.0331 23.2763 31.5001 19.6831 31.5001 15.7501C31.5001 7.07819 24.4219 0 15.7501 0ZM15.7501 4.50001C21.9899 4.50001 27.0001 9.51018 27.0001 15.7501C27.0001 21.9899 21.9899 27.0001 15.7501 27.0001C9.51018 27.0001 4.50001 21.9899 4.50001 15.7501C4.50001 9.51018 9.51018 4.50001 15.7501 4.50001Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M24 16C24 16.5523 23.5523 17 23 17H9C8.44772 17 8 16.5523 8 16V16C8 15.4477 8.44772 15 9 15H23C23.5523 15 24 15.4477 24 16V16Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }