var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M1.53802 9.97504C1.89751 9.73401 2.30813 9.51674 2.75831 9.32422L3.13087 10.1954C2.72486 10.369 2.3679 10.5594 2.06568 10.762C1.55377 11.1053 1.23655 11.4581 1.07719 11.7866C0.923752 12.1029 0.907448 12.4122 1.02325 12.7237C1.27193 13.3927 2.15728 14.1227 3.72335 14.6699C5.25777 15.206 7.21898 15.4846 9.22694 15.4403L9.24784 16.3876C7.14992 16.4339 5.07243 16.1449 3.41081 15.5644C1.78083 14.9949 0.538044 14.1377 0.135119 13.0538C-0.0748809 12.4889 -0.036375 11.9112 0.224706 11.373C0.479868 10.8471 0.936887 10.3781 1.53802 9.97504ZM14.8655 9.32422C15.7183 9.68896 16.4306 10.1432 16.9193 10.6787C17.3534 11.1544 17.6103 11.6951 17.6232 12.2724C17.6363 12.8511 17.4028 13.3982 16.9863 13.8819C16.1716 14.8282 14.6494 15.547 12.7929 15.9679L12.5834 15.0439C14.3668 14.6395 15.6503 13.9815 16.2683 13.2637C16.5682 12.9154 16.6826 12.5881 16.676 12.2938C16.6693 11.9982 16.539 11.6675 16.2194 11.3174C15.8526 10.9153 15.2711 10.5282 14.4929 10.1954L14.8655 9.32422Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M10.328 15.8235L7.91185 18.2031L7.91185 13.4438L10.328 15.8235Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M8.81191 0L1.23181 7.02525L1.93375 7.78256L2.61551 7.15012V13.0757H7.77917V7.91952H9.84464V13.0757H15.0083V7.15012L15.6901 7.78256L16.392 7.02525L8.81191 0ZM8.81191 1.40183L13.9756 6.19543V12.0444H10.8774V6.88829H6.74644V12.0444H3.64824V6.19543L8.81191 1.40183Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }