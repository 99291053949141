<template>
    <div class="admin-workspace-container">
        <!--        <div class="admin-categories-filter-grid">-->
        <!--&lt;!&ndash;            <div class="modal-form-grid-label bold-text">&ndash;&gt;-->
        <!--&lt;!&ndash;                Область применения&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--&lt;!&ndash;            <select class="modal-form-grid-input">&ndash;&gt;-->
        <!--&lt;!&ndash;                <option></option>&ndash;&gt;-->
        <!--&lt;!&ndash;                <option>Кровля</option>&ndash;&gt;-->
        <!--&lt;!&ndash;                <option>Фасад</option>&ndash;&gt;-->
        <!--&lt;!&ndash;                <option>Забор</option>&ndash;&gt;-->
        <!--&lt;!&ndash;            </select>&ndash;&gt;-->
        <!--            <div class="modal-form-grid-label bold-text">-->
        <!--                Тип продукции-->
        <!--            </div>-->
        <!--            <select class="modal-form-grid-input" v-model="selectedProductType">-->
        <!--                <option v-for="productType in productTypes" :value="productType">{{productType.name}}</option>-->
        <!--&lt;!&ndash;                <option :value="1">Металлочерепица</option>&ndash;&gt;-->
        <!--&lt;!&ndash;                <option :value="2">Профнастил</option>&ndash;&gt;-->
        <!--&lt;!&ndash;                <option :value="3">Лист</option>&ndash;&gt;-->
        <!--            </select>-->
        <!--        </div>-->
        <!--        <div class="mp-sidebar-borderline" style="margin: 15px 0"></div>-->

        <div>
            <div class="admin-search-new-container">
<!--                <div>-->
<!--                    <div class="search-icon-anchor">-->
<!--                        <SVGSearch class="search-icon"/>-->
<!--                    </div>-->
<!--                    <input class="modal-form-grid-input search-input" type="text"-->
<!--                           :placeholder="'Найти'">-->
<!--                </div>-->
                <div class="blue-button admin-new-button" v-on:click="addNewSubcategory">
                    Добавить подкатегорию
                </div>
            </div>


            <div class="admin-tabs-container">
                <div v-for="(category, index) in categories"
                     class="admin-tab" :class="{'active-admin-tab': idSelectedCategory == category.id}"
                     v-on:click="selectCategory(category.id)"
                >
                    <!--                    <SVGPaintRoller style="margin-right: 12px; height: 16px; width: 16px;"/>-->
                    <div class="admin-tab-text">{{category.name}}</div>
                    <div class="admin-tab-controls">
                        <template>
                            <SVGEye v-if="category.active" class="admin-tab-icon" tooltip-content="показать/скрыть"/>
                            <SVGEyeSlash v-else class="admin-tab-icon" tooltip-content="показать/скрыть"/>
                        </template>
                        <div v-on:click="editCategory(category)">
                            <SVGEdit class="admin-tab-icon" tooltip-content="редактировать"/>
                        </div>
                    </div>
                </div>
                <div class="admin-tab-blue" v-on:click="addNewCategory">
                    +
                </div>
                <!--                <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'kind'}"-->
                <!--                     v-on:click="switchAdminTab('kind')">-->
                <!--                    <SVGKinds style="margin-right: 12px; height: 16px;"/>-->
                <!--                    <div class="admin-tab-text">Вид</div>-->
                <!--                </div>-->
                <!--                <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'surfaceKind'}"-->
                <!--                     v-on:click="switchAdminTab('surfaceKind')">-->
                <!--                    <SVGPlaneUnknown style="margin-right: 12px; height: 16px;"/>-->
                <!--                    <div class="admin-tab-text">Вид поверхности</div>-->
                <!--                </div>-->
                <!--                <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'covering'}"-->
                <!--                     v-on:click="switchAdminTab('covering')">-->
                <!--                    <SVGCovering style="margin-right: 12px; height: 16px;"/>-->
                <!--                    <div class="admin-tab-text">Покрытия</div>-->
                <!--                </div>-->
            </div>


            <vue-custom-scrollbar class="admin-table-container">
                <table class="admin-table">
                    <thead>
                    <tr class="admin-table-head-tr">
                        <th>№</th>
                        <th v-if="selectedCategoryIsColor">Изображение</th>
                        <th>Название</th>
<!--                        <th>Код (HEX)</th>-->
<!--                        <th>Код (RAL)</th>-->
                        <th class="admin-table-options-head">Опции</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(value, index) in subcategories" class="admin-table-line">
                        <td>
                            <span class="">{{index + 1}}</span>
                        </td>
                        <td v-if="selectedCategoryIsColor">
                            <img class="admin-table-image" :src="urlForPhoto(value.preview_src)"/>
                        </td>
                        <td>
                            <span>{{value.name}}</span>
                        </td>
<!--                        <td>-->
<!--                            <span>{{value.HEXCode}}</span>-->
<!--                        </td>-->
<!--                        <td>-->
<!--                            <span>{{value.RALCode}}</span>-->
<!--                        </td>-->
                        <td>
                            <div class="admin-table-options">
                                <div class="admin-table-option" v-on:click="editRow(value)">
                                    <SVGEdit/>
                                </div>
                                <div class="admin-table-option" v-on:click="deleteRow(value)">
                                    <SVGClose/>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </vue-custom-scrollbar>
        </div>

    </div>
</template>

<script>
    import SVGSearch from "../../../SVG/SVGSearch";
    import SVGSwarm from "../../../SVGMP/SVGSwarm";
    import SVGEdit from "../../../SVGMP/SVGEdit";
    import SVGClose from "../../../SVGMP/SVGClose";
    import {urlForStaticVue} from "../../../common";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import SVGPaintRoller from "../../../SVGMP/SVGPaintRoller";
    import SVGCovering from "../../../SVGMP/SVGCovering";
    import SVGKinds from "../../../SVGMP/SVGKinds";
    import SVGPlaneUnknown from "../../../SVGMP/SVGPlaneUnknown";
    import ModalAttention from "../../../ModalMP/ModalAdmin/ModalAttention";
    import ModalCategory from "../../../ModalMP/ModalAdmin/ModalCategory";
    import ModalSubcategory from "../../../ModalMP/ModalAdmin/ModalSubcategory";
    import {http_request} from "../../../../httpConfig";
    import SVGEye from "../../../SVG/SVGEye";
    import SVGEyeSlash from "../../../SVGMP/SVGEyeSlash";

    export default {
        name: "AdminWorkspaceCategories",
        mixins: [urlForStaticVue],
        components: {
            SVGEyeSlash,
            SVGEye,
            SVGPlaneUnknown,
            SVGKinds,
            SVGCovering,
            SVGPaintRoller,
            SVGClose,
            SVGEdit,
            SVGSwarm,
            SVGSearch,
            vueCustomScrollbar
        },
        data() {
            return {
                categories: [],
                subcategories: []
            }
        },
        computed: {
            idSelectedCategory() {
                return this.$route.params.categoryId;
            },
            selectedCategoryIsColor() {
                if (this.idSelectedCategory) {
                    const selectCategory = this.categories.find(category => category.id == this.idSelectedCategory);
                    if (selectCategory && selectCategory.name && typeof (selectCategory.name) === 'string') {
                        return selectCategory.name.toLowerCase().indexOf('цвет') > -1;
                    }
                }
            }
        },
        watch: {
            categories(value) {
                this.showSubcategory();
            },
            idSelectedCategory(value) {
                this.showSubcategory();
            }
        },
        methods: {
            urlForPhoto(url) {
                return this.$store.getters[`projectConfiguration/GENERATE_URL`](url);
            },
            showSubcategory() {
                if (!this.idSelectedCategory && this.categories.length > 0) {
                    this.selectFirstCategory();
                }

                const category = this.categories.find(categ => categ.id == this.idSelectedCategory);
                if (category) {
                    this.subcategories.length = 0;
                    this.subcategories.push(...category.subcategories);
                } else {
                    this.subcategories.length = 0;
                }
            },
            selectFirstCategory() {
                if (this.categories.length > 0) {
                    this.selectCategory(this.categories[0].id);
                }
            },
            addNewCategory() {
                this.$modal.show(ModalCategory, {
                    callback: this.loadCategories
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 500,
                });
            },
            editCategory(category) {
                console.log(category);
                this.$modal.show(ModalCategory, {
                    categoryForChange: category,
                    callback: this.loadCategories
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 500,
                });
            },
            editRow(subcategory) {
                this.$modal.show(ModalSubcategory, {
                    subcategoryForChange: subcategory,
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 500,
                });
            },
            addNewSubcategory() {
                this.$modal.show(ModalSubcategory, {
                    categories: this.categories,
                    defaultSelectCategoryId: this.idSelectedCategory,
                    callback: this.loadCategories
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 500,
                });
            },
            deleteRow(subcategory) {
                this.$modal.show(ModalAttention,
                    {
                        alertMsg: `Вы уверены, что хотите удалить подкатегорию "${subcategory.name}"?`,
                        callback: this.deleteSubcategory(subcategory.id)
                    }, {
                        height: 'auto',
                        pivotY: 0.2,
                        width: 515,
                    });
            },
            deleteSubcategory(subcategoryId) {
                return () => {
                    http_request.post('/delete_subcategory', {id: subcategoryId})
                        .then((request) => {
                            console.log(request.data);
                            const indexSubcategory = this.subcategories.map(subcat => subcat.id).indexOf(subcategoryId);
                            if (indexSubcategory > -1) {
                                this.subcategories.splice(indexSubcategory, 1);
                            }
                        })
                };
            },
            selectCategory(categoryId) {
                // this.activeAdminTab = tabName
                this.$router.push({name: 'adminPageCategorySelected', params: {categoryId: categoryId}}).catch(err => {
                });
            },
            loadCategories() {
                console.log('Загрузить категории');
                http_request.post('/get_categories', {})
                    .then((request) => {
                        console.log(request.data);
                        this.categories.length = 0;
                        this.categories.push(...request.data.categories);
                    })
            }
        },
        mounted() {
            // bus.$on('updateSubcategory', (subcategory) => {
            //     const indexSubcategory = this.subcategories.map(subcat => subcat.id).indexOf(subcategory.id);
            //     if (indexSubcategory > -1) {
            //         this.subcategories.splice(indexSubcategory, 1, subcategory);
            //     } else {
            //         this.subcategories.push(subcategory);
            //     }
            // })
            this.loadCategories();
        },
        destroyed() {
            // bus.$off('updateSubcategory');
        }
    }
</script>

<style scoped>

</style>
