<template>
    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52348 0L17.047 7.95525L15 10.1484L8.52348 4.10366L2.04695 10.1484L0 7.95525L8.52348 0Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGCollapseTop"
    }
</script>

<style scoped>

</style>
