var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-building-types" }, [
    _c(
      "div",
      {
        staticClass: "building-type-button",
        class: { green: _vm.selectedTagPermanentId == 4 },
        on: {
          click: function($event) {
            return _vm.selectTagPermanentId(4)
          }
        }
      },
      [
        _c("SVGPrivateHouse", { staticClass: "building-type-button-svg" }),
        _vm._v(" "),
        _c("div", { staticClass: "building-type-button-text" }, [
          _vm._v("\n                Частный дом\n            ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }