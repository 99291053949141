import {planePointer} from "../primitives/planePointer";
import store from "../../store";
import {planeLine} from "../primitives/planeLine";
import draw from "tui-image-editor/src/js/ui/template/submenu/draw";
import {planePolygon} from "../primitives/planePolygon";
import {bus} from "../../vue_bus";
import {sortObjectsByZindex} from "../libs/sortObjectsByZindex";
import {nearPoint} from "../libs/nearPoint";
import {markerPointer} from "../primitives/markerPointer";
import {getFermatPolygon} from "../../math/getAreaCorrected";
import {getEdgesFiltered} from "../../math/fermatCollection";
import {describeEdges, getIncludedPlanes, getNeighbours, getPlaneSide} from "../../math/2dPlanReconstruction";
import {marker} from "../primitives/markers";

export const markersTool = function (fabricJS) {
    this.fabricJS = fabricJS;
    this.pointZoneSize = 10;
    this.movingID = null;
    this.movingPosition = null;
    this.oldPointID = null;
    this.pointArray = [];
    this.selectedPointID = null;
    this.isComplete = false;
    this.unsaveChanges = false;

    this.drawAll = async (pointListIn) => {
        let pointList
        if (pointListIn)
            pointList = pointListIn;
        else {
            const projectUUID = store.state.route.params.projectUUID;
            const photoID = store.state.route.params.photoID;
            console.log(projectUUID);
            console.log(photoID);
            const markers = store.getters[`projectList/${projectUUID}/photoList/${photoID}/MARKERS`];
            pointList = [];
            markers.forEach((marker)=>{
                pointList.push({
                    x: marker.left,
                    y: marker.top,
                    typePlanes: marker.typePlanes
                });
            })
            console.log(pointList)
        }

        let points = []
        for (let pointID in pointList) {

            let pointer = await new marker(pointList[pointID], pointID);
            console.log(pointer)
            pointer.toFabric().set({zIndex: 7});

            pointer.dragging(true);
            this.pointArray.push(pointer);
            points.push(pointer.position);
            this.fabricJS.add(pointer.toFabric());
        }
        sortObjectsByZindex(this.fabricJS);
    }

    this.addMovingEvent = () => {
        this.fabricJS.on('object:moving', (event) => {
            this.movingID = event.target.pointID;
            this.movingPosition = {
                x: event.target.left,
                y: event.target.top
            }

            this.fabricJS.renderAll();
            this.unsaveChanges = true;
        });
    }

    this.fabricJS.on('selection:updated', (event) => {
        this.selectedPointID = event.target.pointID
    });

    this.fabricJS.on('selection:cleared', () => {
        this.selectedPointID = null;
    });

    this.destroy = () => {
        bus.$off("AcceptPlaneDrawing");
    }

     bus.$on("AcceptPlaneDrawing", async (callback) => {
        const projectUUID = store.state.route.params.projectUUID;
        const photoID = store.state.route.params.photoID;
        const planeId = parseInt(store.state.route.params.planeID);
        this.unsaveChanges = false;
        let newMarkerArray = [];
        const markers = store.getters[`projectList/${projectUUID}/photoList/${photoID}/MARKERS`];

        for (let index in markers) {
            newMarkerArray.push({
                left: this.pointArray[index].position.x,
                top: this.pointArray[index].position.y,
                idPlaneGroup: markers[index].idPlaneGroup,
                typePlanes: markers[index].typePlanes,
            });

        }
        // console.log(newMarkerArray);
        store.commit(`projectList/${projectUUID}/photoList/${photoID}/MARKERS`, newMarkerArray);

        // callback()
    })

    this.onUpdateScale = (scale)=>{
        this.pointArray.forEach((pointer)=>{
            pointer.toFabric().set({
                scaleX: 1/scale,
                scaleY: 1/scale,
            })
        })

        this.pointZoneSize = 18 / scale;
        // console.log(this.pointZoneSize);
        // console.log(scale);
    }

    // this.drawAll();

    this.isComplete = true;
    this.drawAll();
    this.addMovingEvent();
}
