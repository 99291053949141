var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "12",
        height: "13",
        viewBox: "0 0 12 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M8.57005 3.51C8.57005 5.0343 7.33435 6.27 5.81005 6.27C4.28574 6.27 3.05005 5.0343 3.05005 3.51C3.05005 1.98569 4.28574 0.749999 5.81005 0.749999C7.33435 0.749999 8.57005 1.98569 8.57005 3.51Z",
          stroke: "currentColor",
          "stroke-width": "1.5"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M10.88 13C10.88 10.1281 8.66829 7.79999 5.94 7.79999C3.21171 7.79999 1 10.1281 1 13",
          stroke: "currentColor",
          "stroke-width": "1.5"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }