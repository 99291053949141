import {planePointer} from "../primitives/planePointer";
import store from "../../store";
import {planePolygon} from "../primitives/planePolygon";
import {planeLine} from "../primitives/planeLine";
import {bus} from "../../vue_bus";
import {http_request} from "../../httpConfig";
import router from "../../router";


export const homography = function (fabricJS) {
    this.fabricJS = fabricJS;
    this.planeList = store.getters["active/ALL_PLANES_FROM_PHOTO"];
    this.polygonList = [];
    this.verticalLines = [];
    this.angleDeadZone = 5 * Math.PI / 180





    this.isVertical = (point1, point2) => {
        let angle = Math.atan2(
            (point1.x - point2.x),
            (point1.y - point2.y)
        )
        return (
            (Math.abs(angle) > Math.PI - this.angleDeadZone) && (Math.abs(angle) < Math.PI + this.angleDeadZone) ||
            (Math.abs(angle) > -this.angleDeadZone) && (Math.abs(angle) < this.angleDeadZone));
    }

    this.drawLine = (point1, point2) => {
        this.fabricJS.add(new fabric.Line(
            [point1.x, point1.y, point2.x, point2.y],
            {
                stroke: 'rgba(11,11,180)',
                selectable: false,
                strokeWidth: 5
            }))
    }

    const lineForEach = (points, callback) => {
        for (let key in points) {
            callback(points[points[key].link], points[key]);
        }
    }
    let flag = true
    for (let planeID in this.planeList) {
        planeID = parseInt(planeID);
        let pointList = this.planeList[planeID].points


        let pointArray = [];
        for (let key in pointList) {
            pointArray.push(pointList[key])
        }
        if ((pointArray.length === 4) && (flag)){
            flag = false;
            let inputData = {}
            inputData.texture_src = "84c63dee-c813-4376-bc93-ca0da7fbd87c_kamni_kamennyj_zabor_176090_3840x2160.jpg";
            inputData.scale_factor = 1;
            inputData.roi = pointArray;
            let size = store.dispatch("activeProject/GET_ACTIVE_IMG_SIZE");
            inputData.width = size.width;
            inputData.height = size.height;

            http_request.post('/get_texture_with_perspective', inputData)
                .then((request) => {
                    console.log(request)
                    let img = document.getElementById("debugTesture")
                    img.src = "data:image/png;charset=utf-8;base64," + request.data.out;
                    // img.src = request.data.out;
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        lineForEach(pointList, (point1, point2) => {
            if (this.isVertical(point1, point2)) {
                this.drawLine(point1, point2)
            }
            // console.log(this.isVertical(angle), angle*180/Math.PI, point1.x - point2.x,point1.y - point2.y)
        })

    }


}
