<template>
    <div class="sidebar-loader-container">
        <div class="sidebar-loader-grid">
            <SVGSmallLoader/>
            <div class="small-loader-text">Идёт загрузка...</div>
        </div>
    </div>
</template>

<script>
    import SVGSmallLoader from "../../SVGMP/SVGSmallLoader";
    export default {
        name: "SmallLoader",
        components: {
            SVGSmallLoader
        }
    }
</script>

<style>

    .sidebar-loader-container {
        width: 100%;
        display: flex;
        height: 50px;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    .sidebar-loader-grid {
        display: grid;
        grid-template-columns: max-content max-content;
        margin: auto;
    }

    .small-loader-text {
        color: #4C5459;
        font-size: 14px;
        padding-left: 18px;
        animation: text-blinking 0.9s linear 0s alternate infinite none running;
    }

    @keyframes text-blinking {
        from {
            opacity: 0.65;
        }
        to {
            opacity: 1;
        }
    }

    .small-loader-path {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        animation: dash 1.8s linear 0s normal infinite none running;
    }

    @keyframes dash {
        from {
            stroke-dashoffset: 100;
        }
        to {
            stroke-dashoffset: 0;
        }
    }
</style>
