var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-data-container modal-tags-grid" },
    [
      _c(
        "vue-custom-scrollbar",
        { staticClass: "admin-scrollable-tags right-border" },
        [
          _c("table", { staticClass: "admin-logs-table" }, [
            _c("thead", [
              _c("tr", { staticClass: "admin-tags-table-head" }, [
                _c("th", { staticStyle: { "padding-left": "30px" } }, [
                  _vm._v(
                    "\n                        Категория\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("th", { staticStyle: { "padding-left": "30px" } }, [
                  _vm._v("Теги")
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.categories_with_tags, function(category, index) {
                return _c(
                  "tr",
                  { class: { line: index % 2 === 0 } },
                  [
                    _c("td", { staticStyle: { "padding-left": "30px" } }, [
                      _c("div", { staticClass: "th-double" }, [
                        _c("div", [_vm._v(_vm._s(category.tag_category_name))])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        staticStyle: { "padding-left": "30px" },
                        attrs: {
                          id: category.id,
                          list: category.tags,
                          sort: false,
                          group: { name: "people" }
                        },
                        on: { end: _vm.onEnd }
                      },
                      _vm._l(category.tags, function(tag) {
                        return _c(
                          "div",
                          {
                            staticClass: "pill-tag-static",
                            staticStyle: { "margin-top": "6px" },
                            attrs: { id: tag.id }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(tag.tag_name) +
                                "\n                        "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tags-sorting-right-grid" },
        [
          _c(
            "vue-custom-scrollbar",
            {
              staticClass: "admin-scrollable-tags",
              staticStyle: { height: "300px" }
            },
            [
              _c("table", { staticClass: "admin-logs-table" }, [
                _c("thead", [
                  _c("tr", { staticClass: "admin-tags-table-head" }, [
                    _c("th", { staticStyle: { "padding-left": "30px" } }, [
                      _vm._v("Несгруппированные")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c(
                    "tr",
                    [
                      _c(
                        "draggable",
                        {
                          staticStyle: { "padding-left": "30px" },
                          attrs: {
                            id: null,
                            list: _vm.uncategorized_tags,
                            sort: false,
                            group: { name: "people" }
                          },
                          on: { end: _vm.onEnd }
                        },
                        _vm._l(_vm.uncategorized_tags, function(tag) {
                          return _c(
                            "div",
                            {
                              staticClass: "pill-tag-static",
                              staticStyle: { "margin-top": "6px" },
                              attrs: { id: tag.id }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(tag.tag_name) +
                                  "\n                            "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tag-input-grid-sized" }, [
            _c(
              "div",
              {
                staticClass:
                  "blue-button modal-accept-button tags-modal-button",
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [_vm._v("Сохранить")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "blue-outline-button modal-accept-button tags-modal-button",
                on: {
                  click: function($event) {
                    return _vm.cancel()
                  }
                }
              },
              [_vm._v("Отмена")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }