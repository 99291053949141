<template>
    <div>
        <vue-custom-scrollbar class="products-container" ref="scrollSideBarProducts" @ps-y-reach-end="scrollReachEnd">
            <div :class="{
                    'products-sidebar-double': isFilterCollapsed,
                    'products-sidebar-single': !isFilterCollapsed,
                }">
                <div v-for="product in products" class="sidebar-product-container"
                     @click="selectProduct(product.uuid)" :title="product.name">
                    <img class="sidebar-product-img" :title="product.name" :src="urlForPhoto(product.preview)">
                    <div class="sidebar-product-text">{{getShortName(product.name)}}</div>
                </div>
            </div>
        </vue-custom-scrollbar>
    </div>
</template>

<script>
    import {urlForStaticVue} from "../../../../../common";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import {bus} from "../../../../../../vue_bus";

    export default {
        name: "SidebarLeftProducts",
        mixins: [urlForStaticVue],
        components:{
            vueCustomScrollbar
        },
        props: {
            isFilterCollapsed: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            products() {
                return this.$store.state.productsWithFilters.products;
            },
        },
        watch: {
            products(value) {
                if(value.length === 0) {
                    this.$refs.scrollSideBarProducts.$el.scrollTop = 0;
                }
            }
        },
        methods: {
            scrollReachEnd(){
                if(this.products.length === 0){
                    return;
                }

                this.$store.dispatch('productsWithFilters/GET_PRODUCTS');
            },
            urlForPhoto(url) {
                return this.$store.getters[`projectConfiguration/GENERATE_URL`](url);
            },
            selectProduct(productUUID) {
                console.log('Select product', productUUID);
                this.$store.dispatch('active/SET_PRODUCT_DESIGN_GROUP', productUUID);
                bus.$emit('productSelected', productUUID);
            },
            getShortName(name) {
                const cutList = [
                    "Металлочерепица МП",
                    "Профилированный лист",
                    "Сайдинг"
                ]
                let newName;
                cutList.forEach((cutString)=>{
                    if (name.startsWith(cutString))
                        newName = name.replace(cutString,'');
                })
                return newName
            },
        },
    }
</script>

<style scoped>

</style>
