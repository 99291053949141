import store from '../store/index'

export default ((to, from, next) => {
    if(!to.matched.some(record => record.meta.offCheckApplicationVersion)) {
        const recordWithApplicationVersion = to.matched.find(record => record.meta.applicationVersion);
        if (recordWithApplicationVersion) {
            store.commit('APPLICATION_VERSION', recordWithApplicationVersion.meta.applicationVersion);
        } else {
            store.commit('APPLICATION_VERSION', null);
        }
    }
    next();
});