var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "31",
        height: "31",
        viewBox: "0 0 31 31",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M1 6C1 3.23858 3.23858 1 6 1H24.7667C27.5281 1 29.7667 3.23858 29.7667 6V24.7667C29.7667 27.5281 27.5281 29.7667 24.7667 29.7667H6C3.23858 29.7667 1 27.5281 1 24.7667V6Z",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M12.8187 8.97339H5.127M12.8187 8.97339H5.127",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M26.4935 24.357L18.8018 24.357M26.4935 24.357L18.8018 24.357",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M26.4935 20.9385L18.8018 20.9385M26.4935 20.9385L18.8018 20.9385",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M12.8189 22.2203L5.12724 22.2203",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M8.97308 18.3745L8.97308 26.0661M8.97308 18.3745L8.97308 26.0661",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M25.3455 6.40942L19.6563 12.0986M25.3455 6.40942L19.6563 12.0986",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M19.6564 6.40967L25.3455 12.0988M19.6564 6.40967L25.3455 12.0988",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }