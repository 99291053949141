// import RegisterStoreModule from '@/mixins/RegisterStoreModule'
import {http_request} from "../../httpConfig";
import {Project} from "../ProjectModuleFactory/Project";
import {Plane} from "../ProjectModuleFactory/Plane";
import {Photo} from "../ProjectModuleFactory/Photo";
import router from '../../router';
import {v4 as uuidv4} from 'uuid';
import {getModuleNamespace} from "../index";

export const projectList = {
    namespaced: true,
    state: {
        // Нельзя подключать модули, кроме динамически генерирумых из data_json проекта.
        // Т.к. при открытии нового проекта, все ранее созданные модули удаляются!
        // Создавать переменные можно.
        newUserExecutorId: null,   // Желательно переделать. Особенно, когда данная переменная обнуляется.
    },
    getters: {
        DATA_PROJECT_BY_UUID: state => uuid => {
            if (state[uuid]) return state[uuid];
            else return null;
        },
        PROJECT_BY_UUID: state => uuid => {
            return state[uuid];
        }
    },
    mutations:{
        NEW_USER_EXECUTOR_ID: (state, id) => {
            state.newUserExecutorId = id;
        },
        CLEAR_ALL:(state)=>{
            state.newUserExecutorId = null;
        }
    },
    actions: {
        NEW_USER_EXECUTOR_ID: function (context, value) {
            const id = parseInt(value);
            if(id) {
                context.commit('NEW_USER_EXECUTOR_ID', id);
                return true;
            } else {
                console.error('Указан неверный id', id)
                return false;
            }
        },
        GET_FIRST_PHOTO_PROJECT: function (context, projectUUID) {
            return context.getters[`${projectUUID}/photoList/SRC_FIRST_PHOTO`];
        },
        CREATE_PROJECT_MODULE: function (context, inputData) {
            let store = this;
            const projectUUID = inputData.projectUUID;
            const projectData = inputData.projectData;
            const pathCreateProject = inputData.rootPath.concat([projectUUID]);
            store.registerModule(pathCreateProject, new Project());
            context.dispatch(`${pathCreateProject.join('/')}/INIT`, {
                projectPath: pathCreateProject,
                projectData: projectData
            }, {root: true});
        },
        createDynamicModules: async function (context, inputData) {
            const projects = inputData.projects;
            const rootPath = inputData.rootPath;
            context.commit('CLEAR_ALL');

            context.commit('saveProject/INITIALIZATION_MODULES_PROCESS', true, {root: true});
            const recursiveCreationModules = (globalPath, objects) => {
                // const relativePath = globalPath.slice(1);
                // console.log(globalPath, relativePath);
                for (let prop in objects) {
                    const globalPathCreateModule = globalPath.concat([prop]);
                    const dataObject = objects[prop];
                    if (dataObject && dataObject.constructorName) {
                        const paramsModule = {
                            id: prop,
                            data: dataObject,
                            path: globalPath,
                        };

                        if (dataObject.constructorName === 'Photo') {
                            context.dispatch(globalPath.join('/') + '/CREATE_PHOTO_MODULE', paramsModule, {root: true});
                        } else if (dataObject.constructorName === 'Plane' && dataObject.isComplete) {
                            // Create planeModule
                            context.dispatch(globalPath.join('/') + '/CREATE_PLANE_MODULE', paramsModule, {root: true});
                        } else if (dataObject.constructorName === 'Design') {
                            context.dispatch(globalPath.join('/') + '/CREATE_DESIGN_MODULE', paramsModule, {root: true});
                        } else if (dataObject.constructorName === 'PlaneGroup') {
                            context.dispatch(globalPath.join('/') + '/CREATE_PLANE_GROUP_MODULE', paramsModule, {root: true});
                        } else if (dataObject.constructorName === 'DesignGroup') {
                            context.dispatch(globalPath.join('/') + '/CREATE_DESIGN_GROUP_MODULE', paramsModule, {root: true});
                        }
                        recursiveCreationModules(globalPathCreateModule, dataObject);
                    } else if (dataObject && dataObject.constructor === Object) {
                        recursiveCreationModules(globalPathCreateModule, dataObject);
                    }
                }
            };
            const createProject = (uuid, data) => {
                const globalPath = rootPath.concat([uuid]);
                if (!this.hasModule(globalPath)) {
                    context.dispatch('CREATE_PROJECT_MODULE', {
                        projectUUID: uuid,
                        projectData: data,
                        rootPath: rootPath
                    });
                    context.commit('resultImg/CLEAR', null, {root: true});
                    recursiveCreationModules(globalPath, data);
                } else {
                    // console.log('Этот модуль проекта уже есть!', globalPath);
                }
            };
            if (Array.isArray(projects)) {
                for (let project of projects) {
                    createProject(project.uuid, project.data_json);
                }
            }
            context.commit('saveProject/INITIALIZATION_MODULES_PROCESS', false, {root: true});
        },
        LOAD_PROJECT_BY_UUID: async function (context, uuid) {
            // uuid может быть типа string или массивом из uuid продуктов типа string
            context.commit('CLEAR_ALL');
            const pathThisModule = getModuleNamespace(this, context.state);
            if (!uuid) {
                console.log('Не указан UUID проекта!');
                return null;
            }
            let dataForSend = {
                uuid: uuid
            };
            let dataProject = await http_request.post('/get_project_lite', dataForSend)
                .then((request) => {
                    console.log('Проект', request.data);
                    return [request.data.project];
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                    return null;
                })
            if (dataProject) {
                await context.dispatch('createDynamicModules', {
                    rootPath: pathThisModule,
                    projects: dataProject
                });
                return context.getters['PROJECT_BY_UUID'](uuid);
            } else {
                return null;
            }
        },
        CREATE_NEW_PROJECT: async function (context) {
            const newProjectUUID = uuidv4();
            const pathThisModule = getModuleNamespace(this, context.state);

            const templateProject = [
                {
                    data_json: {
                        constructorName: "Project",
                        name: "Новый проект"
                    },
                    uuid: newProjectUUID
                }
            ];
            await context.dispatch('createDynamicModules', {
                rootPath: pathThisModule,
                projects: templateProject
            });
            return newProjectUUID;
        },
        SAVE_PROJECT: async function (context, uuid) {
            const userId = context.state.newUserExecutorId;
            let dataProject = context.state[uuid];

            if(userId){
                dataProject['user_id'] = userId;
            }

            console.log('save_project', {
                uuid: uuid,
                data_json: dataProject
            });

            const requestPromise = http_request.post('/save_project', {
                uuid: uuid,
                data_json: dataProject
            })
                .then((request) => {
                    console.log(request.data);
                    if(userId && context.state[uuid]) {
                        context.state.newUserExecutorId = null;
                    }
                    return true;
                })
                .catch((error) => {
                    console.error(error);
                    return false;
                });
            return await requestPromise;
        },
        SAVE_ACTIVE_PROJECT: async function (context) {
            const UUID_activeProject = context.rootState.route.params.projectUUID;
            if (!UUID_activeProject) return;

            context.dispatch('SAVE_PROJECT', UUID_activeProject);
        },
        DELETE_ACTIVE_PROJECT: async function (context) {
            const pathThisModule = getModuleNamespace(this, context.state);
            const UUID_activeProject = context.rootState.route.params.projectUUID;

            if (!UUID_activeProject) return;

            let dataForSend = {
                uuid: UUID_activeProject
            };

            return await http_request.post('/delete_project', dataForSend)
                .then((request) => {
                    this.unregisterModule(pathThisModule.concat([UUID_activeProject]));
                    router.replace({
                        name: "projects"
                    }).catch(err => {})
                    return UUID_activeProject;
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });
        },
        DELETE_ALL_PROJECTS_EXCEPT_ACTIVE: function (context) {
            const UUID_activeProject = context.rootState.route.params.projectUUID;
            const pathThisModule = getModuleNamespace(this, context.state);
            for (let propName in context.state) {
                if(this.hasModule(pathThisModule.concat([propName])) && propName !== UUID_activeProject){
                    this.unregisterModule(pathThisModule.concat([propName]));
                }
            }
        },
        DELETE_MODULE: function (context, modulePath) {
            console.log(modulePath);
            this.unregisterModule(modulePath);
            const projectUUID = modulePath[1];
            context.commit(`${projectUUID}/MODULE_WAS_REMOVED`, modulePath);
        },
        CHECKING_PROJECT_WITH_SERVER: async function (context, projectUUID) {
            const project = await context.dispatch('LOAD_PROJECT_BY_UUID', projectUUID);
            console.log(project);
        }
    }
};
