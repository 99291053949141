var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vue-custom-scrollbar",
        {
          ref: "scrollSideBarProducts",
          staticClass: "products-container",
          on: { "ps-y-reach-end": _vm.scrollReachEnd }
        },
        [
          _c(
            "div",
            {
              class: {
                "products-sidebar-double": _vm.isFilterCollapsed,
                "products-sidebar-single": !_vm.isFilterCollapsed
              }
            },
            _vm._l(_vm.products, function(product) {
              return _c(
                "div",
                {
                  staticClass: "sidebar-product-container",
                  attrs: { title: product.name },
                  on: {
                    click: function($event) {
                      return _vm.selectProduct(product.uuid)
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "sidebar-product-img",
                    attrs: {
                      title: product.name,
                      src: _vm.urlForPhoto(product.preview)
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "sidebar-product-text" }, [
                    _vm._v(_vm._s(_vm.getShortName(product.name)))
                  ])
                ]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }