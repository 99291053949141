var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "modal-data-container pv-23" }, [
      _c(
        "div",
        {
          staticClass: "modal-form-grid",
          staticStyle: { "grid-template-columns": "170px auto" }
        },
        [
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                Выберите категорию\n            ")
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedCategoryId,
                  expression: "selectedCategoryId"
                }
              ],
              staticClass: "modal-form-grid-input width100",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedCategoryId = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v("Без категории")
              ]),
              _vm._v(" "),
              _vm._l(_vm.categoriesWithTags, function(category) {
                return _c("option", { domProps: { value: category.id } }, [
                  _vm._v(
                    _vm._s(category.tag_category_name) + "\n                "
                  )
                ])
              })
            ],
            2
          )
        ]
      )
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal-data-container modal-tags-grid",
        staticStyle: { height: "301px" }
      },
      [
        _c(
          "vue-custom-scrollbar",
          { staticClass: "admin-scrollable-tags right-border" },
          [
            _c(
              "td",
              { staticStyle: { "padding-left": "30px" } },
              [
                _vm._l(_vm.tagsFromSelectedCategory, function(tag) {
                  return _c(
                    "div",
                    {
                      staticClass: "pill-tag-static",
                      staticStyle: { "margin-top": "6px" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(tag.tag_name) +
                          "\n                    "
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "edit-tag-button",
                          on: {
                            click: function($event) {
                              return _vm.initEditTag(tag.id)
                            }
                          }
                        },
                        [_c("SVGEdit", { staticStyle: { width: "12px" } })],
                        1
                      )
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "pill-outline-tag",
                    staticStyle: { "margin-top": "6px" },
                    on: {
                      click: function($event) {
                        return _vm.initAddTag()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    Добавить\n                    "
                    ),
                    _c("SVGPlus", { staticClass: "pill-tag-svg" })
                  ],
                  1
                )
              ],
              2
            )
          ]
        ),
        _vm._v(" "),
        _c("vue-custom-scrollbar", { staticClass: "admin-scrollable-tags" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedTagStatus,
                  expression: "selectedTagStatus"
                }
              ],
              staticClass: "tag-input-grid-sized"
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newNameTag,
                    expression: "newNameTag"
                  }
                ],
                staticClass: "tag-input-sized",
                attrs: { type: "text", placeholder: "введите тег" },
                domProps: { value: _vm.newNameTag },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newNameTag = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "blue-button modal-accept-button tags-modal-button",
                  on: { click: _vm.saveTag }
                },
                [_vm._v("Сохранить")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "blue-outline-button modal-accept-button tags-modal-button",
                  on: { click: _vm.cancelTag }
                },
                [_vm._v("Отмена")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.selectedTagStatus,
                  expression: "!selectedTagStatus"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "modal-data-container modal-tags-text" },
                [
                  _vm._v(
                    "\n                    Редактируйте тег\n                    "
                  ),
                  _c("SVGEdit", {
                    staticStyle: { width: "12px", margin: "0 5px" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-data-container modal-tags-text" },
                [
                  _vm._v("\n                    Или "),
                  _c("span", { staticClass: "bold-text" }, [
                    _vm._v("добавьте")
                  ]),
                  _vm._v(" новый\n                ")
                ]
              )
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-borderline-thin " })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }