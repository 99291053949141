import {Plane} from "./Plane";

export function PlaneList(paramModule) {
    this.namespaced = true;
    this.state = {
        // Здесь нельзя ничего писать! Здесь будут только динамически созданные модули проектов! Иначе сломается логика.
    }

    this.getters = {
        MAX_ID_PLANE: state => {
            let maxID = 0;
            for (let idPlane in state) {
                if (maxID < parseInt(idPlane)) maxID = idPlane;
            }
            return maxID;
        },
        PLANE_EXIST_BY_ID: state => id => {
            return !!state[id];
        },
        PLANE_BY_ID: state => id => {
            if(state[id]){
                return state[id];
            }else {
                return null;
            }
        },
        PLANE_LIST: state  => state,
        ALL_WALL: state  => {
            let wallList = {};
            for(let key in state) {
                if(state[key].type === "wall") {
                    wallList[key] = state[key];
                }
            }
            return wallList;
        }
    }

    this.actions = {
        CREATE_PLANE_MODULE: function (context, inputData) {
            let id;
            if (inputData.id) {
                id = inputData.id;
            } else {
                id = parseInt(context.getters.MAX_ID_PLANE) + 1;
            }
            const pathPlane = inputData.path.concat([id]);
            const planeData = JSON.parse(JSON.stringify(inputData.data));
            this.registerModule(pathPlane, new Plane());
            context.dispatch(`${id}/INIT`, {
                path: pathPlane,
                data: planeData,
            });
            return id;
        },

        ADD_PLANE: async function (context, paramsPlane) {
            const pathPlane = paramsPlane.path.concat(['planeList']);
            const id = await context.dispatch('CREATE_PLANE_MODULE', {
                path: pathPlane,
                data: paramsPlane.data,
            })
            return id;
        }
    }
}
