import {updatePhotoAnother} from "../../httpConfig";

export const saveRender = (quality, fabricJS, size) => {
    return new Promise((resolve, reject) => {
        let designTexture = getRenderBase64(quality, fabricJS, size);
        let byteString = atob(designTexture.split(',')[1]);
        let mimeString = designTexture.split(',')[0].split(':')[1].split(';')[0]
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        let blob = new Blob([ab], {type: mimeString});

        updatePhotoAnother(blob).then((result)=>{
            resolve(result);
        })
    });
}

export const getRenderBase64 = (quality, fabricJS, size) => {
    return fabricJS.toDataURL({
        left: 0,
        top: 0,
        width: size.width,
        height: size.height,
        multiplier: quality,
        format: "jpeg",
        quality: 0.4,
    })
}
