<template>
    <div class="thenavbar">
        <div class="logo-container" v-on:click="toIndex">
            <SVGMetallProfil/>
        </div>
        <div class="navbar-text-container">
            <div class="profile-button">
                <span>Admin</span>
                <SVGUser class="mh-10"/>
            </div>
        </div>
    </div>
</template>

<script>
    import MetallProfilLogo from "../../SVGMP/SVGMetallProfilLogo";
    import SVGUser from "../../SVGMP/SVGUser";
    import SVGMetallProfil from "../../SVGMP/SVGMetallProfil";
    export default {
        name: "TheAdminNavbar",
        components: {
            SVGMetallProfil,
            SVGUser,
            MetallProfilLogo
        },
        methods: {
            toIndex() {
                this.$router.push({name: "liteProjects"}).catch(() => {})
            }
        }
    }
</script>

<style scoped>

</style>
