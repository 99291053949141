var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lite-app-body" }, [
    _c(
      "div",
      { staticClass: "lite-configurator-sidebar" },
      [
        _c(
          "div",
          {
            staticClass: "filters-container",
            class: { collapsed: _vm.isFilterCollapsed }
          },
          [
            _vm.isFilterCollapsed
              ? _c("div", { staticClass: "filters-head-collapsed" }, [
                  _c(
                    "div",
                    {
                      staticClass: "filters-collapse-button default-border",
                      on: { click: _vm.toggleFilters }
                    },
                    [_c("SVGExpandSingle")],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "filters-collapsed-label" }, [
                    _vm._v(
                      "\n                        Параметры материала\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "collapsed-plane-type-menu" }, [
                    _c(
                      "div",
                      {
                        staticClass: "plane-type-menu-button",
                        class: { activeFiltersTab: _vm.checkActiveTab("roof") },
                        on: {
                          click: function($event) {
                            return _vm.goTo("roof")
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-center" },
                          [_c("SVGRoof")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "plane-type-menu-button-text" },
                          [_vm._v("Кровля")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "plane-type-menu-button m-top-1",
                        class: { activeFiltersTab: _vm.checkActiveTab("wall") },
                        on: {
                          click: function($event) {
                            return _vm.goTo("wall")
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-center" },
                          [_c("SVGFacade")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "plane-type-menu-button-text" },
                          [_vm._v("Фасад")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "plane-type-menu-button m-top-1",
                        class: {
                          activeFiltersTab: _vm.checkActiveTab("fence")
                        },
                        on: {
                          click: function($event) {
                            return _vm.goTo("fence")
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-center" },
                          [_c("SVGFence")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "plane-type-menu-button-text" },
                          [_vm._v("Забор")]
                        )
                      ]
                    )
                  ])
                ])
              : _c("div", { staticClass: "filters-head-expanded" }, [
                  _c(
                    "div",
                    {
                      staticClass: "filters-expanded-label",
                      on: { click: _vm.toggleFilters }
                    },
                    [
                      _c("SVGCollapseSingle", {
                        staticStyle: { "margin-right": "15px" }
                      }),
                      _vm._v(
                        "\n                        Свернуть\n                    "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "expanded-plane-type-menu" }, [
                    _c(
                      "div",
                      {
                        staticClass: "plane-type-menu-button",
                        class: { activeFiltersTab: _vm.checkActiveTab("roof") },
                        on: {
                          click: function($event) {
                            return _vm.goTo("roof")
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-center" },
                          [_c("SVGRoof")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "plane-type-menu-button-text" },
                          [_vm._v("Кровля")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "plane-type-menu-button m-left-1",
                        class: { activeFiltersTab: _vm.checkActiveTab("wall") },
                        on: {
                          click: function($event) {
                            return _vm.goTo("wall")
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-center" },
                          [_c("SVGFacade")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "plane-type-menu-button-text" },
                          [_vm._v("Фасад")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "plane-type-menu-button m-left-1",
                        class: {
                          activeFiltersTab: _vm.checkActiveTab("fence")
                        },
                        on: {
                          click: function($event) {
                            return _vm.goTo("fence")
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-center" },
                          [_c("SVGFence")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "plane-type-menu-button-text" },
                          [_vm._v("Забор")]
                        )
                      ]
                    )
                  ])
                ]),
            _vm._v(" "),
            _c(
              "vue-custom-scrollbar",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFilterCollapsed,
                    expression: "!isFilterCollapsed"
                  }
                ],
                ref: "scrollSideBarFilters",
                staticClass: "sidebar-filters-outer-container"
              },
              [
                _c(
                  "div",
                  { staticClass: "sidebar-filters-container" },
                  [
                    _c("router-view", { attrs: { name: "sidebarLeftFilters" } })
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("router-view", {
          attrs: {
            name: "sidebarLeftProducts",
            isFilterCollapsed: _vm.isFilterCollapsed
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        { staticClass: "lite-configurator-workspace" },
        [_c("router-view", { attrs: { name: "workspace" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "lite-configurator-result-tools" },
        [_c("LiteConfiguratorLowerBar")],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }