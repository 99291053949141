import {http_request} from "../../httpConfig";

export const products = {
    namespaced: true,
    state: () => ({
        numberLoadedItems: 50,
        products: [],
        waitingResponseToProductRequest: false,
        allUploaded: false,
        mask: ''
    }),
    mutations: {
        PRODUCTS: (state, products) => {
            if (products.length !== 0)
                state.products = state.products.concat(products);
            else
                state.allUploaded = true;
        },
        CLEAR_PRODUCTS: (state) => {
            state.products.splice(0);
            state.allUploaded = false;
        },
        SAVE_PRODUCT: (state, dataProduct) => {
            let indexProduct = state.products.findIndex(product => product.uuid === dataProduct.uuid);
            if (indexProduct === -1) {
                state.products.unshift(dataProduct);
            } else {
                state.products.splice(indexProduct, 1, dataProduct)
            }
        },
        DELETE_PRODUCT: (state, uuidProduct) => {
            let indexProduct = state.products.findIndex(product => product.uuid === uuidProduct);
            if (indexProduct !== -1) {
                console.log(state.products.splice(indexProduct, 1));
            }
        },
        MASK: (state, value) => {
            state.mask = value;
        }
    },
    getters: {
        SELF: state => {
            return state.products;
        }
    },
    actions: {
        GET_PRODUCTS: async function (context, inputData) {
            if(context.state.waitingResponseToProductRequest || context.state.allUploaded)
                return;

            context.state.waitingResponseToProductRequest = true;
            const mask = context.state.mask.trim();
            let dataForSend = {
                part: {
                    // from: context.getters.LENGTH,
                    from: context.state.products.length,
                    count: context.state.numberLoadedItems,
                },
            };
            if(mask.length !== 0){
                dataForSend.find = {
                    mask,
                    field: 'name'
                };
            }
            console.log(dataForSend);
            await http_request.post('/get_products', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('PRODUCTS', request.data.products);
                })
                .catch((error) => {
                    console.error(error);
                    context.state.allUploaded = true;
                });

            context.state.waitingResponseToProductRequest = false;
        },
        SAVE_PRODUCTS: async function (context, inputData) {
            console.log(inputData);
            await http_request.post('/save_product', inputData)
                .then((request) => {
                    console.log(request.data);
                    context.commit("SAVE_PRODUCT", request.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        DELETE_PRODUCT: async function (context, uuid) {
            console.log(uuid);
            await http_request.post('/delete_product', {uuid: uuid})
                .then((request) => {
                    console.log(request.data);
                    context.commit('DELETE_PRODUCT', request.data.deleted_product);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    }
};
