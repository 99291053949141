import store from "../../store";

export const planePointer = function(point, pointID, perspectiveFlag) {
    let scale = 2;
    if (store.state.route.query.view)
        scale = 2/JSON.parse(store.state.route.query.view)[0]

    if (perspectiveFlag)
        this.defaultColor = 'rgb(69,146,248)'
    else
        this.defaultColor = 'rgba(173,12,39)'

    let fabricObject = new fabric.Circle({
        radius: 6,
        originX: 'center',
        originY: 'center',
        left: point.x,
        top: point.y,
        strokeWidth: 1,
        stroke: this.defaultColor,
        fill: 'rgba(0,0,0,0)',

    });

    let fabricObject2 = new fabric.Circle({
        radius: 2,
        originX: 'center',
        originY: 'center',
        left: point.x,
        top: point.y,
        strokeWidth: 1,
        fill: this.defaultColor
    });

    this.setColor = (color) => {
        if (color === "default")
            color = this.defaultColor;
        fabricObject.set({
            stroke: color
        })
        fabricObject2.set({
            fill: color
        })
    }

    this.dragging = (dragging) => {
        group.set({
            lockMovementX: !dragging,
            lockMovementY: !dragging,
        })
    }

    let group = new fabric.Group([ fabricObject, fabricObject2 ], {
        originX: 'center',
        originY: 'center',
        pointID: pointID,
        lockMovementX: true,
        lockMovementY: true,
        scaleX: scale,
        scaleY: scale,
        // evented: false
    });

    group.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: false,
        br: false,
        tl: false,
        tr: false,
        mtr: false,
    });



    this.toFabric = () => group;


    this.position = {
        set x(val) {
            group.left = val;
        },
        get x() {
            return group.left;
        },
        set y(val) {
            group.top = val;
        },
        get y() {
            return group.top;
        }
    }

    return this
}
