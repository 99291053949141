<template>
    <div class="lover-bar-buttons">
        <div class="lover-bar-button" style="border-left: none" v-on:click="openProjectList">
            <div class="lover-bar-button-svg">
                <SVGProjectsGallery/>
            </div>
            <div class="lover-bar-button-text">
                Галерея проектов
            </div>
        </div>
        <div class="lover-bar-button" v-on:click="openModalResult">
            <div class="lover-bar-button-svg">
                <SVGShop/>
            </div>
            <div class="lover-bar-button-text">
                Результат
            </div>
        </div>
        <div class="lover-bar-button" v-on:click="redirectToCalc">
            <div class="lover-bar-button-svg">
                <SVGCalculators/>
            </div>
            <div class="lover-bar-button-text">
                Калькуляторы
            </div>
        </div>
        <div class="lover-bar-button" v-on:click="openModalContactManager">
            <div class="lover-bar-button-svg">
                <SVGContactManager/>
            </div>
            <div class="lover-bar-button-text">
                Ваш менеджер
            </div>
        </div>
        <div class="lover-bar-button" v-on:click="createPDF">
            <div class="lover-bar-button-svg">
                <SVGSavePDF/>
            </div>
            <div class="lover-bar-button-text">
                Сохранить
            </div>
        </div>
        <div class="lover-bar-button" v-on:click="openModalShare">
            <div class="lover-bar-button-svg">
                <SVGShare/>
            </div>
            <div class="lover-bar-button-text">
                Поделиться
            </div>
        </div>
    </div>
</template>

<script>
    import SVGProjectsGallery from "../../../SVGMP/SVGProjectsGallery";
    import SVGShop from "../../../SVGMP/SVGShop";
    import SVGCalculators from "../../../SVGMP/SVGCalculators";
    import SVGContactManager from "../../../SVGMP/SVGContactManager";
    import SVGSavePDF from "../../../SVGMP/SVGSavePDF";
    import SVGShare from "../../../SVGMP/SVGShare";
    import ModalResult from "../../../ModalMP/ModalLite/ModalResult";
    import ModalContactManager from "../../../ModalMP/ModalLite/ModalContactManager";
    import ModalShare from "../../../ModalMP/ModalLite/ModalShare";
    import {bus} from "../../../../vue_bus";
    import {loadResultPDF} from "../../../../reportGenerator/createPdf";

    export default {
        name: "LiteConfiguratorLowerBar",
        components: {
            SVGShare,
            SVGSavePDF,
            SVGContactManager,
            SVGCalculators,
            SVGShop,
            SVGProjectsGallery
        },
        methods: {
            openProjectList() {
                this.$router.push({
                    name: "liteProjects",
                }).catch(() => {
                });
                // https://metallprofil.ru/calculators/
            },
            redirectToCalc() {
                //Todo get from database
                window.open("https://metallprofil.ru/calculators/", '_blank').focus();
            },
            openModalResult() {
                const promise = new Promise((resolve)=>{
                    bus.$emit("getRenderBase64", ()=> {
                        this.$modal.show(ModalResult, {}, {
                            height: 'auto',
                            pivotY: 0.2,
                            width: 920
                        });
                        resolve();
                    });
                })
                this.$store.dispatch('IN_PROGRESS', promise);
            },
            openModalContactManager() {
                this.$modal.show(ModalContactManager, {}, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 515,
                });
            },
            openModalShare() {
                const promise = new Promise((resolve)=> {
                    bus.$emit("saveRenderedImage", (src) => {
                        this.$modal.show(ModalShare, {
                            src: src,
                        }, {
                            height: 'auto',
                            pivotY: 0.2,
                            width: 515,
                        });
                        resolve();
                    });
                });
                this.$store.dispatch('IN_PROGRESS', promise);
            },
            async createPDF() {
                bus.$emit("getRenderBase64", async (src)=>{
                    // const photos = await this.$store.dispatch("resultImg/GET_UPLOADED_PHOTOS_BASE64", {
                    //     idsPhotos: Object.keys(this.$store.getters["active/GET_ALL_PHOTO"]),
                    //     idsDesigns: [this.$store.state.route.params.designID]
                    // });
                    // await this.$store.dispatch('costLite/GET_COST');
                    const projectUUID = this.$store.state.route.params.projectUUID;
                    const photoID = this.$store.state.route.params.photoID;
                    const designID = this.$store.state.route.params.designID;
                    const renderedPhotos = [];
                    for (let id of Object.keys(this.$store.getters['active/GET_ALL_PHOTO'])) {
                        renderedPhotos.push(
                            this.$store.getters[`projectList/${projectUUID}/designList/${designID}/RENDERED_PHOTO_BY_ID`](id));
                    }
                    const promisePDF = loadResultPDF(await this.$store.dispatch('costLite/GET_COST'), renderedPhotos);
                    await this.$store.dispatch('IN_PROGRESS', promisePDF);
                });
            },
        }
    }
</script>

<style scoped>

</style>
