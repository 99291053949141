<template>
    <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M44 7.5H9C8.17157 7.5 7.5 8.17157 7.5 9V44C7.5 44.8284 8.17157 45.5 9 45.5H44C44.8284 45.5 45.5 44.8284 45.5 44V9C45.5 8.17157 44.8284 7.5 44 7.5ZM9 6.5C7.61929 6.5 6.5 7.61929 6.5 9V44C6.5 45.3807 7.61929 46.5 9 46.5H44C45.3807 46.5 46.5 45.3807 46.5 44V9C46.5 7.61929 45.3807 6.5 44 6.5H9Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 28H0L0 25.5H5L5 28Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 21H0L0 18.5H5L5 21Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 14L0 14L0 11.5L5 11.5L5 14Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 42H0L0 39.5H5L5 42Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 35H0L0 32.5H5L5 35Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53 27H48V24.5H53V27Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53 20H48V17.5H53V20Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53 13L48 13V10.5L53 10.5V13Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53 41H48V38.5H53V41Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53 34H48V31.5H53V34Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M25 5L25 -1.09278e-07L27.5 0L27.5 5L25 5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 5L32 -1.09278e-07L34.5 0L34.5 5L32 5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39 5L39 -1.09278e-07L41.5 0L41.5 5L39 5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 5L11 -1.09278e-07L13.5 0L13.5 5L11 5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 5L18 -1.09278e-07L20.5 0L20.5 5L18 5Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M25 53L25 48L27.5 48L27.5 53L25 53Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 53L32 48L34.5 48L34.5 53L32 53Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39 53L39 48L41.5 48L41.5 53L39 53Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 53L11 48L13.5 48L13.5 53L11 53Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 53L18 48L20.5 48L20.5 53L18 53Z" fill="currentColor"/>
        <path d="M10.5 13C10.5 11.6193 11.6193 10.5 13 10.5H40C41.3807 10.5 42.5 11.6193 42.5 13V40C42.5 41.3807 41.3807 42.5 40 42.5H13C11.6193 42.5 10.5 41.3807 10.5 40V13Z" fill="currentColor"/>
        <path d="M22.0261 30.8408C21.8023 30.9385 21.5989 31.03 21.4158 31.1155C21.2367 31.2009 21.0007 31.2904 20.7078 31.384C20.4596 31.4613 20.189 31.5264 19.896 31.5793C19.6071 31.6363 19.2877 31.6648 18.9377 31.6648C18.2786 31.6648 17.6784 31.5732 17.1372 31.3901C16.6001 31.203 16.1322 30.912 15.7334 30.5173C15.3428 30.1308 15.0376 29.6405 14.8179 29.0464C14.5981 28.4482 14.4883 27.7545 14.4883 26.9651C14.4883 26.2164 14.5941 25.547 14.8057 24.957C15.0173 24.367 15.3224 23.8686 15.7212 23.4617C16.1077 23.067 16.5736 22.7659 17.1189 22.5583C17.6682 22.3508 18.2765 22.2471 18.9438 22.2471C19.4321 22.2471 19.9184 22.3061 20.4026 22.4241C20.8909 22.5421 21.432 22.7496 22.0261 23.0466V24.481H21.9346C21.4341 24.0618 20.9377 23.7567 20.4453 23.5654C19.953 23.3742 19.426 23.2786 18.8645 23.2786C18.4047 23.2786 17.9897 23.3538 17.6194 23.5044C17.2532 23.6509 16.9256 23.8808 16.6367 24.1941C16.356 24.4993 16.1362 24.8858 15.9775 25.3538C15.8229 25.8176 15.7456 26.3547 15.7456 26.9651C15.7456 27.6039 15.8311 28.1532 16.002 28.613C16.1769 29.0728 16.4007 29.4472 16.6733 29.7361C16.9582 30.0372 17.2898 30.261 17.6682 30.4075C18.0507 30.5499 18.4535 30.6211 18.8767 30.6211C19.4586 30.6211 20.0038 30.5214 20.5125 30.322C21.0211 30.1226 21.4972 29.8236 21.9407 29.4248H22.0261V30.8408Z" fill="white"/>
        <path d="M29.6799 25.1584C29.6799 25.5613 29.6087 25.9356 29.4663 26.2815C29.328 26.6233 29.1326 26.9203 28.8804 27.1726C28.5671 27.4859 28.1968 27.7219 27.7695 27.8806C27.3423 28.0352 26.8031 28.1125 26.1521 28.1125H24.9436V31.5H23.7351V22.4119H26.2009C26.7462 22.4119 27.208 22.4587 27.5864 22.5522C27.9648 22.6418 28.3005 22.7842 28.5935 22.9795C28.9394 23.2114 29.2059 23.5003 29.3931 23.8462C29.5843 24.1921 29.6799 24.6295 29.6799 25.1584ZM28.4226 25.189C28.4226 24.8757 28.3677 24.603 28.2578 24.3711C28.1479 24.1392 27.9811 23.95 27.7573 23.8035C27.562 23.6773 27.3382 23.5878 27.0859 23.5349C26.8377 23.4779 26.5224 23.4495 26.1399 23.4495H24.9436V27.0811H25.9629C26.4512 27.0811 26.8479 27.0383 27.1531 26.9529C27.4583 26.8634 27.7065 26.723 27.8977 26.5317C28.0889 26.3364 28.2232 26.1309 28.3005 25.9153C28.3819 25.6996 28.4226 25.4575 28.4226 25.189Z" fill="white"/>
        <path d="M38.1211 27.8501C38.1211 28.5093 38.0479 29.085 37.9014 29.5774C37.759 30.0657 37.5229 30.4726 37.1934 30.7981C36.88 31.1073 36.5138 31.3332 36.0947 31.4756C35.6756 31.618 35.1873 31.6892 34.6299 31.6892C34.0602 31.6892 33.5638 31.6139 33.1406 31.4634C32.7174 31.3128 32.3614 31.0911 32.0725 30.7981C31.7429 30.4644 31.5049 30.0616 31.3584 29.5896C31.216 29.1176 31.1448 28.5378 31.1448 27.8501V22.4119H32.3533V27.9111C32.3533 28.4035 32.3858 28.7921 32.4509 29.0769C32.5201 29.3617 32.634 29.6201 32.7927 29.8521C32.9718 30.1165 33.2139 30.3159 33.519 30.4502C33.8283 30.5845 34.1986 30.6516 34.6299 30.6516C35.0653 30.6516 35.4355 30.5865 35.7407 30.4563C36.0459 30.322 36.29 30.1206 36.4731 29.8521C36.6318 29.6201 36.7437 29.3556 36.8088 29.0586C36.878 28.7575 36.9126 28.3852 36.9126 27.9417V22.4119H38.1211V27.8501Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGCPU"
    }
</script>

<style scoped>

</style>
