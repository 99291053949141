var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-background" }, [
    _c("div", { staticClass: "modal-head-grid" }, [
      _c("div", { staticClass: "modal-label" }, [
        _vm._v("\n                Поделиться\n            ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-close-btn-mp",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("SVGClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-tip-grid" }, [
        _c(
          "div",
          { staticClass: "modal-tip-icon" },
          [_c("SVGShare", { staticStyle: { width: "48px", height: "45px" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-tip-text" }, [
          _vm._v(
            "\n                    Поделитесь вашим проектом в социальных сетях или отправьте его на E-mail.\n                "
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-share-buttons-container" }, [
        _c(
          "div",
          { staticClass: "modal-share-buttons-grid" },
          [
            _c(
              "ShareNetwork",
              {
                staticClass: "lover-bar-button std-gray-border",
                attrs: {
                  network: "Email",
                  url: _vm.getDesignURL(),
                  title: _vm.getTitle(),
                  hashtags: "металл профиль, фасад, кровля",
                  media: _vm.urlForFileStorage(_vm.src)
                }
              },
              [_c("SVGMailTo")],
              1
            ),
            _vm._v(" "),
            _c(
              "ShareNetwork",
              {
                staticClass: "lover-bar-button std-gray-border",
                attrs: {
                  network: "WhatsApp",
                  url: _vm.getDesignURL(),
                  title: _vm.getTitle(),
                  hashtags: "металл профиль, фасад, кровля",
                  media: _vm.urlForFileStorage(_vm.src)
                }
              },
              [_c("SVGWhatsapp")],
              1
            ),
            _vm._v(" "),
            _c(
              "ShareNetwork",
              {
                staticClass: "lover-bar-button std-gray-border",
                attrs: {
                  network: "Telegram",
                  url: _vm.getDesignURL(),
                  title: _vm.getTitle(),
                  hashtags: "металл профиль, фасад, кровля",
                  media: _vm.urlForFileStorage(_vm.src)
                }
              },
              [_c("SVGTelegram")],
              1
            ),
            _vm._v(" "),
            _c(
              "ShareNetwork",
              {
                staticClass: "lover-bar-button std-gray-border",
                attrs: {
                  network: "VK",
                  url: _vm.getDesignURL(),
                  title: _vm.getTitle(),
                  hashtags: "металл профиль, фасад, кровля",
                  media: _vm.getRenderSRC()
                }
              },
              [_c("SVGVK")],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }