<template>
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M49.4225 58.5777L54 54.0002L44.0844 44.0846L39.5069 48.662C36.5069 50.6434 22.1773 36.4484 23.8864 33.0415L28.4639 28.4641L18.5143 18.5145L13.9368 23.0919C9.76647 35.6158 37.7467 63.3941 49.4225 58.5777Z" fill="currentColor"/>
        <path d="M36 12.5442C36 11.4396 36.8969 10.536 37.9981 10.6226C44.0182 11.0964 49.6993 13.6993 54 18C58.3007 22.3007 60.9036 27.9818 61.3774 34.0019C61.464 35.1031 60.5604 36 59.4558 36L58.6236 36C57.5191 36 56.6337 35.1024 56.5268 34.003C56.0661 29.2664 53.9772 24.811 50.5831 21.4169C47.189 18.0228 42.7336 15.9339 37.997 15.4732C36.8976 15.3663 36 14.4809 36 13.3764L36 12.5442Z" fill="currentColor"/>
        <path d="M34.4571 20.3599C34.4571 19.8077 34.9052 19.3571 35.4566 19.3874C37.5028 19.5001 39.5177 19.958 41.4153 20.744C43.6214 21.6578 45.6258 22.9971 47.3142 24.6855C49.0027 26.374 50.342 28.3784 51.2558 30.5845C52.0418 32.4821 52.4997 34.497 52.6124 36.5432C52.6427 37.0946 52.1921 37.5427 51.6398 37.5427L51.1065 37.5427C50.5543 37.5427 50.1099 37.0945 50.0746 36.5434C49.965 34.8301 49.574 33.1442 48.9153 31.5539C48.1288 29.6552 46.9761 27.9301 45.5229 26.4769C44.0697 25.0237 42.3446 23.871 40.4459 23.0845C38.8556 22.4258 37.1697 22.0348 35.4564 21.9252C34.9053 21.8899 34.4571 21.4455 34.4571 20.8933L34.4571 20.3599Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGPhone"
    }
</script>

<style scoped>

</style>
