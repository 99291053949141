import {http_request} from "../../httpConfig";

export const config = {
    namespaced: true,
    state: () => ({
        config: [],
        waitingResponseToConfigRequest: false
    }),
    mutations: {
        CONFIG: (state, config) => {
            state.config = config;
        },
        SAVE_CONFIG:(state, configData) => {
            let indexConfig = state.config.findIndex(config => config.name === configData.name);
            if (indexConfig === -1) {
                state.config.unshift(configData);
            } else {
                state.config.splice(indexConfig, 1, configData)
            }
        }
    },
    getters: {
        SELF: state => {
            return state.config;
        }
    },
    actions: {
        GET_CONFIG: async function (context, inputData) {
            context.state.waitingResponseToConfigRequest = true;
            let dataForSend = {};
            await http_request.post('/get_config', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('CONFIG', request.data.config);
                })
                .catch((error) => {
                    console.error(error);
                });
            context.state.waitingResponseToConfigRequest = false;
        },
        UPDATE_CONFIG: async function (context, inputData) {
            await http_request.post('/update_config', inputData)
                .then((request) => {
                    console.log(request.data);
                    context.commit('SAVE_CONFIG', inputData);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        RESET_CONFIG: async function (context, inputData) {
            await http_request.post('/reset_config', inputData)
                .then((request) => {
                    console.log(request.data);
                    context.commit('SAVE_CONFIG', inputData);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
};
