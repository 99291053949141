var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-background" }, [
    _c("div", { staticClass: "modal-head-grid" }, [
      _c("div", { staticClass: "modal-label" }, [
        _vm._v("\n            Обратная связь\n        ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-close-btn-mp",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("SVGClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-tip-grid" }, [
        _c(
          "div",
          { staticClass: "modal-tip-icon" },
          [_c("SVGPhone", { staticStyle: { width: "72px", height: "72px" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-tip-text" }, [
          _vm._v(
            "\n                Наши менеджеры всегда готовы проконсультировать Вас! Просто оставьте заявку и вскоре Вам перезвонят.\n            "
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-form-grid" }, [
        _c("div", { staticClass: "modal-form-grid-label" }, [
          _vm._v("\n                Ваше имя\n            ")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name"
            }
          ],
          staticClass: "modal-form-grid-input",
          attrs: { type: "text", placeholder: "Как к Вам обращаться?" },
          domProps: { value: _vm.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-form-grid-label" }, [
          _vm._v("\n                Ваш телефон\n            ")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.phoneNumber,
              expression: "phoneNumber"
            }
          ],
          staticClass: "modal-form-grid-input",
          attrs: { type: "text", placeholder: "+7 (***)-***-**-**" },
          domProps: { value: _vm.phoneNumber },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.phoneNumber = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c(
        "div",
        {
          staticClass: "blue-button modal-accept-button",
          on: { click: _vm.sendMail }
        },
        [_vm._v("Заказать звонок")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }