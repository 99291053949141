<template>
    <div class="lite-app-body">
        <div class="lite-projects-sidebar">
            <SidebarProjectsBuildingTypes :selectedTagPermanentId="selectedTagPermanentId" v-on:selectTagPermanentId="selectTagPermanentId"/>
            <div class="lite-projects-sidebar-list">
                <SidebarProjectsLite v-on:selectProject="selectProject" :selectProjectUuid="projectUuid" :selectedTagPermanentId="selectedTagPermanentId"/>
            </div>
        </div>
        <div class="lite-projects-workspace">
            <div class="arrow-photo-left" style="left: 460px;" v-on:click="previousPhoto">
                <SVGArrowSwitchPhotoLeft/>
            </div>
            <div class="arrow-photo-right" :style="{'right': rightPhotoArrow}" v-on:click="nextPhoto">
                <SVGArrowSwitchPhotoRight/>
            </div>
            <div v-if="idShowingPhoto" class="configurator-button" v-on:click="goToSelectProduct">
                Подобрать материалы
                <SVGColorCards style="margin-left: 10px"/>
            </div>
            <div v-if="photoList.length > 0" class="photo-navigation-tip">
                <SVGRotateHouse/>
                <template v-for="(photo) in photoList">
                    <div class="house-rotation-mark" :class="{green: photo.id == idShowingPhoto}"
                         v-on:click="selectPhoto(photo.id)"></div>
                </template>
            </div>
            <img class="projects-workspace-image" :src="urlForPhoto(srcShowingPhoto)"/>
        </div>

        <div class="lite-projects-sidebar-prompt" v-show="projectsPromptStatus">
            <PromptProjects/>
        </div>
    </div>
</template>

<script>
    import SidebarProjectsBuildingTypes from "./SidebarProjects/SidebarProjectsBuildingTypes";
    import PromptProjects from "./PromptProjects";
    import {urlForStaticVue} from "../../../common";
    import SVGArrowSwitchPhotoLeft from "../../../SVGMP/SVGArrowSwitchPhotoLeft";
    import SVGArrowSwitchPhotoRight from "../../../SVGMP/SVGArrowSwitchPhotoRight";
    import SVGPaintRoller from "../../../SVGMP/SVGPaintRoller";
    import SVGRotateHouse from "../../../SVGMP/SVGRotateHouse";
    import SVGColorCards from "../../../SVGMP/SVGColorCards";
    import SidebarProjectsLite from "./SidebarProjects/SidebarProjectsLite";

    export default {
        name: "TheLiteProjects",
        mixins: [urlForStaticVue],
        components: {
            SidebarProjectsLite,
            SVGColorCards,
            SVGRotateHouse,
            SVGPaintRoller,
            SVGArrowSwitchPhotoRight,
            SVGArrowSwitchPhotoLeft,
            PromptProjects,
            SidebarProjectsBuildingTypes
        },
        data() {
            return {
                photoList: [],
                project: null,
                projectUuid: null,
                designId: null,
                idShowingPhoto: null,
                selectedTagPermanentId: 4
            }
        },
        computed: {
            srcShowingPhoto() {
                const selectPhoto = this.photoList.find(photo => photo.id == this.idShowingPhoto);
                // if (selectPhoto)
                //     return selectPhoto.src;

                if (!this.project)
                    return null

                if(!this.project.data_json.designList) {
                   return selectPhoto.src;
                }

                let designId = Object.keys(this.project.data_json.designList)[0];

                if(!(designId && this.project.data_json.designList[designId].renderedPhotos)){
                    return selectPhoto.src;
                }

                const renderedPhoto = this.project.data_json.designList[designId].renderedPhotos.find(photo => photo.photoId == this.idShowingPhoto);

                if (renderedPhoto)
                    return renderedPhoto.photoSrc;

                return selectPhoto.src;
            },
            projectsPromptStatus() {
                return this.$store.state.projectsPromptStatus
            },
            rightPhotoArrow() {
                if (this.projectsPromptStatus) {
                    return '295px'
                } else {
                    return '20px'
                }
            }
        },
        methods: {
            selectTagPermanentId(tagPermanentId){
                this.selectedTagPermanentId = tagPermanentId;
            },
            selectProject(projectData){
                console.log('Выбран этот проект!', projectData);
                this.project = projectData;
                const photosProject = this.project.data_json.photoList;
                this.projectUuid = this.project.uuid;
                this.photoList.length = 0;
                this.designId = Object.keys(this.project.data_json.designList)[0];
                for (let key in photosProject) {
                    this.photoList.push({
                        id: key,
                        src: photosProject[key].src
                    });
                }
                if(this.photoList.length > 0) {
                    this.idShowingPhoto = this.photoList[0].id;
                }
            },
            nextPhoto() {
                const indexPhoto = this.photoList.findIndex(photo => photo.id == this.idShowingPhoto);
                if (indexPhoto < this.photoList.length - 1) {
                    this.idShowingPhoto = this.photoList[indexPhoto + 1].id;
                } else {
                    this.idShowingPhoto = this.photoList[0].id;
                }
            },
            previousPhoto() {
                const indexPhoto = this.photoList.findIndex(photo => photo.id == this.idShowingPhoto);
                if (indexPhoto > 0) {
                    this.idShowingPhoto = this.photoList[indexPhoto - 1].id;
                } else {
                    this.idShowingPhoto = this.photoList[this.photoList.length - 1].id;
                }
            },
            selectPhoto(id) {
                this.idShowingPhoto = id;
            },
            urlForPhoto(url) {
                if (url)
                    return this.$store.getters[`projectConfiguration/GENERATE_URL`](url);
            },
            goToSelectProduct() {
                let firstMarker;
                const activePhoto = this.project.data_json.photoList[this.idShowingPhoto];
                if(!(activePhoto && activePhoto.markers && activePhoto.markers[0])) {
                    alert('У проекта нет маркеров!');
                    return;
                }
                firstMarker = activePhoto.markers[0];

                // Необходимо для того, чтобы не происходила повторная загрузка проекта с сервера (экономия времени)
                // this.$store.commit('UI/loadedProjectList/UPLOADED_PROJECTS', [this.project])


                this.$router.push({
                    name: 'selectProduct',
                    params: {
                        projectUUID: this.projectUuid,
                        photoID: this.idShowingPhoto,
                        designID: this.designId,
                        groupID: firstMarker.idPlaneGroup,
                        facadeElement: firstMarker.typePlanes
                    }
                }).catch(() => {
                });
            }
        },
    }
</script>

<style scoped>

</style>
