// import * as THREE from 'three';
import add_renderer from "./renderer";
import {
    PointLight,
    HemisphereLight,
    PMREMGenerator,
    UnsignedByteType,
    TextureLoader,
    EquirectangularReflectionMapping,
    LinearFilter,
    LinearMipmapLinearFilter,
    sRGBEncoding,
    RepeatWrapping, Scene, Group, MeshPhongMaterial, DoubleSide, MeshPhysicalMaterial, Color,
} from "three";
import store from "../store";
import {getPerspectiveCamera} from "./getPerspectiveCamera";
import {loadModel3dPromise} from "./loader3dModels";
import {getMinYPoint} from "../fabric/libs/common";
import {baseLineToFloorPlan} from "./baseLineToFloorPlan";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import {urlForStatic} from "../UI/common";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import { CopyShader} from "three/examples/jsm/shaders/CopyShader";
import { SSAARenderPass } from "three/examples/jsm/postprocessing/SSAARenderPass";

/**
 * Calculate brightness value by RGB or HEX color.
 * @param color (String) The color value in RGB or HEX (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
function brightnessByColor (color) {
    var color = "" + color, isHEX = color.indexOf("#") == 0, isRGB = color.indexOf("rgb") == 0;
    if (isHEX) {
        const hasFullSpec = color.length == 7;
        var m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
        if (m) var r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16), g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16), b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
    }
    if (isRGB) {
        var m = color.match(/(\d+){3}/g);
        if (m) var r = m[0], g = m[1], b = m[2];
    }
    if (typeof r != "undefined") return ((r*299)+(g*587)+(b*114))/1000;
}

export const get3dTextureFromModelV3 = async function (scale, photoID) {
    const projectUUID = store.state.route.params.projectUUID;
    this.starttime = new Date().getTime();
    this.imageSize = await store.dispatch("active/GET_ACTIVE_IMG_SIZE");
    this.width = this.imageSize.width / window.devicePixelRatio;
    this.height = this.imageSize.height / window.devicePixelRatio;
    //TODO is need to getting from webgl
    this.glBufferSize = 1500;//4096;
    let widthDif = this.glBufferSize/(this.width * scale);
    let heightDif = this.glBufferSize/(this.height * scale);
    if (widthDif > heightDif)
        this.upScale = heightDif;
    else
        this.upScale = widthDif;

    const cameraParameters = store.getters[`projectList/${projectUUID}/photoList/${photoID}/CAMERA_SETTINGS`];

    let scale3d = store.getters[`projectList/${projectUUID}/photoList/${photoID}/SCALE`].scale;
    if (!scale3d)
        scale3d = 1;

    const imageSize = await store.dispatch("active/GET_ACTIVE_IMG_SIZE");

    this.camera = await new getPerspectiveCamera(cameraParameters, imageSize.width, imageSize.height, scale3d);

    this.renderer = add_renderer(this.component, {
        width: this.width * scale * this.upScale,
        height: this.height * scale * this.upScale
    });

    this.scene = new Scene();

    // let composer1, composer2, , fxaaPass;
    // const renderPass = new RenderPass( this.scene, this.camera );
    //
    // //
    //
    // fxaaPass = new ShaderPass( FXAAShader );
    // const copyPass = new ShaderPass( CopyShader );
    //
    // composer1 = new EffectComposer( this.renderer );
    // composer1.addPass( renderPass );
    // composer1.addPass( copyPass );
    //
    // const pixelRatio = this.renderer.getPixelRatio();
    //
    // fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( this.width * scale * this.upScale );
    // fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( this.height * scale * this.upScale );
    //
    // composer2 = new EffectComposer( this.renderer );
    // composer2.addPass( renderPass );
    // composer2.addPass( fxaaPass );

    let ssaaRenderPassP, copyPass2, composer3
    composer3 = new EffectComposer( this.renderer );
    composer3.setPixelRatio( 1 ); // ensure pixel ratio is always 1 for performance reasons
    ssaaRenderPassP = new SSAARenderPass( this.scene, this.camera );
    composer3.addPass( ssaaRenderPassP );
    copyPass2 = new ShaderPass( CopyShader );
    composer3.addPass( copyPass2 );



    // const width = 200;
    // const height = 200;
    // const intensity = 0.5;
    // const rectLight = new THREE.RectAreaLight( 0xffffff, intensity,  width, height );
    // rectLight.position.set( 50, 50, 30 );
    // rectLight.lookAt( 0, 0, 0 );
    // this.scene.add( rectLight )

    // const rectLightHelper = new THREE.RectAreaLightHelper( rectLight );
    // rectLight.add( rectLightHelper );

    let pointLight = new PointLight(0xffffff, 1.0);
    pointLight.position.set(500, 1000, 500);


    // pointLight.castShadow = true;
    // pointLight.shadow.mapSize.width = 1024;
    // pointLight.shadow.mapSize.height = 1024;
    // pointLight.shadow.camera.near = 0.01;
    // pointLight.shadow.camera.far = 60000;
    // pointLight.shadowDarkness = 0.5;
    // pointLight.shadow.radius = 10;

    this.scene.add(pointLight);

    // this.scene.fog = new THREE.Fog(0xffffff, 1, 100);

    // let ambientLight = new THREE.HemisphereLight(0xffffff, 1.5);
    // let ambientLight = new THREE.HemisphereLight(0xffffff, 0x080820, 2.0);
    let hemisphereLight = new HemisphereLight(0xffffff, 0x333333, 3);
    this.scene.add(hemisphereLight);

    // let ambientLight = new AmbientLight(0xffffff, 20);
    // this.scene.add(ambientLight);

    const pmremGenerator = new PMREMGenerator(this.renderer);
    pmremGenerator.compileEquirectangularShader();

    const rgbeLoader = new RGBELoader()
        .setDataType(UnsignedByteType);

    // const envMapTexture = await rgbeLoader.loadAsync( urlForStatic('/img/venice_sunset_1k.hdr') )
    const envMapTexture = await new TextureLoader().load(urlForStatic('/img/envMapTest.jpg'));
    // const envMapTexture = await new THREE.TextureLoader().load(urlForStatic('/img/envMap2.jpg'));
    // const envMapTexture = await new THREE.TextureLoader().load(urlForStatic('/img/venice_sunset_1k.hdr'));

    envMapTexture.mapping = EquirectangularReflectionMapping;
    envMapTexture.magFilter = LinearFilter;
    envMapTexture.minFilter = LinearMipmapLinearFilter;
    envMapTexture.encoding = sRGBEncoding;

    this.cleanMaterial = material => {
        material.dispose()
        // dispose textures
        for (const key of Object.keys(material)) {
            const value = material[key]
            if (value && typeof value === 'object' && 'minFilter' in value) {
                value.dispose()
            }
        }
    }

    this.cleanObject = object => {
        if (!object.traverse)
            return
        object.traverse(object => {
            if (!object.isMesh) return

            object.geometry.dispose()

            if (object.material.isMaterial) {
                this.cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) this.cleanMaterial(material)
            }
        })
    }

    this.destroy = () => {
        this.renderer.dispose();
        this.renderer = null;
        this.cleanObject(this.scene);

        this.scene = null;
        this.camera = null;
    }

    this.getTexture = async (planeID, product, boundingBox) =>
    {
        this.renderer.setSize(
            this.width * scale * this.upScale,
            this.height * scale * this.upScale);
        let reflectivity = 0.1;
        let shininess = 5;
        let specular = 0xAAAAAA;
        // hemisphereLight.intensity = 5;
        if (product.data_json && product.data_json.textureParameters) {
            const textureParameters = store.state.projectConfiguration.textureParameters[product.data_json.textureParameters];
            if (textureParameters.hasOwnProperty('reflectivity'))
                reflectivity = textureParameters.reflectivity;
            if (textureParameters.hasOwnProperty('shininess'))
                shininess = textureParameters.shininess;
            if (textureParameters.hasOwnProperty('specular'))
                specular = textureParameters.specular;
        }

        let modelSRC = store.getters[`projectConfiguration/GENERATE_URL`](
            product.model3d.model3d);
        let modelParam = product.model3d.data_json;
        let defuse_map = product.defuse_map;
        let normal_map = product.normal_map;
        let height_map = product.height_map;
        let color = defuse_map.hex;
        let textureRotation = modelParam.changeOrientation;
        let plane = store.getters["active/PLANE_BY_ID"](planeID);
        let group = new Group();
        const planeParams = store.getters[
            `projectList/${projectUUID}/photoList/${photoID}/planeList/${planeID}/PLANE_3D`];

        if (planeParams && planeParams.position && planeParams.rotation && planeParams.scale) {
            group.position.set(
                planeParams.position.x,
                planeParams.position.y,
                planeParams.position.z
            );
            group.rotation.set(
                planeParams.rotation.x,
                planeParams.rotation.y,
                planeParams.rotation.z
            );
            group.rotateX(Math.PI / 2);
            if (textureRotation)
                group.rotateY(Math.PI / 2);
        } else {
            const planePoints = plane.points;
            let minYPoint = getMinYPoint(planePoints);
            let minPoint3d = await baseLineToFloorPlan([minYPoint], this.imageSize, cameraParameters, scale);
            group.position.set(
                minPoint3d[0].x,
                minPoint3d[0].y,
                minPoint3d[0].z
            )
            if (plane.side === "left") {
                group.rotateY(-Math.PI / 2);
            }
        }

        //
        // const envMap = pmremGenerator.fromEquirectangular( envMapTexture ).texture;
        // pmremGenerator.dispose();
        //
        // this.scene.background = envMap;
        // this.scene.environment = envMap;
        let material;
        if (!product.materialType || product.materialType === "MeshPhongMaterial") {
            material = new MeshPhongMaterial(
                {
                    // color: color,
                    side: DoubleSide,
                    // map: colormap
                    // shininess: 1,
                    shininess: shininess,
                    reflectivity: reflectivity,
                    envMap: envMapTexture,
                    specular: specular,
                    // combine: THREE.AddOperation,//THREE.Multiply, THREE.MixOperation,  THREE.AddOperation
                    // envMapIntensity: 0.7,
                    // refractionRatio: 0.99,
                    // specular: 0x111111,
                    // shininess: 1,
                    // normalScale: new THREE.Vector2(0.5, 0.5),
                    // emissive: 0xffffff,
                    // precision: "highp"
                }
            );
        } else if (product.materialType === "MeshPhysicalMaterial") {
            material = new MeshPhysicalMaterial(
                {
                    side: DoubleSide,
                    roughness: 0.2,
                    metalness: 0,
                    envMap: envMapTexture,
                    clearcoat: 0,
                    clearcoatRoughness: 0

                }
            );
        }

        //TODO light map
        if (height_map.height_map) {
            let heightMap;
            let promise = new Promise((resolve) => {
                heightMap = new TextureLoader().load(store.getters[`projectConfiguration/GENERATE_URL`](height_map.height_map), () => {
                    resolve();
                })
            });
            await promise

            heightMap.center.set(0.5, 0.5);
            heightMap.wrapS = heightMap.wrapT = RepeatWrapping;

            heightMap.repeat.set(1 / heightMap.width, 1 / heightMap.height);
            heightMap.repeat_setting = {x: heightMap.repeat.x, y: heightMap.repeat.y};
            heightMap.anisotropy = 16;
            heightMap.needsUpdate = true;

            material.setValues({
                lightMap: heightMap
            });
        }

        if (normal_map.normal_map) {
            let normalMap;
            let promise = new Promise((resolve) => {
                normalMap = new TextureLoader().load(store.getters[`projectConfiguration/GENERATE_URL`](normal_map.normal_map), () => {
                    resolve();
                })
            });
            await promise

            normalMap.center.set(0.5, 0.5);
            normalMap.wrapS = normalMap.wrapT = RepeatWrapping;

            normalMap.repeat.set(1 / normal_map.width, 1 / normal_map.height);
            normalMap.repeat_setting = {x: normalMap.repeat.x, y: normalMap.repeat.y};
            normalMap.anisotropy = 16;
            normalMap.needsUpdate = true;

            material.setValues({
                normalMap: normalMap
            });
        }

        if (defuse_map.defuse_map) {
            let colormap;
            let promise = new Promise((resolve) => {
                colormap = new TextureLoader().load(store.getters[`projectConfiguration/GENERATE_URL`](defuse_map.defuse_map), () => {
                    resolve();
                })
            });
            await promise

            colormap.center.set(0.5, 0.5);
            colormap.wrapS = colormap.wrapT = RepeatWrapping;

            colormap.repeat.set(1 / defuse_map.width, 1 / defuse_map.height);
            colormap.repeat_setting = {x: colormap.repeat.x, y: colormap.repeat.y};
            colormap.anisotropy = 16;
            colormap.needsUpdate = true;

            material.setValues({
                map: colormap
            });
        } else if (color) {
            const threeColor = new Color(color)
            threeColor.convertSRGBToLinear();
            // let brightness = brightnessByColor(defuse_map.hex);
            // if (brightness < 150) {
            //     hemisphereLight.intensity = 6*1/brightness;
            //     console.log(hemisphereLight.intensity)
            // }
            material.setValues({
                color: threeColor
            });
        }

        let promise = loadModel3dPromise({
            extension: 'glb',
            src: modelSRC,
            scale: 1
        }).then(async (model3d) => {
            let meshFind = (model) => {
                if (model.type !== "Mesh")
                    return meshFind(model.children[0])
                else
                    return model
            }
            let modelMesh = meshFind(model3d);
            modelMesh.material = material;
            // modelMesh.receiveShadow = true;
            // modelMesh.castShadow = true;
            let modelScale = parseFloat(modelParam.scale)
            model3d.scale.set(
                modelScale,
                modelScale,
                modelScale,
            );
            model3d.position.set(
                parseFloat(modelParam.position.x),
                parseFloat(modelParam.position.y),
                parseFloat(modelParam.position.z),
            );
            model3d.rotation.set(
                parseFloat(modelParam.rotation.x) * Math.PI / 180,
                parseFloat(modelParam.rotation.y) * Math.PI / 180,
                parseFloat(modelParam.rotation.z) * Math.PI / 180,
            );
            // const geometry = new THREE.PlaneBufferGeometry(1,1, 4, 4);
            // let material = new THREE.MeshBasicMaterial(
            //     {
            //         color: 0xFF0000,
            //         side: THREE.DoubleSide,
            //         // map: colormap
            //     }
            // );

            // let planeMesh = new THREE.Mesh(geometry, material);
            // group.add(planeMesh);
            // this.scene.add(planeMesh)
            // group.add(model3d);
            let width;
            let height;
            let stepX;
            let stepZ;
            let error = 2.0;
            if (!textureRotation) {
                width = parseFloat(planeParams.scale.x);
                height = parseFloat(planeParams.scale.y);
            } else {
                width = parseFloat(planeParams.scale.y);
                height = parseFloat(planeParams.scale.x);
            }
            stepX = parseFloat(modelParam.repeatX) * modelScale;
            stepZ = parseFloat(modelParam.repeatZ) * modelScale;
            for (let i = -error - width/2; i <= width/2 + error; i += stepX) {
                for (let j = -error - height/2; j <= height/2 + error; j += stepZ) {
                    let part3d = model3d.clone();
                    part3d.position.set(
                        part3d.position.x + i,
                        part3d.position.y,
                        part3d.position.z + j,
                    );
                    group.add(part3d)
                }
            }

        })

        this.scene.add(group);
        await promise

        // this.renderer.setScissor( halfWidth, 0, halfWidth, container.offsetHeight );
        // composer2.render();

        ssaaRenderPassP.sampleLevel = 2;
        ssaaRenderPassP.enabled = true;

        composer3.render();
        // this.renderer.setScissorTest( false );

        // this.renderer.render(this.scene, this.camera);

        this.scene.remove(group);
        // this.cleanObject(group);

        let temp_canvas = document.createElement("canvas");
        let temp_canvas_context = temp_canvas.getContext("2d");
        temp_canvas.width = (boundingBox.maxPoint.x - boundingBox.minPoint.x) * scale;
        temp_canvas.height = (boundingBox.maxPoint.y - boundingBox.minPoint.y) * scale;
        temp_canvas_context.drawImage(
            this.renderer.domElement,
            boundingBox.minPoint.x * scale * this.upScale,
            boundingBox.minPoint.y * scale * this.upScale,
            (boundingBox.maxPoint.x - boundingBox.minPoint.x) * scale * this.upScale,
            (boundingBox.maxPoint.y - boundingBox.minPoint.y) * scale * this.upScale,
            0,
            0,
            temp_canvas.width,
            temp_canvas.height
        );
        let imageInDataURL = temp_canvas.toDataURL("webp", 0.7);
        temp_canvas.width = 1;
        temp_canvas.height = 1;
        temp_canvas = null;
        this.renderer.setSize(1, 1);
        return imageInDataURL
    }
    return this
}

