<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Параметры проекта
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container pt-23">

            <div class="two-cols-modal-grid">
                <div>
                    <div class="modal-form-grid">
                        <div class="modal-form-grid-label">
                            Название
                        </div>
                        <input class="modal-form-grid-input" type="text" placeholder="Название проекта"
                               v-model="nameProject">
                        <div class="modal-form-grid-label">
                            Тип здания
                        </div>
                        <select v-model="selectedTagIdPermanent" class="modal-form-grid-input">
                            <option v-for="tag in tagsPermanent" :value="tag.id">{{tag.tag_name}}</option>
                        </select>
                        <div class="modal-form-grid-label">
                            Доступ в Lite
                        </div>
                        <select class="modal-form-grid-input" v-model="openRead">
                            <option :value="true">Доступен</option>
                            <option :value="false">Скрыт</option>
                        </select>
                    </div>
                </div>

                <div class="modal-form-images-container">
                    <div class="modal-form-imgs-carousel">
                        <!--                        <div class="arrow-photo-left" style="right: 205px">-->
                        <!--                            <SVGArrowSwitchPhotoLeft style="height: 40px"/>-->
                        <!--                        </div>-->
                        <!--                        <div class="arrow-photo-right" style="right: 27px">-->
                        <!--                            <SVGArrowSwitchPhotoRight style="height: 40px"/>-->
                        <!--                        </div>-->
                        <!--                        <img class="modal-form-img" :src="urlForStatic('/img/projectPreviewsDemo/house3.jpg')"/>-->
                        <PreviewImgFromProject :dataProject="project.data_json" class="modal-form-img"/>
                    </div>
                    <div class="blue-link">
                        Перейти к конфигуратору
                        <SVGArrowForward style="margin-left: 5px"/>
                    </div>
                </div>
            </div>

            <div class="mp-sidebar-borderline" style="margin: 16px 0"></div>
            <div class="project-tags-menu-head">
                <div class="modal-form-grid-label">Теги</div>
                <div class="tag-menu-switch" v-on:click="switchTagsMenu(true)" v-show="!tagsExpanded">
                    Выбрать
                    <SVGExpandBot style="margin-left: 8px; width: 15px"/>
                </div>
                <div class="tag-menu-switch" v-on:click="switchTagsMenu(false)" v-show="tagsExpanded">
                    Свернуть
                    <SVGCollapseTop style="margin-left: 8px; width: 15px"/>
                </div>
            </div>

            <div class="project-modal-tags pt-23" v-show="!tagsExpanded">
                <div class="pill-tag-static" v-for="tag in projectTags">
                    {{tag.tag_name}}
                </div>
            </div>

            <div class="project-modal-tags pt-23" v-show="tagsExpanded">
                <vue-custom-scrollbar class="admin-scrollable-tags" style="height: 190px">
                    <table class="admin-logs-table">
                        <thead></thead>
                        <tbody>
                        <tr v-for="(category, index) in categories_with_tags" v-bind:class="{'line': index % 2 === 0}">
                            <td style="padding-left: 30px">
                                <span>{{category.tag_category_name}}</span>
                            </td>
                            <td style="padding-left: 30px">
                                <div v-for="tag in category.tags"
                                     :class="{'pill-tag': checkTagInProject(tag), 'pill-outline-tag': !checkTagInProject(tag)}"
                                     style="margin-top: 6px"
                                     v-on:click="clickOnTag(tag)">
                                    {{tag.tag_name}}
                                </div>
                            </td>
                        </tr>
                        <tr v-bind:class="{'line': categories_with_tags.length % 2 === 0}">
                            <td style="padding-left: 30px">
                                <span>Без категории</span>
                            </td>
                            <td style="padding-left: 30px">
                                <div v-for="tag in uncategorized_tags"
                                     :class="{'pill-tag': checkTagInProject(tag), 'pill-outline-tag': !checkTagInProject(tag)}"
                                     style="margin-top: 6px"
                                     v-on:click="clickOnTag(tag)">
                                    {{tag.tag_name}}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </vue-custom-scrollbar>
            </div>
        </div>

        <div class="modal-data-container">
            <div class="modal-lower-buttons-grid">
                <div class="blue-button modal-accept-button" v-on:click="save()">Сохранить</div>
                <div class="blue-outline-button modal-accept-button" v-on:click="$emit('close')">Отмена</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import {urlForStaticVue} from "../../common";
    import SVGArrowForward from "../../SVGMP/SVGArrowForward";
    import SVGExpandBot from "../../SVGMP/SVGExpandBot";
    import SVGCollapseTop from "../../SVGMP/SVGCollapseTop";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import {http_request} from "../../../httpConfig";
    import PreviewImgFromProject from "../../LiteVersionMP/Components/Projects/SidebarProjects/PreviewImgFromProject";

    export default {
        name: "ModalProject",
        mixins: [urlForStaticVue],
        components: {
            PreviewImgFromProject,
            SVGCollapseTop,
            SVGExpandBot,
            SVGArrowForward,
            SVGClose,
            vueCustomScrollbar
        },
        props: {
            project: {
                type: Object
            },
            callback: {
                type: Function
            }
        },
        data() {
            return {
                nameProject: null,
                openRead: false,
                tagsExpanded: false,
                projectTags: [],
                categories_with_tags: [],
                uncategorized_tags: [],
                tagsPermanent: [],
                selectedTagIdPermanent: null
            }
        },
        methods: {
            switchTagsMenu(status) {
                this.tagsExpanded = status
            },
            checkTagInProject(newTag) {
                const indexTagInProjects = this.projectTags.findIndex(tag => tag.id == newTag.id);
                return indexTagInProjects > -1;
            },
            clickOnTag(newTag) {
                const indexTagInProjects = this.projectTags.findIndex(tag => tag.id == newTag.id);
                if (indexTagInProjects > -1) {
                    this.projectTags.splice(indexTagInProjects, 1);
                } else {
                    this.projectTags.push(newTag)
                }
            },
            save() {
                let tagsInProject = this.projectTags.map(tag => tag.id);
                if (this.selectedTagIdPermanent) {
                    tagsInProject.push(this.selectedTagIdPermanent);
                }

                let newProductData = {
                    uuid: this.project.uuid,
                    name: this.nameProject,
                    open_read: this.openRead,
                    tags: tagsInProject
                };

                console.log('Отправил!', newProductData);
                http_request.post('/save_project', newProductData)
                    .then((request) => {
                        console.log(request.data);
                        if (this.callback) {
                            this.callback(request.data.project);
                        }
                        this.$emit('close');
                    })
                    .catch((error) => {
                        console.error(error);
                        alert('Ошибка при сохранении!');
                    });

            },
            getCategoriesWithTags() {
                this.categories_with_tags.length = 0;
                this.uncategorized_tags.length = 0;
                http_request.post('/get_categories_with_tags', {})
                    .then((request) => {
                        console.log(request.data);
                        this.categories_with_tags.push(...request.data.tag_categories);
                        this.uncategorized_tags.push(...request.data.uncategorized.filter(tag => !tag.permanent));
                        this.tagsPermanent.push(...request.data.uncategorized.filter(tag => tag.permanent));
                    })
                    .catch((err) => {
                        alert('Ошибка при загрузке списка тегов.');
                        console.log(err);
                    })
            }
        },
        mounted() {
            if (this.project && this.project.uuid) {
                this.nameProject = this.project.name;
                this.openRead = this.project.open_read;
                this.projectTags.push(...this.project.tags.filter(tag => !tag.permanent));
                const tagPermanent = this.project.tags.find(tag => tag.permanent);
                if (tagPermanent) {
                    this.selectedTagIdPermanent = tagPermanent.id;
                }
            } else {
                alert('Ошибка! Не переданы данные проекта!');
            }
            this.getCategoriesWithTags();
        }
    }
</script>

<style scoped>

</style>
