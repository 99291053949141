<template>
    <div class="admin-workspace-container">
        <div class="admin-search-new-container">
            <div>
                <div class="search-icon-anchor">
                    <SVGSearch class="search-icon"/>
                </div>
                <input class="modal-form-grid-input search-input" type="text" placeholder="Найти продукцию" v-model="mask">
            </div>
            <div class="blue-button admin-new-button" v-on:click="addNew">Добавить новый</div>
        </div>


        <div class="admin-tabs-container">
            <div class="admin-tab" :class="{'active-admin-tab': !!activeAreaName === false}"
                 v-on:click="selectArea()">
                <SVGSwarm style="margin-right: 12px; width: 16px;"/>
                <div class="admin-tab-text">Все товары</div>
            </div>
            <div class="admin-tab" :class="{'active-admin-tab': activeAreaName === 'roof'}"
                 v-on:click="selectArea('roof')">
                <SVGRoof style="margin-right: 12px; height: 16px;"/>
                <div class="admin-tab-text">Кровля</div>
            </div>
            <div class="admin-tab" :class="{'active-admin-tab': activeAreaName === 'wall'}"
                 v-on:click="selectArea('wall')">
                <SVGFacade style="margin-right: 12px; height: 16px;"/>
                <div class="admin-tab-text">Фасад</div>
            </div>
            <div class="admin-tab" :class="{'active-admin-tab': activeAreaName === 'gutter'}"
                 v-on:click="selectArea('gutter')">
                <SVGGutter style="margin-right: 12px; height: 16px;"/>
                <div class="admin-tab-text">Водосток</div>
            </div>
            <div class="admin-tab" :class="{'active-admin-tab': activeAreaName === 'fence'}"
                 v-on:click="selectArea('fence')">
                <SVGFence style="margin-right: 12px; height: 16px;"/>
                <div class="admin-tab-text">Забор</div>
            </div>
        </div>

        <vue-custom-scrollbar class="admin-table-container"  @ps-y-reach-end="scrollReachEnd">
            <table class="admin-table">
                <thead>
                <tr class="admin-table-head-tr">
                    <th>№</th>
                    <th>Изображение</th>
                    <th>Название</th>
<!--                    <th>-->
<!--                        <SVGFilter class="admin-table-filter-icon"/>-->
<!--                        Тип поверхности-->
<!--                    </th>-->
<!--                    <th>-->
<!--                        <SVGFilter class="admin-table-filter-icon"/>-->
<!--                        Покрытие-->
<!--                    </th>-->
                    <th class="admin-table-options-head">Опции</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(value, index) in products" class="admin-table-line">
                    <td>
                        <span class="">{{index + 1}}</span>
                    </td>
                    <td>
                        <img class="admin-table-image" :src="urlForPhoto(value.preview)"/>
                    </td>
                    <td>
                        <span>{{value.name}}</span>
                    </td>
<!--                    <td>-->
<!--                        <span>{{value.surfaceType}}</span>-->
<!--                    </td>-->
<!--                    <td>-->
<!--                        <span>{{value.covering}}</span>-->
<!--                    </td>-->
                    <td>
                        <div class="admin-table-options">
                            <div class="admin-table-option" v-on:click="editRow(value)">
                                <SVGEdit/>
                            </div>
                            <div class="admin-table-option" v-on:click="deleteRow(value)">
                                <SVGClose/>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </vue-custom-scrollbar>
    </div>
</template>

<script>
    import SVGSearch from "../../../SVG/SVGSearch";
    import SVGSwarm from "../../../SVGMP/SVGSwarm";
    import SVGRoof from "../../../SVGMP/SVGRoof";
    import SVGFacade from "../../../SVGMP/SVGFacade";
    import SVGGutter from "../../../SVGMP/SVGGutter";
    import SVGFence from "../../../SVGMP/SVGFence";
    import SVGEdit from "../../../SVGMP/SVGEdit";
    import SVGClose from "../../../SVGMP/SVGClose";
    import {urlForStaticVue} from "../../../common";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import SVGFilter from "../../../SVGMP/SVGFilter";
    import ModalProduct from "../../../ModalMP/ModalAdmin/ModalProduct";
    import ModalAttention from "../../../ModalMP/ModalAdmin/ModalAttention";
    import {http_request} from "../../../../httpConfig";

    export default {
        name: "AdminWorkspaceProducts",
        mixins: [urlForStaticVue],
        components: {
            SVGFilter,
            SVGClose,
            SVGEdit,
            SVGFence,
            SVGGutter,
            SVGFacade,
            SVGRoof,
            SVGSwarm,
            SVGSearch,
            vueCustomScrollbar
        },
        data() {
            return {
                products: [],
                activeAdminTab: 'allProducts',
                mask: '',
                timerId: null,
                requestInProgress: false,
                allProductsLoaded: false
            }
        },
        computed: {
            activeAreaName() {
                return this.$route.params.appArea;
            }
        },
        methods: {
            scrollReachEnd(){
                this.getProducts();
            },
            urlForPhoto(url) {
                return this.$store.getters[`projectConfiguration/GENERATE_URL`](url);
            },
            addNew() {
                this.$modal.show(ModalProduct, {
                    callback: (data) => {window.location.reload();}
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 880,
                });
            },
            editRow(product) {
                this.$modal.show(ModalProduct, {
                    product,
                    callback: this.updateProduct
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 880,
                });
            },
            deleteRow(product) {
                console.log(product);
                this.$modal.show(ModalAttention,
                    {
                        alertMsg: `Вы уверены, что хотите удалить продукт "${product.name}"?`,
                        callback: this.deleteProduct(product.uuid)
                    }, {
                        height: 'auto',
                        pivotY: 0.2,
                        width: 515,
                    });
            },
            deleteProduct(productUuid) {
                return () => {
                    http_request.post('/delete_product', {uuid: productUuid})
                        .then((request) => {
                            console.log(request.data);
                            const indexProduct = this.products.map(prod => prod.uuid).indexOf(productUuid);
                            if (indexProduct > -1) {
                                this.products.splice(indexProduct, 1);
                            }
                        })
                }
            },
            selectArea(areaName) {
                if (areaName) {
                    this.$router.push({name: 'adminPageProductsArea', params: {appArea: areaName}}).catch(err => {
                    });
                } else {
                    this.$router.push({name: 'adminPageProducts'}).catch(err => {
                    });
                }
            },
            updateProduct(product) {
                const indexProduct = this.products.map(prod => prod.uuid).indexOf(product.uuid);
                if (indexProduct > -1) {
                    this.products.splice(indexProduct, 1, product);
                }
            },
            callbackForProduct(data){
                const {newProducts, mask, activeAreaName, productsLength} = data;
                if(mask === this.mask
                && activeAreaName == this.activeAreaName
                && productsLength === this.products.length) {
                    if(newProducts.length === 0) {
                        this.allProductsLoaded = true;
                        return;
                    }
                    this.products.push(...newProducts);
                } else {
                    console.warn('Пришли уже неактуальные данные.', newProducts)
                }
            },
            async getProducts() {
                if(this.requestInProgress || this.allProductsLoaded){
                    return;
                }
                const productsLength = this.products.length;
                const mask = this.mask;
                const activeAreaName = this.activeAreaName;

                let dataForSend = {
                    part: {
                        from: productsLength,
                        count: 15,
                    },
                    find: {
                        mask: mask,
                    }
                };

                this.requestInProgress = true;
                if (activeAreaName) {
                    dataForSend['application_area_name'] = activeAreaName;
                    await http_request.post('/get_products_by_application_area_name', dataForSend)
                        .then((request) => {
                            this.callbackForProduct({
                                newProducts: request.data.products,
                                mask,
                                activeAreaName,
                                productsLength
                            });
                        })
                } else {
                    await http_request.post('/get_products', dataForSend)
                        .then((request) => {
                            this.callbackForProduct({
                                newProducts: request.data.products,
                                mask,
                                activeAreaName,
                                productsLength
                            });
                        })
                }
                this.requestInProgress = false;
            }
        },
        watch: {
            activeAreaName() {
                this.products.length = 0;
                this.requestInProgress = false;
                this.allProductsLoaded = false;
                this.getProducts();
            },
            mask(){
                this.products.length = 0;
                this.requestInProgress = false;
                this.allProductsLoaded = false;
                if (this.timerId) {
                    clearTimeout(this.timerId);
                    this.timerId = null;
                }
                this.timerId = setTimeout(() => {
                    this.getProducts();
                    this.timerId = null;
                }, 500);
            }
        },
        mounted() {
            this.getProducts();
        },
    }
</script>

<style scoped>

</style>
