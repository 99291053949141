import {planePointer} from "../primitives/planePointer";
import store from "../../store";
import {planePolygon} from "../primitives/planePolygon";
import {perspectiveLine} from "../primitives/perspectiveLine";
import {sortObjectsByZindex} from "../libs/sortObjectsByZindex";
import {bus} from "../../vue_bus";
import {planeLine} from "../primitives/planeLine";
import {Vector3} from "three";
import {getPerspectivePoints} from "../libs/getPerspectivePoints";
import {getPerspectiveMatrix} from "../../math/perspectiveTransform";
import {getPerspectiveCamera} from "../../threeJS/getPerspectiveCamera";

export const SetPerspectiveTool = function (fabricJS) {
    this.fabricJS = fabricJS;
    this.pointZoneSize = 5;
    this.movingID = null;
    this.polygon = null;
    this.pointArray = [];
    this.pointerArray = [];
    this.lineArray = {};
    this.activePlaneId = store.state.route.params.planeID

    this.updatePolygon = (points) => {
        let polygon = new planePolygon(points);
        this.fabricJS.remove(this.activeShape);
        this.fabricJS.add(polygon);
        this.activeShape = polygon;
        this.fabricJS.renderAll();
    }



    this.drawAll = () => {
        this.pointArray = getPerspectivePoints(this.activePlaneId);
        console.log(this.pointArray);
        for (let pointID in this.pointArray) {
            // console.log(this.pointArray[pointID].x,this.pointArray[pointID].y)
            let pointer = new planePointer(this.pointArray[pointID], pointID, true);
            pointer.toFabric().set({zIndex: 7})
            pointer.dragging(true);
            this.fabricJS.add(pointer.toFabric());
            this.pointerArray.push(pointer.toFabric());
            let link = (pointID==0)?3:pointID-1;
            let line = new perspectiveLine(this.pointArray[link], this.pointArray[pointID]);
            line.toFabric().set({zIndex: 5})
            this.fabricJS.add(line.toFabric());
            this.lineArray[link] = line;
        }

        this.polygon = new planePolygon(this.pointArray);
        this.polygon.set({zIndex: 3})
        this.fabricJS.add(this.polygon);

        sortObjectsByZindex(this.fabricJS);
    }

    this.addMovingEvent = () => {
        this.fabricJS.on('object:moving', (event) => {
            this.movingID = event.target.pointID;
            this.movingPosition = {
                x: event.target.left,
                y: event.target.top
            }
            let points = this.polygon.get("points");
            points[this.movingID].x = event.target.left
            points[this.movingID].y = event.target.top

            this.polygon.set({
                points: points
            });
            this.lineArray[this.movingID].set({
                x1: event.target.left,
                y1: event.target.top
            })

            this.lineArray[(this.movingID==0)?3:this.movingID-1].set({
                x2: event.target.left,
                y2: event.target.top
            })

            this.fabricJS.renderAll();
        });
    }

    this.destroy = () => {
        bus.$off("AcceptPlaneDrawing");
    }

    bus.$on("AcceptPlaneDrawing", async (callback) => {
        const activeProjectUUID = store.state.route.params.projectUUID;
        const idActivePhoto = store.state.route.params.photoID;
        const idActivePlane = store.state.route.params.planeID;
        await store.dispatch("active/SAVE_PERSPECTIVE_POINTS", this.pointArray);
        let perspectiveMatrix = await getPerspectiveMatrix({
            planeID: idActivePlane,
            photoID: idActivePhoto,
            scale: 1 / window.devicePixelRatio
        })
        store.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/PERSPECTIVE_MATRIX`, perspectiveMatrix);

        // TODO Now using global camera settings
        // let camera = await new getPerspectiveCamera(idActivePhoto, idActivePlane);
        // let cameraSettings = {
        //     position: camera.position,
        //     up: camera.up,
        //     target: camera.userData.target,
        //     fov: camera.fov
        // }
        // store.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/CAMERA_SETTINGS`, cameraSettings);
        callback()
    })

    this.onUpdateScale = (scale)=>{
        // console.log(scale);
        this.pointerArray.forEach((pointer)=>{
            pointer.set({
                scaleX: 2/scale,
                scaleY: 2/scale,
            })
        })
        for (let lineID in this.lineArray) {
            this.lineArray[lineID].toFabric().set({
                strokeWidth: 3 / scale,
            })
        }

    }

    this.drawAll();
    this.addMovingEvent();


}
