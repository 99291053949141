var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "17",
        height: "9",
        viewBox: "0 0 17 9",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("rect", {
        attrs: { width: "5.05405", height: "3.67568", fill: "currentColor" }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "5.9729",
          width: "5.05405",
          height: "3.67568",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          y: "4.5946",
          width: "5.05405",
          height: "3.67568",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "5.9729",
          y: "4.5946",
          width: "5.05405",
          height: "3.67568",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "11.946",
          width: "5.05405",
          height: "3.67568",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }