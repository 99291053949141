export const activeDesign = {
    namespaced: false,
    state: {},
    getters: {
        DESIGN: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const designID = rootState.route.params.designID;
            return rootGetters[`projectList/${projectUUID}/designList/DESIGN_BY_ID`](designID)
        },
        PLANE_DESIGN_SETTINGS: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const designID = rootState.route.params.designID;
            const idDesignGroup = rootState.route.params.groupID;
            const idTypePlane = rootState.route.params.facadeElement;
            if(projectUUID && designID && idDesignGroup && idTypePlane)
                return rootGetters[`projectList/${projectUUID}/designList/${designID}/designGroupList/${idDesignGroup}/DESIGN_SETTINGS_BY_PLANE_TYPE`](idTypePlane);
        },
        SHADOW_STATUS: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const designID = rootState.route.params.designID;
            const idDesignGroup = rootState.route.params.groupID;
            if(projectUUID && designID && idDesignGroup)
                return rootGetters[`projectList/${projectUUID}/designList/${designID}/designGroupList/${idDesignGroup}/SHADOWS`];
        },
        LIGHT_CORRECTION_STATUS: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const designID = rootState.route.params.designID;
            const idDesignGroup = rootState.route.params.groupID;
            if(projectUUID && designID && idDesignGroup)
                return rootGetters[`projectList/${projectUUID}/designList/${designID}/designGroupList/${idDesignGroup}/LIGHT_CORRECTION`];
        },
    },
    actions: {
        ROTATE_LEFT: function (context, planeType) {
            const projectUUID = context.rootState.route.params.projectUUID;
            const designID = context.rootState.route.params.designID;
            const idDesignGroup = context.rootState.route.params.groupID;
            context.dispatch(`projectList/${projectUUID}/designList/${designID}/designGroupList/${idDesignGroup}/ROTATE_LEFT`, planeType, {root: true});
        },
        ROTATE_RIGHT: function (context, planeType) {
            const projectUUID = context.rootState.route.params.projectUUID;
            const designID = context.rootState.route.params.designID;
            const idDesignGroup = context.rootState.route.params.groupID;
            context.dispatch(`projectList/${projectUUID}/designList/${designID}/designGroupList/${idDesignGroup}/ROTATE_RIGHT`, planeType, {root: true});
        },
        SWITCH_SHADOWS: function (context, status) {
            const projectUUID = context.rootState.route.params.projectUUID;
            const designID = context.rootState.route.params.designID;
            const idDesignGroup = context.rootState.route.params.groupID;
            if(projectUUID && designID && idDesignGroup) {
                context.commit(`projectList/${projectUUID}/designList/${designID}/designGroupList/${idDesignGroup}/SHADOWS`, status, {root: true});
            }
        },
        SWITCH_LIGHT_CORRECTION: function (context, status) {
            const projectUUID = context.rootState.route.params.projectUUID;
            const designID = context.rootState.route.params.designID;
            const idDesignGroup = context.rootState.route.params.groupID;
            if(projectUUID && designID && idDesignGroup) {
                context.commit(`projectList/${projectUUID}/designList/${designID}/designGroupList/${idDesignGroup}/LIGHT_CORRECTION`, status, {root: true});
            }
        },
        DELETE_DESIGN_MODULE: function (context,) {
            const projectUUID = context.rootState.route.params.projectUUID;
            const designID = context.rootState.route.params.designID;
            // this.unregisterModule(['projectList', projectUUID, 'designList', designID]);
            context.dispatch('projectList/DELETE_MODULE', ['projectList', projectUUID, 'designList', designID], {root: true});
        },
        SET_PRODUCT_DESIGN_GROUP: function (context, uuidProduct) {
            const projectUUID = context.rootState.route.params.projectUUID;
            const designID = context.rootState.route.params.designID;
            const idDesignGroup = context.rootState.route.params.groupID;
            const planeType = context.rootState.route.params.facadeElement;
            if(projectUUID && designID && idDesignGroup) {
                context.commit(`projectList/${projectUUID}/designList/${designID}/designGroupList/${idDesignGroup}/DESIGN_SETTINGS_BY_PLANE_TYPE`, {
                    [planeType]: {
                        uuidProduct
                    }
                }, {root: true});
            }
        },
        ADD_DESIGN: async function (context, inputData) {
            const projectUUID = context.rootState.route.params.projectUUID;
            const pathArr = ['projectList', projectUUID, 'designList'];
            let id = await this.dispatch(pathArr.join('/') + '/CREATE_NEW_DESIGN_MODULE', {
                data: inputData
            });
            return id;
        },
        ADD_DESIGN_GROUP: async function (context, inputData) {
            const projectUUID = context.rootState.route.params.projectUUID;
            const designID = context.rootState.route.params.designID;
            const planeGroupID = 1;     //TODO Должен браться из адресной строки
            const pathArr = ['projectList', projectUUID, 'designList', designID, 'designGroupList'];
            await this.dispatch(pathArr.join('/') + '/CREATE_DESIGN_GROUP_MODULE', {
                id: planeGroupID,
                path: pathArr,
                data: inputData
            });
        },
    }
};
