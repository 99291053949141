import store from '../store/index'

export default ((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.getters['profile/USER_AUTHORIZED']) {
        next({
            name: 'login',
            query: {redirect: to.fullPath},
            replace: true
        });
    } else {
        next();
    }
});
