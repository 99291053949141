var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "10",
        height: "14",
        viewBox: "0 0 10 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M7.69789 4.86007C7.50144 6.40457 6.19152 7.68228 4.69152 7.68228C3.18802 7.68228 1.85748 6.40134 1.65738 4.86007H4.69152H7.69789Z",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "0.8"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M8.52866 3.78558C8.40132 2.57298 7.70151 1.53039 6.70338 0.933772L6.13244 2.02164L5.37825 1.83576V0.44952C5.17851 0.416954 4.97334 0.399993 4.76395 0.399993C4.55457 0.399993 4.3494 0.416954 4.14966 0.44952V1.83576L3.39547 2.02164L2.82452 0.933771C1.82639 1.53039 1.12659 2.57298 0.99925 3.78558H8.52866Z",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "0.8"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M0.4 10.1178C0.4 9.21456 1.13222 8.48234 2.03546 8.48234C2.22476 8.48234 2.41298 8.51078 2.59382 8.5667L3.04492 8.7062C4.1641 9.05231 5.36172 9.05231 6.4809 8.7062L6.93199 8.5667C7.11284 8.51078 7.30106 8.48234 7.49035 8.48234C8.39359 8.48234 9.12581 9.21456 9.12581 10.1178V13.5999H0.4V10.1178Z",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "0.8"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }