<template>
<!--    <svg width="31" height="20" viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M16.815 6.1101V0.867188H24.2035L26.4704 7.63224H14.9678V0.867188H7.91517L5.39636 7.63224H12.6169V9.23894H4.80864L1.36627 18.8791H14.9678V9.23894H26.8902L30.2486 18.8791H16.815V11.6067" stroke="CurrentColor"/>-->
<!--    </svg>-->
    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin: 0 1px 1px 0">
        <path d="M15.4765 9.13731V4.28931H17.276M15.4765 14.2199V20.9445H28.0648L24.9177 12.0305H13.7456V20.9445H1.00003L4.22577 12.0305H11.5427V10.5448H4.77651L7.13681 4.28931H13.7456V10.5448H20" stroke="currentColor"/>
        <circle cx="22.3957" cy="5.79422" r="5.29422" stroke="currentColor"/>
        <path d="M25.3671 5.94304C25.3671 6.15733 25.1934 6.33104 24.9791 6.33104H22.7238V8.57177C22.7238 8.7941 22.5436 8.97433 22.3213 8.97433V8.97433C22.0989 8.97433 21.9187 8.7941 21.9187 8.57177V6.33104H19.6634C19.4491 6.33104 19.2754 6.15733 19.2754 5.94304V5.94304C19.2754 5.72875 19.4491 5.55503 19.6634 5.55503H21.9187V3.3143C21.9187 3.09197 22.0989 2.91174 22.3213 2.91174V2.91174C22.5436 2.91174 22.7238 3.09197 22.7238 3.3143V5.55503H24.9791C25.1934 5.55503 25.3671 5.72875 25.3671 5.94304V5.94304Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGFacadePlus"
    }
</script>

<style scoped>

</style>
