<template>
    <div class="admin-sidebar-menu">
        <div class="admin-sidebar-menu-element"
             v-on:click="switchAdminPage('adminPageProducts')"
             :class="{'active-menu': activeAdminPage === 'products'}">
            <div class="admin-sidebar-menu-element-svg">
                <SVGShop style="width: 16px; height: 19px;"/>
            </div>
            <div class="admin-sidebar-menu-element-text">
                Продукция
            </div>
        </div>
        <div class="admin-sidebar-menu-element"
             v-on:click="switchAdminPage('adminPageCategories')"
             :class="{'active-menu': activeAdminPage === 'categories'}">
            <div class="admin-sidebar-menu-element-svg">
                <SVGCategories/>
            </div>
            <div class="admin-sidebar-menu-element-text">
                Классификация
            </div>
        </div>
        <div class="admin-sidebar-menu-element"
             v-on:click="switchAdminPage('adminPageModels')"
             :class="{'active-menu': activeAdminPage === 'models'}">
            <div class="admin-sidebar-menu-element-svg">
                <SVG3D style="width: 16px; height: 19px;"/>
            </div>
            <div class="admin-sidebar-menu-element-text">
                3D модели
            </div>
        </div>
        <div class="admin-sidebar-menu-element"
             v-on:click="switchAdminPage('adminPageDefuseMap')"
             :class="{'active-menu': activeAdminPage === 'defuseMap'}">
            <div class="admin-sidebar-menu-element-svg">
                <SVGTexture style="width: 16px; height: 19px;"/>
            </div>
            <div class="admin-sidebar-menu-element-text">
                Текстуры
            </div>
        </div>
        <div class="admin-sidebar-menu-element"
             v-on:click="switchAdminPage('adminPageNormalMap')"
             :class="{'active-menu': activeAdminPage === 'normalMap'}">
            <div class="admin-sidebar-menu-element-svg">
                <SVGNormalMap style="width: 16px; height: 19px;"/>
            </div>
            <div class="admin-sidebar-menu-element-text">
                Карты нормалей
            </div>
        </div>
        <div class="admin-sidebar-menu-element"
             v-on:click="switchAdminPage('adminPageHeightMap')"
             :class="{'active-menu': activeAdminPage === 'heightMap'}">
            <div class="admin-sidebar-menu-element-svg">
                <SVGHeightMap style="width: 16px; height: 19px;"/>
            </div>
            <div class="admin-sidebar-menu-element-text">
                Карты высот
            </div>
        </div>
        <div class="admin-sidebar-menu-element"
             v-on:click="switchAdminPage('adminPageUsers')"
             :class="{'active-menu': activeAdminPage === 'users'}">
            <div class="admin-sidebar-menu-element-svg">
                <SVGPeople/>
            </div>
            <div class="admin-sidebar-menu-element-text">
                Пользователи
            </div>
        </div>
        <div class="admin-sidebar-menu-element"
             v-on:click="switchAdminPage('adminPageProjects')"
             :class="{'active-menu': activeAdminPage === 'projects'}">
            <div class="admin-sidebar-menu-element-svg">
                <SVGCompass/>
            </div>
            <div class="admin-sidebar-menu-element-text">
                Проекты
            </div>
        </div>
        <div class="admin-sidebar-menu-element"
             v-on:click="switchAdminPage('adminPageAdministrating')"
             :class="{'active-menu': activeAdminPage === 'administrating'}">
            <div class="admin-sidebar-menu-element-svg">
                <SVGAdministrating/>
            </div>
            <div class="admin-sidebar-menu-element-text">
                Администрирование
            </div>
        </div>
    </div>
</template>

<script>
    import SVGShop from "../../SVGMP/SVGShop";
    import SVGCategories from "../../SVGMP/SVGCategories";
    import SVGPeople from "../../SVGMP/SVGPeople";
    import SVGCompass from "../../SVGMP/SVGCompass";
    import SVGAdministrating from "../../SVGMP/SVGAdministrating";
    import SVG3D from "../../SVGMP/SVG3D";
    import SVGTexture from "../../SVGMP/SVGTexture";
    import SVGHeightMap from "../../SVGMP/SVGHeightMap";
    import SVGNormalMap from "../../SVGMP/SVGNormalMap";

    export default {
        name: "TheAdminSidebar",
        components: {
            SVGNormalMap,
            SVGHeightMap,
            SVGTexture,
            SVG3D,
            SVGAdministrating,
            SVGCompass,
            SVGPeople,
            SVGCategories,
            SVGShop
        },
        computed: {
            activeAdminPage() {
                return this.$route.meta.highlightMenu;
            },
        },
        methods: {
            switchAdminPage(pageName) {
                this.$router.push({name: pageName});
            }
        }
    }
</script>

<style scoped>

</style>
