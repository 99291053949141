var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lover-bar-buttons" }, [
    _c(
      "div",
      {
        staticClass: "lover-bar-button",
        staticStyle: { "border-left": "none" },
        on: { click: _vm.openProjectList }
      },
      [
        _c(
          "div",
          { staticClass: "lover-bar-button-svg" },
          [_c("SVGProjectsGallery")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "lover-bar-button-text" }, [
          _vm._v("\n            Галерея проектов\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "lover-bar-button", on: { click: _vm.openModalResult } },
      [
        _c("div", { staticClass: "lover-bar-button-svg" }, [_c("SVGShop")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "lover-bar-button-text" }, [
          _vm._v("\n            Результат\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "lover-bar-button", on: { click: _vm.redirectToCalc } },
      [
        _c(
          "div",
          { staticClass: "lover-bar-button-svg" },
          [_c("SVGCalculators")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "lover-bar-button-text" }, [
          _vm._v("\n            Калькуляторы\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "lover-bar-button",
        on: { click: _vm.openModalContactManager }
      },
      [
        _c(
          "div",
          { staticClass: "lover-bar-button-svg" },
          [_c("SVGContactManager")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "lover-bar-button-text" }, [
          _vm._v("\n            Ваш менеджер\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "lover-bar-button", on: { click: _vm.createPDF } },
      [
        _c(
          "div",
          { staticClass: "lover-bar-button-svg" },
          [_c("SVGSavePDF")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "lover-bar-button-text" }, [
          _vm._v("\n            Сохранить\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "lover-bar-button", on: { click: _vm.openModalShare } },
      [
        _c("div", { staticClass: "lover-bar-button-svg" }, [_c("SVGShare")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "lover-bar-button-text" }, [
          _vm._v("\n            Поделиться\n        ")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }