<template>
    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.12535 0.912388L7.05836 0.908997L1.48352 17.6251L1.61661 17.6196C2.38646 17.5881 3.9692 17.8486 4.88228 19.1183C5.47344 19.9404 6.00173 20.4558 6.53617 20.7647C7.07228 21.0745 7.60745 21.1724 8.20458 21.1724C8.79766 21.1724 9.28479 21.1238 9.87553 20.8651C10.4627 20.608 11.1476 20.1455 12.1424 19.328C13.1316 18.5151 13.8457 18.0893 14.4207 17.8659C14.9934 17.6434 15.4329 17.6197 15.8795 17.6197C16.3264 17.6197 16.7888 17.6434 17.3846 17.8663C17.982 18.0899 18.7184 18.5157 19.707 19.328C20.7018 20.1455 21.3867 20.608 21.9738 20.8651C22.5646 21.1238 23.0517 21.1724 23.6448 21.1724C24.2419 21.1724 24.7771 21.0745 25.3132 20.7647C25.8476 20.4558 26.3759 19.9404 26.9671 19.1183C27.8802 17.8486 29.4629 17.5881 30.2328 17.6196L30.3658 17.6251L24.791 0.908997L24.724 0.912388C24.3994 0.928827 23.8795 1.01705 23.3661 1.22842C22.8538 1.43937 22.3364 1.77771 22.0329 2.30225C21.6771 2.91707 21.349 3.23859 21.0514 3.40817C20.7546 3.5772 20.4788 3.60083 20.2136 3.60083C19.9563 3.60083 19.7006 3.5561 19.3654 3.36061C19.0265 3.16299 18.6047 2.8103 18.0201 2.18866C17.429 1.56002 16.9912 1.2393 16.6501 1.07671C16.3589 0.937898 16.1418 0.916271 15.9698 0.912901C15.9551 0.912901 15.9401 0.912281 15.9247 0.912364L15.8795 0.91228C15.7076 0.915649 15.4905 0.937898 15.1993 1.07671C14.8582 1.2393 14.4204 1.56002 13.8292 2.18866C13.2447 2.8103 12.8228 3.16299 12.484 3.36061C12.1487 3.5561 11.8931 3.60083 11.6357 3.60083C11.3706 3.60083 11.0947 3.5772 10.798 3.40817C10.5003 3.23859 10.1723 2.91707 9.81647 2.30225C9.51292 1.77771 8.99561 1.43937 8.48323 1.22842C7.96982 1.01705 7.44998 0.928827 7.12535 0.912388Z" stroke="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGRoof"
    }
</script>

<style scoped>

</style>
