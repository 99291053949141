import router from '../../router'

export const add_zoom_canvas = function (fabricJS, cb) {
    this.eventedObject = null;
    this.timer = null;
    this.callbacks = [];
    this.savedZoom = fabricJS.getZoom();

    this.updateZoom = () => {
        this.savedZoom = fabricJS.getZoom();
        if (this.callbacks.length)
            this.callbacks.forEach((cb)=>{
                cb(this.savedZoom)
            });
    }

    this.setCallback = (cb)=>{
        cb(this.savedZoom);
        this.callbacks.push(cb);
    }

    this.zoom_canvas_handler = function() {
        return (opt) => {
            if (opt.target)
                if (opt.target.evented) {
                    this.eventedObject = opt.target;
                    this.eventedObject.set({
                        evented: false
                    });
                    setTimeout(()=>{
                        opt.target.set({
                            evented: true
                        });
                    }, 200);
                }

            let delta = opt.e.deltaY;
            let zoom = fabricJS.getZoom();
            zoom = zoom - delta / (600 / zoom);
            if (zoom > 10) zoom = 10;
            if (zoom < 0.05) zoom = 0.05;
            fabricJS.zoomToPoint({x: opt.e.offsetX, y: opt.e.offsetY}, zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
            if (!this.timer)
                this.timer = setTimeout(()=>{
                    router.replace({query: {view: JSON.stringify(fabricJS.viewportTransform)}}).catch(err => {})
                    this.timer = null;
                }, 1000);
            this.savedZoom = zoom;
            if (this.callbacks.length)
                this.callbacks.forEach((cb)=>{
                    cb(zoom)
                });

            // if (this.eventedObject) {
            //     this.eventedObject.set({
            //         evented: true
            //     });
            // }
        }
    };

    fabricJS.on('mouse:wheel', this.zoom_canvas_handler());
    return this
}

