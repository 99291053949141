import {activePlane} from "./activePlane";
import {activeDesign} from "./activeDesign";
import {activePlaneGroup} from "./activePlaneGroup";

export default {
    namespaced: true,
    state: () => ({
        numberLoaded: 5,
        selectedPhotos: []
    }),
    modules: {
        activePlane,
        activeDesign,
        activePlaneGroup
    },
    mutations: {
        //Не писать mutations по изменения данных в projectList!!! (Правильнее обращаться к mutations в projectList из actions)
        SELECT_PHOTO: function (state, selectedPhoto) {
            state.selectedPhotos.push(selectedPhoto);
        },
        UNSELECT_PHOTO: function (state, selectedPhoto) {
            state.selectedPhotos.splice(
                state.selectedPhotos.indexOf(selectedPhoto), 1);
        },
        UNSELECT_ALL_PHOTO: function (state, selectedPhoto) {
            state.selectedPhotos.length=0;
        }
    },
    getters: {
        ALL_PLANES_FROM_PHOTO: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const photoID = rootState.route.params.photoID;
            if (projectUUID && photoID) {
                return rootGetters[`projectList/${projectUUID}/photoList/${photoID}/planeList/PLANE_LIST`];
            } else {
                return null;
            }
        },
        ALL_WALL_FROM_PHOTO: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const photoID = rootState.route.params.photoID;
            if (projectUUID && photoID) {
                return rootGetters[`projectList/${projectUUID}/photoList/${photoID}/planeList/ALL_WALL`];
            } else {
                return null;
            }
        },
        ALL_DESIGNS: function (state, getters, rootState, rootGetters) {
            const activeProjectUUID = rootState.route.params.projectUUID;
            return rootGetters[`projectList/${activeProjectUUID}/designList/ALL_DESIGNS`];
        },
        GET_ALL_PHOTO: function (state, getters, rootState, rootGetters) {
            return rootGetters[`projectList/${rootState.route.params.projectUUID}/photoList/PHOTOS`];
        },
        GET_ACTIVE_PHOTO: function (state, getters, rootState, rootGetters) {
            const projectUUID = rootState.route.params.projectUUID;
            const photoID = rootState.route.params.photoID;
            if (projectUUID && photoID && rootState.projectList[projectUUID]) {
                return rootGetters[`projectList/${projectUUID}/photoList/GET_PHOTO_BY_ID`](photoID);
            }
        },
        DATA_ACTIVE_PROJECT: function (state, getters, rootState, rootGetters) {
            const activeProjectUUID = rootState.route.params.projectUUID;
            if (activeProjectUUID) {
                const dataProject = rootGetters['projectList/DATA_PROJECT_BY_UUID'](activeProjectUUID);
                return dataProject;
            }
        },
        NAME: (state, getters, rootState, rootGetters) => {
            if (rootState.route.params.projectUUID) {
                const activeProjectUUID = rootState.route.params.projectUUID;
                return rootState.projectList[activeProjectUUID].name;
            }
        },
        TITLE_IMG: (state, getters, rootState, rootGetters) => {
            if (rootState.route.params.projectUUID) {
                const activeProjectUUID = rootState.route.params.projectUUID;
                return rootState.projectList[activeProjectUUID].img;
            }
        },
        GET_SELECTED_PHOTOS: (state, getters, rootState, rootGetters) => {
            let selectedPhotos = [];
            const projectUUID = rootState.route.params.projectUUID;
            state.selectedPhotos.forEach((photoID) => {
                    selectedPhotos.push(rootGetters[`projectList/${projectUUID}/photoList/GET_PHOTO_BY_ID`](photoID));
                }
            )
            return selectedPhotos;
        },
        CHECK_ACTIVE_PHOTO: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const activePhotoID = rootState.route.params.photoID;
            return rootGetters[`projectList/${projectUUID}/photoList/CHECK_PHOTO_BY_ID`](activePhotoID);
        },
        SCAlE_PHOTO: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const photoID = rootState.route.params.photoID;
            return rootGetters[`projectList/${projectUUID}/photoList/${photoID}/SCALE`];
        },
        CHECK_SCAlE_PHOTO: (state, getters, rootState, rootGetters) => {
            const projectUUID = rootState.route.params.projectUUID;
            const photoID = rootState.route.params.photoID;
            return rootGetters[`projectList/${projectUUID}/photoList/${photoID}/CHECK_SCAlE_PHOTO`];
        },
    },
    actions: {
        NAME: function ({state, rootState, commit}, name) {
            const activeProjectUUID = rootState.route.params.projectUUID;
            if (activeProjectUUID) {
                commit(`projectList/${activeProjectUUID}/NAME`, name, {root: true});
            }
        },
        SAVE_PHOTOS: function (context, photosFiles) {
            const pathProject = `projectList/${context.rootState.route.params.projectUUID}`;
            const pathArr = ['projectList', context.rootState.route.params.projectUUID];
            this.dispatch(pathProject + '/photoList/ADD_PHOTOS', {
                pathProject: pathArr,
                photosFiles: photosFiles
            });
        },
        DELETE_PHOTOS: function (context) {
            const projectUUID = context.rootState.route.params.projectUUID;
            if (context.state.selectedPhotos.length > 0) {
                context.state.selectedPhotos.forEach((photoID) => {
                    // this.unregisterModule(['projectList', projectUUID, 'photoList', photoID]);
                    context.dispatch('projectList/DELETE_MODULE', ['projectList', projectUUID, 'photoList', photoID], {root: true});
                });
                context.state.selectedPhotos = [];
            } else {
                const photoID = context.rootState.route.params.photoID;
                // this.unregisterModule(['projectList', projectUUID, 'photoList', photoID]);
                context.dispatch('projectList/DELETE_MODULE', ['projectList', projectUUID, 'photoList', photoID], {root: true});
            }
        },
        GET_ACTIVE_IMG: function ({state, getters, rootState, rootGetters}) {
            return fetch(rootGetters['projectConfiguration/GENERATE_URL'](getters.GET_ACTIVE_PHOTO.src))
                .then(res => res.blob())
                .then(blob => URL.createObjectURL(blob))
            // .then(imgSRC => new Image().src = imgSRC)
            // .then(img => console.log(img))
        },
        GET_ACTIVE_IMG_SIZE: function (context) {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const photoID = context.rootState.route.params.photoID;
            if (context.getters.GET_ALL_PHOTO[photoID].size)
                return context.getters.GET_ALL_PHOTO[photoID].size
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.src = context.rootGetters['projectConfiguration/GENERATE_URL'](context.getters.GET_ACTIVE_PHOTO.src);
                img.onload = () => {
                    let data = {width: img.naturalWidth, height: img.naturalHeight}
                    context.commit(`projectList/${activeProjectUUID}/photoList/${photoID}/SIZE`, data, {root: true});
                    resolve(data)
                }
            })
        },
        GET_ACTIVE_IMG_SIZE_BY_ID: function (context,photoID) {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            if (context.getters.GET_ALL_PHOTO[photoID].size)
                return context.getters.GET_ALL_PHOTO[photoID].size
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.src = context.rootGetters['projectConfiguration/GENERATE_URL'](context.getters.GET_ALL_PHOTO[photoID].src);
                img.onload = () => {
                    let data = {width: img.naturalWidth, height: img.naturalHeight}
                    context.commit(`projectList/${activeProjectUUID}/photoList/${photoID}/SIZE`, data, {root: true});
                    resolve(data)
                }
            })
        },
        SET_SCALE_PHOTO: (context, data) => {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const idActivePhoto = context.rootState.route.params.photoID;
            context.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/SCALE`, data, {root: true});
        },
    }
};
