import {getLinesIntersection} from "../fabric/libs/getPerspectivePoints"
import {getFermatPolygon} from "./getAreaCorrected";
import {Point, Line} from "@mathigon/fermat";
import {fermatCollection, getEdgesFiltered} from "./fermatCollection"

// TODO maybe it has sense to add all possible lines? For now, we add only edges (contour).
export const getVPCloud = function (planeList, width, height) {
    let pointCloud = [];
    let collectionLeft = new fermatCollection();
    let collectionRight = new fermatCollection();
    for (let p in planeList) {
        if (planeList[p].type === "wall" || planeList[p].type === "window" || planeList[p].type === "door") {
            let fermat = getFermatPolygon(planeList[p].points);
            let edgesFiltered = getEdgesFiltered(fermat);
            if (edgesFiltered) {
                fermat["horizontals"] = edgesFiltered["horizontals"];
                fermat["verticals"] = edgesFiltered["verticals"];
                let point = getLinesIntersection(edgesFiltered["horizontals"][0], edgesFiltered["horizontals"][1]);
                if (point.x < width / 2) {
                    collectionLeft.add(fermat);
                    point["weight"] = fermat.area/10000;
                    collectionLeft.potentialVPs.push(point);
                } else if (point.x > width / 2) {
                    collectionRight.add(fermat);
                    collectionRight.potentialVPs.push(point);
                    point["weight"] = fermat.area/10000;
                }
            }
        }
    }
    if (!collectionLeft.maxFermat || !collectionRight.maxFermat) {
        return undefined;
    }
    const leftVP = getMeanPoint(collectionLeft.potentialVPs);
    const rightVP = getMeanPoint(collectionRight.potentialVPs)
    pointCloud.push(leftVP);
    pointCloud.push(rightVP);
    return {"pointCloud": pointCloud,
            "leftHorizontals": getHorizontals(leftVP, collectionLeft.maxFermat["verticals"]),
            "rightHorizontals": getHorizontals(rightVP, collectionRight.maxFermat["verticals"])};
}


const getHorizontals = function (point, verticals) {
    let fermatPoint = new Point(point.x, point.y);
    let out = [];
    if (fermatPoint.distanceFromLine(verticals[0]) > fermatPoint.distanceFromLine(verticals[1])) {
        out.push(new Line(verticals[0].p1,
                 getLinesIntersection(new Line(verticals[0].p1, fermatPoint), verticals[1])));

        out.push(new Line(verticals[0].p2,
            getLinesIntersection(new Line(verticals[0].p2, fermatPoint), verticals[1])));
    }
    else {
        out.push(new Line(verticals[1].p1,
            getLinesIntersection(new Line(verticals[1].p1, fermatPoint), verticals[0])));

        out.push(new Line(verticals[1].p2,
            getLinesIntersection(new Line(verticals[1].p2, fermatPoint), verticals[0])));
    }
    return out;
}


const getMeanPoint = function (points) {
    if (points) {
        let x = 0;
        let y = 0;
        let weight = 0;
        for (let p in points) {
            // x += points[p].x;
            // y += points[p].y;
            x += points[p].x*points[p].weight;
            y += points[p].y*points[p].weight;
            weight += points[p].weight;
        }
        // return {"x": x/points.length, "y": y/points.length}
        return {"x": x/(weight), "y": y/(weight)}
    }
    return undefined;
}

// export const getMeanVPs = function (points, width) {
//     let out = [];
//     let leftPoints = [];
//     let rightPoints = [];
//     for (let p in points) {
//         if (points[p].x < width/2) {
//             leftPoints.push(points[p]);
//         }
//         else if (points[p].x > width/2) {
//             rightPoints.push(points[p]);
//         }
//     }
//     out.push(getMeanPoint(leftPoints));
//     out.push(getMeanPoint(rightPoints));
//     return out;
// }
