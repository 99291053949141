import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import {PTSans_base64} from "./fontBase64";
import {CoHeadlineB64} from "./CoHeadlineCorpB64";
import {headerBackground} from "./headerBackground";
import {MPLogo} from "./MPLogo";
import {footerLine} from "./footerLine";

import store from "../store";

let elemCanvasWithImg = null;
// let paramMaterials = null;

const createPdf = async function (paramMaterials, photos) {
    console.log(paramMaterials);
    console.log(Object.keys(photos).length);
    if (paramMaterials === null) {
        alert("Нет данных для формирования отчета!");
        return;
    }

    let finalY = 0;

    let yOffset = function (value) {
        finalY += value;
        return finalY
    };

    let doc = new jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: 'a4',
        putOnlyUsedFonts:true,
        floatPrecision: 16
    });
    // doc.addFileToVFS('PTSans.ttf', PTSans_base64);
    doc.addFileToVFS('CoHeadline.woff2', CoHeadlineB64);
    // doc.addFont('PTSans.ttf', 'PTSans', 'normal');
    doc.addFont('CoHeadline.woff2', 'CoHeadline', 'normal');
    // doc.setFont("PTSans");


    let dataText = {
        number: 1,
        cost: 0,
    };

    // doc.addImage(
    //     Logo,
    //     'PNG', 15, 13, 60, 11);
    // doc.setFontSize(10);

    // doc.text('Горячая линия (бесплатно)', 155, 15, null, null);
    // doc.setFontSize(11);
    // doc.setTextColor('#d83336');
    // doc.text('8 (800) 100-10-01', 155, 20, null, null);
    // doc.setTextColor('#828282');
    // doc.setFontSize(10);
    // doc.text('', 155, 25, null, null);

    // doc.rect(0,0, 296, 27, 'F')

    let arrPromise = [];
    let photoIndex = 1
    let pageIndex = 1
    let photosLength = photos.length
    let productsOnPage = 2;
    let productIndex = 0
    let productPages = Math.ceil((paramMaterials.length)/2)
    const productTypesDictionary = {
        'wall': "Фасад",
        'roof': "Кровля",
        'fence': "Забор",
    }

    for (let photoDataBase64 of photos) {
        if (photoDataBase64.length !== null) {

            let uselessPromise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve()
                }, 100)
            });

            await uselessPromise;

            const promise = new Promise(async function (resolve, reject) {
                let img = new Image()

                img.src = store.getters[`projectConfiguration/GENERATE_URL`](photoDataBase64);
                img.onload = () => {
                    doc.setFont("CoHeadline");
                    doc.addImage(headerBackground, 'PNG', 0, 0, 298, 26);
                    doc.addImage(MPLogo, 'PNG', 10, 4, 27, 18);
                    doc.setTextColor('#ffffff');
                    doc.setFontSize(25);
                    doc.text('Результат', 47, 11, null, null);
                    doc.setFontSize(15);
                    doc.text('Визуализация: фото №'+ photoIndex, 47, 21, null, null);
                    photoIndex++
                    doc.setFontSize(13);
                    doc.text('Отчёт получен на сайте:', 175, 15, null, null);
                    doc.text('visual.metallprofil.ru', 235, 15, null);
                    doc.setDrawColor('#ffffff');
                    doc.line(235, 16, 280, 16)
                    doc.addImage(img, 'PNG', 15, 33, 268, Math.round((img.height*268)/img.width));
                    doc.addImage(footerLine, 'PNG', 0, 198, 270, 9);
                    doc.setFontSize(15);
                    doc.setTextColor('#0075A9');
                    doc.text(pageIndex.toString(), 256, 200, null);
                    pageIndex++
                    if (pageIndex <= photosLength) {
                        doc.addPage();
                    } else if (pageIndex > photosLength) {
                        for (let j=0; j < productPages; j++) {
                            doc.addPage();
                            doc.addImage(headerBackground, 'PNG', 0, 0, 298, 26);
                            doc.addImage(MPLogo, 'PNG', 10, 4, 27, 18);
                            doc.setTextColor('#ffffff');
                            doc.setFontSize(25);
                            doc.text('Результат', 47, 11, null, null);

                            doc.setFontSize(15);
                            doc.text('Визуализация: материалы', 47, 21, null, null);

                            doc.setFontSize(13);
                            doc.text('Отчёт получен на сайте:', 175, 15, null, null);
                            doc.text('visual.metallprofil.ru', 235, 15, null);
                            doc.setDrawColor('#ffffff');
                            doc.line(235, 16, 280, 16)

                            doc.addImage(footerLine, 'PNG', 0, 198, 270, 9);
                            doc.setFontSize(15);
                            doc.setTextColor('#0075A9');
                            doc.text(pageIndex.toString(), 256, 200, null);
                            pageIndex++

                            for (let i=0; i < productsOnPage; i++) {
                                let prodImg = new Image()
                                if (!paramMaterials[productIndex])
                                    break
                                prodImg.src = paramMaterials[productIndex].src
                                if (i === 0) {
                                    doc.addImage(prodImg, prodImg.src.split('.').pop(), 20, 45, 60, 60);
                                    doc.setTextColor('#535353');
                                    doc.setFontSize(15);
                                    doc.text(paramMaterials[productIndex].name, 100, 45, null);
                                    doc.setTextColor('#73818A');
                                    doc.setFontSize(13);
                                    doc.text('Применение: '+productTypesDictionary[paramMaterials[productIndex].type], 100, 60, null);
                                } else {
                                    doc.addImage(prodImg, prodImg.src.split('.').pop(), 20, 125, 60, 60);
                                    doc.setTextColor('#535353');
                                    doc.setFontSize(15);
                                    doc.text(paramMaterials[productIndex].name, 100, 125, null);
                                    doc.setTextColor('#73818A');
                                    doc.setFontSize(13);
                                    doc.text('Применение: '+productTypesDictionary[paramMaterials[productIndex].type], 100, 140, null);
                                }
                                prodImg = null
                                productIndex++

                                }
                            }
                    }
                    img = null
                    finalY = 0;
                    resolve();
                }
            });

            arrPromise.push(promise);
            await store.dispatch('IN_PROGRESS', promise);

        }
    }



    // let times = 2;
    // let productIndex = 0
    // for(let i=0; i < times; i++){
    //     for (let material in paramMaterials) {
    //         if (productIndex < 2) {
    //             doc.text(paramMaterials[material].name, 10, 50, null);
    //             productIndex++
    //         } else {
    //             productIndex = 0
    //             return
    //         }
    //
    //     }
    // }

    // for (let material in paramMaterials) {
    //
    //
    //     doc.addImage(img, 'PNG', 15, 33, 268, Math.round((img.height*268)/img.width));
    //     doc.addImage(footerLine, 'PNG', 0, 198, 270, 9);
    //     doc.setFontSize(15);
    //     doc.setTextColor('#0075A9');
    //     doc.text(pageIndex.toString(), 256, 200, null);
    //     pageIndex++
    //     doc.addPage();
    //
    // }

    await Promise.all(arrPromise);


    // let arrPromise = [];
    // for (let photoDataBase64 of photos) {
    //     if (photoDataBase64.length !== null) {
    //
    //         if (finalY === 0)
    //             doc.addPage();
    //
    //         let uselessPromise = new Promise((resolve) => {
    //             setTimeout(() => {
    //                 resolve()
    //             }, 100)
    //         });
    //         await uselessPromise;
    //         const promise = new Promise(async function (resolve, reject) {
    //             let img = new Image()
    //
    //             img.src = store.getters[`projectConfiguration/GENERATE_URL`](photoDataBase64);
    //             img.onload = () => {
    //                 doc.addImage(img, 'PNG', 15, yOffset(10), 180, Math.round((img.height*180)/img.width));
    //                 img = null
    //                 finalY = 0;
    //                 resolve();
    //             }
    //         });
    //         arrPromise.push(promise);
    //         await store.dispatch('IN_PROGRESS', promise);
    //     }
    // }
    // await Promise.all(arrPromise);

    ///////////////////////////////////////
    return doc;
};

// export const getResultPDF_Blob = (materials) => {
//     return createPdf(materials).output('blob');
// }

export const loadResultPDF = function (materials, photos) {
    return new Promise(function (resolve, reject) {
        createPdf(materials, photos).then(doc => {
            doc.save('Result.pdf');
            resolve();
        }).catch((err) => {
            reject(err)
        });
    });
};
