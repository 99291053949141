<template>
    <div class="modal-data-container modal-tags-grid">
        <vue-custom-scrollbar class="admin-scrollable-tags right-border">
            <table class="admin-logs-table">
                <tbody>
                <tr v-for="(category, index) in categoriesWithTags" v-bind:class="{'line': index % 2 === 0}">
                    <td style="padding-left: 30px">
                        <div>
                            <div class="table-data-spacing">{{category.tag_category_name}}</div>
                        </div>
                    </td>
                    <td class="modal-table-edit-btn" v-on:click="initEditTagCategory(category.id)">
                        <SVGEdit/>
                    </td>
                </tr>
                <tr>
                    <td style="padding-left: 30px">
                        <div>
                            <div class="table-data-spacing add-tag-category" v-on:click="initAddTagCategory()">
                                <span add-tag-category>Добавить</span>
                                <SVGPlus class="pill-tag-svg"/>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </vue-custom-scrollbar>
        <vue-custom-scrollbar class="admin-scrollable-tags">

            <div class="tag-input-grid-sized" v-show="selectedTagCategoryStatus">
                <input class="tag-input-sized" type="text" placeholder="введите категорию" v-model="newNameOfCategory">
                <div class="blue-button modal-accept-button tags-modal-button" @click="saveTag">Сохранить</div>
                <div class="blue-outline-button modal-accept-button tags-modal-button" v-on:click="cancelTag">Отмена</div>
            </div>

            <div v-show="!selectedTagCategoryStatus">
                <div class="modal-data-container modal-tags-text">
                    Редактируйте категорию
                    <SVGEdit style="width: 12px; margin: 0 5px"/>
                </div>
                <div class="modal-data-container modal-tags-text">
                    Или&#160;<span class="bold-text">добавьте</span>&#160;новую
                </div>
            </div>
        </vue-custom-scrollbar>
    </div>
</template>

<script>
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import SVGEdit from "../../SVGMP/SVGEdit";
    import SVGPlus from "../../SVGMP/SVGPlus";
    import {http_request} from "../../../httpConfig";

    export default {
        name: "ModalTagsCategories",
        components: {
            SVGPlus,
            SVGEdit,
            vueCustomScrollbar,
        },
        data() {
            return {
                selectedTagCategoryStatus: false,
                categoriesWithTags: [],
                newNameOfCategory: '',
                categoryIdSelectedForChange: null
            }
        },
        methods: {
            initAddTagCategory() {
                this.newNameOfCategory = '';
                this.categoryIdSelectedForChange = null;
                this.selectedTagCategoryStatus = true;
            },
            initEditTagCategory(categoryId) {
                const categorySelectedForChange = this.categoriesWithTags.find(category => category.id == categoryId);
                if (!categorySelectedForChange) {
                    alert('Ошибка.');
                    return;
                }
                this.newNameOfCategory = categorySelectedForChange.tag_category_name;
                this.categoryIdSelectedForChange = categoryId;
                this.selectedTagCategoryStatus = true;
            },
            saveTag() {
                let dataForSend = {
                    tag_category_name: this.newNameOfCategory,
                };
                if (this.categoryIdSelectedForChange) {
                    dataForSend['id'] = this.categoryIdSelectedForChange;
                }
                this.selectedTagCategoryStatus = false;
                console.log('Сохранить категорию', dataForSend);

                http_request.post('/save_tag_category', dataForSend)
                    .then((request) => {
                        console.log(request.data);
                        this.getCategoriesWithTags();
                    })
                    .catch((err) => {
                        alert('Ошибка. Тег не сохранен.')
                        console.log(err);
                    })
            },
            cancelTag() {
                this.newNameOfCategory = '';
                this.categoryIdSelectedForChange = null;
                this.selectedTagCategoryStatus = false;
            },
            getCategoriesWithTags() {
                this.categoriesWithTags.length = 0;
                http_request.post('/get_categories_with_tags', {})
                    .then((request) => {
                        console.log(request.data);
                        this.categoriesWithTags.push(...request.data.tag_categories);
                    })
                    .catch((err) => {
                        alert('Ошибка при загрузке списка тегов.');
                        console.log(err);
                    })
            }
        },
        mounted() {
            this.getCategoriesWithTags();
        }
    }
</script>

<style scoped>
    table, th, tr, td {
        border: none;
    }

    .admin-logs-table {
        border: none;
    }
</style>
