var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-data-container modal-tags-grid" },
    [
      _c(
        "vue-custom-scrollbar",
        { staticClass: "admin-scrollable-tags right-border" },
        [
          _c("table", { staticClass: "admin-logs-table" }, [
            _c(
              "tbody",
              [
                _vm._l(_vm.categoriesWithTags, function(category, index) {
                  return _c("tr", { class: { line: index % 2 === 0 } }, [
                    _c("td", { staticStyle: { "padding-left": "30px" } }, [
                      _c("div", [
                        _c("div", { staticClass: "table-data-spacing" }, [
                          _vm._v(_vm._s(category.tag_category_name))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "modal-table-edit-btn",
                        on: {
                          click: function($event) {
                            return _vm.initEditTagCategory(category.id)
                          }
                        }
                      },
                      [_c("SVGEdit")],
                      1
                    )
                  ])
                }),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticStyle: { "padding-left": "30px" } }, [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "table-data-spacing add-tag-category",
                          on: {
                            click: function($event) {
                              return _vm.initAddTagCategory()
                            }
                          }
                        },
                        [
                          _c("span", { attrs: { "add-tag-category": "" } }, [
                            _vm._v("Добавить")
                          ]),
                          _vm._v(" "),
                          _c("SVGPlus", { staticClass: "pill-tag-svg" })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("vue-custom-scrollbar", { staticClass: "admin-scrollable-tags" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedTagCategoryStatus,
                expression: "selectedTagCategoryStatus"
              }
            ],
            staticClass: "tag-input-grid-sized"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newNameOfCategory,
                  expression: "newNameOfCategory"
                }
              ],
              staticClass: "tag-input-sized",
              attrs: { type: "text", placeholder: "введите категорию" },
              domProps: { value: _vm.newNameOfCategory },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newNameOfCategory = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "blue-button modal-accept-button tags-modal-button",
                on: { click: _vm.saveTag }
              },
              [_vm._v("Сохранить")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "blue-outline-button modal-accept-button tags-modal-button",
                on: { click: _vm.cancelTag }
              },
              [_vm._v("Отмена")]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.selectedTagCategoryStatus,
                expression: "!selectedTagCategoryStatus"
              }
            ]
          },
          [
            _c(
              "div",
              { staticClass: "modal-data-container modal-tags-text" },
              [
                _vm._v(
                  "\n                Редактируйте категорию\n                "
                ),
                _c("SVGEdit", {
                  staticStyle: { width: "12px", margin: "0 5px" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-data-container modal-tags-text" }, [
              _vm._v("\n                Или "),
              _c("span", { staticClass: "bold-text" }, [_vm._v("добавьте")]),
              _vm._v(" новую\n            ")
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }