var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "authorization-page" } }, [
    _c("div", { attrs: { id: "authorisation-container" } }, [
      _c("div", { attrs: { id: "gl-logo-container" } }, [
        _c("img", {
          attrs: {
            src: _vm.urlForStatic("/img/logo-metall-profil.png"),
            id: "authorisation-logo-svg"
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "authorization-label" } }, [
        _vm._v("Вход в аккаунт")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "login-input-container" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputNickname,
              expression: "inputNickname"
            }
          ],
          staticClass: "login-text-input",
          attrs: {
            id: "nickname",
            type: "text",
            placeholder: "Почта или логин"
          },
          domProps: { value: _vm.inputNickname },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.inputNickname = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "login-input-container" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputPassword,
              expression: "inputPassword"
            }
          ],
          staticClass: "login-text-input",
          attrs: { id: "password", type: "password", placeholder: "Пароль" },
          domProps: { value: _vm.inputPassword },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.inputPassword = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.authorisationFailed,
              expression: "authorisationFailed"
            }
          ],
          staticClass: "form-alert-danger"
        },
        [
          _vm._v(
            "\n                Неверный email пользователя или пароль!\n            "
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "login-input-checkbox-container" }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "btn-login-submit" }, on: { click: _vm.logIn } },
        [_vm._v("\n                Войти в аккаунт\n            ")]
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "authorisation-app-tip" } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }