<template>
    <div class="sidebar-building-types">
        <div class="building-type-button" :class="{green: selectedTagPermanentId == 4}" v-on:click="selectTagPermanentId(4)">
            <SVGPrivateHouse class="building-type-button-svg"/>
            <div class="building-type-button-text">
                Частный дом
            </div>
        </div>
<!--        <div class="building-type-button mt-1" :class="{green: selectedTagPermanentId == 5}" v-on:click="selectTagPermanentId(5)">-->
<!--            <SVGPublicBuilding class="building-type-button-svg"/>-->
<!--            <div class="building-type-button-text">-->
<!--                Общественное здание-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="building-type-button mt-1" :class="{green: selectedTagPermanentId == 6}" v-on:click="selectTagPermanentId(6)">-->
<!--            <SVGIndustrialBuilding class="building-type-button-svg"/>-->
<!--            <div class="building-type-button-text">-->
<!--                Промышленно-хозяйственный объект-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
    import SVGPrivateHouse from "../../../../SVGMP/SVGPrivateHouse";
    import SVGPublicBuilding from "../../../../SVGMP/SVGPublicBuilding";
    import SVGIndustrialBuilding from "../../../../SVGMP/SVGIndustrialBuilding";

    export default {
        name: "SidebarProjectsBuildingTypes",
        components: {
            SVGIndustrialBuilding,
            SVGPublicBuilding,
            SVGPrivateHouse
        },
        props: {
            selectedTagPermanentId: {
                type: Number
            }
        },
        methods: {
            selectTagPermanentId(tagPermanentId) {
                this.$emit('selectTagPermanentId', tagPermanentId);
            }
        }
    }
</script>

<style scoped>
    .building-type-button {
        height: 110px;
        width: 118px;
        display: grid;
        grid-template-columns: auto;
        border: 1px solid #383838;
        color: white;
        cursor: pointer;
        user-select: none;
    }

    .building-type-button:hover {
        background-color: #343a3d;
        color: #baff00;
    }

    .building-type-button:active {
        background-color: #B4FF00 !important;
        color: #1C1F21 !important;
    }

    .mt-1 {
        margin-top: -1px;
    }

    .building-type-button-text {
        font-size: 12px;
        text-align: center;
        margin: auto;
    }

    .building-type-button-content {
        display: grid;
        grid-template-columns: auto;
    }

    .building-type-button-svg {
        margin: auto;
    }

    .green {
        background-color: #B4FF00 !important;
        color: #1C1F21 !important;
        cursor: default;
    }
</style>
