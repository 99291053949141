export const config = {
    urlForStatic: "./metall-profil/",
    urlForRequest: "https://visual.metallprofil.ru/api/",
    requestFromNginx: true,
    urlForFile: "https://visual.metallprofil.ru/api/get_file/",
    urlForFileNginx: "https://visual.metallprofil.ru/files/",
    backgroundPlanesOpacity: 0.5,
    renderer: "threejs", // opencv or threejs
    applicationAreas: [{
            nameAreaOnServer: 'roof',
            nameInRussian: 'Крыша'
        },
        {
            nameAreaOnServer: 'wall',
            nameInRussian: 'Фасад'
        },
        {
            nameAreaOnServer: 'gutter',
            nameInRussian: 'Водосток'
        },
        {
            nameAreaOnServer: 'fence',
            nameInRussian: 'Забор'
        },],
    textureParameters: {
        1: {
            name: 'Металлик (Purman)',
            reflectivity: 0.2,
            specular: 0xffffff,
            shininess: 30.0,
        },
        2: {
            name: 'Матовые (VikingMP)',
            reflectivity: 0.3,
            specular: 0x111111,
            shininess: 80
        },
        3: {
            name: 'Оцинковка',
            reflectivity: 0.2,
            specular: 0xffffff,
            shininess: 100,
        },
        4: {
            name: 'Осветление',
            lightIntensity: 8
        }
    }
};
