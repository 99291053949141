// import {getArea, getScaleTranslatedPoints} from "../../math/getAreaCorrected";

export const costLite = {
    namespaced: true,
    state: {
        productsCost: []
    },
    mutations: {
        PRODUCTS_COST: (state, dataArray) => {
            state.productsCost.length = 0;
            state.productsCost = state.productsCost.concat(dataArray);
        },
        DELETE_PRODUCT_FROM_COST: (state, productUUID) => {
            const indexProduct = state.productsCost.findIndex(elem => elem.productUUID === productUUID);
           state.productsCost.splice(indexProduct,1);
        }
    },
    getters: {
        PRODUCTS_COST: state => state.productsCost
    },
    actions: {
        GET_COST: async function (context, inputData) {
            console.log("start")
            const calculateProductsInPlane = async (photoID, planes, scalePhoto) => {
                let productsInPlane = [];
                for (let idPlane in planes) {
                    if (!(planes[idPlane].type === "wall" ||
                        planes[idPlane].type === "roof" ||
                        planes[idPlane].type === "fence" ||
                        planes[idPlane].type === "additional")) {
                        continue;
                    }

                    let productCost = {};
                    const idDesignGroup = planes[idPlane].idDesignGroup;
                    const type = planes[idPlane].type;
                    // let translatedScale = await getScaleTranslatedPoints(scalePhoto, planes, photoID);
                    // let area = await getArea(photoID, idPlane, translatedScale);
                    // if (!area || isNaN(area) || area < 0) {
                    //     area = 0;
                    // }

                    if (!idDesignGroup) {
                        console.warn(`У плоскости с id = ${idPlane} на фотографии с id = ${photoID} не задана группа`);
                        continue;
                    }

                    if (!context.rootGetters[`projectList/${activeProjectUUID}/designList/DESIGN_BY_ID`](activeIdDesign)) {
                        console.warn(`Дизайна ${activeIdDesign} нет`);
                        continue;
                    }
                    const productUUID = context.rootGetters[`projectList/${activeProjectUUID}/designList/${activeIdDesign}/designGroupList/${idDesignGroup}/DESIGN_SETTINGS_BY_PLANE_TYPE`](type).uuidProduct;
                    if (!productUUID) {
                        console.warn(`Дизайн ${idDesignGroup} с типом ${type} не имеет продукта`);
                        continue;
                    }
                    const product = await context.dispatch('UI/products/PRODUCT_BY_UUID', productUUID, {root: true});
                    if (!product) {
                        console.warn(`Продукт ${productUUID} не найден`);
                        continue;
                    }

                    const price = (() => {
                        if (product.price && !isNaN(parseFloat(product.price))) {
                            return parseFloat(product.price);
                        } else {
                            return 0;
                        }
                    })();


                    // area = Math.ceil(area);
                    productCost['productUUID'] = productUUID;
                    productCost['name'] = product.name;
                    productCost['src'] = context.rootGetters[`projectConfiguration/GENERATE_URL`](product.preview);
                    productCost['type'] = type;
                    // productCost['area'] = area;
                    productCost['price'] = price;
                    // productCost['cost'] = area * price;
                    productsInPlane.push(productCost);
                }
                return productsInPlane;
            }

            const filterIdenticalProducts = (unfilteredProducts) => {
                let filteredProducts = [];
                for (let product of unfilteredProducts) {
                    const indexInFilteredProducts = filteredProducts.findIndex(elem => elem.productUUID === product.productUUID);
                    if(indexInFilteredProducts >= 0){
                        // filteredProducts[indexInFilteredProducts].area += product.area;
                        // filteredProducts[indexInFilteredProducts].cost += product.cost;
                    } else {
                        filteredProducts.push(product);
                    }
                }
                return filteredProducts;
            }

            let productCostList = [];
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const activeIdDesign = context.rootState.route.params.designID;

            if (!activeProjectUUID || !activeIdDesign) {
                alert('Нет данных для расчета!')
                return;
            }

            const project = context.rootGetters['projectList/PROJECT_BY_UUID'](activeProjectUUID);
            if (!project) {
                alert('Проект не найден!')
                return;
            }

            const idPhotos = Object.keys(context.rootGetters[`projectList/${activeProjectUUID}/photoList/PHOTOS`]);

            for (let idPhoto of idPhotos) {
                const planes = context.rootGetters[`projectList/${activeProjectUUID}/photoList/${idPhoto}/planeList/PLANE_LIST`];
                const scalePhoto =  context.rootGetters[`projectList/${activeProjectUUID}/photoList/${idPhoto}/SCALE`];
                if (Object.keys(planes).length === 0) {
                    console.log(`У фотографии ${idPhoto} нет плоскостей!`);
                    continue;
                }

                const productCostListPlane = await calculateProductsInPlane(idPhoto, planes, scalePhoto);
                productCostList = productCostList.concat(productCostListPlane);
            }
            context.commit('PRODUCTS_COST', filterIdenticalProducts(productCostList));
            return context.getters.PRODUCTS_COST;
        }
    }
};
