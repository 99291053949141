<template>
    <svg width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 30.6944L6.5443 23.6923H36.0633L44 30.6944V41.5824H0V30.6944Z" fill="currentColor"/>
        <rect x="1.93408" y="32.3956" width="40.1319" height="8.21978" fill="white"/>
        <rect x="3.38452" y="34.8132" width="2.41758" height="1.45055" fill="currentColor"/>
        <path d="M0 30.6944L6.5443 23.6923H36.0633L44 30.6944V41.5824H0V30.6944Z" fill="currentColor"/>
        <rect x="1.93408" y="32.3956" width="40.1319" height="8.21978" fill="white"/>
        <path d="M0 18.9008L6.5443 12.0879H36.0633L44 18.9008V29.4945H0V18.9008Z" fill="currentColor"/>
        <path d="M0 18.9008L6.5443 12.0879H36.0633L44 18.9008V29.4945H0V18.9008Z" fill="currentColor"/>
        <rect x="1.93408" y="20.3077" width="40.1319" height="8.21978" fill="white"/>
        <path d="M0 6.8129L6.5443 0H36.0633L44 6.8129V17.4066H0V6.8129Z" fill="currentColor"/>
        <path d="M0 6.8129L6.5443 0H36.0633L44 6.8129V17.4066H0V6.8129Z" fill="currentColor"/>
        <rect x="1.93408" y="8.21973" width="40.1319" height="8.21978" fill="white"/>
        <rect x="4" y="11" width="5" height="2" fill="currentColor"/>
        <rect x="4" y="23" width="5" height="2" fill="currentColor"/>
        <rect x="4" y="35" width="5" height="2" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGHDD"
    }
</script>

<style scoped>

</style>
