export const markerPointer = () => {
    return new fabric.Circle({
        radius: 2,
        originX: 'center',
        originY: 'center',
        left: 0,
        top: 0,
        strokeWidth: 1,
        evented: false,
        // stroke: 'rgba(125,0,0,0)',
        // fill: 'rgba(0,0,0,0)',
    });
}
