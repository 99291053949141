import store from "../store";

const checkProductsAvailability = (projectUUID) => {
    const productsUsed = store.getters[`projectList/${projectUUID}/designList/PRODUCTS_USED`];
    return store.getters['UI/products/CHECK_PRODUCTS'](productsUsed);
};

const checkProjectAvailability = (projectUUID) => {
    if (store.getters['projectList/DATA_PROJECT_BY_UUID'](projectUUID))
        return true;
    const project = store.getters["UI/loadedProjectList/PROJECT_BY_UUID"](projectUUID);
    if (project) {
        store.dispatch('projectList/createDynamicModules', {
            rootPath: ['projectList'],
            projects: [project]
        })
        return true;
    }

    return false;
}

export default ((to, from, next) => {
    if (to.params.projectUUID
        && (!checkProjectAvailability(to.params.projectUUID)
            || (to.matched.some(record => record.meta.checkProducts)
                && !checkProductsAvailability(to.params.projectUUID))
        )) {
        next({
            name: 'loading',
            query: {
                ...to.params,
                redirect: to.fullPath
            },
            replace: (from.fullPath === '/')
        });
    } else {
        next();
    }
});
