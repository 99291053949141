import store from '../store';

export const urlForStatic = function(path) {
    return store.getters["projectConfiguration/URL_FOR_STATIC"] + path;
}

export const urlForStaticVue = {
    methods: {
        urlForStatic(path) {
            return this.$store.getters["projectConfiguration/URL_FOR_STATIC"] + path;
        }
    }
}

export const urlForFileStorage = {
    methods: {
        urlForFileStorage(url) {
            return this.$store.getters[`projectConfiguration/GENERATE_URL`](url);
        }
    }
}

export const modalMethods = {
    methods: {
        hideModal(name) {
            this.$modal.hide(name);
        },
        showModal(name, args) {
            this.$modal.show(name, args);
        }
    }
}
