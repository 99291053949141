export const activePlaneGroup = {
    namespaced: false,
    state: {},
    getters: {
        PLANE_GROUP_LIST: (state, getters, rootState, rootGetters) => {
            const uuidActiveProject = rootState.route.params.projectUUID;
            if(uuidActiveProject)
                return rootGetters[`projectList/${uuidActiveProject}/planeGroupList/SELF`];
            else
                return null;
        },
        PLANE_GROUP: (state, getters, rootState, rootGetters) => {
            const uuidActiveProject = rootState.route.params.projectUUID;
            const uuidActivePlaneGroup = rootState.route.params.groupID;
            if(uuidActiveProject && uuidActivePlaneGroup)
                return rootGetters[`projectList/${uuidActiveProject}/planeGroupList/${uuidActivePlaneGroup}/SELF`];
            else
                return null;
        }
    },
    mutations: {},
    actions: {
        CREATE_PLANE_GROUP: async function (context, dataPlaneGroup) {
            const uuidActiveProject = context.rootState.route.params.projectUUID;
            const idPlaneGroup = await context.dispatch(`projectList/${uuidActiveProject}/CREATE_GROUP`, {
                data: dataPlaneGroup,
            }, {root: true})
            return idPlaneGroup;
        },
        UPDATE_VALUE_PARAMS: async function (context, newParams) {
            const uuidActiveProject = context.rootState.route.params.projectUUID;
            const planeGroupID = context.rootState.route.params.groupID;
            context.commit(`projectList/${uuidActiveProject}/planeGroupList/${planeGroupID}/VALUE_PARAMS`, newParams, {root: true})
        },
        DELETE_PLANE_GROUP: async function (context) {
            const uuidActiveProject = context.rootState.route.params.projectUUID;
            const planeGroupID = context.rootState.route.params.groupID;
            await context.dispatch(`projectList/${uuidActiveProject}/DELETE_GROUP`, planeGroupID, {root: true});
        }
    }
}
