import Vue from "vue";
import VueRouter from "vue-router";
import AuthorisationPage from "../UI/AuthorisationPage.vue";
// import proVersion from "./proVersion";
import liteVersion from "./liteVersion";
import adminPanel from "./adminPanel";
import TheLoadingPage from "../UI/LiteVersionMP/TheLoadingPage";

Vue.use(VueRouter);

const routes = [
    // {path: '*', redirect: '/authorisation'},
    {
        name: 'login', path: '/authorisation', component: AuthorisationPage, meta: {
            offCheckApplicationVersion: true,
            offCheckFirstCrossing: true
        }
    },
    {
        name: 'loading', path: '/loading', component: TheLoadingPage, meta: {
            offCheckApplicationVersion: true,
            offCheckFirstCrossing: true
        }
    },
    // proVersion,
    liteVersion,
    adminPanel,

    {path: '*', redirect: {name: 'liteProjects'}},
];

export default new VueRouter({
    routes // сокращённая запись для `routes: routes`
});

