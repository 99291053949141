<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Поделиться
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-tip-grid">
                <div class="modal-tip-icon">
                    <SVGShare style="width: 48px; height: 45px"/>
                </div>
                <div class="modal-tip-text">
                    Поделитесь вашим проектом в социальных сетях или отправьте его на E-mail.
                </div>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-share-buttons-container">
                <div class="modal-share-buttons-grid">
                    <ShareNetwork
                        class="lover-bar-button std-gray-border"
                        network="Email"
                        :url="getDesignURL()"
                        :title="getTitle()"
                        hashtags="металл профиль, фасад, кровля"
                        :media="urlForFileStorage(src)">
                        <SVGMailTo/>
                    </ShareNetwork>
<!--                    <ShareNetwork-->
<!--                        class="lover-bar-button std-gray-border"-->
<!--                        network="Facebook"-->
<!--                        :url="getDesignURL()"-->
<!--                        :title="getTitle()"-->
<!--                        hashtags="металл профиль, фасад, кровля"-->
<!--                        :media="urlForFileStorage(src)">-->
<!--                        <SVGFacebook/>-->
<!--                    </ShareNetwork>-->
                    <ShareNetwork
                        class="lover-bar-button std-gray-border"
                        network="WhatsApp"
                        :url="getDesignURL()"
                        :title="getTitle()"
                        hashtags="металл профиль, фасад, кровля"
                        :media="urlForFileStorage(src)">
                        <SVGWhatsapp/>
                    </ShareNetwork>
                    <ShareNetwork
                        class="lover-bar-button std-gray-border"
                        network="Telegram"
                        :url="getDesignURL()"
                        :title="getTitle()"
                        hashtags="металл профиль, фасад, кровля"
                        :media="urlForFileStorage(src)">
                        <SVGTelegram/>
                    </ShareNetwork>
<!--                    <ShareNetwork-->
<!--                            class="lover-bar-button std-gray-border"-->
<!--                            network="Twitter"-->
<!--                            :url="getDesignURL()"-->
<!--                            :title="getTitle()"-->
<!--                            hashtags="металл профиль, фасад, кровля"-->
<!--                            :media="urlForFileStorage(src)">-->
<!--                        <SVGTwitter/>-->
<!--                    </ShareNetwork>-->
                    <ShareNetwork
                        class="lover-bar-button std-gray-border"
                        network="VK"
                        :url="getDesignURL()"
                        :title="getTitle()"
                        hashtags="металл профиль, фасад, кровля"
                        :media="getRenderSRC()">
                        <SVGVK/>
                    </ShareNetwork>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import SVGShare from "../../SVGMP/SVGShare";
    import SVGInstagram from "../../SVGMP/SVGInstagram";
    import SVGTwitter from "../../SVGMP/SVGTwitter";
    import SVGFacebook from "../../SVGMP/SVGFacebook";
    import SVGVK from "../../SVGMP/SVGVK";
    import SVGMailTo from "../../SVGMP/SVGMailTo";
    import {urlForFileStorage} from "../../common";
    import store from "../../../store";
    import SVGWhatsapp from "../../SVGMP/SVGWhatsapp";
    import SVGTelegram from "../../SVGMP/SVGTelegram";
    import {config} from "../../../config";


    export default {
        name: "ModalShare",
        components: {
            SVGTelegram,
            SVGWhatsapp, SVGMailTo, SVGVK, SVGFacebook, SVGTwitter, SVGInstagram, SVGShare, SVGClose},
        mixins: [urlForFileStorage],
        props: {
            src: {
                type: String
            }
        },
        mounted() {

        },
        methods: {
            getRenderSRC() {
                return this.urlForFileStorage(this.src)
            },
            getDesignURL() {
                return config.urlForRequest + 'shared_page?url=' + encodeURIComponent(window.location.href) + '&src=' + encodeURIComponent(this.getRenderSRC());
            },
            getTitle() {
                return "Дизайн дома"
            }
        }
    }
</script>

<style scoped>

</style>
