<template>
    <div class="page-container">
        <WorkspaceLoader v-show="isLoading"/>
        <TheModalLite/>
        <TheLiteNavbar/>
<!--        <FullBodyLoader v-if="isLoading"/>-->
        <div class="application-body">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import TheLiteNavbar from "./Components/TheLiteNavbar";
    import TheModalLite from "../ModalMP/ModalLite/TheModalLite";
    import {urlForStaticVue} from "../common";
    import FullBodyLoader from "../ComponentsMP/Loaders/FullBodyLoader";
    import WorkspaceLoader from "../ComponentsMP/Loaders/WorkspaceLoader";

    export default {
        name: "TheLiteVersion",
        mixins: [urlForStaticVue],
        components: {
            WorkspaceLoader,
            FullBodyLoader,
            TheModalLite,
            TheLiteNavbar,
        },
        computed: {
            isLoading() {
                return !!this.$store.getters['IN_PROGRESS'];
                // return !!this.$store.getters['PROGRESS_PERCENT'];
            }
        },
    }
</script>

<style scoped>

</style>
