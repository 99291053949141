var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-sidebar-menu" }, [
    _c(
      "div",
      {
        staticClass: "admin-sidebar-menu-element",
        class: { "active-menu": _vm.activeAdminPage === "products" },
        on: {
          click: function($event) {
            return _vm.switchAdminPage("adminPageProducts")
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "admin-sidebar-menu-element-svg" },
          [_c("SVGShop", { staticStyle: { width: "16px", height: "19px" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "admin-sidebar-menu-element-text" }, [
          _vm._v("\n            Продукция\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "admin-sidebar-menu-element",
        class: { "active-menu": _vm.activeAdminPage === "categories" },
        on: {
          click: function($event) {
            return _vm.switchAdminPage("adminPageCategories")
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "admin-sidebar-menu-element-svg" },
          [_c("SVGCategories")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "admin-sidebar-menu-element-text" }, [
          _vm._v("\n            Классификация\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "admin-sidebar-menu-element",
        class: { "active-menu": _vm.activeAdminPage === "models" },
        on: {
          click: function($event) {
            return _vm.switchAdminPage("adminPageModels")
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "admin-sidebar-menu-element-svg" },
          [_c("SVG3D", { staticStyle: { width: "16px", height: "19px" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "admin-sidebar-menu-element-text" }, [
          _vm._v("\n            3D модели\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "admin-sidebar-menu-element",
        class: { "active-menu": _vm.activeAdminPage === "defuseMap" },
        on: {
          click: function($event) {
            return _vm.switchAdminPage("adminPageDefuseMap")
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "admin-sidebar-menu-element-svg" },
          [
            _c("SVGTexture", { staticStyle: { width: "16px", height: "19px" } })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "admin-sidebar-menu-element-text" }, [
          _vm._v("\n            Текстуры\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "admin-sidebar-menu-element",
        class: { "active-menu": _vm.activeAdminPage === "normalMap" },
        on: {
          click: function($event) {
            return _vm.switchAdminPage("adminPageNormalMap")
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "admin-sidebar-menu-element-svg" },
          [
            _c("SVGNormalMap", {
              staticStyle: { width: "16px", height: "19px" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "admin-sidebar-menu-element-text" }, [
          _vm._v("\n            Карты нормалей\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "admin-sidebar-menu-element",
        class: { "active-menu": _vm.activeAdminPage === "heightMap" },
        on: {
          click: function($event) {
            return _vm.switchAdminPage("adminPageHeightMap")
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "admin-sidebar-menu-element-svg" },
          [
            _c("SVGHeightMap", {
              staticStyle: { width: "16px", height: "19px" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "admin-sidebar-menu-element-text" }, [
          _vm._v("\n            Карты высот\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "admin-sidebar-menu-element",
        class: { "active-menu": _vm.activeAdminPage === "users" },
        on: {
          click: function($event) {
            return _vm.switchAdminPage("adminPageUsers")
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "admin-sidebar-menu-element-svg" },
          [_c("SVGPeople")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "admin-sidebar-menu-element-text" }, [
          _vm._v("\n            Пользователи\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "admin-sidebar-menu-element",
        class: { "active-menu": _vm.activeAdminPage === "projects" },
        on: {
          click: function($event) {
            return _vm.switchAdminPage("adminPageProjects")
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "admin-sidebar-menu-element-svg" },
          [_c("SVGCompass")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "admin-sidebar-menu-element-text" }, [
          _vm._v("\n            Проекты\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "admin-sidebar-menu-element",
        class: { "active-menu": _vm.activeAdminPage === "administrating" },
        on: {
          click: function($event) {
            return _vm.switchAdminPage("adminPageAdministrating")
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "admin-sidebar-menu-element-svg" },
          [_c("SVGAdministrating")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "admin-sidebar-menu-element-text" }, [
          _vm._v("\n            Администрирование\n        ")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }