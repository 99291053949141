import {users} from "./users";
import {organizations} from "./organizations";

export const selectUser = {
    namespaced: true,
    state: () => ({
        selectedRegionID: null,
        selectedCityID: null,
        selectedUserID: null,
        selectedOrganizationID: null,
    }),
    modules:{
        users,
        organizations,
    },
    getters:{
        ORGANIZATIONS_T: (state, getters, rootState, rootGetters) => {
            const organizations = getters['organizations/SELF'];
            const selectCityID = state.selectedCityID;
            if (selectCityID) {
                return organizations.filter(organization => organization.city_id == selectCityID);
            } else {
                return organizations;
            }
        },
        SELECTED_USER_ID: (state) => {
            return state.selectedUserID;
        }
    },
    mutations: {
        SELECTED_USER_ID: (state, value) => {
            state.selectedUserID = value;
        },
        SELECTED_CITY_ID: (state, value) => {
            state.selectedCityID = value;
        },
        SELECTED_REGION_ID: (state, value) => {
            state.selectedRegionID = value;
        },
        SELECTED_ORGANIZATION_ID: (state, value) => {
            state.selectedOrganizationID = value;
        },
        CLEAR_ALL: (state, value) => {
            state.selectedRegionID = null;
            state.selectedCityID = null;
            state.selectedUserID = null;
            state.selectedOrganizationID = null;
        }
    },
    actions: {
        CLEAR_ALL: function (context, inputData) {
            context.commit('users/CLEAR_USERS');
            context.commit('organizations/CLEAR_ORGANIZATIONS');
            context.commit('CLEAR_ALL');
        },
        GET_USERS_BY_SELECT_CATEGORIES: function (context, inputData) {
            let dataForRequest = {};
            if (context.state.selectedRegionID || context.state.selectedCityID || context.state.selectedOrganizationID) {
                dataForRequest.region_id = context.state.selectedRegionID;
                dataForRequest.city_id = context.state.selectedCityID;
                dataForRequest.organization_id = context.state.selectedOrganizationID;
            }

            context.dispatch('users/GET_USERS', dataForRequest);
        },
    }
}
