import {stepsChain} from "./stepsChain";
import {productCategories} from "../productCategories";
import {products} from "../products";
import {requestCustomDesign} from "./requestCustomDesign";
import {planeGroupSettings} from "./planeGroupSettings";
import {loadedProjectList} from "./loadedProjectList";

export default {
    namespaced: true,
    state: {
        distanceBetweenPointsForScale: null
    },
    modules: {
        loadedProjectList,
        stepsChain,
        productCategories,
        products,
        requestCustomDesign,
        planeGroupSettings
    },
    mutations: {
        DISTANCE_BETWEEN_POINTS_FOR_SCALE: function (state, value) {
            state.distanceBetweenPointsForScale = value;
        }
    },
    getters: {},
    actions: {}
};
