import {config} from "../../config";

export const projectConfiguration = {
    namespaced: true,
    state: config,

    getters: {
        URL_FOR_STATIC: state => {
            return state.urlForStatic;
        },
        URL_FOR_REQUEST: state => {
            return state.urlForRequest;
        },
        REQUEST_FROM_NGINX: state => {
            return state.requestFromNginx;
        },
        URL_FOR_FILE: state => {
            return state.urlForFile;
        },
        URL_FOR_FILE_NGINX: state => {
            return state.urlForFileNginx;
        },
        GENERATE_URL: state => urlPart => {
            if (!urlPart)
                return state.urlForStatic + '/img/No_Picture.jpg';

            if (urlPart.startsWith("data"))
                return urlPart;

            if(state.requestFromNginx) {
                let catalog_1 = urlPart.slice(0, 2);
                let catalog_2 = urlPart.slice(2, 4);
                let catalog_3 = urlPart.slice(4, 6);
                let file_name = urlPart.slice(6, urlPart.length);
                return state.urlForFileNginx + catalog_1 + '/' + catalog_2 + '/' + catalog_3 + '/' + file_name;
            }

            if(!state.requestFromNginx) {
                return state.urlForFile + urlPart;
            }
        }
    }
}
