<template>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5.64H5.66083V10H4.33917V5.64H0V4.36H4.33917V0H5.66083V4.36H10V5.64Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGPlus"
    }
</script>

<style scoped>

</style>
