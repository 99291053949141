import {Point, Segment, Polygon, Line} from "@mathigon/fermat";
import {translatePoints} from "../../math/getAreaCorrected";
import {getBackwardMatrix} from "../../math/perspectiveTransform";

export const getPointOnLine = (p1, p2, d) => {
    let x, y;
    let angle = Math.atan2(p2.y - p1.y, p2.x - p1.x)
    x = p1.x + (d * Math.cos(angle))
    y = p1.y + (d * Math.sin(angle))
    return {x, y}
}

// export const getNewPointsForAdditional = (pointArray, width) => {
//     let d;
//     if (width)
//         d = width
//     else
//         d = 10;
//     let newPoint1 = getPointOnLine({x: pointArray[0].x, y: pointArray[0].y}, {x: pointArray[2].x, y: pointArray[2].y}, d);
//     let newPoint2 = getPointOnLine({x: pointArray[1].x, y: pointArray[1].y}, {x: pointArray[3].x, y: pointArray[3].y}, d);
//     return [newPoint1, newPoint2]
// }