import store from "../../store";
import {planePolygonForShowPlanes} from "../primitives/planePolygon";
import {bus} from "../../vue_bus";
import {sortObjectsByZindex} from "../libs/sortObjectsByZindex";
import {getAdditionalPolygonPoints} from "../primitives/additionalPlane";
import pSBC from "shade-blend-color";


export const planeTool = async function(fabricJS, options) {
    this.fabricJS = fabricJS;
    this.photoID = store.state.route.params.photoID;
    this.withTexture = options.withTexture;
    this.sourceImage = options.sourceImage;
    this.scale = options.scale;
    this.planeList = store.getters["active/ALL_PLANES_FROM_PHOTO"];
    this.polygonList = [];
    this.watchersDestroyCallbacks = [];
    this.selected = null;

    this.destroy = () => {
        this.watchersDestroyCallbacks.forEach((cb)=>{cb()});
    }
    // this.applyTexture = new applyTexture(this.fabricJS);
    // this.fabricJS.on('mouse:over', (e) => {
    //     if (!e.target)
    //         return
    //
    //     if (!e.target.isPlane)
    //         return
    //
    //     if (this.planeList[e.target.planeID].idDesignGroup == store.state.route.params.groupID)
    //         return
    //
    //     e.target.oldOpacity = e.target.opacity;
    //     if (this.withTexture) {
    //         e.target.set({
    //             // fill: "#0f5",
    //             opacity: 0.7,
    //         });
    //     }
    //     else
    //         e.target.set({
    //             // fill: group.color,
    //             opacity: 0.9,
    //         });
    //     this.fabricJS.renderAll();
    //
    // });
    //
    // this.fabricJS.on('mouse:out', (e) => {
    //     if (!e.target)
    //         return
    //
    //     if (!e.target.isPlane)
    //         return
    //
    //     if (this.planeList[e.target.planeID].idDesignGroup == store.state.route.params.groupID)
    //         return
    //
    //     if (this.withTexture){
    //         // e.target.fill = e.target.oldfill;
    //         e.target.set({
    //             opacity: e.target.oldOpacity,
    //         });
    //     }
    //     else
    //         e.target.set({
    //
    //             opacity: e.target.oldOpacity
    //         });
    //     this.fabricJS.renderAll();
    //
    // });

    this.fabricJS.on('mouse:down', (e) => {
        if(e.target) {
            if (!e.target.planeID) {
                bus.$emit("unSelectedPlaneFromPlaneVisualisator", e.target.planeID);
            }

            if (e.target.isPlane) {
                bus.$emit("selectedPlaneFromPlaneVisualisator", e.target.planeID);
                if (this.withTexture)
                    return;

                if (this.selected)
                    this.selected.set({
                        strokeWidth: 0,
                    })
                this.selected = e.target;
                this.selected.set({
                    strokeWidth: 2,
                })
            }
        }
    });

    this.fabricJS.on('mouse:dblclick', (e) => {
        if (!e.target)
            return

        if (e.target.isPlane) {
            bus.$emit("dblclickPlaneFromPlaneVisualisator", e.target.planeID);
        }
    });
    // this.designID = designID;
    this.design = store.getters["active/DESIGN"];

    let promises = [];
    this.polygonList = [];
    // fabricJS.viewportTransform = [1/window.devicePixelRatio,0,0,1/window.devicePixelRatio,0,0];
    let index = 0;
    for (let planeID in this.planeList) {
        // store.commit("PROGRESS_PERCENT", Math.round(index/Object.keys(this.planeList).length*100));
        planeID = parseInt(planeID);
        if (!this.planeList[planeID].isComplete)
            continue;

        let pointList = this.planeList[planeID].points;

        if (this.planeList[planeID].type === "additional")
            pointList = getAdditionalPolygonPoints(this.planeList[planeID], 1, 10);

        let polygon = planePolygonForShowPlanes(
            pointList,
            this.planeList[planeID].type,
            this.scale,
            planeID
        );
        polygon.set({
            opacity: 0.7,
        })

        this.polygonList.push(polygon)
        this.fabricJS.add(polygon);

        if (!(["door", "window", "cutout"].includes(this.planeList[planeID].type)))
            this.watchersDestroyCallbacks.push(store.watch(
                ()=>{
                    if (store.getters["active/ALL_PLANES_FROM_PHOTO"])
                        return store.getters["active/ALL_PLANES_FROM_PHOTO"][planeID].takeIntoCalculations
                    else
                        return null
                },
                (flag)=>{
                    if (!flag)
                        return
                    if (store.getters["active/ALL_PLANES_FROM_PHOTO"][planeID].takeIntoCalculations)
                        polygon.set({
                            fill: polygon.oldFill,
                        })
                    else {
                        polygon.oldFill = polygon.fill;
                        polygon.set({
                            fill: pSBC(-0.4, polygon.fill),
                        })
                    }
                    this.fabricJS.requestRenderAll();
                }));


        store.dispatch("IN_PROGRESS", promises[promises.length-1]);

        index+=1;
    }


    await Promise.all(promises);

    this.fabricJS.renderAll();
    sortObjectsByZindex(this.fabricJS);

    this.destroy = ()=>{
        bus.$off("switchVisibilityBackgroundPlanes");
        this.watchersDestroyCallbacks.forEach((cb)=>{cb()});
    }

    bus.$on("switchVisibilityBackgroundPlanes", (flag)=>{
        this.polygonList.forEach((polygon)=>{
            polygon.set({
                opacity: flag?store.state.projectConfiguration.backgroundPlanesOpacity:0,
            });
        })
        this.fabricJS.requestRenderAll();
    });

    if (!this.withTexture)
        return this

    // this.watchersDestroyCallbacks.push(store.watch(
    //     ()=>[store.state.route.params.groupID, store.state.route.params.facadeElement],
    //     (newValue, oldValue)=>{
    //         this.fabricJS._objects.forEach((object) => {
    //             if (object.isPlane) {
    //                 if (newValue[0] == this.planeList[object.planeID].idDesignGroup && newValue[1] === this.planeList[object.planeID].type) {
    //                     object.set({
    //                         // stroke: '#D83336',
    //                         strokeWidth: 2,
    //                         opacity: 1,
    //                     })
    //                 } else {
    //                     object.set({
    //                         strokeWidth: 0,
    //                         opacity: 1,
    //                     })
    //                 }
    //             }
    //         })
    //     }));

    return this
}
