<template>
    <div class="page-container">
        <TheAdminModal/>
        <TheAdminNavbar class="page-navbar"/>
        <div class="page-admin-body">
            <vue-custom-scrollbar class="page-admin-sidebar">
                <TheAdminSidebar/>
            </vue-custom-scrollbar>
            <div class="pade-admin-workspace">
                <router-view name="workspace"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import TheAdminSidebar from "./Components/TheAdminSidebar";
    import TheAdminNavbar from "./Components/TheAdminNavbar";
    import TheAdminModal from "../ModalMP/ModalAdmin/TheAdminModal";
    import vueCustomScrollbar from 'vue-custom-scrollbar';

    export default {
        name: "TheAdminPageMP",
        components: {
            vueCustomScrollbar,
            TheAdminModal,
            TheAdminNavbar,
            TheAdminSidebar
        }
    }
</script>

<style scoped>

</style>
