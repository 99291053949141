<template>
    <svg width="59" height="42" viewBox="0 0 59 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.075116 25.422L22.0911 12.711L27.7666 9.4343L29.6522 12.7003L30.9248 11.9656L29.0392 8.69956L31.0753 7.52398L32.961 10.79L34.2336 10.0553L32.3479 6.78924L34.3841 5.61366L36.2697 8.87969L37.5423 8.14496L35.6567 4.87893L44.1072 4.19617e-05L51.1607 12.217L29.8663 24.6659L6.50878 24.5852L6.56361 36.8338L0.075116 25.422Z" fill="currentColor"/>
        <path d="M7.94238 26.1034L33.4712 26.1034L38.7648 26.1034L40.2217 26.0962L40.2217 29.9354L41.6973 29.9354L41.6973 26.0962L44.0584 26.1034L44.0584 29.9354L45.5341 29.9354L45.534 26.0962L47.8951 26.1034L47.8951 29.9354L49.3708 29.9354L49.3708 26.0962L59.0001 26.1034L59.0001 40.2789L7.94238 40.2789L7.94238 26.1034Z" fill="currentColor"/>
        <rect x="7.94238" y="40.8462" width="51.0577" height="1.13462" fill="currentColor"/>
        <path d="M31.2595 24.8281L51.6212 13.0723L52.062 13.8359L32.973 24.8569L31.2595 24.8281Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGRAM"
    }
</script>

<style scoped>

</style>
