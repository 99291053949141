var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "14",
        viewBox: "0 0 14 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("rect", {
        attrs: {
          y: "4.78943",
          width: "4.42105",
          height: "4.42105",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "4.78955",
          width: "4.42105",
          height: "4.42105",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "9.57886",
          y: "9.57898",
          width: "4.42105",
          height: "4.42105",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "4.78955",
          y: "9.57898",
          width: "4.42105",
          height: "4.42105",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          y: "9.57898",
          width: "4.42105",
          height: "4.42105",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "4.78955",
          y: "4.78943",
          width: "4.42105",
          height: "4.42105",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }