
export default ((to, from, next) => {
    if(from.path === "/" && !to.matched.some(record => record.meta.offCheckFirstCrossing)){
        next({
            name: 'loading',
            query: {
                ...to.params,
                redirect: to.fullPath
            },
            replace: true
        });
    } else {
        next();
    }
});
