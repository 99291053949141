<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Параметры категории
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-form-grid" style="margin-top: 23px">
                <div class="modal-form-grid-label">
                    Название
                </div>
                <input class="modal-form-grid-input" type="text" placeholder="Название категории"
                       v-model="nameCategory">
                <div class="modal-form-grid-label" style="height: 36px">
                    Область применения
                </div>
                <div class="modal-form-checkbox-grid">
                    <template v-for="productType in productTypes">
                        <div>
                            <input type="checkbox" :value="productType.id" v-model="selectedProductTypes"
                                   class="form-input-checkbox">
                        </div>
                        <div class="form-input-checkbox-name">{{productType.name}}</div>
                    </template>
                </div>
                <div class="modal-form-grid-label">
                    Видимость в меню
                </div>
                <div class="modal-form-checkbox-grid">
                    <div>
                        <input type="checkbox" v-model="active"
                               class="form-input-checkbox">
                    </div>
                    <div v-if="active" class="form-input-checkbox-name">Отображается</div>
                    <div v-else class="form-input-checkbox-name">Скрыта</div>
                </div>

                <!--                <div class="modal-form-grid-label">-->
                <!--                    Иконка-->
                <!--                </div>-->
                <!--                <div>-->
                <!--                    <img class="modal-form-img" :src="urlForStatic('/img/no-img-placeholder.png')"-->
                <!--                         :class="{'image-placeholder': image === null}"-->
                <!--                         @click="$refs.file.click()"/>-->
                <!--                    <input type="file" id="fileInputModal" ref="file" v-on:change="handleFileUpload( $event)"-->
                <!--                           accept=".jpg,.png" hidden>-->
                <!--                </div>-->


            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-lower-buttons-grid">
                <div class="blue-button modal-accept-button" v-on:click="save()">Сохранить</div>
                <div class="blue-outline-button modal-accept-button" v-on:click="$emit('close')">Отмена</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import {urlForStaticVue} from "../../common";
    import {http_request} from "../../../httpConfig";
    // import {uploadFiles} from "../../../httpConfig";

    export default {
        name: "ModalSubcategory",
        mixins: [urlForStaticVue],
        components: {
            SVGClose
        },
        props: {
            callback: {
                type: Function
            },
            categoryForChange: {
                type: Object
            }
        },
        data() {
            return {
                productTypes: [],
                selectedProductTypes: [],
                nameCategory: '',
                image: null,
                active: true
            }
        },
        methods: {
            // handleFileUpload(fileInput) {
            //     const savePhotos = (photosSRC) => {
            //         for (let srcPhoto of photosSRC) {
            //             this.srcPhoto = srcPhoto;
            //         }
            //     };
            //
            //     for (let file in fileInput.target.files) {
            //         if (fileInput.target.files[file].size / 1048576 > 15) {
            //             // fileTooLarge = true
            //             return
            //         }
            //     }
            //     // fileTooLarge = false
            //     console.log(fileInput.target.files);
            //     uploadFiles(fileInput.target.files)
            //         .then((request) => {
            //             console.log(request.data);
            //             savePhotos(request.data.fileList);
            //         })
            // },
            save() {
                let dataForSend = {
                    name: this.nameCategory,
                    product_types: this.selectedProductTypes,
                    active: this.active
                };

                if (this.categoryForChange) {
                    dataForSend['id'] = this.categoryForChange.id;
                }

                http_request.post('/save_category', dataForSend)
                    .then((request) => {
                        console.log(request.data);
                        if (this.callback) {
                            this.callback();
                        }
                        this.$emit('close');
                    })
            }
        },
        mounted() {
            if (this.categoryForChange) {
                this.selectedProductTypes.push(...this.categoryForChange.product_types.map(prodType => prodType.id));
                this.nameCategory = this.categoryForChange.name;
                this.image = this.categoryForChange.preview_src;
                this.active = this.categoryForChange.active;
            }
            http_request.post('/get_product_types', {})
                .then((request) => {
                    console.log(request.data);
                    this.productTypes.push(...request.data.product_types);
                })
        }
    }
</script>

<style scoped>

</style>
