<template>
    <div class="workspace-loader-container">
        <div>
            <SVGWorkspaceLoader/>
            <div class="workspace-loader-text-container">
                <div class="workspace-loader-text">Идёт загрузка</div>
                <div class="workspace-loader-text" v-show="!!progressPercent">{{progressPercent}} %</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGWorkspaceLoader from "../../SVGMP/SVGWorkspaceLoader";

    export default {
        name: "WorkspaceLoader",
        components: {SVGWorkspaceLoader},
        computed: {
            progressPercent() {
                return this.$store.getters['PROGRESS_PERCENT'];
            }
        },
        data() {
            return {
                loadingProgress: 68
            }
        }
    }
</script>

<style>
    .workspace-loader-path {
        stroke-dasharray: 1200;
        stroke-dashoffset: 1200;
        animation: ws-dash 3s linear 0s normal infinite none running;
    }

    @keyframes ws-dash {
        from {
            stroke-dashoffset: 1200;
        }
        to {
            stroke-dashoffset: 0;
        }
    }

    .workspace-loader-container {
        position: absolute;
        display: flex;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .workspace-loader-text-container {
        font-family: "Co Headline Corp";
        font-size: 40px;
        color: white;
        position: relative;
        top: -180px;
    }

    .workspace-loader-text {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
