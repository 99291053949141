<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Настроить теги
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>

        <div class="modal-data-container">
            <div class="admin-tabs-container">
                <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'grouping'}"
                     v-on:click="switchAdminTab('grouping')">
                    <SVGAdministrating style="margin-right: 12px; width: 16px;"/>
                    <div class="admin-tab-text">Группировка тегов</div>
                </div>
                <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'categories'}"
                     v-on:click="switchAdminTab('categories')">
                    <SVGCategories style="margin-right: 12px; height: 16px;"/>
                    <div class="admin-tab-text">Категории тегов</div>
                </div>
                <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'tags'}"
                     v-on:click="switchAdminTab('tags')">
                    <SVGTags style="margin-right: 12px; height: 16px;"/>
                    <div class="admin-tab-text">Теги</div>
                </div>
            </div>
            <div class="modal-borderline-thin "></div>
        </div>

        <!--        grouping -->
        <ModalTagsSorting v-if="activeAdminTab === 'grouping'"/>


        <!--        tag categories -->
        <ModalTagsCategories v-if="activeAdminTab === 'categories'"/>

        <!--        tags -->
        <ModalTagsTags v-if="activeAdminTab === 'tags'"/>


<!--        <div class="modal-data-container">-->
<!--            <div class="modal-lower-buttons-grid">-->
<!--                <div class="blue-button modal-accept-button" v-on:click="$emit('close')">Сохранить</div>-->
<!--                <div class="blue-outline-button modal-accept-button" v-on:click="$emit('close')">Отмена</div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import SVGAdministrating from "../../SVGMP/SVGAdministrating";
    import SVGTags from "../../SVGMP/SVGTags";
    import SVGCategories from "../../SVGMP/SVGCategories";
    import ModalTagsSorting from "./ModalTagsSorting";
    import ModalTagsCategories from "./ModalTagsCategories";
    import ModalTagsTags from "./ModalTagsTags";


    export default {
        name: "ModalTags",
        components: {
            ModalTagsTags,
            ModalTagsCategories,
            ModalTagsSorting,
            SVGCategories,
            SVGTags,
            SVGAdministrating,
            SVGClose,
        },
        data() {
            return {
                activeAdminTab: 'grouping',
                tagCategoryOnEdit: null,
                dynamicExpandRule: {},
                showAddTagUnsorted: false,
            }
        },
        methods: {
            switchAdminTab(tabName) {
                this.activeAdminTab = tabName
            },
        },
    }
</script>

<style scoped>
    table, th, tr, td {
        border: none;
    }

    .admin-logs-table {
        border: none;
    }
</style>
