import store from "../../store";
import pSBC from "shade-blend-color"

export const planePolygon = function (points, planeID) {
    this.fabricObject = new fabric.Polygon(points, {
        stroke: '#D83336',
        strokeWidth: 0,
        fill: '#bcbcbc',
        opacity: 0.3,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: true,
        lockMovementX: true,
        lockMovementY: true,
        objectCaching: false,
        perPixelTargetFind: true,
        planeID
    });

    this.updateColor = ()=>{
        if (!store.getters["active/ALL_PLANES_FROM_PHOTO"][planeID])
            return

        if ((["door", "window", "cutout"].includes(store.getters["active/ALL_PLANES_FROM_PHOTO"][planeID].type)))
            return

        let groupID = store.getters["active/ALL_PLANES_FROM_PHOTO"][planeID].idDesignGroup;
        if (groupID !== null && groupID !== undefined && !isNaN(groupID)) {
            let group = store.getters["active/PLANE_GROUP_LIST"][groupID];
            if (store.getters["active/ALL_PLANES_FROM_PHOTO"][planeID].takeIntoCalculations)
                this.fabricObject.set({
                    fill: group.color,
                })
            else
                this.fabricObject.set({
                    oldFill: group.color,
                    fill: pSBC(-0.4, group.color),
                })
        }
    }

    // console.log(store.getters["active/ALL_PLANES_FROM_PHOTO"][planeID].idDesignGroup);
    // store.watch(
    //     ()=>store.getters["active/ALL_PLANES_FROM_PHOTO"][planeID].idDesignGroup,
    //     this.updateColorc
    // )
    //
    // store.watch(
    //     ()=> {
    //         console.log("!!!!!");
    //         let groupID = store.getters["active/ALL_PLANES_FROM_PHOTO"][planeID].idDesignGroup;
    //         return  store.getters["active/PLANE_GROUP_LIST"][groupID];
    //     },
    //     this.updateColor
    // )

    this.updateColor();
    return this.fabricObject
}

const changeByType = (polygon, type, planeID, withTexture) => {
    if (type === "wall") {
        polygon.set({
            // fill: '#bcbcbc',
            zIndex: 1000 + planeID
        });
    } else if (type === "roof") {
        polygon.set({
            // fill: '#aaaa00',
            zIndex: 1200 + planeID
        });
    } else if (type === "fence") {
        polygon.set({
            // fill: '#aaaa00',
            zIndex: 1400 + planeID
        });
    } else if (type === "door") {
        polygon.set({
            // fill: '#aaaa00',
            zIndex: 2000 + planeID
        });
    } else if (type === "platband") {
        polygon.set({
            // fill: '#aaaaaa',
            zIndex: 2500 + planeID,
            // evented: withTexture ? false : true,
        });
    } else if (type === "window") {
        polygon.set({
            // fill: '#aa00aa',

            zIndex: 3000 + planeID
        });
    } else if (type === "additional") {
        polygon.set({
            // fill: '#aaaaaa',
            zIndex: 4000 + planeID,
            // evented: true,
        });
    } else if (type === "cutout") {
        polygon.set({
            // fill: '#aaaaaa',
            zIndex: 5000 + planeID,
            // evented: withTexture?false:true,
        });
    }
}

const getArrayFromObject = function(pointList, type, scale) {

    let points = [];
    for (let pointID in pointList) {
        points.push({
            x: pointList[pointID].x * scale,
            y: pointList[pointID].y * scale
        });
    }

    // if (type === "additional") {
    //     [points[3], points[2]] = getNewPointsForAdditional(points);
    // }

    if (type === "platband") {


    }


    return points
}

export const planePolygonForShowPlanes = function (pointList, type, scale, planeID, withTexture) {
    let points = getArrayFromObject(pointList, type, scale);

    let polygon = new planePolygon(points, planeID);
    polygon.set({
        opacity: 0.1,
        evented: true,
        hoverCursor: 'default',
        // selectable: true,
        isPlane: "true",
    });
    // let opacity;
    // if (withTexture) {
    //     opacity = 1;
    // } else {
    //     opacity = 0.7
    //     // polygon.updateColor();
    // }
    // polygon.set({
    //     opacity: opacity,
    // });
    changeByType(polygon, type, planeID, withTexture);

    return polygon;
}

export const planePolygonForTools = function (pointList, type, scale, planeID) {
    let points = getArrayFromObject(pointList, type, scale);

    let polygon = new fabric.Polygon(points, {
        stroke: '#D83336',
        strokeWidth: 0,
        fill: '#bcbcbc',
        opacity: (store.state.projectConfiguration.backgroundPlanesOpacity?
            store.state.projectConfiguration.backgroundPlanesOpacity:0.5),
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: true,
        lockMovementX: true,
        lockMovementY: true,
        objectCaching: false,
        perPixelTargetFind: true,
        planeID,
        isPlane: "true",
    });

    // changeByType(polygon, type);

    return polygon;
}
