<template>
    <svg width="50" height="50" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="36" cy="36" r="36" fill="#73818A"/>
        <path d="M39.7656 10L38.8643 47.4067H32.9707L32 10H39.7656ZM39.4189 61.6206H32.3467V54.3057H39.4189V61.6206Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGAlert"
    }
</script>

<style scoped>

</style>
