import {getPerspectiveCamera} from "./getPerspectiveCamera";
import {PointProjection} from "./pointProjections";

export const baseLineToFloorPlan = async function (pointList, imageSize, cameraParameters, scale) {
    const width = imageSize.width;
    const height = imageSize.height;

    const camera = await new getPerspectiveCamera(
        cameraParameters,
        width,
        height,
        scale
    );

    const projector = new PointProjection(
        camera,
        width,
        height
    );

    let pointList3d = [];
    pointList.forEach((point) => {
        let projection = projector.getFloorProjection(point);
        if (projection[0])
            pointList3d.push(projection[0].point);
    });

    return pointList3d;


}
