var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "46",
        height: "30",
        viewBox: "0 0 46 30",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M6.25676 22.6235V29H32.7432V22.6235H37L31.7973 5.91444H24.2297V1H19.5V5.91444H6.25676L2 22.6235H6.25676Z",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M35.9778 5V7.63273H32.5L35.9778 20H42.8054V16.584H45L42.3177 7.63273H38.4162V5H35.9778Z",
          fill: "currentColor",
          stroke: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }