var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-background" }, [
    _c("div", { staticClass: "modal-head-grid" }, [
      _c("div", { staticClass: "modal-label" }, [
        _vm._v("\n                Логи событий\n            ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-close-btn-mp",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("SVGClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal-data-container" },
      [
        _c(
          "vue-custom-scrollbar",
          {
            staticClass: "admin-scrollable-logs",
            staticStyle: { height: "600px" }
          },
          [
            _c("table", { staticClass: "admin-logs-table" }, [
              _c("thead", [
                _c("tr", { staticClass: "admin-logs-table-head" }, [
                  _c("th", { staticStyle: { "padding-left": "30px" } }, [
                    _vm._v("Дата")
                  ]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Пользователь")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Действие")])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.logs, function(log, index) {
                  return _c("tr", { class: { line: index % 2 === 0 } }, [
                    _c("td", [
                      _c("span", { staticStyle: { "padding-left": "30px" } }, [
                        _vm._v(_vm._s(log.date))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_c("span", [_vm._v(_vm._s(log.user.fullname))])]),
                    _vm._v(" "),
                    _c("td", { staticStyle: { width: "50%" } }, [
                      _c("span", [_vm._v(_vm._s(log.tablename) + ":")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "bold-text" }, [
                        _vm._v(_vm._s(log.metadata.action))
                      ])
                    ])
                  ])
                }),
                0
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }