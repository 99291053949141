<template>
    <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9976 15.1875L21.0974 10.3011H14.2208V7.13022C14.2208 5.7934 15.1814 4.49033 18.2612 4.49033H21.3874V0.330117C21.3874 0.330117 18.5505 0 15.838 0C10.1749 0 6.47323 2.34035 6.47323 6.57703V10.3011H0.178223V15.1875H6.47323V27H14.2208V15.1875H19.9976Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGFacebook"
    }
</script>

<style scoped>

</style>
