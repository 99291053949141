import {Segment} from "@mathigon/fermat";
import {translatePoints} from "../../math/getAreaCorrected";
import {getBackwardMatrix} from "../../math/perspectiveTransform";
import {getPointOnLine} from "../libs/lines";

export const getNewPointsForAdditional = (options) => {
    const pointArray = options.points;
    const width = options.width;
    const perspectiveMatrix = options.perspectiveMatrix;

    let d;
    if (width)
        d = width
    else
        d = 10;

    // let segment1 = new Segment(pointArray[0], pointArray[1]);
    // let segment2 = new Segment(pointArray[1], pointArray[2]);
    // let segment3 = new Segment(pointArray[2], pointArray[3]);
    // let segment4 = new Segment(pointArray[4], pointArray[0]);
    let newPoint1;
    let newPoint2;
    if (perspectiveMatrix) {
        const translatedPoints = translatePoints(perspectiveMatrix, pointArray);
        newPoint1 = getPointOnLine(
            {x: translatedPoints[0].x, y: translatedPoints[0].y},
            { x: translatedPoints[2].x, y: translatedPoints[2].y}, d);
        newPoint2 = getPointOnLine(
            {x: translatedPoints[1].x, y: translatedPoints[1].y},
            {x: translatedPoints[3].x, y: translatedPoints[3].y}, d);

        const inversePerspectiveMatrix = getBackwardMatrix(perspectiveMatrix);

        const newPoints = translatePoints(inversePerspectiveMatrix, [translatedPoints[0], translatedPoints[1], newPoint1, newPoint2]);
        return [newPoints[2], newPoints[3]]
    } else {
        newPoint1 = getPointOnLine({x: pointArray[0].x, y: pointArray[0].y}, {x: pointArray[2].x, y: pointArray[2].y}, d);
        newPoint2 = getPointOnLine({x: pointArray[1].x, y: pointArray[1].y}, {x: pointArray[3].x, y: pointArray[3].y}, d);
        return [newPoint1, newPoint2]
    }

    // console.log(segment1);


}

export const getAdditionalPolygonPoints = (plane, scale, width) => {
    let points = {};
    const pointDict = plane.points;
    for (let pointID in pointDict) {
        points[pointID] = {
            x: pointDict[pointID].x,
            y: pointDict[pointID].y,
        };
    }

    [points[4], points[3]] = getNewPointsForAdditional({
        points: Object.values(points),
        width: width,
        perspectiveMatrix: plane.perspectiveMatrix,
    });

    for (let pointID in points) {
        points[pointID] = {
            x: points[pointID].x*scale,
            y: points[pointID].y*scale,
        };
    }

    return points
}