var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-background" }, [
    _c("div", { staticClass: "modal-head-grid" }, [
      _c("div", { staticClass: "modal-label" }, [
        _vm._v("\n            Параметры пользователя\n        ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-close-btn-mp",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("SVGClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c(
        "div",
        {
          staticClass: "modal-form-grid",
          staticStyle: { "margin-top": "23px" }
        },
        [
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                ФИО\n            ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.fullname,
                expression: "fullname"
              }
            ],
            staticClass: "modal-form-grid-input",
            attrs: { type: "text", placeholder: "Полное имя" },
            domProps: { value: _vm.fullname },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.fullname = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                Подразделение\n            ")
          ]),
          _vm._v(" "),
          _c("input", {
            staticClass: "modal-form-grid-input",
            attrs: { type: "text", placeholder: "Место работы" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                E-mail\n            ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            staticClass: "modal-form-grid-input",
            attrs: { type: "text", placeholder: "example@mail.com" },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          !_vm.changePassword
            ? [
                _c("div"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "blue-button",
                    on: {
                      click: function($event) {
                        _vm.changePassword = true
                      }
                    }
                  },
                  [_vm._v("Сменить пароль")]
                )
              ]
            : [
                _c("div", { staticClass: "modal-form-grid-label" }, [
                  _vm._v("\n                    Пароль\n                ")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password"
                    }
                  ],
                  staticClass: "modal-form-grid-input",
                  attrs: { type: "text", placeholder: "Пароль" },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "modal-form-grid-label" }, [
                  _vm._v(
                    "\n                    Подтверждение пароля\n                "
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.repeatedPassword,
                      expression: "repeatedPassword"
                    }
                  ],
                  staticClass: "modal-form-grid-input",
                  attrs: { type: "text", placeholder: "Пароль" },
                  domProps: { value: _vm.repeatedPassword },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.repeatedPassword = $event.target.value
                    }
                  }
                })
              ],
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                Телефон\n            ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone"
              }
            ],
            staticClass: "modal-form-grid-input",
            attrs: { type: "text", placeholder: "+7 (***)-***-**-**" },
            domProps: { value: _vm.phone },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phone = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                Роль пользователя\n            ")
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.role,
                  expression: "role"
                }
              ],
              staticClass: "modal-form-grid-input",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.role = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c("option", { attrs: { value: "manager" } }, [
                _vm._v("manager")
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "superadmin" } }, [
                _vm._v("superadmin")
              ])
            ]
          )
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-lower-buttons-grid" }, [
        _c(
          "div",
          {
            staticClass: "blue-button modal-accept-button",
            on: { click: _vm.saveUser }
          },
          [_vm._v("Сохранить")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blue-outline-button modal-accept-button",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v("Отмена")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }