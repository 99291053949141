// import * as THREE from "three";

import {PCFSoftShadowMap, ReinhardToneMapping, WebGLRenderer} from "three";

function add_renderer(container, size) {
    const renderer = new WebGLRenderer({antialias: true, alpha: true});
    renderer.setPixelRatio(window.devicePixelRatio);
    // renderer.setClearColor(new THREE.Color(0xffffff), 0.5);
    renderer.setSize(size.width, size.height);
    renderer.autoClear = true;
    // renderer.shadowMap.enabled = true;
    renderer.toneMapping = ReinhardToneMapping;
    renderer.shadowMap.type = PCFSoftShadowMap;
    // renderer.setClearAlpha(0.5);
    // container.appendChild(renderer.domElement);

    // renderer.ontick_handlers.push(()=>{renderer.render(scene, cameraPersp)});



    return renderer
}

export default add_renderer
