<template>
  <svg width="70" height="55" viewBox="0 0 70 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0161 40.561V53.289H59.8944V40.561H67.9105L58.113 9.99115H43.8621V1H34.9553V9.99115H10.0161L2 40.561H10.0161Z" stroke="currentColor" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
name: "SVGPrivateHouse"
}
</script>

<style scoped>

</style>