import {Point, Segment, Polygon, Line} from "@mathigon/fermat";
import store from "../store";
import {getPerspectiveMatrix, translatePointByPerspectiveMatrix} from "../math/perspectiveTransform";

export const translatePoints = function (perspectiveMatrix, pointList) {
    let translatedPointList = [];
    for (let pointID in pointList) {
        translatedPointList.push(translatePointByPerspectiveMatrix(perspectiveMatrix, pointList[pointID]))
    }
    return translatedPointList
}

const translatePlanes = function (perspectiveMatrix, planeList) {
    let newPlanes = {};
    for (let planeID in planeList) {
        newPlanes[planeID] = translatePoints(perspectiveMatrix, planeList[planeID].points);
    }
    return newPlanes;
}

export const getScalePlaneId = function (scalePoints, planeList) {
    let scalePlaneID = undefined;
    let scaleSegment = new Segment(scalePoints[0], scalePoints[1]);
    let maxLength = 0;
    for (let pID in planeList) {
        let intersects = [];
        let fermatPolygon = getFermatPolygon(planeList[pID].points);
        let innerPoints = [];
        for (let p in scalePoints) {
            if (fermatPolygon.contains(scalePoints[p])) {
                innerPoints.push(scalePoints[p]);
            }
        }
        if (innerPoints.length == 2) {
            scalePlaneID = pID;
            break;
        }
        let fermatPolygonEdges = fermatPolygon.edges;
        for (let edge in fermatPolygonEdges) {
            let intersection = Segment.intersect(scaleSegment, fermatPolygonEdges[edge]);
            if (intersection) {
                intersects.push(intersection);
            }
        }
        if (intersects.length == 0) {
            continue;
        }
        let innerLine = undefined;
        if (intersects.length == 1) {
            innerLine = new Line(innerPoints[0], intersects[0]);
        } else if (intersects.length == 2) {
            innerLine = new Line(intersects[0], intersects[1]);
        } else {
            console.error(`More then two intersection points are found for plane with planeID = ${pID}`);
        }
        if (innerLine.length > maxLength) {
            maxLength = innerLine.length;
            scalePlaneID = pID;
        }
    }
    return scalePlaneID;
}

export const getScaleTranslatedPoints = async function (scaleObject, planeList, photoID) {
    let scalePoints = [
        new Point(scaleObject.points[1].x, scaleObject.points[1].y),
        new Point(scaleObject.points[2].x, scaleObject.points[2].y),
    ]
    let scalePlaneID = getScalePlaneId(scalePoints, planeList);
    if (!scalePlaneID) {
        console.error("Can't find plane for scale line for photo ",photoID);
        return scalePoints;
    }
    const activeProjectUUID = store.state.route.params.projectUUID;
    let perspectiveMatrixScale = await store.dispatch(
        `projectList/${activeProjectUUID}/photoList/${photoID}/planeList/${scalePlaneID}/GET_PERSPECTIVE_MATRIX`,
        {planeID: scalePlaneID, photoID});

    return translatePoints(perspectiveMatrixScale, scalePoints);
}

export const getArea = async function (photoID, planeID, translatedScale) {
    const planeList = store.getters["active/GET_ALL_PHOTO"][photoID].planeList;
    let scaleObject = store.getters["active/GET_ALL_PHOTO"][photoID].scale;
    planeID = parseInt(planeID);

    let perspectiveMatrix = await getPerspectiveMatrix({
        planeID: planeID,
        photoID: photoID,
        scale: 1
    })
    // }
    let scalePoints = [
        new Point(translatedScale[0].x, translatedScale[0].y),
        new Point(translatedScale[1].x, translatedScale[1].y),
    ]
    let dpm = Point.distance(scalePoints[0], scalePoints[1]) / scaleObject.distance;
    if (planeList[planeID].type === "wall") {
        return getAreaCorrected(planeList, planeID, perspectiveMatrix) / Math.pow(dpm, 2);
    }
    if (planeList[planeID].type === "additional") {
        return getFootage(planeList, planeID, perspectiveMatrix) / dpm;
    }
}

export const getFootage = function (planes, planeID, perspectiveMatrix) {


    // if (perspectiveMatrix) {
    //     let pointsTranslated = translatePoints(perspectiveMatrix, planes[planeID].points);
    // } else {
        let pointsTranslated = planes[planeID].points;
    // }
    let footage = Math.sqrt( Math.pow(pointsTranslated[2].x - pointsTranslated[1].x, 2) +
                     Math.pow(pointsTranslated[2].y - pointsTranslated[1].y, 2));
    // let pointsTranslated = planes[planeID].points;
    // let fermatPolygonOrig = getFermatPolygon(pointsTranslated);
    // let fermatEdges = fermatPolygonOrig.edges;
    // let footage = 0;
    // for (let edge in fermatEdges) {
    //     if (fermatEdges[edge].length > footage) {
    //         footage = fermatEdges[edge].length;
    //     }
    // }
    // console.log(footage);
    return footage;
}

export const getDPM = async function (distance, translatedScale) {
    let scalePoints = [
        new Point(translatedScale[0].x, translatedScale[0].y),
        new Point(translatedScale[1].x, translatedScale[1].y),
    ]
    let dpm = Point.distance(scalePoints[0], scalePoints[1]) / distance;
    return dpm;
}

export const getAreaCorrected = function (planes, planeID, perspectiveMatrix) {
    let origPoints = addPointsToEdgeCenters(planes[planeID].points);
    let fermatPolygonOrig = new Polygon(...origPoints);
    let translatedPoints = translatePoints(perspectiveMatrix, planes[planeID].points);
    let fermatPolygonOrigTranslated = getFermatPolygon(translatedPoints);
    let area = fermatPolygonOrigTranslated.area;
    for (let pID in planes) {
        if (planeID != pID && planes[pID].type != "additional" && planes[pID].type != "cutout") {
            let points = addPointsToEdgeCenters(planes[pID].points);
            let fermatPolygon = new Polygon(...points);

            let intersection = fermatPolygon.intersect(fermatPolygonOrig);

            if (intersection) {
                if (intersection.area > fermatPolygonOrig.area) {
                    continue;
                }
                if (intersection.area > fermatPolygon.area) {
                    let intersectionTranslated = translatePoints(perspectiveMatrix, points);
                    let intersectionTranslatedFermatPolygon = getFermatPolygon(intersectionTranslated);
                    area -= intersectionTranslatedFermatPolygon.area;
                    // console.log("planeID", planeID);
                    // console.log("pID", pID);
                    // console.log("fermatPolygonOrig", planes[planeID].points);
                    // console.log("fermatPolygon", fermatPolygon, planes[planeID].points);
                }
                else {
                    let intersectionTranslated = translatePoints(perspectiveMatrix, intersection.points);
                    let intersectionTranslatedFermatPolygon = getFermatPolygon(intersectionTranslated);

                    area -= intersectionTranslatedFermatPolygon.area;
                }
            }
        }
    }
    return area;
}

export const addPointsToEdgeCenters = function (pointsIn) {
    let points = [];
    let pointsOut = [];
    for (let i in pointsIn) {
        points.push(new Point(pointsIn[i].x, pointsIn[i].y));
    }
    pointsOut.push(points[0]);
    for (let i=0; i < points.length; ++i) {
        let next = i == points.length - 1 ? 0 : i+1;
        let fermatSegment = new Segment(points[i], points[next]);
        pointsOut.push(fermatSegment.midpoint);
        if (next != 0) {
            pointsOut.push(points[next]);
        }
    }
    return pointsOut;
}

export const getFermatPolygon = function (points) {
    let fermatPoints = [];
    for (let p in points) {
        fermatPoints.push(new Point(points[p].x, points[p].y));
    }
    return new Polygon(...fermatPoints)
}
