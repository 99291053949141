<template>
        <img :src="photoSrc">
</template>

<script>
    import {urlForFileStorage, urlForStaticVue} from "../../../../common";
    import {imgIsLoadingWhite} from "../../../../ImgIsLoadingWhite";
    import {checkTextureOnServer} from "../../../../../tools";

    export default {
    name: "PreviewImgFromProject",
        mixins: [urlForFileStorage, urlForStaticVue],
        data(){
            return {
                photoSrc: imgIsLoadingWhite
            }
        },
        props: {
            dataProject: {
                type: Object,
            }
        },
        methods: {
            titleImageProject() {
                const getPhotoFromPhotoList = () => {
                    for (let idPhoto in this.dataProject.photoList) {
                        return this.urlForFileStorage(this.dataProject.photoList[idPhoto].src);
                    }
                    return this.urlForStatic('img/No_Picture.jpg');
                }

                if (this.dataProject.src) {
                    this.photoSrc = this.urlForFileStorage(this.dataProject.src);
                    return;
                }

                if(!this.dataProject.designList) {
                    this.photoSrc = getPhotoFromPhotoList();
                    return;
                }

                let designId = Object.keys(this.dataProject.designList)[0];

                if(!(designId && this.dataProject.designList[designId].renderedPhotosPreview && this.dataProject.designList[designId].renderedPhotosPreview[0])){
                    this.photoSrc = getPhotoFromPhotoList();
                    return;
                }

                checkTextureOnServer(this.dataProject.designList[designId].renderedPhotosPreview[0].photoSrc).then(flag => {
                    if (flag) {
                        this.photoSrc = this.urlForFileStorage(this.dataProject.designList[designId].renderedPhotosPreview[0].photoSrc);
                    } else {
                        this.photoSrc = getPhotoFromPhotoList();
                    }
                });

            }
        },
        created() {
            this.titleImageProject();
        }
}
</script>

<style scoped>

</style>
