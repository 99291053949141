<template>
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9747 8.59631L28.9195 20.5412L25.1474 24.3132L13.2026 12.3684L16.9747 8.59631Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.46 9.22516L15.7173 10.4825L15.0886 11.1112L14.46 10.4825L11.661 13.2815L18.2621 19.8826L15.403 22.7417L14.7743 22.113L17.0047 19.8826L10.4036 13.2815L14.46 9.22516Z" fill="currentColor"/>
        <path d="M13.9885 18.4982C14.0753 18.4114 14.216 18.4114 14.3028 18.4982L19.0179 23.2133C19.1047 23.3001 19.1047 23.4408 19.0179 23.5276L17.9177 24.6278C17.8309 24.7146 17.6901 24.7146 17.6033 24.6278L12.8883 19.9128C12.8015 19.826 12.8015 19.6852 12.8883 19.5984L13.9885 18.4982Z" fill="currentColor"/>
        <path d="M13.3598 20.3843L17.1318 24.1563L8.95904 32.3291C8.87224 32.4159 8.73151 32.4159 8.6447 32.3291L5.18699 28.8714C5.10018 28.7846 5.10018 28.6439 5.18699 28.5571L13.3598 20.3843Z" fill="currentColor"/>
        <path d="M21.8857 3.01801L33.9065 15.0388L30.1444 18.801L18.1236 6.78015L21.8857 3.01801Z" fill="currentColor"/>
        <path d="M27.1891 4.07859C25.2092 1.533 23.0643 2.54652 22.2393 3.37148L33.553 14.6853C33.9065 14.3317 34.6844 13.1296 34.9672 11.1497C35.2501 9.16978 33.1995 8.9105 32.1388 9.02836C31.5495 9.14621 30.2296 9.24049 29.6639 8.6748C29.0982 8.10912 27.7783 5.37496 27.1891 4.07859Z" fill="currentColor" stroke="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "SVGPaintRoller"
}
</script>

<style scoped>

</style>