<template>
    <div class="lite-app-body">
        <div class="lite-configurator-sidebar">
            <div class="filters-container" :class="{'collapsed': isFilterCollapsed}">
                <div v-if="isFilterCollapsed" class="filters-head-collapsed">
                    <div class="filters-collapse-button default-border" v-on:click="toggleFilters">
                        <SVGExpandSingle/>
                    </div>
                    <div class="filters-collapsed-label">
                        Параметры материала
                    </div>
                    <div class="collapsed-plane-type-menu">
                        <div class="plane-type-menu-button"  v-on:click="goTo('roof')" :class="{activeFiltersTab: checkActiveTab('roof')}">
                            <div class="flex-center">
                                <SVGRoof/>
                            </div>
                            <div class="plane-type-menu-button-text">Кровля</div>
                        </div>
                        <div class="plane-type-menu-button m-top-1"  v-on:click="goTo('wall')" :class="{activeFiltersTab: checkActiveTab('wall')}">
                            <div class="flex-center">
                                <SVGFacade/>
                            </div>
                            <div class="plane-type-menu-button-text">Фасад</div>
                        </div>
<!--                        <div class="plane-type-menu-button m-top-1"  v-on:click="goTo('gutter')" :class="{activeFiltersTab: checkActiveTab('gutter')}">-->
<!--                            <div class="flex-center">-->
<!--                                <SVGGutter/>-->
<!--                            </div>-->
<!--                            <div class="plane-type-menu-button-text">Водосток</div>-->
<!--                        </div>-->
                        <div class="plane-type-menu-button m-top-1"  v-on:click="goTo('fence')" :class="{activeFiltersTab: checkActiveTab('fence')}">
                            <div class="flex-center">
                                <SVGFence/>
                            </div>
                            <div class="plane-type-menu-button-text">Забор</div>
                        </div>
                    </div>
                </div>

                <div v-else class="filters-head-expanded">
                    <div class="filters-expanded-label" v-on:click="toggleFilters">
                        <SVGCollapseSingle style="margin-right: 15px"/>
                        Свернуть
                    </div>
                    <div class="expanded-plane-type-menu">
                        <div class="plane-type-menu-button" v-on:click="goTo('roof')" :class="{activeFiltersTab: checkActiveTab('roof')}">
                            <div class="flex-center">
                                <SVGRoof/>
                            </div>
                            <div class="plane-type-menu-button-text">Кровля</div>
                        </div>
                        <div class="plane-type-menu-button m-left-1" v-on:click="goTo('wall')" :class="{activeFiltersTab: checkActiveTab('wall')}">
                            <div class="flex-center">
                                <SVGFacade/>
                            </div>
                            <div class="plane-type-menu-button-text">Фасад</div>
                        </div>
<!--                        <div class="plane-type-menu-button m-left-1"  v-on:click="goTo('gutter')" :class="{activeFiltersTab: checkActiveTab('gutter')}">-->
<!--                            <div class="flex-center">-->
<!--                                <SVGGutter/>-->
<!--                            </div>-->
<!--                            <div class="plane-type-menu-button-text">Водосток</div>-->
<!--                        </div>-->
                        <div class="plane-type-menu-button m-left-1"  v-on:click="goTo('fence')" :class="{activeFiltersTab: checkActiveTab('fence')}">
                            <div class="flex-center">
                                <SVGFence/>
                            </div>
                            <div class="plane-type-menu-button-text">Забор</div>
                        </div>
                    </div>
                </div>
                <vue-custom-scrollbar ref="scrollSideBarFilters" class="sidebar-filters-outer-container" v-show="!isFilterCollapsed">
                    <div class="sidebar-filters-container">
                        <router-view name="sidebarLeftFilters"></router-view>
                    </div>
                </vue-custom-scrollbar>

            </div>
                <router-view name="sidebarLeftProducts" :isFilterCollapsed="isFilterCollapsed"></router-view>
        </div>
        <div>
            <div class="lite-configurator-workspace">
                <router-view name="workspace"></router-view>
            </div>
            <div class="lite-configurator-result-tools">
                <LiteConfiguratorLowerBar/>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGRoof from "../../../SVGMP/SVGRoof";
    import SVGFacade from "../../../SVGMP/SVGFacade";
    import SVGGutter from "../../../SVGMP/SVGGutter";
    import SVGFence from "../../../SVGMP/SVGFence";
    import LiteConfiguratorLowerBar from "./LiteConfiguratorLowerBar";
    import SVGExpandSingle from "../../../SVGMP/SVGExpandSingle";
    import SVGCollapseSingle from "../../../SVGMP/SVGCollapseSingle";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import WorkspaceLoader from "../../../ComponentsMP/Loaders/WorkspaceLoader";
    import {bus} from "../../../../vue_bus";

    export default {
        name: "TheLiteConfigurator",
        components: {
            WorkspaceLoader,
            SVGCollapseSingle,
            SVGExpandSingle,
            LiteConfiguratorLowerBar,
            SVGFence,
            SVGGutter,
            SVGFacade,
            SVGRoof,
            vueCustomScrollbar
        },
        data() {
            return {
                isFilterCollapsed: false,
                isLoading: true
            }
        },
        methods: {
            toggleFilters() {
                this.isFilterCollapsed = !this.isFilterCollapsed
            },
            goTo(nameElement){
                let firstMarker;
                const activePhoto = this.$store.getters['active/GET_ACTIVE_PHOTO'];
                if(!activePhoto && !activePhoto.markers && !activePhoto.markers[0]) {
                    alert('У проекта нет маркеров!');
                    return;
                }
                firstMarker = activePhoto.markers.find(marker => marker.typePlanes === nameElement);

                if(!firstMarker) {
                    console.warn('Нет таких плоскостей!');
                    return;
                }

                this.$router.replace({
                    name: 'selectProduct',
                    params: {
                        groupID: firstMarker.idPlaneGroup,
                        facadeElement: firstMarker.typePlanes
                    }
                }).catch(() => {});
            },
            checkActiveTab(name) {
                const nameActiveElement =  this.$store.state.route.params.facadeElement;
                return nameActiveElement === name;
            }
        },
        mounted() {
            bus.$on('scrollSideBarFiltersTop', () => {
                this.$refs.scrollSideBarFilters.$el.scrollTop = 0;
            });
        },
        destroyed() {
            bus.$off('scrollSideBarFiltersTop');
        }
    }
</script>

<style scoped>

</style>
