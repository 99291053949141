import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'

export const loadModel3D = function ({extension, src, scale}, callback) {
    const loaderGLTF = new GLTFLoader();
    if (extension === 'gltf' || extension === 'glb') {
        loaderGLTF.load(src, (model) => {
            model.scene.scale.set(scale, scale, scale);
            if (callback) {
                callback(model.scene);
            }
        });
    }
};

export const loadModel3dPromise = (paramOBJ) => new Promise((resolve, reject) => {
    loadModel3D(paramOBJ, (model) => {
        resolve(model);
    })
})
