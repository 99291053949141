import {http_request} from "../../httpConfig";
import router from '../../router'

export const profile = {
    namespaced: true,
    state: () => ({
        userAuthorized: false,
        userName: null,
        userEmail: null,
        userRole: null,
        userId: null,
        authorisationFailed: false
    }),
    mutations: {
        USER_AUTHORIZED: (state, status) => {
            state.userAuthorized = status;
            state.authorisationFailed = false;

            if (!status) {
                state.userName = null;
                state.userEmail = null;
                state.userRole = null;
            }
        },
        AUTHORIZATION_FAILED: (state) => {
            state.authorisationFailed = true
        },
        UPDATE_PROFILE_DATA:(state, data) => {
            state.userName = data.fullname;
            state.userEmail = data.email;
            state.userRole = data.role;
            state.userId = data.id;
        }
    },
    getters: {
        USER_AUTHORIZED: (state) => {
            return state.userAuthorized;
        }
    },
    actions: {
        LOG_IN: async (context, inputData) => {
            await http_request.post('/login', inputData)
                .then((request) => {
                    context.commit('USER_AUTHORIZED', true);
                    console.warn(request.data.user_info)
                    context.commit('UPDATE_PROFILE_DATA', request.data.user_info);
                    console.log(request.data);
                    router.replace(inputData.redirect).catch(err => {
                    });
                })
                .catch((error) => {
                    console.error(error);
                    if (error.response.status === 423) {
                        context.commit('AUTHORIZATION_FAILED', true);
                    }
                    // console.error(error.response);
                    // if (error.message.includes('timeout')) {
                    //     //Ошибка
                    // } else if (error.response && context.rootState.user_authorized && error.response.status === 401) {
                    //     //Ошибка
                    // } else if (error.response.status === 423) {
                    //     //Ошибка
                    // }
                });
        },
        USER_UNAUTHORIZED: function (context) {
            context.commit('USER_AUTHORIZED', false);
            if(context.rootState.route.query.redirect){
                router.replace({
                    name: 'login',
                    query: {redirect: context.rootState.route.query.redirect}
                });
            }else {
                router.replace({
                    name: 'login',
                    query: {redirect: context.rootState.route.fullPath}
                });
            }
        }
    }
};
