<template>
    <svg width="206" height="113" viewBox="0 0 206 113" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.8154 71.4234V59.4647H2L13.8154 14.2874H50.5746V1H63.7028V14.2874H84.708L99.1491 59.4647H87.3337V71.4234" stroke="black" stroke-width="2"/>
        <path d="M139.968 53.6712V44.727H131.142L139.968 10.938H167.427V1H177.233V10.938H192.924L203.711 44.727H194.885V53.6712" stroke="black" stroke-width="2"/>
        <path d="M62.9009 88.9697V112H152.953V88.9697H167.426L149.737 33.6558H124.008V17.387H107.927V33.6558H62.9009L48.4282 88.9697H62.9009Z" fill="#B4FF00" stroke="black" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: "SVGPromptOne"
}
</script>

<style scoped>

</style>