import {http_request} from "../../httpConfig";

export const organizations = {
    namespaced: true,
    state: () => ({
        numberLoadedItems: 50,
        organizations: [],
        waitingResponseToOrganizationRequest: false,
        allUploaded: false,
        mask: ''
    }),
    mutations: {
        ORGANIZATIONS: (state, organizations) => {
            if(organizations.length !== 0)
                state.organizations = state.organizations.concat(organizations);
            else
                state.allUploaded = true;
        },
        SAVE_ORGANIZATION: (state, dataOrganization) => {
            let indexOrganization = state.organizations.findIndex(organization => organization.id === dataOrganization.id);
            if (indexOrganization === -1) {
                state.organizations.unshift(dataOrganization);
            } else {
                state.organizations.splice(indexOrganization, 1, dataOrganization)
            }
        },
        DELETE_ORGANIZATION: (state, idOrganization) => {
            let indexOrganization = state.organizations.findIndex(organization => organization.id === idOrganization);
            if (indexOrganization !== -1) {
                console.log(state.organizations.splice(indexOrganization, 1));
            }
        },
        CLEAR_ORGANIZATIONS: (state) => {
            state.organizations.splice(0);
            state.allUploaded = false;
        },
        MASK: (state, value) => {
            state.mask = value;
        }
    },
    getters: {
        SELF: state => {
            const mask = state.mask.trim().toLowerCase();
            if (mask.length !== 0) {
                return state.organizations.filter(organization => {
                    const organizationTitle = organization.name.toLowerCase();
                    return !!(organizationTitle.indexOf(mask) + 1);
                });
            } else {
                return state.organizations;
            }
        }
    },
    actions: {
        GET_ORGANIZATIONS: async function (context, inputData) {
            if(context.state.waitingResponseToorganizationRequest || context.state.allUploaded)
                return

            context.state.waitingResponseToorganizationRequest = true;
            let dataForSend = {};
            // const mask = context.state.mask.trim();
            // let dataForSend = {
            //     part: {
            //         // from: context.getters.LENGTH,
            //         from: context.state.organizations.length,
            //         count: context.state.numberLoadedItems
            //     }
            // };
            // if(mask.length !== 0){
            //     dataForSend.find = {
            //         mask,
            //         field: 'name'
            //     };
            // }
            console.log(dataForSend);
            await http_request.post('/get_organizations', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('ORGANIZATIONS', request.data.organizations);
                })
                .catch((error) => {
                    console.error(error);
                    context.state.allUploaded = true;
                });

            context.state.waitingResponseToorganizationRequest = false;
        },
        SAVE_ORGANIZATION: async function (context, inputData) {
            console.log(inputData);
            await http_request.post('/save_organization', inputData)
                .then((request) => {
                    console.log(request.data);
                    context.commit('SAVE_ORGANIZATION', request.data.organization);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        DELETE_ORGANIZATION: async function (context, idOrganization) {
            await http_request.post('/delete_organization', {id: idOrganization})
                .then((request) => {
                    console.log(request.data);
                    context.commit('DELETE_ORGANIZATION', idOrganization);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
};
