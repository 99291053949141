var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-workspace-container" },
    [
      _c("div", { staticClass: "admin-search-new-container" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "search-icon-anchor" },
            [_c("SVGSearch", { staticClass: "search-icon" })],
            1
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mask,
                expression: "mask"
              }
            ],
            staticClass: "modal-form-grid-input search-input",
            attrs: { type: "text", placeholder: "Найти продукцию" },
            domProps: { value: _vm.mask },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.mask = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blue-button admin-new-button",
            on: { click: _vm.addNew }
          },
          [_vm._v("Добавить новый")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "admin-tabs-container" }, [
        _c(
          "div",
          {
            staticClass: "admin-tab",
            class: { "active-admin-tab": !!_vm.activeAreaName === false },
            on: {
              click: function($event) {
                return _vm.selectArea()
              }
            }
          },
          [
            _c("SVGSwarm", {
              staticStyle: { "margin-right": "12px", width: "16px" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "admin-tab-text" }, [_vm._v("Все товары")])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "admin-tab",
            class: { "active-admin-tab": _vm.activeAreaName === "roof" },
            on: {
              click: function($event) {
                return _vm.selectArea("roof")
              }
            }
          },
          [
            _c("SVGRoof", {
              staticStyle: { "margin-right": "12px", height: "16px" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "admin-tab-text" }, [_vm._v("Кровля")])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "admin-tab",
            class: { "active-admin-tab": _vm.activeAreaName === "wall" },
            on: {
              click: function($event) {
                return _vm.selectArea("wall")
              }
            }
          },
          [
            _c("SVGFacade", {
              staticStyle: { "margin-right": "12px", height: "16px" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "admin-tab-text" }, [_vm._v("Фасад")])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "admin-tab",
            class: { "active-admin-tab": _vm.activeAreaName === "gutter" },
            on: {
              click: function($event) {
                return _vm.selectArea("gutter")
              }
            }
          },
          [
            _c("SVGGutter", {
              staticStyle: { "margin-right": "12px", height: "16px" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "admin-tab-text" }, [_vm._v("Водосток")])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "admin-tab",
            class: { "active-admin-tab": _vm.activeAreaName === "fence" },
            on: {
              click: function($event) {
                return _vm.selectArea("fence")
              }
            }
          },
          [
            _c("SVGFence", {
              staticStyle: { "margin-right": "12px", height: "16px" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "admin-tab-text" }, [_vm._v("Забор")])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "vue-custom-scrollbar",
        {
          staticClass: "admin-table-container",
          on: { "ps-y-reach-end": _vm.scrollReachEnd }
        },
        [
          _c("table", { staticClass: "admin-table" }, [
            _c("thead", [
              _c("tr", { staticClass: "admin-table-head-tr" }, [
                _c("th", [_vm._v("№")]),
                _vm._v(" "),
                _c("th", [_vm._v("Изображение")]),
                _vm._v(" "),
                _c("th", [_vm._v("Название")]),
                _vm._v(" "),
                _c("th", { staticClass: "admin-table-options-head" }, [
                  _vm._v("Опции")
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.products, function(value, index) {
                return _c("tr", { staticClass: "admin-table-line" }, [
                  _c("td", [_c("span", {}, [_vm._v(_vm._s(index + 1))])]),
                  _vm._v(" "),
                  _c("td", [
                    _c("img", {
                      staticClass: "admin-table-image",
                      attrs: { src: _vm.urlForPhoto(value.preview) }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v(_vm._s(value.name))])]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "admin-table-options" }, [
                      _c(
                        "div",
                        {
                          staticClass: "admin-table-option",
                          on: {
                            click: function($event) {
                              return _vm.editRow(value)
                            }
                          }
                        },
                        [_c("SVGEdit")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "admin-table-option",
                          on: {
                            click: function($event) {
                              return _vm.deleteRow(value)
                            }
                          }
                        },
                        [_c("SVGClose")],
                        1
                      )
                    ])
                  ])
                ])
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }