<template>
    <div class="admin-workspace-container">
        <div class="admin-search-new-container">
            <div>
                <div class="search-icon-anchor">
                    <SVGSearch class="search-icon"/>
                </div>
                <input class="modal-form-grid-input search-input" type="text" placeholder="Найти пользователя" v-model="mask">
            </div>
            <div class="blue-button admin-new-button" v-on:click="addNew">Добавить нового</div>
        </div>


        <div class="admin-tabs-container">
            <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'manager'}"
                 v-on:click="activeAdminTab = 'manager'">
                <SVGWorker style="margin-right: 12px; width: 16px;"/>
                <div class="admin-tab-text">Менеджеры</div>
            </div>
            <div class="admin-tab" :class="{'active-admin-tab': activeAdminTab === 'superadmin'}"
                 v-on:click="activeAdminTab = 'superadmin'">
                <SVGAdmin style="margin-right: 12px; height: 16px;"/>
                <div class="admin-tab-text">Администраторы</div>
            </div>
        </div>

        <vue-custom-scrollbar class="admin-table-container">
            <table class="admin-table">
                <thead>
                <tr class="admin-table-head-tr">
                    <th>№</th>
                    <th>ФИО</th>
                    <th>Подразделение</th>
                    <th>E-mail</th>
                    <th>Телефон</th>
                    <th class="admin-table-options-head">Опции</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user, index) in users" class="admin-table-line">
                    <td>
                        <span class="">{{index + 1}}</span>
                    </td>
                    <td>
                        <span>{{user.fullname}}</span>
                    </td>
                    <td>
                        <span>{{user.company}}</span>
                    </td>
                    <td>
                        <span>{{user.email}}</span>
                    </td>
                    <td>
                        <span>{{user.phone}}</span>
                    </td>
                    <td>
                        <div class="admin-table-options">
                            <div class="admin-table-option" v-on:click="editRow(user)">
                                <SVGEdit/>
                            </div>
                            <div class="admin-table-option" v-on:click="deleteRow(user)">
                                <SVGClose/>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

        </vue-custom-scrollbar>
    </div>
</template>

<script>
    import SVGSearch from "../../../SVG/SVGSearch";
    import SVGSwarm from "../../../SVGMP/SVGSwarm";
    import SVGRoof from "../../../SVGMP/SVGRoof";
    import SVGFacade from "../../../SVGMP/SVGFacade";
    import SVGGutter from "../../../SVGMP/SVGGutter";
    import SVGFence from "../../../SVGMP/SVGFence";
    import SVGEdit from "../../../SVGMP/SVGEdit";
    import SVGClose from "../../../SVGMP/SVGClose";
    import {urlForStaticVue} from "../../../common";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import SVGFilter from "../../../SVGMP/SVGFilter";
    import SVGWorker from "../../../SVGMP/SVGWorker";
    import SVGAdmin from "../../../SVGMP/SVGAdmin";
    import ModalUser from "../../../ModalMP/ModalAdmin/ModalUser";
    import ModalAttention from "../../../ModalMP/ModalAdmin/ModalAttention";
    import {http_request} from "../../../../httpConfig";

    export default {
        name: "AdminWorkspaceUsers",
        mixins: [urlForStaticVue],
        components: {
            SVGAdmin,
            SVGWorker,
            SVGClose,
            SVGEdit,
            SVGSearch,
            vueCustomScrollbar
        },
        data() {
            return {
                activeAdminTab: 'manager',
                users: [],
                mask: ''
            }
        },
        methods: {
            addNew() {
                this.$modal.show(ModalUser, {
                    callback: () => {
                        window.location.reload();
                    }
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 500,
                });
            },
            updateUser(user) {
                const indexUser = this.users.map(u => u.id).indexOf(user.id);
                if (indexUser > -1) {
                    this.users.splice(indexUser, 1, user);
                }
            },
            editRow(user) {
                this.$modal.show(ModalUser, {
                    user: user,
                    callback: this.updateUser
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 500,
                });
            },
            deleteUser(userId) {
                return () => {
                    http_request.post('/delete_user', {id: userId})
                        .then((request) => {
                            console.log(request.data);
                            const indexUser = this.users.map(user => user.id).indexOf(userId);
                            if (indexUser > -1) {
                                this.users.splice(indexUser, 1);
                            }
                        })
                }
            },
            deleteRow(user) {
                this.$modal.show(ModalAttention,
                    {
                        alertMsg: `Вы уверены, что хотите удалить пользователя "${user.fullname}"?`,
                        callback: this.deleteUser(user.id)
                    }, {
                        height: 'auto',
                        pivotY: 0.2,
                        width: 515,
                    });
            },
            getUsers() {
                let dataForSend = {
                    part: {
                        from: this.users.length,
                        count: 15,
                    },
                    find: {
                        mask: this.mask,
                    },
                    role: this.activeAdminTab
                };

                console.log('/get_users', dataForSend);
                http_request.post('/get_users', dataForSend)
                    .then((request) => {
                        console.log(request.data);
                        this.users.push(...request.data.users);
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                    });
            }
        },
        watch: {
            mask(){
                this.users.splice(0);
                this.getUsers();
            },
            activeAdminTab() {
                this.users.splice(0);
                this.getUsers();
            }
        },
        mounted() {
            this.getUsers();
        }
    }
</script>

<style scoped>

</style>

