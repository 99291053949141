<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Обратная связь
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-tip-grid">
                <div class="modal-tip-icon">
                    <SVGPhone style="width: 72px; height: 72px"/>
                </div>
                <div class="modal-tip-text">
                    Наши менеджеры всегда готовы проконсультировать Вас! Просто оставьте заявку и вскоре Вам перезвонят.
                </div>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-form-grid">
                <div class="modal-form-grid-label">
                    Ваше имя
                </div>
                <input v-model="name" class="modal-form-grid-input" type="text" placeholder="Как к Вам обращаться?">
                <div class="modal-form-grid-label">
                    Ваш телефон
                </div>
                <input v-model="phoneNumber" class="modal-form-grid-input" type="text" placeholder="+7 (***)-***-**-**">
            </div>
        </div>
        <div class="modal-data-container">
            <div class="blue-button modal-accept-button" v-on:click="sendMail">Заказать звонок</div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import SVGPhone from "../../SVGMP/SVGPhone";
    import {http_request} from "../../../httpConfig";
    export default {
        name: "ModalFeedback",
        components: {SVGPhone, SVGClose},
        data() {return {
          name: null,
          phoneNumber: null,
        }},
        methods: {
          sendMail() {
            const mail_data = {
              "name": this.name,
              "phoneNumber": this.phoneNumber,
              "email": "",
              "city": "",
            }
            http_request.post('/send_email', mail_data)
                .then((request) => {
                  // console.log(request.data);
                  this.$emit('close');
                });
          }
        }
    }
</script>

<style scoped>

</style>
