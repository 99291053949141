<template>
    <svg width="28" height="65" viewBox="0 0 28 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.65262 1.50113C4.5 0.289731 6.97963 0.805297 8.19103 2.65268L25.7324 29.4033C26.9333 31.2346 26.9333 33.6039 25.7324 35.4352L8.19103 62.1858C6.97963 64.0332 4.5 64.5488 2.65263 63.3374C0.805246 62.126 0.289679 59.6463 1.50108 57.799L16.5254 34.8869C17.5079 33.3885 17.5079 31.45 16.5254 29.9516L1.50108 7.03953C0.289679 5.19215 0.805246 2.71252 2.65262 1.50113Z" fill="white" fill-opacity="currentOpacity" stroke="#4C5459"/>
    </svg>
</template>

<script>
export default {
    name: "SVGArrowSwitchPhotoRight"
}
</script>

<style scoped>

</style>