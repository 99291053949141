var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "22",
        viewBox: "0 0 32 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        staticClass: "small-loader-path",
        attrs: {
          d:
            "M1.38823 5.23373L8.0408 1.23985C8.28773 1.09161 8.59528 1.08753 8.84605 1.22919L15.5299 5.00473C15.7725 5.14178 16.0689 5.14272 16.3124 5.00722L23.043 1.26134C23.2894 1.12424 23.5897 1.12693 23.8336 1.26842L30.6015 5.19533C30.8482 5.33846 31 5.60209 31 5.88729V17.4636C31 17.9054 30.6418 18.2636 30.2 18.2636H16.7204C16.2786 18.2636 15.9204 18.6218 15.9204 19.0636V20.0323C15.9204 20.5344 15.4632 20.9124 14.97 20.818L1.64954 18.2672C1.27258 18.1951 1 17.8653 1 17.4815V5.91962C1 5.63868 1.14736 5.37833 1.38823 5.23373Z",
          stroke: "#4C5459",
          "stroke-width": "1.2"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }