<template>
    <div>
        <vue-custom-scrollbar class="admin-scrollable-workspace">
            <div class="admin-card">
                <div class="admin-card-head">
                    Параметры сервера
                </div>
                <div class="admin-card-body">
                    <div class="admin-card-graphs">
                        <div class="admin-graph-container">
<!--                            <SVGScalingArch class="admin-graph"/>-->
                            <div class="diagram"
                                 :style="{'--angle': 'rotate('+ (-180 + (CPUGraphPercent*180)/100) + 'deg)'}">
                                <span class="overlay"></span>
                            </div>
                            <div class="admin-graph-info-anchor">
                                <SVGCPU class="admin-graph-icon"/>
                                <div class="admin-graph-label">Загрузка CPU</div>
                                <div class="admin-graph-value">{{CPUGraphPercent}}%</div>
                            </div>
                        </div>
                        <div class="admin-graph-container">
<!--                            <SVGScalingArch class="admin-graph"/>-->
                            <div class="diagram"
                                 :style="{'--angle': 'rotate('+ (-180 + (RAMGraphPercent*180)/100) + 'deg)'}">
                                <span class="overlay"></span>
                            </div>
                            <div class="admin-graph-info-anchor">
                                <SVGRAM class="admin-graph-icon"/>
                                <div class="admin-graph-label">Использование RAM</div>
                                <div class="admin-graph-value">{{RAMGraphPercent}}%</div>
                            </div>
                        </div>
                        <div class="admin-graph-container">
<!--                            <SVGScalingArch class="admin-graph"/>-->
                            <div class="diagram"
                                 :style="{'--angle': 'rotate('+ (-180 + (HDDGraphPercent*180)/100) + 'deg)'}">
                                <span class="overlay"></span>
                            </div>
                            <div class="admin-graph-info-anchor">
                                <SVGHDD class="admin-graph-icon"/>
                                <div class="admin-graph-label">Использование хранилища</div>
                                <div class="admin-graph-value">{{HDDUsed}}/{{HDDTotal}} Гб</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="admin-card">
                <div class="admin-card-head">
                    Системные переменные
                </div>
                <div class="admin-card-body" style="border: none">
                    <table class="admin-table">
                        <thead>
                        <tr class="admin-table-head-tr">
                            <th>№</th>
                            <th>Название</th>
                            <th>Значение</th>
                            <th>Значение по умолчанию</th>
                            <th class="admin-table-options-head">Опции</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value, index) in variables" class="admin-table-line">
                            <td>
                                <span class="">{{index+1}}</span>
                            </td>
                            <td>
                                <span>{{value.name}}</span>
                            </td>
                            <td>
                                <span>{{value.value}}</span>
                            </td>
                            <td>
                                <span>{{value.default}}</span>
                            </td>
                            <td>
                                <div class="admin-table-options">
                                    <div class="admin-table-option" v-on:click="editRow(value)">
                                        <SVGEdit/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </vue-custom-scrollbar>
        <div class="admin-logs-container">
            <div class="admin-logs-head">
                <span>Логи событий</span>
                <span class="link-underline" v-on:click="openLogsWindow">открыть в окне</span>
            </div>
            <div class="admin-logs-body">
                <vue-custom-scrollbar class="admin-scrollable-logs">
                    <table class="admin-logs-table">
                        <thead>
                            <tr class="admin-logs-table-head">
                                <th style="padding-left: 30px">Дата</th>
<!--                                <th>Время</th>-->
                                <th>Пользователь</th>
                                <th>Таблица: Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(log, index) in logs" v-bind:class="{'line': index % 2 === 0}">
                                <td>
                                    <span style="padding-left: 30px">{{log.date}}</span>
                                </td>
<!--                                <td>-->
<!--                                    <span>{{log.date}}</span>-->
<!--                                </td>-->
                                <td>
                                    <span>{{log.user.fullname}}</span>
                                </td>
                                <td style="width: 50%">
                                    <span>{{log.tablename}}: </span>
                                    <span class="bold-text">{{log.metadata.action}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </vue-custom-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import SVGRefresh from "../../../SVGMP/SVGRefresh";
    import SVGEdit from "../../../SVGMP/SVGEdit";
    import SVGScalingArch from "../../../SVGMP/SVGScalingArch";
    import SVGCPU from "../../../SVGMP/SVGCPU";
    import SVGRAM from "../../../SVGMP/SVGRAM";
    import SVGHDD from "../../../SVGMP/SVGHDD";
    import ModalLogs from "../../../ModalMP/ModalAdmin/ModalLogs";
    import ModalAttention from "../../../ModalMP/ModalAdmin/ModalAttention";
    import ModalVariable from "../../../ModalMP/ModalAdmin/ModalVariable";
    import {http_request} from "../../../../httpConfig";

    export default {
        name: "AdminWorkspaceAdministrating",
        components: {
            SVGHDD,
            SVGRAM,
            SVGCPU,
            SVGScalingArch,
            SVGEdit,
            vueCustomScrollbar
        },
        data() {
            return {
                CPUGraphPercent: 34,
                RAMGraphPercent: 78,
                HDDGraphPercent: 42,
                HDDTotal: 0,
                HDDFree: 0,
                HDDUsed: 0,
                bytesInGb: 1024*1024*1024,
                variables: [],
                logs: []
            }
        },
        methods: {
            requestServerInfo() {
                http_request.post('/get_server_info', {})
                    .then((request) => {
                        this.CPUGraphPercent = request.data.cpu_load;
                        this.RAMGraphPercent = request.data.free_memory;
                        this.HDDGraphPercent = request.data.disk_usage[3];
                        this.HDDTotal = (request.data.disk_usage[0]/this.bytesInGb).toFixed(2);
                        this.HDDFree = (request.data.disk_usage[1]/this.bytesInGb).toFixed(2);
                        this.HDDUsed = (request.data.disk_usage[2]/this.bytesInGb).toFixed(2);
                        // console.log(request.data);
                    })
            },
            updateVariable(variable) {
                const indexVariable = this.variables.map(v => v.name).indexOf(variable.name);
                if (indexVariable > -1) {
                    this.variables.splice(indexVariable, 1, variable);
                }
            },
            editRow(data) {
                this.$modal.show(ModalVariable,
                    {
                        variable: data,
                        callback: this.updateVariable
                    }, {
                        height: 'auto',
                        pivotY: 0.2,
                        width: 500,
                    });
            },
            resetRow(data) {
                this.$modal.show(ModalAttention,
                    {alertMsg: 'Вы уверены, что хотите сбросить параметр "' + data.name + '" на значение по умолчанию? '}, {
                        height: 'auto',
                        pivotY: 0.2,
                        width: 515,
                    });
            },
            openLogsWindow() {
                this.$modal.show(ModalLogs, {
                    logs: this.logs
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 1000,
                });
            },
        },
        mounted() {
            this.continuousRequest = () => {
                this.requestServerInfo();
                setTimeout(()=>{
                    this.continuousRequest();
                }, 1000);
            }
            this.continuousRequest();
            http_request.post('/get_config', {})
                .then((request) => {
                    console.log(request.data);
                    this.variables.push(...request.data.config)
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });
            http_request.post('/get_logs', {})
                .then((request) => {
                    console.log(request.data);
                    this.logs.push(...request.data.logs)
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });
        },
    }
</script>

<style>
    :root {
        --angle: rotate(0deg);
    }
    .diagram {
        margin: 0 auto;
        position: relative;
        width: 200px;
        height: 100px;
        border-radius: 200px 200px 0 0;
        overflow: hidden;
    }
    .diagram::after {
        transform: var(--angle);
        background: #baff00;
        transform-origin: center bottom;
    }
    .diagram::before {
        border: 30px solid #0075A9;
    }
    .diagram .overlay{
        top: 30px;
        left: 30px;
        width: calc(100% - 60px);
        height: calc(200% - 60px);
        border-radius: 100%;
        background: white;
        z-index: 1;
    }
    .diagram *,
    .diagram::before,
    .diagram::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: inherit;
        box-sizing: border-box;
    }
</style>
