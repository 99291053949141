<template>
    <div class="thenavbar">
        <div class="logo-container" v-on:click="toIndex">
            <SVGMetallProfil/>
        </div>
        <div class="navbar-text-container">
            <span class="mh-10 white-text">Мы можем Вам помочь!</span>
            <span class="mh-10 white-text bold-text">8 800 100-10-01</span>
            <span class="mh-10 white-text link-underline" v-on:click="openModalFeedback">Перезвоните мне</span>
        </div>
    </div>
</template>

<script>
    import SVGNavbarUser from "../../SVG/SVGNavbarUser.vue";
    import SVGDialog from "../../SVG/SVGDialog";
    import {modalMethods, urlForStaticVue} from "../../common";
    import MetallProfilLogo from "../../SVGMP/SVGMetallProfilLogo";
    import ModalFeedback from "../../ModalMP/ModalLite/ModalFeedback";
    import SVGMetallProfil from "../../SVGMP/SVGMetallProfil";

    export default {
        name: "TheLiteNavbar",
        mixins: [modalMethods, urlForStaticVue],
        components: {
            SVGMetallProfil,
            ModalFeedback,
            MetallProfilLogo,
            SVGDialog,
            SVGNavbarUser
        },
        computed:{
            userName(){
                const name = this.$store.state.profile.userName;
                if(name){
                    return name;
                } else {
                    return 'Вход для дилеров';
                }
            }
        },
        methods: {
            openModalFeedback() {
                this.$modal.show(ModalFeedback, {}, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 515,
                });
            },
            toIndex() {
                this.$router.push({name: "liteProjects"}).catch(() => {})
            }
        }
    }
</script>

<style scoped>

</style>
