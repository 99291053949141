<template>
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="6" width="12" height="7.63636" rx="2" fill="currentColor"/>
        <path d="M1.63647 2C1.63647 0.89543 2.53191 0 3.63647 0H8.36375C9.46832 0 10.3637 0.895431 10.3637 2V6H8.61829V1.71429H3.38193V6H1.63647V2Z" fill="currentColor"/>
        <circle cx="6.00033" cy="8.72732" r="1.09091" fill="#4C5459"/>
        <rect x="5.45435" y="9.27264" width="1.09091" height="2.18182" fill="#4C5459"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGLock"
    }
</script>

<style scoped>

</style>
