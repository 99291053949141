var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "workspace-loader-container" }, [
    _c(
      "div",
      [
        _c("SVGWorkspaceLoader"),
        _vm._v(" "),
        _c("div", { staticClass: "workspace-loader-text-container" }, [
          _c("div", { staticClass: "workspace-loader-text" }, [
            _vm._v("Идёт загрузка")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !!_vm.progressPercent,
                  expression: "!!progressPercent"
                }
              ],
              staticClass: "workspace-loader-text"
            },
            [_vm._v(_vm._s(_vm.progressPercent) + " %")]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }