import store from "../../store";
import { getPointOnLine } from "../libs/lines";
import {geometry, solver} from 'view-solver'

export const perspectiveLineGlobal = function (point1, point2, fabricJS, color) {
    let d = 100;
    // let color = "#173af3";
    this.fabricJS = fabricJS
    this.x1 = point1.x;
    this.y1 = point1.y;
    this.x2 = point2.x;
    this.y2 = point2.y;
    let scale = 3;
    if (store.state.route.query.view)
        scale = 3/JSON.parse(store.state.route.query.view)[0]

    this.fabricObjects = [];

    this.getNewPoints = () => {
        let newPoint1 = getPointOnLine({x: this.x1, y: this.y1}, {x: this.x2, y: this.y2}, -d);
        let newPoint2 = getPointOnLine({x: this.x2, y: this.y2}, {x: this.x1, y: this.y1}, -d);
        this._x1 = newPoint1.x;
        this._y1 = newPoint1.y;
        this._x2 = newPoint2.x;
        this._y2 = newPoint2.y;
    }

    this._set = (key, value) => {
        this[key] = value;
    };

    this.set = (obj) => {
        for (let prop in obj) {
            this._set(prop, obj[prop]);
        }
        this.getNewPoints();
        this.fabricObject.set({
                x1: this._x1,
                y1: this._y1,
                x2: this._x2,
                y2: this._y2
            }
        )
    }

    this.getNewPoints();


    this.fabricPoint1 = new fabric.Circle({
        radius: 6,
        originX: 'center',
        originY: 'center',
        left: this.x1,
        top: this.y1,
        strokeWidth: 1,
        stroke: color,
        fill: color,
        point1: true,
        id: this.id,
        hasBorders: false,
    });

    this.fabricPoint2 = new fabric.Circle({
        radius: 6,
        originX: 'center',
        originY: 'center',
        left: this.x2,
        top: this.y2,
        strokeWidth: 1,
        stroke: color,
        fill: color,
        point2: true,
        id: this.id,
        hasBorders: false,
    });

    this.fabricLineDashed = new fabric.Line(
        [
            this._x1,
            this._y1,
            this._x2,
            this._y2
        ],
        {
            originX: 'center',
            originY: 'center',
            stroke: color,
            selectable: false,
            strokeDashArray: [5, 10],
            strokeWidth: 3,
            evented: false,
        })

    this.fabricLine = new fabric.Line(
        [
            this.x1,
            this.y1,
            this.x2,
            this.y2
        ],
        {
            originX: 'center',
            originY: 'center',
            stroke: color,
            // selectable: false,
            strokeWidth: 3,
            // evented: false,
            perPixelTargetFind: true,
            hasBorders: false,
            id: this.id,
        })

    this.fabricLine.toObject = () => { return {
        x1: this.fabricLine.left-(this.fabricLine.x2 - this.fabricLine.x1)/2,
        y1: this.fabricLine.top-(this.fabricLine.y2 - this.fabricLine.y1)/2,
        x2: this.fabricLine.left + (this.fabricLine.x2 - this.fabricLine.x1)/2,
        y2: this.fabricLine.top + (this.fabricLine.y2 - this.fabricLine.y1)/2 }; };

    this.fabricObjects.push(this.fabricLineDashed);
    this.fabricObjects.push(this.fabricLine);
    this.fabricObjects.push(this.fabricPoint1);
    this.fabricObjects.push(this.fabricPoint2);

    this.fabricObjects.forEach((object)=>{
        object.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            bl: false,
            br: false,
            tl: false,
            tr: false,
            mtr: false,
        });
        this.fabricJS.add(object);
    })

    this.objectMovingHandler =  (event) => {
        if (event.target.id !== this.id)
            return

        if (event.target === this.fabricPoint1) {
            if (this.id == 2) {
                this.x2 = this.x2 - this.x1 + event.target.left;
                this.y2 = this.y2 - this.y1 + event.target.top;
                this.fabricPoint2.set({
                    left: this.x2,
                    top: this.y2,
                });
                this.fabricPoint2.setCoords();
            }
            this.x1 = event.target.left;
            this.y1 = event.target.top;
        }

        if (event.target === this.fabricPoint2) {
            this.x2 = event.target.left;
            this.y2 = event.target.top;
        }

        if (event.target === this.fabricLine) {
            let position = event.target.toObject()
            this.x1 = position.x1;
            this.y1 = position.y1;
            this.x2 = position.x2;
            this.y2 = position.y2;
            this.fabricPoint1.set({
                left: this.x1,
                top: this.y1,
            });
            this.fabricPoint2.set({
                left: this.x2,
                top: this.y2,
            });
            this.fabricPoint1.setCoords();
            this.fabricPoint2.setCoords();
            this.fabricPoint1.bringToFront();
            this.fabricPoint2.bringToFront();
            this.fabricJS.discardActiveObject();
        } else {
            this.fabricLine.set({
                x1: this.x1,
                y1: this.y1,
                x2: this.x2,
                y2: this.y2
            })
            this.fabricLine.setCoords();
        }



        let newPoint1 = getPointOnLine({x: this.x1, y: this.y1}, {x: this.x2, y: this.y2}, -d);
        let newPoint2 = getPointOnLine({x: this.x2, y: this.y2}, {x: this.x1, y: this.y1}, -d);
        this.fabricLineDashed.set({
            x1: newPoint1.x,
            y1: newPoint1.y,
            x2: newPoint2.x,
            y2: newPoint2.y
        })
        this.fabricLineDashed.setCoords();

        this.fabricJS.renderAll();
    }

    this.fabricJS.on('mouse:up', this.mouseUpHandler);


    this.fabricJS.on('object:moving', this.objectMovingHandler);

    this.destroy = () => {
        this.fabricJS.off('mouse:up', this.mouseUpHandler)
        this.fabricJS.off('object:moving', this.objectMovingHandler);
        this.fabricObjects.forEach((object)=> {
            this.fabricJS.remove(object);
        });
        this.fabricJS.requestRenderAll();
    }


    return this
}

