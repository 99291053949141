<template>
    <div>
        <div class="modal-close-btn-point">
<!--            <div class="modal-close-btn-container" v-on:click="$emit('close')">-->
<!--                <SVGModalClose/>-->
<!--            </div>-->
        </div>
        <div class="ap-modal-title-big">
            Внимание!
        </div>
        <div class="modal-borderline no-margin-top"></div>
        <div class="ap-modal-element">{{text}}</div>
        <div class="confirmation-btn-grid">
            <div class="ap-modal-button profile-btn hide-underline btn-ok" v-on:click="accept">
                Ок
            </div>
        </div>
    </div>
</template>

<script>
    import SVGModalClose from "../SVG/SVGModalClose";
    export default {
        name: "ModalConfirmation",
        components: {
            SVGModalClose
        },
        props: {
            text: {
                type: String
            }
        },
        methods: {
            accept() {
                this.$emit('close');
            }
        }
    }
</script>

<style scoped>
    .btn-ok {
        width: 70px;
        text-align: center;
    }
</style>
