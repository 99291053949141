var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-loader-container" }, [
    _c(
      "div",
      { staticClass: "sidebar-loader-grid" },
      [
        _c("SVGSmallLoader"),
        _vm._v(" "),
        _c("div", { staticClass: "small-loader-text" }, [
          _vm._v("Идёт загрузка...")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }