<template>
    <div class="projects-sidebar">
        <div>
            <div class="sidebar-data-container">
                <div class="mp-sidebar-label">Галерея проектов</div>
            </div>
            <vue-custom-scrollbar class="sidebar-data-container mr-10 sidebar-tags-container">
                <div v-if="selectedTags.length === 0" class="sidebar-tags-container-placeholder">
                    Теги не выбраны
                </div>
                <div v-else v-for="tag in selectedTags" class="pill-tag" v-on:click="selectTag(tag)">{{tag.tag_name}}</div>
            </vue-custom-scrollbar>
            <div class="sidebar-data-container">
                <div class="tag-menu-switch" v-on:click="switchTagsMenu(true)">
                    <SVGExpandBot style="margin-right: 8px"/>
                    Теги
                </div>
            </div>

            <div class="expanded-tags-menu" v-show="tagsMenuStatus">
                <div class="sidebar-data-container">
                    <div class="tag-menu-switch" v-on:click="switchTagsMenu(false)">
                        <SVGCollapseTop style="margin-right: 8px"/>
                        Свернуть
                    </div>
                </div>
                <vue-custom-scrollbar class="sidebar-data-container mr-10 scroll-tags-menu">
<!--                    <div class="mp-sidebar-borderline"></div>-->
                    <template v-for="category in categories_with_tags">
                        <div class="tag-category-grid">
                            <div class="tag-category-label">
                                {{category.tag_category_name}}
                            </div>
                            <div class="tag-category-container">
                                <div class="pill-tag" v-for="tag in category.tags" v-on:click="selectTag(tag)">{{tag.tag_name}}</div>
                            </div>
                        </div>
                        <div class="mp-sidebar-borderline"></div>
                    </template>
                    <div class="tag-category-grid">
                        <div class="tag-category-label">
                            Разное
                        </div>
                        <div  class="tag-category-container">
                            <div class="pill-tag" v-for="tag in uncategorized_tags" v-on:click="selectTag(tag)">{{tag.tag_name}}</div>
                        </div>
                    </div>
                </vue-custom-scrollbar>
            </div>

            <div class="mp-sidebar-borderline"></div>
        </div>
        <vue-custom-scrollbar class="projects-list-container" @ps-y-reach-end="scrollReachEnd">
            <div class="sidebar-data-container projects-list">
                <div v-for="(project) in projects" class="sidebar-project-preview"
                     :class="{'active-sidebar-project-preview': project.uuid == selectProjectUuid}"
                     v-on:click="openProject(project)">
                    <PreviewImgFromProject :dataProject="project.data_json" class="sidebar-project-preview-image"/>
                </div>
            </div>
        </vue-custom-scrollbar>

        <SmallLoader v-show="requestedDataInProgress"/>

<!--        <div>-->
<!--            <div class="mp-sidebar-borderline"></div>-->
<!--            <div class="sidebar-data-container">-->
<!--                <div class="mp-sidebar-text">-->
<!--                    Не нашли подходящий проект?-->
<!--                </div>-->
<!--                <div class="blue-button">-->
<!--                    Создайте свой собственный!-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {urlForStaticVue} from "../../../../common";
    import SVGExpandBot from "../../../../SVGMP/SVGExpandBot";
    import SVGCollapseTop from "../../../../SVGMP/SVGCollapseTop";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import PreviewImgFromProject from "./PreviewImgFromProject";
    import SmallLoader from "../../../../ComponentsMP/Loaders/SmallLoader";
    import {http_request} from "../../../../../httpConfig";

    export default {
        name: "SidebarProjectsLite",
        mixins: [urlForStaticVue],
        components: {
            SmallLoader,
            PreviewImgFromProject,
            SVGCollapseTop,
            SVGExpandBot,
            vueCustomScrollbar
        },
        props: {
            selectProjectUuid: {
                type: String,
            },
            selectedTagPermanentId: {
                type: Number
            }
        },
        data() {
            return {
                tagsMenuStatus: false,
                projects: [],
                requestedDataInProgress: false,
                allProjectsLoaded: false,
                categories_with_tags: [],
                uncategorized_tags: [],
                selectedTags: []
            }
        },
        methods: {
            scrollReachEnd() {
                this.getProjects();
            },
            openProject(project) {
                const idsPhotos = Object.keys(project.data_json.photoList);
                if (idsPhotos.length === 0) {
                    alert('В данном проекте нет фото!');
                    return;
                }
                this.$emit('selectProject', project);
            },
            openFirstProject(){
                if(!+this.selectProjectUuid && this.projects.length > 0) {
                    this.openProject(this.projects[0]);
                }
            },
            switchTagsMenu(status) {
                this.tagsMenuStatus = status
            },
            compareDataRequest(data1, data2) {
                if (!(data1 && data2)) {
                    return false;
                }
                const projectsLength1 = data1['projectsLength'];
                const selectedTagsIds1 = data1['selectedTagsIds'];
                const projectsLength2 = data2['projectsLength'];
                const selectedTagsIds2 = data2['selectedTagsIds'];

                const compareArr = (a1, a2) => {
                    return a1.length === a2.length && a1.every((v, i) => v === a2[i])
                };

                return projectsLength1 === projectsLength2 && compareArr(selectedTagsIds1, selectedTagsIds2);
            },
            async getProjects() {
                const projectsLength = this.projects.length;
                let selectedTagsIds = this.selectedTags.map(tag => tag.id);
                selectedTagsIds.push(this.selectedTagPermanentId);
                const dataForCompare = {
                    projectsLength,
                    selectedTagsIds
                };

                // В методе compareDataRequest производится сравнение того, что зпросили, с тем, что собираемся запросить
                // Если данные для запроса одинаковые, то запрос не производится.
                if (this.compareDataRequest(this.requestedDataInProgress, dataForCompare) || this.allProjectsLoaded) {
                    return;
                }

                let dataForSend = {
                    part: {
                        from: projectsLength,
                        count: 15,
                    },
                    tags: selectedTagsIds
                };
                this.requestedDataInProgress = dataForCompare;
                console.log('/get_project_lite', dataForSend);
                await http_request.post('/get_project_lite', dataForSend)
                    .then((request) => {
                        console.log(request.data);
                        if (this.compareDataRequest(this.requestedDataInProgress, dataForCompare)) {
                            this.requestedDataInProgress = null;
                            if (request.data.projects.length === 0) {
                                this.allProjectsLoaded = true;
                                return;
                            }


                            request.data.projects.sort((a, b)=>{
                              // console.log(a,b)
                              return a.order - b.order
                            })

                            this.projects.push(...request.data.projects);
                            this.openFirstProject();
                        } else {
                            console.warn('Пришли уже неактуальные данные.', request.data.projects)
                        }
                    })
                    .catch((error) => {
                        if (this.compareDataRequest(this.requestedDataInProgress, dataForCompare)) {
                            this.requestedDataInProgress = null;
                        }
                        console.log(error);
                        console.log(error.response);
                    });
            },
            checkTagInSelected(newTag) {
                const indexTagInProjects = this.selectedTags.findIndex(tag => tag.id == newTag.id);
                return indexTagInProjects > -1;
            },
            selectTag(newTag) {
                const indexTagInProjects = this.selectedTags.findIndex(tag => tag.id == newTag.id);
                if (indexTagInProjects > -1) {
                    this.selectedTags.splice(indexTagInProjects, 1);
                } else {
                    this.selectedTags.push(newTag)
                }
            },
            getCategoriesWithTags() {
                this.categories_with_tags.length = 0;
                this.uncategorized_tags.length = 0;
                http_request.post('/get_categories_with_tags', {})
                    .then((request) => {
                        console.log(request.data);
                        this.categories_with_tags.push(...request.data.tag_categories);
                        this.uncategorized_tags.push(...request.data.uncategorized.filter(tag => !tag.permanent));
                    })
                    .catch((err) => {
                        alert('Ошибка при загрузке списка тегов.');
                        console.log(err);
                    })
            },
            resetParamsAndGetProjects(){
                this.projects = [];
                this.allProjectsLoaded = false;
                this.getProjects();
            }
        },
        watch: {
            selectedTags() {
                this.resetParamsAndGetProjects();
            },
            selectedTagPermanentId() {
                this.resetParamsAndGetProjects();
            }
        },
        async mounted() {
            this.getProjects();
            this.getCategoriesWithTags();
            // this.$store.dispatch('UI/loadedProjectList/GET_PROJECTS');
            // this.$store.dispatch('UI/loadedProjectList/GET_PROJECT_BY_UUID', '24b0ea60-640c-43fa-a8fb-2a4fcdaba2a1');
        },
    }
</script>

<style scoped>
    .mr-10 {
        margin-right: 10px;
    }

</style>
