var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vue-custom-scrollbar",
        { staticClass: "admin-scrollable-workspace" },
        [
          _c("div", { staticClass: "admin-card" }, [
            _c("div", { staticClass: "admin-card-head" }, [
              _vm._v(
                "\n                    Параметры сервера\n                "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "admin-card-body" }, [
              _c("div", { staticClass: "admin-card-graphs" }, [
                _c("div", { staticClass: "admin-graph-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "diagram",
                      style: {
                        "--angle":
                          "rotate(" +
                          (-180 + (_vm.CPUGraphPercent * 180) / 100) +
                          "deg)"
                      }
                    },
                    [_c("span", { staticClass: "overlay" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "admin-graph-info-anchor" },
                    [
                      _c("SVGCPU", { staticClass: "admin-graph-icon" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "admin-graph-label" }, [
                        _vm._v("Загрузка CPU")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "admin-graph-value" }, [
                        _vm._v(_vm._s(_vm.CPUGraphPercent) + "%")
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "admin-graph-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "diagram",
                      style: {
                        "--angle":
                          "rotate(" +
                          (-180 + (_vm.RAMGraphPercent * 180) / 100) +
                          "deg)"
                      }
                    },
                    [_c("span", { staticClass: "overlay" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "admin-graph-info-anchor" },
                    [
                      _c("SVGRAM", { staticClass: "admin-graph-icon" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "admin-graph-label" }, [
                        _vm._v("Использование RAM")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "admin-graph-value" }, [
                        _vm._v(_vm._s(_vm.RAMGraphPercent) + "%")
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "admin-graph-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "diagram",
                      style: {
                        "--angle":
                          "rotate(" +
                          (-180 + (_vm.HDDGraphPercent * 180) / 100) +
                          "deg)"
                      }
                    },
                    [_c("span", { staticClass: "overlay" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "admin-graph-info-anchor" },
                    [
                      _c("SVGHDD", { staticClass: "admin-graph-icon" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "admin-graph-label" }, [
                        _vm._v("Использование хранилища")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "admin-graph-value" }, [
                        _vm._v(
                          _vm._s(_vm.HDDUsed) +
                            "/" +
                            _vm._s(_vm.HDDTotal) +
                            " Гб"
                        )
                      ])
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "admin-card" }, [
            _c("div", { staticClass: "admin-card-head" }, [
              _vm._v(
                "\n                    Системные переменные\n                "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "admin-card-body",
                staticStyle: { border: "none" }
              },
              [
                _c("table", { staticClass: "admin-table" }, [
                  _c("thead", [
                    _c("tr", { staticClass: "admin-table-head-tr" }, [
                      _c("th", [_vm._v("№")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Название")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Значение")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Значение по умолчанию")]),
                      _vm._v(" "),
                      _c("th", { staticClass: "admin-table-options-head" }, [
                        _vm._v("Опции")
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.variables, function(value, index) {
                      return _c("tr", { staticClass: "admin-table-line" }, [
                        _c("td", [_c("span", {}, [_vm._v(_vm._s(index + 1))])]),
                        _vm._v(" "),
                        _c("td", [_c("span", [_vm._v(_vm._s(value.name))])]),
                        _vm._v(" "),
                        _c("td", [_c("span", [_vm._v(_vm._s(value.value))])]),
                        _vm._v(" "),
                        _c("td", [_c("span", [_vm._v(_vm._s(value.default))])]),
                        _vm._v(" "),
                        _c("td", [
                          _c("div", { staticClass: "admin-table-options" }, [
                            _c(
                              "div",
                              {
                                staticClass: "admin-table-option",
                                on: {
                                  click: function($event) {
                                    return _vm.editRow(value)
                                  }
                                }
                              },
                              [_c("SVGEdit")],
                              1
                            )
                          ])
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "admin-logs-container" }, [
        _c("div", { staticClass: "admin-logs-head" }, [
          _c("span", [_vm._v("Логи событий")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "link-underline",
              on: { click: _vm.openLogsWindow }
            },
            [_vm._v("открыть в окне")]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "admin-logs-body" },
          [
            _c(
              "vue-custom-scrollbar",
              { staticClass: "admin-scrollable-logs" },
              [
                _c("table", { staticClass: "admin-logs-table" }, [
                  _c("thead", [
                    _c("tr", { staticClass: "admin-logs-table-head" }, [
                      _c("th", { staticStyle: { "padding-left": "30px" } }, [
                        _vm._v("Дата")
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Пользователь")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Таблица: Действие")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.logs, function(log, index) {
                      return _c("tr", { class: { line: index % 2 === 0 } }, [
                        _c("td", [
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "30px" } },
                            [_vm._v(_vm._s(log.date))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(log.user.fullname))])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { width: "50%" } }, [
                          _c("span", [_vm._v(_vm._s(log.tablename) + ": ")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "bold-text" }, [
                            _vm._v(_vm._s(log.metadata.action))
                          ])
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }