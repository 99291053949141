var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-background" }, [
    _c("div", { staticClass: "modal-head-grid" }, [
      _c("div", { staticClass: "modal-label" }, [
        _vm._v("\n            Добавить модель\n        ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-close-btn-mp",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("SVGClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container pv-23" }, [
      _c("div", { staticClass: "two-cols-modal-grid" }, [
        _c("div", { staticClass: "modal-form-grid" }, [
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Название\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Размер\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.scale,
                expression: "scale"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.scale },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.scale = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Позиция X\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.positionX,
                expression: "positionX"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.positionX },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.positionX = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Позиция Y\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.positionY,
                expression: "positionY"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.positionY },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.positionY = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Позиция Z\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.positionZ,
                expression: "positionZ"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.positionZ },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.positionZ = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-form-images-container" }, [
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Повторять вправо\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.repeatX,
                expression: "repeatX"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.repeatX },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.repeatX = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Повторять вверх\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.repeatZ,
                expression: "repeatZ"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.repeatZ },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.repeatZ = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Поворот X\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.rotationX,
                expression: "rotationX"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.rotationX },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.rotationX = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Поворот Y\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.rotationY,
                expression: "rotationY"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.rotationY },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.rotationY = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                    Поворот Z\n                ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.rotationZ,
                expression: "rotationZ"
              }
            ],
            staticClass: "modal-form-grid-input",
            domProps: { value: _vm.rotationZ },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.rotationZ = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-checkbox-grid" }, [
            _c("div", { staticClass: "form-input-checkbox-name" }, [
              _vm._v(
                "\n                        Сменить ориентацию  модели\n                    "
              )
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.changeOrientation,
                  expression: "changeOrientation"
                }
              ],
              staticClass: "form-input-checkbox",
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.changeOrientation)
                  ? _vm._i(_vm.changeOrientation, null) > -1
                  : _vm.changeOrientation
              },
              on: {
                change: function($event) {
                  var $$a = _vm.changeOrientation,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.changeOrientation = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.changeOrientation = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.changeOrientation = $$c
                  }
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-form-images-container" }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticStyle: { "font-size": "12px" },
              attrs: { href: _vm.urlForFileStorage(_vm.model3dSrc) }
            },
            [_vm._v(_vm._s(_vm.model3dSrc))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "blue-button modal-form-img-button",
              on: {
                click: function($event) {
                  return _vm.$refs.file2.click()
                }
              }
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.model3dSrc ? "Обновить модель" : "Загрузить модель"
                  ) +
                  "\n                "
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            ref: "file2",
            attrs: {
              type: "file",
              id: "fileInput3DModal",
              accept: ".glb",
              hidden: ""
            },
            on: {
              change: function($event) {
                return _vm.handleFileUpload3D($event)
              }
            }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-lower-buttons-grid" }, [
        _c(
          "div",
          {
            staticClass: "blue-button modal-accept-button",
            on: { click: _vm.save }
          },
          [_vm._v("Сохранить\n            ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blue-outline-button modal-accept-button",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v("Отмена")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }