<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6.85274" cy="2.01704" r="1.51704" fill="currentColor" stroke="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.85803 0.137329L12.5421 12.4459L12.5892 14L11.4001 12.9733L6.85803 3.13774L2.31596 12.9733L1.11597 14L1.17392 12.4459L6.85803 0.137329Z" fill="currentColor"/>
        <path d="M1 6.47248C1.53119 7.57574 2.3634 8.5064 3.40067 9.15712C4.43793 9.80785 5.638 10.1521 6.86248 10.1503C8.08696 10.1485 9.28598 9.80055 10.3213 9.1467C11.3566 8.49285 12.186 7.55969 12.7138 6.45483" stroke="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGCompass"
    }
</script>

<style scoped>

</style>
