import {getSlope} from "../fabric/libs/getPerspectivePoints"
import {Segment} from "@mathigon/fermat";

export const fermatCollection = function () {
    this.collection = [];
    // this.verticals = [];
    this.horizontals = [];
    this.potentialVPs = [];
    this.maxArea = 0;
    this.maxFermat = undefined;

    this.add = (fermat) => {
        this.collection.push(fermat);
        // this.sortEdges(fermat);
        if (fermat.area > this.maxArea) {
            this.maxArea = fermat.area;
            this.maxFermat = fermat;
        }
    }

    return this
}

export const getEdgesFiltered = function (fermat) {
    if (fermat.points.length === 4) {
        return getEdgesFiltered4E(fermat);
    }
    else if (fermat.points.length > 4) {
        return getEdgesFilteredSE(fermat);
    }
    return undefined;
}

const getEdgesFilteredSE = function (fermat) {
    let edges = fermat.edges;
    let verticals = [];
    for (let edge = 0; edge < edges.length; ++edge) {
        // console.log("slope", getSlope(edges[edge]));
        if (getSlope(edges[edge]) >= 3) {
            verticals.push(edges[edge]);
        }
    }
    if (verticals.length === 2) {
        let topPoints = [];
        let bottomPoints = [];
        for (let v in verticals) {
            if (verticals[v].p1.y > verticals[v].p2.y) {
                topPoints.push(verticals[v].p1);
                bottomPoints.push(verticals[v].p2);
            }
            else {
                topPoints.push(verticals[v].p2);
                bottomPoints.push(verticals[v].p1);
            }
        }
        return {
            "horizontals": [ new Segment(topPoints[0], topPoints[1]), new Segment(bottomPoints[0], bottomPoints[1])],
            "verticals": verticals
        }
    }

    return undefined;
}

const getEdgesFiltered4E = function (fermat) {
    let edges = fermat.edges;
    let horizontals = [];
    let verticals = [];
    for (let edge = 0; edge < edges.length; ++edge) {
        // console.log("slope", getSlope(edges[edge]));
        if (getSlope(edges[edge]) < 3) {  // slope = 3 equals approximately 18 degrees
            horizontals.push(edges[edge]);
        } else {
            verticals.push(edges[edge]);
        }
    }
    return { "horizontals": horizontals, "verticals": verticals};
}
