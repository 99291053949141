import {fabric} from "fabric";

export const addImg = async (url) => {
    return new Promise(resolve => fabric.Image.fromURL(url, (myImg) => {
        myImg.set({
            selectable: false
        });
        resolve(myImg);
    },{crossOrigin: 'Anonymous'}));
}
