<template>
    <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4154 2.71227C11.4154 3.85123 10.4921 4.77454 9.35314 4.77454C8.21418 4.77454 7.29087 3.85123 7.29087 2.71227C7.29087 1.57331 8.21418 0.649999 9.35314 0.649999C10.4921 0.649999 11.4154 1.57331 11.4154 2.71227Z" stroke="currentColor" stroke-width="1.3"/>
        <path d="M13.2708 10.0454C13.2708 7.82622 11.5617 6.02722 9.4535 6.02722C7.34528 6.02722 5.63623 7.82622 5.63623 10.0454" stroke="currentColor" stroke-width="1.3"/>
        <path d="M16.6458 2.69436C16.6458 3.47951 16.0093 4.11601 15.2241 4.11601C14.439 4.11601 13.8025 3.47951 13.8025 2.69436C13.8025 1.9092 14.439 1.2727 15.2241 1.2727C16.0093 1.2727 16.6458 1.9092 16.6458 2.69436Z" stroke="currentColor"/>
        <path d="M17.9999 7.88986C17.9999 6.31756 16.789 5.04297 15.2954 5.04297C13.8017 5.04297 12.5908 6.31756 12.5908 7.88986" stroke="currentColor"/>
        <path d="M5.05497 2.69436C5.05497 3.47951 4.41848 4.11601 3.63332 4.11601C2.84816 4.11601 2.21167 3.47951 2.21167 2.69436C2.21167 1.9092 2.84816 1.2727 3.63332 1.2727C4.41848 1.2727 5.05497 1.9092 5.05497 2.69436Z" stroke="currentColor"/>
        <path d="M6.40909 7.88986C6.40909 6.31756 5.19822 5.04297 3.70455 5.04297C2.21087 5.04297 1 6.31756 1 7.88986" stroke="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGPeople"
    }
</script>

<style scoped>

</style>
