<template>
    <svg width="28" height="65" viewBox="0 0 28 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.4596 1.50113C23.6122 0.289731 21.1326 0.805297 19.9212 2.65268L2.37983 29.4033C1.17896 31.2346 1.17896 33.6039 2.37983 35.4352L19.9212 62.1858C21.1326 64.0332 23.6122 64.5488 25.4596 63.3374C27.307 62.126 27.8226 59.6463 26.6112 57.799L11.5868 34.8869C10.6043 33.3885 10.6043 31.45 11.5868 29.9516L26.6112 7.03953C27.8226 5.19215 27.307 2.71252 25.4596 1.50113Z" fill="white" fill-opacity="currentOpacity" stroke="#4C5459"/>
    </svg>
</template>

<script>
export default {
    name: "SVGArrowSwitchPhotoLeft"
}
</script>

<style scoped>

</style>