import store from "./store";

export const blobToFile = function (theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
};

export const checkTextureOnServer = function (src, cb) {
    return new Promise(resolve => {
        fetch(store.getters[`projectConfiguration/GENERATE_URL`](src), { method: 'HEAD' }).then(res => {
            // console.log(res)
            if (res.ok) {
                resolve(true)
            } else {
                console.error("Image not found on server ", src)
                resolve(false)
            }
        })
    })

}
