var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "30",
        height: "24",
        viewBox: "0 0 30 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M29.7001 10.3996L25.7001 5.09961L22.7001 7.39961L18.1001 17.9996L18.7001 18.6996L29.7001 10.3996Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M17.2001 16.9L17.6001 17.4L23.4001 3.5L17.4001 1L16.1001 3.8L17.2001 16.9Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M8.6999 5.6L16.4999 15.9L14.4999 0L7.8999 0.8L8.6999 5.6Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M5.3 2.30078L0 6.30078L12.8 23.2008L18.1 19.2008L5.3 2.30078ZM12.5 19.7008C12 19.1008 12.1 18.2008 12.8 17.7008C13.4 17.2008 14.3 17.3008 14.8 18.0008C15.3 18.6008 15.2 19.5008 14.5 20.0008C13.9 20.4008 13 20.3008 12.5 19.7008Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }