var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-frame no-mt" }, [
        _c("div", { staticClass: "filter-label-grid" }, [
          _c("div", { staticClass: "filter-label" }, [
            _vm._v("\n                Тип\n            ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "filter-frame-collapse-arrow",
              on: {
                click: function($event) {
                  _vm.displayType = !_vm.displayType
                }
              }
            },
            [_vm.displayType ? _c("SVGCollapseTop") : _c("SVGExpandBot")],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.displayType,
                expression: "displayType"
              }
            ],
            staticClass: "filter-data-container"
          },
          [
            _c(
              "div",
              { staticClass: "filter-data" },
              [
                _vm._l(_vm.productTypes, function(prodType, index) {
                  return [
                    prodType.nof_products > 0 &&
                    (index < _vm.indexBreaker || _vm.productTypesExpanded)
                      ? _c(
                          "div",
                          { staticClass: "filter-data-checkbox-line" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.productTypesSelected,
                                  expression: "productTypesSelected"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: prodType.id,
                                checked: Array.isArray(_vm.productTypesSelected)
                                  ? _vm._i(
                                      _vm.productTypesSelected,
                                      prodType.id
                                    ) > -1
                                  : _vm.productTypesSelected
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.productTypesSelected,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = prodType.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.productTypesSelected = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.productTypesSelected = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.productTypesSelected = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "checkbox-label" }, [
                              _vm._v(_vm._s(prodType.name))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.productTypes.length > _vm.indexBreaker
              ? _c("div", { staticClass: "expand-to-full-container" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.productTypes.length > _vm.indexBreaker &&
                            !_vm.productTypesExpanded,
                          expression:
                            "productTypes.length > indexBreaker && !productTypesExpanded"
                        }
                      ],
                      staticClass: "filter-expand-to-full",
                      on: {
                        click: function($event) {
                          _vm.productTypesExpanded = true
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Показать ещё\n                    "
                      ),
                      _c("SVGExpandBot", { staticClass: "arrow-to-full" })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.productTypes.length > 2 &&
                            _vm.productTypesExpanded,
                          expression:
                            "productTypes.length > 2 && productTypesExpanded"
                        }
                      ],
                      staticClass: "filter-expand-to-full",
                      on: {
                        click: function($event) {
                          _vm.productTypesExpanded = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Свернуть\n                    "
                      ),
                      _c("SVGCollapseTop", { staticClass: "arrow-to-full" })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.categories, function(category) {
        return [
          category.name
            .trim()
            .toLowerCase()
            .indexOf("цвет") === -1 &&
          _vm.getFilledSubcategories(category.subcategories).length > 0
            ? _c("div", { staticClass: "filter-frame no-mt" }, [
                _c("div", { staticClass: "filter-label-grid" }, [
                  _c("div", { staticClass: "filter-label" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(category.name) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "filter-frame-collapse-arrow",
                      on: {
                        click: function($event) {
                          return _vm.collapseFilter(category.id)
                        }
                      }
                    },
                    [
                      !_vm.collapseCategoryIds.includes(category.id)
                        ? _c("SVGCollapseTop")
                        : _c("SVGExpandBot")
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.collapseCategoryIds.includes(category.id),
                        expression: "!collapseCategoryIds.includes(category.id)"
                      }
                    ],
                    staticClass: "filter-data-container"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "filter-data" },
                      [
                        _vm._l(
                          _vm.getFilledSubcategories(category.subcategories),
                          function(subcategory, index) {
                            return [
                              index < _vm.indexBreaker ||
                              _vm.dynamicExpandRule[category.id]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "filter-data-checkbox-line"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.subcategorySelected,
                                            expression: "subcategorySelected"
                                          }
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          value:
                                            category.id + "/" + subcategory.id,
                                          checked: Array.isArray(
                                            _vm.subcategorySelected
                                          )
                                            ? _vm._i(
                                                _vm.subcategorySelected,
                                                category.id +
                                                  "/" +
                                                  subcategory.id
                                              ) > -1
                                            : _vm.subcategorySelected
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.subcategorySelected,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v =
                                                  category.id +
                                                  "/" +
                                                  subcategory.id,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.subcategorySelected = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.subcategorySelected = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.subcategorySelected = $$c
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-label" },
                                        [_vm._v(_vm._s(subcategory.name))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-count" },
                                        [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm.getNofProductsInSubcategory(
                                                  subcategory.id
                                                )
                                              ) +
                                              ")"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.getFilledSubcategories(category.subcategories).length >
                    _vm.indexBreaker
                      ? _c("div", { staticClass: "expand-to-full-container" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.getFilledSubcategories(
                                      category.subcategories
                                    ).length > _vm.indexBreaker &&
                                    !_vm.dynamicExpandRule[category.id],
                                  expression:
                                    "getFilledSubcategories(category.subcategories).length > indexBreaker && !dynamicExpandRule[category.id]"
                                }
                              ],
                              staticClass: "filter-expand-to-full",
                              on: {
                                click: function($event) {
                                  return _vm.setExpandRule(category.id, true)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Показать ещё\n                        "
                              ),
                              _c("SVGExpandBot", {
                                staticClass: "arrow-to-full"
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.productTypes.length > 2 &&
                                    _vm.dynamicExpandRule[category.id],
                                  expression:
                                    "productTypes.length > 2 && dynamicExpandRule[category.id]"
                                }
                              ],
                              staticClass: "filter-expand-to-full",
                              on: {
                                click: function($event) {
                                  return _vm.setExpandRule(category.id, false)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Свернуть\n                        "
                              ),
                              _c("SVGCollapseTop", {
                                staticClass: "arrow-to-full"
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            : _vm.getFilledSubcategories(category.subcategories).length > 0
            ? _c(
                "div",
                { staticClass: "filter-frame" },
                [
                  _c("div", { staticClass: "filter-label-grid" }, [
                    _c("div", { staticClass: "filter-label" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(category.name) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "filter-frame-collapse-arrow",
                        on: {
                          click: function($event) {
                            return _vm.collapseFilter(category.id)
                          }
                        }
                      },
                      [
                        !_vm.collapseCategoryIds.includes(category.id)
                          ? _c("SVGCollapseTop")
                          : _c("SVGExpandBot")
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "vue-custom-scrollbar",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.collapseCategoryIds.includes(category.id),
                          expression:
                            "!collapseCategoryIds.includes(category.id)"
                        }
                      ],
                      staticClass: "filter-data-container"
                    },
                    [
                      _c("div", { staticClass: "filter-data" }, [
                        _c(
                          "div",
                          { staticClass: "filter-data-colors-line" },
                          _vm._l(
                            _vm.getFilledSubcategories(category.subcategories),
                            function(subcategory) {
                              return _c(
                                "div",
                                {
                                  staticClass: "filter-color",
                                  class: {
                                    "color-selected": _vm.checkColorActive(
                                      category.id + "/" + subcategory.id
                                    )
                                  },
                                  attrs: { title: subcategory.name }
                                },
                                [
                                  _c("img", {
                                    staticClass: "filter-color-icon",
                                    attrs: {
                                      src: _vm.urlForPhoto(
                                        subcategory.preview_src
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectColorCategory(
                                          category.id + "/" + subcategory.id
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            }
                          ),
                          0
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }