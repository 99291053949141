export function PlaneGroup(idGroup) {
    this.namespaced = true;
    this.state = {
        constructorName: 'PlaneGroup',
        name: 'Группа ' + idGroup,
        color: '#CB2D44',
    };
    this.getters = {
        SELF: state => state,
    };
    this.mutations = {
        VALUE_PARAMS: (state, data) => {
            for (let mutProp in data) {
                if(state[mutProp])
                    state[mutProp] = data[mutProp];
            }
        }
    };
    this.actions = {
        INIT: function (context, inputData) {
            context.commit('VALUE_PARAMS', inputData.data);
        },
    };
}