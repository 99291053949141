<template>
    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 5.40217L8.53261 1L1 2.17391L9.21739 10.6848L19 5.40217Z" stroke="currentColor" stroke-width="0.5"/>
        <path d="M6.08691 7.45653C6.42847 7.81361 6.88336 7.892 7.74996 7.88801M15.7717 7.16305C15.5713 7.21833 15.3755 7.25978 15.1847 7.28897M7.74996 7.88801C8.70352 7.88361 9.9187 7.42414 11.2717 5.98914C11.7771 6.58372 13.2149 7.5903 15.1847 7.28897M7.74996 7.88801L15.1847 7.28897" stroke="currentColor" stroke-width="0.5"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGCovering"
    }
</script>

<style scoped>

</style>
