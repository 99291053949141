import {http_request} from "../../httpConfig";

export const projects = {
    namespaced: true,
    state: () => ({
        numberLoadedItems: 20,
        projects: [],
        waitingResponseToProjectsRequest: false,
        allUploaded: false,
        mask: ''
    }),
    getters: {
        PROJECTS: state => state.projects,
        PROJECT_BY_UUID: state => uuid => {
            return state.projects.find(project => project.uuid === uuid);
        },
    },
    mutations: {
        PROJECTS: (state, newProjects) => {

            if (newProjects.length !== 0)
                state.projects = state.projects.concat(newProjects);
            else
                state.allUploaded = true;
        },
        SAVE_PROJECT: (state, newDataProject) => {
            let indexProject = state.projects.findIndex(project => project.uuid === newDataProject.uuid);
            if (indexProject === -1) {
                // state.projects.unshift(newDataProject);
                console.error('Проект с таким uuid не найден!');
            } else {
                state.projects.splice(indexProject, 1, newDataProject)
            }
        },
        MASK: (state, value) => {
            state.mask = value;
        },
        DELETE_PROJECT: (state, uuidProject) => {
            let indexProject = state.projects.findIndex(project => project.uuid === uuidProject);
            if (indexProject !== -1) {
                console.log(state.projects.splice(indexProject, 1));
            }
        },
        CLEAR_PROJECTS: (state) => {
            state.projects.splice(0);
            state.allUploaded = false;
        }
    },
    actions: {
        GET_PROJECTS: async function (context, inputData) {
            if (context.state.waitingResponseToProjectsRequest || context.state.allUploaded)
                return;

            context.state.waitingResponseToProjectsRequest = true;
            const mask = context.state.mask.trim();
            let dataForSend = {
                part: {
                    from: context.state.projects.length,
                    count: context.state.numberLoadedItems
                },
                user_request: false
            };
            if (mask.length !== 0) {
                dataForSend.find = {
                    mask,
                    field: "data_json->>'name'"
                };
            }
            console.log(dataForSend);
            await http_request.post('/get_project', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('PROJECTS', request.data.projects);
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                    context.state.allUploaded = true;
                    // if (error.message.includes('timeout')) {
                    //     //Ошибка
                    // } else if (error.response && context.rootState.user_authorized && error.response.status === 401) {
                    //     //Ошибка
                    // } else if (error.response.status === 423) {
                    //     //Ошибка
                    // }
                });

            context.state.waitingResponseToProjectsRequest = false;
        },
        SAVE_PROJECT: async function (context, inputData) {
            let projectNeedsEdit = context.getters.PROJECT_BY_UUID(inputData.uuid);
            if (!projectNeedsEdit) {
                console.error('Не найден проект, который необходимо изменить.');
            }

            let copeDataProject = JSON.parse(JSON.stringify(projectNeedsEdit));

            let newDataProject = Object.assign(copeDataProject, inputData);

            // let test = {
            //     [newDataProject.uuid] : newDataProject
            // }

            let dataForSend = {
                uuid: inputData.uuid,
                open_read: inputData.open_read,
                open_write: inputData.open_write,
                user_id: inputData.user_id,
            }

            console.log(dataForSend);
            http_request.post('/save_project', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('SAVE_PROJECT', newDataProject);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        DELETE_PROJECT: async function (context, uuid) {

            http_request.post('/delete_project', {uuid})
                .then((request) => {
                    console.log(request.data);
                    context.commit('DELETE_PROJECT', request.data.project_deleted);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}
