import store from "../../store";
import {Vector3} from "three";
import {getFermatPolygon} from "../../math/getAreaCorrected";
import {isArray} from "mathjs";
import {Point, Segment} from "@mathigon/fermat";

export const getPerspectivePoints = function (planeID, photoID) {
    let plane
    if (photoID) {
        let allPhotos = store.getters["active/GET_ALL_PHOTO"]
        plane = allPhotos[photoID].planeList[planeID];
    } else {
        plane = store.getters["active/PLANE_BY_ID"](planeID)
    }

    const checkDirection = () => {
        let diagonalCenter = {
            x: (pointArray[0].x + pointArray[2].x) / 2,
            y: (pointArray[0].y + pointArray[2].y) / 2
        }

        let v1 = new Vector3(diagonalCenter.x - pointArray[0].x, diagonalCenter.y - pointArray[0].y, 0)
        let v2 = new Vector3(pointArray[0].x - pointArray[1].x, pointArray[0].y - pointArray[1].y, 0)
        let v3 = v1.x * v2.y - v2.x * v1.y
        return v3 > 0;
    }

    let pointArray = [];
    if (plane.perspectivePoints.length > 0) {
        for (let pointID in plane.perspectivePoints) {
            pointArray.push(Object.assign({}, plane.perspectivePoints[pointID]))
        }
    } else if (Object.keys(plane.points).length === 3) {
        const pointList = plane.points;
        let lines = getBaseLine(pointList);
        let baseLine = lines[0];
        let vertex = getLinesIntersection(lines[1], lines[2]);
        let horizontal = new Segment(new Point(0, vertex.y), vertex);
        // 0.01 below because we dont want this lines to be parallel since when we request real rectangle
        // with get_real_rectangle in /api/get_texture_with_perspective request it may cause error
        let vertical1 = new Segment(new Point(baseLine.p1.x+0.01, 0), baseLine.p1);
        let vertical2 = new Segment(new Point(baseLine.p2.x-0.01, 0), baseLine.p2);
        pointArray.push({x: baseLine.p1.x, y: baseLine.p1.y});
        pointArray.push({x: baseLine.p2.x, y: baseLine.p2.y});
        pointArray.push(getLinesIntersection(vertical2, horizontal));
        pointArray.push(getLinesIntersection(vertical1, horizontal));
    } else if (Object.keys(plane.points).length > 4) {
        const pointList = plane.points;
        let verticals = getVerticals(pointList);
        if (verticals.length >= 2) {
            // TODO validate verticals
            for (let vertical = 0; vertical < 2; ++vertical) {
                pointArray.push({x: verticals[vertical].p1.x, y: verticals[vertical].p1.y});
                pointArray.push({x: verticals[vertical].p2.x, y: verticals[vertical].p2.y});
            }
        }
    }
    if (pointArray.length === 0) {
        const pointList = plane.points;
        for (let pointID = 1; pointID <= 4; pointID++) {
            let point = Object.assign({}, pointList[pointID]);
            delete point.link
            // point.link = (pointList[pointID].link < pointID) ? (pointList[pointID].link - 1) : 3;
            pointArray.push(point);
        }
    }
    if (!checkDirection()) {
        // console.log("Смена направления");
        [pointArray[0], pointArray[2]] = [pointArray[2], pointArray[0]];
    }

    // let xyMult = pointArray[0].x * pointArray[0].y;
    // let xyNult2 = pointArray[1].x * pointArray[1].y;
    let deltaX = Math.abs(pointArray[0].x - pointArray[1].x);
    let deltaY = Math.abs(pointArray[0].y - pointArray[1].y);
    let horizontalFactor = deltaX / deltaY;
    let minPointID = 0;

    for (let pointID = 0; pointID < pointArray.length; pointID++) {
        let newDeltaX = Math.abs(pointArray[pointID].x - pointArray[(pointID !== 3) ? pointID + 1 : 0].x);
        let newDeltaY = Math.abs(pointArray[pointID].y - pointArray[(pointID !== 3) ? pointID + 1 : 0].y);
        if (newDeltaX / newDeltaY > horizontalFactor) {
            horizontalFactor = newDeltaX / newDeltaY;
            minPointID = pointID;
        }
    }


    let minPointID2
    if (minPointID < 2)
        minPointID2 = minPointID+2;
    else
        minPointID2 = minPointID-2;


    // deltaX = Math.abs(pointArray[1].x - pointArray[2].x);
    // deltaY = Math.abs(pointArray[1].y - pointArray[2].y);
    // horizontalFactor = deltaX / deltaY;
    // let minPointID2 = 0;
    // for (let pointID = 0; pointID < pointArray.length; pointID++) {
    //     if (pointID !== minPointID) {
    //         console.log("!!!!!!!!!", pointID)
    //         let newDeltaX = Math.abs(pointArray[pointID].x - pointArray[(pointID !== 3) ? pointID + 1 : 0].x);
    //         let newDeltaY = Math.abs(pointArray[pointID].y - pointArray[(pointID !== 3) ? pointID + 1 : 0].y);
    //         if (newDeltaX / newDeltaY > horizontalFactor) {
    //             horizontalFactor = newDeltaX / newDeltaY;
    //             minPointID2 = pointID;
    //         }
    //     }
    // }

    // console.log(minPointID, minPointID2)
    let sumY1 = pointArray[minPointID].y + pointArray[(minPointID !== 3)?minPointID+1 : 0].y
    let sumY2 = pointArray[minPointID2].y + pointArray[(minPointID2 !== 3)?minPointID2+1 : 0].y

    if (sumY2 < sumY1)
        minPointID = minPointID2;

    if (minPointID != 3) {
        if (pointArray[minPointID].x > pointArray[minPointID + 1].x)
            minPointID = minPointID + 1;
    } else {
        if (pointArray[3].x > pointArray[0].x)
            minPointID = 0;
    }

    if (minPointID != 0) {
        for (let i = 1; i <= minPointID; i++) {
            pointArray.push(pointArray.shift())
        }
    }

    return pointArray
}

const getVerticals = function (pointList) {
    const fermatPolygon = getFermatPolygon(pointList);
    let edges = fermatPolygon.edges;
    let verticals = [];
    for (let edge = 0; edge < edges.length; ++edge) {
        if (getSlope(edges[edge]) > 3) {  // slope = 3 equals approximately 18 degrees
            verticals.push(edges[edge]);
        }
    }
    verticals.sort(compareSlope);

    return verticals;
}

export const getSlope = function (segment) {
    return Math.abs((segment.p2.y - segment.p1.y)/(segment.p2.x - segment.p1.x));
}

const compareSlope = function (a, b) {
    if (getSlope(a) < getSlope(b)) {
        return 1;
    }
    if (getSlope(a) > getSlope(b)) {
        return -1;
    }
    return 0;
}

const getBaseLine = function (pointList) {
    const fermatPolygon = getFermatPolygon(pointList);
    let edges = fermatPolygon.edges;
    edges.sort(compareSlope);
    edges.reverse();

    return edges;
}

const getMidPoint = function (segment) {
    return new Point((segment.p1.x+segment.p2.x)/2, (segment.p1.y+segment.p2.y)/2);
}

export const getLinesIntersection = function (a, b) {
    // Check if none of the lines are of length 0
    if ((a.p1.x === a.p2.x && a.p1.y === a.p2.y) || (b.p1.x === b.p2.x && b.p1.y === b.p2.y)) {
        return false;
    }

    let denominator = ((b.p2.y - b.p1.y) * (a.p2.x - a.p1.x) - (b.p2.x - b.p1.x) * (a.p2.y - a.p1.y));

    // Lines are parallel
    if (denominator === 0) {
        return false;
    }

    let ua = ((b.p2.x - b.p1.x) * (a.p1.y - b.p1.y) - (b.p2.y - b.p1.y) * (a.p1.x - b.p1.x)) / denominator;
    let ub = ((a.p2.x - a.p1.x) * (a.p1.y - b.p1.y) - (a.p2.y - a.p1.y) * (a.p1.x - b.p1.x)) / denominator;

    // is the intersection along the segments
    // if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    //     return false;
    // }

    // Return a object with the x and y coordinates of the intersection
    let x = a.p1.x + ua * (a.p2.x - a.p1.x);
    let y = a.p1.y + ua * (a.p2.y - a.p1.y);

    return {x, y}
}
