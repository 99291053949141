<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Добавить
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container pv-23">
            <div class="two-cols-modal-grid">
                <div class="modal-form-grid">
                    <div class="modal-form-grid-label">
                        Название
                    </div>
                    <input v-model="name" class="modal-form-grid-input">
                    <div class="modal-form-grid-label">
                        Ширина
                    </div>
                    <input v-model="width" class="modal-form-grid-input">
                    <div class="modal-form-grid-label">
                        Высота
                    </div>
                    <input v-model="height" class="modal-form-grid-input">
                </div>
                <div class="modal-form-img-container">
                    <img class="modal-form-img" :src="getPhoto()"
                         :class="{'image-placeholder': image === null}"/>
                    <div class="blue-button modal-form-img-button" @click="$refs.previewFile.click()">
                        Изменить
                    </div>
                    <input type="file" ref="previewFile" v-on:change="handleFileUpload($event)"
                           accept=".jpg,.png" hidden>
                </div>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-lower-buttons-grid">
                <div class="blue-button modal-accept-button"
                     v-on:click="save">Сохранить
                </div>
                <div class="blue-outline-button modal-accept-button" v-on:click="$emit('close')">Отмена</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import {urlForStaticVue} from "../../common";
    import {http_request, uploadFiles} from "../../../httpConfig";

    export default {
        name: "ModalHeightMap",
        mixins: [urlForStaticVue],
        components: {
            SVGClose,
        },
        props: {
            heightMapData: {
                type: Object
            },
            callback: {
                type: Function
            }
        },
        data() {
            return {
                image: null,
                name: null,
                width: null,
                height: null,
                heightMapSrc: null
            }
        },
        methods: {
            getPhoto() {
                if (this.heightMapSrc) {
                    return this.urlForPhoto(this.heightMapSrc);
                } else {
                    return this.urlForStatic('/img/no-img-placeholder.png');
                }
            },
            urlForPhoto(url) {
                return this.$store.getters[`projectConfiguration/GENERATE_URL`](url);
            },
            uploadFile(fileInput, callback) {
                for (let file in fileInput.target.files) {
                    if (fileInput.target.files[file].size / 1048576 > 15) {
                        return
                    }
                }
                console.log(fileInput.target.files);
                uploadFiles(fileInput.target.files)
                    .then((request) => {
                        console.log(request.data);
                        callback(request.data.fileList);
                    })
            },
            handleFileUpload(fileInput) {
                const save = (filesSrc) => {
                    for (let src of filesSrc) {
                        this.heightMapSrc = src;
                    }
                };
                this.uploadFile(fileInput, save);
            },
            save() {
                const data = {
                    name: this.name,
                    width: this.width,
                    height: this.height,
                    height_map: this.heightMapSrc
                };

                if (this.heightMapData) {
                    data['id'] = this.heightMapData.id;
                }

                console.log(data);

                http_request.post('/save_height_map', data)
                    .then((request) => {
                        console.log(request.data);
                        if (this.callback) {
                            this.callback(request.data.height_map);
                        }
                        this.$emit('close');
                    });
            }
        },
        mounted() {
            if (this.heightMapData) {
                this.name = this.heightMapData.name;
                this.width = this.heightMapData.width;
                this.height = this.heightMapData.height;
                this.heightMapSrc = this.heightMapData.height_map;
            }
        }
    }
</script>

<style scoped>

</style>
