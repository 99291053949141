<template>
<!--    <svg width="197" height="142" viewBox="0 0 197 142" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M9.76126 80L53.9718 48H122.739L78.5282 80H9.76126Z" stroke="currentColor" stroke-width="6"/>-->
<!--        <path d="M78 83L132 45L150.5 70.5L126.5 88L130 92.5L153.5 75L174 103.5L120 142L78 83Z" fill="currentColor"/>-->
<!--        <path d="M72.1213 0.87868C70.9497 -0.292893 69.0502 -0.292893 67.8787 0.87868L48.7868 19.9706C47.6152 21.1421 47.6152 23.0416 48.7868 24.2132C49.9584 25.3848 51.8579 25.3848 53.0294 24.2132L70 7.24264L86.9706 24.2132C88.1421 25.3848 90.0416 25.3848 91.2132 24.2132C92.3848 23.0416 92.3848 21.1421 91.2132 19.9706L72.1213 0.87868ZM73 64L73 3L67 3L67 64L73 64Z" fill="currentColor"/>-->
<!--        <path d="M184.299 51.9649C184.556 50.3281 183.437 48.793 181.801 48.5362L155.127 44.3521C153.49 44.0954 151.955 45.2141 151.698 46.851C151.441 48.4878 152.56 50.0229 154.197 50.2796L177.907 53.9989L174.188 77.7089C173.931 79.3458 175.05 80.8808 176.687 81.1376C178.323 81.3943 179.859 80.2756 180.115 78.6387L184.299 51.9649ZM153.603 75.4244L183.103 53.9244L179.569 49.0756L150.069 70.5756L153.603 75.4244Z" fill="currentColor"/>-->
<!--    </svg>-->
    <svg width="244" height="216" viewBox="0 0 244 216" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 103.853C44.0665 83.8735 135.6 58.3463 236.5 104.284V202.481C142.057 146.902 51.7635 174.499 7.5 201.794V199.499V116.999V103.853Z" stroke="currentColor" stroke-width="15"/>
        <path d="M126.596 2.40381C124.058 -0.134594 119.942 -0.134594 117.404 2.40381L76.0381 43.7696C73.4996 46.308 73.4996 50.4235 76.0381 52.962C78.5765 55.5004 82.692 55.5004 85.2304 52.9619L122 16.1924L158.77 52.9619C161.308 55.5004 165.424 55.5004 167.962 52.9619C170.5 50.4235 170.5 46.308 167.962 43.7696L126.596 2.40381ZM128.5 125L128.5 7.00001L115.5 7.00001L115.5 125L128.5 125Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGNormalMap"
    }
</script>

<style scoped>

</style>
