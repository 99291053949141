var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "35",
        height: "35",
        viewBox: "0 0 35 35",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M17.3955 8.4735C12.4602 8.4735 8.4794 12.4552 8.4794 17.3916C8.4794 22.328 12.4602 26.3097 17.3955 26.3097C22.3308 26.3097 26.3117 22.328 26.3117 17.3916C26.3117 12.4552 22.3308 8.4735 17.3955 8.4735ZM17.3955 23.1896C14.2062 23.1896 11.5989 20.5894 11.5989 17.3916C11.5989 14.1938 14.1984 11.5937 17.3955 11.5937C20.5926 11.5937 23.1922 14.1938 23.1922 17.3916C23.1922 20.5894 20.5849 23.1896 17.3955 23.1896ZM28.756 8.1087C28.756 9.26519 27.8248 10.1888 26.6764 10.1888C25.5202 10.1888 24.5967 9.25743 24.5967 8.1087C24.5967 6.95998 25.5279 6.02858 26.6764 6.02858C27.8248 6.02858 28.756 6.95998 28.756 8.1087ZM34.6613 10.2199C34.5294 7.43344 33.8931 4.96524 31.8522 2.93169C29.8191 0.898142 27.3515 0.261687 24.5657 0.121978C21.6945 -0.0410165 13.0888 -0.0410165 10.2176 0.121978C7.43957 0.253926 4.97192 0.89038 2.93106 2.92393C0.890209 4.95748 0.261656 7.42568 0.121978 10.2121C-0.0409801 13.0839 -0.0409801 21.6916 0.121978 24.5634C0.253896 27.3498 0.890209 29.818 2.93106 31.8516C4.97192 33.8851 7.43181 34.5216 10.2176 34.6613C13.0888 34.8243 21.6945 34.8243 24.5657 34.6613C27.3515 34.5293 29.8191 33.8929 31.8522 31.8516C33.8853 29.818 34.5216 27.3498 34.6613 24.5634C34.8243 21.6916 34.8243 13.0917 34.6613 10.2199ZM30.9521 27.6447C30.3468 29.166 29.1751 30.338 27.6464 30.9512C25.3572 31.8593 19.9253 31.6498 17.3955 31.6498C14.8658 31.6498 9.42611 31.8516 7.14469 30.9512C5.62375 30.3458 4.45201 29.1738 3.83897 27.6447C2.93106 25.3551 3.14058 19.9219 3.14058 17.3916C3.14058 14.8613 2.93882 9.42042 3.83897 7.1385C4.44425 5.61722 5.61599 4.44521 7.14469 3.83204C9.43387 2.92393 14.8658 3.13349 17.3955 3.13349C19.9253 3.13349 25.365 2.93169 27.6464 3.83204C29.1673 4.43745 30.3391 5.60946 30.9521 7.1385C31.86 9.42818 31.6505 14.8613 31.6505 17.3916C31.6505 19.9219 31.86 25.3628 30.9521 27.6447Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }