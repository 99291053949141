import {fabric} from "fabric";
import router from '../../router'

export const add_dragging = function (fabricJS) {
    this.eventedObject = null;

    this.handler_right_mouse_mousemove = (event) => {
        let delta = new fabric.Point(event.e.movementX, event.e.movementY);
        fabricJS.relativePan(delta);
    };

    this.handler_right_mouse_down = (e) => {
        return (opt) => {
            if (opt.e.button === 2) {
                if (opt.target)
                    if (opt.target.evented) {
                        this.eventedObject = opt.target;
                        this.eventedObject.set({
                            evented: false
                        });
                    }
                fabricJS.on('mouse:move', this.handler_right_mouse_mousemove);
            }
        }
    };

    this.handler_right_mouse_up = (e) => {
        return (opt) => {
            if (opt.e.button === 2) {
                if (this.eventedObject) {
                    this.eventedObject.set({
                        evented: true
                    });
                }
                fabricJS.off('mouse:move', this.handler_right_mouse_mousemove);
                fabricJS.off('mouse:move', this.handler_right_mouse_mousemove);
                router.replace({query: {view: JSON.stringify(fabricJS.viewportTransform)}});
            }
        }
    };

    this.handler_right_mouse_contextmenu = (e) => {
        e.preventDefault();
    };

    fabricJS.on('mouse:down:before', this.handler_right_mouse_down());
    fabricJS.on('mouse:up:before', this.handler_right_mouse_up());
    fabricJS.upperCanvasEl.addEventListener('contextmenu', this.handler_right_mouse_contextmenu);

    return this
}

