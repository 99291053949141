var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "251",
        height: "161",
        viewBox: "0 0 251 161",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M7 157H158L120.25 78.5L86.1048 7.49615C84.6516 4.4742 80.3484 4.4742 78.8952 7.49615L7 157Z",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "8"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M7 157H158L120.25 78.5L86.1048 7.49615C84.6516 4.4742 80.3484 4.4742 78.8952 7.49615L7 157Z",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "8"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M90 157L162.877 65.9043C164.478 63.9027 167.522 63.9027 169.123 65.9043L242 157H158",
          stroke: "currentColor",
          "stroke-width": "8"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }