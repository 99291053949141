import {users} from "./users";
import {products} from "./products";
import {categories} from "./categories";
import {customers} from "./customers";
import {projects} from "./projects";
import {incoming} from "./incoming";
import {colors} from "./colors";
import {config} from "./config";
import {organizations} from "./organizations";
import {selectRegionCity} from "./forUniversalFields/selectRegionCity";
import {selectUser} from "./selectUser";


export default {
    namespaced: true,
    state: () => ({}),
    modules:{
        users,
        products,
        categories,
        customers,
        projects,
        incoming,
        colors,
        config,
        organizations,
        selectRegionCity,
        selectUser
    },
    mutations: {},
    getters: {},
    actions: {}
};
