var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-background" }, [
    _c("div", { staticClass: "modal-head-grid" }, [
      _c("div", { staticClass: "modal-label" }, [
        _vm._v(
          '\n            Изменить значение переменной "' +
            _vm._s(_vm.variableName) +
            '"\n        '
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-close-btn-mp",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("SVGClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c(
        "div",
        {
          staticClass: "modal-form-grid-three",
          staticStyle: { "margin-top": "23px" }
        },
        [
          _c("div", { staticClass: "modal-form-grid-label" }, [
            _vm._v("\n                Значение\n            ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.variableData,
                expression: "variableData"
              }
            ],
            staticClass: "modal-form-grid-input",
            attrs: { type: "text", placeholder: "Задайте значение" },
            domProps: { value: _vm.variableData },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.variableData = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "admin-table-option flex-center",
              on: {
                click: function($event) {
                  return _vm.reset()
                }
              }
            },
            [_c("SVGRefresh")],
            1
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-lower-buttons-grid" }, [
        _c(
          "div",
          {
            staticClass: "blue-button modal-accept-button",
            on: { click: _vm.save }
          },
          [_vm._v("Сохранить")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blue-outline-button modal-accept-button",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v("Отмена")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }