var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lite-app-body" }, [
    _c(
      "div",
      { staticClass: "lite-projects-sidebar" },
      [
        _c("SidebarProjectsBuildingTypes", {
          attrs: { selectedTagPermanentId: _vm.selectedTagPermanentId },
          on: { selectTagPermanentId: _vm.selectTagPermanentId }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "lite-projects-sidebar-list" },
          [
            _c("SidebarProjectsLite", {
              attrs: {
                selectProjectUuid: _vm.projectUuid,
                selectedTagPermanentId: _vm.selectedTagPermanentId
              },
              on: { selectProject: _vm.selectProject }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "lite-projects-workspace" }, [
      _c(
        "div",
        {
          staticClass: "arrow-photo-left",
          staticStyle: { left: "460px" },
          on: { click: _vm.previousPhoto }
        },
        [_c("SVGArrowSwitchPhotoLeft")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "arrow-photo-right",
          style: { right: _vm.rightPhotoArrow },
          on: { click: _vm.nextPhoto }
        },
        [_c("SVGArrowSwitchPhotoRight")],
        1
      ),
      _vm._v(" "),
      _vm.idShowingPhoto
        ? _c(
            "div",
            {
              staticClass: "configurator-button",
              on: { click: _vm.goToSelectProduct }
            },
            [
              _vm._v("\n            Подобрать материалы\n            "),
              _c("SVGColorCards", { staticStyle: { "margin-left": "10px" } })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.photoList.length > 0
        ? _c(
            "div",
            { staticClass: "photo-navigation-tip" },
            [
              _c("SVGRotateHouse"),
              _vm._v(" "),
              _vm._l(_vm.photoList, function(photo) {
                return [
                  _c("div", {
                    staticClass: "house-rotation-mark",
                    class: { green: photo.id == _vm.idShowingPhoto },
                    on: {
                      click: function($event) {
                        return _vm.selectPhoto(photo.id)
                      }
                    }
                  })
                ]
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("img", {
        staticClass: "projects-workspace-image",
        attrs: { src: _vm.urlForPhoto(_vm.srcShowingPhoto) }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.projectsPromptStatus,
            expression: "projectsPromptStatus"
          }
        ],
        staticClass: "lite-projects-sidebar-prompt"
      },
      [_c("PromptProjects")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }