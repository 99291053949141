<template>
    <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.99967 1.3335C3.43024 1.3335 1.33301 3.43073 1.33301 6.00016C1.33301 8.5696 3.43024 10.6668 5.99967 10.6668C7.16501 10.6668 8.22968 10.2322 9.04915 9.521L9.33301 9.80485V10.6668L13.057 14.3908C13.425 14.7588 14.0223 14.7588 14.3903 14.3908C14.7583 14.0228 14.7583 13.4255 14.3903 13.0575L10.6663 9.3335H9.80436L9.52051 9.04964C10.2317 8.23017 10.6663 7.16549 10.6663 6.00016C10.6663 3.43073 8.56911 1.3335 5.99967 1.3335ZM5.99967 2.66683C7.84852 2.66683 9.33301 4.15132 9.33301 6.00016C9.33301 7.84901 7.84852 9.3335 5.99967 9.3335C4.15083 9.3335 2.66634 7.84901 2.66634 6.00016C2.66634 4.15132 4.15083 2.66683 5.99967 2.66683Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGSearch"
    }
</script>

<style scoped>

</style>
