import Vue from 'vue';
import router from './router';
import store from './store';
import TheApp from "./UI/TheApp.vue";
import VModal from 'vue-js-modal'
import checkAuth from "./router/checkAuth";
import checkInProgress from "./router/checkInProgress";
import checkDataProject from "./router/checkDataProject";
import checkSaveProjects from "./router/checkSaveProjects";
import {sync} from "vuex-router-sync";
import checkFirstCrossing from "./router/checkFirstCrossing";
import checkApplicationVersion from "./router/checkApplicationVersion";
import VueTheMask from 'vue-the-mask'
import accessRights from "./router/accessRights";
import checkScale from "./router/checkScale";
import VueSocialSharing from 'vue-social-sharing'

//
// new Vue({
//     render: h => h(TheApp),
// }).$mount('#application');

router.beforeEach(checkInProgress);
router.beforeEach(checkSaveProjects);
router.beforeEach(checkAuth);
router.beforeEach(checkApplicationVersion);
router.beforeEach(accessRights);
router.beforeEach(checkFirstCrossing);
router.beforeEach(checkDataProject);
router.beforeEach(checkScale);

Vue.use(VModal, {
    dialog: true,
    dynamic: true,
    // injectModalsContainer: true      // Надо узнать, что это такое
});

Vue.use(VueSocialSharing);
Vue.use(VueTheMask)

store.dispatch('INIT');
sync(store, router);

new Vue({
    components: {TheApp},
    router,
    store,
    template: '<TheApp/>'
}).$mount('#application');

store.subscribe((mutName, state) => {
    store.dispatch('saveProject/CHECK_MUTATION', mutName);
});

window.calculateCost = async () => {
    let cost = await store.dispatch('cost/GET_COST');
    console.log(cost);
}
