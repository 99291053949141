import TheLiteVersion from "../UI/LiteVersionMP/TheLiteVersion";
import TheLiteProjects from "../UI/LiteVersionMP/Components/Projects/TheLiteProjects";
import TheLiteConfigurator from "../UI/LiteVersionMP/Components/Configurator/TheLiteConfigurator";
import SidebarLeftFilters from "../UI/LiteVersionMP/Components/Configurator/SidebarConfiguratorLeft/Filters/SidebarLeftFilters";
import SidebarLeftProducts from "../UI/LiteVersionMP/Components/Configurator/SidebarConfiguratorLeft/ProductMenu/SidebarLeftProducts";
import WorkspaceConfiguratorDefault from "../UI/LiteVersionMP/Components/Configurator/WorkspaceConfigurator/WorkspaceConfiguratorDefault";

export default {
    name: "TheLiteVersion",
    path: '/the-lite-version',
    component: TheLiteVersion,
    meta: {requiresAuth: false, applicationVersion: 'lite'},
    children: [
        {
            name: "liteProjects",
            path: 'lite-projects',
            component: TheLiteProjects,
        },
        {
            name: "liteConfigurator",
            path: 'lite-configurator',
            component: TheLiteConfigurator,
            children: [
                {
                    name: "selectProduct",
                    path: 'selectProduct/project=:projectUUID/photo=:photoID/design=:designID/group=:groupID/element=:facadeElement',
                    // meta: {activeFiltersTab: 'roof'},
                    components: {
                        sidebarLeftFilters: SidebarLeftFilters,
                        sidebarLeftProducts: SidebarLeftProducts,
                        workspace: WorkspaceConfiguratorDefault
                    }
                },
                // {
                //     name: "facade",
                //     path: 'facade/project=:projectUUID/photo=:photoID/design=:designID/group=:groupID/element=:facadeElement',
                //     meta: {activeFiltersTab: 'wall'},
                //     components: {
                //         sidebarLeftFilters: SidebarLeftFiltersFacade,
                //         sidebarLeftProducts: SidebarLeftProductsFence,
                //         workspace: WorkspaceConfiguratorDefault
                //     }
                // },
                // {
                //     name: "gutter",
                //     path: 'gutter/project=:projectUUID/photo=:photoID/design=:designID',
                //     meta: {activeFiltersTab: 'gutter'},
                //     components: {
                //         sidebarLeftFilters: SidebarLeftFiltersGutter,
                //         sidebarLeftProducts: SidebarLeftProductsGutter,
                //         workspace: WorkspaceConfiguratorDefault
                //     }
                // },
                // {
                //     name: "fence",
                //     path: 'fence/project=:projectUUID/photo=:photoID/design=:designID',
                //     meta: {activeFiltersTab: 'fence'},
                //     components: {
                //         sidebarLeftFilters: SidebarLeftFiltersFence,
                //         sidebarLeftProducts: SidebarLeftProductsFence,
                //         workspace: WorkspaceConfiguratorDefault
                //     }
                // },
            ]
        },
    ]
};
