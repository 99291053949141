export const dictToArray = (dict) => {
    let array = [];
    for (let id in dict) {
        array.push(dict[id])
    }
    return array
};

export const getScale = function(sourceImg, fabricJS) {
    let dw = fabricJS.width / sourceImg.width;
    let dh = fabricJS.height / sourceImg.height;
    let scale = (dw < dh) ? dw : dh;
    return scale
}

export const getNormalizeScale = function(sourceImg, fabricJS, upScaleIn) {
    let upScale = 1;
    if (upScaleIn)
        upScale = upScaleIn
    // let normalWidth = 1920;
    // let normalHeight = 1080;
    let normalWidth = 3840*upScale;
    let normalHeight = 2160*upScale;
    let dw = fabricJS.width / sourceImg.width;
    let dh = fabricJS.height / sourceImg.height;
    let scale = (dw < dh) ? normalWidth/sourceImg.width : normalHeight/sourceImg.height;
    return scale
}

export const getMinYPoint = function(points) {
    let pointList = Array.isArray(points)?points:dictToArray(points);

    let minPoint = pointList[0];
    pointList.forEach((point)=>{
        if (minPoint.y < point.y) {
            minPoint = point;
        }
    })

    return minPoint
}

export const sleep = function(timeout) {
  return new Promise(resolve => {
        setTimeout(resolve, timeout);
    })
}
