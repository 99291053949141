var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "35",
        height: "35",
        viewBox: "0 0 30 30",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M15 3C7.82 3 2 7.925 2 14C2 17.368 3.79333 20.3785 6.61133 22.3965C6.68328 23.9325 6.44528 26.0534 4.32617 27.0312L4.32227 27.0332C4.22759 27.0692 4.14608 27.1331 4.08853 27.2165C4.03098 27.2998 4.00011 27.3987 4 27.5C4 27.6326 4.05268 27.7598 4.14645 27.8536C4.24021 27.9473 4.36739 28 4.5 28C4.51434 28 4.52868 27.9993 4.54297 27.998C6.97699 27.9824 9.04327 26.667 10.4688 25.2773C10.9207 24.8363 11.5509 24.6193 12.1719 24.7363C13.0819 24.9093 14.028 25 15 25C22.18 25 28 20.075 28 14C28 7.925 22.18 3 15 3Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M13.4199 16.2637V15.541C13.4199 14.916 13.5534 14.3724 13.8203 13.9102C14.0872 13.4479 14.5755 12.9564 15.2852 12.4355C15.9688 11.9473 16.418 11.5501 16.6328 11.2441C16.8542 10.9382 16.9648 10.5964 16.9648 10.2188C16.9648 9.79557 16.8086 9.47331 16.4961 9.25195C16.1836 9.0306 15.7474 8.91992 15.1875 8.91992C14.2109 8.91992 13.0977 9.23893 11.8477 9.87695L10.7832 7.73828C12.235 6.92448 13.7747 6.51758 15.4023 6.51758C16.7435 6.51758 17.8079 6.83984 18.5957 7.48438C19.39 8.12891 19.7871 8.98828 19.7871 10.0625C19.7871 10.7786 19.6243 11.3971 19.2988 11.918C18.9733 12.4388 18.3548 13.0247 17.4434 13.6758C16.8184 14.138 16.4212 14.4896 16.252 14.7305C16.0892 14.9714 16.0078 15.2871 16.0078 15.6777V16.2637H13.4199ZM13.1074 19.6035C13.1074 19.0566 13.2539 18.6432 13.5469 18.3633C13.8398 18.0833 14.2663 17.9434 14.8262 17.9434C15.3665 17.9434 15.7832 18.0866 16.0762 18.373C16.3757 18.6595 16.5254 19.0697 16.5254 19.6035C16.5254 20.1178 16.3757 20.5247 16.0762 20.8242C15.7767 21.1172 15.36 21.2637 14.8262 21.2637C14.2793 21.2637 13.8561 21.1204 13.5566 20.834C13.2572 20.541 13.1074 20.1309 13.1074 19.6035Z",
          fill: "white"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }