import {http_request} from "../../httpConfig";

export const categories = {
    namespaced: true,
    state: () => ({
        categories: {},
        waitingResponseToCategoriesRequest: false,
    }),
    mutations: {
        CATEGORIES: (state, categories) => {
            state.categories = categories;
        },
    },
    getters: {
        SELF: state => {
            return state.categories;
        },
        CATEGORY_BY_HIERARCHY_IDS: state => ids => {
            let category = {};
            if(ids.length !== 0) {
                if(state.categories[ids[0]] === undefined)
                    return category;

                category = state.categories[ids[0]];
                for (let i = 1; i < ids.length; i++) {
                    if(category.subcategories[ids[i]] === undefined)
                        return category;

                    category = category.subcategories[ids[i]];
                }
            }
            return category;
        }
    },
    actions: {
        GET_CATEGORIES: async function (context, inputData) {
            if(context.state.waitingResponseToProductRequest)
                return;

            context.state.waitingResponseToCategoriesRequest = true;
            let dataForSend = {};
            console.log(dataForSend);
            await http_request.post('/get_product_categories', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('CATEGORIES', request.data.product_categories);
                })
                .catch((error) => {
                    console.error(error);
                    context.state.allUploaded = true;
                });
            context.state.waitingResponseToCategoriesRequest = false;
        },
        SAVE_CATEGORY: async function (context, inputData) {
            await http_request.post('/save_category', inputData)
                .then((request) => {
                    console.log(request.data);
                    context.dispatch('GET_CATEGORIES');
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        DELETE_CATEGORY: async function (context, idCategory) {
            await http_request.post('/delete_category', {category_id: idCategory})
                .then((request) => {
                    console.log(request.data);
                    context.dispatch('GET_CATEGORIES');
                })
                .catch((error) => {
                    console.error(error);
                    console.log(typeof(error.response.data));
                    if((typeof(error.response.data) === 'object')){
                        console.log(error.response.data);
                    }
                });
        },
    }
};