<template>
    <div class="lite-projects-sidebar-prompt-container">
        <div class="prompt-label">
            Создайте дом Вашей мечты всего за 3 действия!
        </div>
        <SVGPromptOne class="prompt-svg"/>
        <div class="prompt-grid">
            <div class="prompt-number">
                1
            </div>
            <div class="prompt-sub-label">
                Выберите подходящий проект здания
            </div>
        </div>
        <SVGPromptTwo class="prompt-svg"/>
        <div class="prompt-grid">
            <div class="prompt-number">
                2
            </div>
            <div class="prompt-sub-label">
                Подберите матералы, которые подойдут именно Вам
            </div>
        </div>
        <SVGPromptThree class="prompt-svg"/>
        <div class="prompt-grid">
            <div class="prompt-number">
                3
            </div>
            <div class="prompt-sub-label">
                Получите результат и  оформите заказ
            </div>
        </div>
        <div class="blue-button" style="margin-top: 40px" v-on:click="closePrompt">
            Понятно!
        </div>
    </div>
</template>

<script>
import SVGPromptOne from "../../../SVGMP/SVGPromptOne";
import SVGPromptTwo from "../../../SVGMP/SVGPromptTwo";
import SVGPromptThree from "../../../SVGMP/SVGPromptThree";

export default {
    name: "PromptProjects",
    components: {
        SVGPromptThree,
        SVGPromptTwo,
        SVGPromptOne
    },
    methods: {
        closePrompt() {
            this.$store.commit('CLOSE_PROJECTS_PROMPT')
        }
    }
}
</script>

<style scoped>
    .lite-projects-sidebar-prompt-container {
        margin: 18px 23px;
    }

    .prompt-label {
        font-size: 12px;
        font-weight: bold;
    }

    .prompt-grid {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 20px;
    }

    .prompt-svg {
        margin: 25px 0;
    }

    .prompt-number {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        color: white;
        background-color: #0075A9;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    .prompt-sub-label {
        font-size: 12px;
    }
</style>
