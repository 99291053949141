<template>
<!--    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path class="small-loader-path" d="M8.00985 1.23874L1.39016 5.18755C1.1482 5.33188 1 5.59285 1 5.8746V17.4389C1 17.8232 1.27334 18.1533 1.65098 18.2249L15.3387 20.8201C15.8315 20.9135 16.2878 20.5356 16.2878 20.0341V19.0464C16.2878 18.6045 16.6459 18.2464 17.0878 18.2464H19.9658H30.2C30.6418 18.2464 31 17.8882 31 17.4464V5.88016C31 5.59543 30.8487 5.33215 30.6026 5.18885L23.8089 1.23226C23.5621 1.08852 23.2573 1.08727 23.0094 1.22897L16.324 5.04911C16.0786 5.18935 15.7774 5.18966 15.5316 5.04992L8.81517 1.23037C8.56479 1.08799 8.25721 1.09118 8.00985 1.23874Z" stroke="#4C5459" stroke-width="1.2"/>-->
<!--    </svg>-->
    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="small-loader-path" d="M1.38823 5.23373L8.0408 1.23985C8.28773 1.09161 8.59528 1.08753 8.84605 1.22919L15.5299 5.00473C15.7725 5.14178 16.0689 5.14272 16.3124 5.00722L23.043 1.26134C23.2894 1.12424 23.5897 1.12693 23.8336 1.26842L30.6015 5.19533C30.8482 5.33846 31 5.60209 31 5.88729V17.4636C31 17.9054 30.6418 18.2636 30.2 18.2636H16.7204C16.2786 18.2636 15.9204 18.6218 15.9204 19.0636V20.0323C15.9204 20.5344 15.4632 20.9124 14.97 20.818L1.64954 18.2672C1.27258 18.1951 1 17.8653 1 17.4815V5.91962C1 5.63868 1.14736 5.37833 1.38823 5.23373Z" stroke="#4C5459" stroke-width="1.2"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGSmallLoader"
    }
</script>

<style scoped>

</style>
