var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "application-body" }, [
    _c("div", { staticClass: "loading-line" }, [
      _c("div", {
        staticClass: "loading-line-progress",
        style: { width: +_vm.loadingProgress + "%" }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "loading-page-logo-container" }, [
      _c("img", {
        attrs: { src: _vm.urlForStatic("/img/metal-profil-logo-gray.png") }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }