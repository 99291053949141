export const stepsChain = {
    namespaced: true,
    state: {
        pro: {
            stepsOrder: {
                "projects":
                    {
                        "prev": "",
                        "next": ""
                    },
                "selectProject":
                    {
                        "prev": "projects",
                        "next": "loadPhoto"
                    },
                "loadPhoto":
                    {
                        "prev": "selectProject",
                        "next": "viewPhoto"
                    },
                "viewPhoto":
                    {
                        "prev": "selectProject",
                        "next": "planeCategories"
                    },
                "EditPhoto":
                    {
                        "prev": "viewPhoto",
                        "next": "viewPhoto"
                    },
                "SetPerspective":
                    {
                        "prev": "planeCategoriesForPerspective",
                        "next": "planeCategories"
                    },
                "planeCategories":
                    {
                        "prev": "viewPhoto",
                        "next": "SetScale"
                    },
                "wall":
                    {
                        "prev": "planeCategories",
                        "next": "SetScale"
                    },
                "roof":
                    {
                        "prev": "planeCategories",
                        "next": "SetScale"
                    },
                "fence":
                    {
                        "prev": "planeCategories",
                        "next": "SetScale"
                    },
                "window":
                    {
                        "prev": "planeCategories",
                        "next": "SetScale"
                    },
                "door":
                    {
                        "prev": "planeCategories",
                        "next": "SetScale"
                    },
                "additional":
                    {
                        "prev": "planeCategories",
                        "next": "SetScale"
                    },
                "cutout":
                    {
                        "prev": "planeCategories",
                        "next": "SetScale"
                    },
                "additionalManual":
                    {
                        "prev": "planeCategories",
                        "next": "SetScale"
                    },
                "planeCategoriesActiveSelection":
                    {
                        "prev": "planeCategories",
                        "next": "SetScale"
                    },
                "SetScale":
                    {
                        "prev": "planeCategories",
                        "next": "",
                        "alternativeNext": "planeCategoriesForPerspective",
                    },
                "planeCategoriesForPerspective":
                    {
                        "prev": "planeCategories",
                        "next": "groupingPlanes"
                    },
                "planeCategoriesForPerspectiveActivePlane":
                    {
                        "prev": "planeCategories",
                        "next": "groupingPlanes"
                    },
                "groupingPlanes":
                    {
                        "prev": "planeCategoriesForPerspective",
                        "next": "designList"
                    },
                "groupingPlanesActive":
                    {
                        "prev": "groupingPlanes",
                        "next": "designList"
                    },
                "groupingPlanesActiveEditGroup":
                    {
                        "prev": "groupingPlanesActive",
                        "next": "designList"
                    },

                "designList":
                    {
                        "prev": "groupingPlanes",
                        "next": ""
                    },
                "designListSelected":
                    {
                        "prev": "groupingPlanes",
                        "next": "resultSelectDesign"
                    },
                // "designGroups":
                //     {
                //         "prev": "designListSelected",
                //         "next": ""
                //     },
                // "designGroupsSelected":
                //     {
                //         "prev": "designListSelected",
                //         "next": "designMaterials",
                //         "nextSelected": "designMaterialsSelected",
                //     },
                "designMaterials":
                    {
                        "prev": "designListSelected",
                        "next": "resultSelectDesign"
                    },
                "designMaterialsSelectedGroup":
                    {
                        "prev": "designListSelected",
                        "next": "resultSelectDesign"
                    },
                "resultSelectDesign":
                    {
                        "prev": "designListSelected",
                        "next": "resultMaterials"
                    },
                "resultMaterials":
                    {
                        "prev": "resultSelectDesign",
                        "next": ""
                    }
            }
        },
        lite: {
            stepsOrder: {
                "liteProjects":
                    {
                        "prev": "",
                        "next": ""
                    },
                "liteSelectProjects":
                    {
                        "prev": "",
                        "next": "liteDesignList"
                    },
                "liteDesignList":
                    {
                        "prev": "liteSelectProjects",
                        "next": ""
                    },
                "liteDesignListSelected":
                    {
                        "prev": "liteSelectProjects",
                        "next": "lightResultImages"
                    },
                "lightDesignMaterialsEmpty":
                    {
                        "prev": "liteDesignListSelected",
                        "next": ""
                    },
                "lightDesignMaterials":
                    {
                        "prev": "liteDesignListSelected",
                        "next": ""
                    },
                "lightDesignMaterialsSelected":
                    {
                        "prev": "liteDesignListSelected",
                        "next": ""
                    },
                "lightResultImages":
                    {
                        "prev": "liteDesignListSelected",
                        "next": "lightResultMaterials"
                    },
                "lightResultMaterials":
                    {
                        "prev": "lightResultImages",
                        "next": ""
                    },
            }
        }
    }
};
