export const planeGroupSettings = {
    namespaced: true,
    state: {
        name: '',
        color: '',
        colorsOptions: [
            '#F3DD78',
            '#FFCA6E',
            '#ABD452',
            '#81A647',
            '#126635',
            '#4CC7E9',
            '#1A5D7A',
            '#1B2F62',
            '#E04F5E',
            '#CB2D44',
            '#812637',
            '#622546',
            '#E9D8CE',
            '#322721'
        ],
    },
    getters: {
        VALUE_PARAMS: state => {
            return {
                name: state.name,
                color: state.color
            }
        }
    },
    mutations: {
        NAME: (state, value) => {
            state.name = value;
        },
        COLOR: (state, value) => {
            state.color = value;
        },
    },
    actions: {
        APPLY: function (context) {

        }
    }
}