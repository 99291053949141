var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "25",
        height: "31",
        viewBox: "0 0 25 31",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M1 2C1 1.44772 1.44772 1 2 1H18.0847L23.3813 7.31225V29C23.3813 29.5523 22.9336 30 22.3813 30H2C1.44772 30 1 29.5523 1 29V2Z",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M5.33239 20.5724V23.1608H3.83133V15.8145H6.67333C7.22039 15.8145 7.70072 15.9154 8.11435 16.1172C8.53131 16.319 8.85154 16.6066 9.07503 16.98C9.29852 17.35 9.41026 17.7721 9.41026 18.2464C9.41026 18.9663 9.16509 19.5347 8.67474 19.9518C8.18773 20.3656 7.51226 20.5724 6.64832 20.5724H5.33239ZM5.33239 19.3464H6.67333C7.07028 19.3464 7.37216 19.2522 7.57897 19.0638C7.78912 18.8754 7.89419 18.6063 7.89419 18.2565C7.89419 17.8966 7.78912 17.6056 7.57897 17.3836C7.36882 17.1616 7.07862 17.0473 6.70836 17.0405H5.33239V19.3464Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M10.446 23.1608V15.8145H12.6876C13.328 15.8145 13.9001 15.9608 14.4038 16.2534C14.9108 16.5427 15.3061 16.9564 15.5896 17.4946C15.8732 18.0295 16.0149 18.6383 16.0149 19.3211V19.6592C16.0149 20.342 15.8748 20.9492 15.5946 21.4806C15.3178 22.0121 14.9258 22.4242 14.4188 22.7168C13.9118 23.0094 13.3397 23.1574 12.7026 23.1608H10.446ZM11.9471 17.0405V21.9448H12.6726C13.2596 21.9448 13.7083 21.7514 14.0185 21.3646C14.3287 20.9778 14.4872 20.4244 14.4938 19.7046V19.3161C14.4938 18.5693 14.3404 18.0042 14.0335 17.6208C13.7266 17.2339 13.278 17.0405 12.6876 17.0405H11.9471Z",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M21.4938 20.1587H18.6117V23.1608H17.1107V15.8145H21.854V17.0405H18.6117V18.9377H21.4938V20.1587Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }