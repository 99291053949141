<template>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1337 0H1.26638C0.675133 0 0.376794 0.717378 0.795722 1.13631L5.92498 6.26634V11.9813C5.92498 12.1985 6.03095 12.402 6.2089 12.5266L8.42765 14.0792C8.86538 14.3856 9.47499 14.075 9.47499 13.5338V6.26634L14.6044 1.13631C15.0225 0.71821 14.7262 0 14.1337 0Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "SVGFilter"
}
</script>

<style scoped>

</style>