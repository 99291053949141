var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "206",
        height: "113",
        viewBox: "0 0 206 113",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M13.8154 71.4234V59.4647H2L13.8154 14.2874H50.5746V1H63.7028V14.2874H84.708L99.1491 59.4647H87.3337V71.4234",
          stroke: "black",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M139.968 53.6712V44.727H131.142L139.968 10.938H167.427V1H177.233V10.938H192.924L203.711 44.727H194.885V53.6712",
          stroke: "black",
          "stroke-width": "2"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M62.9009 88.9697V112H152.953V88.9697H167.426L149.737 33.6558H124.008V17.387H107.927V33.6558H62.9009L48.4282 88.9697H62.9009Z",
          fill: "#B4FF00",
          stroke: "black",
          "stroke-width": "2"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }