<template>
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1484 8.52348L2.19319 17.047L2.50935e-05 15L6.04478 8.52348L2.56597e-05 2.04695L2.19319 -3.47735e-07L10.1484 8.52348Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGExpandSingle"
    }
</script>

<style scoped>

</style>
