<template>
<!--    <svg width="276" height="161" viewBox="0 0 276 161" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M183 157H32L103.895 7.49615C105.348 4.4742 109.652 4.4742 111.105 7.49615L145.25 78.5L157.5 104" stroke="currentColor" stroke-width="8"/>-->
<!--        <path d="M115 157L187.877 65.9043C189.478 63.9027 192.522 63.9027 194.123 65.9043L267 157H183" stroke="currentColor" stroke-width="8"/>-->
<!--        <path d="M0 157H22" stroke="currentColor" stroke-width="8"/>-->
<!--        <path d="M0 138H32" stroke="currentColor" stroke-width="8"/>-->
<!--        <path d="M0 119L41 119" stroke="currentColor" stroke-width="8"/>-->
<!--        <path d="M0 100L49 100" stroke="currentColor" stroke-width="8"/>-->
<!--        <path d="M0 81L58 81" stroke="currentColor" stroke-width="8"/>-->
<!--        <path d="M0 62L67 62" stroke="currentColor" stroke-width="8"/>-->
<!--        <path d="M0 43L75 43" stroke="currentColor" stroke-width="8"/>-->
<!--        <path d="M0 24L84 24" stroke="currentColor" stroke-width="8"/>-->
<!--        <path d="M0 5L95 5" stroke="currentColor" stroke-width="8"/>-->
<!--    </svg>-->
    <svg width="251" height="161" viewBox="0 0 251 161" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 157H158L120.25 78.5L86.1048 7.49615C84.6516 4.4742 80.3484 4.4742 78.8952 7.49615L7 157Z" fill="currentColor" stroke="currentColor" stroke-width="8"/>
        <path d="M7 157H158L120.25 78.5L86.1048 7.49615C84.6516 4.4742 80.3484 4.4742 78.8952 7.49615L7 157Z" fill="currentColor" stroke="currentColor" stroke-width="8"/>
        <path d="M90 157L162.877 65.9043C164.478 63.9027 167.522 63.9027 169.123 65.9043L242 157H158" stroke="currentColor" stroke-width="8"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGHeightMap"
    }
</script>

<style scoped>

</style>
