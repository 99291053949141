import TheAdminPageMP from "../UI/AdminPageMP/TheAdminPageMP";
import AdminWorkspaceProducts from "../UI/AdminPageMP/Components/Workspace/AdminWorkspaceProducts";
import AdminWorkspaceCategories from "../UI/AdminPageMP/Components/Workspace/AdminWorkspaceCategories";
import AdminWorkspaceUsers from "../UI/AdminPageMP/Components/Workspace/AdminWorkspaceUsers";
import AdminWorkspaceProjects from "../UI/AdminPageMP/Components/Workspace/AdminWorkspaceProjects";
import AdminWorkspaceAdministrating from "../UI/AdminPageMP/Components/Workspace/AdminWorkspaceAdministrating";
import AdminWorkspaceModels from "../UI/AdminPageMP/Components/Workspace/AdminWorkspaceModels";
import AdminWorkspaceHeightMaps from "../UI/AdminPageMP/Components/Workspace/AdminWorkspaceHeightMaps";
import AdminWorkspaceDefuseMaps from "../UI/AdminPageMP/Components/Workspace/AdminWorkspaceDefuseMaps";
import AdminWorkspaceNormalMaps from "../UI/AdminPageMP/Components/Workspace/AdminWorkspaceNormalMaps";

export default {
    name: "adminPage",
    path: '/admin-page',
    redirect: '/admin-page/products',
    component: TheAdminPageMP,
    meta: {applicationVersion: 'adminPage', requiresAuth: true},
    children: [
        {
            path: 'products',
            components: {
                workspace: AdminWorkspaceProducts,
            },
            children: [
                {
                    name: "adminPageProducts",
                    path: '',
                    meta: {highlightMenu : 'products', requiresAuth: true},
                },
                {
                    name: "adminPageProductsArea",
                    path: 'area=:appArea',
                    meta: {highlightMenu : 'products', requiresAuth: true},
                }
            ],
        },
        {
            path: 'categories',
            components: {
                workspace: AdminWorkspaceCategories,
            },
            children: [
                {
                    name: "adminPageCategories",
                    path: '',
                    meta: {highlightMenu : 'categories', requiresAuth: true},
                },
                {
                    name: "adminPageCategorySelected",
                    path: 'category=:categoryId',
                    meta: {highlightMenu : 'categories', requiresAuth: true},
                }
            ]
        },
        {
            name: "adminPageModels",
            path: 'models',
            components: {
                workspace: AdminWorkspaceModels,
            },
            meta: {highlightMenu : 'models', requiresAuth: true}
        },
        {
            name: "adminPageDefuseMap",
            path: 'defuse_map',
            components: {
                workspace: AdminWorkspaceDefuseMaps,
            },
            meta: {highlightMenu : 'defuseMap', requiresAuth: true}
        },
        {
            name: "adminPageNormalMap",
            path: 'normal_map',
            components: {
                workspace: AdminWorkspaceNormalMaps,
            },
            meta: {highlightMenu : 'normalMap', requiresAuth: true}
        },
        {
            name: "adminPageHeightMap",
            path: 'height_map',
            components: {
                workspace: AdminWorkspaceHeightMaps,
            },
            meta: {highlightMenu : 'heightMap', requiresAuth: true}
        },
        {
            name: "adminPageUsers",
            path: 'users',
            components: {
                workspace: AdminWorkspaceUsers,
            },
            meta: {highlightMenu : 'users', requiresAuth: true}
        },
        {
            name: "adminPageProjects",
            path: 'projects',
            components: {
                workspace: AdminWorkspaceProjects,
            },
            meta: {highlightMenu : 'projects', requiresAuth: true}
        },
        {
            name: "adminPageAdministrating",
            path: 'administrating',
            components: {
                workspace: AdminWorkspaceAdministrating,
            },
            meta: {highlightMenu : 'administrating', requiresAuth: true}
        },
    ]
};
