<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Тредуется подтверждение
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-tip-grid">
                <div class="modal-tip-icon">
                    <SVGAlert/>
                </div>
                <div class="modal-tip-text">
                    {{alertMsg}}
                </div>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-lower-buttons-grid">
                <div class="blue-button modal-accept-button" v-on:click="apply">Подтвердить</div>
                <div class="blue-outline-button modal-accept-button" v-on:click="$emit('close')">Отмена</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import SVGAlert from "../../SVGMP/SVGAlert";
    export default {
        name: "ModalAttention",
        components: {
            SVGAlert,
            SVGClose
        },
        props: {
          alertMsg: {
            type: String
          },
            callback: {
            type: Function
          }},
      methods:{
          apply(){
            this.callback();
            this.$emit('close');
          }
      }
    }
</script>

<style scoped>

</style>
