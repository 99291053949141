<template>
    <div id="authorization-page">
        <div id="authorisation-container">
            <div id="gl-logo-container">
                <img :src="urlForStatic('/img/logo-metall-profil.png')" id="authorisation-logo-svg"/>
            </div>
            <div id="authorization-label">Вход в аккаунт</div>
            <div class="login-input-container">
                <input id="nickname" class="login-text-input" type="text" v-model="inputNickname" placeholder="Почта или логин">
            </div>
            <div class="login-input-container">
                <input id="password" class="login-text-input" type="password" v-model="inputPassword" placeholder="Пароль">
            </div>
            <div class="form-alert-danger" v-show="authorisationFailed">
                Неверный email пользователя или пароль!
            </div>
            <div class="login-input-checkbox-container">
<!--                <div class="pretty p-default">-->
<!--                    <input type="checkbox" v-model="rememberMe"/>-->
<!--                    <div class="state p-danger">-->
<!--                        <label></label>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <span id="remember-me-text-tip" v-bind:class="{'remember-me-text-true': rememberMe}">Запомнить меня</span>-->
            </div>
            <div id="btn-login-submit" v-on:click="logIn">
                Войти в аккаунт
            </div>
            <div id="authorisation-app-tip">

            </div>
        </div>
    </div>
</template>

<script>
    import {urlForStaticVue} from "./common";

    export default {
        name: "AuthorisationPage",
        mixins: [urlForStaticVue],
        data () {
            return {
                inputNickname: '',
                inputPassword: '',
                rememberMe: false
            }
        },
        computed: {
            authorisationFailed() {
                return this.$store.state.profile.authorisationFailed
            }
        },
        methods: {
            logIn () {
                this.$store.dispatch('profile/LOG_IN', {
                    email: this.inputNickname,
                    password: this.inputPassword,
                    redirect: this.$route.query.redirect
                });
                // this.inputNickname = ''
                // this.inputPassword = ''
            }
        }
    }
</script>


<style scoped>
    #authorization-page {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    #authorisation-container {
        display: block;
        width: 300px;
    }

    #gl-logo-container {
        text-align: center;
        margin-bottom: 70px;
    }

    #authorisation-logo-svg {
        width: 260px;
    }

    #authorization-label {
        font-weight: bold;
        font-size: 22px;
        text-align: center;
        margin-bottom: 20px;
    }

    #authorisation-app-tip {
        margin-top: 40px;
        font-size: 13px;
        text-align: center;
        color: #ACACAC;
    }

    .login-input-container {
        margin: 20px 0;
    }

    .login-text-input {
        width: 278px;
        padding: 10px;
        border: 1px solid #BDBDBD;
    }

    .login-text-input:hover {
        border-color: #828282;
    }

    .login-text-input:focus {
        border-color: #828282;
    }

    .login-input-checkbox-container {
        margin: 45px 0 20px 5px;
    }

    #remember-me-text-tip {
        color: #BDBDBD;
    }

    .remember-me-text-true {
        color: #303030 !important;
    }

    #btn-login-submit {
        padding: 10px 0;
        background-color: #383838;
        width: 100%;
        text-align: center;
        color: white;
        border-radius: 2px;
        cursor: pointer;
        user-select: none;
    }

    #btn-login-submit:hover {
        background-color: #404040;
    }

    #btn-login-submit:active {
        background-color: #383838;
    }
</style>
