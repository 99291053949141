export function Perspective(paramModule) {
    this.namespaced = true;
    this.state = {
        constructorName: 'Perspective'
    }

    this.getters = {
        NAME: state => {
            return state.name;
        }
    }

    this.mutations = {
        NAME: (state, newName) => {
            state.name = newName;
        }
    }

    this.actions = {
        NAME: ()=>{
            console.log('Это Магия!!!');
        }
    }
}