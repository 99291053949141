import store from "../../store";
import {getNewPointsForAdditional} from "./additionalPlane";

export const additionalElementsLines = function (pointArray) {
    this.points = [];
    this.points.push(...pointArray.map(item=>item.position));
    this.newPoint1 = null;
    this.newPoint2 = null;

    this.dashLineParams = {
        stroke: 'rgba(173,12,39)',
        selectable: false,
        strokeDashArray: [5, 10],
        strokeWidth: 1,
        zIndex: 1000,
    }

    this.solidLineParams = {
        stroke: 'rgba(173,12,39)',
        selectable: false,
        evented: false,
        perPixelTargetFind: true,
        // strokeWidth: scale
    }


        let scale = 3;
    if (store.state.route.query.view)
        scale = 3/JSON.parse(store.state.route.query.view)[0]

    this.getNewPoints = () => {
        let d = 10;
        [this.newPoint1, this.newPoint2] = getNewPointsForAdditional({
            points: this.points,
            perspectiveMatrix: null,
        });
    }


    this.update = (pointArray) => {
        this.points = [...pointArray.map(item=>item.position)];
        this.getNewPoints();
        this.dashLine1.set({
            x1: this.points[0].x,
            y1: this.points[0].y,
            x2: this.points[2].x,
            y2: this.points[2].y
        })

        this.dashLine2.set({
            x1: this.points[1].x,
            y1: this.points[1].y,
            x2: this.points[3].x,
            y2: this.points[3].y
        })

        this.line1.set({
            x1: this.points[0].x,
            y1: this.points[0].y,
            x2: this.points[1].x,
            y2: this.points[1].y
        })

        this.line2.set({
            x1: this.newPoint1.x,
            y1: this.newPoint1.y,
            x2: this.newPoint2.x,
            y2: this.newPoint2.y
        })

        this.line3.set({
            x1: this.points[0].x,
            y1: this.points[0].y,
            x2: this.newPoint1.x,
            y2: this.newPoint1.y
        })

        this.line4.set({
            x1:  this.points[1].x,
            y1:  this.points[1].y,
            x2:  this.newPoint2.x,
            y2:  this.newPoint2.y
        })
    }

    // this.getNewPoints();
    this.getNewPoints();
    this.dashLine1 = new fabric.Line(
        [
            this.points[0].x,
            this.points[0].y,
            this.points[2].x,
            this.points[2].y
        ], this.dashLineParams)

    this.dashLine2 = new fabric.Line(
        [
            this.points[1].x,
            this.points[1].y,
            this.points[3].x,
            this.points[3].y
        ], this.dashLineParams)

    this.line1 = new fabric.Line(
        [
            this.points[0].x,
            this.points[0].y,
            this.points[1].x,
            this.points[1].y
        ], this.solidLineParams)

    this.line2 = new fabric.Line(
        [
            this.newPoint1.x,
            this.newPoint1.y,
            this.newPoint2.x,
            this.newPoint2.y
        ], this.solidLineParams)

    this.line3 = new fabric.Line(
        [
            this.points[0].x,
            this.points[0].y,
            this.newPoint1.x,
            this.newPoint1.y
        ], this.solidLineParams)

    this.line4 = new fabric.Line(
        [
            this.points[1].x,
            this.points[1].y,
            this.newPoint2.x,
            this.newPoint2.y
        ], this.solidLineParams)

    this.fabric = [ this.dashLine1, this.dashLine2, this.line1, this.line2, this.line3, this.line4 ];

    return this
}

