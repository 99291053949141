<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29289 6.64191C7.68342 7.03244 8.31658 7.03244 8.70711 6.64192L14.0235 1.32549C14.2033 1.14573 14.4947 1.14573 14.6745 1.32549C14.8543 1.50525 14.8543 1.7967 14.6745 1.97647L9.35808 7.29289C8.96756 7.68342 8.96756 8.31658 9.35808 8.70711L14.6745 14.0235C14.8543 14.2033 14.8543 14.4947 14.6745 14.6745C14.4947 14.8543 14.2033 14.8543 14.0235 14.6745L8.70711 9.35808C8.31658 8.96756 7.68342 8.96756 7.29289 9.35808L1.97647 14.6745C1.7967 14.8543 1.50525 14.8543 1.32549 14.6745C1.14573 14.4947 1.14573 14.2033 1.32549 14.0235L6.64191 8.70711C7.03244 8.31658 7.03244 7.68342 6.64192 7.29289L1.32549 1.97647C1.14573 1.7967 1.14573 1.50525 1.32549 1.32549C1.50525 1.14573 1.7967 1.14573 1.97647 1.32549L7.29289 6.64191Z" fill="currentColor" stroke="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGClose"
    }
</script>

<style scoped>

</style>
