import {PlaneList} from "./PlaneList";
import {Perspective} from "./Perspective";
import {http_request} from "../../httpConfig";

export function Photo() {
    this.namespaced = true;
    this.state = {
        src: '',
        shadowMaskSRC: '',
        constructorName: 'Photo',
        name: null,
        scale: null,
        size: null,
        markers: [],
        cameraSettings: {},
        perspectiveLines: [],
        baseLine: [],
        floorPlan: [],
    };

    this.getters = {
        NAME: state => {
            return state.name;
        },
        SCALE: state => {
            return state.scale;
        },
        CHECK_SCAlE_PHOTO: state => {
            return !!state.scale;
        },
        CAMERA_SETTINGS: (state) => {
            return state.cameraSettings;
        },
        BASE_LINE: (state) => {
            return state.baseLine;
        },
        PERSPECTIVE_LINES: (state) => {
            return state.perspectiveLines;
        },
        MARKERS: (state, data) => {
           return state.markers
        },
    };

    this.mutations = {
        ADD_MARKER: (state, data) => {
            if(data && data.idPlaneGroup) {
                state.markers.push(data);
            }
        },
        MARKERS: (state, data) => {
            state.markers.splice(0);
            state.markers.push(...data);
        },
        DELETE_MARKER_BY_ID_PLANE_GROUP:() => {

        },
        INIT: (state, data) => {
            for (let key in data) {
                if(state.hasOwnProperty(key)) {
                    state[key] = data[key];
                }
            }
        },
        SRC: (state, src) => {
            state.src = src;
        },
        NAME: (state, newName) => {
            state.name = newName;
        },
        SCALE: (state, scale) => {
            state.scale = scale;
        },
        SHADOW_MASK: (state, src) => {
        state.shadowMaskSRC = src;
        },
        SIZE: (state, size) => {
            state.size = size;
        },
        CAMERA_SETTINGS: (state, settings) => {
            state.cameraSettings = settings;
        },
        BASE_LINE: (state, baseLine) => {
            state.baseLine = baseLine;
        },
        PERSPECTIVE_LINES: (state, perspectiveLines) => {
            state.perspectiveLines = perspectiveLines;
        },
    };

    this.actions = {
        INIT: function (context, inputData) {
            const photoData = inputData.data;
            const pathPhoto = inputData.path;
            // !!!!!!!!!!!!!!!!!!!!!! ВАЖНО !!!!!!!!!!!!
            // INIT должен вызываться перед созданием модулей planeList, perspective и т.д.
            // Иначе, данные модули будут созданны не верно
            context.commit('INIT', photoData);
            // const preserveState = inputData.preserveState;
            const pathPlaneList = pathPhoto.concat(['planeList']);
            this.registerModule(pathPlaneList, new PlaneList());
            const pathPerspective = pathPhoto.concat(['perspective']);
            this.registerModule(pathPerspective, new Perspective());
        },
        GET_SHADOW_MASK: function (context) {
            let requestPromise = ()=> {
                return new Promise((resolve) => {
                    http_request.post('/get_shadows_map', {image_src: context.state.src})
                        .then((request) => {
                            console.log("request shadow ", request.data.uuid_filename)
                            context.commit('SHADOW_MASK', request.data.uuid_filename);
                            resolve(request.data.uuid_filename)
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                })
            }
            if (context.state.shadowMaskSRC) {
                return fetch(context.rootGetters[`projectConfiguration/GENERATE_URL`](context.state.shadowMaskSRC), { method: 'HEAD' }).then(res => {
                    if (res.ok){
                        return context.state.shadowMaskSRC;
                    } else {
                        console.error("Image not found on server ", context.state.shadowMaskSRC)
                        return requestPromise()
                    }
                })
            }
            else {
                return requestPromise()
            }

        }
    };
}
