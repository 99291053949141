<template>
<!--    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M1 2.44121V20.6965H4.84322V2.44121L2.92161 1L1 2.44121Z" stroke="currentColor"/>-->
<!--        <path d="M4.84314 6.28468H8.68636" stroke="currentColor"/>-->
<!--        <path d="M4.84314 17.8142H8.68636" stroke="currentColor"/>-->
<!--        <path d="M8.68634 2.44121V20.6965H12.5296V2.44121L10.6079 1L8.68634 2.44121Z" stroke="currentColor"/>-->
<!--        <path d="M12.5295 6.28468H16.3727" stroke="currentColor"/>-->
<!--        <path d="M12.5295 17.8142H16.3727" stroke="currentColor"/>-->
<!--        <path d="M16.3726 2.44121V20.6965H20.2158V2.44121L18.2942 1L16.3726 2.44121Z" stroke="currentColor"/>-->
<!--        <path d="M20.2158 6.28468H24.059" stroke="currentColor"/>-->
<!--        <path d="M20.2158 17.8142H24.059" stroke="currentColor"/>-->
<!--        <path d="M24.059 2.44121V20.6965H27.9022V2.44121L25.9806 1L24.059 2.44121Z" stroke="currentColor"/>-->
<!--    </svg>-->
    <svg width="29" height="23" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin: 0 0 1px 1px">
        <path d="M1 6.31408V22.9592H4.51725V6.31408L2.75863 5L1 6.31408Z" stroke="currentColor"/>
        <path d="M4.51682 9.81836H8.03407" stroke="currentColor"/>
        <path d="M4.51682 20.3308H8.03407" stroke="currentColor"/>
        <path d="M8.03485 6.31408V22.9592H11.5521V6.31408L9.79348 5L8.03485 6.31408Z" stroke="currentColor"/>
        <path d="M11.5519 9.81836H15.0691" stroke="currentColor"/>
        <path d="M11.5519 20.3308H15.0691" stroke="currentColor"/>
        <path d="M15.0689 6.31408V22.9592H18.5862V6.31408L16.8275 5L15.0689 6.31408Z" stroke="currentColor"/>
        <path d="M18.5859 9.81836H21.5" stroke="currentColor"/>
        <path d="M18.5859 20.3308H22.1032" stroke="white"/>
        <path d="M22 10.5L22.104 22.9592H25.6212V11" stroke="currentColor"/>
        <circle cx="24.7942" cy="5.79422" r="5.29422" stroke="currentColor"/>
        <path d="M27.7658 5.94291C27.7658 6.1572 27.5921 6.33092 27.3778 6.33092H25.1225V8.57165C25.1225 8.79398 24.9423 8.97421 24.7199 8.97421V8.97421C24.4976 8.97421 24.3174 8.79398 24.3174 8.57165V6.33092H22.0621C21.8478 6.33092 21.6741 6.1572 21.6741 5.94291V5.94291C21.6741 5.72863 21.8478 5.55491 22.0621 5.55491H24.3174V3.31418C24.3174 3.09185 24.4976 2.91162 24.7199 2.91162V2.91162C24.9423 2.91162 25.1225 3.09185 25.1225 3.31418V5.55491H27.3778C27.5921 5.55491 27.7658 5.72863 27.7658 5.94291V5.94291Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGFencePlus"
    }
</script>

<style scoped>

</style>
