<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Отправить заявку менеджеру
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-tip-grid">
                <div class="modal-tip-icon">
                    <SVGContactManager style="width: 33px; height: 53px"/>
                </div>
                <div class="modal-tip-text">
                    Начните воплощать задуманное в жизнь! Отправьте нам Ваш проект, а мы ответим на все Ваши вопросы и поможем оформить заказ.
                </div>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-form-grid">
                <div class="modal-form-grid-label">
                    Ваше имя
                </div>
                <input v-model="name" class="modal-form-grid-input" type="text" placeholder="Как к Вам обращаться?">
                <div class="modal-form-grid-label">
                    Ваша почта
                </div>
                <input v-model="email" class="modal-form-grid-input" type="text" placeholder="example@mail.com">
                <div class="modal-form-grid-label">
                    Ваш телефон
                </div>
                <input v-model="phoneNumber" class="modal-form-grid-input" type="text" placeholder="+7 (***)-***-**-**">
                <div class="modal-form-grid-label">
                    Город
                </div>
                <input v-model="city" class="modal-form-grid-input" type="text" placeholder="Ваш город">
            </div>
        </div>
        <div class="modal-data-container">
            <div class="blue-button modal-accept-button" v-on:click="sendMail()">Отправить заявку</div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import SVGContactManager from "../../SVGMP/SVGContactManager";
    import {http_request} from "../../../httpConfig";
    export default {
        name: "ModalContactManager",
        components: {SVGContactManager, SVGClose},
        data() { return {
          name: null,
          email: null,
          phoneNumber: null,
          city: null
        }},
        methods: {
          sendMail() {
            const mail_data = {
              "name": this.name,
              "email": this.email,
              "phoneNumber": this.phoneNumber,
              "city": this.city
            }
            http_request.post('/send_email', mail_data)
                .then((request) => {
                  // console.log(request.data);
                  this.$emit('close');
                });
          }
        }
    }
</script>

<style scoped>

</style>
