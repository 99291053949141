import { render, staticRenderFns } from "./ModalProject.vue?vue&type=template&id=63876a78&scoped=true&"
import script from "./ModalProject.vue?vue&type=script&lang=js&"
export * from "./ModalProject.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63876a78",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/metall-profil/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63876a78')) {
      api.createRecord('63876a78', component.options)
    } else {
      api.reload('63876a78', component.options)
    }
    module.hot.accept("./ModalProject.vue?vue&type=template&id=63876a78&scoped=true&", function () {
      api.rerender('63876a78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/UI/ModalMP/ModalAdmin/ModalProject.vue"
export default component.exports