<template>
    <svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.4701 6.76065C27.4895 7.05735 27.4895 7.3541 27.4895 7.65079C27.4895 16.7003 21.1757 27.1275 9.63592 27.1275C6.08071 27.1275 2.77811 26.0042 0 24.0545C0.505127 24.118 0.990759 24.1392 1.51532 24.1392C4.44879 24.1392 7.14921 23.0584 9.30564 21.2146C6.54697 21.151 4.23512 19.18 3.43859 16.4672C3.82717 16.5308 4.21569 16.5732 4.6237 16.5732C5.18707 16.5732 5.7505 16.4884 6.275 16.3401C3.39978 15.7042 1.24329 12.9491 1.24329 9.62177V9.53702C2.07863 10.0457 3.05008 10.3636 4.07964 10.4059C2.38947 9.17666 1.28216 7.07855 1.28216 4.70487C1.28216 3.4333 1.59294 2.26766 2.13694 1.25038C5.22588 5.40427 9.86902 8.11697 15.0755 8.41373C14.9783 7.90509 14.92 7.37531 14.92 6.84546C14.92 3.073 17.7176 0 21.195 0C23.0018 0 24.6336 0.826538 25.7799 2.16172C27.198 1.86503 28.5579 1.29278 29.7625 0.508644C29.2961 2.09818 28.3054 3.43336 27.0038 4.28104C28.2666 4.13276 29.4905 3.75119 30.6172 3.22141C29.7626 4.57773 28.694 5.7857 27.4701 6.76065Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGTwitter"
    }
</script>

<style scoped>

</style>
