var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "15",
        height: "12",
        viewBox: "0 0 15 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("rect", {
        attrs: { width: "4", height: "4", rx: "1", fill: "currentColor" }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "11",
          width: "4",
          height: "4",
          rx: "1",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "7",
          y: "8",
          width: "4",
          height: "4",
          rx: "1",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", { attrs: { d: "M4 2H11", stroke: "currentColor" } }),
      _vm._v(" "),
      _c("path", { attrs: { d: "M3.5 3.5L7.5 8.5", stroke: "currentColor" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }