<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Логи событий
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container">
            <vue-custom-scrollbar class="admin-scrollable-logs" style="height: 600px">
                <table class="admin-logs-table">
                    <thead>
                    <tr class="admin-logs-table-head">
                        <th style="padding-left: 30px">Дата</th>
<!--                        <th>Время</th>-->
                        <th>Пользователь</th>
                        <th>Действие</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(log, index) in logs" v-bind:class="{'line': index % 2 === 0}">
                        <td>
                            <span style="padding-left: 30px">{{log.date}}</span>
                        </td>
<!--                        <td>-->
<!--                            <span>{{log.date}}</span>-->
<!--                        </td>-->
                        <td>
                            <span>{{log.user.fullname}}</span>
                        </td>
                        <td style="width: 50%">
                            <span>{{log.tablename}}:</span>
                            <span class="bold-text">{{log.metadata.action}}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </vue-custom-scrollbar>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import vueCustomScrollbar from 'vue-custom-scrollbar';

    export default {
        name: "ModalLogs",
        components: {
            SVGClose,
            vueCustomScrollbar
        },
        props: {
            logs: {
                type: Array
            }
        }
    }
</script>

<style scoped>

</style>
