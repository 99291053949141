var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("TheAdminModal"),
      _vm._v(" "),
      _c("TheAdminNavbar", { staticClass: "page-navbar" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-admin-body" },
        [
          _c(
            "vue-custom-scrollbar",
            { staticClass: "page-admin-sidebar" },
            [_c("TheAdminSidebar")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pade-admin-workspace" },
            [_c("router-view", { attrs: { name: "workspace" } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }