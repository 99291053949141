<template>
    <div id="temp-copy-right">
        <div>
            <a href="https://pahomov.pro">©2020 pahomov.pro</a>
        </div>
    </div>
</template>


<script>
import {bus} from "../vue_bus";
import ModalAlert from "./Modal/ModalAlert";

export default {
    name: "tempCopyRight",
}
</script>

<style scoped>
#temp-copy-right {
    display: block;
    position: absolute;
    left: 50vw;
    bottom: 10px;
    z-index: 10000;
}
</style>
