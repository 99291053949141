import store from "../../store";
import {planePolygonForShowPlanes} from "../primitives/planePolygon";
import {bus} from "../../vue_bus";
import {sortObjectsByZindex} from "../libs/sortObjectsByZindex";
import {getAdditionalPolygonPoints} from "../primitives/additionalPlane";

export const planeGroupingTool = function (fabricJS) {
    this.fabricJS = fabricJS;
    this.photoID = store.state.route.params.photoID;
    this.withTexture = false;
    this.scale = 1;
    this.planeList = store.getters["active/ALL_PLANES_FROM_PHOTO"];
    this.polygonList = [];
    this.groupID = store.state.route.params.groupID;
    this.groupColor = store.getters["active/PLANE_GROUP"].color;
    this.group = store.getters["active/PLANE_GROUP"];
    this.planesInGroup = [];
    for (let planeID in this.planeList) {
        if (this.planeList[planeID].idDesignGroup == this.groupID) {
            this.planesInGroup.push(parseInt(planeID));
        }
    }
    this.changedGroupIDPlanes = {}; //plnaeID: groupID

    // this.applyTexture = new applyTexture(this.fabricJS);
    this.fabricJS.on('mouse:over', (e) => {
        if (!e.target)
            return

        if (!e.target.isPlane)
            return

        e.target.set({
            // fill: group.color,
            opacity: 0.9,
        });

        this.fabricJS.renderAll();

    });

    this.fabricJS.on('mouse:out', (e) => {
        if (!e.target)
            return

        if (!e.target.isPlane)
            return

        e.target.set({

            opacity: 0.7
        });

        this.fabricJS.renderAll();

    });

    this.fabricJS.on('mouse:down', (e) => {
        if (!e.target)
            return

        if (!e.target.isPlane)
            return

        const index = this.planesInGroup.indexOf(e.target.planeID)
        if (index > -1) {
            this.planesInGroup.splice(index, 1);
            let groupID = store.getters["active/ALL_PLANES_FROM_PHOTO"][e.target.planeID].idDesignGroup;
            if (groupID == this.groupID) {
                this.changedGroupIDPlanes[e.target.planeID] = null;
                e.target.set({
                    fill: '#bcbcbc',
                })
            } else if (groupID !== null && groupID !== undefined) {
                let group = store.getters["active/PLANE_GROUP_LIST"][groupID];
                this.changedGroupIDPlanes[e.target.planeID] = group;
                e.target.set({
                    fill: group.color,
                })
            }
        } else {
            this.changedGroupIDPlanes[e.target.planeID] = this.groupID;
            e.target.set({
                fill: this.groupColor,
            })
            this.planesInGroup.push(e.target.planeID)
        }
    });


    for (let planeID in this.planeList) {
        planeID = parseInt(planeID);
        if (!this.planeList[planeID].isComplete)
            continue;

        if (["window","door","cutout"].includes(this.planeList[planeID].type))
            continue;

        let pointList = this.planeList[planeID].points

        if (this.planeList[planeID].type === "additional")
            pointList = getAdditionalPolygonPoints(this.planeList[planeID], this.scale, 10);

        let polygon = planePolygonForShowPlanes(
            pointList,
            this.planeList[planeID].type,
            this.scale,
            planeID,
            this.withTexture
        );

        this.fabricJS.add(polygon);
    }

    this.fabricJS.renderAll();
    sortObjectsByZindex(this.fabricJS);

    this.destroy = () => {
        bus.$off("AcceptPlaneGrouping", this.acceptGrouping);
    }

    this.acceptGrouping = () => {
        const activeProjectUUID = store.state.route.params.projectUUID;
        const idActivePhoto = store.state.route.params.photoID;
        for (let planeID in this.changedGroupIDPlanes) {
            store.commit(`projectList/${activeProjectUUID}` +
                `/photoList/${idActivePhoto}` +
                `/planeList/${planeID}` +
                `/ID_DESIGN_GROUP`,
                this.changedGroupIDPlanes[planeID]);
        }
    }

    store.watch(() => store.state.UI.planeGroupSettings.color,
        (color) => {
            this.groupColor = color;
            this.planesInGroup.forEach((planeID) => {
                this.fabricJS._objects.forEach((object) => {
                    if (object.isPlane) {
                        if (planeID == object.planeID) {
                            object.set({
                                fill: this.groupColor,
                            })
                        }
                    }
                })
            })
            this.fabricJS.renderAll();
        })

    bus.$on("AcceptPlaneGrouping", this.acceptGrouping);

    return this

}
