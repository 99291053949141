import store from '../store/index'

export default ((to, from, next) => {
    if(to.matched.some(record => record.meta.accessRightsAP)) {
        if(store.state.profile.userRole !== "superadmin"){
            alert('Недостаточно прав!');
            next({
                name: 'admin',
            });
        }
    }
    next();
});