import {http_request} from "../../httpConfig";

export const colors = {
    namespaced: true,
    state: () => ({
        colors: [],
    }),
    mutations: {
        COLORS: (state, colors) => {
            state.colors = colors;
        },
    },
    getters: {
        SELF: state => {
            return state.colors;
        }
    },
    actions: {
        GET_COLORS: async function (context) {
            await http_request.post('/get_color', {})
                .then((request) => {
                    console.log(request.data);
                    context.commit('COLORS', request.data.colors);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
};
