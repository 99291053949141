<template>
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 5.71572L12 13L10.1131 12.5H7.5L5.2739 13L2 5.71572L5.63235 8.14381L8.5 2L10.1131 5.07191L11.7261 8.14381L15 5.71572Z" stroke="currentColor" stroke-width="1.5"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGAdmin"
    }
</script>

<style scoped>

</style>
