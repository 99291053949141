import {http_request} from "../../httpConfig";

export const feedback = {
    namespaced: true,
    actions: {
        SEND_FEEDBACK_DATA: async function (context, dataIn) {
            console.log(dataIn)
            http_request.post('/send_customer_request', dataIn)
                .then((request) => {
                    console.log(request.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    }
};
