import {http_request} from "../../../httpConfig";
import {v4 as uuidv4} from "uuid";


export const loadedProjectList = {
    namespaced: true,
    state: {
        uploadedProjects: [],
        numberLoadedItems: 8,
        waitingResponseToProjectsRequest: false,
        allUploaded: false,
        mask: '',
        requests: []
    },
    mutations: {
        DELETE_PROJECT_BY_UUID:(state, uuid) => {
            let indexProject = state.uploadedProjects.findIndex(project => project.uuid === uuid);
            if (indexProject !== -1) {
                console.log(state.uploadedProjects.splice(indexProject, 1));
            }
        },
        UPLOADED_PROJECTS:(state, newProjects) => {
            if (newProjects.length !== 0)
                state.uploadedProjects = state.uploadedProjects.concat(newProjects);
            else
                state.allUploaded = true;
        },
        MASK: (state, value) => {
            state.mask = value;
        },
        CLEAR_UPLOADED_PROJECTS: (state) => {
            state.uploadedProjects.splice(0);
            state.allUploaded = false;
        }
    },
    getters: {
        UPLOADED_PROJECTS: state => state.uploadedProjects,
        PROJECT_BY_UUID: state => uuid => {
            return state.uploadedProjects.find(project => project.uuid === uuid);
        }
    },
    actions: {
        COPY_ACTIVE_PROJECT: async function (context, nameNewProject) {
            const activeProjectUUID = context.rootState.route.params.projectUUID;
            const newProjectUUID = uuidv4();
            const projectForCopy = context.getters.PROJECT_BY_UUID(activeProjectUUID);
            if (!projectForCopy)
                return;

            let projectCopy = JSON.parse(JSON.stringify(projectForCopy.data_json));
            projectCopy.name = nameNewProject;
            const templateCopyProject = {
                data_json: projectCopy,
                uuid: newProjectUUID,
                customers: [],
                open_read: false,
                open_write: false,
                user_request: false
            };
            return await http_request.post('/save_project', templateCopyProject)
                .then((request) => {
                    console.log(request.data);
                    return newProjectUUID;
                })
                .catch((error) => {
                    console.error(error);
                });


            // context.commit(`${newProjectUUID}/NAME`, nameNewProject);
            // return newProjectUUID;
        },
        GET_DATA_PROJECT: function (context) {
            if (context.state.waitingResponseToProjectsRequest || context.state.allUploaded)
                return;

            const mask = context.state.mask.trim();
            let dataForSend = {
                part: {
                    from: context.state.uploadedProjects.length,
                    count: context.state.numberLoadedItems
                },
                user_request: false
            };
            if(mask.length !== 0){
                dataForSend.find = {
                    mask,
                    field: "data_json->>'name'"
                };
            }
            return dataForSend;
        },
        GET_PROJECTS:async function (context, inputData) {
            const dataForSend = await context.dispatch('GET_DATA_PROJECT');
            if(!dataForSend || context.state.waitingResponseToProjectsRequest)
                return;

            const projects = await http_request.post('/get_project', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('UPLOADED_PROJECTS', request.data.projects);
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });

            context.state.requests.push(projects);
        },
        GET_PROJECT_BY_UUID: async function (context, uuid) {
            const getProjectByUuid = (uuid) => {
                if (context.getters['PROJECT_BY_UUID'](uuid)) {
                    return context.getters['PROJECT_BY_UUID'](uuid);
                }

                let dataForSend = {
                    uuid: uuid
                };

                const project = http_request.post('/get_project_lite', dataForSend)
                    .then((request) => {
                        console.log('Проект', request.data);
                        context.commit('UPLOADED_PROJECTS', [request.data.project]);
                        return request.data.project;
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        return null;
                    })

                context.state.requests.push(project);
                return project;
            }
            if (!uuid) {
                console.log('Не указан UUID проекта!');
                return null;
            }
            if (context.state.requests.length !== 0) {
                await Promise.allSettled(context.state.requests);
                context.state.requests.length = 0;
                return getProjectByUuid(uuid);
            } else {
                return getProjectByUuid(uuid);
            }
        },
        GET_PROJECTS_LITE:async function (context, inputData) {
            const dataForSend = await context.dispatch('GET_DATA_PROJECT');
            if(!dataForSend)
                return;
            context.state.waitingResponseToProjectsRequest = true;
            await http_request.post('/get_project_lite', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('UPLOADED_PROJECTS', request.data.projects);
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                });

            context.state.waitingResponseToProjectsRequest = false;
        }
    }
};
