import {http_request} from "../../httpConfig";
// import {selectUser} from "./selectUser";

export const incoming = {
    namespaced: true,
    state: () => ({
        numberLoadedItems: 20,
        incomingProjects: [],
        waitingResponseToIncomingProjectsRequest: false,
        allUploaded: false,
        mask: ''
    }),
    getters:{
        INCOMING_PROJECTS: state => state.incomingProjects,
        INCOMING_BY_UUID: state => uuid => {
            return state.incomingProjects.find(incoming => incoming.uuid === uuid);
        },
    },
    mutations:{
        INCOMING_PROJECTS:(state, newIncomingProjects) => {
            if (newIncomingProjects.length !== 0)
                state.incomingProjects = state.incomingProjects.concat(newIncomingProjects);
            else
                state.allUploaded = true;
        },
        REMOVE_FROM_INCOMING: (state, uuid) => {
            state.incomingProjects.splice(state.incomingProjects.findIndex(incoming => incoming.uuid === uuid), 1);
        },
        SAVE_INCOMING: (state, newDataProject) => {
            let indexProject = state.incomingProjects.findIndex(incoming => incoming.uuid === newDataProject.uuid);
            if (indexProject === -1) {
                // state.incomingProjects.unshift(newDataProject);
                console.error('Проект с таким uuid не найден!');
            } else {
                state.incomingProjects.splice(indexProject, 1, newDataProject)
            }
        },
        MASK: (state, value) => {
            state.mask = value;
        },
        DELETE_INCOMING_PROJECT: (state, uuidProject) => {
            let indexProject = state.incomingProjects.findIndex(incoming => incoming.uuid === uuidProject);
            if (indexProject !== -1) {
                console.log(state.incomingProjects.splice(indexProject, 1));
            }
        },
        CLEAR_INCOMING_PROJECTS: (state) => {
            state.incomingProjects.splice(0);
            state.allUploaded = false;
        }
    },
    actions:{
        GET_INCOMING_PROJECTS:async function (context, inputData) {
            if (context.state.waitingResponseToIncomingProjectsRequest || context.state.allUploaded)
                return;

            context.state.waitingResponseToIncomingProjectsRequest = true;
            const mask = context.state.mask.trim();
            let dataForSend = {
                part: {
                    from: context.state.incomingProjects.length,
                    count: context.state.numberLoadedItems
                },
                user_request: true
            };
            if(mask.length !== 0){
                dataForSend.find = {
                    mask,
                    field: "data_json->>'name'"
                };
            }
            console.log(dataForSend);
            await http_request.post('/get_project', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('INCOMING_PROJECTS', request.data.projects);
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                    // if (error.message.includes('timeout')) {
                    //     //Ошибка
                    // } else if (error.response && context.rootState.user_authorized && error.response.status === 401) {
                    //     //Ошибка
                    // } else if (error.response.status === 423) {
                    //     //Ошибка
                    // }
                    context.state.allUploaded = true;
                });

            context.state.waitingResponseToIncomingProjectsRequest = false;
        },
        SAVE_INCOMING_PROJECTS:async function (context, inputData) {
            let incomingNeedsEdit = context.getters.INCOMING_BY_UUID(inputData.uuid);
            if(!incomingNeedsEdit){
                console.error('Не найден проект, который необходимо изменить.');
            }

            let copeDataIncoming = JSON.parse(JSON.stringify(incomingNeedsEdit));

            let newDataIncoming = Object.assign(copeDataIncoming, inputData);

            let sendData = {
                uuid: newDataIncoming.uuid,
                data_json: newDataIncoming
            }

            console.log('data save_project', sendData);
            http_request.post('/save_project', sendData)
                .then((request) => {
                    console.log(request.data);
                    context.commit('SAVE_INCOMING', newDataIncoming);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        ASSIGN_INCOMING_PROJECT: async function (context, inputData) {
            let dataForSend = {
                uuid: inputData.projectUUID,
                user_id: inputData.userId
            };
            console.log(dataForSend);
            http_request.post('/assign_incoming_project', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('REMOVE_FROM_INCOMING', inputData.projectUUID);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        DELETE_INCOMING: async function (context, uuid) {
            http_request.post('/delete_project', {uuid})
                .then((request) => {
                    console.log(request.data);
                    context.commit('DELETE_INCOMING_PROJECT', request.data.project_deleted);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}
