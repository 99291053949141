import {http_request} from "../../../httpConfig";

export const regions = {
    namespaced: true,
    state: () => ({
        numberLoadedItems: 5,
        regions: [],
        waitingResponseToRegionRequest: false,
        allUploaded: false,
        mask: ''
    }),
    mutations: {
        REGIONS: (state, regions) => {
            if (regions.length !== 0)
                state.regions = state.regions.concat(regions);
            else
                state.allUploaded = true;
        },
        CLEAR_REGIONS: (state) => {
            state.regions.splice(0);
            state.allUploaded = false;
        },
        MASK: (state, value) => {
            state.mask = value;
        }
    },
    getters: {
        SELF: state => {
            const mask = state.mask.trim().toLowerCase();
            if (mask.length !== 0) {
                return state.regions.filter(region => {
                    const regionTitle = region.title_ru.toLowerCase();
                    return !!(regionTitle.indexOf(mask) + 1);
                });
            } else {
                return state.regions;
            }
        }
    },
    actions: {
        GET_REGIONS: async function (context, inputData) {
            if (context.state.waitingResponseToRegionRequest || context.state.allUploaded)
                return;

            context.state.waitingResponseToRegionRequest = true;
            let dataForSend = {};
            // const mask = context.state.mask.trim();

            // let dataForSend = {
            //     part: {
            //         // from: context.getters.LENGTH,
            //         from: context.state.regions.length,
            //         count: context.state.numberLoadedItems
            //     }
            // };
            // if (mask.length !== 0) {
            //     dataForSend.find = {
            //         mask,
            //         field: 'title_ru'
            //     };
            // }
            console.log(dataForSend);
            await http_request.post('/get_regions', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('REGIONS', request.data.regions);
                })
                .catch((error) => {
                    context.state.allUploaded = true;
                    console.error(error);
                });

            context.state.waitingResponseToRegionRequest = false;
        },
    }
}
