var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-background" }, [
    _c("div", { staticClass: "modal-head-grid" }, [
      _c("div", { staticClass: "modal-label" }, [
        _vm._v("\n            Параметры проекта\n        ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-close-btn-mp",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("SVGClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container pt-23" }, [
      _c("div", { staticClass: "two-cols-modal-grid" }, [
        _c("div", [
          _c("div", { staticClass: "modal-form-grid" }, [
            _c("div", { staticClass: "modal-form-grid-label" }, [
              _vm._v("\n                        Название\n                    ")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.nameProject,
                  expression: "nameProject"
                }
              ],
              staticClass: "modal-form-grid-input",
              attrs: { type: "text", placeholder: "Название проекта" },
              domProps: { value: _vm.nameProject },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.nameProject = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-form-grid-label" }, [
              _vm._v(
                "\n                        Тип здания\n                    "
              )
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedTagIdPermanent,
                    expression: "selectedTagIdPermanent"
                  }
                ],
                staticClass: "modal-form-grid-input",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedTagIdPermanent = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.tagsPermanent, function(tag) {
                return _c("option", { domProps: { value: tag.id } }, [
                  _vm._v(_vm._s(tag.tag_name))
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-form-grid-label" }, [
              _vm._v(
                "\n                        Доступ в Lite\n                    "
              )
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.openRead,
                    expression: "openRead"
                  }
                ],
                staticClass: "modal-form-grid-input",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.openRead = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { domProps: { value: true } }, [
                  _vm._v("Доступен")
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: false } }, [_vm._v("Скрыт")])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-form-images-container" }, [
          _c(
            "div",
            { staticClass: "modal-form-imgs-carousel" },
            [
              _c("PreviewImgFromProject", {
                staticClass: "modal-form-img",
                attrs: { dataProject: _vm.project.data_json }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "blue-link" },
            [
              _vm._v(
                "\n                    Перейти к конфигуратору\n                    "
              ),
              _c("SVGArrowForward", { staticStyle: { "margin-left": "5px" } })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "mp-sidebar-borderline",
        staticStyle: { margin: "16px 0" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "project-tags-menu-head" }, [
        _c("div", { staticClass: "modal-form-grid-label" }, [_vm._v("Теги")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.tagsExpanded,
                expression: "!tagsExpanded"
              }
            ],
            staticClass: "tag-menu-switch",
            on: {
              click: function($event) {
                return _vm.switchTagsMenu(true)
              }
            }
          },
          [
            _vm._v("\n                Выбрать\n                "),
            _c("SVGExpandBot", {
              staticStyle: { "margin-left": "8px", width: "15px" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tagsExpanded,
                expression: "tagsExpanded"
              }
            ],
            staticClass: "tag-menu-switch",
            on: {
              click: function($event) {
                return _vm.switchTagsMenu(false)
              }
            }
          },
          [
            _vm._v("\n                Свернуть\n                "),
            _c("SVGCollapseTop", {
              staticStyle: { "margin-left": "8px", width: "15px" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.tagsExpanded,
              expression: "!tagsExpanded"
            }
          ],
          staticClass: "project-modal-tags pt-23"
        },
        _vm._l(_vm.projectTags, function(tag) {
          return _c("div", { staticClass: "pill-tag-static" }, [
            _vm._v(
              "\n                " + _vm._s(tag.tag_name) + "\n            "
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tagsExpanded,
              expression: "tagsExpanded"
            }
          ],
          staticClass: "project-modal-tags pt-23"
        },
        [
          _c(
            "vue-custom-scrollbar",
            {
              staticClass: "admin-scrollable-tags",
              staticStyle: { height: "190px" }
            },
            [
              _c("table", { staticClass: "admin-logs-table" }, [
                _c("thead"),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.categories_with_tags, function(category, index) {
                      return _c("tr", { class: { line: index % 2 === 0 } }, [
                        _c("td", { staticStyle: { "padding-left": "30px" } }, [
                          _c("span", [
                            _vm._v(_vm._s(category.tag_category_name))
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticStyle: { "padding-left": "30px" } },
                          _vm._l(category.tags, function(tag) {
                            return _c(
                              "div",
                              {
                                class: {
                                  "pill-tag": _vm.checkTagInProject(tag),
                                  "pill-outline-tag": !_vm.checkTagInProject(
                                    tag
                                  )
                                },
                                staticStyle: { "margin-top": "6px" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickOnTag(tag)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(tag.tag_name) +
                                    "\n                            "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    }),
                    _vm._v(" "),
                    _c(
                      "tr",
                      {
                        class: {
                          line: _vm.categories_with_tags.length % 2 === 0
                        }
                      },
                      [
                        _c("td", { staticStyle: { "padding-left": "30px" } }, [
                          _c("span", [_vm._v("Без категории")])
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticStyle: { "padding-left": "30px" } },
                          _vm._l(_vm.uncategorized_tags, function(tag) {
                            return _c(
                              "div",
                              {
                                class: {
                                  "pill-tag": _vm.checkTagInProject(tag),
                                  "pill-outline-tag": !_vm.checkTagInProject(
                                    tag
                                  )
                                },
                                staticStyle: { "margin-top": "6px" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickOnTag(tag)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(tag.tag_name) +
                                    "\n                            "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ],
                  2
                )
              ])
            ]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-data-container" }, [
      _c("div", { staticClass: "modal-lower-buttons-grid" }, [
        _c(
          "div",
          {
            staticClass: "blue-button modal-accept-button",
            on: {
              click: function($event) {
                return _vm.save()
              }
            }
          },
          [_vm._v("Сохранить")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blue-outline-button modal-accept-button",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v("Отмена")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }