import { render, staticRenderFns } from "./SVGPromptTwo.vue?vue&type=template&id=4d73705e&scoped=true&"
import script from "./SVGPromptTwo.vue?vue&type=script&lang=js&"
export * from "./SVGPromptTwo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d73705e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/metall-profil/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4d73705e')) {
      api.createRecord('4d73705e', component.options)
    } else {
      api.reload('4d73705e', component.options)
    }
    module.hot.accept("./SVGPromptTwo.vue?vue&type=template&id=4d73705e&scoped=true&", function () {
      api.rerender('4d73705e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/UI/SVGMP/SVGPromptTwo.vue"
export default component.exports