var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "thenavbar" }, [
    _c(
      "div",
      { staticClass: "logo-container", on: { click: _vm.toIndex } },
      [_c("SVGMetallProfil")],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "navbar-text-container" }, [
      _c(
        "div",
        { staticClass: "profile-button" },
        [
          _c("span", [_vm._v("Admin")]),
          _vm._v(" "),
          _c("SVGUser", { staticClass: "mh-10" })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }