var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projects-sidebar" },
    [
      _c(
        "div",
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "vue-custom-scrollbar",
            {
              staticClass: "sidebar-data-container mr-10 sidebar-tags-container"
            },
            [
              _vm.selectedTags.length === 0
                ? _c(
                    "div",
                    { staticClass: "sidebar-tags-container-placeholder" },
                    [
                      _vm._v(
                        "\n                    Теги не выбраны\n                "
                      )
                    ]
                  )
                : _vm._l(_vm.selectedTags, function(tag) {
                    return _c(
                      "div",
                      {
                        staticClass: "pill-tag",
                        on: {
                          click: function($event) {
                            return _vm.selectTag(tag)
                          }
                        }
                      },
                      [_vm._v(_vm._s(tag.tag_name))]
                    )
                  })
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "sidebar-data-container" }, [
            _c(
              "div",
              {
                staticClass: "tag-menu-switch",
                on: {
                  click: function($event) {
                    return _vm.switchTagsMenu(true)
                  }
                }
              },
              [
                _c("SVGExpandBot", { staticStyle: { "margin-right": "8px" } }),
                _vm._v("\n                    Теги\n                ")
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tagsMenuStatus,
                  expression: "tagsMenuStatus"
                }
              ],
              staticClass: "expanded-tags-menu"
            },
            [
              _c("div", { staticClass: "sidebar-data-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "tag-menu-switch",
                    on: {
                      click: function($event) {
                        return _vm.switchTagsMenu(false)
                      }
                    }
                  },
                  [
                    _c("SVGCollapseTop", {
                      staticStyle: { "margin-right": "8px" }
                    }),
                    _vm._v(
                      "\n                        Свернуть\n                    "
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "vue-custom-scrollbar",
                {
                  staticClass: "sidebar-data-container mr-10 scroll-tags-menu"
                },
                [
                  _vm._l(_vm.categories_with_tags, function(category) {
                    return [
                      _c("div", { staticClass: "tag-category-grid" }, [
                        _c("div", { staticClass: "tag-category-label" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(category.tag_category_name) +
                              "\n                            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "tag-category-container" },
                          _vm._l(category.tags, function(tag) {
                            return _c(
                              "div",
                              {
                                staticClass: "pill-tag",
                                on: {
                                  click: function($event) {
                                    return _vm.selectTag(tag)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tag.tag_name))]
                            )
                          }),
                          0
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mp-sidebar-borderline" })
                    ]
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tag-category-grid" }, [
                    _c("div", { staticClass: "tag-category-label" }, [
                      _vm._v(
                        "\n                            Разное\n                        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tag-category-container" },
                      _vm._l(_vm.uncategorized_tags, function(tag) {
                        return _c(
                          "div",
                          {
                            staticClass: "pill-tag",
                            on: {
                              click: function($event) {
                                return _vm.selectTag(tag)
                              }
                            }
                          },
                          [_vm._v(_vm._s(tag.tag_name))]
                        )
                      }),
                      0
                    )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mp-sidebar-borderline" })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vue-custom-scrollbar",
        {
          staticClass: "projects-list-container",
          on: { "ps-y-reach-end": _vm.scrollReachEnd }
        },
        [
          _c(
            "div",
            { staticClass: "sidebar-data-container projects-list" },
            _vm._l(_vm.projects, function(project) {
              return _c(
                "div",
                {
                  staticClass: "sidebar-project-preview",
                  class: {
                    "active-sidebar-project-preview":
                      project.uuid == _vm.selectProjectUuid
                  },
                  on: {
                    click: function($event) {
                      return _vm.openProject(project)
                    }
                  }
                },
                [
                  _c("PreviewImgFromProject", {
                    staticClass: "sidebar-project-preview-image",
                    attrs: { dataProject: project.data_json }
                  })
                ],
                1
              )
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _c("SmallLoader", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.requestedDataInProgress,
            expression: "requestedDataInProgress"
          }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebar-data-container" }, [
      _c("div", { staticClass: "mp-sidebar-label" }, [
        _vm._v("Галерея проектов")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }