<template>
    <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.1831 11.6364C29.3957 11.6364 32 9.03148 32 5.81818C32 2.60489 29.3957 0 26.1831 0C22.9704 0 20.3661 2.60489 20.3661 5.81818C20.3661 6.20177 20.4032 6.57669 20.4741 6.93954L10.5403 11.4409C9.4842 9.97422 7.76207 9.01931 5.81695 9.01931C2.60434 9.01931 0 11.6242 0 14.8375C0 18.0508 2.60434 20.6557 5.81695 20.6557C7.76207 20.6557 9.4842 19.7008 10.5403 18.234L20.4741 22.8786C20.4032 23.2415 20.3661 23.6164 20.3661 24C20.3661 27.2133 22.9704 29.8182 26.1831 29.8182C29.3957 29.8182 32 27.2133 32 24C32 20.7867 29.3957 18.1818 26.1831 18.1818C24.2149 18.1818 22.4751 19.1594 21.4225 20.6557L11.5078 16.1492C11.5078 15.7341 11.5904 14.0175 11.5078 13.627L21.3249 9.01931C22.3655 10.5961 24.1528 11.6364 26.1831 11.6364Z" fill="currentColor"/>
        <path d="M30.1822 5.8183C30.1822 8.02744 28.3913 9.8183 26.1822 9.8183C23.9731 9.8183 22.1822 8.02744 22.1822 5.8183C22.1822 3.60916 23.9731 1.8183 26.1822 1.8183C28.3913 1.8183 30.1822 3.60916 30.1822 5.8183Z" fill="white"/>
        <path d="M30.1822 24.0001C30.1822 26.2093 28.3913 28.0001 26.1822 28.0001C23.9731 28.0001 22.1822 26.2093 22.1822 24.0001C22.1822 21.791 23.9731 20.0001 26.1822 20.0001C28.3913 20.0001 30.1822 21.791 30.1822 24.0001Z" fill="white"/>
        <path d="M9.81854 14.9092C9.81854 17.1183 8.02768 18.9092 5.81854 18.9092C3.6094 18.9092 1.81854 17.1183 1.81854 14.9092C1.81854 12.7001 3.6094 10.9092 5.81854 10.9092C8.02768 10.9092 9.81854 12.7001 9.81854 14.9092Z" fill="white"/>
    </svg>

</template>

<script>
    export default {
        name: "SVGShare"
    }
</script>

<style scoped>

</style>
