var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "34",
        height: "20",
        viewBox: "0 0 34 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M33.2251 1.35625C33.4587 0.574995 33.2251 -4.41064e-06 32.1016 -4.41064e-06H28.384C27.4372 -4.41064e-06 27.0017 0.493745 26.7682 1.04375C26.7682 1.04375 24.8747 5.61249 22.1985 8.575C21.3338 9.43124 20.9361 9.70625 20.4627 9.70625C20.2292 9.70625 19.8694 9.43124 19.8694 8.64999V1.35625C19.8694 0.418745 19.6043 -4.41064e-06 18.8217 -4.41064e-06H12.977C12.3837 -4.41064e-06 12.0302 0.437496 12.0302 0.843746C12.0302 1.73125 13.3683 1.9375 13.5072 4.4375V9.8625C13.5072 11.05 13.2926 11.2687 12.8192 11.2687C11.5568 11.2687 8.48932 6.68125 6.67153 1.43125C6.30545 0.412496 5.94568 -4.41064e-06 4.9926 -4.41064e-06H1.27498C0.2146 -4.41064e-06 0 0.493745 0 1.04375C0 2.01875 1.26235 6.8625 5.87625 13.2625C8.95008 17.6312 13.2799 20 17.2185 20C19.5854 20 19.8757 19.475 19.8757 18.5687C19.8757 14.3937 19.6611 14 20.8478 14C21.3969 14 22.3436 14.275 24.5528 16.3812C27.0775 18.8812 27.494 20 28.9079 20H32.6255C33.6859 20 34.2224 19.475 33.9131 18.4375C33.2062 16.2562 28.4282 11.7687 28.2136 11.4687C27.6645 10.7687 27.8223 10.4562 28.2136 9.83125C28.2199 9.825 32.758 3.5 33.2251 1.35625Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }