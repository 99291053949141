import {DesignPlaneData} from "./DesignPlaneData";

export function DesignGroup() {
    this.namespaced = true;
    this.state = {
        constructorName: 'DesignGroup',
        wall: new DesignPlaneData(),
        additional: new DesignPlaneData(),
        platband: new DesignPlaneData(),
        roof: new DesignPlaneData(),
        fence: new DesignPlaneData(),
        shadows: true,
        lightCorrection: true,
    };
    this.getters = {
        PLANE_PARAM: state => planeType => state[planeType],
        DESIGN_SETTINGS_BY_PLANE_TYPE: state => type => {
            return state[type];
        },
        PRODUCTS_USED: state => {
            let productsUsed = [];
            for (let prop in state) {
                if(typeof state[prop] === "object" && state[prop].uuidProduct) {
                    const uuidProduct = state[prop].uuidProduct;
                    if (!productsUsed.includes(uuidProduct)) {
                        productsUsed.push(uuidProduct);
                    }
                }
            }
            return productsUsed;
        },
        SHADOWS: state => {
            return state.shadows;
        },
        LIGHT_CORRECTION: state => {
            return state.lightCorrection;
        }
    };
    this.mutations = {
        INIT: function (state, data) {
            for (let param in data) {
                if(typeof state[param] !== "object"){
                    state[param] = data[param];
                }
            }
        },
        DESIGN_SETTINGS_BY_PLANE_TYPE: function (state, data) {
            for (let planeType in data) {
                if (typeof state[planeType] === "object") {
                    const planeSettings = JSON.parse(JSON.stringify(state[planeType]));
                    for (let param in data[planeType]) {
                        if ((param in planeSettings) && (data[planeType][param] || typeof data[planeType][param] === "number")) {
                            planeSettings[param] = data[planeType][param];
                        }
                    }
                    state[planeType] = planeSettings;
                }
            }
        },
        SHADOWS: (state, shadows) => {
            state.shadows = shadows;
        },
        LIGHT_CORRECTION: (state, lightCorrection) => {
            state.lightCorrection = lightCorrection;
        },
    };
    this.actions = {
        INIT: function (context, inputData) {
            context.commit('DESIGN_SETTINGS_BY_PLANE_TYPE', inputData.data);
            context.commit('INIT', inputData.data);
        },
        ROTATE_LEFT: function (context, planeType) {
            if(!context.state[planeType]){
                console.error('Указан неверный тип плоскости: ' + planeType);
                return;
            }

            const currentAngle = parseInt(context.state[planeType].textureRotationAngle);
            const newAngle = currentAngle - 45;
            if(newAngle < -90)
                return;

            context.commit('DESIGN_SETTINGS_BY_PLANE_TYPE', {[planeType]: {
                    'textureRotationAngle': newAngle
                }});
        },
        ROTATE_RIGHT: function (context, planeType) {
            if(!context.state[planeType]){
                console.error('Указан неверный тип плоскости: ' + planeType);
                return;
            }

            const currentAngle = parseInt(context.state[planeType].textureRotationAngle);
            const newAngle = currentAngle + 45;
            if(newAngle > 90)
                return;

            context.commit('DESIGN_SETTINGS_BY_PLANE_TYPE', {[planeType]: {
                    'textureRotationAngle': newAngle
                }});
        },
    };
}
