import store from "../../store";
import {planePolygonForShowPlanes} from "../primitives/planePolygon";
import {planesDrawer} from "./drawPlanes";
import {sortObjectsByZindex} from "./sortObjectsByZindex";
import {fabric} from "fabric";
import {addImg} from "./addImg";
import {sourceImagePreset} from "./sourceImagePreset";
import {getScale, getNormalizeScale} from "./common";
import {getAdditionalPolygonPoints} from "../primitives/additionalPlane";
import {updatePhotoAnother} from "../../httpConfig";
import {checkTextureOnServer} from "../../tools";

export const renderDesign = async function(designID, photoID, previewFlag) {
    this.designID = designID;
    this.photoID = photoID;
    const activeProjectUUID = store.state.route.params.projectUUID;
    let src
    if (previewFlag)
        src = store.getters[`projectList/${activeProjectUUID}/designList/${this.designID}/RENDERED_PREVIEW_PHOTO_BY_ID`](photoID);
    else
        src = store.getters[`projectList/${activeProjectUUID}/designList/${this.designID}/RENDERED_PHOTO_BY_ID`](photoID);

    if (src) {
        if (await checkTextureOnServer(src))
            return src;
    }

    this.design = store.getters['active/ALL_DESIGNS'][designID];
    let temp_fabric_canvas_dom = document.createElement("canvas");
    this.fabricJS = new fabric.Canvas(temp_fabric_canvas_dom);

    // if (photoID) {
    this.planeList = store.getters["active/GET_ALL_PHOTO"][this.photoID].planeList;
    this.size = await store.dispatch("active/GET_ACTIVE_IMG_SIZE_BY_ID", this.photoID);
    this.scale = getNormalizeScale(this.size, this.fabricJS);
    this.sourceImage = await addImg(
        store.getters[`projectConfiguration/GENERATE_URL`](
            store.getters[`active/GET_ALL_PHOTO`][photoID].src)).then(obj=>sourceImagePreset(obj,this.scale));

    this.fabricJS.add(this.sourceImage);
    this.sourceImage.sendToBack();


    this.planesDrawer = await new planesDrawer(
        this.fabricJS,
        this.planeList,
        this.design,
        this.photoID,
        this.sourceImage,
        this.scale,
        true);

    this.polygonList = [];
    let index = 0;
    for (let planeID in this.planeList) {
        planeID = parseInt(planeID);
        if (!this.planeList[planeID].isComplete)
            continue;
        let pointList = this.planeList[planeID].points

        if (this.planeList[planeID].type === "additional")
            pointList = getAdditionalPolygonPoints(this.planeList[planeID], 1, 10);

        let polygon = planePolygonForShowPlanes(
            pointList,
            this.planeList[planeID].type,
            this.scale,
            planeID
        );

        this.polygonList.push(polygon)
        this.fabricJS.add(polygon);

        if ((["door", "window", "cutout"].includes(this.planeList[planeID].type))) {
            await this.planesDrawer.setPolygonSurface(polygon, planeID);
            continue;
        }

        if (this.design)
            if (this.design.designGroupList)
                if (this.design.designGroupList[this.planeList[planeID].idDesignGroup]) {
                    await this.planesDrawer.setPolygonSurface(polygon, planeID);
                }

        index+=1;
    }

    this.fabricJS.renderAll();
    sortObjectsByZindex(this.fabricJS);
    this.fabricJS.renderAll();
    this.saveRender = (quality) => {
        return new Promise((resolve, reject) => {
            let designTexture = this.fabricJS.toDataURL({
                left: 0,
                top: 0,
                width: this.size.width*this.scale,
                height: this.size.height*this.scale,
                multiplier: quality,
                format: "jpeg",
                quality: 0.4,
            })
            console.log(designTexture)
            let byteString = atob(designTexture.split(',')[1]);
            let mimeString = designTexture.split(',')[0].split(':')[1].split(';')[0]
            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            let blob = new Blob([ab], {type: mimeString});

            updatePhotoAnother(blob).then((result)=>{
                resolve(result);
            })
        });
    }

    let blobPromiseFullSize = this.saveRender(1);
    let blobPromisePreviewSize = this.saveRender(0.2);
    Promise.all([blobPromiseFullSize, blobPromisePreviewSize]).then(()=>{
        this.fabricJS.dispose();
    })


    let previewSrc = await blobPromisePreviewSize.then((request)=>{
        if (request.data.fileList){
            store.commit(`projectList/${activeProjectUUID}/designList/${this.designID}/RENDERED_PHOTOS_PREVIEW`, {photoId: this.photoID, photoSrc: request.data.fileList[0]});
            return request.data.fileList[0];
        }
    })

    let fullSizeSrc = await blobPromiseFullSize.then((request)=>{
        if (request.data.fileList){
            store.commit(`projectList/${activeProjectUUID}/designList/${this.designID}/RENDERED_PHOTOS`, {photoId: this.photoID, photoSrc: request.data.fileList[0]});
            return request.data.fileList[0];
        }
    })

    if (previewFlag)
        return previewSrc
    else
        return fullSizeSrc

}
