import {DesignGroupList} from "./DesignGroupList";

export function Design() {
    this.namespaced = true;
    this.state = {
        constructorName: 'Design',
        renderedPhotos: [],
        renderedPhotosPreview: [],
    };

    this.getters = {
        PRODUCTS_USED: (state, getters) => {
            let productsUsed = [];
            for (let idDesignGroup in state.designGroupList) {
                const productsArray = getters[`designGroupList/${idDesignGroup}/PRODUCTS_USED`];
                for(let prodUUID of productsArray){
                    if(!productsUsed.includes(prodUUID)){
                        productsUsed.push(prodUUID);
                    }
                }
            }
            return productsUsed;
        },
        RENDERED_PHOTO_BY_ID: state => photoId => {
            const photo = state.renderedPhotos.find(photo => (photo.photoId == photoId));
            if(photo)
                return photo.photoSrc;
        },
        RENDERED_PREVIEW_PHOTO_BY_ID: state => photoId => {
            const photo = state.renderedPhotosPreview.find(photo => (photo.photoId == photoId));
            if(photo)
                return photo.photoSrc;
        }
    };

    this.mutations = {
        INIT: function (state, data) {
            if (data.renderedPhotos)
                state.renderedPhotos = data.renderedPhotos;
            if (data.renderedPhotosPreview)
                state.renderedPhotosPreview = data.renderedPhotosPreview;
        },
        RENDERED_PHOTOS: function (state, photoData) {
            const photoId = photoData.photoId;
            const photoSrc = photoData.photoSrc;
            const indexOldPhoto = state.renderedPhotos.findIndex(photo => (photo.photoId == photoId));
            const newPhoto = {
                photoId,
                photoSrc: photoSrc
            }
            if(indexOldPhoto > -1){
                state.renderedPhotos[indexOldPhoto] = newPhoto;
            } else {
                state.renderedPhotos.push(newPhoto);
            }
        },
        RENDERED_PHOTOS_PREVIEW: function (state, photoData) {
            const photoId = photoData.photoId;
            const photoSrc = photoData.photoSrc;
            const indexOldPhoto = state.renderedPhotosPreview.findIndex(photo => (photo.photoId == photoId));
            const newPhoto = {
                photoId,
                photoSrc: photoSrc
            }
            if(indexOldPhoto > -1){
                state.renderedPhotosPreview[indexOldPhoto] = newPhoto;
            } else {
                state.renderedPhotosPreview.push(newPhoto);
            }
        },
        CLEAR_RENDERED_PHOTOS: function (state) {
            state.renderedPhotos.splice(0);
            state.renderedPhotosPreview.splice(0);
        }
    };

    this.actions = {
        INIT: function (context, inputData) {
            context.commit('INIT', inputData.data);
            const pathDesignGroupsList = inputData.path.concat(['designGroupList']);
            this.registerModule(pathDesignGroupsList, new DesignGroupList());
        },
    };
}
