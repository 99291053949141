<template>
    <div class="modal-data-container modal-tags-grid">
        <vue-custom-scrollbar class="admin-scrollable-tags right-border">
            <table class="admin-logs-table">
                <thead>
                <tr class="admin-tags-table-head">
                    <th style="padding-left: 30px">
                        Категория
                    </th>
                    <th style="padding-left: 30px">Теги</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(category, index) in categories_with_tags" v-bind:class="{'line': index % 2 === 0}">
                    <td style="padding-left: 30px">
                        <div class="th-double">
                            <div>{{category.tag_category_name}}</div>
                        </div>
                    </td>
                    <draggable :id="category.id" :list=category.tags :sort="false" @end="onEnd"
                               :group="{ name: 'people'}" style="padding-left: 30px">
                        <div v-for="tag in category.tags" :id="tag.id" class="pill-tag-static" style="margin-top: 6px">
                            {{tag.tag_name}}
                        </div>
                    </draggable>
                </tr>
                </tbody>
            </table>
        </vue-custom-scrollbar>
        <div class="tags-sorting-right-grid">
            <vue-custom-scrollbar class="admin-scrollable-tags" style="height: 300px">
                <table class="admin-logs-table">
                    <thead>
                    <tr class="admin-tags-table-head">
                        <th style="padding-left: 30px">Несгруппированные</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <draggable :id="null" :list=uncategorized_tags :sort="false" @end="onEnd" :group="{ name: 'people'}"
                                   style="padding-left: 30px">
                            <div v-for="tag in uncategorized_tags" :id="tag.id" class="pill-tag-static"
                                 style="margin-top: 6px">
                                {{tag.tag_name}}
                            </div>
                        </draggable>
                    </tr>
                    </tbody>
                </table>
            </vue-custom-scrollbar>

            <div class="tag-input-grid-sized">
                <div class="blue-button modal-accept-button tags-modal-button" v-on:click="save()">Сохранить</div>
                <div class="blue-outline-button modal-accept-button tags-modal-button" v-on:click="cancel()">Отмена</div>
            </div>
        </div>


<!--        <button v-on:click="save()">Сохранить!</button>-->
    </div>
</template>

<script>
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import draggable from 'vuedraggable';
    import {http_request} from "../../../httpConfig";


    export default {
        name: "ModalTagsSorting",
        components: {
            vueCustomScrollbar,
            draggable,
        },
        data() {
            return {
                categories_with_tags: [],
                uncategorized_tags: [],
                historyOfMovingTags: []
            }
        },
        methods: {
            onEnd(elem) {
                if (elem.to.id !== elem.from.id) {
                    this.historyOfMovingTags.push({
                        tagId: elem.clone.id,
                        toCategoryId: elem.to.id,
                        fromCategoryId: elem.from.id
                    })
                    console.log(elem.clone.id,);
                }
            },
            save() {
                let changedTagForSave = [];
                for (let i = this.historyOfMovingTags.length - 1; i >= 0; i--) {
                    const tagForCheck = this.historyOfMovingTags[i];
                    let tegForSave = {};
                    if (changedTagForSave.find(tag => tag.id == tagForCheck.tagId)) {
                        continue;
                    }

                    tegForSave['id'] = tagForCheck.tagId;

                    // if (+tagForCheck.toCategoryId) {
                    //     tegForSave['category_id'] = +tagForCheck.toCategoryId;
                    // } else {
                    //     tegForSave['category_id'] = null;
                    // }

                    tegForSave['category_id'] = +tagForCheck.toCategoryId ? +tagForCheck.toCategoryId : null;
                    changedTagForSave.push(tegForSave);
                }
                this.historyOfMovingTags.length = 0;
                console.log('changedTagForSave', changedTagForSave);
                http_request.post('/update_tags', {tags: changedTagForSave})
                    .then((request) => {
                        console.log(request.data);
                        this.getCategoriesWithTags();
                    })
                    .catch((err) => {
                        alert('Ошибка. Изменения не сохранены.')
                        console.log(err);
                    })
            },
            cancel() {
                this.getCategoriesWithTags();
            },
            getCategoriesWithTags() {
                this.categories_with_tags.length = 0;
                this.uncategorized_tags.length = 0;
                http_request.post('/get_categories_with_tags', {})
                    .then((request) => {
                        console.log(request.data);
                        this.categories_with_tags.push(...request.data.tag_categories);
                        this.uncategorized_tags.push(...request.data.uncategorized.filter(tag => !tag.permanent));
                    })
                    .catch((err) => {
                        alert('Ошибка при загрузке списка тегов.');
                        console.log(err);
                    })
            }
        },
        mounted() {
            this.getCategoriesWithTags();
        }
    }
</script>

<style scoped>
    table, th, tr, td {
        border: none;
    }

    .admin-logs-table {
        border: none;
    }
</style>
