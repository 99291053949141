import { render, staticRenderFns } from "./SVGMetallProfilLogo.vue?vue&type=template&id=8d495cf4&scoped=true&"
import script from "./SVGMetallProfilLogo.vue?vue&type=script&lang=js&"
export * from "./SVGMetallProfilLogo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d495cf4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/metall-profil/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8d495cf4')) {
      api.createRecord('8d495cf4', component.options)
    } else {
      api.reload('8d495cf4', component.options)
    }
    module.hot.accept("./SVGMetallProfilLogo.vue?vue&type=template&id=8d495cf4&scoped=true&", function () {
      api.rerender('8d495cf4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/UI/SVGMP/SVGMetallProfilLogo.vue"
export default component.exports