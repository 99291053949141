import store from "../../store";
import {planePolygonForShowPlanes} from "../primitives/planePolygon";
import {getScaleTranslatedPoints} from "../../math/getAreaCorrected";
import {cutPlane} from "./cutPlane";
import {getBoundingBox} from "../Tools/pointsTools";
import {getAdditionalPolygonPoints} from "../primitives/additionalPlane";
import {Point} from "@mathigon/fermat";
import {sortObjectsByZindex} from "./sortObjectsByZindex";
import {get3dTextureFromModelV3} from "../../threeJS/getTextureFromModelV3";
import {bus} from "../../vue_bus";
import {sleep} from "./common";

export const planesDrawer = async function (fabricJS, planeList, design, photoID, sourceImage, scale) {
    this.photoID = photoID;
    this.fabricJS = fabricJS;
    this.planeList = planeList;
    this.design = design;
    this.sourceImage = sourceImage;
    this.scale = scale;
    this.activeProjectUUID = store.state.route.params.projectUUID;

    this.textureGenerator = await new get3dTextureFromModelV3(
        this.scale / window.devicePixelRatio,
        photoID,
    )

    // this.shadowMaskSRC = await store.dispatch(`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/GET_SHADOW_MASK`);
    // console.log(this.shadowMaskSRC)
     this.getCanvasWithImg = async function (src, scale) {
        return await new Promise((resolve) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.addEventListener("load", function () {
                const canvas = document.createElement('canvas');
                canvas.width = img.width * scale;
                canvas.height = img.height * scale;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(
                    img,
                    0,
                    0,
                    img.width,
                    img.height,
                    0,
                    0,
                    canvas.width,
                    canvas.height);
                resolve({canvas: canvas, context: ctx});
            }, false);
            img.src = store.getters[`projectConfiguration/GENERATE_URL`](src);
        })
    }

    this.shadowMask = await this.getCanvasWithImg(
        await store.dispatch(`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/GET_SHADOW_MASK`),
        this.scale / window.devicePixelRatio
    )

    this.applyShadowMask = async (polygon, texture) => {
        const boundingRect = polygon.getBoundingRect(true);
        let width = Math.ceil(boundingRect.width/devicePixelRatio);
        let height = Math.ceil(boundingRect.height/devicePixelRatio);
        let imageData = this.shadowMask.context.getImageData(
            Math.floor(boundingRect.left/devicePixelRatio),
            Math.floor(boundingRect.top/devicePixelRatio),
            width,
            height);


        const resultCanvas = document.createElement('canvas');
        resultCanvas.width = width;
        resultCanvas.height = height;

        let textureCanvas = await this.getCanvasWithImg(
            texture,
            1
        )

        let textureImageData = textureCanvas.context.getImageData(
            0,
            0,
            width,
            height);


        for (let i = 0; i < imageData.data.length; i += 1) {
            imageData.data[i] = Math.pow(imageData.data[i],0.5) * textureImageData.data[i]/12;
            // imageData.data[i] = imageData.data[i] * textureImageData.data[i]/140;
            // imageData.data[i] = textureImageData.data[i];
        }

        resultCanvas.getContext('2d').putImageData(
            imageData,
            0,
            0,
            0,
            0,
            width,
            height
        );

        return resultCanvas.toDataURL("webp", 0.7);
    }

    this.getLightnessMean = (textureImageData) => {
        let meanTexture = 0;
        let countTexture = 0;
        for (let i = 0; i < textureImageData.data.length; i += 4) {
            meanTexture += 0.299 * textureImageData.data[i] + 0.587 * textureImageData.data[i+1]
                + 0.114 * textureImageData.data[i+2];
            countTexture += 1;
        }
        return meanTexture/countTexture;
    }

    this.applyLightCorrection = async (polygon, texture) => {
        const boundingRect = polygon.getBoundingRect(true);
        let width = Math.ceil(boundingRect.width/devicePixelRatio);
        let height = Math.ceil(boundingRect.height/devicePixelRatio);

        let textureCanvas = await this.getCanvasWithImg(
            texture,
            1
        );

        let shadowMaskPart = this.shadowMask.context.getImageData(
            Math.floor(boundingRect.left/devicePixelRatio),
            Math.floor(boundingRect.top/devicePixelRatio),
            width,
            height);

        let shadowMaskFull = this.shadowMask.context.getImageData(
            0,
            0,
            this.shadowMask.canvas.width,
            this.shadowMask.canvas.height);

        let textureImageData = textureCanvas.context.getImageData(
            0,
            0,
            width,
            height);

        let meanOriginalPart = this.getLightnessMean(shadowMaskPart);
        let meanOriginalFull = this.getLightnessMean(shadowMaskFull);
        let meanTexture = this.getLightnessMean(textureImageData);

        let delta = 0.4*((0.6*meanOriginalPart + 0.4*meanOriginalFull) - meanTexture);

        const resultCanvas = document.createElement('canvas');
        resultCanvas.width = width;
        resultCanvas.height = height;

        for (let i = 0; i < textureImageData.data.length; i += 4) {
            for (let c = 0; c < 3; c++) {
                    textureImageData.data[i+c] += delta;
            }
        }

        resultCanvas.getContext('2d').putImageData(
            textureImageData,
            0,
            0,
            0,
            0,
            width,
            height
        );

        return resultCanvas.toDataURL("webp", 0.7);
    }

    this.setTextureFromURL = (obj, src) => {
        return new Promise((resolve) => {
            fabric.Image.fromURL(
                store.getters[`projectConfiguration/GENERATE_URL`](src),
                (fabric_img) => {
                    let pattern = this.get_patten(obj, fabric_img);
                    this.accept_texture(obj, pattern);
                    resolve();
                }, {crossOrigin: 'Anonymous'})
        })

    }

    this.get_patten = (obj, texture) => {
        //TODO need to compute scale
        texture.scale(1);

        let patternSourceCanvas = new fabric.StaticCanvas();
        patternSourceCanvas.add(texture);
        patternSourceCanvas.renderAll();
        // noinspection JSUnusedGlobalSymbols
        let pattern = new fabric.Pattern({
            source: function () {
                patternSourceCanvas.setDimensions({
                    width: texture.getScaledWidth(),
                    height: texture.getScaledHeight()
                });
                patternSourceCanvas.renderAll();
                return patternSourceCanvas.getElement();
            },
            repeat: 'no-repeat'
            // repeat: 'repeat'
        });

        return pattern;
    };

    this.accept_texture = (obj, pattern) => {
        obj.set({
            fill: pattern,
            objectCaching: false,
        });
        this.fabricJS.requestRenderAll();
    };

    const cuttingPlanes = ["window", "door", "cutout"]

    this.renderingTasks = [];
    this.atLeastOneTask = false;
    this.textureRendering = false;
    this.setTextureFromProduct = async (polygon, planeID, productUUID, withShadow, withLight, withProgress) => {
        const promises = [];
        if (this.textureRendering) {
            this.renderingTasks.push([polygon, planeID, productUUID, withShadow, withLight, withProgress])
            return
        }
        this.textureRendering = true;
        let product = await store.dispatch('UI/products/PRODUCT_BY_UUID', productUUID, {root: true})

        //TODO need to refactoring
        if (!product) {
            polygon.set({
                opacity: 0.3,
            })
            this.textureRendering = false;
            if (this.renderingTasks.length) {
                let params = this.renderingTasks[0]
                this.renderingTasks.splice(0, 1);
                this.atLeastOneTask = true;
                await this.setTextureFromProduct(...params);
            }
            return
        }

        // if (!product.texture)
        //     return

        //TODO may be unnecessary if texture periodically will not disappear, comment for improve performance
        // if (!(await checkTextureOnServer(product.texture)))
        //     return

        const scalePhoto = store.getters[`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/SCALE`];

        if (!scalePhoto)
            return

        let boundingBox = new getBoundingBox(this.planeList[planeID].points);
        const getTexturePromise = this.textureGenerator.getTexture(
            planeID,
            product,
            boundingBox,
        );
        let texture = await getTexturePromise;

        if (withProgress)
            store.dispatch("IN_PROGRESS", getTexturePromise);
        // else
        //     await sleep(20);
        this.textureRendering = false;
        if (this.renderingTasks.length) {
            let params = this.renderingTasks[0]
            this.renderingTasks.splice(0, 1);
            this.atLeastOneTask = true;
            await this.setTextureFromProduct(...params);
        } else {
            if (this.atLeastOneTask)
                bus.$emit("completeUpdatingTextures");
        }

        if (texture) {
            // if (withShadow) {
            //     texture = await this.applyShadowMask(polygon, texture);
            //     if (!withProgress)
            //         await sleep(20);
            // }
            // if (withLight) {
            //     texture = await this.applyLightCorrection(polygon, texture);
            //     if (!withProgress)
            //         await sleep(20);
            // }
            const setTexturePromise = this.setTextureFromURL(polygon, texture).then(()=> {
                polygon.set({
                    opacity: 1,
                })
            });
            if (withProgress)
                store.dispatch("IN_PROGRESS", setTexturePromise);

            await setTexturePromise;
        }
    }

    this.setPolygonSurface = async (polygon, planeID, withProgress) => {
        if (this.planeList[planeID].type === "wall" && this.design) {
            return this.setTextureFromProduct(
                polygon,
                planeID,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].wall.uuidProduct,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].shadows,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].lightCorrection,
                withProgress
            )
        } else if (this.planeList[planeID].type === "fence" && this.design) {
            return this.setTextureFromProduct(
                polygon,
                planeID,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].fence.uuidProduct,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].shadows,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].lightCorrection,
                withProgress
            )
        } else if (this.planeList[planeID].type === "roof" && this.design) {
            return this.setTextureFromProduct(
                polygon,
                planeID,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].roof.uuidProduct,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].shadows,
                this.design.designGroupList[this.planeList[planeID].idDesignGroup].lightCorrection,
                withProgress
            )
        }
        else if (cuttingPlanes.includes(this.planeList[planeID].type)) {
            polygon.set({
                opacity: 1,
            })
            if (this.planeList[planeID].type === "window") {
                // console.log("window", this.planeList[planeID]);
                // let perspectiveMatrix = await store.dispatch(
                //     `projectList/${store.state.route.params.projectUUID}`+
                //     `/photoList/${this.photoID}`+
                //     `/planeList/${planeID}`+
                //     `/GET_PERSPECTIVE_MATRIX`,
                //     {planeID, photoID: this.photoID});

                // console.log(perspectiveMatrix);
                // new fabric.Polygon(points, {
                //     strokeWidth: 0,
                //     fill: '#bcbcbc',
                //     opacity: 1,
                //     selectable: false,
                //     hasBorders: false,
                //     hasControls: false,
                //     evented: false,
                //     lockMovementX: true,
                //     lockMovementY: true,
                //     objectCaching: false,
                //     planeID
                // });

            }

            return this.setTextureFromURL(
                polygon,
                await cutPlane(this.fabricJS, polygon, this.sourceImage, this.scale)
            );
        } else if (this.planeList[planeID].type === "additional" && this.design) {
            let productUUID = this.design.designGroupList[this.planeList[planeID].idDesignGroup].additional.uuidProduct;
            let product = await store.dispatch('UI/products/PRODUCT_BY_UUID', productUUID, {root: true})
            // console.log(product);
            if (!product)
                return

            if (!product.color)
                return

            if (!product.color.hex)
                return

            polygon.set({
                opacity: 1,
            })

            if (product.texture_width) {
                //TODO texture_width must recalculating from meters to pixels
                // console.log(product.texture_width);
                const scalePhoto = store.getters[`projectList/${this.activeProjectUUID}/photoList/${this.photoID}/SCALE`];

                // console.log(scaleObject);
                let translatedScale = await getScaleTranslatedPoints(scalePhoto, this.planeList, photoID);
                let scalePoints = [
                    new Point(translatedScale[0].x, translatedScale[0].y),
                    new Point(translatedScale[1].x, translatedScale[1].y),
                ]
                let dpm = Point.distance(scalePoints[0], scalePoints[1]) / scalePhoto.distance;
                // console.log("scale",this.scale);
                // console.log("ratio",devicePixelRatio);
                let texture_width = product.texture_width * dpm;
                // console.log("dpm", dpm);
                // console.log("w", texture_width);
                // polygon.set({
                //     calcCoords: true,
                // });
                //TODO polygon not removing
                this.fabricJS.remove(polygon);
                const pointDict = getAdditionalPolygonPoints(this.planeList[planeID], 1.0, texture_width);
                let pointList = Object.values(pointDict);

                polygon = planePolygonForShowPlanes(
                    pointList,
                    this.planeList[planeID].type,
                    this.scale,
                    planeID
                );
                this.fabricJS.add(polygon);
                sortObjectsByZindex(this.fabricJS);
                this.fabricJS.renderAll();
            }
            let rect = polygon.getBoundingRect(true);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext("2d");
            canvas.width = Math.ceil(rect.width/devicePixelRatio);// * scale/devicePixelRatio;
            canvas.height = Math.ceil(rect.height/devicePixelRatio);// * scale/devicePixelRatio;
            // console.log(canvas)
            ctx.fillStyle = product.color.hex;
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            let texture = canvas.toDataURL("jpeg", 0.4);
            if (texture) {
                if (this.design.designGroupList[this.planeList[planeID].idDesignGroup].shadows)
                    texture = await this.applyShadowMask(polygon, texture);
                if (this.design.designGroupList[this.planeList[planeID].idDesignGroup].lightCorrection)
                    texture = await this.applyLightCorrection(polygon, texture);
                return await this.setTextureFromURL(polygon, texture).then(()=> {
                    polygon.set({
                        opacity: 1,
                    })
                });
            }
        }
        // else if (this.planeList[planeID].type === "roof" && this.design) {
        //     let productUUID = this.design.designGroupList[this.planeList[planeID].idDesignGroup].roof.uuidProduct;
        //     // let product = await store.dispatch('UI/products/PRODUCT_BY_UUID', productUUID, {root: true})
        //
        //     // if (!this.planeList[planeID].threejs.models3d) {
        //     //     return
        //     // }
        //     // let model = this.planeList[planeID].threejs.models3d[0]
        //     // let productUUID = model.productUuid;
        //     let product = await store.dispatch('UI/products/PRODUCT_BY_UUID', productUUID, {root: true});
        //     let boundingBox = getBoundingBox(this.planeList[planeID].points);
        //
        //
        //     let texture = await new this.textureGenerator.getTexture(
        //         planeID,
        //         product,
        //         boundingBox,
        //     )
        //
        //     if (texture) {
        //         if (this.design.designGroupList[this.planeList[planeID].idDesignGroup].shadows)
        //             texture = await this.applyShadowMask(polygon, texture);
        //
        //         if (this.design.designGroupList[this.planeList[planeID].idDesignGroup].lightCorrection)
        //             texture = await this.applyLightCorrection(polygon, texture);
        //
        //         return await this.setTextureFromURL(polygon, texture).then(()=>{
        //             polygon.set({
        //                 opacity: 1,
        //             })
        //         });
        //     }
        // }

    }


    this.setOpacity = (polygon, planeID, takeIntoCalculations) => {
        let opacity;
        if (!takeIntoCalculations) {
            opacity = 0.3;
        } else {
            opacity = 0.7;
        }
        polygon.set({
            opacity
        });
        this.fabricJS.requestRenderAll();
    }

    this.destroy = () => {
        this.textureGenerator.destroy();
    }

    return this
}
