<template>
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 0H12L14.9999 10H0L3 0Z" fill="CurrentColor"/>
        <path d="M10.2603 3.50099C10.2603 3.75905 10.1912 3.98947 10.0532 4.19223C9.91509 4.39236 9.73363 4.57011 9.50877 4.72548C9.28787 4.87558 9.03343 5.01646 8.74546 5.14812C8.45749 5.27979 8.15177 5.40751 7.8283 5.53127V6.42001H6.76913V5.21527C7.02554 5.11784 7.30167 5.01119 7.59753 4.89533C7.89733 4.77946 8.14191 4.66228 8.33126 4.54378C8.56006 4.40685 8.73757 4.26596 8.8638 4.12113C8.99004 3.97367 9.05315 3.7867 9.05315 3.56024C9.05315 3.26267 8.90128 3.04147 8.59753 2.89664C8.29773 2.74918 7.90917 2.67544 7.43185 2.67544C7.00582 2.67544 6.60148 2.72021 6.21883 2.80974C5.84014 2.89928 5.54033 2.99013 5.31943 3.08229H5.26025V2.2765C5.53639 2.2054 5.8855 2.1422 6.30759 2.0869C6.73363 2.02897 7.13599 2 7.51469 2C8.36282 2 9.03146 2.13825 9.52061 2.41475C10.0137 2.68861 10.2603 3.05069 10.2603 3.50099ZM7.92298 8H6.71588V7.16656H7.92298V8Z" fill="#4C5459"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGPlaneUnknown"
    }
</script>

<style scoped>

</style>
