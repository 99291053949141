import {create, all} from 'mathjs';
import store from "../store";
import {getPerspectivePoints} from "../fabric/libs/getPerspectivePoints";
import {getBoundingBox} from "../fabric/Tools/pointsTools";
import {http_request} from "../httpConfig";

const math = create(all);

export const translatePointByPerspectiveMatrix = function (perspective_matrix, point) {
    let column_matrix = math.matrix([
        point.x,
        point.y,
        1]);
    let matrixMultyply = math.multiply(math.matrix(perspective_matrix),column_matrix);
    return {
        x: matrixMultyply._data[0]/matrixMultyply._data[2],
        y: matrixMultyply._data[1]/matrixMultyply._data[2]
    }
}

export const getBackwardMatrix = function(perspective_matrix) {
    return math.inv(math.matrix(perspective_matrix));
}

export const getPerspectiveMatrix = async function (options) {
    let scale;
    if (options.scale)
        scale = options.scale;
    else
        scale = 1;

    let pointArray = getPerspectivePoints(options.planeID,options.photoID,scale);

    let inputData = {}
    inputData.roi = pointArray;
    inputData.roi.forEach((point)=>{
        point.x*=scale;
        point.y*=scale;
    })

    inputData.texture_factor = 0.1*scale;

    let size = await store.dispatch("active/GET_ACTIVE_IMG_SIZE_BY_ID", options.photoID);
    inputData.width = size.width*scale;
    inputData.height = size.height*scale;

    return http_request.post('/get_perspective_matrix', inputData)
        .then((request) => {
            return request.data.perspective_matrix;
        })
        .catch((error) => {
            console.error(error);
        });
}

