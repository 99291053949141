<template>
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1014 5.30391L11.7763 0.196613C11.2226 -0.250487 10.35 0.112469 10.35 0.806995V3.49709C4.57739 3.55889 -1.52588e-05 4.6407 -1.52588e-05 9.75609C-1.52588e-05 11.8207 1.42243 13.8662 2.99477 14.9355C3.48543 15.2692 4.1847 14.8504 4.00379 14.3095C2.37424 9.43648 4.7767 8.1428 10.35 8.06779V11.0221C10.35 11.7177 11.2233 12.079 11.7763 11.6325L18.1014 6.52467C18.4993 6.20335 18.4998 5.62566 18.1014 5.30391Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "SVGArrowForward"
}
</script>

<style scoped>

</style>