import {Vector2, PerspectiveCamera} from "three";
import {Vector3} from "three";
import add_renderer from "./renderer";
import {findPlanePerspective} from "./findPlanePerspective";
import store from "../store";

export const getPerspectiveCamera = async function (cameraParameters, width, height,scale) {

    // this.imageSize = await store.dispatch("active/GET_ACTIVE_IMG_SIZE");
    // this.width = this.imageSize.width;
    // this.height = this.imageSize.height;
    this.scale = 1;
    if (scale)
        this.scale = scale;
    this.width = width;
    this.height = height;
    this.camera = new PerspectiveCamera(70, this.width / this.height, 0.1, 1000);
    // this.component = document.getElementById("debugThreejs");

    // let cameraSettings = store.getters[`projectList/${activeProjectUUID}/photoList/${photoID}/planeList/${planeID}/CAMERA_SETTINGS`];
    // if (cameraSettings) {
    //     this.camera.position.set(cameraSettings.position.x,cameraSettings.position.y,cameraSettings.position.z);
    //     this.camera.up.set(cameraSettings.up.x,cameraSettings.up.y,cameraSettings.up.z);
    //     this.camera.userData.target = new Vector3(cameraSettings.target.x,cameraSettings.target.y,cameraSettings.target.z);
    //     this.camera.fov = cameraSettings.fov;
    //     this.camera.lookAt(this.camera.userData.target);
    //     this.camera.updateProjectionMatrix();
    //     this.camera.updateMatrixWorld();
    // } else {
    //     this.camera.position.set(this.width/2, this.height/2, 1000);
    //     this.camera.userData.target = new Vector3(this.width/2, this.height/2, 0);
    //     this.camera.lookAt(this.camera.userData.target);
    //     this.camera.updateProjectionMatrix();
    //     this.camera.updateMatrixWorld();
    //     await new findPlanePerspective(planeID, this.camera, photoID);
    // }


    // console.log(cameraParameters);
    this.camera.setRotationFromMatrix(cameraParameters.cameraTransform);

    // let scale = 1;
    // window._someScale = scale;
    this.camera.position.x = cameraParameters.cameraTransform.elements[12]*this.scale;
    this.camera.position.y = cameraParameters.cameraTransform.elements[13]*this.scale;
    this.camera.position.z = cameraParameters.cameraTransform.elements[14]*this.scale;
    this.camera.fov = cameraParameters.verticalFieldOfView * 180/Math.PI;
    // this.camera.position.set(-500,20,500);
    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld();
    // console.log("this.camera.position", this.camera.position)


    return this.camera;
}
