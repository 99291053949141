var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-background" },
    [
      _c("div", { staticClass: "modal-head-grid" }, [
        _c("div", { staticClass: "modal-label" }, [
          _vm._v("\n                Настроить теги\n            ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "modal-close-btn-mp",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_c("SVGClose")],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-data-container" }, [
        _c("div", { staticClass: "admin-tabs-container" }, [
          _c(
            "div",
            {
              staticClass: "admin-tab",
              class: { "active-admin-tab": _vm.activeAdminTab === "grouping" },
              on: {
                click: function($event) {
                  return _vm.switchAdminTab("grouping")
                }
              }
            },
            [
              _c("SVGAdministrating", {
                staticStyle: { "margin-right": "12px", width: "16px" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "admin-tab-text" }, [
                _vm._v("Группировка тегов")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "admin-tab",
              class: {
                "active-admin-tab": _vm.activeAdminTab === "categories"
              },
              on: {
                click: function($event) {
                  return _vm.switchAdminTab("categories")
                }
              }
            },
            [
              _c("SVGCategories", {
                staticStyle: { "margin-right": "12px", height: "16px" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "admin-tab-text" }, [
                _vm._v("Категории тегов")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "admin-tab",
              class: { "active-admin-tab": _vm.activeAdminTab === "tags" },
              on: {
                click: function($event) {
                  return _vm.switchAdminTab("tags")
                }
              }
            },
            [
              _c("SVGTags", {
                staticStyle: { "margin-right": "12px", height: "16px" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "admin-tab-text" }, [_vm._v("Теги")])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-borderline-thin " })
      ]),
      _vm._v(" "),
      _vm.activeAdminTab === "grouping" ? _c("ModalTagsSorting") : _vm._e(),
      _vm._v(" "),
      _vm.activeAdminTab === "categories"
        ? _c("ModalTagsCategories")
        : _vm._e(),
      _vm._v(" "),
      _vm.activeAdminTab === "tags" ? _c("ModalTagsTags") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }