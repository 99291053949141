<template>
    <div class="application-body">
        <div class="loading-line">
            <div class="loading-line-progress" :style="{'width': +loadingProgress + '%'}"></div>
        </div>
        <div class="loading-page-logo-container">
            <img class="" :src="urlForStatic('/img/metal-profil-logo-gray.png')">
        </div>
    </div>
</template>

<script>
    import {urlForStaticVue} from "../../common";

    export default {
        name: "FullBodyLoader",
        mixins: [urlForStaticVue],
        props: {
            loadingProgress: {
                type: [Number, String],
                default: 3
            }
        }

    }
</script>

<style scoped>

</style>
