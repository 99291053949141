import {renderDesign} from "../../fabric/libs/renderDesign";

export const resultImg = {
    namespaced: true,
    state: () => ({
        allUploaded: true,
        uploadedPhotos: []
    }),
    getters: {
        PHOTO_BASE64_BY_ID: state => (idPhoto, idDesign) => {
            const photo = state.uploadedPhotos.find(photo => (photo.idPhoto == idPhoto && photo.idDesign == idDesign));
            if (photo)
                return photo.dataBase64;
        },
        LENGTH_UPLOADED_PHOTOS: state => {
            return state.uploadedPhotos.length;
        }
    },
    mutations: {
        UPLOADED_PHOTOS: (state, photo) => {
            state.uploadedPhotos.push({
                idPhoto: photo.idPhoto,
                idDesign: photo.idDesign,
                dataBase64: photo.dataBase64
            });
        },
        CLEAR: (state, data) => {
            state.uploadedPhotos.splice(0);
        }
    },
    actions: {
        GET_DESIGN_PHOTO(context, inputData) {
            return new renderDesign(inputData.designID, inputData.photoID, false).then((design) => {
                context.commit('UPLOADED_PHOTOS', {
                    idPhoto: inputData.photoID,
                    idDesign: inputData.designID,
                    dataBase64: design
                });
            })
        },
        async LOAD_ALL_DESIGN_PHOTO(context, inputData) {
            const idsDesigns = inputData.idsDesigns; // array
            const idsPhotos = inputData.idsPhotos; // array
            context.state.allUploaded = false;
            let arrPromises = [];
            for (let idDs of idsDesigns) {
                for (let idPh of idsPhotos) {
                    if (!context.getters['PHOTO_BASE64_BY_ID'](idPh, idDs)) {
                        arrPromises.push(context.dispatch('GET_DESIGN_PHOTO', {
                            designID: idDs,
                            photoID: idPh
                        }));
                        context.dispatch("IN_PROGRESS", arrPromises[arrPromises.length - 1], {root: true});
                    }
                }
            }
            await Promise.all(arrPromises);
            context.state.allUploaded = true;
        },
        async GET_UPLOADED_PHOTOS_BASE64(context, inputData) {
            const idsPhotos = inputData.idsPhotos; // array
            const idsDesigns = inputData.idsDesigns;  // array
            let photos = [];

            await context.dispatch('LOAD_ALL_DESIGN_PHOTO', inputData);

            for (let idDs of idsDesigns) {
                for (let idPh of idsPhotos) {
                    const photoBase64 = context.getters['PHOTO_BASE64_BY_ID'](idPh, idDs);
                    if(photoBase64){
                        photos.push(photoBase64);
                    }
                }
            }
            return photos;
        }
    }
}
