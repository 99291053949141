var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "395",
        height: "264",
        viewBox: "0 0 395 264",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        staticClass: "workspace-loader-path",
        attrs: {
          d:
            "M11.4478 62.047L100.024 9.46983C101.557 8.55999 103.458 8.53496 105.014 9.40412L194.08 59.1484C195.585 59.9892 197.418 59.995 198.929 59.1637L288.538 9.85449C290.067 9.01335 291.924 9.02987 293.437 9.89808L383.488 61.5589C385.042 62.4503 386 64.1047 386 65.8959V217.5C386 220.261 383.761 222.5 381 222.5H201.5C198.739 222.5 196.5 224.739 196.5 227.5V250.465C196.5 253.599 193.649 255.96 190.57 255.377L13.0699 221.771C10.7091 221.324 9 219.261 9 216.858V66.3466C9 64.5818 9.9303 62.9478 11.4478 62.047Z",
          stroke: "white",
          "stroke-width": "17"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }