<template>
    <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="5.05405" height="3.67568" fill="currentColor"/>
        <rect x="5.9729" width="5.05405" height="3.67568" fill="currentColor"/>
        <rect y="4.5946" width="5.05405" height="3.67568" fill="currentColor"/>
        <rect x="5.9729" y="4.5946" width="5.05405" height="3.67568" fill="currentColor"/>
        <rect x="11.946" width="5.05405" height="3.67568" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGKinds"
    }
</script>

<style scoped>

</style>
