var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "29",
        height: "24",
        viewBox: "0 0 29 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M26.3543 23.3537V17.7791L16.0464 10.0366V7.62092H18M22.3524 23.3537V19.3276L11.6806 11.8948V7.62092H5.49587V5.14331H13.9241H17.6916M5.49587 10.0366L0.705704 17.7791C0.584434 19.6373 1.37269 23.3537 5.49587 23.3537C9.61905 23.3537 10.4882 19.6373 10.4073 17.7791L5.49587 10.0366Z",
          stroke: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("circle", {
        attrs: {
          cx: "23.0441",
          cy: "5.9711",
          r: "5.29422",
          stroke: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M26.0158 6.11967C26.0158 6.33396 25.8421 6.50768 25.6278 6.50768H23.3725V8.74841C23.3725 8.97074 23.1923 9.15097 22.9699 9.15097V9.15097C22.7476 9.15097 22.5674 8.97074 22.5674 8.74841V6.50768H20.3121C20.0978 6.50768 19.9241 6.33396 19.9241 6.11967V6.11967C19.9241 5.90538 20.0978 5.73167 20.3121 5.73167H22.5674V3.49093C22.5674 3.26861 22.7476 3.08838 22.9699 3.08838V3.08838C23.1923 3.08838 23.3725 3.26861 23.3725 3.49093V5.73167H25.6278C25.8421 5.73167 26.0158 5.90538 26.0158 6.11967V6.11967Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }