var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "84",
        height: "84",
        viewBox: "0 0 84 84",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("rect", {
        attrs: {
          x: "63",
          y: "32",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 63 32)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "63",
          y: "64",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 63 64)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "32",
          y: "37",
          width: "20",
          height: "10",
          rx: "4",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "47",
          y: "48",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 47 48)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "64",
          y: "37",
          width: "20",
          height: "10",
          rx: "4",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "48",
          y: "53",
          width: "20",
          height: "10",
          rx: "4",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "48",
          y: "21",
          width: "20",
          height: "10",
          rx: "4",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "47",
          y: "16",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 47 16)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "63",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 63 0)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "79",
          y: "16",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 79 16)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "79",
          y: "48",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 79 48)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "31",
          y: "32",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 31 32)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "31",
          y: "64",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 31 64)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          y: "37",
          width: "20",
          height: "10",
          rx: "4",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "15",
          y: "48",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 15 48)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "16",
          y: "53",
          width: "20",
          height: "10",
          rx: "4",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "32",
          y: "69",
          width: "20",
          height: "10",
          rx: "4",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "16",
          y: "21",
          width: "20",
          height: "10",
          rx: "4",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "32",
          y: "5",
          width: "20",
          height: "10",
          rx: "4",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "15",
          y: "16",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 15 16)",
          fill: "currentColor"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "31",
          width: "20",
          height: "10",
          rx: "4",
          transform: "rotate(90 31 0)",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }