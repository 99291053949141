import {fabric} from "fabric";
import {add_zoom_canvas} from "./Tools/zoomSceneTool";
import {add_dragging} from "./Tools/draggingSceneTool";
import store from "../store";
import {planeDrawingTool} from "./Tools/planeDrawingTool";
import {planeTool} from "./Tools/planeTool";
import {homography} from "./libs/homography";
import {addImg} from "./libs/addImg";
import {SetPerspectiveTool} from "./Tools/setPerspectiveTool";
import {additionalElementsTool} from "./Tools/additionalElementsTool";
import {sortObjectsByZindex} from "./libs/sortObjectsByZindex";
import {SetScaleTool} from "./Tools/setScaleTool";
import router from '../router/index';
import {planePolygonForTools} from "./primitives/planePolygon";
import {planeGroupingTool} from "./Tools/planeGroupingTool";
import {getScale} from "./libs/common";
import {getAdditionalPolygonPoints} from "./primitives/additionalPlane";
import {bus} from "../vue_bus";
import {SetGlobalPerspectiveTool} from "./Tools/setGlobalPerspectiveTool";
import {baseLineTool} from "./Tools/baseLineTool";
import {materialTool} from "./Tools/materialTool";
import {markersTool} from "./Tools/markersTool";
import {addDraggingLite} from "./Tools/draggingSceneLiteTool";

const fabricEditor = function (canvas, options) {
    this.tools = {
        "planeShower": planeTool,
        "planeGroupingTool": planeGroupingTool,
        "planeDrawingTool": planeDrawingTool,
        "setScale": SetScaleTool,
        "additionalElementsTool": additionalElementsTool,
        "setPerspective": SetPerspectiveTool,
        "setGlobalPerspectiveTool": SetGlobalPerspectiveTool,
        "baseLineTool": baseLineTool,
        "markersTool": markersTool,
    }

    this.options = Object.assign({
        zoom: true,
        dragging: true
    }, options)
    this.fabricJS = new fabric.Canvas(canvas);
    this.fabricJS.backgroundColor = "#ffffff";
    this.fabricJS.selection = false;
    if (this.options.zoom)
        this.zoom_canvas = new add_zoom_canvas(this.fabricJS);
    if (this.options.dragging)
        this.dragging = new add_dragging(this.fabricJS);
    if (this.options.draggingLite)
        this.draggingLite = new addDraggingLite(this.fabricJS);
    this.sourceImage = null;
    this.fabricJS.set({
        // targetFindTolerance: 5,
        objectCaching: false,
    });

    this.showPlanes = async (options = {
        withTexture: false,
        scale: 1
    }) => {
        options.sourceImage = this.sourceImage;
        this.planeShower = await new planeTool(this.fabricJS, options);
    }

    this.addMaterialTool = (options = {
        withTexture: true,
        scale: 1
    }) => {
        options.sourceImage = this.sourceImage;
        return new materialTool(this.fabricJS, options).then((tool)=>{
            this.materialTool = tool;
            this.tools["materialTool"] = this.materialTool;
            return tool
        })
    }

    this.addPlaneGroupingTool = async (options = {
        withTexture: false,
        scale: 1
    }) => {
        options.sourceImage = this.sourceImage;
        this.planeGroupingTool = await new planeGroupingTool(this.fabricJS, options);
    }

    this.showPlanesForTools = (excludePlaneID) => {
        let planeList = store.getters["active/ALL_PLANES_FROM_PHOTO"];
        let polygonArray = [];
        for (let planeID in planeList) {
            if (planeID == excludePlaneID)
                continue
            if (!planeList[planeID].isComplete)
                continue
            planeID = parseInt(planeID);
            let pointList = planeList[planeID].points

            if (planeList[planeID].type === "additional")
                pointList = getAdditionalPolygonPoints(planeList[planeID], this.scale, 10);

            let polygon = planePolygonForTools(
                pointList,
                planeList[planeID].type,
                1,
                planeID,
                false
            );
            polygonArray.push(polygon);
            this.fabricJS.add(polygon);

            bus.$on("switchVisibilityBackgroundPlanes", (flag)=>{
                polygonArray.forEach((polygon)=>{
                    polygon.set({
                        opacity: flag?store.state.projectConfiguration.backgroundPlanesOpacity:0,
                    });
                })
                this.fabricJS.requestRenderAll();
            });
        }
    }

    this.sortObjectsByZindex = () => {
        sortObjectsByZindex(this.fabricJS);
    }


    this.updateCanvaSize = (size) => {
        this.fabricJS.setWidth(size.width);
        this.fabricJS.setHeight(size.height);
    }


    this.addSourceImage = (url,scaleImg) => {
        this.sourceImage = addImg(url).then((obj) => {
                // this.sourceImage = obj;
                this.fabricJS.add(obj);
                obj.set({
                    zIndex: 1,
                    hoverCursor: 'default'
                })
                if (scaleImg)
                    obj.scale(scaleImg)
                obj.sendToBack();

                return obj;
            }
        )
        return this.sourceImage
    }

    this.setViewport = (sourceImg, scaleImg) => {
        this.scale = scaleImg;
        if (!router.currentRoute.query.view && sourceImg) {
            let scale = getScale(sourceImg, this.fabricJS);
            let center = sourceImg.getCenterPoint();
            let viewScale = 1;
            if (scaleImg)
                viewScale = scale/scaleImg;
            this.fabricJS.viewportTransform[0] = viewScale;
            this.fabricJS.viewportTransform[3] = viewScale;
            this.fabricJS.viewportTransform[4] = this.fabricJS.width / 2 - center.x * viewScale;
            this.fabricJS.viewportTransform[5] = this.fabricJS.height / 2 - center.y * viewScale;
            this.fabricJS.setViewportTransform(this.fabricJS.viewportTransform);
        } else if (router.currentRoute.query.view) {
            this.fabricJS.setViewportTransform(JSON.parse(router.currentRoute.query.view));
        }
        if (this.zoom_canvas)
            this.zoom_canvas.updateZoom();
        this.fabricJS.calcOffset();
        this.fabricJS.renderAll();
        return this.fabricJS.viewportTransform
    };

    this.setViewportLite = (sourceImg, scale) => {
        let center = sourceImg.getCenterPoint();
        let viewScale = scale;
        this.draggingLite.setCenter({
                x: center.x * viewScale,
                y: center.y * viewScale
            });
        this.fabricJS.viewportTransform[0] = viewScale;
        this.fabricJS.viewportTransform[3] = viewScale;
        this.fabricJS.viewportTransform[4] = this.fabricJS.width / 2 - center.x * viewScale;
        this.fabricJS.viewportTransform[5] = this.fabricJS.height / 2 - center.y * viewScale;
        this.fabricJS.setViewportTransform(this.fabricJS.viewportTransform);

        this.fabricJS.calcOffset();
        this.fabricJS.renderAll();

        return this.fabricJS.viewportTransform
    };

    this.addhomography = () => {
        new homography(this.fabricJS);
    }

    this.addSetScale = () => {
        this.setScale = new SetScaleTool(this.fabricJS);
        if (this.zoom_canvas)
            this.zoom_canvas.setCallback(this.setScale.onUpdateScale);
    }

    this.setQuality = (quality) => {
        this.fabricJS.contextContainer.imageSmoothingQuality = quality; // "low", "med", "high"
    }

    this.addTool = (toolName) => {
        this[toolName] = new this.tools[toolName](this.fabricJS);

        if (this.zoom_canvas && this[toolName].onUpdateScale)
            this.zoom_canvas.setCallback(this[toolName].onUpdateScale);
    }

    this.convertToHtmlCoords = (coords) => {
        // return {
        //     left: this.fabricJS._offset.left + this.fabricJS.viewportTransform[4] + coords.left * this.fabricJS.viewportTransform[0] * this.scale,
        //     top: this.fabricJS._offset.top + this.fabricJS.viewportTransform[5] + coords.top * this.fabricJS.viewportTransform[0] * this.scale,
        // };
        return {
            left: 362 + this.fabricJS.viewportTransform[4] + coords.left * this.fabricJS.viewportTransform[0] * this.scale,
            top: 60 + this.fabricJS.viewportTransform[5] + coords.top * this.fabricJS.viewportTransform[0] * this.scale,
            };
    }

    this.destroy = () => {
        bus.$off("turnOffBackgroundPlanes");
        bus.$off("turnOnBackgroundPlanes");

        for (let toolName in this.tools) {
            if (this[toolName])
                this[toolName].destroy();
        }

        this.fabricJS.dispose();
    }

    this.addDebugCoords = () => {
        this.fabricJS.on('mouse:down', (e) => {
            console.log(e.absolutePointer.x/this.scale,e.absolutePointer.y/this.scale,);
            const testConverted = this.convertToHtmlCoords({
                top: e.absolutePointer.y/this.scale,
                left: e.absolutePointer.x/this.scale
            })
            // console.log(testConverted)
        });
    }

    return this

}

export {fabricEditor}
