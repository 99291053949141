var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "workspace-configurator" },
    [
      _c(
        "div",
        {
          staticClass: "arrow-photo-left",
          staticStyle: { "z-index": "3", left: "402px", top: "45%" },
          on: { click: _vm.previousPhoto }
        },
        [_c("SVGArrowSwitchPhotoLeft")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "arrow-photo-right",
          staticStyle: { "z-index": "3", right: "40px", top: "45%" },
          on: { click: _vm.nextPhoto }
        },
        [_c("SVGArrowSwitchPhotoRight")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: Object.keys(_vm.photoList).length > 0,
              expression: "Object.keys(photoList).length > 0"
            }
          ],
          staticClass: "photo-navigation-tip-configurator",
          staticStyle: { left: "calc(50% + 92px)" }
        },
        [
          _c("SVGRotateHouse"),
          _vm._v(" "),
          _vm._l(_vm.photoList, function(photo, id) {
            return [
              _c("div", {
                staticClass: "house-rotation-mark",
                class: { green: id === _vm.activePhoto },
                on: {
                  click: function($event) {
                    return _vm.selectPhoto(id)
                  }
                }
              })
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "blue-workspace-button zoom-button",
          class: { "active-blue-workspace-button": _vm.isZoomed },
          on: { click: _vm.zoomSwitch }
        },
        [
          _c("SVGZoomUp", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isZoomed,
                expression: "!isZoomed"
              }
            ],
            staticClass: "zoom-button-icon"
          }),
          _vm._v(" "),
          _c("SVGZoomDown", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isZoomed,
                expression: "isZoomed"
              }
            ],
            staticClass: "zoom-button-icon"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.marks, function(dataMark) {
        return _vm.visibleMarks
          ? _c(
              "div",
              {
                staticClass: "workspace-product-type-button",
                style: {
                  top: _vm.getHtmlCoords(dataMark).top - 35 + "px",
                  left: _vm.getHtmlCoords(dataMark).left - 35 + "px"
                },
                on: {
                  click: function($event) {
                    return _vm.selectMark(dataMark)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "workspace-product-type-button-outer" },
                  [
                    _c(
                      "div",
                      { staticClass: "workspace-product-type-button-inner" },
                      [
                        _c(
                          _vm.matchingSvgAndTypePlane[dataMark.typePlanes]
                            .component,
                          { tag: "component" }
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "workspace-product-type-button-text" },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.matchingSvgAndTypePlane[
                                    dataMark.typePlanes
                                  ].name
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "fabricJSContainer", staticClass: "fabric-js-container" },
        [
          _vm._l([1, 2, 3, 4, 5], function(index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activePhoto == index,
                    expression: "activePhoto == index"
                  }
                ],
                class: { hidden: !_vm.showCanvas }
              },
              [
                _c("canvas", {
                  ref: "fabricJS" + index,
                  refInFor: true,
                  staticClass: "workspace-module-container",
                  attrs: { id: "fabric-js-canvas" + index }
                })
              ]
            )
          }),
          _vm._v(" "),
          !_vm.showCanvas
            ? _c("img", {
                staticClass: "configurator-workspace-image",
                attrs: { src: _vm.srcShowingPhoto }
              })
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }