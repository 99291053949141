var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "15",
        height: "15",
        viewBox: "0 0 15 15",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M11.5605 0C11.3686 0 11.1765 0.0731015 11.0303 0.219727L9.75 1.5L12.75 4.5L14.0303 3.21973C14.3235 2.92648 14.3235 2.45168 14.0303 2.15918L12.0908 0.219727C11.9442 0.0731015 11.7525 0 11.5605 0ZM8.625 2.625L0 11.25V14.25H3L11.625 5.625L8.625 2.625Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }