import Vue from 'vue'
import Vuex from 'vuex'
import {VuexPersistence} from "vuex-persist";
import {profile} from "./modules/profile";
import {projectList} from "./modules/projectList";
import {projectConfiguration} from "./modules/projectConfiguration";
import active from "./modules/active/index";
import UI from "./modules/UI";
import {saveProject} from "./modules/saveProject";
import {photoEditorData} from "./modules/photoEditorData";
import {cost} from "./modules/cost";
import {costLite} from "./modules/costLite";
import {feedback} from "./modules/feedback";
import adminPanel from "./AdminPanel";
import {resultImg} from "./modules/resultImg";
import {productsWithFilters} from "./modules/productsWithFilters";

Vue.use(Vuex);

export const getModuleNamespace = function (store, state) {
    const moduleNamespace = Object.keys(store._modulesNamespaceMap)
        .find(path => store._modulesNamespaceMap[path].context.state === state);
    if (typeof moduleNamespace === 'string') {
        return  moduleNamespace.slice(0, -1).split('/');
    } else {
        return null;
    }
}

const forCookie = (key, state, storage) => {
    // console.log('Смротрим', key, state, storage);
    if(store.state.profile.userAuthorized) {
        localStorage[key] = JSON.stringify(state);
    }
};

const getCookie = (key) => {
    if (localStorage[key]) {
        sessionStorage.initialStateFromLocalStorage = localStorage[key];
        let stateForLoad = JSON.parse(localStorage[key]);
        delete stateForLoad.projectList;
        return stateForLoad;
    }
    return {};
};

const vuexCookie = new VuexPersistence({
    key: 'stateVuex',
    restoreState: (key, storage) => getCookie(key),
    saveState: (key, state, storage) => forCookie(key, state, storage),
    modules: ['projectList', 'profile']
});

const store = new Vuex.Store({
    state: {
        inProgress: [],
        progressPercent: 0,
        applicationVersion: null,
        completeTask: 0.1,
        projectsPromptStatus: true,
    },
    modules: {
        saveProject,
        profile,
        projectList,
        projectConfiguration,
        active,
        UI,
        photoEditorData,
        cost,
        costLite,
        feedback,
        adminPanel,
        resultImg,
        productsWithFilters
    },
    plugins: [vuexCookie.plugin],
    getters: {
        IN_PROGRESS_PROMISES: state => {
            return state.inProgress;
        },
        IN_PROGRESS: state => {
            return state.inProgress.length;
        },
        PROGRESS_PERCENT: state => {
            return state.progressPercent;
        },
        APPLICATION_VERSION: state => state.applicationVersion
    },
    mutations: {
        CLOSE_PROJECTS_PROMPT: (state) => {
            state.projectsPromptStatus = false
        },
        APPLICATION_VERSION: (state, name) => {
            state.applicationVersion = name;
        },
        IN_PROGRESS: (state, promise) => {
            if (promise)
                state.inProgress.push(promise);
            else
                state.inProgress.splice(-1, state.inProgress.length);
        },
        PROGRESS_PERCENT: (state, percent) => {
            state.progressPercent = percent;
        },
        RESTORE_MUTATION: vuexCookie.RESTORE_MUTATION,
    },
    actions: {
        INIT: function (context) {
        },
        IN_PROGRESS: function (context, promise) {
            if (!promise)
                return;

            context.commit('IN_PROGRESS', promise);
            context.commit("PROGRESS_PERCENT", Math.round(100*context.state.completeTask/context.state.inProgress.length));
            promise.then(()=>{
                context.state.completeTask +=1;
                context.commit("PROGRESS_PERCENT", Math.round(100*context.state.completeTask/context.state.inProgress.length));
            })
            Promise.allSettled(context.state.inProgress).then(() => {
                if(context.state.inProgress.length === 0)
                    return
                context.commit('IN_PROGRESS', null);
                context.commit('PROGRESS_PERCENT', 0);
                context.state.completeTask = 0.1;
            });
        },
        IN_PROGRESS_WITHOUT_PERCENT: function (context, promise) {
            if (!promise)
                return;

            context.commit('IN_PROGRESS', promise);

            Promise.allSettled(context.state.inProgress).then(() => {
                if(context.state.inProgress.length === 0)
                    return
                context.commit('IN_PROGRESS', null);
                context.commit('PROGRESS_PERCENT', 0);
                context.state.completeTask = 0.1;
            });
        },
    }
});

export default store;
