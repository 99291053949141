var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 13 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M9.39146 1.64338L9.43628 1.75329L9.5338 1.82097C10.1521 2.25001 11.0207 3.08712 11.5834 4.17769C11.988 4.96185 12.2304 5.86673 12.1179 6.84834C11.8985 6.35902 11.6329 5.8409 11.3346 5.37472C11.0558 4.93891 10.7365 4.52935 10.385 4.2322C10.0365 3.93766 9.61082 3.71633 9.13524 3.75731L8.99851 3.7691L8.89782 3.86234C8.54137 4.19239 8.06541 4.39358 7.54157 4.39358C6.43878 4.39358 5.54478 3.49959 5.54478 2.39679C5.54478 1.29399 6.43878 0.4 7.54157 0.4C8.37714 0.4 9.09377 0.913344 9.39146 1.64338ZM6.29226 12.032C5.41158 11.9771 4.51044 11.7211 3.72545 11.1211C4.25805 11.1837 4.83907 11.2214 5.39223 11.2045C5.90936 11.1887 6.42473 11.1246 6.86012 10.9752C7.29169 10.8272 7.70002 10.5752 7.90876 10.146L7.96877 10.0225L7.94038 9.88828C7.83987 9.413 7.91127 8.90124 8.17994 8.45154C8.74556 7.50485 9.97153 7.19592 10.9182 7.76154C11.8649 8.32716 12.1739 9.55313 11.6082 10.4998C11.1797 11.2171 10.3714 11.569 9.59206 11.4501L9.47472 11.4322L9.3666 11.4813C8.68118 11.792 7.51706 12.1082 6.29226 12.032ZM2.13146 3.54717C2.62835 2.81798 3.30863 2.17392 4.22536 1.80539C3.89955 2.23134 3.57043 2.71164 3.30251 3.19588C3.05204 3.64858 2.84395 4.1244 2.75004 4.57502C2.65696 5.02169 2.66504 5.50141 2.92754 5.9001L3.00301 6.01472L3.13295 6.05887C3.59291 6.21515 3.99646 6.53788 4.2459 6.99852C4.77102 7.96826 4.41059 9.18009 3.44084 9.70521C2.4711 10.2303 1.25927 9.8699 0.734143 8.90015C0.336268 8.16539 0.44644 7.29079 0.946643 6.68139L1.02195 6.58964L1.03503 6.47166C1.1179 5.72368 1.44042 4.56127 2.13146 3.54717Z",
          stroke: "currentColor",
          "stroke-width": "0.8"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }