<template>
  <svg width="68" height="57" viewBox="0 0 68 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 47.3201V55.156H34.8234H67V32.8386L45.3248 26.752V7.00451L34.8234 2L23.2752 7.00451V15.5257V26.752L1 32.8386V47.3201Z" stroke="currentColor" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: "SVGPublicBuilding"
}
</script>

<style scoped>

</style>