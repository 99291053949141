export const getBoundingBox = function (pointList, overflowFactorPercent) {
    let overflowFactor = 0;
    if (overflowFactorPercent)
        overflowFactor = overflowFactorPercent/100;
    // for objects only
    let minPoint = {
        x:pointList[1].x,
        y:pointList[1].y
    }
    let maxPoint = {
        x:pointList[1].x,
        y:pointList[1].y
    }
    for (let key in pointList) {
        if (pointList[key].x < minPoint.x)
            minPoint.x = pointList[key].x;

        if (pointList[key].y < minPoint.y)
            minPoint.y = pointList[key].y;

        if (pointList[key].x > maxPoint.x)
            maxPoint.x = pointList[key].x;

        if (pointList[key].y > maxPoint.y)
            maxPoint.y = pointList[key].y;
    }

    minPoint.x *=(1-overflowFactor);
    minPoint.y *=(1-overflowFactor);
    maxPoint.x *=(1+overflowFactor);
    maxPoint.y *=(1+overflowFactor);
    return {minPoint, maxPoint}
}

export const toArray = function(object) {
    let array = []
    for (let key in object) {
        array.push(object[key]);
    }
    return array
}
