var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-workspace-container" },
    [
      _c("div", { staticClass: "admin-search-new-container" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "search-icon-anchor" },
            [_c("SVGSearch", { staticClass: "search-icon" })],
            1
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mask,
                expression: "mask"
              }
            ],
            staticClass: "modal-form-grid-input search-input",
            attrs: { type: "text", placeholder: "Найти пользователя" },
            domProps: { value: _vm.mask },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.mask = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blue-button admin-new-button",
            on: { click: _vm.addNew }
          },
          [_vm._v("Добавить нового")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "admin-tabs-container" }, [
        _c(
          "div",
          {
            staticClass: "admin-tab",
            class: { "active-admin-tab": _vm.activeAdminTab === "manager" },
            on: {
              click: function($event) {
                _vm.activeAdminTab = "manager"
              }
            }
          },
          [
            _c("SVGWorker", {
              staticStyle: { "margin-right": "12px", width: "16px" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "admin-tab-text" }, [_vm._v("Менеджеры")])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "admin-tab",
            class: { "active-admin-tab": _vm.activeAdminTab === "superadmin" },
            on: {
              click: function($event) {
                _vm.activeAdminTab = "superadmin"
              }
            }
          },
          [
            _c("SVGAdmin", {
              staticStyle: { "margin-right": "12px", height: "16px" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "admin-tab-text" }, [
              _vm._v("Администраторы")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("vue-custom-scrollbar", { staticClass: "admin-table-container" }, [
        _c("table", { staticClass: "admin-table" }, [
          _c("thead", [
            _c("tr", { staticClass: "admin-table-head-tr" }, [
              _c("th", [_vm._v("№")]),
              _vm._v(" "),
              _c("th", [_vm._v("ФИО")]),
              _vm._v(" "),
              _c("th", [_vm._v("Подразделение")]),
              _vm._v(" "),
              _c("th", [_vm._v("E-mail")]),
              _vm._v(" "),
              _c("th", [_vm._v("Телефон")]),
              _vm._v(" "),
              _c("th", { staticClass: "admin-table-options-head" }, [
                _vm._v("Опции")
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.users, function(user, index) {
              return _c("tr", { staticClass: "admin-table-line" }, [
                _c("td", [_c("span", {}, [_vm._v(_vm._s(index + 1))])]),
                _vm._v(" "),
                _c("td", [_c("span", [_vm._v(_vm._s(user.fullname))])]),
                _vm._v(" "),
                _c("td", [_c("span", [_vm._v(_vm._s(user.company))])]),
                _vm._v(" "),
                _c("td", [_c("span", [_vm._v(_vm._s(user.email))])]),
                _vm._v(" "),
                _c("td", [_c("span", [_vm._v(_vm._s(user.phone))])]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "admin-table-options" }, [
                    _c(
                      "div",
                      {
                        staticClass: "admin-table-option",
                        on: {
                          click: function($event) {
                            return _vm.editRow(user)
                          }
                        }
                      },
                      [_c("SVGEdit")],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "admin-table-option",
                        on: {
                          click: function($event) {
                            return _vm.deleteRow(user)
                          }
                        }
                      },
                      [_c("SVGClose")],
                      1
                    )
                  ])
                ])
              ])
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }