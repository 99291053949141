var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-workspace-container" },
    [
      _c("div", { staticClass: "admin-search-new-container" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "search-icon-anchor" },
            [_c("SVGSearch", { staticClass: "search-icon" })],
            1
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mask,
                expression: "mask"
              }
            ],
            staticClass: "modal-form-grid-input search-input",
            attrs: { type: "text", placeholder: "Найти проект" },
            domProps: { value: _vm.mask },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.mask = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "blue-button admin-new-button" }, [
          _vm._v("Добавить новый")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "admin-tabs-container-extended" }, [
        _c("div", { staticClass: "admin-tabs-container" }, [
          _c(
            "div",
            {
              staticClass: "admin-tab",
              class: { "active-admin-tab": _vm.activeAdminTab === "common" },
              on: {
                click: function($event) {
                  return _vm.switchAdminTab("common")
                }
              }
            },
            [
              _c("SVGPeople", {
                staticStyle: { "margin-right": "12px", width: "16px" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "admin-tab-text" }, [_vm._v("Общие")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "admin-tab",
              class: { "active-admin-tab": _vm.activeAdminTab === "private" },
              on: {
                click: function($event) {
                  return _vm.switchAdminTab("private")
                }
              }
            },
            [
              _c("SVGLock", {
                staticStyle: { "margin-right": "12px", height: "16px" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "admin-tab-text" }, [_vm._v("Частные")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "admin-tab",
              class: { "active-admin-tab": _vm.activeAdminTab === "self" },
              on: {
                click: function($event) {
                  return _vm.switchAdminTab("self")
                }
              }
            },
            [
              _c("SVGWorker", {
                staticStyle: { "margin-right": "12px", height: "16px" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "admin-tab-text" }, [
                _vm._v("Мои проекты")
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "blue-link", on: { click: _vm.editTags } },
          [
            _vm._v("\n            настроить теги\n            "),
            _c("SVGTags", { staticStyle: { "margin-left": "5px" } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("vue-custom-scrollbar", { staticClass: "admin-table-container" }, [
        _c("table", { staticClass: "admin-table" }, [
          _c("thead", [
            _c("tr", { staticClass: "admin-table-head-tr" }, [
              _c("th", [_vm._v("№")]),
              _vm._v(" "),
              _c("th", [_vm._v("Изображение")]),
              _vm._v(" "),
              _c("th", [_vm._v("Название")]),
              _vm._v(" "),
              _c("th", [_vm._v("Создан")]),
              _vm._v(" "),
              _c("th", [_vm._v("Отредактирован")]),
              _vm._v(" "),
              _c("th", [_vm._v("Доступ в Lite")]),
              _vm._v(" "),
              _c("th", { staticClass: "admin-table-options-head" }, [
                _vm._v("Опции")
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.projects, function(value, index) {
              return _c("tr", { staticClass: "admin-table-line" }, [
                _c("td", [_c("span", {}, [_vm._v(_vm._s(index + 1))])]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("PreviewImgFromProject", {
                      staticClass: "admin-table-image",
                      attrs: { dataProject: value.data_json }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [_c("span", [_vm._v(_vm._s(value.name))])]),
                _vm._v(" "),
                _c("td", [
                  _c("div", [_vm._v(_vm._s(_vm.dateIntoRu(value.create_date)))])
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", [_vm._v(_vm._s(_vm.dateIntoRu(value.update_date)))])
                ]),
                _vm._v(" "),
                _c("td", [_c("span", [_vm._v(_vm._s(value.open_read))])]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "admin-table-options" }, [
                    _c(
                      "div",
                      {
                        staticClass: "admin-table-option",
                        on: {
                          click: function($event) {
                            return _vm.editRow(value)
                          }
                        }
                      },
                      [_c("SVGEdit")],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "admin-table-option",
                        on: {
                          click: function($event) {
                            return _vm.deleteRow(value)
                          }
                        }
                      },
                      [_c("SVGClose")],
                      1
                    )
                  ])
                ])
              ])
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }