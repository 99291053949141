import store from "../../store";
import {getFermatPolygon} from "../../math/getAreaCorrected";
import {Line, Point} from "@mathigon/fermat";
import {markerPointer} from "../primitives/markerPointer";

export const nearPoint = function (fabricJS, pointZoneSize) {
    this.enable = true;
    this.fabricJS = fabricJS;
    this.pointZoneSize = pointZoneSize;
    this.scaledPointZoneSize = this.pointZoneSize;
    this.allPoints = [];
    this.allLines = [];
    this.nearLine = null;
    this.markerLineIsAdded = false;
    this.visible = true;
    const planeList = store.getters["active/ALL_PLANES_FROM_PHOTO"];
    const activePlaneID = store.state.route.params.planeID;
    for (let planeID in planeList) {
        if (planeID == activePlaneID)
            continue;
        planeID = parseInt(planeID);
        if (planeList[planeID].type === "additional")
            continue;

        if (Object.keys(planeList[planeID].points).length > 20)
            continue;

        let pointList = planeList[planeID].points
        let edges = getFermatPolygon(pointList).edges;
        for (let edge in edges) {
            let line = new Line(edges[edge].p1, edges[edge].p2);
            this.allLines.push(line);
        }
        for (let pointID in pointList) {
            this.allPoints.push(pointList[pointID]);
        }
    }

    this.distance = (point1, point2) => {
        return Math.pow(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2), 0.5);
    }

    this.markerPointer = markerPointer();
    //     new fabric.Circle({
    //     radius: 2,
    //     originX: 'center',
    //     originY: 'center',
    //     left: 0,
    //     top: 0,
    //     strokeWidth: 1,
    //     evented: false,
    //     // stroke: 'rgba(125,0,0,0)',
    //     // fill: 'rgba(0,0,0,0)',
    // });

    this.markerLine = new fabric.Line(
        [
            0,
            0,
            0,
            0
        ],
        {
            stroke: 'rgb(69,146,248)',
            selectable: false,
            strokeDashArray: [2, 2],
            strokeWidth: 1,
            evented: false,
            originX: 'center',
            originY: 'center',
        })

    this.setScale = (scale) => {
        this.scaledPointZoneSize=this.pointZoneSize/scale;
        this.markerPointer.set({
                scaleX: 2 / scale,
                scaleY: 2 / scale,
            }
        )
        this.markerLine.set({
            strokeWidth: 4/scale,
            strokeDashArray: [Math.round(8/scale), Math.round(8/scale)],
        })
    }

    this.findNearPoint = (relativePoint) => {
        if (!this.enable)
            return null

        let nearPoint = null;
        let oldDistance = null;
        for (let pointIndex in this.allPoints) {
            if ((Math.abs(this.allPoints[pointIndex].x - relativePoint.x) < this.scaledPointZoneSize) &&
                (Math.abs(this.allPoints[pointIndex].y - relativePoint.y) < this.scaledPointZoneSize)) {
                if (!nearPoint) {
                    nearPoint = this.allPoints[pointIndex]
                    oldDistance = this.distance(nearPoint, relativePoint);
                    this.nearLine = null;
                }
                if (this.distance(nearPoint, relativePoint) < oldDistance) {
                    nearPoint = this.allPoints[pointIndex]
                    oldDistance = this.distance(nearPoint, relativePoint);
                    this.nearLine = null;
                }
            }
        }
        for (let line in this.allLines) {
            let p = this.allLines[line].project(relativePoint);
            let d = this.distance(p, relativePoint);
            if (d < this.scaledPointZoneSize/2) {
                if (!nearPoint) {
                    nearPoint = p;
                    oldDistance = d;
                    this.nearLine = this.allLines[line];
                    continue;
                }
                if (this.distance(nearPoint, relativePoint) < oldDistance) {
                    nearPoint = p;
                    oldDistance = d;
                    this.nearLine = this.allLines[line];
                }
            }
        }
        return nearPoint
    }

    this.setEnable = (state) => {
        this.enable = state;

    }

    this.setVisible = (state) => {
        this.visible = state;
        if (!this.visible) {
            this.fabricJS.remove(this.markerLine);
            this.markerLineIsAdded = false;
            this.fabricJS.remove(this.markerPointer);
            this.markerPointerIsAdded = false;
            this.fabricJS.requestRenderAll();
        }
    }

    this.setPoint = (pointer) => {
        if (this.visible) {
            let nearPoint = this.findNearPoint(pointer, this.scaledPointZoneSize);
            if (this.nearLine && nearPoint) {
                let d1 = this.distance(nearPoint, this.nearLine.p1);
                let d2 = this.distance(nearPoint, this.nearLine.p2);
                let d3 = this.distance(this.nearLine.p1, this.nearLine.p2);
                if (d3+1 > d1+d2) {
                    this.fabricJS.remove(this.markerLine);
                    this.markerLineIsAdded = false;
                } else {
                    let nearLinePoint;
                    if (d1 < d2)
                        nearLinePoint = this.nearLine.p1;
                    else
                        nearLinePoint = this.nearLine.p2;
                    this.markerLine.set({
                        x1: nearLinePoint.x,
                        y1: nearLinePoint.y,
                        x2: nearPoint.x,
                        y2: nearPoint.y
                    })
                    if (!this.markerLineIsAdded) {
                        this.fabricJS.add(this.markerLine);
                        this.markerLineIsAdded = true;
                    }
                }
            } else {
                if (this.markerLineIsAdded) {
                    this.fabricJS.remove(this.markerLine);
                    this.markerLineIsAdded = false;
                }
            }

            if (nearPoint) {
                this.markerPointer.left = nearPoint.x;
                this.markerPointer.top = nearPoint.y;
                if (!this.markerPointerIsAdded) {
                    this.fabricJS.add(this.markerPointer);
                    this.markerPointerIsAdded = true;
                }
            } else {
                if (this.markerPointerIsAdded) {
                    this.fabricJS.remove(this.markerPointer);
                    this.markerPointerIsAdded = false;
                }
            }
        }
    }

}
