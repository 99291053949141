var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "modal-close-btn-point" }),
    _vm._v(" "),
    _c("div", { staticClass: "ap-modal-title-big" }, [
      _vm._v("\n            Поздравляем!\n        ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-borderline no-margin-top" }),
    _vm._v(" "),
    _c("div", { staticClass: "ap-modal-element" }, [_vm._v(_vm._s(_vm.text))]),
    _vm._v(" "),
    _c("div", { staticClass: "confirmation-btn-grid" }, [
      _c(
        "div",
        {
          staticClass: "ap-modal-button profile-btn hide-underline btn-ok",
          on: { click: _vm.accept }
        },
        [_vm._v("\n                Хорошо\n            ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }