<template>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.1588 6.73609L9.87916 0.45646C9.55103 0.128329 9.09374 -0.0365045 8.63172 0.00681216L6.10999 0.243236L8.57595 0.619015C9.40669 0.745369 9.93311 1.30248 10.112 1.56377C10.1602 1.6343 10.1972 1.71209 10.2399 1.78611L14.6562 9.43531C14.8432 9.75922 14.8848 10.1431 14.8823 10.2945L16.1906 8.90734C16.7689 8.2941 16.7549 7.33214 16.1588 6.73609Z" fill="currentColor"/>
        <path d="M8.56026 1.27613C9.02073 1.35208 9.42291 1.6304 9.65626 2.03456L14.0719 9.6826C14.5022 10.428 14.2468 11.3811 13.5014 11.8115L6.75324 15.7075C6.00786 16.1379 5.05474 15.8825 4.62439 15.1371L0.208793 7.48905C-0.0245501 7.08489 -0.0644854 6.59743 0.0999661 6.16068L1.87581 1.44439C2.13774 0.748748 2.85451 0.334924 3.58792 0.455905L8.56026 1.27613Z" fill="currentColor"/>
        <path d="M5.79419 3.12481C6.22454 3.8702 5.96915 4.82332 5.22377 5.25366C4.47838 5.68401 3.52526 5.42862 3.09491 4.68324C2.66457 3.93785 2.91995 2.98473 3.66534 2.55439C4.41072 2.12404 5.36384 2.37943 5.79419 3.12481Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGTags"
    }
</script>

<style scoped>

</style>
