import {fabric} from "fabric";

export const addDraggingLite = function (fabricJS) {
    this.eventedObject = null;
    this.enable = false;
    this.centerX = fabricJS.width/2;
    this.centerY = fabricJS.height/2;

    this.setCenter = (center) => {
        this.centerX = center.x;
        this.centerY = center.y;
    }

    this.resetDelta = (delta) => {
        delta.x *= -1;
        delta.y *= -1;
        fabricJS.relativePan(delta);
    }

    this.handler_left_mouse_mousemove = (event) => {
        let delta = new fabric.Point(event.e.movementX, event.e.movementY);
        fabricJS.relativePan(delta);

        if (fabricJS.viewportTransform[4] >= 0) {
            this.resetDelta(delta);
        }

        if (fabricJS.viewportTransform[4] <= fabricJS.width - this.centerX*2) {
            this.resetDelta(delta);
        }

        if (fabricJS.viewportTransform[5] >= 0) {
            this.resetDelta(delta);
        }

        if (fabricJS.viewportTransform[5] <= fabricJS.height - this.centerY*2) {
            this.resetDelta(delta);
        }

    };

    this.handler_left_mouse_down = (e) => {
        return (opt) => {
            if (!this.enable)
                return;
            if (opt.e.button === 0) {
                if (opt.target)
                    if (opt.target.evented) {
                        this.eventedObject = opt.target;
                        this.eventedObject.set({
                            evented: false
                        });
                    }
                fabricJS.on('mouse:move', this.handler_left_mouse_mousemove);
            }
        }
    };

    this.handler_left_mouse_up = (e) => {
        return (opt) => {
            if (!this.enable)
                return;
            if (opt.e.button === 0) {
                if (this.eventedObject) {
                    this.eventedObject.set({
                        evented: true
                    });
                }
                fabricJS.off('mouse:move', this.handler_left_mouse_mousemove);
                fabricJS.off('mouse:move', this.handler_left_mouse_mousemove);
            }
        }
    };

    this.handler_right_mouse_contextmenu = (e) => {
        e.preventDefault();
    };



    fabricJS.on('mouse:down:before', this.handler_left_mouse_down());
    fabricJS.on('mouse:up:before', this.handler_left_mouse_up());
    fabricJS.upperCanvasEl.addEventListener('contextmenu', this.handler_right_mouse_contextmenu);

    return this
}

