<template>
<!--    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M7.12535 0.912388L7.05836 0.908997L1.48352 17.6251L1.61661 17.6196C2.38646 17.5881 3.9692 17.8486 4.88228 19.1183C5.47344 19.9404 6.00173 20.4558 6.53617 20.7647C7.07228 21.0745 7.60745 21.1724 8.20458 21.1724C8.79766 21.1724 9.28479 21.1238 9.87553 20.8651C10.4627 20.608 11.1476 20.1455 12.1424 19.328C13.1316 18.5151 13.8457 18.0893 14.4207 17.8659C14.9934 17.6434 15.4329 17.6197 15.8795 17.6197C16.3264 17.6197 16.7888 17.6434 17.3846 17.8663C17.982 18.0899 18.7184 18.5157 19.707 19.328C20.7018 20.1455 21.3867 20.608 21.9738 20.8651C22.5646 21.1238 23.0517 21.1724 23.6448 21.1724C24.2419 21.1724 24.7771 21.0745 25.3132 20.7647C25.8476 20.4558 26.3759 19.9404 26.9671 19.1183C27.8802 17.8486 29.4629 17.5881 30.2328 17.6196L30.3658 17.6251L24.791 0.908997L24.724 0.912388C24.3994 0.928827 23.8795 1.01705 23.3661 1.22842C22.8538 1.43937 22.3364 1.77771 22.0329 2.30225C21.6771 2.91707 21.349 3.23859 21.0514 3.40817C20.7546 3.5772 20.4788 3.60083 20.2136 3.60083C19.9563 3.60083 19.7006 3.5561 19.3654 3.36061C19.0265 3.16299 18.6047 2.8103 18.0201 2.18866C17.429 1.56002 16.9912 1.2393 16.6501 1.07671C16.3589 0.937898 16.1418 0.916271 15.9698 0.912901C15.9551 0.912901 15.9401 0.912281 15.9247 0.912364L15.8795 0.91228C15.7076 0.915649 15.4905 0.937898 15.1993 1.07671C14.8582 1.2393 14.4204 1.56002 13.8292 2.18866C13.2447 2.8103 12.8228 3.16299 12.484 3.36061C12.1487 3.5561 11.8931 3.60083 11.6357 3.60083C11.3706 3.60083 11.0947 3.5772 10.798 3.40817C10.5003 3.23859 10.1723 2.91707 9.81647 2.30225C9.51292 1.77771 8.99561 1.43937 8.48323 1.22842C7.96982 1.01705 7.44998 0.928827 7.12535 0.912388Z" stroke="currentColor"/>-->
<!--    </svg>-->
    <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.7566 5.7285C17.439 5.54577 17.0438 5.21964 16.496 4.64482C15.942 4.06354 15.5317 3.76697 15.2122 3.61663C14.9393 3.48827 14.7358 3.46827 14.5747 3.46516C14.5609 3.46516 14.5468 3.46458 14.5324 3.46466L14.4901 3.46458C14.3289 3.4677 14.1255 3.48827 13.8526 3.61663C13.533 3.76697 13.1227 4.06354 12.5688 4.64482C12.021 5.21964 11.6257 5.54577 11.3082 5.7285C10.9941 5.90927 10.7545 5.95063 10.5133 5.95063C10.2649 5.95063 10.0064 5.92878 9.72833 5.77248C9.44939 5.61567 9.14195 5.31837 8.80855 4.74986C8.52411 4.26483 8.03935 3.95197 7.55921 3.75691C7.07812 3.56147 6.59099 3.47988 6.28679 3.46468L6.22402 3.46155L1 18.9185L1.12471 18.9135C1.84612 18.8844 3.32926 19.1252 4.18487 20.2993C4.73883 21.0595 5.23388 21.5361 5.73469 21.8217C6.23706 22.1082 6.73855 22.1987 7.2981 22.1987C7.85386 22.1987 8.31034 22.1537 8.8639 21.9145C9.41408 21.6768 10.0559 21.2491 10.9881 20.4932C11.9151 19.7416 12.5842 19.3478 13.123 19.1412C13.6597 18.9355 14.0715 18.9136 14.4901 18.9136C14.9088 18.9136 15.3421 18.9355 15.9004 19.1416C16.4603 19.3483 17.1503 19.7421 18.0766 20.4932C19.0088 21.2491 19.6507 21.6768 20.2009 21.9145C20.7544 22.1537 21.2109 22.1987 21.7667 22.1987C22.3262 22.1987 22.8277 22.1082 23.3301 21.8217C23.8309 21.5361 24.3259 21.0595 24.8799 20.2993C25.7355 19.1252 27.2186 18.8844 27.94 18.9135L28.0648 18.9185L25.5 11" stroke="currentColor"/>
        <circle cx="23.2231" cy="5.79422" r="5.29422" stroke="currentColor"/>
        <path d="M26.1947 5.94291C26.1947 6.1572 26.021 6.33092 25.8067 6.33092H23.5514V8.57165C23.5514 8.79398 23.3712 8.97421 23.1488 8.97421V8.97421C22.9265 8.97421 22.7463 8.79398 22.7463 8.57165V6.33092H20.491C20.2767 6.33092 20.103 6.1572 20.103 5.94291V5.94291C20.103 5.72863 20.2767 5.55491 20.491 5.55491H22.7463V3.31418C22.7463 3.09185 22.9265 2.91162 23.1488 2.91162V2.91162C23.3712 2.91162 23.5514 3.09185 23.5514 3.31418V5.55491H25.8067C26.021 5.55491 26.1947 5.72863 26.1947 5.94291V5.94291Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVGRoofPlus"
    }
</script>

<style scoped>

</style>
