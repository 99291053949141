import {planePointer} from "../primitives/planePointer";
import store from "../../store";
import {planeLine} from "../primitives/planeLine";
import draw from "tui-image-editor/src/js/ui/template/submenu/draw";
import {planePolygon, planePolygonForTools} from "../primitives/planePolygon";
import {bus} from "../../vue_bus";
import {sortObjectsByZindex} from "../libs/sortObjectsByZindex";
import {additionalElementsLines} from "../primitives/additionalElementsLines";
import {nearPoint, getPointsFromAllPlanes} from "../libs/nearPoint";
import {getPerspectiveMatrix} from "../../math/perspectiveTransform";

export const additionalElementsTool = function (fabricJS) {
    this.fabricJS = fabricJS;
    this.pointZoneSize = 10;
    this.movingID = null;
    this.movingPosition = null;
    this.oldPointID = null;
    this.activeLine = null;
    this.activeShape = null;
    this.polygon = null;
    this.pointArray = [];
    this.lineArray = {};
    this.markerPointerIsAdded = false;
    this.linesGroup = null;
    this.nearPointFinder = new nearPoint(this.fabricJS, this.pointZoneSize);

    this.complete = (pointID) => {
        this.pointArray[pointID].link = this.pointArray.length - 1;
        this.pointArray[0].setColor('default');
        this.fabricJS.remove(this.activeLine);
        this.activeLine = null;
        this.fabricJS.off('mouse:down');

        this.pointArray.push(new planePointer({
            x: this.pointArray[0].position.x+100,
            y: this.pointArray[0].position.y,
        }, 2));

        this.pointArray.push(new planePointer({
            x: this.pointArray[1].position.x+100,
            y: this.pointArray[1].position.y,
        }, 3));

        this.pointArray.forEach((point) => {
            point.dragging(true);
        })

        this.linesGroup = new additionalElementsLines(this.pointArray)
        this.linesGroup.fabric.forEach((obj)=>{
            this.fabricJS.add(obj)
        })
        // this.fabricJS.add(this.linesGroup.fabric);
        this.fabricJS.add(this.pointArray[2].toFabric());
        this.fabricJS.add(this.pointArray[3].toFabric());
        // this.fabricJS.add(dashLine2);

        sortObjectsByZindex(this.fabricJS);
        this.addMovingEvent();
        this.fabricJS.requestRenderAll();
        this.nearPointFinder.setVisible(false);
    }

    this.draw = () => {
        const pointList = store.getters["active/POINTS"];
        if (Object.keys(pointList).length > 0) {
            // let points = []
            for (let pointID in pointList) {
                let pointer = new planePointer(pointList[pointID], pointID - 1);
                pointer.toFabric().set({zIndex: 700});
                // pointer.link = parseInt(pointList[pointID].link) - 1;
                pointer.dragging(true);
                this.pointArray.push(pointer);
                // points.push(pointer.position);
                this.fabricJS.add(pointer.toFabric());
            }
        }

        this.linesGroup = new additionalElementsLines(this.pointArray);
        this.linesGroup.fabric.forEach((obj)=>{
            this.fabricJS.add(obj)
        });


        sortObjectsByZindex(this.fabricJS);
        this.addMovingEvent();
        this.fabricJS.requestRenderAll();
    }

    this.updatePolygon = (points) => {
        let polygon = new planePolygon(points);
        this.fabricJS.remove(this.activeShape);
        this.fabricJS.add(polygon);
        this.activeShape = polygon;
        this.fabricJS.renderAll();
    }

    this.addPoint = () => {
        return (opt) => {
            let relativePoint = this.fabricJS.getPointer(opt.e);
            let correctedPoint = {};
            correctedPoint.x = relativePoint.x;
            correctedPoint.y = relativePoint.y;
            // const pointList = store.getters["active/POINTS"]
            let nearPoint = this.nearPointFinder.findNearPoint(relativePoint);
            if (nearPoint) {
                correctedPoint.x = nearPoint.x;
                correctedPoint.y = nearPoint.y;
            }
            let pointID = this.pointArray.length;
            let pointer = new planePointer(correctedPoint, pointID)
            pointer.toFabric().set({zIndex: 7});
            if (pointID > 0) {
                pointer.link = this.oldPointID;
            } else {
                pointer.setColor('green');
            }
            this.pointArray.push(pointer);
            this.fabricJS.add(pointer.toFabric());
            if (this.activeLine) {
                this.activeLine.set({
                    x2: correctedPoint.x,
                    y2: correctedPoint.y
                })
            }

            if (this.pointArray.length >= 2) {
                this.complete(pointID);
                return
            }

            this.activeLine = new planeLine(correctedPoint, correctedPoint)
            this.activeLine.set({zIndex: 5});
            this.fabricJS.add(this.activeLine);
            this.lineArray[pointID] = this.activeLine;
            this.oldPointID = pointID;

        }
    }

    this.addMovingEvent = () => {
        this.fabricJS.on('object:moving', (event) => {
            let nearPoint = this.nearPointFinder.findNearPoint({x: event.target.left, y: event.target.top});
            if (nearPoint) {
                event.target.set({
                    left: nearPoint.x,
                    top: nearPoint.y
                })
            }
            this.linesGroup.update(this.pointArray);
            this.fabricJS.renderAll();
        });
    }

    this.fabricJS.on('mouse:move', (options) => {
        let pointer = this.fabricJS.getPointer(options.e);
        if (this.activeLine) {
            this.activeLine.set({
                x2: pointer.x,
                y2: pointer.y
            })
        }
        this.nearPointFinder.setPoint(pointer);
        this.fabricJS.renderAll();
    });

    this.destroy = () => {
        bus.$off("AcceptPlaneDrawing");
    }

    bus.$on("AcceptPlaneDrawing", async (callback) => {
        if (await store.dispatch("active/IS_COMPLETE")) {
            for (const point of this.pointArray) {
                let index = this.pointArray.indexOf(point);
                await store.dispatch(`active/CHANGE_POINT_POSITION`,{
                    id: index+1,
                    position: point.position
                })
            }
        } else {
            this.pointArray.forEach((point) => {
                store.dispatch(`active/CREATE_POINT`, point.position)
                // point.dragging(true);
            });
            // const activeProjectUUID = store.state.route.params.projectUUID;
            // const idActivePhoto = store.state.route.params.photoID;
            // const idActivePlane = store.state.route.params.planeID;
            let perspectivePoints = [
                this.pointArray[0].position,
                this.pointArray[1].position,
                this.pointArray[3].position,
                this.pointArray[2].position,
            ]
            await store.dispatch("active/SAVE_PERSPECTIVE_POINTS", perspectivePoints);
            // let perspectiveMatrix = await getPerspectiveMatrix({
            //     planeID: idActivePlane,
            //     photoID: idActivePhoto,
            //     scale: 1 / window.devicePixelRatio
            // });
            // store.commit(`projectList/${activeProjectUUID}/photoList/${idActivePhoto}/planeList/${idActivePlane}/PERSPECTIVE_MATRIX`, perspectiveMatrix);
            await store.dispatch("active/IS_COMPLETE", true);
        }
        callback()
    })

    this.onUpdateScale = (scale) => {
        this.pointZoneSize = 10 / scale;
        this.pointArray.forEach((pointer) => {
            pointer.toFabric().set({
                scaleX: 2 / scale,
                scaleY: 2 / scale,
            })
        })
        for (let lineID in this.lineArray) {
            this.lineArray[lineID].set({
                strokeWidth: 2 / scale,
            })
        }
        this.nearPointFinder.setScale(scale);
    }

    store.dispatch('active/IS_COMPLETE').then((isComplete) => {
            if (!isComplete) {
                this.fabricJS.on('mouse:down', this.addPoint());
            } else {
                this.nearPointFinder.setVisible(false);
                this.draw();
                // this.addMovingEvent();
            }

        }
    )


}
