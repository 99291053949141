<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Добавить модель
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container pv-23">
            <div class="two-cols-modal-grid">
                <div class="modal-form-grid">
                    <div class="modal-form-grid-label">
                        Название
                    </div>
                    <input v-model="name" class="modal-form-grid-input">
                    <div class="modal-form-grid-label">
                        Размер
                    </div>
                    <input v-model="scale" class="modal-form-grid-input">
                    <div class="modal-form-grid-label">
                        Позиция X
                    </div>
                    <input v-model="positionX" class="modal-form-grid-input">
                    <div class="modal-form-grid-label">
                        Позиция Y
                    </div>
                    <input v-model="positionY" class="modal-form-grid-input">
                    <div class="modal-form-grid-label">
                        Позиция Z
                    </div>
                    <input v-model="positionZ" class="modal-form-grid-input">
                </div>
                <div class="modal-form-images-container">
                    <div class="modal-form-grid-label">
                        Повторять вправо
                    </div>
                    <input v-model="repeatX" class="modal-form-grid-input">
                    <div class="modal-form-grid-label">
                        Повторять вверх
                    </div>
                    <input v-model="repeatZ" class="modal-form-grid-input">
                    <div class="modal-form-grid-label">
                        Поворот X
                    </div>
                    <input v-model="rotationX" class="modal-form-grid-input">
                    <div class="modal-form-grid-label">
                        Поворот Y
                    </div>
                    <input v-model="rotationY" class="modal-form-grid-input">
                    <div class="modal-form-grid-label">
                        Поворот Z
                    </div>
                    <input v-model="rotationZ" class="modal-form-grid-input">
                    <div class="modal-form-checkbox-grid">
                        <div class="form-input-checkbox-name">
                            Сменить ориентацию  модели
                        </div>
                        <input type="checkbox" v-model="changeOrientation"
                               class="form-input-checkbox">
                    </div>
                    <div class="modal-form-images-container">
                    </div>
                    <a :href='urlForFileStorage(model3dSrc)' style="font-size: 12px;">{{model3dSrc}}</a>
                    <div class="blue-button modal-form-img-button" @click="$refs.file2.click()">
                        {{model3dSrc?'Обновить модель':'Загрузить модель'}}
                    </div>
                    <input type="file" id="fileInput3DModal" ref="file2" v-on:change="handleFileUpload3D($event)"
                           accept=".glb" hidden>
                </div>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-lower-buttons-grid">
                <div class="blue-button modal-accept-button"
                     v-on:click="save">Сохранить
                </div>
                <div class="blue-outline-button modal-accept-button" v-on:click="$emit('close')">Отмена</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import {urlForFileStorage, urlForStaticVue} from "../../common";
    import {http_request, uploadFiles} from "../../../httpConfig";

    export default {
        name: "ModalProduct",
        mixins: [urlForStaticVue, urlForFileStorage],
        components: {
            SVGClose,
        },
        props: {
            model3dData: {
                type: Object
            },
            callback: {
                type: Function
            }
        },
        data() {
            return {
                name: null,
                model3dSrc: null,
                repeatX: null,
                repeatZ: null,
                scale: null,
                positionX: null,
                positionY: null,
                positionZ: null,
                rotationX: null,
                rotationY: null,
                rotationZ: null,
                changeOrientation: false
            }
        },
        methods: {
            uploadFile(fileInput, callback) {
                for (let file in fileInput.target.files) {
                    if (fileInput.target.files[file].size / 1048576 > 15) {
                        return
                    }
                }
                console.log(fileInput.target.files);
                uploadFiles(fileInput.target.files)
                    .then((request) => {
                        console.log(request.data);
                        callback(request.data.fileList);
                    })
            },
            handleFileUpload3D(fileInput) {
                const saveModel3D = (filesSrc) => {
                    for (let src of filesSrc) {
                        this.model3dSrc = src;
                    }
                };
                this.uploadFile(fileInput, saveModel3D);
            },
            save() {
                const dataModel = {
                    name: this.name,
                    model3d: this.model3dSrc,
                    data_json: {
                        position: {
                            x: this.positionX,
                            y: this.positionY,
                            z: this.positionZ,
                        },
                        rotation: {
                            x: this.rotationX,
                            y: this.rotationY,
                            z: this.rotationZ,
                        },
                        scale: this.scale,
                        repeatX: this.repeatX,
                        repeatZ: this.repeatZ,
                        changeOrientation: this.changeOrientation
                    }
                };

                if (this.model3dData) {
                    dataModel['id'] = this.model3dData.id;
                }

                console.log(dataModel);

                http_request.post('/save_model3d', dataModel)
                    .then((request) => {
                        console.log(request.data);
                        if (this.callback) {
                            this.callback(request.data.model3d);
                        }
                        this.$emit('close');
                    });
            }
        },
        mounted() {
            if (this.model3dData) {
              console.log(this.model3dData)
                this.name = this.model3dData.name;
                this.model3dSrc = this.model3dData.model3d;
                this.repeatX = this.model3dData.data_json.repeatX;
                this.repeatZ = this.model3dData.data_json.repeatZ;
                this.scale = this.model3dData.data_json.scale;
                if (this.model3dData.data_json.position) {
                    this.positionX = this.model3dData.data_json.position.x;
                    this.positionY = this.model3dData.data_json.position.y;
                    this.positionZ = this.model3dData.data_json.position.z;
                }
                if (this.model3dData.data_json.rotation) {
                    this.rotationX = this.model3dData.data_json.rotation.x;
                    this.rotationY = this.model3dData.data_json.rotation.y;
                    this.rotationZ = this.model3dData.data_json.rotation.z;
                }
                this.changeOrientation = !!this.model3dData.data_json.changeOrientation;
            }
        }
    }
</script>

<style scoped>

</style>
