<template>
    <svg width="160" height="153" viewBox="0 0 160 153" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36.7151 1.85298C38.4323 0.646677 40.4746 0 42.5672 0H154.88C157.708 0 160 2.31225 160 5.16456V120.722C160 122.133 159.427 123.483 158.415 124.457L131.739 150.143C129.834 151.977 127.302 153 124.669 153H5.12C2.2923 153 0 150.688 0 147.835V30.3418C0 28.6521 0.819379 27.0693 2.19395 26.1037L36.7151 1.85298ZM42.5672 10.3291L21.4308 25.1772H124.778L141.385 10.3291H42.5672ZM149.76 16.6435L131.84 32.6654V135.766L149.76 118.512V16.6435ZM121.6 142.671V35.5063H10.24V142.671H121.6Z" fill="currentColor"/>
    </svg>
</template>

<script>
    export default {
        name: "SVG3D"
    }
</script>

<style scoped>

</style>
