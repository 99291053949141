var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "11",
        viewBox: "0 0 18 11",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M8.5234 10.1484L-8.01086e-05 2.19319L2.04688 2.57492e-05L8.5234 6.04478L14.9999 2.57492e-05L17.0469 2.19319L8.5234 10.1484Z",
          fill: "currentColor"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }