<template>
    <div class="admin-workspace-container">
        <div class="admin-search-new-container">
            <div>
                <div class="search-icon-anchor">
                    <SVGSearch class="search-icon"/>
                </div>
                <input class="modal-form-grid-input search-input" type="text" placeholder="Найти" v-model="mask">
            </div>
            <div class="blue-button admin-new-button" v-on:click="addNew">Добавить</div>
        </div>

        <vue-custom-scrollbar class="admin-table-container">
            <table class="admin-table">
                <thead>
                <tr class="admin-table-head-tr">
                    <th>№</th>
                    <th>Наименование</th>
                    <th>Ширина</th>
                    <th>Высота</th>
                    <th class="admin-table-options-head">Опции</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(value, index) in normalMap" class="admin-table-line">
                    <td>
                        <span class="">{{index + 1}}</span>
                    </td>
                    <td>
                        <span>{{value.name}}</span>
                    </td>
                    <td>
                        <span>{{value.width}}</span>
                    </td>
                    <td>
                        <span>{{value.height}}</span>
                    </td>
                    <td>
                        <div class="admin-table-options">
                            <div class="admin-table-option" v-on:click="editRow(value)">
                                <SVGEdit/>
                            </div>
                            <div class="admin-table-option" v-on:click="deleteRow(value)">
                                <SVGClose/>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

        </vue-custom-scrollbar>
    </div>
</template>

<script>
    import SVGSearch from "../../../SVG/SVGSearch";
    import SVGEdit from "../../../SVGMP/SVGEdit";
    import SVGClose from "../../../SVGMP/SVGClose";
    import {urlForStaticVue} from "../../../common";
    import vueCustomScrollbar from 'vue-custom-scrollbar';
    import {http_request} from "../../../../httpConfig";
    import ModalAttention from "../../../ModalMP/ModalAdmin/ModalAttention";
    import ModalNormalMap from "../../../ModalMP/ModalAdmin/ModalNormalMap";

    export default {
        name: "AdminWorkspaceNormalMaps",
        mixins: [urlForStaticVue],
        components: {
            SVGClose,
            SVGEdit,
            SVGSearch,
            vueCustomScrollbar
        },
        data() {
            return {
                normalMap: [],
                mask: ''
            }
        },
        methods: {
            addNew() {
                this.$modal.show(ModalNormalMap, {callback: (data) => {window.location.reload();}}, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 780,
                });
            },
            update(newData) {
                const index = this.normalMap.map(element => element.id).indexOf(newData.id);
                if (index > -1) {
                    this.normalMap.splice(index, 1, newData);
                }
            },
            editRow(data) {
                this.$modal.show(ModalNormalMap, {
                    normalMapData: data,
                    callback: this.update
                }, {
                    height: 'auto',
                    pivotY: 0.2,
                    width: 780,
                });
            },
            delete(id) {
                return () => {
                    http_request.post('/delete_normal_map', {id: id})
                        .then((request) => {
                            console.log(request.data);
                            const index = this.normalMap.map(element => element.id).indexOf(id);
                            if (index > -1) {
                                this.normalMap.splice(index, 1);
                            }
                        })
                }
            },
            deleteRow(params) {
                this.$modal.show(ModalAttention,
                    {
                        alertMsg: 'Вы уверены, что хотите удалить карту нормали "' + params.name + '"? ',
                        callback: this.delete(params.id)
                    }, {
                        height: 'auto',
                        pivotY: 0.2,
                        width: 515,
                    });
            },
            getNormalMaps(){
                http_request.post('/get_normal_maps', {
                    find: {
                        mask: this.mask,
                    },
                })
                    .then((request) => {
                        console.log(request.data);
                        this.normalMap.push(...request.data.normal_maps);
                    });
            }
        },
        watch: {
            mask() {
                this.normalMap.splice(0);
                this.getNormalMaps();
            },
        },
        mounted() {
           this.getNormalMaps();
        }
    }
</script>

<style scoped>

</style>

