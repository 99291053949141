var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "68",
        height: "54",
        viewBox: "0 0 68 54",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M1 35.698V52.7446H44.1171H67V28.5283V12.7454H44.1171V28.5283H38.322L37.3 1H29.82L28.4461 28.5283H23.1283L21.9 1H14.64L13.2525 28.5283H1.00003L1 35.698Z",
          stroke: "currentColor",
          "stroke-width": "2"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }