import {http_request} from "../../httpConfig";

export const users = {
    namespaced: true,
    state: () => ({
        numberLoadedItems: 5,
        users: [],
        waitingResponseToUserRequest: false,
        allUploaded: false,
        mask: ''
    }),
    mutations: {
        USERS: (state, users) => {
            if (users.length !== 0)
                state.users = state.users.concat(users);
            else
                state.allUploaded = true;
        },
        SAVE_USER:(state, userData) => {
            let indexUser = state.users.findIndex(user => user.id === userData.id);
            if (indexUser === -1) {
                state.users.unshift(userData);
            } else {
                state.users.splice(indexUser, 1, userData)
            }
        },
        DELETE_USER: (state, idUser) => {
            let indexUser = state.users.findIndex(user => user.id === idUser);
            if (indexUser !== -1) {
                console.log(state.users.splice(indexUser, 1));
            }
        },
        CLEAR_USERS: (state) => {
            state.users.splice(0);
            state.allUploaded = false;
        },
        MASK: (state, value) => {
            state.mask = value;
        }
    },
    getters: {
        SELF: state => {
            return state.users;
        }
    },
    actions: {
        GET_USERS: async function (context, inputData) {
            if(context.state.waitingResponseToUserRequest || context.state.allUploaded)
                return;

            context.state.waitingResponseToUserRequest = true;
            const mask = context.state.mask.trim();
            let dataForSend = {};

            if (inputData) {
                dataForSend = Object.assign(dataForSend, inputData);
            }

            dataForSend.part = {
                // from: context.getters.LENGTH,
                from: context.state.users.length,
                count: context.state.numberLoadedItems
            };

            if(mask.length !== 0){
                dataForSend.find = {
                    mask: mask + '%',
                    field: 'fullname'
                };
            }
            console.log(dataForSend);
            await http_request.post('/get_users', dataForSend)
                .then((request) => {
                    console.log(request.data);
                    context.commit('USERS', request.data.users);
                })
                .catch((error) => {
                    context.state.allUploaded = true;
                    console.error(error);
                });

            context.state.waitingResponseToUserRequest = false;
        },

        SAVE_USER: async function (context, inputData) {
            await http_request.post('/save_user', inputData)
                .then((request) => {
                    console.log(request.data);
                    if ('user_updated' in request.data){
                        context.commit('SAVE_USER', request.data.user_updated);
                    }else if ('user_added' in request.data){
                        context.commit('SAVE_USER', request.data.user_added);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        DELETE_USER: async function (context, idUser) {
            await http_request.post('/delete_user', {id: idUser})
                .then((request) => {
                    console.log(request.data);
                    context.commit('DELETE_USER', idUser);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    }
};
