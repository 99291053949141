import {DesignGroup} from "./DesignGroup";
import {getModuleNamespace} from "../index";

export function DesignGroupList() {
    this.namespaced = true;
    this.state = {
        // Здесь нельзя ничего писать! Здесь будут только динамически созданные модули проектов! Иначе сломается логика.
    };
    this.getters = {};
    this.mutations = {
        DESIGN_GROUP_WAS_REMOVED:(state, data) => {

        }
    };
    this.actions = {
        CREATE_DESIGN_GROUP_MODULE: async function (context, inputData) {
            const modulePath = getModuleNamespace(this, context.state);

            let id;
            if (inputData.id) {
                id = inputData.id;
            } else {
                console.log('Не указан id модуля designGroup');
                return;
            }

            const pathDesign = modulePath.concat([id]);
            const designData = inputData.data;
            this.registerModule(pathDesign, new DesignGroup());
            await context.dispatch(`${id}/INIT`, {
                data: designData,
            });
        },
        DELETE_DESIGN_GROUP_MODULE: async function (context, moduleID) {
            const modulePath = getModuleNamespace(this, context.state);
            const pathDesignGroup = modulePath.concat([moduleID]);
            this.unregisterModule(pathDesignGroup);
            context.commit('DESIGN_GROUP_WAS_REMOVED', pathDesignGroup);
        },
        GET_OR_CREATE_DESIGN_GROUP_MODULE: async function (context, data) {
            const planeParams = () => context.getters[`${data.idDesignGroup}/PLANE_PARAM`](data.planeType);
            if(state[data.idDesignGroup])
                return planeParams;
            else {
                await context.dispatch('CREATE_DESIGN_GROUP_MODULE', {
                    id: data.idDesignGroup,
                    data: {}
                });
                return planeParams;
            }
        }
    };
}