import store from "../../store";

export const planeLine = function(point1, point2) {
    let scale = 2;
    if (store.state.route.query.view)
        scale = 2/JSON.parse(store.state.route.query.view)[0]

    this.fabricObject  = new fabric.Line(
        [
            point1.x,
            point1.y,
            point2.x,
            point2.y
        ],
        {
            stroke: 'rgba(173,12,39)',
            selectable: false,
            // evented: false,
            perPixelTargetFind: true,
            strokeWidth: scale
        })

    this.fabricObject.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: false,
        br: false,
        tl: false,
        tr: false,
        mtr: false
    });

    return this.fabricObject
}

