import {PlaneGroup} from "./PlaneGroup";
import {getModuleNamespace} from "../index";

export function PlaneGroupList() {
    this.namespaced = true;
    this.state = {
        // Здесь нельзя ничего писать! Здесь будут только динамически созданные модули проектов! Иначе сломается логика.
    };
    this.getters = {
        MAX_ID_DESIGN: state => {
            let maxID = 0;
            for (let idPlaneGroup in state) {
                if (maxID < parseInt(idPlaneGroup)) maxID = parseInt(idPlaneGroup);
            }
            return maxID;
        },
        SELF: state => state,
    };
    this.mutations = {
        PLANE_GROUP_WAS_REMOVED:(state, data) => {

        }
    };
    this.actions = {
        CREATE_PLANE_GROUP_MODULE: async function (context, inputData) {
            const modulePath = getModuleNamespace(this, context.state);
            let id;
            if (inputData.id) {
                id = inputData.id;
            } else {
                id = context.getters.MAX_ID_DESIGN + 1;
            }

            const pathPlaneGroup = modulePath.concat([id]);
            const dataPlaneGroup = inputData.data;
            this.registerModule(pathPlaneGroup, new PlaneGroup(id));
            await context.dispatch(`${id}/INIT`, {
                data: dataPlaneGroup,
            });
            return id;
        },
        DELETE_PLANE_GROUP_MODULE: async function (context, moduleID) {
            const modulePath = getModuleNamespace(this, context.state);
            const pathPlaneGroup = modulePath.concat([moduleID]);
            this.unregisterModule(pathPlaneGroup);
            context.commit('PLANE_GROUP_WAS_REMOVED', pathPlaneGroup);
        }
    };
}