<template>
    <div class="modal-background">
        <div class="modal-head-grid">
            <div class="modal-label">
                Добавить подкатегорию
            </div>
            <div class="modal-close-btn-mp" v-on:click="$emit('close')">
                <SVGClose/>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-form-grid" style="margin-top: 23px">
                <div class="modal-form-grid-label">
                    Название
                </div>
                <input class="modal-form-grid-input" type="text" placeholder="Название подкатегории"
                       v-model="nameSubcategory">
                <template v-if="!subcategoryForChange">
                    <div class="modal-form-grid-label">Категория</div>
                    <select v-model="selectCategoryId"
                            class="modal-form-grid-input">
                        <option v-for="category in categories" :value="category.id">
                            {{category.name}}
                        </option>
                    </select>
                </template>
                <template v-if="selectedCategoryIsColor">
                    <div class="modal-form-grid-label">
                        Изображение
                    </div>
                    <div>
                        <img class="modal-form-img" :src="urlForStatic('/img/no-img-placeholder.png')"
                             :class="{'image-placeholder': srcPhoto === null}"
                             @click="$refs.file.click()"/>
                        <input type="file" id="fileInputModal" ref="file" v-on:change="handleFileUpload($event)"
                               accept=".jpg,.png" hidden>
                    </div>
                </template>
            </div>
        </div>
        <div class="modal-data-container">
            <div class="modal-lower-buttons-grid">
                <div class="blue-button modal-accept-button" v-on:click="save">Сохранить</div>
                <div class="blue-outline-button modal-accept-button" v-on:click="$emit('close')">Отмена</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SVGClose from "../../SVGMP/SVGClose";
    import {urlForStaticVue} from "../../common";
    import {http_request, uploadFiles} from "../../../httpConfig";
    import {bus} from "../../../vue_bus";

    export default {
        name: "ModalSubcategory",
        mixins: [urlForStaticVue],
        components: {
            SVGClose
        },
        props: {
            categories: {
                type: Array,
                default(){
                    return [];
                }
            },
            defaultSelectCategoryId: {
                type: [String, Number]
            },
            subcategoryForChange: {
                type: Object
            },
            callback: {
                type: Function
            }
        },
        data() {
            return {
                srcPhoto: null,
                selectCategoryId: this.defaultSelectCategoryId,
                nameSubcategory: ''
            }
        },
        computed: {
            selectedCategoryIsColor() {
                if (this.selectCategoryId) {
                    const selectCategory = this.categories.find(category => category.id == this.selectCategoryId);
                    if (selectCategory && selectCategory.name && typeof (selectCategory.name) === 'string') {
                        return selectCategory.name.toLowerCase().indexOf('цвет') > -1;
                    }
                }
            }
        },
        methods: {
            handleFileUpload(fileInput) {
                const savePhotos = (photosSRC) => {
                    for (let srcPhoto of photosSRC) {
                        this.srcPhoto = srcPhoto;
                    }
                };

                for (let file in fileInput.target.files) {
                    if (fileInput.target.files[file].size / 1048576 > 15) {
                        // fileTooLarge = true
                        return
                    }
                }
                // fileTooLarge = false
                console.log(fileInput.target.files);
                uploadFiles(fileInput.target.files)
                    .then((request) => {
                        console.log(request.data);
                        savePhotos(request.data.fileList);
                    })
            },
            save() {
                const dataSubcategory = {
                    name: this.nameSubcategory,
                    preview_src: this.srcPhoto
                };

                if (this.subcategoryForChange) {
                    dataSubcategory['id'] = this.subcategoryForChange.id;
                } else {
                    if (!this.selectCategoryId) {
                        alert('Необходимо выбрать категорию');
                    }
                    dataSubcategory['categories'] = [this.selectCategoryId];
                }

                console.log(dataSubcategory);
                http_request.post('/save_subcategory', dataSubcategory)
                    .then((request) => {
                        console.log(request.data);
                        if(this.callback) {
                            this.callback();
                        }
                        this.$emit('close');
                    });
            }
        },
        mounted() {
            if (this.subcategoryForChange) {
                this.nameSubcategory = this.subcategoryForChange.name;
            }
        }
    }
</script>

<style scoped>

</style>
