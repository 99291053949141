import store from "../../store";
import { getPointOnLine } from "../libs/lines";

export const perspectiveLine = function (point1, point2) {
    let d = 100;
    this.x1 = point1.x;
    this.y1 = point1.y;
    this.x2 = point2.x;
    this.y2 = point2.y;
    let scale = 3;
    if (store.state.route.query.view)
        scale = 3/JSON.parse(store.state.route.query.view)[0]

    this.getNewPoints = () => {
        let newPoint1 = getPointOnLine({x: this.x1, y: this.y1}, {x: this.x2, y: this.y2}, -d);
        let newPoint2 = getPointOnLine({x: this.x2, y: this.y2}, {x: this.x1, y: this.y1}, -d);
        this._x1 = newPoint1.x;
        this._y1 = newPoint1.y;
        this._x2 = newPoint2.x;
        this._y2 = newPoint2.y;
    }

    this._set = (key, value) => {
        this[key] = value;
    };

    this.set = (obj) => {
        for (let prop in obj) {
            this._set(prop, obj[prop]);
        }
        this.getNewPoints();
        this.fabricObject.set({
                x1: this._x1,
                y1: this._y1,
                x2: this._x2,
                y2: this._y2
            }
        )
    }

    this.getNewPoints();

    this.fabricObject = new fabric.Line(
        [
            this._x1,
            this._y1,
            this._x2,
            this._y2
        ],
        {
            stroke: 'rgb(69,146,248)',
            selectable: false,
            strokeDashArray: [5, 10],
            strokeWidth: scale,
            evented: false,
        })

    this.fabricObject.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: false,
        br: false,
        tl: false,
        tr: false,
        mtr: false,
    });

    this.toFabric = () => {
        return this.fabricObject;
    }



    return this
}

